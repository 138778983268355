<div class="w-full text-ellipsis  overflow-hidden whitespace-nowrap">
    @if(article.articleNumber){
        {{ article.articleNumber }}<br />
    }    
    <span class="text-xl font-bold">{{ article.title }}</span>
</div>
<div class="grid grid-cols-11 mt-2 gap-2">
    <div class="col-span-3">
        <span class="text-on-app-light text-sm">{{ 'Articles.List.Price' | transloco }}</span>
        <br />
        <div class="text-xl">{{ article.price | arcCurrency }}</div>
    </div>
    <div class="col-span-2">
        <span class="text-on-app-light text-sm">{{ 'Transactions.Details.VAT' | transloco }}</span>
        <br />
        <div class="text-xl">{{ article.taxRate * 100 | arcNumber: 2:undefined:'%' }}</div>
    </div>
    <div class="col-span-6">
        <span class="text-on-app-light text-sm">
            {{
            (!!article.buyingPriceExclusive
            ? 'Articles.Detail.BuyingPriceExclusive'
            : 'Articles.Detail.BuyingPriceInclusive') | transloco
            }}
        </span>
        <br />
        @if (buyingPricePermission !== true) {
        <arc-no-permission-lock [missingPermission]="buyingPricePermission"> </arc-no-permission-lock>
        }
        @else {
        <div class="text-xl">{{ article.buyingPrice | arcCurrency }}</div>
        }
    </div>
</div>
<div class="w-full mt-4">
    <span class="text-on-app-light text-sm">{{ article.mainAndTradeGroup }}</span>
    <br />
    <div class="text-xl">{{ article.articleGroupDesc }}</div>
</div>
<div *ngIf="article.identifications.length > 0" class="w-full flex items-center flex-col mt-3">
    <img [src]="getUrl(article.identifications[0].identification)" class=" w-[320px] h-[55px]" />
    <div class="text-xl">
        {{ article.identifications[0].identification }}
    </div>
</div>