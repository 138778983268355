import { Component, WritableSignal, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { WarehouseRequestModel } from '../../../../../models/warehouse-request.model';
import { WarehouseRequestEditModel } from '../../../../../models/requests/warehouse-request-edit.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GenericStatusEnum, GenericStatusEnumExtensions } from '../../../../../models/enums/generic-status.enum';

@Component({
    selector: 'arc-warehouse-transfer-edit-base-data',
    templateUrl: './warehouse-transfer-edit-base-data.component.html',
    styleUrl: './warehouse-transfer-edit-base-data.component.scss'
})
export class WarehouseTransferEditBaseDataComponent extends BaseEditSidebarItemComponent<WarehouseRequestModel, WarehouseRequestEditModel> {
    statusBgColorClass = GenericStatusEnumExtensions.getColor(GenericStatusEnum.Draft);

    override formGroup = new FormGroup({
        remarks: new ArcFormControl<OptionalType<string>>(undefined)
    });

    readonly requestedArticlesCount: WritableSignal<OptionalType<number>>;
    readonly receivedArticlesCount: WritableSignal<OptionalType<number>>;

    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();
        this.requestedArticlesCount = this.sharedDataService.getOrCreateSignal<number>('requestedArticlesCount');
        this.receivedArticlesCount = this.sharedDataService.getOrCreateSignal<number>('receivedArticlesCount');
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
        this.statusBgColorClass = GenericStatusEnumExtensions.getColor(this.item.status);
    }

    override prepareSaveModel(): Partial<WarehouseRequestEditModel> {
        return { remarks: this.formGroup.getRawValue().remarks };
    }
}
