import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { SecurityStorage } from '../storages/security.storage';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    private readonly securityStorage = inject(SecurityStorage);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.securityStorage.getToken();

        // do not add header for requests that do not go to our api server
        if (!!token && (request.url.startsWith(environment.baseUrl) || request.url.startsWith(environment.reportingUrl))) {
            const authRequest = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`)
            });
            return next.handle(authRequest);
        }

        return next.handle(request);
    }
}
