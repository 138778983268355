import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { ArticleImageModel } from '../../../../../../../models/responses/article-image.model';
import { MainArticlesStore } from '../../../../../../../services/stores/main-articles.store';
import { ArcFormControl } from '../../../../../../../../core/utils/arc-form-control';
import { PermissionTypeEnum } from '../../../../../../../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../../../../../../models/enums/permissions.enum';
import { KeyValueModel } from '../../../../../../../../core/models/key-value.model';
import { GeneralDataTypeEnum } from '../../../../../../../../core/models/enums/general-data-type.enum';
import { GeneralDataService } from '../../../../../../../../core/services/general-data.service';
import { OptionalType } from '../../../../../../../../core/models/types/optional.type';
import { VariantEditModalModel } from './variant-edit-modal.model';
import { EntityTagModel } from '../../../../../../../models/entity-tag.model';

@Component({
    selector: 'arc-article-variant-edit-modal',
    templateUrl: './article-variant-edit-modal.component.html',
    styleUrl: './article-variant-edit-modal.component.scss'
})
export class ArticleVariantEditModalComponent implements OnInit {
    readonly PermissionTypeEnum = PermissionTypeEnum;
    readonly PermissionsEnum = PermissionsEnum;
    modalFormGroup = new FormGroup({
        orderNumber: new ArcFormControl<OptionalType<string>>(undefined),
        buyingPrice: new ArcFormControl<OptionalType<number>>(0),
        tags: new ArcFormControl<KeyValueModel[]>([]),
        articleImages: new ArcFormControl<ArticleImageModel[]>([])
    });
    isLoading = true;
    articleTags: KeyValueModel[] = [];
    mainArticleTags: EntityTagModel[] = [];
    tagsToSkip: string[] = [];
    get canUpdateOrderNumber(): boolean {
        return this.data.hasMainSupplier;
    }

    private readonly data: VariantEditModalModel = inject(MAT_DIALOG_DATA);
    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly generalDataService = inject(GeneralDataService);

    ngOnInit(): void {
        this.mainArticleTags = this.data.mainArticleTags;
        this.tagsToSkip = this.mainArticleTags.map(t => `${t.id}${t.title}`);
        this.articleTags = this.data.recordData.tags
            .filter(t => !this.tagsToSkip.includes(`${t.id}${t.title}`))
            .map(t => ({ key: t.id.toString(), value: t.title }));

        this.modalFormGroup.patchValue({
            orderNumber: this.data.recordData.orderNumber,
            buyingPrice: this.data.recordData.buyingPrice,
            tags: this.articleTags,
            articleImages: this.data.recordData.articleImages
        });

        if (!this.canUpdateOrderNumber) {
            this.modalFormGroup.controls.orderNumber.disable();
        }

        // Is editing.
        if (this.data.recordData.id > 0) {
            this.mainArticlesStore.get(this.data.recordData.id).subscribe(resp => {
                this.modalFormGroup.patchValue({
                    articleImages: resp.value?.articleImages || []
                });
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }

    getTagId(option: KeyValueModel): string {
        return option.key ?? '';
    }

    tagsOptionDisplay(option: KeyValueModel): string {
        return option.value || '';
    }

    tagsSearch(query: string): Observable<KeyValueModel[]> {
        return this.generalDataService
            .getGeneralData(GeneralDataTypeEnum.Tags)
            .pipe(
                map(response => {
                    let result = !!response && !!query
                        ? response?.filter(f => f.value?.toLowerCase().includes(query.toLowerCase())) || []
                        : response || [];
                    result = result.filter(t => !this.tagsToSkip.includes(`${t.key}${t.value}`));

                    return result;
                })
            );
    }
}
