import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { MessageModel } from '../../models/message.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { MessageListModel } from '../../models/responses/message-list.model';

@Injectable({
    providedIn: 'root'
})
export class MessagesStore extends BaseCrudStore<MessageModel, MessageListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'messages' }));
    }

    getNewHighPriorityMessages(): Observable<ApiResponseModel<MessageModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<MessageModel[]>>(
            this.getUrl('get-new-high-priority'));
    }

    markAsRead(id: number): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePut<ApiResponseModel<boolean>>(this.getUrl(`${id}/mark-as-read`));
    }

    resetEdi(id: number): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePut<ApiResponseModel<boolean>>(this.getUrl(`${id}/reset-edi`));
    }
}
