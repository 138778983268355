<div class="h-full w-full">
    @if(isLoading()) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    }
    @if(!!tableConfig && items.length > 0){
        <arc-static-table [config]="tableConfig" [shouldUseCompactStyle]="true"></arc-static-table>
    } 
    @else {
        <div class="pt-10">
            <p class="text-on-app-light text-center">{{'InventoryArticles.Details.NotCounted' | transloco}}</p>
        </div>
    }
</div>
