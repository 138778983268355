import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ReportEditRequestModel } from '../../../../../models/requests/report-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ReportModel } from '../../../../../models/report.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';

@Component({
    selector: 'arc-report-edit',
    templateUrl: './report-edit-base-data.component.html',
    styleUrls: ['./report-edit-base-data.component.scss']
})
export class ReportEditBaseDataComponent extends BaseEditSidebarItemComponent<ReportModel, ReportEditRequestModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        description_de: new ArcFormControl<OptionalType<string>>(undefined),
        description_fr: new ArcFormControl<OptionalType<string>>(undefined),
        description_en: new ArcFormControl<OptionalType<string>>(undefined),
        description_it: new ArcFormControl<OptionalType<string>>(undefined),
        requiredRoleId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }
    override prepareSaveModel(): Partial<ReportEditRequestModel> {
        return { ...this.formGroup.getRawValue() };
    }
}
