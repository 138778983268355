<div class="h-full flex flex-col">
    <div class="relative overflow-y-auto my-4 gap-4 card-grid pb-8 min-h-[128px]">
        @for (item of data; track item.title) {
            <mat-card>
                <mat-card-header class="flex rounded-tr rounded-tl items-center bg-gray text-black h-[100px] !px-[32px] !py-[16px]">
                    <div class="w-full">
                        <div class="text-3xl">{{ item.title }}</div>
                    </div>
                </mat-card-header>
                <mat-card-content class="!pt-2 mt-0.5 grow overflow-auto">
                    @for (section of item.sections; track section.title) {
                        <div class="flex-col bg-element-selected mt-4 py-1 px-4 rounded items-center"
                            [class.bg-warn]="section.isMissingRequiredInformation">
                            <div class="flex items-center" [class.text-on-warn]="section.isMissingRequiredInformation">
                                <span class="font-bold mr-auto">{{ section.title }}</span>
                                @if (section.isMissingRequiredInformation) {
                                    <mat-icon>warning</mat-icon>
                                }
                            </div>
                            @for (row of section.rowsContent; track row) {
                                <span class="text-sm">{{ row }}</span>
                            }
                        </div>
                    }
                </mat-card-content>
                <mat-card-actions>
                    @if (hasPermission(item.requiredPermission) === true) {
                        <arc-buttons-bar class="ml-auto" [items]="buttons" [shouldStopPropagation]="true" [data]="item"></arc-buttons-bar>
                    }@else {
                        <arc-no-permission-lock class="ml-auto pr-2" [iconSize]="24" [missingPermission]="hasPermission(item.requiredPermission)"></arc-no-permission-lock>
                    }
                </mat-card-actions>
            </mat-card>
        }

        @if (isLoading) {
            <div class="absolute inset-0 bg-overlay grid place-items-center">
                <mat-spinner diameter="64"></mat-spinner>
            </div>
        }
    </div>
</div>
