import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleVariantListModel } from '../../../../../models/responses/article-variant-list.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-variant-stock-data',
    templateUrl: './article-variant-stock-data.detail-widget.html',
    styleUrl: './article-variant-stock-data.detail-widget.scss'
})
export class ArticleVariantStockDataDetailWidget extends BaseDetailWidget<ArticleVariantListModel, ArticleModel> {}
