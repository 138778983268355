import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SaleModel } from '../../../../../../models/sale.model';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { UnitsEnum } from '../../../../../../../core/models/enums/units.enum';
import { PermissionsEnum } from '../../../../../../models/enums/permissions.enum';
import { TranslationService } from '../../../../../../../core/services/translation.service';
import { SalesDiscountTypesEnum as SaleDiscountTypesEnum } from '../../../../../../models/enums/sale-discount-types.enum';

@Component({
    selector: 'arc-sale-edit-base-data',
    templateUrl: './sale-edit-base-data.component.html',
    styleUrls: ['./sale-edit-base-data.component.scss']
})
export class SaleEditBaseDataComponent extends BaseEditSidebarItemComponent<SaleModel> {
    PermissionsEnum = PermissionsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    UnitsEnum = UnitsEnum;
    SaleDiscountTypesEnum = SaleDiscountTypesEnum;
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        quantity: new ArcFormControl<OptionalType<number>>(undefined),
        roundTo: new ArcFormControl(0.05, Validators.required),
        bonusFactor: new ArcFormControl<OptionalType<number>>(undefined),
        branchId: new ArcFormControl<OptionalType<number>>(undefined),
        priceLevel: new ArcFormControl<OptionalType<number>>(undefined),
        promotionCode: new ArcFormControl(''),
        allowDeceedBuyingPrice: new ArcFormControl(true),
        isDiscountLocked: new ArcFormControl(false),
        valueAsNumber: new ArcFormControl(0, Validators.required)
    });
    discountType = new FormControl(SaleDiscountTypesEnum.Amount);

    readonly translateService = inject(TranslationService);
    readonly discountTypeItems = [
        { value: SaleDiscountTypesEnum.Amount, label: this.translateService.getText('Sales.Edit.Amount') },
        { value: SaleDiscountTypesEnum.Percent, label: this.translateService.getText('Sales.Edit.Percent') }
    ];

    onItemSet(): void {
        this.formGroup.patchValue(this.item);

        if (!!this.item.value) {
            const isPercentage = this.item.value.includes('%');
            this.discountType.setValue(isPercentage ? SaleDiscountTypesEnum.Percent : SaleDiscountTypesEnum.Amount) ;
            const val = Number(this.item.value.replace('%', '').trim());

            this.formGroup.controls.valueAsNumber.setValue(val);
        }

        if (!this.isCreate) {
            // discount may only be changed for new items
            this.formGroup.controls.valueAsNumber.disable();
            this.discountType.disable();
        }
    }

    override prepareSaveModel(): Partial<SaleModel> {
        let saleValue = this.formGroup.getRawValue().valueAsNumber.toString();
        if (this.discountType.value === SaleDiscountTypesEnum.Percent) {
            saleValue += '%';
        }

        const formValue =this.formGroup.value;
        return {
            title_de: formValue.title_de,
            title_fr: formValue.title_fr,
            title_en: formValue.title_en,
            title_it: formValue.title_it,
            quantity: formValue.quantity,
            roundTo: formValue.roundTo,
            bonusFactor: formValue.bonusFactor,
            branchId: formValue.branchId,
            priceLevel: formValue.priceLevel,
            promotionCode: formValue.promotionCode,
            allowDeceedBuyingPrice: formValue.allowDeceedBuyingPrice,
            isDiscountLocked: formValue.isDiscountLocked,
            value: saleValue
        };
    }
}
