<div cdkDropList class="w-full" (cdkDropListDropped)="drop($event)">
    @for (image of value; track image.id) {
        <div cdkDrag class="flex gap-2 items-center pl-2 rounded w-full mb-2">
            <mat-icon cdkDragHandle class="cursor-pointer mr-2">drag_indicator</mat-icon>
            <div class="bg-power-search !h-[70px] !w-[90px]">
                @if (image.path) {
                    <img [ngSrc]="image.path" width="90" height="70" [attr.alt]="image.description || ''" class="object-contain h-full" />
                } @else {
                    <img [src]="image | transform : toDataUrl" width="90" height="70" [attr.alt]="image.description || ''"
                        class="object-contain h-full" />
                }
            </div>
            <arc-textarea [ngModel]="image.description" (ngModelChange)="updateImageDescription(image, $event)" label="General.Description">
            </arc-textarea>
            <button class="ml-auto" mat-icon-button (click)="remove(image)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    }
    <arc-file-upload class="block w-full !h-[60px]" fileTypes=".png, .jpg, .jpeg" [shouldForceMinHeight]="false" [formControl]="fileControl"
        [allowUrlUpload]="true" [maxFileSizeInKb]="2048">
    </arc-file-upload>
</div>
