import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { RecordChangesFieldListModel } from '../../models/record-changes-field-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';

@Injectable({
    providedIn: 'root'
})
export class RecordChangesStore extends BaseCrudStore<RecordChangesFieldListModel, RecordChangesFieldListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'record-changes' }));
    }
}
