<h1 mat-dialog-title>
    @if (isAdd) {
        {{ 'PosLayouts.Edit.PosLayoutGroups.DialogTitleAdd' | transloco }}
    } @else {
        {{ 'PosLayouts.Edit.PosLayoutGroups.DialogTitleEdit' | transloco }}
    }
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    @if (isLoading()) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="48"></mat-spinner>
        </div>
    } @else {
        <form [formGroup]="formGroup" class="flex flex-col gap-4 pt-1">
    
            <arc-translation-input labelKey="PosLayouts.Edit.PosLayoutGroups.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
                [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
                [controlIt]="formGroup.controls.title_it">
            </arc-translation-input>
    
            <arc-general-data-select label="PosLayouts.Edit.PosLayoutGroups.RequiredRole" [generalDataType]="GeneralDataTypeEnum.Roles"
                [formControl]="formGroup.controls.requiredRoleId">
            </arc-general-data-select>
    
            <arc-checkbox labelKey="PosLayouts.Edit.PosLayoutGroups.JumpToMainGroup" [control]="formGroup.controls.jumpToMainGroup">
            </arc-checkbox>
    
        </form>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isSaving()" (click)="save()">
        {{ 'General.Actions.Save' | transloco }}
    </arc-button>
</mat-dialog-actions>
