<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-date-range-picker [control]="formGroup.controls.fromDate" [controlEnd]="formGroup.controls.toDate"
        labelKey="Sales.Edit.FromToDate"></arc-date-range-picker>

    <arc-checkbox [control]="formGroup.controls.monday" labelKey="Sales.Edit.Monday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.tuesday" labelKey="Sales.Edit.Tuesday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.wednesday" labelKey="Sales.Edit.Wednesday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.thursday" labelKey="Sales.Edit.Thursday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.friday" labelKey="Sales.Edit.Friday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.saturday" labelKey="Sales.Edit.Saturday"></arc-checkbox>
    <arc-checkbox [control]="formGroup.controls.sunday" labelKey="Sales.Edit.Sunday"></arc-checkbox>

    <div class="flex flex-row gap-4">
        <arc-input type="time" [step]="1" [control]="formGroup.controls.fromTime"
            labelKey="Sales.Edit.FromTime"></arc-input>
        <arc-input type="time" [step]="1" [control]="formGroup.controls.toTime"
            labelKey="Sales.Edit.ToTime"></arc-input>
    </div>
</form>