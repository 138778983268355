import { Component, Input } from '@angular/core';

import { TicketMessageModel } from '../../../../app/models/ticket-message.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { BlobModel } from '../../../../app/models/blob.model';

@Component({
    selector: 'arc-ticket-message-item',
    templateUrl: './ticket-message-item.component.html',
    styleUrl: './ticket-message-item.component.scss'
})
export class TicketMessageItemComponent {
    @Input() message?: TicketMessageModel;

    downloadFile(file: BlobModel): void {
        const blob = new Blob([new Uint8Array(file.blobData)], { type: file.fileMimeType });
        Utils.saveFile(blob, file.fileName ?? 'file.bin');
    }
}
