import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ArticleStockModel } from '../../models/article-stock.model';
import { ArticleStockListModel } from '../../models/responses/article-stock-list.model';
import { ArticleBookingRequestModel } from '../../models/requests/artcle-stock-edit-request.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleStocksStore
    extends BaseCrudStore<ArticleStockModel, ArticleStockListModel, ArticleStockModel, ArticleStockModel, string> {
    constructor() {
        super(new StoreConfig({ baseController: 'article-stocks' }));
    }

    bookArticles(requestModel: ArticleBookingRequestModel): Observable<ApiResponseModel<number>> {
        return this._requestService.makePost<ApiResponseModel<number>>(this.getUrl('book'), requestModel);
    }

    getStock(articleStockId: string): Observable<ApiResponseModel<number>> {
        return this._requestService.makeGet<ApiResponseModel<number>>(this.getUrl(`${articleStockId}/stock`));
    }
}
