import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ArticleModel } from '../../models/article.model';
import { ArticleListModel } from '../../models/responses/article-list.model';
import { ArticleEditModel } from '../../models/requests/article-edit.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionTypeArticlesStore extends BaseCrudStore<ArticleModel, ArticleListModel, ArticleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'articles/without-subscription' }));
    }
}
