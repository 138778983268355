import { Injectable, inject } from '@angular/core';
import { HashMap } from '@ngneat/transloco';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TranslationService } from './translation.service';
import { GeneralPromptDialogComponent } from '../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { GeneralPromptDialogDataModel } from '../../components/dialogs/general-prompt-dialog/models/general-prompt-dialog-data.model';
import { DialogButtonConfigurationEnum } from '../../components/dialogs/general-prompt-dialog/models/enums/dialog-button-configuration.enum';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private readonly translationService = inject(TranslationService);
    private readonly dialog = inject(MatDialog);

    /**
     * Displays an alert.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     * @param messageParams The translation parameters to be used.
     */
    showAlert(message: string, title?: string, isTranslated = false, isHtml = false): MatDialogRef<GeneralPromptDialogComponent> {
        const opt = this.generateOptions(
            message,
            title ?? 'General.Alert.Warning',
            isTranslated,
            isHtml,
            undefined,
            DialogButtonConfigurationEnum.Ok
        );
        return this.dialog.open(GeneralPromptDialogComponent, { data: opt, maxWidth: 450, maxHeight: 350 });
    }

    /**
     * Displays an information.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     * @param messageParams The translation parameters to be used.
     */
    showInfo(message: string, title?: string, isTranslated = false, isHtml = false): MatDialogRef<GeneralPromptDialogComponent> {
        const opt = this.generateOptions(
            message,
            title ?? 'General.Alert.Info',
            isTranslated,
            isHtml,
            undefined,
            DialogButtonConfigurationEnum.Ok
        );
        return this.dialog.open(GeneralPromptDialogComponent, { data: opt, maxWidth: 450, maxHeight: 350 });
    }

    /**
     * Displays an error.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [isHtml] Whether the content has HTML.
     * @param messageParams The translation parameters to be used.
     */
    showError(message: string, title?: string, isTranslated = false, isHtml = false): MatDialogRef<GeneralPromptDialogComponent> {
        const opt = this.generateOptions(
            message,
            title ?? 'General.Alert.Error',
            isTranslated,
            isHtml,
            undefined,
            DialogButtonConfigurationEnum.Ok
        );
        return this.dialog.open(GeneralPromptDialogComponent, { data: opt, maxWidth: 450, maxHeight: 350 });
    }

    /**
     * Displays a success message.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [callback] Callback executed if the user clicks on the button.
     * @param [isHtml] Whether the content has HTML.
     * @param messageParams The translation parameters to be used.
     */
    showSuccess(message: string, title?: string, callback?: any, isTranslated = false, isHtml = false): void {
        const opt = this.generateOptions(
            message,
            title ?? 'General.Alert.Success',
            isTranslated,
            isHtml,
            undefined,
            DialogButtonConfigurationEnum.Ok
        );
        this.dialog
            .open(GeneralPromptDialogComponent, { data: opt, maxWidth: 450, maxHeight: 350 })
            .afterClosed()
            .subscribe((data: boolean) => {
                if (callback && data) {
                    callback();
                }
            });
    }

    /**
     * Displays a message requesting a confirmation from the user.
     * @param message Message to be displayed to the user.
     * @param [title] Message's title.
     * @param [isTranslated] Whether the message/title combo is translated or not.
     * @param [callback] Callback executed if the user clicks on confirm.
     * @param [isHtml] Whether the content has HTML.
     * @param messageParams The translation parameters to be used.
     */
    showConfirm(message: string, title?: string, callback?: any, isTranslated = false, isHtml = false, messageParams?: HashMap): void {
        const opt = this.generateOptions(
            message,
            title ?? 'General.Alert.Confirm',
            isTranslated,
            isHtml,
            undefined,
            DialogButtonConfigurationEnum.ConfirmCancel,
            messageParams
        );

        this.dialog
            .open(GeneralPromptDialogComponent, { data: opt, maxWidth: 450, maxHeight: 350, disableClose: true })
            .afterClosed()
            .subscribe((data: boolean) => {
                if (callback && data) {
                    callback();
                }
            });
    }

    private generateOptions(
        message: string,
        defaultTitle: string,
        isTranslated: boolean,
        isHtml: boolean,
        title?: string,
        dialogButtonConfig?: DialogButtonConfigurationEnum,
        messageParams?: HashMap
    ): GeneralPromptDialogDataModel {
        const text = isTranslated ? message : this.translationService.getText(message, messageParams);

        return {
            promptKey: title ?? defaultTitle,
            messageKey: text,
            isMessageTranslated: isTranslated,
            buttonConfiguration: dialogButtonConfig,
            isMessageHTML: isHtml
        };
    }
}
