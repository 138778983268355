import { Component } from '@angular/core';

import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-amounts-column',
    templateUrl: './amounts-column.component.html',
    styleUrls: ['./amounts-column.component.scss']
})
export class AmountsColumnComponent extends BaseCustomColumnComponent<InvoiceListModel> {}
