import { Component, inject, OnInit, Input } from '@angular/core';
import { ChartData } from 'chart.js';

import { ArticleTurnoverModel } from '../../../../app/models/article-turnover.model';
import { MainArticlesStore } from '../../../../app/services/stores/main-articles.store';
import { DateService } from '../../../../core/services/date.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';

@Component({
    selector: 'arc-article-turnover-widget',
    templateUrl: './article-turnover-widget.component.html',
    styleUrl: './article-turnover-widget.component.scss'
})
export class ArticleTurnoverWidgetComponent implements OnInit {
    @Input() articleId!: number;

    isLoading = true;
    chartData?: ChartData;
    currentData?: ArticleTurnoverModel;

    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly dateService = inject(DateService);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        const turnoverLabel = this.translationService.getText('Articles.Detail.Turnover');

        this.mainArticlesStore.getTurnover(this.articleId).subscribe(r => {
            this.currentData = r.value!;
            this.chartData = {
                labels: this.getLabels(),
                datasets: [
                    {
                        label: turnoverLabel,
                        data: this.getData(this.currentData.last12MonthsTurnover),
                        backgroundColor: 'rgb(12, 66, 69)'
                    }
                ]
            };
            this.isLoading = false;
        });
    }

    private getLabels(): string[] {
        return Object.keys(this.currentData!.last12MonthsTurnover)
            .map(k => `${this.dateService.format(new Date(k), 'MMM')}`.toUpperCase().substring(0, 3));
    }

    private getData(dict: DictionaryType<number>): number[] {
        return Object.values(dict);
    }
}
