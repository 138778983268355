import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { InventoryScanDataModel } from '../../models/inventory-scan-data.model';

@Injectable({
    providedIn: 'root'
})
export class InventoryScansStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'inventory-scans' }));
    }

    getScans(articleId: number, inventoryId: number): Observable<ApiResponseModel<InventoryScanDataModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<InventoryScanDataModel[]>>(this.getUrl(`${articleId}-${inventoryId}/scans`));
    }
}
