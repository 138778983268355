import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';

@Component({
    selector: 'arc-settings-invoices-reminders',
    templateUrl: './settings-invoices-reminders.component.html',
    styleUrl: './settings-invoices-reminders.component.scss'
})
export class SettingsInvoicesRemindersComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    override formGroup = new FormGroup({
        invoiceLateFeeArticleId1: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number()),
        invoiceLateFeeArticleId2: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number())
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            invoiceLateFeeArticleId1: value.invoiceLateFeeArticleId1,
            invoiceLateFeeArticleId2: value.invoiceLateFeeArticleId2
        };
    }
}
