import { Component } from '@angular/core';

import {
    BaseSidebarHeaderAdditionalInfoComponent
} from '../../../../../components/sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';
import { ReservationDetailsModel } from '../../../../models/reservation-details.model';

@Component({
    selector: 'arc-reservation-edit-header-additional-info',
    templateUrl: './reservation-edit-header-additional-info.component.html',
    styleUrl: './reservation-edit-header-additional-info.component.scss'
})
export class ReservationEditHeaderAdditionalInfoComponent extends BaseSidebarHeaderAdditionalInfoComponent<ReservationDetailsModel> {
    onItemSet(): void {
    }
}
