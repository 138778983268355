import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableDetailWidgetDirective } from './table-detail-widget.directive';
import { CardContentDirective } from './card-content.directive';
import { QuickFeedbackDirective } from './quick-feedback.directive';
import { ErrorTooltipDirective } from './error-tooltip.directive';
import { DynamicColumnDirective } from './dynamic-column.directive';
import { PermissionTooltipDirective } from './permission-tooltip.directive';
import { HasPermissionDirective } from './has-permission.directive';
import { PortalTooltipDirective } from './portal-tooltip.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TableDetailWidgetDirective,
        CardContentDirective,
        QuickFeedbackDirective,
        ErrorTooltipDirective,
        DynamicColumnDirective,
        PermissionTooltipDirective,
        HasPermissionDirective,
        PortalTooltipDirective
    ],
    exports: [
        TableDetailWidgetDirective,
        CardContentDirective,
        QuickFeedbackDirective,
        ErrorTooltipDirective,
        DynamicColumnDirective,
        PermissionTooltipDirective,
        HasPermissionDirective,
        PortalTooltipDirective
    ]
})
export class DirectivesModule { }
