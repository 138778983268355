export enum InventoryStatusEnum {
    Open = 0,
    Finished = 1
}
export class InventoryStatusEnumExtensions {
    static getColor(value: InventoryStatusEnum): string {
        switch (value) {
            case InventoryStatusEnum.Open:
                return 'bg-success-light text-on-success-light';
            case InventoryStatusEnum.Finished:
                return 'bg-element-selected text-on-app';
        }
    }
}
