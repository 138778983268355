<h1 mat-dialog-title>
    {{ 'Orders.Edit.Articles.EditDialogTitle' | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <form [formGroup]="formGroup" class="grid grid-cols-2 gap-4 pt-2">

        @if (isDraft) {
            <arc-input labelKey="Orders.Edit.Articles.OrderNumber" [control]="formGroup.controls['orderNumber']"
                class="col-span-2">
            </arc-input>
        } @else {
            <arc-number-input label="Orders.Edit.Articles.DeliveredUnitQuantity"
                [formControl]="formGroup.controls['deliveredUnitQuantity'] | formControl" [unit]="unit">
            </arc-number-input>
            <div></div>
        }

        <arc-currency-input label="Orders.Edit.Articles.BuyingPrice"
            [formControl]="formGroup.controls['orderBuyingPrice'] | formControl">
        </arc-currency-input>
        <div class="flex gap-4 items-center">
            <p>{{ 'Orders.Edit.Articles.BuyingPriceTax' | transloco }}</p>
            <arc-button-toggle-group [control]="formGroup.controls['orderBuyingPriceExclusive'] | formControl" 
                [items]="orderBuyingPriceItems" class="w-full h-full arc-button-toggle-dynamic-height">
            </arc-button-toggle-group>
        </div>

        @if (isDraft) {
            <arc-number-input label="Orders.Edit.Articles.OrderUnitQuantity"
                [formControl]="formGroup.controls['orderUnitQuantity'] | formControl" [unit]="unit">
            </arc-number-input>
            <div></div>
        }

        <arc-checkbox labelKey="Orders.Edit.Articles.UpdateArticle" [control]="formGroup.controls['updateArticle']"
            class="col-span-2">
        </arc-checkbox>
        <arc-textarea label="Orders.Edit.Articles.Notes" [formControl]="formGroup.controls['notes'] | formControl" class="col-span-2">
        </arc-textarea>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isSaving" (clicked)="save()">
        {{ 'General.Actions.Save' | transloco }}
    </arc-button>
</mat-dialog-actions>