import { Component, OnInit, inject } from '@angular/core';

import { UserListModel } from '../../../../../models/responses/user-list.model';
import { UserModel } from '../../../../../models/user.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { BlobsStore } from '../../../../../services/stores/blobs.store';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-user-data',
    templateUrl: './user-data.detail-widget.html',
    styleUrl: './user-data.detail-widget.scss'
})
export class UserDataDetailWidget extends BaseDetailWidget<UserListModel, UserModel> implements OnInit {
    imageSrc = '';

    private readonly blobsStore = inject(BlobsStore);

    ngOnInit(): void {
        this.blobsStore.getFileByName(this.entityModel.profileImageBlobFileName!).subscribe(res => this.imageSrc = res.value!);
    }

    getBase64Src(): string {
        return Utils.getBase64ImageSrc(this.imageSrc);
    }
}
