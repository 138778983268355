import { BaseColumnModel } from './base-column.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class TreeAutocompleteColumnModel extends BaseColumnModel {
    allowSelectionAtLevels: number[] = [2];

    constructor(init?: Partial<TreeAutocompleteColumnModel>) {
        super(ColumnTypeEnum.TreeAutocomplete);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
