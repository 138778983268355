import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';

import { UsersStore } from '../../../services/stores/users.store';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-request-pwd-token',
    templateUrl: './request-password-token.component.html',
    styleUrls: ['./request-password-token.component.scss'],
    providers: [UsersStore]
})
export class RequestPasswordTokenComponent implements OnInit {
    readonly formGroup = new FormGroup({
        email: new ArcFormControl<string>('', [Validators.required, Validators.email, Validators.maxLength(50)])
    });
    wasRequestSent = false;
    subdomain = '';

    private readonly usersStore = inject(UsersStore);
    private readonly router = inject(Router);

    ngOnInit(): void {
        const subdomains = window.location.host.split('.');
        this.subdomain = subdomains[0].includes('arcavis') ? '' : subdomains[0];
    }

    requestResetEmail(): void {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        this.usersStore.requestPasswordChange(this.formGroup.value.email!).subscribe(resp => this.wasRequestSent = resp.value || false);
    }

    returnToLogin(): void {
        this.router.navigate(['login']).then();
    }
}
