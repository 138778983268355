<div class="flex items-center justify-between px-8 bg-app" [class.py-4]="hasPadding()">
    <div class="flex items-center">
        @if(headerService.hasBackButton()){
            <button mat-icon-button class="mb-1 mr-2" (click)="handleBackButtonClick()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        }
        <span class="py-4">{{ headerService.title() }}</span>
        @if(headerService.subtitle()){
            <pre> | </pre>
            <span class="font-bold">{{ headerService.subtitle() }}</span>
        }
    </div>
    <div class="flex items-center justify-between">
        <ng-template [cdkPortalOutlet]="additionalInfoComponentPortal()" (attached)="handleHeaderAdditionalInfoComponentAttached($event)">
        </ng-template>
        @if(headerService.hasCloseButton()){
            <button mat-icon-button class="ml-4" (click)="closeSidebar()">
                <mat-icon>close</mat-icon>
            </button>
        }
    </div>
</div>
