import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { PosLayoutGroupModel } from '../../models/pos-layout-group.model';
import { PosLayoutGroupListModel } from '../../models/responses/pos-layout-group-list.model';
import { PosLayoutGroupCreateModel } from '../../models/requests/pos-layout-group-create.model';
import { PosLayoutGroupUpdateModel } from '../../models/requests/pos-layout-group-update.model';

@Injectable({
    providedIn: 'root'
})
export class PosLayoutGroupsStore extends BaseCrudStore<
PosLayoutGroupModel,
PosLayoutGroupListModel,
PosLayoutGroupCreateModel,
PosLayoutGroupUpdateModel
> {
    constructor() {
        super(new StoreConfig({ baseController: 'pos-layout-groups' }));
    }
}
