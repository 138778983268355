import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { RouteDataModel } from '../../../../core/models/route-data.model';
import { AuthPermissionModel } from '../../../models/auth-permission.model';
import { RouteService } from '../../../../core/services/route.service';

@Component({
    selector: 'arc-no-permission',
    templateUrl: './no-permission.component.html',
    styleUrl: './no-permission.component.scss'
})
export class NoPermissionComponent implements OnInit {
    missingPermission?: AuthPermissionModel;
    routeData?: RouteDataModel;

    private readonly router = inject(Router);
    private readonly location = inject(Location);
    private readonly routeService = inject(RouteService);

    ngOnInit(): void {
        if (!history.state?.missingPermission || !history.state?.routeData) {
            this.router.navigate(['/']);
            return;
        }

        this.missingPermission = history.state.missingPermission;
        this.routeData = history.state.routeData;

        const items = this.routeService.getFilteredRoutes(this.routeData?.subMenu ?? '');
        this.routeService.subMenuItemsSub.next(items);
    }

    back(): void {
        this.location.back();
    }
}
