import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UnitModel } from '../../../../../models/unit.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { UnitEditModesEnum } from '../../../../../models/enums/unit-edit-modes.enum';

@Component({
    selector: 'arc-unit-edit-base-data',
    templateUrl: './unit-edit-base-data.component.html',
    styleUrl: './unit-edit-base-data.component.scss'
})
export class UnitEditBaseDataComponent extends BaseEditSidebarItemComponent<UnitModel> {
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        titleShort_de: new ArcFormControl('', Validators.required),
        titleShort_fr: new ArcFormControl<OptionalType<string>>(undefined),
        titleShort_en: new ArcFormControl<OptionalType<string>>(undefined),
        titleShort_it: new ArcFormControl<OptionalType<string>>(undefined),
        editmode: new ArcFormControl<number>(UnitEditModesEnum.Standard),
        standardQuantity: new ArcFormControl<number>(1, Validators.required),
        tareGrams: new ArcFormControl<OptionalType<number>>(undefined)
    });

    readonly translationService = inject(TranslationService);
    readonly editModeItems = [
        { value: UnitEditModesEnum.Standard, label: this.translationService.getText('Units.Edit.Standard') },
        { value: UnitEditModesEnum.Manual, label: this.translationService.getText('Units.Edit.Manual') },
        { value: UnitEditModesEnum.Scales, label: this.translationService.getText('Units.Edit.Scales') }
    ];

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<UnitModel> {
        const value = this.formGroup.value;
        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            titleShort_de: value.titleShort_de,
            titleShort_fr: value.titleShort_fr,
            titleShort_en: value.titleShort_en,
            titleShort_it: value.titleShort_it,
            editmode: value.editmode,
            standardQuantity: value.standardQuantity,
            tareGrams: value.tareGrams
        };
    }
}
