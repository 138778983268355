import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../../components/abstractions/base-card.component';
import { QueryDesignerListModel } from '../../../../../models/responses/query-designer-list.model';

@Component({
    selector: 'arc-query-designer-card',
    templateUrl: './query-designer-card.component.html',
    styleUrls: ['./query-designer-card.component.scss']
})
export class QueryDesignerCardComponent extends BaseCardComponent<QueryDesignerListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
