import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ArticleStockModel } from '../../models/article-stock.model';
import { ArticleStockListModel } from '../../models/responses/article-stock-list.model';
import { RequestService } from '../../../core/services/request.service';

export class StoreArticlesStore
    extends BaseCrudStore<ArticleStockModel, ArticleStockListModel, ArticleStockModel, ArticleStockModel, string> {
    constructor(requestService: RequestService, storeId: string) {
        super(new StoreConfig({ baseController: `article-stocks/store/${storeId}` }), requestService);
    }
}
