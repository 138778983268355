<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="SubscriptionTypes.EditFields.SubscriptionTypeArticleArticleNumber"
        [control]="formGroup.controls.subscriptionTypeArticleArticleNumber"></arc-input>

    <arc-translation-input labelKey="Articles.EditFields.Title"
        translationDialogTitleKey="General.Actions.TranslateTitle"
        [controlDe]="formGroup.controls.subscriptionTypeArticleTitle_de"
        [controlEn]="formGroup.controls.subscriptionTypeArticleTitle_en"
        [controlFr]="formGroup.controls.subscriptionTypeArticleTitle_fr"
        [controlIt]="formGroup.controls.subscriptionTypeArticleTitle_it">
    </arc-translation-input>

    <div class="grid items-center grid-cols-2 ssm:grid-cols-1 gap-4">
        <arc-tree-autocomplete label="SubscriptionTypes.EditFields.SubscriptionTypeArticleArticleGroup"
            [formControl]="formGroup.controls.subscriptionTypeArticleArticleGroupId"
            [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfig">
        </arc-tree-autocomplete>
        <arc-general-data-select class="grow" label="SubscriptionTypes.EditFields.SubscriptionTypeArticleUnit"
                [generalDataType]="GeneralDataTypeEnum.Units"
                [formControl]="formGroup.controls.subscriptionTypeArticleUnitId"></arc-general-data-select>

        <arc-currency-input label="SubscriptionTypes.EditFields.SubscriptionTypeArticlePrice"
            [formControl]="formGroup.controls.subscriptionTypeArticlePrice"></arc-currency-input>
        <arc-button-toggle-group [control]="formGroup.controls.subscriptionTypeArticleTaxId" [items]="articleTaxes"></arc-button-toggle-group>

        <arc-number-input label="SubscriptionTypes.EditFields.Valid" [formControl]="formGroup.controls.durationValue"
            [unit]="subscriptionValidityUnit && (subscriptionValidityUnit | transloco)" [isUnitTranslated]="true"
            [decimalPlaces]="0" [min]="1"></arc-number-input>
        <arc-button-toggle-group [control]="formGroup.controls.validityDurationToggle" [items]="validityDurationItems"></arc-button-toggle-group>
        <arc-number-input label="SubscriptionTypes.EditFields.SubscriptionQuantity" [decimalPlaces]="0"
            [formControl]="formGroup.controls.subscriptionQuantity"
            [unit]="subscriptionQuantityUnit && (subscriptionQuantityUnit| transloco)"
            [isUnitTranslated]="true"></arc-number-input>
        <arc-button-toggle-group [control]="formGroup.controls.subscriptionQuantityToggle" [items]="subscriptionQuantityItems"></arc-button-toggle-group>
    </div>
</form>
