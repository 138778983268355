/* eslint-disable no-null/no-null */
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function returnPaymentTypeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const askAmountControl = control.get('askAmount');
        const returnPaymentTypeIdControl = control.get('returnPaymentTypeId');

        if (!askAmountControl || !returnPaymentTypeIdControl) {
            return null; // Return if either control is not found
        }
        if (askAmountControl.value && !returnPaymentTypeIdControl.value) {
            returnPaymentTypeIdControl.setErrors({ returnPaymentTypeRequired: true });
            return { returnPaymentTypeRequired: true };
        } else {
            if (returnPaymentTypeIdControl.hasError('returnPaymentTypeRequired')) {
                returnPaymentTypeIdControl.setErrors(null);
            }
        }
        return null;
    };
}
