import { NgModule } from '@angular/core';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatBadgeModule } from '@angular/material/badge';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxColorsModule } from 'ngx-colors';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';

import { MatPaginatorIntlTranslated } from './mat-paginator-intl-translated';
import { DayjsDateAdapter } from './dayjs-date-adapter';

@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatSelectModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatDialogModule,
        OverlayModule,
        MatBadgeModule,
        PortalModule,
        DragDropModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatSortModule,
        MatTabsModule,
        MatTreeModule,
        MatProgressBarModule,
        ClipboardModule,
        NgxColorsModule,
        MatRadioModule,
        MatSliderModule,
        MatRippleModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatSelectModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatDialogModule,
        OverlayModule,
        MatBadgeModule,
        PortalModule,
        DragDropModule,
        MatDividerModule,
        MatDatepickerModule,
        MatTableModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatSortModule,
        MatTabsModule,
        MatTreeModule,
        MatProgressBarModule,
        ClipboardModule,
        NgxColorsModule,
        MatRadioModule,
        MatSliderModule,
        MatRippleModule
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: { hideMultipleSelectionIndicator: true, hideSingleSelectionIndicator: true }
        },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTranslated },
        { provide: DateAdapter, useClass: DayjsDateAdapter },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'L'
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ]
})
export class MaterialModule { }
