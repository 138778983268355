import { BaseColumnModel } from './base-column.model';
import { KeyValueModel } from '../../../../core/models/key-value.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class ButtonToggleColumnModel extends BaseColumnModel {
    options!: KeyValueModel<any>[];

    constructor(init?: Partial<ButtonToggleColumnModel>) {
        super(ColumnTypeEnum.ButtonToggle);
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
