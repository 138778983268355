import { Component, Input, WritableSignal, signal } from '@angular/core';
import { Observable } from 'rxjs';

import { PosLayoutModel } from '../../../../../models/pos-layout.model';

@Component({
    selector: 'arc-pos-layout-edit-top-left',
    templateUrl: './pos-layout-edit-top-left.component.html',
    styleUrl: './pos-layout-edit-top-left.component.scss'
})
export class PosLayoutEditTopLeftComponent {
    @Input({ required: true }) posLayout!: WritableSignal<PosLayoutModel>;
    @Input({ required: true }) toggleShowUnitFn!: () => Observable<any>;
    @Input({ required: true }) toggleShowTaxRateFn!: () => Observable<any>;

    isUpdatingShowUnit = signal(false);
    isUpdatingShowTaxRate = signal(false);

    toggleShowUnit(): void {
        this.isUpdatingShowUnit.set(true);
        this.toggleShowUnitFn().subscribe().add(() => this.isUpdatingShowUnit.set(false));
    }

    toggleShowTaxRate(): void {
        this.isUpdatingShowTaxRate.set(true);
        this.toggleShowTaxRateFn().subscribe().add(() => this.isUpdatingShowTaxRate.set(false));
    }
}
