import { Directive, Input, inject, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';

import { PermissionService } from '../services/permission.service';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { NoPermissionLockComponent } from '../../components/no-permission-lock/no-permission-lock.component';
import { PermissionTypeEnum } from '../models/enums/permission-type.enum';

/**
 * Usage:
 * *arcHasPermission="PermissionsEnum.Articles; type: PermissionTypeEnum.Create; iconSize: 128"
 *
 * (type and iconSize are optional)
 */
@Directive({
    selector: '[arcHasPermission]'
})
export class HasPermissionDirective implements OnChanges {
    @Input({ alias: 'arcHasPermission', required: true }) requiredPermission!: PermissionsEnum;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input({ alias: 'arcHasPermissionType' }) requiredPermissionType?: PermissionTypeEnum;
    @Input({ alias: 'arcHasPermissionIconSize' }) iconSize = 24;

    permission: true | AuthPermissionModel = true;
    isShowingTpl?: boolean;

    private readonly permissionService = inject(PermissionService);
    private readonly templateRef =inject(TemplateRef);
    private readonly viewContainer =inject(ViewContainerRef);

    ngOnChanges(): void {
        this.permission = this.permissionService.hasPermission(this.requiredPermission, this.requiredPermissionType);
        if (this.permission === true && this.isShowingTpl !== true) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isShowingTpl = true;
        } else if (this.permission !== true && this.isShowingTpl !== false) {
            this.viewContainer.clear();
            const ref = this.viewContainer.createComponent(NoPermissionLockComponent);
            ref.setInput('missingPermission', this.permission);
            ref.setInput('iconSize', this.iconSize);
            this.isShowingTpl = false;
        }
    }
}
