import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { ImportService } from '../../../../core/services/import.service';
import { ImportStepBase } from '../models/import-step-base';
import { ImportEntityColumnModel } from '../../../../app/models/import-entity-column.model';
import { ImportExecutionColumnModel } from '../../../../app/models/import-execution-column.model';

@Component({
    selector: 'arc-step2-mapping',
    templateUrl: './step2-mapping.component.html',
    styleUrls: ['./step2-mapping.component.scss']
})
export class Step2MappingComponent extends ImportStepBase {
    form = new FormGroup({});

    override changes = this.form.valueChanges;

    requiredColumns: ImportEntityColumnModel[] = [];
    additionalColumns: ImportEntityColumnModel[] = [];
    translationColumns: ImportEntityColumnModel[] = [];
    importFileColumns: string[] = [];

    hasAdditionalMappingSuggestion = false;
    hasTranslationMappingSuggestion = false;
    isAdditionalColumnsOpen = false;
    isTranslationColumnsOpen = false;

    private changeSub?: Subscription;

    private readonly importService = inject(ImportService);

    init(): void {
        this.changeSub?.unsubscribe();

        for (const [controlName] of Object.entries(this.form.controls)) {
            this.form.removeControl(controlName);
        }
        for (const column of this.importService.entityImportColumns) {
            const validators = column.isRequired ? [Validators.required] : [];
            this.form.addControl(column.id, new ArcFormControl('', validators));
        }
        this.requiredColumns = this.importService.entityImportColumns.filter(c => c.isRequired);
        this.additionalColumns = this.importService.entityImportColumns.filter(c => !c.isRequired && !c.isTranslation);
        this.translationColumns = this.importService.entityImportColumns.filter(c => !c.isRequired && c.isTranslation);
        this.importFileColumns = this.importService.fileColumns;

        // set existing mapping
        this.form.patchValue(this.importService.fileMapping.reduce(
            (acc, mapping) => ({ ...acc, [mapping.importEntityColumnId]: mapping.fileColumnName }),
            {}
        ));

        const columnsWithMappingSuggestion = this.importService.fileMapping
            .filter(m => !!m.fileColumnName)
            .map(m => this.importService.entityImportColumns.find(c => c.id === m.importEntityColumnId));
        this.hasAdditionalMappingSuggestion = columnsWithMappingSuggestion.some(c => !!c && !c.isRequired && !c.isTranslation);
        this.hasTranslationMappingSuggestion = columnsWithMappingSuggestion.some(c => !!c && !c.isRequired && c.isTranslation);

        // form change handler
        this.changeSub = this.form.valueChanges.subscribe(() => this.saveMapping());
    }

    allowNext(): boolean {
        this.form.updateValueAndValidity({ emitEvent: false });
        return this.form.valid;
    }
    allowPrevious = (): boolean => true;
    allowCancel = (): boolean => true;

    next(): Observable<any> {
        this.saveMapping();
        return this.importService.preview();
    }

    private saveMapping(): void {
        this.importService.fileMapping = [];
        for (const column of this.importService.entityImportColumns) {
            const value = (this.form.value as any)[column.id];
            if (!!value) {
                this.importService.fileMapping.push(new ImportExecutionColumnModel(column.id, value, column.isIdentifier));
            }
        }
    }
}
