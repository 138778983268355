import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from '../../../core/services/auth.service';

@Component({
    selector: 'arc-locked-dialog',
    templateUrl: './locked-dialog.component.html',
    styleUrl: './locked-dialog.component.scss'
})
export class LockedDialogComponent implements OnInit {
    message = '';

    private readonly authService = inject(AuthService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly data: { message: string } = inject(MAT_DIALOG_DATA);

    ngOnInit(): void {
        this.message = this.data.message || '';
    }

    logout(): void {
        this.authService.logout();
        this.matDialogRef.close();
    }
}
