import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ArticleStockListModel } from '../../../models/responses/article-stock-list.model';
import { ArticleStockModel } from '../../../models/article-stock.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { ArticleStocksStore } from '../../../services/stores/article-stocks.store';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ArticleStockEditBaseDataComponent } from './article-stock-edit-items/article-stock-edit-base-data/article-stock-edit-base-data.component';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { ArticleStockPicturesDetailWidget } from './article-stocks-detail-widgets/article-stock-pictures/article-stock-pictures.detail-widget';
import { ArticleStockDescriptionDetailWidget } from './article-stocks-detail-widgets/article-stock-description/article-stock-description.detail-widget';
import { ArticleStockArticleDetailsDetailWidget } from './article-stocks-detail-widgets/article-stock-article-details/article-stock-article-details.detail-widget';
import { ArticleStockDataDetailWidget } from './article-stocks-detail-widgets/article-stock-data/article-stock-data.detail-widget';
import { ArticleStockEditModel } from '../../../models/article-stock-edit.model';

@Component({
    selector: 'arc-article-stocks',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./article-stocks.component.scss']
})
export class ArticleStocksComponent extends PageListViewComponent<
ArticleStockListModel,
ArticleStockModel,
ArticleStockEditModel,
ArticleStockEditModel,
string
> {
    private readonly articleStocksStore = inject(ArticleStocksStore);
    constructor() {
        super('ArticleStocks');
        this.config = new TableListViewConfigModel<
        ArticleStockListModel,
        ArticleStockModel,
        ArticleStockEditModel,
        ArticleStockEditModel,
        string
        >({
            entityName: 'ArticleStocks',
            availableColumns: {
                article: new StackedColumnModel({
                    columnTitleKey: 'ArticleStocks.List.Article',
                    propertyName: 'articleTitle',
                    propertyName2: 'articleNumber',
                    widthPixels: 236
                }),
                storeName: new StringColumnModel({
                    columnTitleKey: 'ArticleStocks.List.StoreName',
                    propertyName: 'storeName',
                    widthPixels: 250
                }),
                stock: new StringColumnModel({
                    columnTitleKey: 'ArticleStocks.List.Stock',
                    propertyName: 'stock',
                    widthPixels: 126
                }),
                orderedStock: new StringColumnModel({
                    columnTitleKey: 'ArticleStocks.List.OrderedStock',
                    propertyName: 'orderedStock',
                    widthPixels: 126
                }),
                minStock: new StringColumnModel({
                    columnTitleKey: 'ArticleStocks.List.MinStock',
                    propertyName: 'minStock',
                    widthPixels: 126
                }),
                targetStock: new StringColumnModel({
                    columnTitleKey: 'ArticleStocks.List.TargetStock',
                    propertyName: 'targetStock',
                    widthPixels: 126
                }),
                stockValueBuyingprice: new CurrencyColumnModel({
                    columnTitleKey: 'ArticleStocks.List.StockValueBuyingPrice',
                    propertyName: 'stockValueBuyingPrice',
                    widthPixels: 126
                }),
                stockValue: new CurrencyColumnModel({
                    columnTitleKey: 'ArticleStocks.List.StockValue',
                    propertyName: 'stockValue',
                    widthPixels: 126
                })
            },
            defaultColumnOrder: [
                'article',
                'storeName',
                'stock',
                'orderedStock',
                'minStock',
                'targetStock',
                'stockValueBuyingprice',
                'stockValue'
            ],
            availableDetailWidgets: {
                pictures: new TableListDetailWidgetModel({
                    name: 'ArticleStocks.Detail.PicturesWidget',
                    component: ArticleStockPicturesDetailWidget
                }),
                stock: new TableListDetailWidgetModel({
                    name: 'ArticleStocks.Detail.StockWidget',
                    component: ArticleStockDataDetailWidget
                }),
                description: new TableListDetailWidgetModel({
                    name: 'ArticleStocks.Detail.DescriptionWidget',
                    component: ArticleStockDescriptionDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.DetailsWidget',
                    component: ArticleStockArticleDetailsDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['pictures', 'stock', 'details', 'description'],
            store: this.articleStocksStore,
            editSidebarConfig: {
                editComponents: [{ titleKey: 'ArticleStocks.Edit.BaseData', component: ArticleStockEditBaseDataComponent }]
            }
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService
            .updateIsHidden(ActionButtonsService.deleteButtonKey, () => true)
            .updateIsHidden(ActionButtonsService.createButtonKey, () => true);
    }
}
