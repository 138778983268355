import { Component, OnInit, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../components/abstractions/base.detail-widget';
import { ReservationListModel } from '../../../../models/responses/reservation-list.model';
import { ReservationDetailsModel } from '../../../../models/reservation-details.model';
import { NumberPipe } from '../../../../../core/pipes/number.pipe';
import { StaticTableConfigModel } from '../../../../../components/list-views/static-table/models/static-table-config.model';
import { ReservationArticleListModel } from '../../../../models/responses/reservation-articles-list.model';
import { UserService } from '../../../../../core/services/user.service';
import { ReservationArticlesStore } from '../../../../services/stores/reservation-articles.store';
import { SearchRequestModel } from '../../../../models/requests/search-request.model';
import { ColumnFilterModel } from '../../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../core/models/enums/filter-item-type.enum';
import { ColumnSortModel } from '../../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../../components/dynamic-table/models/column-types/string-column.model';

@Component({
    selector: 'arc-reservation-articles',
    templateUrl: './reservation-articles.detail-widget.html',
    styleUrl: './reservation-articles.detail-widget.scss',
    providers: [NumberPipe]
})
export class ReservationArticlesDetailWidget extends BaseDetailWidget<ReservationListModel, ReservationDetailsModel> implements OnInit {
    tableConfig?: StaticTableConfigModel<ReservationArticleListModel>;
    isLoading = true;

    private readonly transactionArticlesStore = inject(ReservationArticlesStore);
    private readonly userService = inject(UserService);
    private readonly numberPipe = inject(NumberPipe);

    ngOnInit(): void {
        const currencyCode = this.userService.getUserInfo()!.currencyIsoCode;
        this.transactionArticlesStore
            .search(
                new SearchRequestModel({
                    pageSize: 999,
                    filters: [
                        new ColumnFilterModel({
                            column: 'ReservationId',
                            values: [this.listModel.id],
                            comparisonOperator: ComparisonOperatorsEnum.Equal,
                            dataType: FilterItemTypeEnum.Number
                        })
                    ],
                    sortings: [
                        new ColumnSortModel({
                            column: 'Sort',
                            direction: SortDirectionEnum.Asc
                        })
                    ]
                })
            )
            .subscribe(r => {
                this.tableConfig = new StaticTableConfigModel<ReservationArticleListModel>({
                    data: r.value?.records || [],
                    defaultPageSize: 5,
                    availableColumns: [
                        new StringColumnModel({
                            columnTitleKey: 'ReservationArticles.List.Quantity',
                            propertyName: 'quantity',
                            widthPixels: 40,
                            isCompact: true
                        }),
                        new StringColumnModel({
                            columnTitleKey: 'ReservationArticles.List.Article',
                            propertyName: 'title',
                            widthPixels: 180,
                            isCompact: true
                        }),
                        new StringColumnModel({
                            columnTitleKey: 'ReservationArticles.List.Discount',
                            propertyName: 'discount',
                            widthPixels: 60,
                            columnTitleSuffix: currencyCode,
                            isCompact: true,
                            customFormatter: (record, value) => this.numberPipe.transform(value, 2)
                        }),
                        new StringColumnModel({
                            columnTitleKey: 'ReservationArticles.List.UnitPrice',
                            propertyName: 'unitPrice',
                            widthPixels: 60,
                            columnTitleSuffix: currencyCode,
                            isCompact: true,
                            customFormatter: (record, value) => this.numberPipe.transform(value, 2)
                        })
                    ]
                });
                this.isLoading = false;
            });
    }
}
