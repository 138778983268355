import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { QuillModule } from 'ngx-quill';
import { MarkdownModule } from 'ngx-markdown';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { InputComponent } from './form/input/input.component';
import { MaterialModule } from '../core/utils/material.module';
import { FormFieldErrorControlComponent } from './form/form-field-error-control/form-field-error-control.component';
import { StatisticsWidgetComponent } from './widgets/statistics-widget/statistics-widget.component';
import { ChartComponent } from './chart/chart.component';
import { TableListViewComponent } from './list-views/table-list-view/table-list-view.component';
import { ButtonColumnComponent } from './dynamic-table/column-components/button-column/button-column.component';
import { ButtonToggleColumnComponent } from './dynamic-table/column-components/button-toggle-column/button-toggle-column.component';
import { CheckboxColumnComponent } from './dynamic-table/column-components/checkbox-column/checkbox-column.component';
import { ColoredStackedColumnComponent } from './dynamic-table/column-components/colored-stacked-column/colored-stacked-column.component';
import { CurrencyColumnComponent } from './dynamic-table/column-components/currency-column/currency-column.component';
import { CustomColumnComponent } from './dynamic-table/column-components/custom-column/custom-column.component';
import { DateColumnComponent } from './dynamic-table/column-components/date-column/date-column.component';
import { GeneralDataColumnComponent } from './dynamic-table/column-components/general-data-column/general-data-column.component';
import { GenericStatusColumnComponent } from './dynamic-table/column-components/generic-status-column/generic-status-column.component';
import { IconColumnComponent } from './dynamic-table/column-components/icon-column/icon-column.component';
import { NumberColumnComponent } from './dynamic-table/column-components/number-column/number-column.component';
import { QuickSearchColumnComponent } from './dynamic-table/column-components/quick-search-column/quick-search-column.component';
import { StackedColumnComponent } from './dynamic-table/column-components/stacked-column/stacked-column.component';
import { StringColumnComponent } from './dynamic-table/column-components/string-column/string-column.component';
import { CardListViewComponent } from './list-views/card-list-view/card-list-view.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { FilterComponent } from './search-filter/filter/filter.component';
import { FilterPartComponent } from './search-filter/filter/filter-part/filter-part.component';
import { StatisticsWidgetOptionsDialogComponent } from './widgets/statistics-widget/statistics-widget-options-dialog/statistics-widget-options-dialog.component';
import { EditSidebarComponent } from './sidebar-components/edit-sidebar/edit-sidebar.component';
import { EditSidebarItemComponent } from './sidebar-components/edit-sidebar/edit-sidebar-item/edit-sidebar-item.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { SaveFilterDialogComponent } from './search-filter/filter/save-filter-dialog/save-filter-dialog.component';
import { DatepickerComponent } from './form/datepicker/datepicker.component';
import { AutocompleteComponent } from './form/autocomplete/autocomplete.component';
import { SelectComponent } from './form/select/select.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { DateRangePickerComponent } from './form/date-range-picker/date-range-picker.component';
import { FileUploadComponent } from './form/file-upload/file-upload.component';
import { IconComponent } from './icon/icon.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ReportParametersDialogComponent } from './reports/report-parameters-dialog/report-parameters-dialog.component';
import { ImportDialogComponent } from './dialogs/import-dialog/import-dialog.component';
import { GeneralPromptDialogComponent } from './dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { Step1UploadFileComponent } from './dialogs/import-dialog/step1-upload-file/step1-upload-file.component';
import { Step2MappingComponent } from './dialogs/import-dialog/step2-mapping/step2-mapping.component';
import { Step3PreviewExecuteComponent } from './dialogs/import-dialog/step3-preview-execute/step3-preview-execute.component';
import { Step4ResultComponent } from './dialogs/import-dialog/step4-result/step4-result.component';
import { FabComponent } from './fab/fab.component';
import { DynamicFormDialogComponent } from './dynamic-form-dialog/dynamic-form-dialog.component';
import { ButtonsBarComponent } from './buttons-bar/buttons-bar.component';
import { TableSettingsDialogComponent } from './list-views/table-list-view/table-settings-dialog/table-settings-dialog.component';
import { GeneralDataSelectComponent } from './form/general-data-select/general-data-select.component';
import { PlaceSelectComponent } from './form/place-select/place-select.component';
import { StaticTableComponent } from './list-views/static-table/static-table.component';
import { FeaturesWidgetComponent } from './widgets/features-widget/features-widget.component';
import { FeatureDetailsDialogComponent } from './widgets/features-widget/feature-details-dialog/feature-details-dialog.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { RichTextEditorFormComponent } from './form/rich-text-editor-form/rich-text-editor-form.component';
import { ImportantMessagesDialogComponent } from './dialogs/important-messages-dialog/important-messages-dialog.component';
import { QuickSearchComponent } from './form/quick-search/quick-search.component';
import { DataSelectionDialogComponent } from './dialogs/data-selection-dialog/data-selection-dialog.component';
import { CarouselComponent } from './carousel/carousel.component';
import { QuickFeedbackComponent } from './quick-feedback/quick-feedback.component';
import { AddressPickerComponent } from './form/address-picker/address-picker.component';
import { TreeDataSelectionDialogComponent } from './dialogs/tree-data-selection-dialog/tree-data-selection-dialog.component';
import { TreeAutocompleteComponent } from './form/tree-autocomplete/tree-autocomplete.component';
import { CustomerDataComponent } from './widgets/shared-detail-widgets/customer-data/customer-data.component';
import { ChipSelectorComponent } from './form/chip-selector/chip-selector.component';
import { PaymentsBreakdownComponent } from './widgets/shared-detail-widgets/payments-breakdown/payments-breakdown.component';
import { NoPermissionLockComponent } from './no-permission-lock/no-permission-lock.component';
import { EditableTableComponent } from './form/editable-table/editable-table.component';
import { DescriptionWidgetComponent } from './widgets/shared-detail-widgets/description-widget/description-widget.component';
import { ImagesWidgetComponent } from './widgets/shared-detail-widgets/images-widget/images-widget.component';
import { ButtonComponent } from './button/button.component';
import { TranslationDialogComponent } from './dialogs/translation-dialog/translation-dialog.component';
import { TranslationInputComponent } from './form/translation-input/translation-input.component';
import { NumberInputComponent } from './form/number-input/number-input.component';
import { CurrencyInputComponent } from './form/currency-input/currency-input.component';
import { Step2MappingGroupComponent } from './dialogs/import-dialog/step2-mapping/step2-mapping-group/step2-mapping-group.component';
import { RelatedEntityAssignmentComponent } from './related-entity-assignment/related-entity-assignment.component';
import { HelpComponent } from './help/help.component';
import { TransactionsListWidgetComponent } from './widgets/shared-detail-widgets/transactions-list-widget/transactions-list-widget.component';
import { PosReportParametersDialogComponent } from './pos-reports/pos-report-parameters-dialog/pos-report-parameters-dialog.component';
import { CamtFileDialogComponent } from './dialogs/camt-file-dialog/camt-file-dialog.component';
import { CamtStatusColumnComponent } from './dialogs/camt-file-dialog/camt-status-column/camt-status-column.component';
import { SupplierDataComponent } from './widgets/shared-detail-widgets/supplier-data/supplier-data.component';
import { LoginVisualComponent } from './login/login-visual/login-visual.component';
import { AddImageFromUrlDialogComponent } from './form/file-upload/add-url-dialog/add-image-from-url-dialog.component';
import { OrderEditHeaderAdditionalInfoComponent } from '../app/views/stores/orders/order-edit-header-additional-info/order-edit-header-additional-info.component';
import { OrderArticleEditDialogComponent } from '../app/views/stores/orders/order-edit-items/order-edit-articles/order-article-edit-dialog/order-article-edit-dialog.component';
import { BulkSendEditorComponent } from './bulk-send-editor/bulk-send-editor.component';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { TranslationStringColumnComponent } from './dynamic-table/column-components/translation-string-column/translation-string-column.component';
import { StatusMessageComponent } from './status-message/status-message.component';
import { LockedDialogComponent } from './dialogs/locked-dialog/locked-dialog.component';
import { ArticleDetailsWidgetComponent } from './widgets/shared-detail-widgets/article-details-widget/article-details-widget.component';
import { ArticleStockWidgetComponent } from './widgets/shared-detail-widgets/article-stock-widget/article-stock-widget.component';
import { LicensingOptionsDialogComponent } from './dialogs/licensing-options-dialog/licensing-options-dialog.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ContractsAcceptDialogComponent } from './dialogs/contracts-accept-dialog/contracts-accept-dialog.component';
import { ArticleTurnoverWidgetComponent } from './widgets/shared-detail-widgets/article-turnover-widget/article-turnover-widget.component';
import { CreateTicketComponent } from './help/tickets/create-ticket/create-ticket.component';
import { ViewTicketComponent } from './help/tickets/view-ticket/view-ticket.component';
import { CreateTicketResponseComponent } from './help/tickets/create-ticket-response/create-ticket-response.component';
import { CreateTicketRequestComponent } from './help/tickets/create-ticket-request/create-ticket-request.component';
import { TicketMessageItemComponent } from './help/tickets/ticket-message-item/ticket-message-item.component';
import { NewTicketComponent } from './help/tickets/new-ticket/new-ticket.component';
import { SelectedHelpArticleComponent } from './help/articles/selected-help-article/selected-help-article.component';
import { HelpArticleListItemComponent } from './help/articles/help-article-list-item/help-article-list-item.component';
import { TicketListItemComponent } from './help/tickets/ticket-list-item/ticket-list-item.component';
import { TicketEditHeaderAdditionalInfoComponent } from './help/tickets/ticket-edit-header-additional-info/ticket-edit-header-additional-info.component';
import { TicketStatusComponent } from './help/tickets/ticket-status/ticket-status.component';
import { EmailVerificationDialogComponent } from './dialogs/email-verification-dialog/email-verification-dialog.component';
import { TicketsComponent } from '../app/components/tickets/tickets.component';
import { EventMessageItemComponent } from './help/tickets/event-message-item/event-message-item.component';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { SelectionSliderComponent } from './form/selection-slider/selection-slider.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslocoModule,
        DirectivesModule,
        PipesModule,
        NgOptimizedImage,
        QuillModule.forRoot(),
        MarkdownModule.forRoot(),
        FlatpickrModule.forRoot(),
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        StatisticsWidgetComponent,
        InputComponent,
        FormFieldErrorControlComponent,
        ChartComponent,
        TableListViewComponent,
        SearchFilterComponent,
        FilterComponent,
        FilterPartComponent,
        StatisticsWidgetOptionsDialogComponent,
        CardListViewComponent,
        ButtonColumnComponent,
        ButtonToggleColumnComponent,
        CheckboxColumnComponent,
        ColoredStackedColumnComponent,
        CurrencyColumnComponent,
        CustomColumnComponent,
        DateColumnComponent,
        GeneralDataColumnComponent,
        GenericStatusColumnComponent,
        IconColumnComponent,
        NumberColumnComponent,
        QuickSearchColumnComponent,
        StackedColumnComponent,
        StringColumnComponent,
        EditSidebarComponent,
        EditSidebarItemComponent,
        FilterComponent,
        SaveFilterDialogComponent,
        DatepickerComponent,
        AutocompleteComponent,
        SelectComponent,
        CheckboxComponent,
        DateRangePickerComponent,
        FileUploadComponent,
        IconComponent,
        DynamicFormComponent,
        ReportParametersDialogComponent,
        ImportDialogComponent,
        GeneralPromptDialogComponent,
        Step1UploadFileComponent,
        Step2MappingComponent,
        Step3PreviewExecuteComponent,
        Step4ResultComponent,
        FabComponent,
        DynamicFormDialogComponent,
        ButtonsBarComponent,
        TableSettingsDialogComponent,
        GeneralDataSelectComponent,
        PlaceSelectComponent,
        StaticTableComponent,
        FeaturesWidgetComponent,
        FeatureDetailsDialogComponent,
        RichTextEditorComponent,
        TextareaComponent,
        RichTextEditorFormComponent,
        ImportantMessagesDialogComponent,
        QuickSearchComponent,
        DataSelectionDialogComponent,
        CarouselComponent,
        QuickFeedbackComponent,
        AddressPickerComponent,
        TreeDataSelectionDialogComponent,
        TreeAutocompleteComponent,
        CustomerDataComponent,
        ChipSelectorComponent,
        PaymentsBreakdownComponent,
        NoPermissionLockComponent,
        EditableTableComponent,
        DescriptionWidgetComponent,
        ImagesWidgetComponent,
        ButtonComponent,
        TranslationDialogComponent,
        TranslationInputComponent,
        NumberInputComponent,
        CurrencyInputComponent,
        Step2MappingGroupComponent,
        RelatedEntityAssignmentComponent,
        HelpComponent,
        TransactionsListWidgetComponent,
        PosReportParametersDialogComponent,
        GenericStatusColumnComponent,
        CamtFileDialogComponent,
        CamtStatusColumnComponent,
        SupplierDataComponent,
        LoginVisualComponent,
        AddImageFromUrlDialogComponent,
        OrderEditHeaderAdditionalInfoComponent,
        OrderArticleEditDialogComponent,
        BulkSendEditorComponent,
        MarkdownEditorComponent,
        TranslationStringColumnComponent,
        StatusMessageComponent,
        LockedDialogComponent,
        ArticleDetailsWidgetComponent,
        ArticleStockWidgetComponent,
        LicensingOptionsDialogComponent,
        ColorPickerComponent,
        ContractsAcceptDialogComponent,
        ArticleTurnoverWidgetComponent,
        CreateTicketComponent,
        ViewTicketComponent,
        CreateTicketResponseComponent,
        CreateTicketRequestComponent,
        TicketMessageItemComponent,
        NewTicketComponent,
        SelectedHelpArticleComponent,
        HelpArticleListItemComponent,
        TicketListItemComponent,
        TicketEditHeaderAdditionalInfoComponent,
        TicketStatusComponent,
        EmailVerificationDialogComponent,
        TicketsComponent,
        EventMessageItemComponent,
        ButtonToggleGroupComponent,
        SelectionSliderComponent
    ],
    exports: [
        InputComponent,
        FormFieldErrorControlComponent,
        StatisticsWidgetComponent,
        TableListViewComponent,
        CardListViewComponent,
        DatepickerComponent,
        AutocompleteComponent,
        SelectComponent,
        CheckboxComponent,
        DateRangePickerComponent,
        FileUploadComponent,
        IconComponent,
        DynamicFormComponent,
        FabComponent,
        DynamicFormDialogComponent,
        ButtonsBarComponent,
        GeneralDataSelectComponent,
        PlaceSelectComponent,
        StaticTableComponent,
        ChartComponent,
        FeaturesWidgetComponent,
        RichTextEditorComponent,
        TextareaComponent,
        RichTextEditorFormComponent,
        QuickSearchComponent,
        CarouselComponent,
        AddressPickerComponent,
        TreeDataSelectionDialogComponent,
        TreeAutocompleteComponent,
        CustomerDataComponent,
        ChipSelectorComponent,
        PaymentsBreakdownComponent,
        NoPermissionLockComponent,
        EditableTableComponent,
        DescriptionWidgetComponent,
        ImagesWidgetComponent,
        ButtonComponent,
        TranslationInputComponent,
        NumberInputComponent,
        CurrencyInputComponent,
        RelatedEntityAssignmentComponent,
        TransactionsListWidgetComponent,
        SupplierDataComponent,
        LoginVisualComponent,
        AddImageFromUrlDialogComponent,
        SearchFilterComponent,
        OrderArticleEditDialogComponent,
        StatusMessageComponent,
        ArticleDetailsWidgetComponent,
        ArticleStockWidgetComponent,
        ColorPickerComponent,
        ArticleTurnoverWidgetComponent,
        ButtonToggleGroupComponent,
        SelectionSliderComponent
    ],
    providers: [
        provideNgxMask()
    ]
})
export class ComponentsModule { }
