import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../components/abstractions/base.detail-widget';
import { ReservationListModel } from '../../../../models/responses/reservation-list.model';
import { ReservationDetailsModel } from '../../../../models/reservation-details.model';

@Component({
    selector: 'arc-reservation-customer',
    templateUrl: './reservation-customer.detail-widget.html',
    styleUrl: './reservation-customer.detail-widget.scss'
})
export class ReservationCustomerDetailWidget extends BaseDetailWidget<ReservationListModel, ReservationDetailsModel> {}
