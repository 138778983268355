import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { TransactionArticleModel } from '../../../../../models/transaction-article.model';

@Component({
    selector: 'arc-transaction-article-description',
    templateUrl: './transaction-article-description.detail-widget.html',
    styleUrls: ['./transaction-article-description.detail-widget.scss']
})
export class TransactionArticleDescriptionDetailWidget extends BaseDetailWidget<TransactionArticleListModel, TransactionArticleModel> {}
