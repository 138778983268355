import { Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';

import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PosLayoutGroupsStore } from '../../../../../services/stores/pos-layout-groups.store';
import { PosLayoutEditTabDialogDataCreateModel } from './models/pos-layout-edit-tab-dialog-data-create.model';
import { PosLayoutEditTabDialogDataUpdateModel } from './models/pos-layout-edit-tab-dialog-data-update.model';

@Component({
    selector: 'arc-pos-layout-edit-tab-dialog',
    templateUrl: './pos-layout-edit-tab-dialog.component.html',
    styleUrl: './pos-layout-edit-tab-dialog.component.scss'
})
export class PosLayoutEditTabDialogComponent {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    dialogData: PosLayoutEditTabDialogDataCreateModel | PosLayoutEditTabDialogDataUpdateModel = inject(MAT_DIALOG_DATA);

    isLoading = signal(false);
    isSaving = signal(false);

    isAdd: boolean;

    formGroup = new FormGroup({
        title_de: new ArcFormControl<string>('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        requiredRoleId: new ArcFormControl<OptionalType<number>>(undefined),
        jumpToMainGroup: new ArcFormControl<boolean>(false)
    });

    matDialogRef = inject(MatDialogRef);

    private readonly posLayoutGroupsStore = inject(PosLayoutGroupsStore);

    constructor() {
        this.isAdd = !('posLayoutGroupId' in this.dialogData);
        if ('posLayoutGroupId' in this.dialogData) {
            this.isLoading.set(true);
            this.posLayoutGroupsStore.get(this.dialogData.posLayoutGroupId).subscribe(result => {
                if (!result.value) {
                    this.matDialogRef.close();
                    return;
                }

                this.formGroup.patchValue(result.value ?? {});
                this.isLoading.set(false);
            });
        }
    }

    save(): void {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        if ('posLayoutGroupId' in this.dialogData) {
            this.posLayoutGroupsStore
                .edit({
                    id: this.dialogData.posLayoutGroupId!,
                    ...this.formGroup.getRawValue()
                })
                .subscribe(() => this.matDialogRef.close(true));
        } else {
            this.posLayoutGroupsStore
                .add({
                    id: 0,
                    posLayoutId: this.dialogData.posLayoutId!,
                    isBottomPanel: this.dialogData.isBottomPanel!,
                    ...this.formGroup.getRawValue()
                })
                .subscribe(() => this.matDialogRef.close(true));
        }
    }
}
