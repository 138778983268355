import { Signal } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';
import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';

export class ButtonColumnModel<T extends object> extends BaseColumnModel {
    onClickFunction!: (item: T, formGroup?: FormGroup<{ [K in keyof T]: AbstractControl }>) => void;
    icon?: (item: T) => string;
    bgColor?: string;
    fontColor?: string;
    actionButtonKey?: string;
    actionButtonTitleKey = '';
    isIcon = false;
    contextActionsStore?: BaseSearchStore<any>;
    prefix?: (item: T) => string;
    suffix?: (item: T) => string;
    hideButton?: (item: T) => boolean;
    isButtonDisabled?: (data: T) => boolean;
    isLoading?: (data?: T) => Signal<boolean>;

    constructor(init?: Partial<ButtonColumnModel<T>>) {
        super(ColumnTypeEnum.Button);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
