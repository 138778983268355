import { BaseColumnModel } from './base-column.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';
import { UnitsEnum } from '../../../../core/models/enums/units.enum';

export class NumberColumnModel extends BaseColumnModel {
    decimalPlaces?: number;
    unit?: string | UnitsEnum | ((record: any) => string | UnitsEnum);
    isUnitTranslated = false;
    isEditableFn?: (record: any) => boolean;
    constructor(init?: Partial<NumberColumnModel>) {
        super(ColumnTypeEnum.Number);
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
