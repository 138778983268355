export enum InvoiceStatusEnum {
    Draft = 0,
    Open = 1,
    Delayed = 2,
    Finished = 3
}

export class InvoiceStatusEnumExtensions {
    static getColor(value: InvoiceStatusEnum): string {
        switch (value) {
            case InvoiceStatusEnum.Draft:
                return 'bg-element-selected text-on-app';
            case InvoiceStatusEnum.Open:
                return 'bg-warn-light text-on-warn-light';
            case InvoiceStatusEnum.Delayed:
                return 'bg-error-light text-on-error-light';
            case InvoiceStatusEnum.Finished:
                return 'bg-success-light text-on-success-light';
        }
    }
}
