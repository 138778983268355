import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { SettingsStorage } from '../storages/settings.storage';
import { AuthService } from '../auth.service';
import { SecurityStorage } from '../storages/security.storage';

export const onlyLoggedUsersGuard: CanActivateFn = () => {
    const settingsStorage = inject(SettingsStorage);
    const securityStorage = inject(SecurityStorage);
    const authService = inject(AuthService);

    if (!!securityStorage.getToken()) {
        return true;
    } else {
        settingsStorage.saveNotLoggedRedirectUrl(window.location.href);
        authService.goToLoginPage();

        return false;
    }
};
