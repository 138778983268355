<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.posTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.PosTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.invoiceTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.InvoiceTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.warehouseTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.WarehouseTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.posTakeoutTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.PosTakeoutTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.posEndOfDayTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.PosEndOfDayTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.userEndOfDayTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.UserEndOfDayTransactionType"></arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
        [formControl]="formGroup.controls.voucherTransactionTypeId"
        label="Settings.Edit.Settings.BookingTypes.VoucherTransactionType"></arc-general-data-select>
</form>