<div class="h-full w-full overflow-auto">
    <div class="flex gap-4 items-center h-[44px]">
        <mat-icon>{{icon}}</mat-icon>
        <span class="text-2xl">{{entityModel.paymentTypeTitle}}</span>
    </div>
    <div class="grid grid-cols-12">
        <ng-container [ngSwitch]="entityModel.type">
            <ng-container *ngSwitchCase="TransactionPaymentTypeEnum.Card" [ngTemplateOutlet]="cardTpl"></ng-container>
            <ng-container *ngSwitchCase="TransactionPaymentTypeEnum.Voucher" [ngTemplateOutlet]="voucherTpl"></ng-container>
            <div *ngSwitchDefault>-</div>
        </ng-container>
    </div>
</div>

<ng-template #cardTpl>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.CardNumber' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.eftCardNumber}}
    </div>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.TransactionNumber' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.eftTrxRef}}
    </div>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.AuthCode' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.eftAuthNumber}}
    </div>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.TerminalId' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.eftTerminalId}}
    </div>
</ng-template>

<ng-template #voucherTpl>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.VoucherId' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.voucherId}}
    </div>
    <div class="flex flex-col gap-4 col-span-5">
        {{'TransactionPayments.Details.RemainingValue' | transloco}}:
    </div>
    <div class="flex flex-col gap-4 col-span-7">
        {{entityModel.voucherRemainingValue | arcCurrency }}
    </div>
</ng-template>
