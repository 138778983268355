import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { PosLayoutGroupCommandModel } from '../../models/pos-layout-group-command.model';
import { PosLayoutGroupCommandGridModel } from '../../models/responses/pos-layout-group-command-grid.model';
import { PosLayoutGroupCommandUpdateModel } from '../../models/requests/pos-layout-group-command-update.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class PosLayoutGroupCommandsStore extends BaseCrudStore<
PosLayoutGroupCommandModel,
PosLayoutGroupCommandGridModel,
PosLayoutGroupCommandUpdateModel
> {
    constructor() {
        super(new StoreConfig({ baseController: 'pos-layout-group-commands' }));
    }

    setDoubleSize(id: number, isDoubleSize: boolean): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${id}/set-double-size/${isDoubleSize}`));
    }

    swap(
        sourceCommandId: number,
        destinationPosLayoutGroupId: number,
        destinationPosition: number
    ): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(
            this.getUrl(`swap/${sourceCommandId}/${destinationPosLayoutGroupId}/${destinationPosition}`)
        );
    }
}
