import { DataSource, CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, Observable, merge, map, Subscription } from 'rxjs';

import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { TreeDataSelectionConfig } from '../../../form/tree-autocomplete/models/tree-data-selection.config';
import { DynamicTreeDatabase } from './dynamic-tree.database';
import { Identifyable } from '../../../../core/abstractions/identifyable';

export class DynamicTreeDataSource<T extends Identifyable<TId>, TId> implements DataSource<TreeItemFlatNode<TId>> {
    dataChange = new BehaviorSubject<TreeItemFlatNode<TId>[]>([]);
    get data(): TreeItemFlatNode<TId>[] {
        return this.dataChange.value;
    }
    set data(value: TreeItemFlatNode<TId>[]) {
        const newValue = this.setupCreatableNodes(value);
        this.treeControl.dataNodes = newValue;

        this.dataChange.next(newValue);
    }
    set isFromSearch(value: boolean) {
        this.isFromSearchInner = value;

        if (value) {
            this.selectedNode = undefined;
            this.treeControl.expandAll();
        }
    }

    private changeSubscription?: Subscription;
    private selectedNode?: TreeItemFlatNode<TId>;
    private isFromSearchInner = false;

    constructor(
        private treeControl: FlatTreeControl<TreeItemFlatNode<TId>>,
        private treeConfig: TreeDataSelectionConfig<T, TId>,
        private database: DynamicTreeDatabase<T, TId>
    ) {
    }

    connect(collectionViewer: CollectionViewer): Observable<TreeItemFlatNode<TId>[]> {
        this.changeSubscription = this.treeControl.expansionModel.changed.subscribe(change => {
            const selectionChange = change as SelectionChange<TreeItemFlatNode<TId>>;

            if (selectionChange.added || selectionChange.removed) {
                this.handleTreeControl(selectionChange);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disconnect(collectionViewer: CollectionViewer): void {
        this.changeSubscription?.unsubscribe();
    }

    handleTreeControl(change: SelectionChange<TreeItemFlatNode<TId>>): void {
        if (!!change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }

        if (!!change.removed) {
            change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
        }
    }

    toggleNode(node: TreeItemFlatNode<TId>, expand: boolean): void {
        const index = this.data.indexOf(node);

        if (index < 0) {
            return;
        }

        this.selectedNode = node;

        if (expand) {
            if (!this.isFromSearchInner) {
                this.database.getChildren(node).subscribe(nodes => {
                    if (nodes.length < 1) {
                        return;
                    }

                    this.data.splice(index + 1, 0, ...nodes);
                    this.completeLoadChildren(node);
                });
            }
        } else {
            let count = 0;

            for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}

            this.data.splice(index + 1, count);
            this.completeLoadChildren(node);
        }
    }

    addNewItem(newItem: TreeItemFlatNode<TId>, newButtonNode: TreeItemFlatNode<TId>): void {
        const index = this.data.indexOf(newButtonNode);

        this.data.splice(index, 0, newItem);
        this.completeLoadChildren(newButtonNode);
    }

    private completeLoadChildren(node: TreeItemFlatNode<TId>): void {
        this.data = [...this.data]; // Resetting data to trigger the set function.

        this.dataChange.next(this.data);

        this.selectedNode = undefined;
        node.isInEditMode = false;
        node.isSaving = false;
    }

    private setupCreatableNodes(array: TreeItemFlatNode<TId>[]): TreeItemFlatNode<TId>[] {
        return this.treeConfig.allowsInlineCreation
            ? this.addRootCreationNode(array)
            : array;
    }

    private addRootCreationNode(array: TreeItemFlatNode<TId>[]): TreeItemFlatNode<TId>[] {
        if (!!this.selectedNode || !!array.find(i => !!i.isNewRecordButton)) {
            return array;
        }

        const arrayCopy = array.map(i => ({ ...i }));

        arrayCopy.push({
            id: undefined as TId, // new record button doesn't have id
            isExpandable: false,
            level: 0,
            text: '',
            isNewRecordButton: true
        });

        return arrayCopy;
    }
}
