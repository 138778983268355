import { HslModel } from '../../models/hsl.model';
import { RgbModel } from '../../models/rgb.model';

export class ColorUtils {
    static hexToRgb(hex: string): RgbModel {
        // Remove '#' if present
        hex = hex.replace(/^#/, '');

        // Parse hex components
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return { r, g, b };
    }

    static isHexColorLight(hex: string, threshold = .5): boolean {
        const { l } = this.hexToHsl(hex);
        return l > threshold;
    }

    /**
     * Converts a HEX color value to HSL. Conversion formula adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and returns h, s, and l in the set [0, 1].
     *
     * @param hex The hex color value
     * @return The HSL representation
     **/
    static hexToHsl(hex: string): HslModel {
        const { r, g, b } = this.hexToRgb(hex);
        return this.rgbToHsl(r, g, b);
    }

    /**
     * Converts an RGB color value to HSL. Conversion formula adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and returns h, s, and l in the set [0, 1].
     *
     * @param r The red color value
     * @param g The green color value
     * @param b The blue color value
     * @return The HSL representation
     **/
    static rgbToHsl(r: number, g: number, b: number): HslModel {
        r /= 255;
        g /= 255;
        b /= 255;
        const vMax = Math.max(r, g, b);
        const vMin = Math.min(r, g, b);
        const l = (vMax + vMin) / 2;
        let h = (vMax + vMin) / 2;

        if (vMax === vMin) {
            return { h: 0, s: 0, l }; // achromatic
        }

        const delta = vMax - vMin;
        const s = l > 0.5
            ? delta / (2 - vMax - vMin)
            : delta / (vMax + vMin);

        if (vMax === r) {
            h = (g - b) / delta + (g < b ? 6 : 0);
        }

        if (vMax === g) {
            h = (b - r) / delta + 2;
        }

        if (vMax === b) {
            h = (r - g) / delta + 4;
        }

        h /= 6;

        return { h, s, l };
    }
}
