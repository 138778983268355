import { Component, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { TransactionArticleModel } from '../../../../../models/transaction-article.model';
import { UserService } from '../../../../../../core/services/user.service';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-transaction-article',
    templateUrl: './transaction-article.detail-widget.html',
    styleUrl: './transaction-article.detail-widget.scss'
})
export class TransactionArticleDetailWidget extends BaseDetailWidget<TransactionArticleListModel, TransactionArticleModel> {
    get isPricePerUnitDiff(): boolean {
        return this.listModel.unitPrice !== this.entityModel.unitPrice;
    }
    get isTotalDiff(): boolean {
        return this.unitPriceTimesQuantity !== this.entityModel.price;
    }
    get unitPriceTimesQuantity(): number {
        return Tools.Utils.roundTo(this.entityModel.unitPrice * this.entityModel.quantity, this.userService.defaultRoundTo);
    }
    get boxColorClasses(): string[] {
        return this.isSale ? ['bg-warn', 'text-on-warn'] : ['bg-error', 'text-on-error'];
    }
    get isSale(): boolean {
        return !!this.entityModel.saleTitle;
    }

    private readonly userService = inject(UserService);
}
