import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseCustomColumnComponent } from '../../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { OrderArticleModel } from '../../../../../../models/order-article.model';

@Component({
    selector: 'arc-order-article-status-column',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './order-article-status-column.component.html',
    styleUrl: './order-article-status-column.component.scss'
})
export class OrderArticleStatusColumnComponent extends BaseCustomColumnComponent<OrderArticleModel> implements OnInit {
    readonly colorMap = {
        correct: 'bg-success',
        incorrect: 'bg-warn',
        zero: 'bg-error'
    };

    colorClass = '';

    ngOnInit(): void {
        this.formGroup.valueChanges.subscribe(value => this.setColorClass(value));
        this.setColorClass(this.formGroup.value);
    }

    private setColorClass(value: Partial<OrderArticleModel>): void {
        this.colorClass = !value.deliveredQuantity
            ? this.colorMap.zero
            : // eslint-disable-next-line eqeqeq
            value.deliveredQuantity * (value.deliveredUnitQuantity ?? value.orderUnitQuantity!) ==
              value.orderQuantity! * value.orderUnitQuantity!
                ? this.colorMap.correct
                : this.colorMap.incorrect;
    }
}
