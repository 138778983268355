<mat-sidenav-container class="h-full" autosize [hasBackdrop]="layoutService.isMobileSubject | async"
    (backdropClick)="handleLeftBackdropClick()">
    <mat-sidenav-content class="!flex flex-col">
        <ng-content></ng-content>
    </mat-sidenav-content>

    <mat-sidenav [mode]="(layoutService.isMobileSubject | async) ? 'over' : 'side'"
        [opened]="sidebarService.leftOpenSubject | async" class="!border-r-0">
        <div class="h-full flex flex-col" [style.width.px]="sidebarWidthPx">
            <div class="grow overflow-y-auto">
                <mat-nav-list class="h-full !flex flex-col !p-0">
                    <ng-container *ngIf="!isEmptyFavouritesList; else noFavouritesTpl">
                        <arc-left-sidebar-item *ngFor="let menuItem of currentSubMenu"
                            [menuItem]="menuItem" [arcQuickFeedback]="quickFeedbacks[menuItem.path]"
                            [quickFeedbackShouldShow]="!!quickFeedbacks[menuItem.path]"
                            [quickFeedbackPosition]="{ top: 8, right: 40 }"
                            (favouriteChanged)="favouriteChanged()">
                        </arc-left-sidebar-item>
                    </ng-container>
                    <ng-template #noFavouritesTpl>
                        <p class="mt-10 mx-4 break-words text-center text-base text-on-app-light">
                            {{ 'Layout.Menu.Favourites.NoFavourites' | transloco }}
                        </p>
                    </ng-template>
                </mat-nav-list>
            </div>

            <!-- footer -->
            <div class="px-4 py-4 text-on-app-light text-xs">
                @if (hasSysAdminSection) {
                    <div>
                        <p>{{user?.serverName}}</p>
                        <p>
                            Version {{user?.databaseVersion}} | {{user?.databaseName}}
                        </p>
                        <p>{{user?.accessCode}}</p>
                    </div>
                }
                <div>&copy; Arcavis AG</div>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>