export enum SettingsCardTypesEnum {
    Customize = 1,
    Invoices = 2,
    Security = 3,
    Settings = 4,
    Warehouse = 5,
    DefaultValues = 6,
    Finance = 7,
    PriceLevels = 8,
    AccountTypes = 9,
    Fashion = 10
}
