export enum ArticleSetTypes {
    MandatoryFree = 0,
    Mandatory = 1,
    OptionalFree = 2,
    Optional = 3,
    SelectionFree = 4,
    Selection = 5,
    Ingredient = 6,
    Accessories = 7
}
