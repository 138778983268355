import { InvoiceCreateCustomerModel } from './invoice-create-customer.model';
import { Identifyable } from '../../../core/abstractions/identifyable';

export class InvoiceCreateModel implements Identifyable {
    id!: number;
    issuedDate!: Date;
    customers: InvoiceCreateCustomerModel[] = [];

    constructor(init?: Partial<InvoiceCreateModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
