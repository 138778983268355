import { Component, HostBinding, inject } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { CustomerProfileListModel } from '../../../../models/responses/customer-profile-list.model';
import { UserService } from '../../../../../core/services/user.service';

@Component({
    selector: 'arc-customer-profile-card',
    templateUrl: './customer-profiles-card.component.html',
    styleUrl: './customer-profiles-card.component.scss'
})
export class CustomerProfileCardComponent extends BaseCardComponent<CustomerProfileListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';

    private readonly userService = inject(UserService);

    get currencyCode(): string {
        return this.userService.getUserInfo()!.currencyIsoCode;
    }
}
