import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, inject } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';

import { OverlayService } from '../../core/services/overlay.service';
import { VoteTypeEnum } from '../../app/models/enums/vote-type.enum';
import { FeatureDetailsDialogComponent } from '../widgets/features-widget/feature-details-dialog/feature-details-dialog.component';
import { FeatureDetailsDialogDataModel } from '../widgets/features-widget/feature-details-dialog/models/feature-details-dialog-data.model';
import { FeatureService } from '../../core/services/feature.service';
import { BaseComponent } from '../abstractions/base.component';
import { FeatureModel } from '../../app/models/feature.model';

@Component({
    selector: 'arc-quick-feedback',
    templateUrl: './quick-feedback.component.html',
    styleUrls: ['./quick-feedback.component.scss']
})
export class QuickFeedbackComponent extends BaseComponent implements OnInit {
    @Input() featureId!: string;

    @HostBinding('class') classes = 'absolute z-10';
    @HostBinding('style.display') display = 'none';

    VoteTypeEnum = VoteTypeEnum;

    isHovering = false;
    isHoveringOverlay = false;
    isLoading = true;
    isVisibleInternal = false;
    isVisible = true;
    // first position is default. If that doesn't fit on the screen,
    // material checks the other possible positions
    positions: ConnectedPosition[] = [
        {
            // dropdown on bottom center
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top'
        },
        {
            // dropdown on bottom left
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        },
        {
            // dropdown on bottom right
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            // dropdown on top center
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
        },
        {
            // dropdown on top left
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
        },
        {
            // dropdown on top right
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
        }
    ];

    private parentElement?: HTMLElement;
    private mouseLeaveTimeout?: NodeJS.Timeout;

    private readonly elementRef = inject(ElementRef);
    private readonly overlayService = inject(OverlayService);
    private readonly featureService = inject(FeatureService);
    private readonly matDialog = inject(MatDialog);

    @HostListener('mouseenter')
    onMouseEnter(): void {
        clearTimeout(this.mouseLeaveTimeout);
        this.isHovering = true;

        if (!!this.parentElement) {
            this.overlayService.showOverlay(this.parentElement);
        }
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        // wait a few milliseconds so that the onMouseEnterOverlay can run first
        this.mouseLeaveTimeout = setTimeout(() => {
            if (!this.isHoveringOverlay) {
                this.isHovering = false;
                this.overlayService.removeOverlay();
            }
        }, 20);
    }

    ngOnInit(): void {
        this.setVisibilityInternal(false);

        const sub = this.featureService.featureChanged(this.featureId).subscribe(featureModel => {
            this.checkVisibilityInternal(featureModel);
        });
        this.addSubscriptions(sub);

        const componentElement = this.elementRef.nativeElement as HTMLElement;
        this.parentElement = componentElement.parentElement ?? undefined;

        this.featureService.getFeatureById(this.featureId).subscribe(featureModel => {
            this.checkVisibilityInternal(featureModel);
        });
    }

    setVisibility(isVisible: boolean): void {
        this.isVisible = isVisible;
        this.updateVisibility();
    }

    onMouseEnterOverlay(): void {
        clearTimeout(this.mouseLeaveTimeout);
        this.isHoveringOverlay = true;
    }

    onMouseLeaveOverlay(): void {
        this.isHoveringOverlay = false;
        this.onMouseLeave();
    }

    openFeedbackDialog(vote?: VoteTypeEnum): void {
        const dialogRef = this.matDialog.open(FeatureDetailsDialogComponent, {
            data: new FeatureDetailsDialogDataModel({
                featureId: +this.featureId,
                vote
            }),
            width: '800px',
            maxWidth: '98vw',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(hasVoted => {
            if (hasVoted) {
                this.setVisibilityInternal(false);
            }
        });
    }

    private setVisibilityInternal(isVisibleInternal: boolean): void {
        this.isVisibleInternal = isVisibleInternal;
        this.updateVisibility();
    }

    private checkVisibilityInternal(featureModel?: FeatureModel): void {
        this.setVisibilityInternal(!!featureModel && featureModel.vote === undefined);
    }

    private updateVisibility(): void {
        this.display = this.isVisibleInternal && this.isVisible ? 'block' : 'none';
    }
}
