<arc-editable-table [config]="setArticlesCardConfig" [template]="cardTemplate"
    [formControl]="formGroup.controls.setArticles">
    <ng-template #cardTemplate let-formGroup>
        <div class="grid grid-cols-7 grid-rows-2 gap-4 items-center">
            <arc-quick-search [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig"
                [formControl]="formGroup.controls.articleId" (optionSelected)="handleArticleSelected($event, formGroup)"
                class="col-span-4" label="Articles.EditFields.Article">
            </arc-quick-search>
            <arc-number-input label="Articles.EditFields.Quantity" [formControl]="formGroup.controls.quantity">
            </arc-number-input>
            <arc-select label="Articles.EditFields.SetArticleType" class="col-span-2"
                [formControl]="formGroup.controls.articleSetType" [options]="setArticleOptions">
            </arc-select>
            <arc-input labelKey="Articles.EditFields.SetArticleGroup" class="col-span-2"
                [control]="formGroup.controls.groupName">
            </arc-input>
            <arc-number-input label="Articles.EditFields.RequiredQuantity" class="col-span-2"
                [formControl]="formGroup.controls.requiredQuantity">
            </arc-number-input>
            <div *ngIf="!!formGroup.value.price">
                <p class="text-sm text-on-app-light">{{'Articles.EditFields.Price' | transloco}}</p>
                <p class="text-m">{{formGroup.value.price | arcCurrency}}</p>
            </div>
        </div>
    </ng-template>
</arc-editable-table>