import { Component, computed, effect, ElementRef, inject, signal, untracked, viewChild, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounceTime, distinctUntilChanged, filter, firstValueFrom, Subject, switchMap, tap } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ReservationDetailsModel } from '../../../../../models/reservation-details.model';
import { ReservationArticleModel } from '../../../../../models/reservation-article.model';
import { ReservationEditModel } from '../../../../../models/reservation-edit.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ReservationModel } from '../../../../../models/reservation.model';
import { ReservationsStore } from '../../../../../services/stores/reservations.store';
import { DataSelectionDialogComponent } from '../../../../../../components/dialogs/data-selection-dialog/data-selection-dialog.component';
import { DataSelectionDialogDataModel } from '../../../../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { Tools } from '../../../../../../core/utils/tools';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { UserService } from '../../../../../../core/services/user.service';

@Component({
    selector: 'arc-reservation-edit-articles',
    templateUrl: './reservation-edit-articles.component.html',
    styleUrl: './reservation-edit-articles.component.scss'
})
export class ReservationEditArticlesComponent extends BaseEditSidebarItemComponent<ReservationDetailsModel, ReservationEditModel> {
    static readonly totalPriceSignal = 'totalPrice';
    static readonly totalDiscountSignal = 'totalDiscount';
    static readonly totalSignal = 'total';
    static readonly numberOfPositionsSignal = 'numberOfPositions';
    static readonly numberOfArticlesSignal = 'numberOfArticles';
    static readonly reservationTypeIdSignal = 'reservationTypeId';
    static readonly customerIdSignal = 'customerId';

    readonly ComplexDataTypesEnum = ComplexDataTypesEnum;
    readonly UnitsEnum = UnitsEnum;

    readonly isAddingArticles = signal(false);

    readonly quickAddArticleControl = viewChild.required('quickAddArticleControl', { read: ElementRef });
    readonly quickAddQuantityControl = viewChild.required('quickAddQuantityControl', { read: ElementRef });

    readonly quickAddArticleId = signal<OptionalType<number>>(undefined);
    readonly quickAddQuantity = signal<OptionalType<number>>(undefined);

    readonly reservationArticles = signal<ReservationArticleModel[]>([]);
    readonly reservationArticlesSorted = computed(() => this.reservationArticles().sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0)));

    readonly isRecalculating = signal(false);
    readonly articlesToUpdate = signal<OptionalType<ReservationArticleModel[]>>(undefined);
    readonly canUpdatePrices = computed(() => !this.isRecalculating() && !!this.articlesToUpdate()?.length);

    readonly reservationTypeId: WritableSignal<OptionalType<number>>;
    readonly customerId: WritableSignal<OptionalType<number>>;
    readonly recalculateArticleSubject = new Subject<ReservationModel>();

    readonly currencyIsoCode: string;
    wasChanged = false;

    private readonly sharedDataService = inject(SharedDataService);
    private readonly reservationsStore = inject(ReservationsStore);
    private readonly matDialog = inject(MatDialog);
    private readonly userService = inject(UserService);

    constructor() {
        super();
        this.reservationTypeId = this.sharedDataService.getOrCreateSignal<number>(ReservationEditArticlesComponent.reservationTypeIdSignal);
        this.customerId = this.sharedDataService.getOrCreateSignal<number>(ReservationEditArticlesComponent.customerIdSignal);

        this.currencyIsoCode = this.userService.getUserInfo()?.currencyIsoCode ?? '';

        const recalculateSub = this.recalculateArticleSubject
            .pipe(
                distinctUntilChanged((prev, curr) => this.areEqualReservation(prev, curr)),
                tap(() => this.isRecalculating.set(true)),
                debounceTime(500),
                switchMap(({ reservationTypeId, personId, reservationArticles }) =>
                    this.reservationsStore.recalculateArticles(
                        {
                            id: this.item.id,
                            reservationTypeId,
                            personId,
                            reservationArticles
                        },
                        false,
                        false
                    )
                ),
                tap(() => this.isRecalculating.set(false)),
                filter(result => result.value !== undefined),
                tap(result => this.handleRecalculatedArticles(result.value!))
            )
            .subscribe();
        this.addSubscriptions(recalculateSub);

        effect(() => {
            const reservationTypeId = this.reservationTypeId();
            const customerId = this.customerId();
            const reservationArticles = this.reservationArticlesSorted();

            if (!reservationTypeId) {
                return;
            }

            untracked(() => {
                this.recalculateArticleSubject.next({
                    id: this.item.id,
                    reservationTypeId,
                    personId: customerId,
                    reservationArticles
                });
                this.updateSignals(reservationArticles);
            });
        });
    }

    override onItemSet(): void {
        this.reservationArticles.set(this.item.reservationArticles);
    }

    override prepareSaveModel(): Partial<ReservationEditModel> {
        return { reservationArticles: this.reservationArticles() };
    }

    override hasUnsavedChanges(): boolean {
        return this.wasChanged;
    }

    quickAddArticleSelected(): void {
        if (!!this.quickAddArticleId()) {
            setTimeout(() => this.quickAddQuantityControl().nativeElement.querySelector('input')?.focus());
        }
    }

    async quickAdd(): Promise<void> {
        const articleId = this.quickAddArticleId();
        const quantity = this.quickAddQuantity();
        if (!articleId || !quantity) {
            return;
        }

        await this.addArticles([{ articleId, quantity }]);
    }

    removeArticle(article: ReservationArticleModel): void {
        // all reservation articles should be uniquely identifyable by sort
        this.reservationArticles.update(r => r.filter(a => a.sort !== article.sort));
        this.wasChanged = true;
    }

    openArticleSelectDialog(): void {
        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: new DataSelectionDialogDataModel({
                type: ComplexDataTypesEnum.Article,
                isMultiSelect: true
            }),
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(async (result: ArticleListModel[]) => {
            if (!result) {
                return;
            }

            await this.addArticles(result.map(article => ({ articleId: article.id, quantity: 1 })));
        });
    }

    drop(event: CdkDragDrop<ReservationArticleModel[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        moveItemInArray(this.reservationArticles(), event.previousIndex, event.currentIndex);
        this.reservationArticles.update(r => r.map((a, i) => ({ ...a, sort: i })));
        this.wasChanged = true;
    }

    updateArticle(article: ReservationArticleModel): void {
        this.reservationArticles.update(r => r.map(other => (other.sort !== article.sort ? other : article)));
        this.wasChanged = true;
    }

    updatePrices(): void {
        const recalculatedArticles = this.articlesToUpdate();
        if (!recalculatedArticles) {
            return;
        }

        this.reservationArticles.set(recalculatedArticles);
        this.articlesToUpdate.set(undefined);
    }

    private async addArticles(articles: { articleId: number; quantity: number }[]): Promise<void> {
        const reservationTypeId = this.reservationTypeId();
        if (!reservationTypeId) {
            return;
        }

        this.isAddingArticles.set(true);

        const reservationArticles = Tools.Utils.deepCopy(this.reservationArticles());
        for (const { articleId, quantity } of articles) {
            reservationArticles.push({
                id: 0,
                articleId,
                quantity,

                // placeholder values, will be returned from the api
                title: '',
                articleNumber: '',
                taxRate: 0,
                unitId: 0,
                unitShort: '',
                unitPrice: 0,
                discount: 0,
                isDiscountLocked: false,
                total: 0
            });
        }

        const reservation: ReservationModel = {
            id: this.item.id,
            reservationTypeId,
            personId: this.customerId(),
            reservationArticles
        };

        const result = await firstValueFrom(this.reservationsStore.prepareNewReservationArticles(reservation));
        const newReservationArticles = result.value;
        if (!newReservationArticles) {
            this.isAddingArticles.set(false);
            return;
        }

        this.reservationArticles.update(r => [...r, ...newReservationArticles]);
        this.wasChanged = true;

        this.quickAddArticleId.set(undefined);
        this.quickAddQuantity.set(undefined);
        setTimeout(() => this.quickAddArticleControl().nativeElement.querySelector('input')?.focus());

        this.isAddingArticles.set(false);
    }

    private handleRecalculatedArticles(articles: ReservationArticleModel[]): void {
        const current = this.reservationArticlesSorted();
        const recalculated = articles.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));

        if (!this.areEqualReservationArticles(current, recalculated)) {
            this.articlesToUpdate.set(recalculated);
        } else {
            this.articlesToUpdate.set(undefined);
        }
    }

    private areEqualReservation(reservationA: ReservationModel, reservationB: ReservationModel): boolean {
        if (reservationA.personId !== reservationB.personId) {
            return false;
        }

        if (reservationA.reservationTypeId !== reservationB.reservationTypeId) {
            return false;
        }

        if (!this.areEqualReservationArticles(reservationA.reservationArticles, reservationB.reservationArticles)) {
            return false;
        }

        return true;
    }

    private areEqualReservationArticles(articlesA: ReservationArticleModel[], articlesB: ReservationArticleModel[]): boolean {
        if (articlesA.length !== articlesB.length) {
            return false;
        }

        const articlesASorted = articlesA.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
        const articlesBSorted = articlesB.sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));

        for (let i = 0; i < articlesASorted.length; i++) {
            const articleA = articlesASorted[i];
            const articleB = articlesBSorted[i];

            if (
                articleA.articleId !== articleB.articleId ||
                articleA.quantity !== articleB.quantity ||
                articleA.unitPrice !== articleB.unitPrice ||
                articleA.discount !== articleB.discount ||
                (articleA.discountPercent || 0) !== (articleB.discountPercent || 0) ||
                articleA.total !== articleB.total
            ) {
                return false;
            }
        }

        return true;
    }

    private updateSignals(reservationArticles: ReservationArticleModel[]): void {
        let totalPrice = 0;
        let totalDiscount = 0;
        let total = 0;
        let numberOfArticles = 0;

        for (const article of reservationArticles) {
            numberOfArticles += article.quantity;
            totalPrice += article.quantity * article.unitPrice;
            totalDiscount += article.discount;
            total += article.total;
        }

        this.sharedDataService.setOrUpdateSignal<number>(ReservationEditArticlesComponent.totalPriceSignal, totalPrice);
        this.sharedDataService.setOrUpdateSignal<number>(ReservationEditArticlesComponent.totalDiscountSignal, totalDiscount);
        this.sharedDataService.setOrUpdateSignal<number>(ReservationEditArticlesComponent.totalSignal, total);
        this.sharedDataService.setOrUpdateSignal<number>(ReservationEditArticlesComponent.numberOfArticlesSignal, numberOfArticles);
        this.sharedDataService.setOrUpdateSignal<number>(
            ReservationEditArticlesComponent.numberOfPositionsSignal,
            reservationArticles.length
        );
    }
}
