import { Component } from '@angular/core';

import { PointOfSaleListModel } from '../../../../../models/responses/pos-list.model';
import { PointOfSaleModel } from '../../../../../models/point-of-sale.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';

@Component({
    selector: 'arc-pos-item-overview',
    templateUrl: './pos-item-overview.detail-widget.html',
    styleUrls: ['./pos-item-overview.detail-widget.scss']
})
export class PosItemOverviewComponent extends BaseDetailWidget<PointOfSaleListModel, PointOfSaleModel> {
}
