import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-settings-customize-base-data',
    templateUrl: './settings-customize-base-data.component.html',
    styleUrl: './settings-customize-base-data.component.scss'
})
export class SettingsCustomizeBaseDataComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        companyName: new ArcFormControl('', Validators.required),
        companyStreet: new ArcFormControl<OptionalType<string>>(undefined),
        companyStreetNumber: new ArcFormControl<OptionalType<string>>(undefined),
        companyZip: new ArcFormControl<OptionalType<string>>(undefined),
        companyPlace: new ArcFormControl<OptionalType<string>>(undefined),
        companyCountry: new ArcFormControl<OptionalType<string>>(undefined),
        companyVatNo: new ArcFormControl<OptionalType<string>>(undefined),
        companyUid: new ArcFormControl<OptionalType<string>>(undefined),
        companyPhoneNumber: new ArcFormControl<OptionalType<string>>(undefined),
        companyFaxNumber: new ArcFormControl<OptionalType<string>>(undefined),
        companyEmail: new ArcFormControl<OptionalType<string>>(undefined, Validators.email),
        companyWebsite: new ArcFormControl<OptionalType<string>>(undefined),
        companyHeadquartersPlace: new ArcFormControl<OptionalType<string>>(undefined),
        companyJurisdictionPlace: new ArcFormControl<OptionalType<string>>(undefined),
        companyCeoName: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            companyName: value.companyName,
            companyStreet: value.companyStreet,
            companyStreetNumber: value.companyStreetNumber,
            companyZip: value.companyZip,
            companyPlace: value.companyPlace,
            companyCountry: value.companyCountry,
            companyVatNo: value.companyVatNo,
            companyUid: value.companyUid,
            companyPhoneNumber: value.companyPhoneNumber,
            companyFaxNumber: value.companyFaxNumber,
            companyEmail: value.companyEmail,
            companyWebsite: value.companyWebsite,
            companyHeadquartersPlace: value.companyHeadquartersPlace,
            companyJurisdictionPlace: value.companyJurisdictionPlace,
            companyCeoName: value.companyCeoName
        };
    }
}
