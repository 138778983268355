import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { PortalStore } from '../../../app/services/stores/portal.store';
import { LicensePurchaseResponseModel } from '../../../app/models/responses/license-purchase-response.model';
import { LicensePurchaseOptionModel } from '../../../app/models/responses/license-purchase-option.model';
import { AlertService } from '../../../core/services/alert.service';
import { ApiResponseModel } from '../../../app/models/responses/api-response.model';

@Component({
    selector: 'arc-licensing-options-dialog',
    templateUrl: './licensing-options-dialog.component.html',
    styleUrl: './licensing-options-dialog.component.scss'
})
export class LicensingOptionsDialogComponent implements OnInit {
    isLoading = true;
    licensingOptions?: LicensePurchaseResponseModel;
    get hasBundles(): boolean {
        return !!this.licensingOptions && this.licensingOptions.licensePurchaseOptions.length > 0;
    }

    readonly matDialogRef = inject(MatDialogRef);

    private readonly alertService = inject(AlertService);
    private readonly portalStore = inject(PortalStore);
    private readonly moduleId: string = inject(MAT_DIALOG_DATA);

    ngOnInit(): void {
        this.portalStore.getLicensingOptions(this.moduleId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(r =>
                this.licensingOptions = (r.value?.length || 0) > 0 ? r.value![0] : undefined
            );
    }

    upgrade(item: LicensePurchaseOptionModel, isDemo = false): void {
        this.isLoading = true;
        this.portalStore.purchaseLicense({ isDemo, moduleBundleId: item.moduleBundleId })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(resp => this.handleResponse(resp));
    }

    upgradeProduct(item: LicensePurchaseResponseModel): void {
        if (!!item.upgradeProductId) {
            this.isLoading = true;
            this.portalStore.upgradeProduct(item.upgradeProductId)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe(resp => this.handleResponse(resp));
        }
    }

    private handleResponse(resp: ApiResponseModel<any>): void {
        if (!!resp.value) {
            this.alertService.showSuccess('LicensingOptions.SuccessfulPurchase', undefined, () => this.matDialogRef.close(true));
        }
    }
}
