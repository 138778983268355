import { Component, Input, inject, ViewChild, AfterViewInit, OnInit, TemplateRef, Injector, signal } from '@angular/core';
import {
    FormGroup,
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    Validator,
    ValidationErrors,
    NG_VALIDATORS,
    NgControl,
    FormControlName,
    FormGroupDirective,
    FormControlDirective
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { EditableTableConfigModel } from './models/editable-table-config.model';
import { GeneralPromptDialogComponent } from '../../dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { EditableTableButtonModel } from './models/editable-table-button.model';
import { DictionaryType } from '../../../core/models/types/dictionary.type';
import { BaseComponent } from '../../abstractions/base.component';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { environment } from '../../../environments/environment';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { OptionalType } from '../../../core/models/types/optional.type';

@Component({
    selector: 'arc-editable-table',
    templateUrl: './editable-table.component.html',
    styleUrls: ['./editable-table.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EditableTableComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: EditableTableComponent,
            multi: true
        }
    ]
})
export class EditableTableComponent<T extends Identifyable<TId>, TId = number> extends BaseComponent
    implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    @Input() config!: EditableTableConfigModel<T, TId>;
    @Input() template!: TemplateRef<any>;
    @Input() filterPredicate?: (data: FormGroup, filter: string) => boolean;

    value: T[] = [];
    dataSource = new MatTableDataSource<FormGroup>([]);
    displayedColumns: string[] = [];

    isTouched = false;
    // eslint-disable-next-line no-null/no-null
    businessError = signal<OptionalType<ValidationErrors>>(undefined);

    get formControl(): OptionalType<AbstractControl> {
        return this._formControl;
    }
    readonly additionalButtonsLoading: DictionaryType<boolean> = {};

    protected _formControl?: AbstractControl;

    private readonly injector = inject(Injector);
    private readonly matDialog = inject(MatDialog);

    onTouched: () => void = () => {
    };
    onChange: (value?: T[]) => void = () => {
    };

    ngOnInit(): void {
        this.displayedColumns = (this.config.columns ?? []).map(c => c.identifier);

        if (this.config.allowDelete) {
            this.displayedColumns.push('delete');
        }

        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
            this._formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
        } else {
            this._formControl = (ngControl as FormControlDirective).form;
        }

        if (this._formControl instanceof ArcFormControl) {
            const touchedObservable = this._formControl.onTouched;
            const touchedSub = touchedObservable.subscribe(isTouched => {
                if (isTouched) {
                    this.markAllAsTouched();
                }
            });

            const arcFormControl = this._formControl;
            const statusChangesSub = arcFormControl.statusChanges.subscribe(() => {
                const businessError = arcFormControl.errors?.['businessError'];
                this.businessError.set(!!businessError ? { businessError } : undefined);
            });

            this.addSubscriptions(touchedSub, statusChangesSub);
        } else {
            if (!environment.production) {
                console.warn('Please use ArcFormControl with BaseControlValueAccessor');
            }
        }
    }

    ngAfterViewInit(): void {
        if (!!this.paginator) {
            this.dataSource.paginator = this.paginator;
        }

        if (!!this.filterPredicate) {
            this.dataSource.filterPredicate = this.filterPredicate;
        }
    }

    filter(filterString: string): void {
        this.dataSource.filter = filterString;
    }

    addRow(): void {
        if (!this.value) {
            this.value = [];
        }

        const newFormGroup = this.toFormGroup({}, this.dataSource.data.length);
        this.dataSource.data = [...this.dataSource.data, newFormGroup];
        this.value = [...this.value, newFormGroup.value];

        this.handleValueChanged();
    }

    handleAdditionalAddButtonClicked(btn: EditableTableButtonModel<T>): void {
        this.additionalButtonsLoading[btn.labelKey] = true;
        btn.action(this.value)
            .subscribe()
            .add(() => (this.additionalButtonsLoading[btn.labelKey] = false));
    }

    deleteRow(pageIndex: number): void {
        if (!this.config.showDeleteConfirmation) {
            this.deleteRowInternal(pageIndex);
            return;
        }

        const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
            data: { promptKey: 'General.Prompts.DeleteEntry' }
        });

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.deleteRowInternal(pageIndex);
            }
        });
    }

    /**
     * Value set from outside the control.
     */
    writeValue(value?: T[]): void {
        this.value = value ?? [];
        this.dataSource.data = (value ?? []).map((d, i) => this.toFormGroup(d, i));
    }

    /**
     * Registers a function that should be called when the control value changes
     */
    registerOnChange(fn: (value?: T[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a function that should be called when the control is touched
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    validate(): ValidationErrors | null {
        const isInvalid = this.dataSource.data.some(formGroup => {
            formGroup.updateValueAndValidity({ emitEvent: false });
            return formGroup.invalid;
        });

        // eslint-disable-next-line no-null/no-null
        return isInvalid ? { invalid: true } : null;
    }

    canDeleteRow(formGroup: FormGroup): boolean {
        return this.config.canDeleteRow ? this.config.canDeleteRow(formGroup.value) : true;
    }

    private formGroupValueChanged(index: number, newValue: T): void {
        this.config.onFormGroupValueChanged?.call(this, newValue, this.value[index], this.dataSource.data);
        this.value[index] = { ...this.value[index], ...newValue };

        this.handleValueChanged();
    }

    private handleValueChanged(): void {
        if (!this.isTouched) {
            this.isTouched = true;
            this.onTouched();
        }

        this.onChange(this.value);
    }

    private deleteRowInternal(pageIndex: number): void {
        const realIndex = pageIndex + (!!this.paginator ? this.paginator.pageIndex * this.paginator.pageSize : 0);

        this.dataSource.data.splice(realIndex, 1);
        this.dataSource.data = [...this.dataSource.data];

        this.value.splice(realIndex, 1);
        this.value = [...this.value];

        this.handleValueChanged();

        if (this.config.willInvalidateOnDelete) {
            this.dataSource.data.forEach(formGroup => {
                Object.keys(formGroup.controls).forEach(key => {
                    formGroup.controls[key].updateValueAndValidity();
                });
            });
        }
    }

    private toFormGroup(data: Partial<T>, index: number, shouldSubscribeForChanges = true): FormGroup {
        const formGroup = this.config.formGroupGeneratorFn(data);

        formGroup.patchValue(data);
        this.config.onRowInit?.(formGroup);
        this.config.formGroups.push(formGroup);

        if (shouldSubscribeForChanges) {
            formGroup.valueChanges.subscribe(newVal => this.formGroupValueChanged(index, newVal));
        }

        return formGroup;
    }

    private markAllAsTouched(): void {
        this.dataSource.data.forEach(formGroup => formGroup.markAllAsTouched());
    }

    get rowGapPx(): number {
        return this.config.rowGapPx;
    }
}
