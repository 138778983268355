import { Component, Input } from '@angular/core';

import { TicketStatusModel } from '../../../../app/models/responses/ticket-status.model';
import { TicketStatusEnum, TicketStatusEnumExtensions } from '../../../../app/models/enums/ticket-status.enum';

@Component({
    selector: 'arc-ticket-status',
    templateUrl: './ticket-status.component.html',
    styleUrl: './ticket-status.component.scss'
})
export class TicketStatusComponent {
    @Input() ticket?: TicketStatusModel;

    getStatusColorClasses(ticketStatus: TicketStatusEnum): string {
        return TicketStatusEnumExtensions.getColor(ticketStatus);
    }
}
