import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, computed, inject } from '@angular/core';

import { BaseSidebarHeaderAdditionalInfoComponent } from '../edit-sidebar/base-edit-sidebar-header-additional-info.component';
import { SidebarHeaderService } from '../../../core/services/sidebar-header.service';
import { SidebarService } from '../../../core/services/sidebar.service';
import { BaseComponent } from '../../abstractions/base.component';

@Component({
    selector: 'arc-sidebar-header',
    templateUrl: './sidebar-header.component.html',
    styleUrl: './sidebar-header.component.scss'
})
export class SidebarHeaderComponent<T = any> extends BaseComponent {
    hasPadding = computed(() => this.headerService.hasPadding());

    readonly additionalInfoComponentPortal = computed(() => {
        const component = this.headerService.additionalInfoComponent();
        if (!!component) {
            return new ComponentPortal(component);
        }
        return undefined;
    });

    readonly headerService = inject(SidebarHeaderService);
    private readonly sidebarService = inject(SidebarService);

    handleHeaderAdditionalInfoComponentAttached(ref: CdkPortalOutletAttachedRef): void {
        const item = this.headerService.item();
        if (!!item) {
            const componentRef = ref as ComponentRef<BaseSidebarHeaderAdditionalInfoComponent<T>>;
            componentRef.setInput('item', item);
            componentRef.instance.onItemSet();
        }
    }

    closeSidebar(): void {
        this.sidebarService.closeRight();
    }
    handleBackButtonClick(): void {
        this.headerService.handleBackButtonClicked();
    }
}
