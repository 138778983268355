<div (click)="handleSelectTicket(ticket().id)"
    class="border border-on-app-light border-solid rounded-xl p-5 flex flex-row group hover:bg-element-hover hover:cursor-pointer items-center">
    <arc-icon icon="chat_bubble" [size]="40" class="flex-none"></arc-icon>
    <div class="ml-5 max-w-[28rem] grow">
        <h3 class="font-bold">#{{ticket().id}} {{ ticket().title}}</h3>
        @if(ticket().resolvedOrClosedDate) {
            <span>{{ 'Tickets.ResolvedOn' | transloco }} {{ ticket().resolvedOrClosedDate | date }}</span>
        } @else {
            <span>{{ 'Tickets.CreatedOn' | transloco }} {{ ticket().createdDate| date }}</span>
        }
    </div>
    <arc-ticket-status [ticket]="ticket()"></arc-ticket-status>
    <arc-icon icon="chevron_right" [size]="30" class="opacity-0 group-hover:opacity-100 duration-300 text-icon-light ml-2"></arc-icon>
</div>
