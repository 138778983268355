import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { InvoiceListModel } from '../../../models/responses/invoice-list.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { InvoiceModel } from '../../../models/invoice.model';
import { InvoicesStore } from '../../../services/stores/invoices.store';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { AmountsColumnComponent } from './components/amounts-column/amounts-column.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { InvoiceCreateDialogComponent } from './invoice-create-dialog/invoice-create-dialog.component';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { InvoiceEditBaseDataComponent } from './invoice-edit-items/invoice-edit-base-data/invoice-edit-base-data.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import {
    InvoiceCustomerDetailsDetailWidget
} from './invoice-detail-widgets/invoice-customer-details/invoice-customer-details.detail-widget';
import { InvoiceEditRequestModel } from '../../../models/requests/invoice-edit-request.model';
import { InvoiceTransactionsDetailWidget } from './invoice-detail-widgets/invoice-transactions/invoice-transactions.detail-widget';
import { InvoiceRemarksDetailWidget } from './invoice-detail-widgets/invoice-remarks/invoice-remarks.detail-widget';
import { InvoiceDetailWidget } from './invoice-detail-widgets/invoice/invoice.detail-widget';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { CamtFileDialogComponent } from '../../../../components/dialogs/camt-file-dialog/camt-file-dialog.component';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { InvoiceStatusEnumExtensions } from '../../../models/enums/invoice-status.enum';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-invoices',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent extends PageListViewComponent<InvoiceListModel, InvoiceModel, InvoiceEditRequestModel> {
    private readonly invoicesStore = inject(InvoicesStore);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super('Invoices');

        this.config = new TableListViewConfigModel<InvoiceListModel, InvoiceModel, InvoiceEditRequestModel>({
            entityName: 'Invoices',
            availableColumns: {
                status: new GenericStatusColumnModel({
                    columnTitleKey: 'General.Status',
                    propertyName: 'status',
                    widthPixels: 160,
                    bgColor: value => InvoiceStatusEnumExtensions.getColor(value)
                }),
                invoice: new StackedColumnModel({
                    columnTitleKey: 'Invoices.Invoice',
                    propertyName: 'recipient',
                    propertyName2: 'number',
                    widthPixels: 236
                }),
                amounts: new CustomColumnModel({
                    columnTitleKey: 'Invoices.Amounts',
                    customComponent: AmountsColumnComponent,
                    widthPixels: 236,
                    propertyName: 'openAmount'
                }),
                issuedDate: new DateColumnModel({
                    columnTitleKey: 'General.IssuedDate',
                    propertyName: 'issuedDate',
                    widthPixels: 150
                }),
                dueDate: new DateColumnModel({
                    columnTitleKey: 'Invoices.PayableUntil',
                    propertyName: 'dueDate',
                    widthPixels: 150
                }),
                dateSent: new DateColumnModel({
                    columnTitleKey: 'Invoices.List.DateSent',
                    propertyName: 'dateSent',
                    widthPixels: 150
                }),
                datePaid: new DateColumnModel({
                    columnTitleKey: 'Invoices.List.DatePaid',
                    propertyName: 'datePaid',
                    widthPixels: 150
                }),
                reminderDate1: new DateColumnModel({
                    columnTitleKey: 'Invoices.List.ReminderDate1',
                    propertyName: 'reminderDate1',
                    widthPixels: 150
                }),
                reminderDate2: new DateColumnModel({
                    columnTitleKey: 'Invoices.List.ReminderDate2',
                    propertyName: 'reminderDate2',
                    widthPixels: 150
                })
            },
            defaultColumnOrder: [
                'status',
                'invoice',
                'amounts',
                'issuedDate',
                'dueDate',
                'dateSent',
                'datePaid',
                'reminderDate1',
                'reminderDate2'
            ],
            defaultSort: new ColumnSortModel({
                column: 'status',
                direction: SortDirectionEnum.Asc
            }),
            availableDetailWidgets: {
                customers: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.CustomerWidget',
                    component: InvoiceCustomerDetailsDetailWidget
                }),
                remarks: new TableListDetailWidgetModel({
                    name: 'Customers.Details.Transactions',
                    component: InvoiceTransactionsDetailWidget,
                    requiredPermission: PermissionsEnum.Transactions
                }),
                transactions: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.Remarks',
                    component: InvoiceRemarksDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'Invoices.Detail.DetailsWidget',
                    component: InvoiceDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['customers', 'remarks', 'transactions', 'details'],
            store: this.invoicesStore,
            handleCreateButtonClick: this.handleCreate.bind(this),
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Invoices.Edit.BaseData', component: InvoiceEditBaseDataComponent }],
                headerSubtitle: currentItem => of(currentItem.customer.displayName)
            },
            customActionButtons: [
                new ActionButtonModel({
                    clickFn: () => {
                        const dialogRef = this.matDialog.open(CamtFileDialogComponent, {
                            disableClose: true,
                            height: '700px',
                            maxHeight: '80svh',
                            width: '1200px',
                            maxWidth: '98vw'
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                this.listView.refresh();
                            }
                        });
                    },
                    text: 'Components.CamtFileUpload.Title',
                    icon: 'upload',
                    min: 0,
                    max: 0,
                    isEnabled: true
                })
            ]
        });
    }

    handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(InvoiceCreateDialogComponent, {
                height: '1000px',
                maxHeight: '98svh',
                width: '800px',
                maxWidth: '98vw'
            })
            .afterClosed();
    }
}
