import { BaseColumnModel } from './base-column.model';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class DateColumnModel extends BaseColumnModel {
    format = 'shortDate';
    /**
     * Used for when the column is editable and contains a time picker.
     */
    hasTimePicker = false;
    /**
     * Used to block the selection of dates before the date provided in this record property.
     */
    minDateField?: string;

    hasTime = true;

    constructor(init?: Partial<DateColumnModel>) {
        super(ColumnTypeEnum.Date);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
