import { ColumnFilterModel } from '../../../core/models/column-filter.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';

export class SearchRequestModel {
    id?: string;
    name?: string;
    searchText?: string;
    pageIndex?: number;
    pageSize?: number;
    sortings: ColumnSortModel[] = [];
    filters: ColumnFilterModel[] = [];

    constructor(init?: Partial<SearchRequestModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
