import { Component, ViewChild, OnInit, inject, Input, Output, EventEmitter } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';

import { TranslationService } from '../../core/services/translation.service';

@Component({
    selector: 'arc-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {
    @ViewChild('editor') editor?: QuillEditorComponent;

    @Input() value?: string;
    @Input() maxLength = 0;
    @Input() shouldDisplayCharCounter = true;
    @Input() placeholder = '';
    @Input() isDisabled = false;
    @Input() isInvalid = false;

    @Output() readonly valueChange = new EventEmitter<string>();

    currentCharCounter = 0;
    quillConfig = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image']
            ]
        }
    };

    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        if (!this.placeholder) {
            this.translationService.getTextAsync('Components.RichTextEditor.EnterTextHere').then(r => this.placeholder = r);
        }
    }
}
