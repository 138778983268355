import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, switchMap } from 'rxjs';

import { PermissionTypeEnum } from '../models/enums/permission-type.enum';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { ModuleIdentificationsEnumExtensions, ModuleIdentificationsEnum } from '../../app/models/enums/module-identifications.enum';
import { TranslationService } from './translation.service';
import { SecuritySessionStorage } from './storages/security.session-storage';
import { LicensingOptionsDialogComponent } from '../../components/dialogs/licensing-options-dialog/licensing-options-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private readonly securitySession = inject(SecuritySessionStorage);
    private readonly translationService = inject(TranslationService);
    private readonly matDialog = inject(MatDialog);

    /**
     * Checks whether the user has a specific permission:
     * Whether module is licensed and the role permission is set to true
     * @param permissionId Id of permission to check
     * @param permissionType Whether to check default, write, update or delete role permission
     * @returns true if user has the permission and module is licensed, else returns the missing permission model
     */
    hasPermission(permissionId: PermissionsEnum, permissionType = PermissionTypeEnum.Read): true | AuthPermissionModel {
        const permissions = this.securitySession.getPermissions();
        const permission = permissions.find(p => p.permissionId === permissionId);

        if (!permission) {
            // permission does not exist
            return true;
        }

        if (!permission.isLicensed) {
            return permission;
        }

        switch (permissionType) {
            case PermissionTypeEnum.Read:
                return permission.permit || permission;
            case PermissionTypeEnum.Create:
                return permission.permitCreate || permission;
            case PermissionTypeEnum.Update:
                return permission.permitUpdate || permission;
            case PermissionTypeEnum.Delete:
                return permission.permitDelete || permission;
        }
    }

    /**
     * Whether the user has a specific module licensed to his account.
     * @param moduleId Module ID to be checked.
     */
    hasModuleLicensed(moduleId: ModuleIdentificationsEnum): boolean {
        const userInfo = this.securitySession.getUserInfo();
        return userInfo?.licensedModuleIds.map(id => id.toLowerCase()).includes(moduleId.toLowerCase()) || false;
    }

    getTooltipTextObs(permission?: true | AuthPermissionModel): Observable<string> {
        if (!permission || permission === true) {
            return of('');
        }

        if (!permission.isLicensed) {
            const moduleName = ModuleIdentificationsEnumExtensions.getKey(permission.requiredModuleId!);
            return this.translationService.getTextObservable(`General.Modules.${moduleName}`).pipe(
                switchMap(module => this.translationService.getTextObservable('General.Modules.AvailableWithModule', { module }))
            );
        }

        // TODO display permission name and type
        return this.translationService.getTextObservable('General.Permissions.NoPermission');
    }

    showUpgradeModal(permission: AuthPermissionModel): void {
        const canUpgradeLicense = this.hasPermission(PermissionsEnum.Licensing) === true;

        if (!canUpgradeLicense || permission.isLicensed) {
            return;
        }

        const dialogRef = this.matDialog.open(LicensingOptionsDialogComponent, {
            data: permission.requiredModuleId,
            maxWidth: '98vw',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(didPurchase => {
            if (didPurchase) {
                this.securitySession.eraseSecurityData();
                window.location.reload();
            }
        });
    }
}
