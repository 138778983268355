import { Injectable } from '@angular/core';

import { AccountingExportRecordListModel } from '../../models/responses/accounting-export-record-list.model';
import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { AccountingExportRecordModel } from '../../models/accounting-export-record.model';

@Injectable({
    providedIn: 'root'
})
export class AccountingExportRecordsStore extends BaseCrudStore<AccountingExportRecordModel, AccountingExportRecordListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'accounting-export-records' }));
    }
}
