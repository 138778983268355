import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { TextAlignmentEnum } from '../../../../core/models/enums/text-alignment.enum';
import { IconModel } from '../../../../core/models/icon.model';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { IconColumnModel } from '../../../dynamic-table/models/column-types/icon-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const customerSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'displayName',
        propertyName2: 'personNumber',
        columnTitleKey: 'Customers.List.CustomerName'
    }),
    new IconColumnModel({
        columnTitleKey: 'Customers.List.NoCommunication',
        propertyName: 'noCommunication',
        columnTitleAlignment: TextAlignmentEnum.Left,
        iconMapping: new Map([
            [true, new IconModel({ name: 'block' })],
            [false, new IconModel({ name: '' })]
        ])
    }),
    new StringColumnModel({
        propertyName: 'bonusPoints',
        columnTitleKey: 'Customers.List.BonusPoints',
        requiredPermission: PermissionsEnum.CustomerBonusPrograms
    })
];
