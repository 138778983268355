import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class TimezoneHttpInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // do not add header for requests that do not go to our api server
        if (!request.url.startsWith(environment.baseUrl)) {
            return next.handle(request);
        }

        const contextRequest = request.clone({
            headers: request.headers.set('TZ', Intl.DateTimeFormat().resolvedOptions().timeZone)
        });

        return next.handle(contextRequest);
    }
}
