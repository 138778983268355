import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-security-pin',
    templateUrl: './settings-security-pin.component.html',
    styleUrl: './settings-security-pin.component.scss'
})
export class SettingsSecurityPinComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        posUserCreatePinOnFirstLogin: new ArcFormControl(false)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { posUserCreatePinOnFirstLogin: this.formGroup.value.posUserCreatePinOnFirstLogin };
    }
}
