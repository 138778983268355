<form [formGroup]="formGroup" class="grid items-center grid-cols-2 grid-rows-5 grid-flow-col ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-input type="email" labelKey="Customers.EditFields.ContactEmail" [control]="formGroup.controls.contactEmail"></arc-input>
    <arc-input labelKey="Customers.EditFields.ContactPhone" [control]="formGroup.controls.contactPhone"></arc-input>
    <arc-input labelKey="Customers.EditFields.ContactMobile" [control]="formGroup.controls.contactMobile"></arc-input>
    <arc-input labelKey="Customers.EditFields.ContactFax" [control]="formGroup.controls.contactFax"></arc-input>
    <arc-input labelKey="Customers.EditFields.ContactUrl" [control]="formGroup.controls.contactUrl"></arc-input>

    <arc-checkbox labelKey="Customers.EditFields.NoCommunication" [control]="formGroup.controls.noCommunication"></arc-checkbox>
    <arc-select labelKey="Customers.EditFields.Language" [options]="allowedLanguages"
        [control]="formGroup.controls.languageId"></arc-select>
    <arc-general-data-select label="Customers.EditFields.ResponsibleUser" [generalDataType]="GeneralDataTypeEnum.Users"
        [formControl]="formGroup.controls.responsibleUserId"></arc-general-data-select>
    <arc-quick-search label="Customers.EditFields.PaymentPerson" [config]="ComplexDataTypesEnum.Customer | arcQuickSearchConfig"
        [formControl]="formGroup.controls.paymentPersonId" [requiredPermission]="PermissionsEnum.Invoices"></arc-quick-search>
</form>
