<form class="flex flex-col gap-4">
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.PriceEan" [control]="formGroup.controls.priceEANPrefixes"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.WeightEan" [control]="formGroup.controls.weightEANPrefixes"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixCustomer" [control]="formGroup.controls.scanPrefixCustomer"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixTransaction"
        [control]="formGroup.controls.scanPrefixTransaction"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixReservation"
        [control]="formGroup.controls.scanPrefixReservation"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixSubscription"
        [control]="formGroup.controls.scanPrefixSubscription"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixDiscount" [control]="formGroup.controls.scanPrefixDiscount"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixBoncard" [control]="formGroup.controls.scanPrefixBoncard"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixAppBasket"
        [control]="formGroup.controls.scanPrefixAppBasket"></arc-input>
    <arc-input labelKey="Settings.Edit.Settings.Prefixes.ScanPrefixUserSwitch" [control]="formGroup.controls.scanPrefixUserSwitch">
    </arc-input>

</form>