import { Component, inject, signal } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { UsersStore } from '../../../app/services/stores/users.store';
import { SecuritySessionStorage } from '../../../core/services/storages/security.session-storage';

@Component({
    selector: 'arc-email-verification-dialog',
    templateUrl: './email-verification-dialog.component.html',
    styleUrl: './email-verification-dialog.component.scss'
})
export class EmailVerificationDialogComponent {
    currentStep = signal(1);
    isLoading = signal(false);
    emailControl = new ArcFormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]);
    verificationControl = new ArcFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);

    username?: string;

    private readonly usersStore = inject(UsersStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly securitySession = inject(SecuritySessionStorage);

    constructor() {
        this.username = this.securitySession.getUserInfo()?.username;
    }

    requestConfirmationCode(): void {
        this.isLoading.set(true);
        this.usersStore.requestEmailVerificationCode(this.emailControl.value)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe(() => this.currentStep.set(2));
    }

    verifyEmail(): void {
        this.isLoading.set(true);
        this.usersStore.verifyEmailCode(this.emailControl.value, this.verificationControl.value)
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe(resp => {
                if (!!resp.value) {
                    const userInfo = this.securitySession.getUserInfo();

                    if (!!userInfo) {
                        userInfo.emailVerifiedDate = new Date();

                        this.securitySession.saveUserInfo(userInfo);
                        this.matDialogRef.close();
                    }
                }
            });
    }
}
