import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { InventoryListModel } from '../../../../../models/responses/inventory-list.model';
import { InventoryTypeEnum } from '../../../../../models/enums/inventory-type.enum';

@Component({
    selector: 'arc-inventory-type-column',
    templateUrl: './inventory-type-column.component.html',
    styleUrl: './inventory-type-column.component.scss'
})
export class InventoryTypeColumnComponent extends BaseCustomColumnComponent<InventoryListModel> {
    get type(): string {
        return InventoryTypeEnum[this.item.inventoryType];
    }
}
