import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { SubscriptionModel } from '../../models/subscription.model';
import { SubscriptionListModel } from '../../models/responses/subscription-list.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsStore extends BaseCrudStore<SubscriptionModel, SubscriptionListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'subscriptions' }));
    }
}
