export enum PermissionsEnum {
    AccountTypes = 'AccountTypes',
    ArticleGroups = 'ArticleGroups',
    ArticleIdentifications = 'ArticleIdentifications',
    ArticlePrices = 'ArticlePrices',
    Articles = 'Articles',
    ArticleSales = 'ArticleSales',
    ArticleSets = 'ArticleSets',
    ArticleStocks = 'ArticleStocks',
    ArticleSuppliers = 'ArticleSuppliers',
    ArticleTaxes = 'ArticleTaxes',
    BonusRuns = 'BonusRuns',
    Branches = 'Branches',
    BuyingPrice = 'BuyingPricePermission',
    ChangeProfile = 'ChangeProfile',
    ColorGroups = 'ColorGroups',
    Colors = 'Colors',
    CommunicationRuns = 'CommunicationRuns',
    Configuration = 'Configuration',
    Currencies = 'Currencies',
    CustomerAccounts = 'CustomerAccounts',
    CustomerBonusPrograms = 'CustomerBonusPrograms',
    CustomerGroups = 'CustomerGroups',
    CustomerProfileDiscounts = 'CustomerProfileDiscounts',
    CustomerProfiles = 'CustomerProfiles',
    Customers = 'Customers',
    CustomerTurnovers = 'CustomerTurnovers',
    DefaultValues = 'DefaultValues',
    EditBuyingPrice = 'EditBuyingPrice',
    ExtAccountingExport = 'ExtAccountingExport',
    Extensions = 'Extensions',
    Favourites = 'Favourites',
    GuideProvisions = 'GuideProvisions',
    HomeStatistics = 'HomeStatistics',
    HomeStocks = 'HomeStocks',
    Import = 'Import',
    ImportDefinitions = 'ImportDefinitions',
    Inventories = 'Inventories',
    Invoices = 'Invoices',
    Licensing = 'Licensing',
    Log = 'Log',
    Margins = 'Margins',
    MassMutations = 'MassMutations',
    Messages = 'Messages',
    Orders = 'Orders',
    PaymentTypes = 'PaymentTypes',
    PointOfSales = 'PointOfSales',
    PosCashRegisters = 'PosCashRegisters',
    PosCustomerScreenLayouts = 'PosCustomerScreenLayouts',
    PosEndOfDay = 'PosEndOfDay',
    PosLayoutGroupCommands = 'PosLayoutGroupCommands',
    PosLayoutGroups = 'PosLayoutGroups',
    PosLayouts = 'PosLayouts',
    PosLogin = 'PosLogin',
    PosReports = 'PosReports',
    PriceLevels = 'PriceLevels',
    QueryDesignerReports = 'QueryDesignerReports',
    Regions = 'Regions',
    Reporting = 'Reporting',
    Reservations = 'Reservations',
    ReservationTypes = 'ReservationTypes',
    Resources = 'Resources',
    Roles = 'Roles',
    Sales = 'Sales',
    Seasons = 'Seasons',
    SizeGroups = 'SizeGroups',
    Sizes = 'Sizes',
    Stores = 'Stores',
    SubscriptionTypes = 'SubscriptionTypes',
    Suppliers = 'Suppliers',
    SystemMessages = 'SystemMessages',
    Tags = 'Tags',
    TransactionPurposes = 'TransactionPurposes',
    Transactions = 'Transactions',
    TransactionTypes = 'TransactionTypes',
    Units = 'Units',
    Users = 'Users',
    Vouchers = 'Vouchers',
    Variants = 'Variants',
    VoucherTypes = 'VoucherTypes',
    WarehouseRequests = 'WarehouseRequests',
    WebAPI = 'WebAPI',
    WorkingTimes = 'WorkingTimes'
}
