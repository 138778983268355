import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of, tap } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { OrderListModel } from '../../../models/responses/order-list.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { OrderModel } from '../../../models/order.model';
import { OrdersStore } from '../../../services/stores/orders.store';
import { NumberPipe } from '../../../../core/pipes/number.pipe';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { OrderCreateDialogComponent } from './order-create-dialog/order-create-dialog.component';
import { OrderStatusEnumExtensions } from '../../../models/enums/order-status.enum';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { OrderSupplierBaseDataDetailWidget } from './detail-widgets/order-supplier-base-data/order-supplier-base-data.detail-widget';
import { OrderDataDetailWidget } from './detail-widgets/order-data/order-data.detail-widget';
import { OrderCreateModel } from '../../../models/requests/order-create.model';
import { OrderEditHeaderAdditionalInfoComponent } from './order-edit-header-additional-info/order-edit-header-additional-info.component';
import { OrderUpdateModel } from '../../../models/requests/order-update.model';
import { OrderEditBaseDataComponent } from './order-edit-items/order-edit-base-data/order-edit-base-data.component';
import { OrderEditArticlesComponent } from './order-edit-items/order-edit-articles/order-edit-articles.component';

@Component({
    selector: 'arc-orders',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./orders.component.scss'],
    providers: [NumberPipe]
})
export class OrdersComponent extends PageListViewComponent<OrderListModel, OrderModel, OrderCreateModel, OrderUpdateModel> {
    private readonly ordersStore = inject(OrdersStore);
    private readonly numberPipe = inject(NumberPipe);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super('Orders');

        this.config = new TableListViewConfigModel<OrderListModel, OrderModel, OrderCreateModel, OrderUpdateModel>({
            entityName: 'Orders',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Orders.List.Order',
                    propertyName: 'supplier',
                    propertyName2: 'id',
                    widthPixels: 236
                }),
                status: new GenericStatusColumnModel({
                    columnTitleKey: 'General.Status',
                    propertyName: 'status',
                    widthPixels: 160,
                    bgColor: value => OrderStatusEnumExtensions.getColor(value)
                }),
                branch: new StringColumnModel({
                    columnTitleKey: 'Sales.Branch',
                    propertyName: 'branch',
                    widthPixels: 145
                }),
                dateSent: new DateColumnModel({
                    columnTitleKey: 'Orders.List.SentOn',
                    propertyName: 'dateSent',
                    widthPixels: 145,
                    format: 'short'
                }),
                dateDelivered: new DateColumnModel({
                    columnTitleKey: 'Orders.List.DeliveredOn',
                    propertyName: 'dateDelivered',
                    widthPixels: 145,
                    format: 'short'
                }),
                deliveryDate: new DateColumnModel({
                    columnTitleKey: 'Orders.List.DeliveryDate',
                    propertyName: 'deliveryDate',
                    widthPixels: 145,
                    format: 'short'
                }),
                articlesQuantity: new StringColumnModel({
                    columnTitleKey: 'Sales.ArticlesCount',
                    propertyName: 'articlesQuantity',
                    widthPixels: 126,
                    customFormatter: (record, value) => this.numberPipe.transform(value, 0)
                }),
                total: new CurrencyColumnModel({
                    columnTitleKey: 'Orders.List.OrderTotal',
                    propertyName: 'total',
                    widthPixels: 156
                })
            },
            defaultColumnOrder: ['title', 'status', 'branch', 'dateSent', 'dateDelivered', 'deliveryDate', 'articlesQuantity', 'total'],
            defaultSort: new ColumnSortModel({ column: 'status', direction: SortDirectionEnum.Asc }),
            availableDetailWidgets: {
                supplierData: new TableListDetailWidgetModel({
                    name: 'Orders.Details.SupplierData.Title',
                    component: OrderSupplierBaseDataDetailWidget
                }),
                orderData: new TableListDetailWidgetModel({
                    name: 'Orders.Details.OrderData.Title',
                    component: OrderDataDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['supplierData', 'orderData'],
            store: this.ordersStore,
            handleCreateButtonClick: this.handleCreate.bind(this),
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Orders.Edit.BaseData.BaseData', component: OrderEditBaseDataComponent },
                    { titleKey: 'Orders.Edit.Articles.Articles', component: OrderEditArticlesComponent }
                ],
                headerSubtitle: order => of(`${order.supplier.displayName}, ${order.store.name}`),
                headerAdditionalInfoComponent: OrderEditHeaderAdditionalInfoComponent,
                customMaxWidth: '1200px'
            }
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService.updateIsHidden(
            ActionButtonsService.deleteButtonKey,
            (btn, data) => !!data && !data.canBeDeleted
        );
    }

    handleCreate(): Observable<boolean> {
        return this.matDialog
            .open(OrderCreateDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw'
            })
            .afterClosed()
            .pipe(
                tap<any>(result => {
                    if (!result?.id) {
                        return;
                    }
                    this.listView.openEditSidebar(result.id).subscribe(shouldReload => {
                        if (shouldReload) {
                            this.listView.refresh();
                        }
                    });
                }),
                map(result => result?.shouldReload ?? false)
            );
    }
}
