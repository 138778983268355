<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-select
        labelKey="TransactionTypes.Edit.BaseData.Configuration"
        [options]="allowedConfigurations"
        [control]="formGroup.controls.configurationType"></arc-select>
    <div class="flex gap-2 smd:flex-col">
        <arc-translation-input
            labelKey="TransactionTypes.Edit.BaseData.Title"
            translationDialogTitleKey="General.Actions.TranslateTitle"
            [controlDe]="formGroup.controls.title_de"
            [controlEn]="formGroup.controls.title_en"
            [controlFr]="formGroup.controls.title_fr"
            [controlIt]="formGroup.controls.title_it"
            class="w-[50%] smd:w-full"></arc-translation-input>
        <arc-general-data-select
            label="TransactionTypes.Edit.BaseData.RequiredRole"
            [generalDataType]="GeneralDataTypeEnum.Roles"
            [formControl]="formGroup.controls.requiredRoleId"
            class="w-[35%] smd:w-full">
        </arc-general-data-select>
        <arc-input
            [control]="formGroup.controls.sort"
            labelKey="TransactionTypes.Edit.BaseData.Sort"
            type="number"
            class="w-[15%] smd:w-full"></arc-input>
    </div>
    <arc-translation-input
        labelKey="TransactionTypes.Edit.BaseData.Description"
        translationDialogTitleKey="TransactionTypes.Edit.BaseData.TranslateDescription"
        [controlDe]="formGroup.controls.description_de"
        [controlEn]="formGroup.controls.description_en"
        [controlFr]="formGroup.controls.description_fr"
        [controlIt]="formGroup.controls.description_it"></arc-translation-input>
</form>
