import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { ColorListModel } from '../../models/color-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';

@Injectable({
    providedIn: 'root'
})
export class ColorsStore extends BaseCrudStore<ColorListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'colors' }));
    }
}
