import { Component, HostBinding, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { OptionalType } from '../../../../../core/models/types/optional.type';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { WarehouseRequestCreateModel } from '../../../../models/requests/warehouse-request-create.model';
import { WarehouseRequestsStore } from '../../../../services/stores/warehouse-requests.store';

@Component({
    selector: 'arc-warehouse-transer-create-dialog',
    templateUrl: './warehouse-transer-create-dialog.component.html',
    styleUrl: './warehouse-transer-create-dialog.component.scss'
})
export class WarehouseTranserCreateDialogComponent {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    isLoading = false;
    isCreating = false;

    formGroup = new FormGroup({
        deliveringStoreId: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        receivingStoreId: new ArcFormControl<OptionalType<string>>(undefined, Validators.required)
    });

    private readonly warehouseRequestsStore = inject(WarehouseRequestsStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);

    create(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }

        this.isCreating = true;

        const createModel = this.formGroup.value as WarehouseRequestCreateModel;
        this.warehouseRequestsStore
            .add(createModel)
            .subscribe(response => {
                if (response.value) {
                    this.matDialogRef.close({ shouldReload: true, id: response.value });
                } else {
                    this.toasterService.showError('General.Alert.UnexpectedError');
                    this.isCreating = false;
                }
            })
            .add(() => (this.isCreating = false));
    }
}
