import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../components/abstractions/base.detail-widget';
import { OrderArticleModel } from '../../../../models/order-article.model';
import { OrderArticleListModel } from '../../../../models/responses/order-article-list.model';

@Component({
    selector: 'arc-order-article-stocks',
    templateUrl: './order-article-stocks.detail-widget.html',
    styleUrl: './order-article-stocks.detail-widget.scss'
})
export class OrderArticleStocksDetailWidget extends BaseDetailWidget<OrderArticleListModel, OrderArticleModel, string> {}
