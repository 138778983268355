import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserEditModel } from '../../../../../models/user-edit.model';
import { UserModel } from '../../../../../models/user.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { WorkingTimeModel } from '../../../../../models/working-time.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-user-working-times',
    templateUrl: './user-working-times.component.html',
    styleUrl: './user-working-times.component.scss'
})
export class UserWorkingTimesComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        workingTimes: new ArcFormControl<WorkingTimeModel[]>([])
    });
    workingTimesTableConfig: EditableTableConfigModel<WorkingTimeModel>;

    constructor() {
        super();
        this.workingTimesTableConfig = new EditableTableConfigModel<WorkingTimeModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl<number>(0),
                    fromTime: new ArcFormControl<Date>(new Date(), Validators.required),
                    toTime: new ArcFormControl<Date>(new Date(), Validators.required),
                    remarks: new ArcFormControl<OptionalType<string>>(undefined)
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new DateColumnModel({
                    propertyName: 'fromTime',
                    hasTimePicker: true,
                    isEditable: true,
                    columnTitleKey: 'Users.Edit.WorkingTimes.From'
                }),
                new DateColumnModel({
                    propertyName: 'toTime',
                    hasTimePicker: true,
                    isEditable: true,
                    columnTitleKey: 'Users.Edit.WorkingTimes.To',
                    minDateField: 'fromTime'
                }),
                new StringColumnModel({
                    propertyName: 'total',
                    customFormatter: (record: WorkingTimeModel) => {
                        if (!!record.fromTime && !!record.toTime) {
                            let diff = record.toTime.getTime() - record.fromTime.getTime();
                            const hours = Math.floor(diff / (1000 * 60 * 60));
                            diff -= hours * (1000 * 60 * 60);
                            const minutes = Math.floor(diff / (1000 * 60));

                            return `${hours}h ${minutes}min`;
                        }

                        return '';
                    },
                    columnTitleKey: 'Users.Edit.WorkingTimes.Total'
                }),
                new StringColumnModel({
                    propertyName: 'remarks',
                    isEditable: true,
                    columnTitleKey: 'Users.Edit.WorkingTimes.Remarks'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.workingTimesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<UserEditModel> {
        return { workingTimes: this.formGroup.value.workingTimes };
    }
}
