<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-translation-input labelKey="Reports.Edit.Title"
        translationDialogTitleKey="General.Actions.TranslateTitle" [controlDe]="formGroup.controls.title_de"
        [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it">
    </arc-translation-input>

    <arc-translation-input labelKey="Reports.Edit.Description"
        translationDialogTitleKey="Reports.Edit.TranslateDescription"
        [controlDe]="formGroup.controls.description_de" [controlEn]="formGroup.controls.description_en"
        [controlFr]="formGroup.controls.description_fr" [controlIt]="formGroup.controls.description_it">
    </arc-translation-input>

    <arc-general-data-select
            label="Reports.Edit.RequiredRole"
            [generalDataType]="GeneralDataTypeEnum.Roles"
            [formControl]="formGroup.controls.requiredRoleId">
        </arc-general-data-select>
</form>
