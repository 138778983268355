<h1 mat-dialog-title>
    {{ 'WarehouseTransfers.Edit.Articles.EditDialogTitle' | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    @if (isLoading) {
        <div class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    } @else {
        <form [formGroup]="modalFormGroup" class="flex flex-col gap-4">
            <div class="grid grid-cols-2 gap-4 pt-2">
                <div>
                    <arc-input labelKey="Articles.EditFields.OrderNumber" [control]="modalFormGroup.controls.orderNumber">
                        @if (!canUpdateOrderNumber) {
                            <mat-icon suffix [matTooltip]="'Articles.EditFields.SupplierNotConfigured' | transloco">info_outline</mat-icon>
                        }
                    </arc-input>
                </div>
                <div>
                    <arc-currency-input label="Articles.Edit.BuyingPrice" [requiredPermission]="PermissionsEnum.BuyingPrice"
                        [requiredPermissionType]="PermissionTypeEnum.Update" [formControl]="modalFormGroup.controls.buyingPrice">
                    </arc-currency-input>
                </div>
            </div>
            @if (mainArticleTags.length > 0) {
                <mat-chip-set>
                    @for (tag of mainArticleTags; track tag) {
                        <mat-chip>{{ tag.title }}</mat-chip>
                    }
                </mat-chip-set>
            }
            <arc-chip-selector label="Articles.EditFields.ArticleTags" [formControl]="modalFormGroup.controls.tags" [uniqueIdFn]="getTagId"
                [displayFn]="tagsOptionDisplay" [searchFn]="tagsSearch.bind(this)">
            </arc-chip-selector>
        </form>
        <div class="mt-4 min-h-[100px]">
            <arc-article-images-editor [formControl]="modalFormGroup.controls.articleImages" />
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <button mat-flat-button color="accent" [mat-dialog-close]="modalFormGroup.value">{{ 'General.Actions.Save' | transloco }}</button>
</mat-dialog-actions>