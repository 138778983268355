export enum TransactionTypeConfigurationsEnum {
    None = -1,
    CashRegisterClosure = 0,
    OpenCashDrawer = 1,
    InventoryMovement = 2,
    FundsTransfer = 3,
    CashFlow = 4,
    Sale = 5,
    NonRevenueSale = 6,
    Custom = 7
}

export class TransactionTypeConfigurationsEnumExtensions {
    static doesNotRequirePayment(configuration: TransactionTypeConfigurationsEnum): boolean {
        return [
            TransactionTypeConfigurationsEnum.CashRegisterClosure,
            TransactionTypeConfigurationsEnum.OpenCashDrawer,
            TransactionTypeConfigurationsEnum.InventoryMovement
        ].includes(configuration);
    }
}
