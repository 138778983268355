import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, from } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PosLayoutModel } from '../../../models/pos-layout.model';
import { PosLayoutListModel } from '../../../models/responses/pos-layout-list.model';
import { PosLayoutsStore } from '../../../services/stores/pos-layouts.store';
import { PosLayoutCardComponent } from './pos-layout-card/pos-layout-card.component';

@Component({
    selector: 'arc-poslayouts',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./poslayouts.component.scss']
})
export class PosLayoutsComponent extends PageListViewComponent<PosLayoutListModel, PosLayoutModel> {
    private readonly posLayoutsStore = inject(PosLayoutsStore);
    private readonly activatedRoute = inject(ActivatedRoute);

    constructor() {
        super('PosLayouts');

        this.config = new CardListViewConfigModel<PosLayoutModel, PosLayoutListModel>({
            entityName: 'PosLayouts',
            store: this.posLayoutsStore,
            cardComponent: PosLayoutCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            handleEditButtonClick: (item) => this.handleEdit(item.id),
            buttonsVisibility: new ButtonsVisibilityModel({ hasImport: false, hasExport: false })
        });
    }

    handleEdit(posLayoutId: number): Observable<boolean> {
        return from(this._router.navigate(['edit', posLayoutId], { relativeTo: this.activatedRoute }));
    }
}

