<div class="h-full w-full">
    <div *ngIf="isLoading; else chart" class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
    <ng-template #chart>
        <div *ngIf="!!chartData" class="h-full max-h-[170px]">
            <arc-chart [data]="chartData" type="bar"></arc-chart>
        </div>
        <div class="flex flex-row mt-4 justify-between w-full">
            <div>
                <span class="text-on-app-light text-sm">{{'Customers.Details.Turnover' | transloco}}</span>
                <div class="mt-2 text-2xl">{{ currentData?.totalTurnover | arcCurrency }}</div>
            </div>
            <div>
                <span class="text-on-app-light text-sm">{{'Customers.List.BonusPoints' | transloco}}</span>
                @if(bonusPointsPermission === true){
                    <div class="mt-2 text-2xl">{{ currentData?.currentBonusPoints | arcNumber : 0 }}</div>
                }
                @else {
                    <arc-no-permission-lock [missingPermission]="bonusPointsPermission"></arc-no-permission-lock>
                }
            </div>
            <div>
                <span class="text-on-app-light text-sm">{{'Customers.List.CustomerAccountBalance' | transloco}}</span>
                <div class="mt-2 text-2xl" [class.text-error]="currentData?.currentCustomerAccountBalance! < 0">
                    {{ currentData?.currentCustomerAccountBalance | arcCurrency }}
                </div>
            </div>
        </div>
    </ng-template>
</div>
