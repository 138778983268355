import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { UnitModel } from '../../models/unit.model';
import { UnitListModel } from '../../models/responses/unit-list.model';

@Injectable({
    providedIn: 'root'
})
export class UnitsStore extends BaseCrudStore<UnitModel, UnitListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'units' }));
    }
}
