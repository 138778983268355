<h1 mat-dialog-title>{{ 'Components.EmailVerificationDialog.Title' | transloco : { username } }}</h1>

@if (currentStep() === 1) {
    <mat-dialog-content>
        <div class="w-full flex flex-col gap-4 !mt-4">
            {{ 'Components.EmailVerificationDialog.FirstStepMessage' | transloco }}
            <arc-input type="email" placeholderKey="General.Email" [control]="emailControl"
                (keyup.enter)="requestConfirmationCode()"></arc-input>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <arc-button class="mr-4 mb-4" type="flat" color="accent" (clicked)="requestConfirmationCode()" [isDisabled]="!emailControl.valid"
            [isLoading]="isLoading()">
            {{ 'Components.EmailVerificationDialog.RequestConfirmationCode' | transloco }}
        </arc-button>
    </mat-dialog-actions>
} @else {
    <mat-dialog-content>
        <div class="w-full flex flex-col gap-4 !mt-4">
            {{ 'Components.EmailVerificationDialog.SecondStepMessage' | transloco : { email: emailControl.value } }}
            <div class="w-full flex justify-center">
                <arc-input class="!grow-0 mt-4 !w-32" inputClasses="!text-2xl !text-center" mask="0000" [showMaskTyped]="true"
                    [control]="verificationControl" (keyup.enter)="verifyEmail()">
                </arc-input>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <arc-button type="stroked" (clicked)="currentStep.set(1)" class="mb-4">
            {{ 'Components.EmailVerificationDialog.Return' | transloco }}
        </arc-button>
        <arc-button type="flat" color="accent" class="mr-4 mb-4" (clicked)="verifyEmail()" [isDisabled]="!verificationControl.valid"
            [isLoading]="isLoading()">
            {{ 'Components.EmailVerificationDialog.Confirm' | transloco }}
        </arc-button>
    </mat-dialog-actions>
}
