import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GeneralPromptDialogDataModel } from './models/general-prompt-dialog-data.model';
import { DialogButtonConfigurationEnum } from './models/enums/dialog-button-configuration.enum';

@Component({
    selector: 'arc-general-prompt-dialog',
    templateUrl: './general-prompt-dialog.component.html',
    styleUrls: ['./general-prompt-dialog.component.scss']
})
export class GeneralPromptDialogComponent {
    DialogButtonConfigurationEnum = DialogButtonConfigurationEnum;
    readonly data: GeneralPromptDialogDataModel = inject(MAT_DIALOG_DATA);
}
