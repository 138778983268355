import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../../../models/transaction.model';

@Component({
    selector: 'arc-transaction-customer',
    templateUrl: './transaction-customer.detail-widget.html',
    styleUrls: ['./transaction-customer.detail-widget.scss']
})
export class TransactionCustomerDetailWidget extends BaseDetailWidget<TransactionListModel, TransactionModel> {}
