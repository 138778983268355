import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';

@Component({
    selector: 'arc-customer-contact-information',
    templateUrl: './customer-contact-information.detail-widget.html',
    styleUrls: ['./customer-contact-information.detail-widget.scss']
})
export class CustomerContactInformationDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> {}
