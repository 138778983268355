import { EventEmitter, Injectable, Output, signal } from '@angular/core';

import { CreateTicketStepEnum } from '../../app/models/enums/create-ticket-step.enum';
import { TicketCreateResponseModel } from '../../app/models/responses/ticket-create-response.model';
import { TicketActionButtonModel } from '../models/ticket-action-button.model';
import { TicketCreateRequestModel } from '../../app/models/requests/ticket-create-request.model';
import { HelpArticleModel } from '../../app/models/responses/help-article.model';

@Injectable({
    providedIn: 'root'
})
export class CreateTicketService {
    @Output() readonly completed = new EventEmitter<void>();
    isLoading = signal(false);
    currentStep = signal(CreateTicketStepEnum.None);
    createTicketResponse = signal<TicketCreateResponseModel | undefined>(undefined);
    createTicketRequest = signal<TicketCreateRequestModel | undefined>(undefined);
    selectedArticle = signal<HelpArticleModel | undefined>(undefined);

    readonly buttons = signal<TicketActionButtonModel[]>([]);

    addButtons(...newButtons: TicketActionButtonModel[]): void {
        newButtons = newButtons.filter(button => this.buttons().findIndex(b => b.id === button.id) === -1);
        this.buttons.update(buttons => [...buttons, ...newButtons]);
    }
    reset(): void {
        this.currentStep.set(CreateTicketStepEnum.None);
        this.buttons.set([]);
        this.createTicketResponse.set(undefined);
        this.createTicketRequest.set(undefined);
        this.selectedArticle.set(undefined);
        this.completed.emit();
        this.isLoading.set(false);
    }
    startNewTicket(): void {
        this.currentStep.set(CreateTicketStepEnum.CreateRequest);
    }
    acceptSolution(): void {
        this.reset();
    }
    createTicket(): void {
        this.currentStep.set(CreateTicketStepEnum.CreateTicket);
    }
    ticketResponseCreated(ticketResponse?: TicketCreateResponseModel): void {
        this.createTicketResponse.set(ticketResponse);
        this.currentStep.set(CreateTicketStepEnum.RequestCreated);
        this.isLoading.set(false);
    }
    saveCreateRequest(ticket: TicketCreateRequestModel): void {
        this.createTicketRequest.set(ticket);
    }
}
