<div class="flex flex-col gap-4 p-4">
    <div class="flex justify-between items-center">
        @if(isPreviewMode) {
        <p class="text-xl">{{ 'Components.BulkSendEditor.Preview' | transloco }}</p>
        }
        <div class="flex items-center gap-6">
            @if(!isPreviewMode) {
                <arc-button-toggle-group [control]="editLanguage" [items]="languageItems" toggleButtonClasses="w-[50px]"></arc-button-toggle-group>
            }
        </div>

        @if(!isPreviewMode && this.formGroup.value.canSaveTemplate) {
            <arc-checkbox [control]="formGroup.controls.saveTemplate" labelKey="Components.BulkSendEditor.SaveTemplate"></arc-checkbox>
        }
        <div class="flex">
            @if(isPreviewMode && totalPages > 1) {
                <div class="flex items-center">
                    <button mat-icon-button (click)="previousPage($event)" [disabled]="!canGoPrevious">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                    <div class="items-center">{{ currentPage + 1 }} / {{ totalPages }}</div>
                    <button mat-icon-button (click)="nextPage($event)" [disabled]="!canGoNext">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            } 
            @if(!isPreviewMode) {
                <div class="flex">
                    <button mat-icon-button (click)="cancel($event)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-icon-button (click)="save($event)">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
            } @else {
                <button mat-icon-button (click)="showEdit($event)" class="items-end">
                    <mat-icon>edit</mat-icon>
                </button>
            }
        </div>
    </div>

    <!-- Edit/Preview section -->
    @if(isPreviewMode) {
        <div class="mb-4 font-bold" [innerHTML]="previewTitle | replacePlaceholder: options: additionalData?.[currentPage]"></div>
        <div markdown class="prose prose-indigo" [data]="previewText | replacePlaceholder: options: additionalData?.[currentPage]"></div>
    } @else {
        <arc-input labelKey="Components.BulkSendEditor.Title" [control]="formGroup.controls.title"></arc-input>
        <arc-markdown-editor [formControl]="formGroup.controls.text"> </arc-markdown-editor>
        <div class="flex flex-wrap gap-2">
            <div
                *ngFor="let tag of tags"
                (click)="handleTagClick(tag)"
                class="px-4 py-1 rounded-full w-fit line-clamp-1 bg-info-light text-on-info-light cursor-pointer">
                {{ tag.value }}
            </div>
        </div>
    }
</div>
