import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';

@Component({
    selector: 'arc-invoice-customer-details',
    templateUrl: './invoice-customer-details.detail-widget.html',
    styleUrls: ['./invoice-customer-details.detail-widget.scss']
})
export class InvoiceCustomerDetailsDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> {}
