@if (castedColumnModel.isEditable && !!control) {
    @if (castedColumnModel.hasTimePicker) {
        <mat-form-field subscriptSizing="dynamic">
            <input matInput type="text" mwlFlatpickr [formControl]="control | formControl" [altInput]="true" [convertModelValue]="true"
                [enableTime]="castedColumnModel.hasTime" [locale]="locale" [dateFormat]="dateFormat" [altFormat]="dateFormat" [time24hr]="true"
                [minDate]="minDate">
        </mat-form-field>
    } @else {
        <arc-datepicker [control]="control | formControl"></arc-datepicker>
    }
} @else {
    <span [class.line-clamp-2.break-words]="!castedColumnModel.isCompact" [class.truncate]="castedColumnModel.isCompact">
        <span class="text-on-app" [class.text-sm]="castedColumnModel.isCompact" [class.font-bold]="isBold(castedColumnModel.propertyName)">
            {{ getDateValue() }}
        </span>
    </span>
}
