import { Component, Input, effect, signal, untracked } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { OptionalType } from '../../../core/models/types/optional.type';
import { Utils } from '../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-chip-selector',
    templateUrl: './chip-selector.component.html',
    styleUrl: './chip-selector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ChipSelectorComponent,
            multi: true
        }
    ]
})
export class ChipSelectorComponent<T = any> extends BaseControlValueAccessor<T[]> {
    @Input({ required: true }) displayFn!: (option: T) => string;
    @Input({ required: true }) uniqueIdFn!: (option: T) => string | number;
    @Input() searchFn!: (query: string) => Observable<T[]>;
    @Input() bgColorFn?: (option: T) => OptionalType<string>;
    @Input() fgColorFn?: (option: T) => OptionalType<string>;
    @Input() addFn?: (value: string) => Observable<T>;
    @Input() allowEmptySearch = true;

    searchText = signal<string>('');
    searchResults = signal<T[]>([]);
    isSearching = signal<boolean>(false);

    private readonly internalSearchSubject = new Subject<string>();
    private readonly debounceTimeMs = 250;

    constructor() {
        super();

        this.internalSearchSubject
            .pipe(
                distinctUntilChanged((prev, curr) => Utils.areEqual(prev, curr)),
                filter(searchText => this.allowEmptySearch || !!searchText),
                tap(() => {
                    this.searchResults.set([]);
                    this.isSearching.set(true);
                }),
                debounceTime(this.debounceTimeMs),
                switchMap(searchText => this.searchFn(searchText))
            )
            .subscribe(options => {
                this.searchResults.set(options);
                this.isSearching.set(false);
            });

        effect(() => {
            const searchText = this.searchText();
            if (typeof searchText !== 'string') {
                return;
            }

            untracked(() => this.internalSearchSubject.next(searchText));
        });
    }

    remove(entry: T): void {
        this.valueChanged((this.value ?? []).filter(e => e !== entry));
    }

    getBgColor(entry: T): OptionalType<string> {
        return this.bgColorFn?.(entry);
    }

    getFgColor(entry: T): OptionalType<string> {
        return this.fgColorFn?.(entry);
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const optionToAdd = event.option.value as T;
        event.source.options.forEach(option => option.deselect());

        if (!!this.value && this.value.some(entry => this.uniqueIdFn(entry).toString() === this.uniqueIdFn(optionToAdd).toString())) {
            return;
        }

        this.valueChanged([...(this.value ?? []), optionToAdd]);

        // clear search input
        this.searchText.set('');
    }

    add(event: MatChipInputEvent): void {
        if (!!this.addFn && !!event.value) {
            this.addFn(event.value).subscribe(r => {
                if (!!r) {
                    this.valueChanged([...(this.value ?? []), r]);
                    this.searchText.set('');
                }
            });
        }
    }
}
