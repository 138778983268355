<ng-container *ngIf="entityModel.type.isDiscountVoucher; else valueVoucher">
    <div class="w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-8">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Vouchers.Detail.VoucherTypeWidget' | transloco }}</p>
                <p class="text-xl font-semibold">{{ entityModel.type.title }}</p>
            </div>
            <div>
                <div class="flex gap-8">
                    <div class="mb-4">
                        <p class="text-on-app-light">{{ 'VoucherTypes.ValidFrom' | transloco }}</p>
                        <p class="text-xl" [class.text-error]="entityModel.type.validFrom > today">
                            {{ entityModel.type.validFrom | arcDate }}
                        </p>
                    </div>
                    <div>
                        <p class="text-on-app-light">{{ 'VoucherTypes.ValidUntil' | transloco }}</p>
                        <p class="text-xl" [class.text-error]="entityModel.type.validThru < today">
                            {{ entityModel.type.validThru | arcDate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Vouchers.List.Discount' | transloco }}</p>
                <p class="text-xl font-semibold">
                    <ng-container *ngIf="Tools.Utils.isNumber(entityModel.type.discount); else percentTpl">
                        {{entityModel.type.discount | arcCurrency}}
                    </ng-container>
                    <ng-template #percentTpl>
                        {{entityModel.type.discount}}
                    </ng-template>
                </p>
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'Vouchers.Detail.ValidForBranch' | transloco }}</p>
                <p>{{ entityModel.type.validForBranch || allText }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.ValidFromValue' | transloco }}</p>
                <p>
                    <ng-container *ngIf="Tools.Utils.isNumber(entityModel.type.validFromAmount); else allTextTpl">
                        {{ entityModel.type.validFromAmount | arcCurrency }}
                    </ng-container>
                </p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'Vouchers.Detail.ValidForTags' | transloco }}</p>
                <p>{{ voucherTypeTags }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.ValidForArticleMinPrice' | transloco }}</p>
                <p>
                    <ng-container *ngIf="Tools.Utils.isNumber(entityModel.type.validForArticleMinPrice); else allTextTpl">
                        {{ entityModel.type.validForArticleMinPrice | arcCurrency }}
                    </ng-container>
                </p>
            </div>
        </div>
        <!-- third column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.ValidForGroup' | transloco }}</p>
                <p>{{ entityModel.type.validForArticleGroup || allText}}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.BonusFactor' | transloco }}</p>
                @if(bonusProgramPermission !== true){
                    <div class="flex align-start">
                        <arc-no-permission-lock [missingPermission]="bonusProgramPermission"></arc-no-permission-lock>
                    </div>
                }
                @else if (Tools.Utils.isNumber(entityModel.type.validForArticleMinPrice)){
                    <p>{{ entityModel.type.validForArticleMinPrice | arcCurrency }}</p>
                }
                @else {
                    <p>{{allText}}</p>
                }
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-2 mt-2">
        <div *ngIf="entityModel.type.cumulateVouchers" class="flex flex-col col-span-3 bg-power-search p-2 rounded items-center">
            <p class="break-words break-text text-center text-sm text-on-power-search">
                {{ 'VoucherTypes.Edit.VoucherCanBeAccumulated' | transloco }}
            </p>
        </div>
        <div *ngIf="entityModel.type.cumulateDiscounts" class="flex flex-col col-span-3 bg-power-search p-2 rounded items-center">
            <p class="break-words break-text text-center text-sm text-on-power-search">
                {{ 'VoucherTypes.Edit.DiscountCanBeAccumulated' | transloco }}
            </p>
        </div>
        <div *ngIf="entityModel.type.isReusable" class="flex flex-col col-span-3 bg-power-search p-2 rounded items-center">
            <p class="break-words break-text hyphens-auto text-center text-sm text-on-power-search">
                {{ 'VoucherTypes.Edit.IsReusable' | transloco }}
            </p>
        </div>
    </div>
</ng-container>
<ng-template #valueVoucher>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-8">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Vouchers.Detail.VoucherTypeWidget' | transloco }}</p>
                <p class="text-xl font-semibold">{{ entityModel.type.title }}</p>
            </div>
            <div>
                <div class="flex gap-8">
                    <div class="mb-4">
                        <p class="text-on-app-light">{{ 'VoucherTypes.ValidFrom' | transloco }}</p>
                        <p class="text-xl" [class.text-error]="entityModel.type.validFrom > today">
                            {{ entityModel.type.validFrom | arcDate }}
                        </p>
                    </div>
                    <div>
                        <p class="text-on-app-light">{{ 'VoucherTypes.ValidUntil' | transloco }}</p>
                        <p class="text-xl" [class.text-error]="entityModel.type.validThru < today">
                            {{ entityModel.type.validThru | arcDate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4 mt-2">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.PaymentType' | transloco }}</p>
                <p *ngIf="!!entityModel.type.paymentTypeDesc">{{ entityModel.type.paymentTypeDesc }}</p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs">{{ 'VoucherTypes.Edit.MinimumValue' | transloco }}</p>
                <p *ngIf="!!entityModel.type.minimalVoucherValue">{{ entityModel.type.minimalVoucherValue | arcCurrency }}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #allTextTpl>
    {{allText}}
</ng-template>
