@if(isPayment()){
    <div class="flex flex-col gap-4">
        <span>{{'ExtAccountingExport.Edit.FilterTextPayments' | transloco}}</span>
        <div class="grid grid-cols-2 gap-4">
            <arc-general-data-select [formControl]="formGroup.controls.branchId" [generalDataType]="GeneralDataTypeEnum.Branches"
                label="ExtAccountingExport.EditFields.Branch" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.storeId" [generalDataType]="GeneralDataTypeEnum.Stores"
                label="ExtAccountingExport.EditFields.Store" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.transactionTypeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionTypes" label="ExtAccountingExport.EditFields.TransactionType"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.transactionPurposeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionPurposes" label="ExtAccountingExport.EditFields.TransactionPurpose"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
        </div>
    </div>
} @else {
    <div class="flex flex-col gap-4">
        <span>{{'ExtAccountingExport.Edit.FilterTextArticleProperties' | transloco}}</span>
        <div class="flex items-baseline gap-4">
            <span>{{'ExtAccountingExport.EditFields.TaxRate' | transloco}}</span>
            <arc-button-toggle-group [control]="formGroup.controls.taxRate" toggleGroupClasses="grow"
                [items]="articleTaxes"></arc-button-toggle-group>
        </div>
        <div class="flex gap-4 items-baseline">
            <arc-tree-autocomplete label="ExtAccountingExport.EditFields.ArticleGroup" class="grow"
                [formControl]="formGroup.controls.articleGroupId" [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfig"
                [hasFloatingLabel]="true" [placeholder]="'General.All' | transloco">
            </arc-tree-autocomplete>
            <span>{{'ExtAccountingExport.EditFields.Without' | transloco}}</span>
            <arc-tree-autocomplete label="ExtAccountingExport.EditFields.ArticleGroup" class="grow"
                [formControl]="formGroup.controls.exclArticleGroupId" [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfig"
                [hasFloatingLabel]="true" placeholder="-">
            </arc-tree-autocomplete>
        </div>
        <div class="flex items-baseline gap-4">
            <span>{{'ExtAccountingExport.EditFields.ArticleAmount' | transloco}}</span>
            <arc-button-toggle-group toggleGroupClasses="grow" [control]="formGroup.controls.isNegativeAmount"
                [items]="articleAmounts"></arc-button-toggle-group>
        </div>
        <span class="mt-4">{{'ExtAccountingExport.Edit.FilterTextTransactions' | transloco}}</span>
        <div class="grid grid-cols-2 gap-4">
            <arc-general-data-select [formControl]="formGroup.controls.branchId" [generalDataType]="GeneralDataTypeEnum.Branches"
                label="ExtAccountingExport.EditFields.Branch" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.storeId" [generalDataType]="GeneralDataTypeEnum.Stores"
                label="ExtAccountingExport.EditFields.Store" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.customerProfileId"
                [generalDataType]="GeneralDataTypeEnum.CustomerProfiles" label="ExtAccountingExport.EditFields.CustomerProfile"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <arc-general-data-select [formControl]="formGroup.controls.transactionPurposeId"
                [generalDataType]="GeneralDataTypeEnum.TransactionPurposes" label="ExtAccountingExport.EditFields.TransactionPurpose"
                [placeholder]="'General.All' | transloco" [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
        </div>
    </div>
}
