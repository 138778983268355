import { SearchRequestModel } from './search-request.model';

export class RelatedEntityRequestModel<TId = number> extends SearchRequestModel {
    addedIds: TId[] = [];

    constructor(init?: Partial<RelatedEntityRequestModel<TId>>) {
        super();

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
