import { Component, inject, OnInit } from '@angular/core';

import { StatusMessageTypeEnum } from '../../app/models/enums/status-message-type.enum';
import { StatusMessageModel } from '../../app/models/status-message.model';
import { SecuritySessionStorage } from '../../core/services/storages/security.session-storage';
import { UserService } from '../../core/services/user.service';

@Component({
    selector: 'arc-status-message',
    templateUrl: './status-message.component.html',
    styleUrl: './status-message.component.scss'
})
export class StatusMessageComponent implements OnInit {
    StatusMessageTypeEnum = StatusMessageTypeEnum;
    statusMessages: StatusMessageModel[] = [];

    private readonly securitySession = inject(SecuritySessionStorage);
    private readonly userService = inject(UserService);

    ngOnInit(): void {
        this.setupMessages();
    }

    dismiss(statusMessage: StatusMessageModel): void {
        this.securitySession.dismissStatusMessage(statusMessage);
        this.setupMessages();
    }

    getStyleClasses(statusMessage: StatusMessageModel): string {
        switch (statusMessage.type) {
            case StatusMessageTypeEnum.Error:
                return 'bg-error text-on-error';
            case StatusMessageTypeEnum.Warning:
                return 'bg-warn text-on-warn';
            case StatusMessageTypeEnum.Info:
                return 'bg-info text-on-info';
        }
    }

    private setupMessages(): void {
        this.userService.getUserInfoAsync().then(res => {
            if (!!res) {
                this.statusMessages = this.securitySession.getStatusMessages().filter(sm => !sm.hasBeenDismissed);
            }
        });
    }
}
