import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { BranchListModel } from '../../../models/responses/branches-list.model';
import { BranchModel } from '../../../models/branch.model';
import { BranchesStore } from '../../../services/stores/branches.store';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { BranchEditBaseDataComponent } from './branch-edit-items/branch-edit-base-data/branch-edit-base-data.component';
import { BranchEditReceiptComponent } from './branch-edit-items/branch-edit-receipt/branch-edit-receipt.component';
import { BranchEditModel } from '../../../models/branch-edit.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-branches',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./branches.component.scss']
})
export class BranchesComponent extends PageListViewComponent<BranchListModel, BranchModel, BranchEditModel> {
    private readonly branchesStore = inject(BranchesStore);

    constructor() {
        super('Branches');
        this.config = new TableListViewConfigModel<BranchListModel, BranchModel, BranchEditModel, BranchEditModel>({
            entityName: 'Branches',
            availableColumns: {
                branch: new StringColumnModel({
                    columnTitleKey: 'Branches.List.Name',
                    propertyName: 'name',
                    widthPixels: 200
                }),
                region: new StringColumnModel({
                    columnTitleKey: 'Branches.List.Region',
                    propertyName: 'regionName',
                    widthPixels: 200,
                    requiredPermission: PermissionsEnum.Regions
                }),
                place: new StringColumnModel({
                    columnTitleKey: 'Branches.List.Place',
                    propertyName: 'place',
                    widthPixels: 200
                }),
                store: new StringColumnModel({
                    columnTitleKey: 'Branches.List.Store',
                    propertyName: 'storeName',
                    widthPixels: 200,
                    requiredPermission: PermissionsEnum.Stores
                }),
                orderStore: new StringColumnModel({
                    columnTitleKey: 'Branches.List.OrderStore',
                    propertyName: 'orderStoreName',
                    widthPixels: 200,
                    requiredPermission: PermissionsEnum.Stores
                }),
                posCount: new StringColumnModel({
                    columnTitleKey: 'Branches.List.PosCount',
                    propertyName: 'posCount',
                    widthPixels: 150
                })
            },
            defaultColumnOrder: [
                'branch',
                'region',
                'place',
                'store',
                'orderStore',
                'posCount'
            ],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            defaultSort: new ColumnSortModel({
                column: 'name',
                direction: SortDirectionEnum.Asc
            }),
            store: this.branchesStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Branches.Edit.BaseData', component: BranchEditBaseDataComponent },
                    { titleKey: 'Branches.Edit.Receipt', component: BranchEditReceiptComponent }
                ],
                headerSubtitle: currentItem => of(currentItem.name)
            }
        });
    }
}
