import { Component, effect, inject, signal, untracked, WritableSignal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { AccountingExportRecordModel } from '../../../../../models/accounting-export-record.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleAmountFieldEnum } from '../../../../../models/enums/article-amount-field.enum';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';

@Component({
    selector: 'arc-accounting-export-records-edit-data',
    templateUrl: './accounting-export-records-edit-data.component.html',
    styleUrl: './accounting-export-records-edit-data.component.scss'
})
export class AccountingExportRecordsEditDataComponent extends BaseEditSidebarItemComponent<AccountingExportRecordModel> {
    static isPaymentSignal = 'isPayment';

    override formGroup = new FormGroup({
        isPayment: new ArcFormControl(false),
        transactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        articleAmountField: new ArcFormControl<OptionalType<ArticleAmountFieldEnum>>(undefined, Validators.required),
        paymentTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        cardType: new ArcFormControl<OptionalType<string>>(undefined),
        exclCardType: new ArcFormControl<OptionalType<string>>(undefined)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    ArticleAmountFieldEnum = ArticleAmountFieldEnum;

    isVATincl = signal(false);

    isPayment: WritableSignal<OptionalType<boolean>>;
    articleAmountOptions: SelectOptionModel<string>[] = [
        {
            label: 'ExtAccountingExport.EditFields.SellingPrice',
            value: ArticleAmountFieldEnum.Price
        },
        {
            label: 'ExtAccountingExport.EditFields.NetPrice',
            value: ArticleAmountFieldEnum.NetPrice
        },
        {
            label: 'ExtAccountingExport.EditFields.BuyingPrice',
            value: ArticleAmountFieldEnum.BuyingPrice
        },
        {
            label: 'ExtAccountingExport.EditFields.Discount',
            value: ArticleAmountFieldEnum.Discount
        },
        {
            label: 'ExtAccountingExport.EditFields.NetDiscount',
            value: ArticleAmountFieldEnum.NetDiscount
        }
    ];
    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();
        this.isPayment = this.sharedDataService.getOrCreateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal);

        effect(() => {
            const isPayment = this.isPayment();
            untracked(() => {
                // reset values of other type
                this.formGroup.controls.isPayment.setValue(isPayment!);
                if (isPayment) {
                    this.formGroup.controls.transactionTypeId.disable();
                    this.formGroup.controls.articleAmountField.disable();
                    this.formGroup.controls.paymentTypeId.enable();
                    this.formGroup.controls.cardType.enable();
                    this.formGroup.controls.exclCardType.enable();
                } else {
                    this.formGroup.controls.transactionTypeId.enable();
                    this.formGroup.controls.articleAmountField.enable();
                    this.formGroup.controls.paymentTypeId.disable();
                    this.formGroup.controls.cardType.disable();
                    this.formGroup.controls.exclCardType.disable();
                }
            });
        });
    }

    onItemSet(): void {
        this.sharedDataService.setOrUpdateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal, this.item.isPayment);
        this.formGroup.patchValue(this.item);
    }

    mapArticleAmountOnSet(articleAmount: ArticleAmountFieldEnum): ArticleAmountFieldEnum {
        if (articleAmount === ArticleAmountFieldEnum.Price) {
            this.isVATincl.set(true);
            return ArticleAmountFieldEnum.NetPrice;
        }
        if (articleAmount === ArticleAmountFieldEnum.Discount) {
            this.isVATincl.set(true);
            return ArticleAmountFieldEnum.NetDiscount;
        }

        return articleAmount;
    }

    mapArticleAmountOnSave(articleAmount?: ArticleAmountFieldEnum): OptionalType<ArticleAmountFieldEnum> {
        if (articleAmount === ArticleAmountFieldEnum.NetPrice && this.isVATincl()) {
            return ArticleAmountFieldEnum.Price;
        }
        if (articleAmount === ArticleAmountFieldEnum.NetDiscount && this.isVATincl()) {
            return ArticleAmountFieldEnum.Discount;
        }

        return articleAmount;
    }

    override prepareSaveModel(): Partial<AccountingExportRecordModel> {
        const value = this.formGroup.value;
        return {
            isPayment: value.isPayment,
            transactionTypeId: value.transactionTypeId,
            articleAmountField: value.articleAmountField,
            paymentTypeId: value.paymentTypeId,
            cardType: value.cardType?.toString(),
            exclCardType: value.exclCardType?.toString()
        };
    }

    changeIsPayment(event: MatButtonToggleChange): void {
        this.sharedDataService.setOrUpdateSignal(AccountingExportRecordsEditDataComponent.isPaymentSignal, event.value);
    }
}
