import { ComponentType } from '@angular/cdk/portal';

import { BaseDetailWidget } from '../../../abstractions/base.detail-widget';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { Identifyable } from '../../../../core/abstractions/identifyable';

export class TableListDetailWidgetModel<TList extends Identifyable<TId>, T extends Identifyable<TId>, TId = number> {
    name!: string;
    component!: ComponentType<BaseDetailWidget<TList, T, TId>>;
    requiredPermission?: PermissionsEnum;
    takeFullSizeIfOnly = false;
    shouldRemovePadding = false;

    constructor(init?: Partial<TableListDetailWidgetModel<TList, T, TId>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
