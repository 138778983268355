<h1 mat-dialog-title>{{ data.promptKey | transloco: data.promptParams }}</h1>
@if (!!data.customDeleteConfirmationMessage || !!data.messageKey) {
    <mat-dialog-content>
        @if (!!data.customDeleteConfirmationMessage) {
            {{ data.customDeleteConfirmationMessage }}
        } @else if(data.isMessageHTML) {
            <div [innerHTML]="data.messageKey"></div>
        } @else if(data.isMessageTranslated) {
            {{data.messageKey}}
        } @else {
            {{ data.messageKey | transloco: data.promptParams }}
        }
    </mat-dialog-content>
}
<mat-dialog-actions>
    @switch (data.buttonConfiguration) {
        @case (DialogButtonConfigurationEnum.DeleteCancel) {
            <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
            <button mat-flat-button color="warn" [mat-dialog-close]="true">
                <mat-icon>delete</mat-icon>
                {{ 'General.Actions.Delete' | transloco }}
            </button>
        }        
        @case (DialogButtonConfigurationEnum.ConfirmCancel) {
            <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
            <button mat-flat-button color="accent" [mat-dialog-close]="true">
                <mat-icon>check</mat-icon>
                {{ 'General.Alert.ConfirmButtonText' | transloco }}
            </button>
        }
        @case (DialogButtonConfigurationEnum.Ok) {
            <button mat-flat-button color="accent" [mat-dialog-close]="true">
                <mat-icon>check</mat-icon>
                {{ 'General.Alert.Ok' | transloco }}
            </button>
        }
        @default {
            <button mat-stroked-button cdkFocusInitial [mat-dialog-close]="false">{{ 'General.Actions.No' | transloco }}</button>
            <button mat-flat-button color="accent" [mat-dialog-close]="true">{{ 'General.Actions.Yes' | transloco }}</button>
        }
    }
</mat-dialog-actions>
