import { Component, inject, ViewChild, ElementRef } from '@angular/core';
import { of, finalize } from 'rxjs';

import { ArticleGroupsStore } from '../../../services/stores/article-groups.store';
import { DynamicTreeDatabase } from '../../../../components/dialogs/tree-data-selection-dialog/utils/dynamic-tree.database';
import { TreeConfigSetupService } from '../../../../components/dialogs/tree-data-selection-dialog/utils/tree-config-setup.service';
import { TreeDataSelectionConfig } from '../../../../components/form/tree-autocomplete/models/tree-data-selection.config';
import { generateArticleGroupConfig } from '../../../services/tree-autocomplete-configs.service';
import { BaseTreeListComponent } from '../../../../core/abstractions/base.tree-list.component';
import { TreeItemFlatNode } from '../../../../core/models/tree-item.flat-node';
import { ArticleGroupEditComponent } from './components/article-group-edit/article-group-edit.component';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { AlertService } from '../../../../core/services/alert.service';
import { SearchRequestService } from '../../../../core/services/search-request.service';
import { FilterService } from '../../../../core/services/filter.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { ArticleGroupListModel } from '../../../models/responses/article-group-list.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

function treeConfigFactory(
    articleGroupsStore: ArticleGroupsStore,
    translationService: TranslationService
): TreeDataSelectionConfig<ArticleGroupListModel> {
    return generateArticleGroupConfig(articleGroupsStore, translationService);
}

/**
 * IMPORTANT!
 * This component is expected to behave similarly to the TreeDataSelectionDialogComponent.
 * If one change is made here, that would alter its behavior in a way that should be replicated there, we should do so.
 */
@Component({
    selector: 'arc-article-groups',
    templateUrl: './article-groups.component.html',
    styleUrl: './article-groups.component.scss',
    // Tree list components need their own instances of these services.
    providers: [
        SearchRequestService,
        FilterService,
        TreeConfigSetupService,
        DynamicTreeDatabase,
        { provide: TreeDataSelectionConfig, useFactory: treeConfigFactory, deps: [ArticleGroupsStore, TranslationService] }
    ]
})
export class ArticleGroupsComponent extends BaseTreeListComponent<ArticleGroupListModel> {
    @ViewChild('newRecordInput') _newRecordInput?: ElementRef;
    @ViewChild('movableItem') movableItem!: ElementRef;

    dragElement?: any;
    draggedNode?: TreeItemFlatNode<number>;
    dragNodeExpandOverWaitTimeMs = 600;
    dragNodeExpandOverTime = 0;
    dragNodeExpandOverNode?: TreeItemFlatNode<number>;

    readonly articleGroupsStore = inject(ArticleGroupsStore);
    readonly canEdit: boolean;
    readonly canDelete: boolean;

    private readonly nodeNameProp = 'MAT-TREE-NODE';
    private readonly hoverClasses = ['border', 'border-dashed', 'border-current', 'bg-app'];
    private readonly sidebarService = inject(SidebarService);
    private readonly alertService = inject(AlertService);
    private readonly dialogService = inject(DialogService);
    private readonly permissionsService = inject(PermissionService);

    constructor() {
        super();

        this._canCreate = this.permissionsService.hasPermission(PermissionsEnum.ArticleGroups, PermissionTypeEnum.Create) === true;
        this.canEdit = this.permissionsService.hasPermission(PermissionsEnum.ArticleGroups, PermissionTypeEnum.Update) === true;
        this.canDelete = this.permissionsService.hasPermission(PermissionsEnum.ArticleGroups, PermissionTypeEnum.Delete) === true;
    }

    openEdit(node: TreeItemFlatNode<number>): void {
        const entityName = 'ArticleGroups';
        this.sidebarService.openEdit({
            entityName,
            store: this.articleGroupsStore,
            editComponents: [{ titleKey: 'Articles.Edit.BaseData', component: ArticleGroupEditComponent }],
            existingId: node.id,
            headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(entityName),
            headerSubtitle: item => of(item.title)
        }).subscribe(shouldReload => {
            if (shouldReload) {
                this._searchRequestService.forceReload();
            }
        });
    }

    remove(node: TreeItemFlatNode<number>): void {
        this.dialogService
            .openDeleteDialog('ArticleGroups', 1)
            .afterClosed()
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.isLoading = true;
                    this.articleGroupsStore.remove(node.id)
                        .pipe(
                            finalize(() => this.isLoading = false)
                        )
                        .subscribe(() =>
                            this._searchRequestService.forceReload()
                        );
                }
            });
    }

    /**
     * Perform logic to move/merge groups.
     * Logic:
     * - First level groups cannot be moved.
     * - Second level groups can be moved to another first level parent - their children are moved with them.
     * - Third level groups can be moved to another second level parent.
     * - Third level groups moved onto another third level group can be merged.
     * @param event Drag event.
     * @param hoveredNode Node being hovered by the user.
     */
    handleDrop(event: DragEvent, hoveredNode: TreeItemFlatNode<number>): void {
        event.preventDefault();

        if (!this.canDrop(hoveredNode)) {
            return;
        }

        const draggedNodeId = this.draggedNode!.id;
        const hoveredNodeId = hoveredNode.id;

        if (draggedNodeId !== hoveredNodeId) {
            if (this.draggedNode!.level === 2 && hoveredNode.level === 2) {
                // Confirmation to ask about moving items to the new one and letting the user know the old one will be deleted.
                this.alertService.showConfirm(
                    'ArticleGroups.AreYouSureThirdLevelMerge',
                    undefined,
                    (isConfirmed) => {
                        if(isConfirmed) {
                            this.isLoading = true;
                            this.articleGroupsStore.moveArticles(draggedNodeId, hoveredNodeId).subscribe(() => {
                                this.isLoading = false;
                                this._searchRequestService.forceReload();
                            });
                        }
                    },
                    undefined,
                    true,
                    { oldGroup: this.draggedNode?.text, newGroup: hoveredNode.text }
                );
            } else {
                this.isLoading = true;
                this.articleGroupsStore.updateParentGroup(draggedNodeId, hoveredNodeId).subscribe(() => {
                    this.isLoading = false;
                    this._searchRequestService.forceReload();
                });
            }
        }

        this.resetDragNodes();
    }

    handleDragStart(event: DragEvent, node: TreeItemFlatNode<number>): void {
        this.draggedNode = node;

        event.dataTransfer?.setData('text/plain', node.id.toString()); // Required for Firefox.
        this.treeControl.collapse(node);
    }

    handleDragOver(event: DragEvent, hoveredNode: TreeItemFlatNode<number>): void {
        event.preventDefault();

        const element = (event.target as any).nodeName === this.nodeNameProp
            ? (event.target as any)
            : (event.target as any).parentElement;

        this.dragElement?.classList.remove(...this.hoverClasses);

        if (this.canDrop(hoveredNode)) {
            element.classList.add(...this.hoverClasses);
        }

        // Handling whether the node should be expanded.
        if (hoveredNode === this.dragNodeExpandOverNode) {
            if (hoveredNode.level <= 1 && this.draggedNode !== hoveredNode && !this.treeControl.isExpanded(hoveredNode)
                && (new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
                this.treeControl.expand(hoveredNode);
            }
        } else {
            this.dragElement = element;
            this.dragNodeExpandOverNode = hoveredNode;
            this.dragNodeExpandOverTime = new Date().getTime();
        }
    }

    handleDragEnd(): void {
        this.resetDragNodes();
    }

    private resetDragNodes(): void {
        this.dragElement?.classList.remove(...this.hoverClasses);

        this.dragElement = undefined;
        this.draggedNode = undefined;
        this.dragNodeExpandOverNode = undefined;
        this.dragNodeExpandOverTime = 0;
    }

    /**
     * Whether the item can be dropped in the current node.
     * Logic:
     * - First level groups cannot be moved.
     * - Second level groups can be moved to another first level parent - their children are moved with them.
     * - Third level groups can be moved to another second level parent.
     * - Third level groups moved onto another third level group can be merged.
     * @param hoveredNode Node being hovered by the user.
     * @private
     */
    private canDrop(hoveredNode: TreeItemFlatNode<number>): boolean {
        return !!this.draggedNode
            && (
                (this.draggedNode.level === 1 && hoveredNode.level === 0)
                || (this.draggedNode.level === 2 && (hoveredNode.level === 1 || hoveredNode.level === 2))
            );
    }
}
