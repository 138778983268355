<div
    class="relative p-4 rounded-lg size-full bg-on-app text-app"
    [style.background-color]="_posLayoutGroupCommand().backgroundColor"
    [style.color]="_posLayoutGroupCommand().foreColor" draggable="true">
    <!-- command content -->
    <div class="flex flex-col items-center justify-center overflow-hidden text-center size-full">
        @if (!!imageSrc()) {
            <img [src]="imageSrc()" class="flex items-center justify-center w-16 h-16 text-sm" />
        }

        <div class="line-clamp-2">{{ _posLayoutGroupCommand().title }}</div>
    </div>

    <!-- remove button -->
    <div class="absolute top-1 left-1">
        <button class="p-1 rounded-full" matRipple (click)="remove.emit()">
            <arc-icon icon="close" [size]="16"></arc-icon>
        </button>
    </div>

    <!-- edit button -->
    <div class="absolute bottom-1 left-1">
        <button class="p-1 rounded-full" matRipple (click)="edit.emit()">
            <arc-icon icon="edit" [size]="16"></arc-icon>
        </button>
    </div>

    <!-- expand/collapse button -->
    <div class="absolute bottom-1 right-1">
        @if (_posLayoutGroupCommand().isDoubleSize) {
            <button class="p-1 rounded-full" matRipple (click)="setIsDoubleSize(false)">
                <arc-icon class="rotate-90" icon="unfold_less" [size]="16"></arc-icon>
            </button>
        }
        @else if(canExpand()) {
            <button class="p-1 rounded-full" matRipple (click)="setIsDoubleSize(true)">
                <arc-icon class="rotate-90" icon="unfold_more" [size]="16"></arc-icon>
            </button>
        }
    </div>
</div>
