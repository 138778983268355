import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { InventoryArticleModel } from '../../models/inventory-article.model';
import { InventoryArticleListModel } from '../../models/responses/inventory-article-list.model';

@Injectable({
    providedIn: 'root'
})
export class InventoryArticlesStore extends BaseCrudStore<
InventoryArticleModel,
InventoryArticleListModel,
InventoryArticleListModel,
InventoryArticleListModel,
object
> {
    constructor() {
        super(new StoreConfig({ baseController: 'inventory-articles' }));
    }
}
