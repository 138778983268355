import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { StoreModel } from '../../../../../models/store.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';

@Component({
    selector: 'arc-settings-warehouse-stores',
    templateUrl: './settings-warehouse-stores.component.html',
    styleUrl: './settings-warehouse-stores.component.scss'
})
export class SettingsWarehouseStoresComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        stores: new ArcFormControl<StoreModel[]>([])
    });
    storesTableConfig: EditableTableConfigModel<StoreModel>;

    constructor() {
        super();
        this.storesTableConfig = new EditableTableConfigModel<StoreModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    name: new ArcFormControl('', Validators.required),
                    useCumulativeStockForOrder: new ArcFormControl(false)
                }),
            rowHeightPx: 63,
            columns: [
                new StringColumnModel({
                    propertyName: 'name',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Warehouse.Stores.StoreName'
                }),
                new CheckboxColumnModel({
                    propertyName: 'useCumulativeStockForOrder',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Warehouse.Stores.UseCumulativeStockForOrder'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.storesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { stores: this.formGroup.value.stores };
    }
}
