import { Component } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserEditModel } from '../../../../../models/user-edit.model';
import { UserModel } from '../../../../../models/user.model';
import { PosCashRegister } from '../../../../../models/pos-cash-registers.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-user-pos-cash-registers',
    templateUrl: './user-pos-cash-registers.component.html',
    styleUrl: './user-pos-cash-registers.component.scss'
})
export class UserPosCashRegistersComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        posCashRegisters: new ArcFormControl<PosCashRegister[]>([])
    });
    posCashRegistersTableConfig: EditableTableConfigModel<PosCashRegister>;

    constructor() {
        super();
        this.posCashRegistersTableConfig = new EditableTableConfigModel<PosCashRegister>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    balance: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
                    basis: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ min: 0.0 })),
                    currencyId: new ArcFormControl(1, [Validators.required, this.uniqueCurrencyValidator()]),
                    lastCheckDate: new ArcFormControl(new Date())
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new CurrencyColumnModel({
                    propertyName: 'balance',
                    isEditable: false,
                    currencyId: item => item.currencyId,
                    columnTitleKey: 'Users.Edit.PosCashRegisters.Balance'
                }),
                new CurrencyColumnModel({
                    propertyName: 'basis',
                    isEditable: true,
                    currencyId: item => item.currencyId,
                    columnTitleKey: 'Users.Edit.PosCashRegisters.Basis'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'currencyId',
                    isEditable: true,
                    generalDataType: GeneralDataTypeEnum.Currencies,
                    columnTitleKey: 'Users.Edit.PosCashRegisters.Currency',
                    onValueChanged: (item, newValue, updateValidity) => {
                        const posCashRegisters = this.formGroup.value.posCashRegisters;
                        this.formGroup.patchValue({ posCashRegisters });
                        // This is needed so to update validity of form on next event loop cycle
                        setTimeout(() => updateValidity(), 50);
                    }
                }),
                new DateColumnModel({
                    propertyName: 'lastCheckDate',
                    columnTitleKey: 'Users.Edit.PosCashRegisters.LastCheckDate',
                    format: 'short'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.posCashRegistersTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<UserEditModel> {
        return { posCashRegisters: this.formGroup.value.posCashRegisters };
    }

    uniqueCurrencyValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            const newCurrencyId = control.value;

            const isCurrencyExists = this.formGroup.value.posCashRegisters?.find(
                d => d.currencyId === newCurrencyId && control.parent?.value?.id > 0 && d.id !== control.parent?.value.id
            );

            if (isCurrencyExists) {
                return { uniqueCurrencies: true };
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }
}
