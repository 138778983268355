import { Component, HostBinding, input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TextareaComponent
        }
    ]
})
export class TextareaComponent extends BaseControlValueAccessor<string> {
    @HostBinding('class') classes = 'grow';

    minLength = input<number>();
    maxLength = input<number>();
    height = input<number>();

    onInput(): void {
        this.valueChanged(this.internalControl.value);
    }
}
