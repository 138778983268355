import { Injectable, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    get baseUrl(): string {
        let baseUrl = environment.baseUrl;

        // If no base URL is present for the current environment, we use the current path.
        if (!baseUrl || baseUrl.length < 1) {
            baseUrl = this.document.location.origin;
        }

        return baseUrl;
    }

    get reportingUrl(): string {
        let reportingUrl = environment.reportingUrl;

        // If no base URL is present for the current environment, we use the current path.
        if (!reportingUrl || reportingUrl.length < 1) {
            reportingUrl = this.document.location.origin;
        }

        return reportingUrl;
    }

    private document: Document = inject(DOCUMENT);

    getSubdomain(): string {
        const hostname = this.document.location.hostname;

        if (hostname.indexOf('.') > 0) {
            return hostname.split('.')[0];
        }
        return 'dev';
    }

}
