import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomerEditRequestModel } from '../../../../../models/requests/customer-edit-request.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';

@Component({
    selector: 'arc-customer-edit-marketing',
    templateUrl: './customer-edit-marketing.component.html',
    styleUrls: ['./customer-edit-marketing.component.scss']
})
export class CustomerEditMarketingComponent extends BaseEditSidebarItemComponent<CustomerModel, CustomerEditRequestModel>
    implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    PermissionsEnum = PermissionsEnum;
    PermissionTypeEnum = PermissionTypeEnum;
    override formGroup = new FormGroup({
        cardNumber: new ArcFormControl('', Validators.maxLength(20)),
        customerProfileId: new ArcFormControl<OptionalType<number>>(undefined),
        bonusPoints: new ArcFormControl(0, Validators.required)
    });

    private readonly permissionService = inject(PermissionService);
    private readonly customersStore = inject(CustomersStore);

    ngOnInit(): void {
        if (this.permissionService.hasPermission(PermissionsEnum.CustomerProfiles) === true) {
            this.formGroup.controls.customerProfileId.addValidators(Validators.required);
            this.formGroup.controls.customerProfileId.updateValueAndValidity();
        }
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);

        if (!this.item.cardNumber) {
            this.updateCardNumber();
        }
    }

    override prepareSaveModel(): Partial<CustomerEditRequestModel> {
        const value = this.formGroup.value;
        return {
            cardNumber: value.cardNumber,
            customerProfileId: value.customerProfileId,
            bonusPoints: value.bonusPoints
        };
    }

    updateCardNumberAction(): Observable<any> {
        return of(this.updateCardNumber());
    }

    private updateCardNumber(): void {
        this.customersStore.generateCardNumbers().subscribe(r => {
            if (!!r.value && r.value.length > 0) {
                this.formGroup.patchValue({ cardNumber: r.value[0] });
            }
        });
    }
}
