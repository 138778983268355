import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { ImportStore } from '../../app/services/stores/import.store';
import { Utils } from '../utils/tools/utils.tools';
import { ImportEntityColumnModel } from '../../app/models/import-entity-column.model';
import { ImportExecutionColumnModel } from '../../app/models/import-execution-column.model';
import { ImportExecutionResultResponseModel } from '../../app/models/responses/import-execution-result-response.model';
import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { ErrorsService } from './errors.service';
import { ImportExecutionRowStatusEnum } from '../../app/models/enums/import-execution-row-status.enum';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    entityType = '';
    fileColumns: string[] = [];
    entityImportColumns: ImportEntityColumnModel[] = [];
    fileMapping: ImportExecutionColumnModel[] = [];
    previewData?: ImportExecutionResultResponseModel;
    resultData?: ImportExecutionResultResponseModel;

    // INFO: For now, this is hardcoded to true
    private readonly shouldOverwriteExistingData = true;

    private readonly importStore = inject(ImportStore);
    private readonly errorsService = inject(ErrorsService);
    private readonly alertService = inject(AlertService);

    init(entityType: string): Observable<any> {
        this.entityType = entityType;
        this.fileColumns = [];
        this.entityImportColumns = [];
        this.fileMapping = [];
        this.previewData = undefined;
        this.resultData = undefined;
        this.errorsService.shouldDisplayAlertOnError = false;

        return this.importStore.getImportEntites().pipe(
            tap(importEntities => {
                this.entityImportColumns = importEntities.value?.find(e => e.id === this.entityType)?.columns ?? [];
            })
        );
    }

    uploadFile(file: File): Observable<boolean> {
        return Utils.blobToBase64(file).pipe(
            switchMap(result =>
                this.importStore.uploadFile(
                    this.entityType ?? '',
                    {
                        blob: result,
                        originalFileExtension: file.name.split('.').pop() ?? ''
                    }
                )
            ),
            map(result => {
                const newColumnNames = result.value?.columnNames ?? [];
                if (!Utils.areEqual(this.fileColumns, newColumnNames)) {
                    this.fileMapping = [];
                }
                this.fileColumns = newColumnNames;

                const suggestedMapping = result.value?.suggestedImportExecutionColumns ?? [];
                if (suggestedMapping.length > 0) {
                    this.fileMapping = suggestedMapping;
                }

                return true;
            }),
            catchError(() => of(false))
        );
    }

    preview(): Observable<ApiResponseModel<ImportExecutionResultResponseModel>> {
        this.previewData = undefined;
        return this.importStore
            .preview(this.entityType, this.shouldOverwriteExistingData, this.fileMapping)
            .pipe(tap(result => {
                this.previewData = result.value;
                if (!!result.value?.globalErrorMessage) {
                    this.alertService.showAlert(result.value.globalErrorMessage, undefined, true);
                }
            }));
    }

    executeImport(): Observable<ApiResponseModel<ImportExecutionResultResponseModel>> {
        this.resultData = undefined;

        const invalidEntities = this.previewData!.rows.filter(r => !!r.entity && r.status === ImportExecutionRowStatusEnum.Invalid);
        const invalidKeys = invalidEntities.map(e => e.key);

        return this.importStore
            .execute(this.entityType, this.shouldOverwriteExistingData, this.fileMapping, invalidKeys)
            .pipe(tap(result => {
                this.resultData = result.value;
                if (!!result.value?.globalErrorMessage) {
                    this.alertService.showAlert(result.value.globalErrorMessage, undefined, true);
                }
            }));
    }

    getImportErrorFile(): Observable<HttpResponse<Blob>> {
        return this.importStore.getImportErrorFile(this.entityType);
    }
}
