import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ReservationArticleListModel } from '../../models/responses/reservation-articles-list.model';
import { ReservationArticleModel } from '../../models/reservation-article.model';

@Injectable({
    providedIn: 'root'
})
export class ReservationArticlesStore extends BaseCrudStore<ReservationArticleModel, ReservationArticleListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'reservation-articles' }));
    }
}
