<div *ngIf="!!item.isDefault"
    class="absolute top-[10px] right-[10px] py-[2px] px-[5px] bg-element-selected rounded-3xl text-xs shadow">
    <div class="flex">
        <mat-icon class="text-base !h-4 !w-4 mr-1">check_circle</mat-icon> <span>{{'CustomerProfiles.Card.Standard' | transloco}}</span>
    </div>
</div>
<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'CustomerProfiles.Card.PersonsCount' | transloco }}</p>
        <p class="text-xl">{{ item.personsCount }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'CustomerProfiles.Card.BonusFactor' | transloco }}</p>
        <p class="text-xl">{{ item.bonusFactor ?  (item.bonusFactor | arcNumber : 2 : '': ' ' + ('CustomerProfiles.Card.PointsPer' | transloco) + ' ' + currencyCode) : 'CustomerProfiles.Card.NoBonusFactor' | transloco }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'CustomerProfiles.Card.Turnover' | transloco }}</p>
        <p class="text-xl">{{ item.turnover | arcCurrency }}</p>
    </div>
</div>
