import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { InvoiceEditRequestModel } from '../../../../../models/requests/invoice-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { InvoiceModel } from '../../../../../models/invoice.model';

@Component({
    selector: 'arc-invoice-edit-base-data',
    templateUrl: './invoice-edit-base-data.component.html',
    styleUrls: ['./invoice-edit-base-data.component.scss']
})
export class InvoiceEditBaseDataComponent extends BaseEditSidebarItemComponent<InvoiceModel, InvoiceEditRequestModel> {
    override formGroup = new FormGroup({
        dueDate: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        reminderDate1: new ArcFormControl<OptionalType<Date>>(undefined),
        reminderDate2: new ArcFormControl<OptionalType<Date>>(undefined),
        datePaid: new ArcFormControl<OptionalType<Date>>(undefined),
        remarks: new ArcFormControl<OptionalType<string>>(undefined)
    });

    shouldHaveReminderDate1 = false;
    shouldHaveReminderDate2 = false;
    shouldHaveDatePaid = false;

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
        if (!!this.formGroup.value.reminderDate1) {
            this.shouldHaveReminderDate1 = true;
            this.formGroup.controls.reminderDate1.addValidators(Validators.required);
        }
        if (!!this.formGroup.value.reminderDate2) {
            this.shouldHaveReminderDate2 = true;
            this.formGroup.controls.reminderDate2.addValidators(Validators.required);
        }
        if (!!this.formGroup.value.datePaid) {
            this.shouldHaveDatePaid = true;
            this.formGroup.controls.datePaid.addValidators(Validators.required);
        }
    }

    override prepareSaveModel(): Partial<InvoiceEditRequestModel> {
        const value = this.formGroup.value;
        return {
            dueDate: value.dueDate,
            reminderDate1: value.reminderDate1,
            reminderDate2: value.reminderDate2,
            datePaid: value.datePaid,
            remarks: value.remarks
        };
    }
}
