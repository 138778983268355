import { Component, inject, Input } from '@angular/core';
import { getLocaleDayNames, FormStyle, TranslationWidth } from '@angular/common';

import { TranslationService } from '../../../../core/services/translation.service';
import { SupplierModel } from '../../../../app/models/supplier.model';

@Component({
    selector: 'arc-supplier-data',
    templateUrl: './supplier-data.component.html',
    styleUrls: ['./supplier-data.component.scss']
})
export class SupplierDataComponent {
    @Input() model?: SupplierModel;

    private readonly translationService = inject(TranslationService);
    private readonly weekDays = getLocaleDayNames(this.translationService.current.code, FormStyle.Standalone, TranslationWidth.Abbreviated);

    /**
     * Removes the protocol from the url like 'http://' or 'https://'
     */
    get websiteUrl(): string {
        const url = this.model?.contactUrl ?? '';
        return url.slice(url.indexOf('//') + 1);
    }
    get deliveryDays(): string {
        return !this.model?.personDeliveryCondition
            ? ''
            : this.weekDays.filter((v, i) => this.deliveryDaysArray.includes(i)).join(', ');
    }
    get deliveryDaysArray(): number[] {
        const days: number[] = [];

        if (!this.model) {
            return days;
        }

        if (!!this.model.personDeliveryCondition?.sunday) {
            days.push(0);
        }

        if (!!this.model.personDeliveryCondition?.monday) {
            days.push(1);
        }

        if (!!this.model.personDeliveryCondition?.tuesday) {
            days.push(2);
        }

        if (!!this.model.personDeliveryCondition?.wednesday) {
            days.push(3);
        }

        if (!!this.model.personDeliveryCondition?.thursday) {
            days.push(4);
        }

        if (!!this.model.personDeliveryCondition?.friday) {
            days.push(5);
        }

        if (!!this.model.personDeliveryCondition?.saturday) {
            days.push(6);
        }

        return days;
    }
}
