import { Component, inject, OnInit } from '@angular/core';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';

import { BaseColumnComponent } from '../base-column.component';
import { DateColumnModel } from '../../models/column-types/date-column.model';
import { DateService } from '../../../../core/services/date.service';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { TranslationService } from '../../../../core/services/translation.service';

@Component({
    selector: 'arc-date-column',
    templateUrl: './date-column.component.html',
    styleUrls: ['./date-column.component.scss']
})
export class DateColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    minDate = new Date(1970, 1, 1);
    locale: object | string = 'en';
    dateFormat = '';
    get castedColumnModel(): DateColumnModel {
        return this.columnModel as DateColumnModel;
    }

    private readonly dateService = inject(DateService);
    private readonly translationService = inject(TranslationService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.locale = this.getLocale();
        this.dateFormat = this.castedColumnModel.hasTime
            ? this.translationService.current.dateFormatFlatPicker
            : this.translationService.current.dateFormatFlatPickerNoTime;

        if (!!this.castedColumnModel.minDateField && !!this.formGroup) {
            const minDateSub = this.formGroup.valueChanges.subscribe(val => this.updateMinDate(val));

            this.updateMinDate(this.formGroup.value);
            this.addSubscriptions(minDateSub);
        }
    }

    getDateValue(): string {
        return this.dateService.format(this.getItemValue(this.castedColumnModel.propertyName), this.castedColumnModel.format);
    }

    private updateMinDate(formValue: any): void {
        const newMinDate = new Date(formValue[this.castedColumnModel.minDateField!]);

        if (newMinDate.getTime() !== this.minDate.getTime()) {
            this.minDate = newMinDate;
        }
    }

    private getLocale(): object | string {
        switch (this.translationService.current.code) {
            case 'de':
                return German;
            case 'fr':
                return French;
            case 'it':
                return Italian;
            default:
                return 'en';
        }
    }
}
