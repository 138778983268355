import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { SecurityStorage } from './storages/security.storage';
import { TranslationService } from './translation.service';
import { EnvironmentService } from './environment.service';
import { GeneralDataStore } from '../../app/services/stores/general-data.store';
import { WelcomeBarModel } from '../models/welcome-bar-model';
import { OptionalType } from '../models/types/optional.type';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomeBarService {
    private readonly securityStorage = inject(SecurityStorage);
    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly translationService = inject(TranslationService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly userService = inject(UserService);

    getWelcomeBarModel(): Observable<Promise<OptionalType<WelcomeBarModel>>> {
        return this.generalDataStore.getUnreadMessagesCount().pipe(
            map(async response => {
                const userInfo = (await this.userService.getUserInfoAsync())!;
                const welcomeBarText = await this.translationService.getTextAsync('Home.Welcome', { userFullName: userInfo.realName });
                const welcomeBar: WelcomeBarModel = new WelcomeBarModel({
                    userFullName: userInfo.realName,
                    licenseName: userInfo.licenseName,
                    unreadMessagesCount: response.value,
                    welcomeBarText
                });

                if (!!welcomeBar.unreadMessagesCount) {
                    let messagesText;

                    if (!!welcomeBar.unreadMessagesCount) {
                        let messagesKey = 'Home.YouHaveUnreadMessagesSingular';

                        if (welcomeBar.unreadMessagesCount > 1) {
                            messagesKey = 'Home.YouHaveUnreadMessagesPlural';
                        }

                        messagesText = await this.translationService.getTextAsync(messagesKey, {
                            messagesCount: welcomeBar.unreadMessagesCount
                        });
                    }

                    welcomeBar.welcomeBarText += ` ${messagesText}`;
                }

                return welcomeBar;
            })
        );
    }

    getCompanyLogoUrl(): string {
        const token = this.securityStorage.getToken();
        const baseUrl = this.environmentService.baseUrl;

        return `${baseUrl}/general-data/logo?token=${token}`;
    }
}
