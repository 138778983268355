import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseColumnComponent } from '../base-column.component';
import { QuickSearchColumnModel } from '../../models/column-types/quick-search-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-quick-search-column',
    templateUrl: './quick-search-column.component.html',
    styleUrls: ['./quick-search-column.component.scss']
})
export class QuickSearchColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    readonlyControl = new FormControl();

    get castedCellModel(): QuickSearchColumnModel {
        return this.columnModel as QuickSearchColumnModel;
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.readonlyControl.disable();
        this.readonlyControl.setValue(this.getItemValue(this.columnModel.propertyName));
    }
}
