import { Observable } from 'rxjs';

import { EditSidebarConfigModel } from './edit-sidebar-config.model';
import { ColumnSortModel } from '../models/column-sort.model';
import { BaseCrudStore } from './base-crud.store';
import { PaginatorConfigModel } from '../models/paginator-config.model';
import { ActionButtonModel } from '../models/action-button.model';
import { ButtonsVisibilityModel } from '../models/buttons-visibility.model';
import { Identifyable } from './identifyable';

export abstract class BaseListViewConfigModel<
    T extends Identifyable<TId>,
    TList extends Identifyable<TId>,
    TCreate extends Identifyable<TId> = T,
    TUpdate extends Identifyable<TId> = TCreate,
    TId = number
> {
    editSidebarConfig?: EditSidebarConfigModel<T, TUpdate, TId>;
    defaultSort?: ColumnSortModel;
    customDeleteConfirmationMessage?: string;
    handleCreateButtonClick?: () => Observable<boolean>;
    handleEditButtonClick?: (item: TList) => Observable<boolean>;
    handleDeleteButtonClick?: (items: TList[]) => Observable<boolean>;
    customActionButtons: ActionButtonModel[] = [];
    buttonsVisibility = new ButtonsVisibilityModel();
    pollAtEveryMs?: number;

    abstract entityName: string;
    abstract store: BaseCrudStore<T, TList, TCreate, TUpdate, TId>;
    abstract paginatorConfig: PaginatorConfigModel;

    protected constructor(init?: Partial<BaseListViewConfigModel<T, TList, TCreate, TUpdate, TId>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
