import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { BrokenRuleModel } from '../../app/models/broken-rule.model';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    onBusinessException = new Subject<ApiResponseModel<any>>();
    shouldDisplayAlertOnError = true;

    private readonly alertService = inject(AlertService);

    setBusinessException(apiResponseModel: ApiResponseModel<any>): void {
        this.onBusinessException.next(apiResponseModel);
    }

    showAlertForBrokenRules(brokenRules: BrokenRuleModel[], message: string): void {
        let content = '<ul class="text-start list-disc list-outside pl-8 w-full">';
        brokenRules.forEach(e => (content += `<li>${e.message}</li>`));
        content += '</ul>';
        this.alertService.showAlert(content, message ?? undefined, true, true);
    }
}
