import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-settings-security-password',
    templateUrl: './settings-security-password.component.html',
    styleUrl: './settings-security-password.component.scss'
})
export class SettingsSecurityPasswordComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        passwordComplexityLength: new ArcFormControl(0, [Validators.required, CustomValidators.number()]),
        passwordComplexityLower: new ArcFormControl(false),
        passwordComplexityUpper: new ArcFormControl(false),
        passwordComplexityNumber: new ArcFormControl(false),
        passwordComplexitySpecial: new ArcFormControl(false),
        authenticationExpiryMinutes: new ArcFormControl(0, [Validators.required, CustomValidators.number()])
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            passwordComplexityLength: value.passwordComplexityLength,
            passwordComplexityLower: value.passwordComplexityLower,
            passwordComplexityUpper: value.passwordComplexityUpper,
            passwordComplexityNumber: value.passwordComplexityNumber,
            passwordComplexitySpecial: value.passwordComplexitySpecial,
            authenticationExpiryMinutes: value.authenticationExpiryMinutes
        };
    }
}
