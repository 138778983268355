import { Directive, HostListener, Input, OnDestroy, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Subject, Subscription, switchMap, takeUntil, timer } from 'rxjs';

import { PortalStore } from '../../app/services/stores/portal.store';
import { TranslationService } from '../services/translation.service';
import { SecuritySessionStorage } from '../services/storages/security.session-storage';

@Directive({
    selector: '[arcPortalTooltip]'
})
export class PortalTooltipDirective extends MatTooltip implements OnDestroy {
    @Input({ alias: 'arcPortalTooltip', required: true }) key?: string;

    private readonly debounceTimeMs = 3000;
    private readonly portalStore = inject(PortalStore);
    private readonly translationService = inject(TranslationService);
    private readonly securitySession = inject(SecuritySessionStorage);
    private readonly mouseOutSubject = new Subject<void>();
    private timerSub?: Subscription;

    @HostListener('mouseenter')
    onMouseOver(): void {
        if (!this.key?.trim() || !this.securitySession.getUserInfo()) {
            return;
        }

        this.timerSub = timer(this.debounceTimeMs)
            .pipe(
                takeUntil(this.mouseOutSubject),
                switchMap(() => this.portalStore.getTooltip(this.key!))
            )
            .subscribe({
                next: result => {
                    this.message = result?.value ?? this.translationService.getText(this.key!);
                    this.show();
                },
                complete: () => {
                    if (this.message === '' || this.message === ' ') {
                        this.message = this.translationService.getText(this.key!);
                        this.show();
                    }
                }
            });
    }

    @HostListener('mouseleave')
    onMouseOut(): void {
        this.mouseOutSubject.next();
        this.message = '';
        this.hide();
    }

    override ngOnDestroy(): void {
        this.mouseOutSubject.complete();
        this.timerSub?.unsubscribe();
    }
}
