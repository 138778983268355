import { Component, EventEmitter, Input, Output, computed, inject, signal } from '@angular/core';

import { PosLayoutGroupModel } from '../../../../../models/pos-layout-group.model';
import { PosLayoutGroupCommandGridModel } from '../../../../../models/responses/pos-layout-group-command-grid.model';
import { PosLayoutGroupCommandsStore } from '../../../../../services/stores/pos-layout-group-commands.store';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-pos-layout-edit-command-cell',
    templateUrl: './pos-layout-edit-command-cell.component.html',
    styleUrl: './pos-layout-edit-command-cell.component.scss'
})
export class PosLayoutEditCommandCellComponent {
    // TODO change to signal input once we're on v18
    _posLayoutGroup = signal<PosLayoutGroupModel>({
        id: 0,
        posLayoutId: 0,
        title: '',
        title_de: '',
        isTab: false,
        isBottomPanel: false,
        sort: 0,
        rows: 0,
        columns: 0,
        buttonSize: 0,
        jumpToMainGroup: false,
        posLayoutGroupCommands: []
    });
    @Input({ required: true }) set posLayoutGroup(value: PosLayoutGroupModel) {
        this._posLayoutGroup.set(value);
    }

    // TODO change to signal input once we're on v18
    _posLayoutGroupCommand = signal<PosLayoutGroupCommandGridModel>({
        id: 0,
        title: '',
        isDoubleSize: false,
        position: 0
    });
    @Input({ required: true }) set posLayoutGroupCommand(value: PosLayoutGroupCommandGridModel) {
        this._posLayoutGroupCommand.set(value);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output() readonly edit = new EventEmitter<void>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output() readonly remove = new EventEmitter<void>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Output() readonly reload = new EventEmitter<void>();

    canExpand = computed(() => {
        const posLayoutGroupCommand = this._posLayoutGroupCommand();
        const posLayoutGroup = this._posLayoutGroup();

        if (posLayoutGroupCommand.position % posLayoutGroup.columns === 0) {
            // command is on the edge of the row
            return false;
        }

        const nextCommand = posLayoutGroup.posLayoutGroupCommands.find(c => c.position === posLayoutGroupCommand.position + 1);

        // can only be expanded if there is no next command
        return !nextCommand;
    });

    imageSrc = computed(() => {
        const command = this._posLayoutGroupCommand();
        if (!command.image) {
            return undefined;
        }
        return Tools.Utils.getBase64ImageSrc(command.image, 'png');
    });

    private readonly posLayoutGroupCommandsStore = inject(PosLayoutGroupCommandsStore);

    setIsDoubleSize(isDoubleSize: boolean): void {
        this.posLayoutGroupCommandsStore.setDoubleSize(this._posLayoutGroupCommand().id, isDoubleSize).subscribe(() => {
            this.reload.emit();
        });
    }
}
