import { Component, inject, OnChanges, OnInit, signal } from '@angular/core';

import { InventoryArticleListModel } from '../../../../../models/responses/inventory-article-list.model';
import { InventoryArticleModel } from '../../../../../models/inventory-article.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InventoryScansStore } from '../../../../../services/stores/inventory-scans.store';
import { InventoryScanDataModel } from '../../../../../models/inventory-scan-data.model';
import { IconColumnModel } from '../../../../../../components/dynamic-table/models/column-types/icon-column.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { InventoryScanSourceEnum } from '../../../../../models/enums/inventory-scan-source.enum';
import { IconModel } from '../../../../../../core/models/icon.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';

@Component({
    selector: 'arc-inventory-article-scans.detail-widget',
    templateUrl: './inventory-article-scans.detail-widget.html',
    styleUrl: './inventory-article-scans.detail-widget.scss'
})
export class InventoryArticleScansDetailWidgetComponent
    extends BaseDetailWidget<InventoryArticleListModel, InventoryArticleModel, object>
    implements OnInit, OnChanges {
    isLoading = signal(true);
    items: InventoryScanDataModel[] = [];
    tableConfig?: StaticTableConfigModel<InventoryScanDataModel>;

    private readonly inventorieScansStore = inject(InventoryScansStore);

    ngOnInit(): void {
        this.isLoading.set(true);
        this.inventorieScansStore.getScans(this.listModel.articleId, this.listModel.inventoryId).subscribe(r => {
            this.items = r.value || [];
            this.setupTable();
        });
    }

    ngOnChanges(): void {
        this.setupTable();
    }

    protected setupTable(): void {
        this.tableConfig = new StaticTableConfigModel<InventoryScanDataModel>({
            data: this.items,
            defaultPageSize: 4,
            availableColumns: [
                new IconColumnModel({
                    columnTitleKey: 'InventoryArticles.Details.Device',
                    propertyName: 'inventoryScanSource',
                    iconMapping: new Map([
                        [InventoryScanSourceEnum.App, new IconModel({ name: 'smartphone' })],
                        [InventoryScanSourceEnum.Backoffice, new IconModel({ name: 'computer' })]
                    ]),
                    widthPixels: 90
                }),
                new StringColumnModel({
                    columnTitleKey: 'InventoryArticles.Details.User',
                    propertyName: 'userName',
                    widthPixels: 90,
                    isCompact: true
                }),
                new DateColumnModel({
                    columnTitleKey: 'InventoryArticles.Details.CreatedDate',
                    propertyName: 'createdDate',
                    widthPixels: 90,
                    format: 'short',
                    isCompact: true
                }),
                new StringColumnModel({
                    columnTitleKey: 'InventoryArticles.Details.Stock',
                    propertyName: 'stock',
                    widthPixels: 80,
                    isCompact: true,
                    customFormatter: (record, value) => (value > 0 ? '+' + value : value)
                })
            ]
        });
        this.isLoading.set(false);
    }
}
