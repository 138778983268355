import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { FavouriteListModel } from '../../models/responses/favourite-list.model';
import { FavouriteModel } from '../../models/favourite.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class FavouritesStore extends BaseCrudStore<FavouriteModel, FavouriteListModel, FavouriteModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'favourites' }));
    }

    getAll(): Observable<ApiResponseModel<FavouriteListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<FavouriteListModel[]>>(this.getUrl(''));
    }
}
