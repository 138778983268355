import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomerDescriptionDialogComponent } from './customer-description-dialog/customer-description-dialog.component';

@Component({
    selector: 'arc-customer-description',
    templateUrl: './customer-description.detail-widget.html',
    styleUrls: ['./customer-description.detail-widget.scss']
})
export class CustomerDescriptionDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements AfterViewInit, OnInit {
    @ViewChild('content') content!: ElementRef;

    description: SafeHtml = '';
    showReadMoreButton = false;

    private readonly domSanitizer = inject(DomSanitizer);
    private readonly matDialog = inject(MatDialog);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this.description = this.domSanitizer.bypassSecurityTrustHtml(this.entityModel.notes ?? '');
    }

    ngAfterViewInit(): void {
        this.checkOverflow();
    }

    checkOverflow(): void {
        const contentElement = this.content.nativeElement;
        if (contentElement.scrollHeight > contentElement.clientHeight) {
            this.showReadMoreButton = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    openDialog(): void {
        const dialogRef = this.matDialog.open(CustomerDescriptionDialogComponent, {
            data: this.description,
            width: '400px',
            maxWidth: '98vw',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(shouldRefetch => {
            if (shouldRefetch) {
                return;
            }
        });
    }
}
