import { Component, OnInit, inject } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';
import { DateService } from '../../../../../../core/services/date.service';

@Component({
    selector: 'arc-invoice',
    templateUrl: './invoice.detail-widget.html',
    styleUrls: ['./invoice.detail-widget.scss']
})
export class InvoiceDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> implements OnInit {
    primaryButtonKey = '';
    secondaryButtonKey = '';
    primaryTitleButtonKey = '';
    secondaryTitleButtonKey = '';
    paymentDiffDays?: number;
    paymentDiffClass?: string;
    paymentDiffTranslationKey?: string;
    readonly today = new Date();

    private readonly dateService = inject(DateService);

    ngOnInit(): void {
        if (!!this.entityModel.datePaid) {
            this.paymentDiffDays = this.dateService.diff(this.entityModel.dueDate, this.entityModel.datePaid);
            this.paymentDiffClass = this.paymentDiffDays > 0 ? '!text-error' : '!text-success';
            this.paymentDiffTranslationKey = `General.Units.Days.${this.paymentDiffDays === 1 ? 'Singular' : 'Long'}`;
        }

        if (this.listModel.canSend) {
            this.primaryButtonKey = 'InvoiceSendContextAction';
            this.primaryTitleButtonKey = 'General.Actions.Send';
        } else if (this.listModel.canPay) {
            this.primaryButtonKey = 'InvoicePayContextAction';
            this.primaryTitleButtonKey = 'General.Paid';
        }

        if (this.listModel.canCancel) {
            this.secondaryButtonKey = 'InvoiceCancelContextAction';
            this.secondaryTitleButtonKey = 'General.Actions.Annulate';
        } else if (this.listModel.canSendReminder) {
            this.secondaryButtonKey = 'InvoiceSendReminderContextAction';
            this.secondaryTitleButtonKey = !!this.entityModel.reminderDate1 ? 'Invoices.List.ReminderDate2' : 'Invoices.List.ReminderDate1';
        }
    }
}
