import { Component } from '@angular/core';

@Component({
    selector: 'arc-default-values',
    templateUrl: './default-values.component.html',
    styleUrls: ['./default-values.component.scss']
})
export class DefaultValuesComponent {

}
