<form [formGroup]="quickAddFormGroup" (submit)="quickAdd()" class="flex gap-4 items-center flex-wrap">
    <arc-quick-search #quickAddArticleControl label="Orders.Edit.Articles.Article"
        [config]="quickSearchConfig!" (optionSelected)="quickAddArticleChanged($event)"
        [formControl]="quickAddFormGroup.controls.articleSupplierId" class="basis-[200px] grow shrink-0">
    </arc-quick-search>

    <arc-number-input #quickAddQuantityControl label="Orders.Edit.Articles.OrderQuantity"
        [unit]="quickAddQuantityUnit()" [isUnitTranslated]="true"
        [formControl]="quickAddFormGroup.controls.orderQuantity" class="basis-[150px] grow shrink-0">
    </arc-number-input>

    <div class="min-w-fit grow shrink-0 flex gap-4 items-center">
        <p class="grow-0">x</p>

        <div class="min-w-max grow shrink-0">
            <span class="text-on-app-light">
                <span class="text-on-app">
                    {{ quickAddBuyingPrice() | arcCurrency }}
                </span>
                <span class="ml-2 text-sm text-on-app-light">
                    {{ (quickAddBuyingPriceExclusive()
                        ? 'Orders.Edit.Articles.BuyingPriceExclusive'
                        : 'Orders.Edit.Articles.BuyingPriceInclusive') | transloco }}
                </span>
            </span>
        </div>        

        <p class="grow-0">=</p>

        <div class="min-w-max grow shrink-0 flex flex-col">
            <span class="truncate text-xs">
                {{ quickAddTotalQuantity() | arcNumber : 0 : undefined : ' ' + (quickAddOrderArticle()?.articleUnitTitle ?? '') }}
            </span>
            <span>{{ quickAddTotal() | arcCurrency }}</span>
        </div>
        
        <button mat-icon-button (click)="quickAdd()" [disabled]="isQuickAddLoading">
            @if (isQuickAddLoading) {
                <mat-spinner [diameter]="24"></mat-spinner>
            } @else {
                <mat-icon>add</mat-icon>
            }
        </button>
    </div>
</form>