import { Component, OnInit, inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseAuthComponent } from '../../../../components/abstractions/base-auth.component';
import { SettingsStore } from '../../../services/stores/settings.store';
import { SettingsListModel } from '../../../models/responses/settings-list.model';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { SettingsCardTypesEnum } from '../../../models/enums/settings-card-types.enum';
import { EditSidebarDataModel } from '../../../../components/sidebar-components/edit-sidebar/models/edit-sidebar-data.model';
import { ConfigurationModel } from '../../../models/configuration.model';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { SettingsCustomizeBaseDataComponent } from './settings-edit-items/settings-customize-base-data/settings-customize-base-data.component';
import { SettingsCustomizeColorsComponent } from './settings-edit-items/settings-customize-colors/settings-customize-colors.component';
import { SettingsCustomizeLogoComponent } from './settings-edit-items/settings-customize-logo/settings-customize-logo.component';
import { SettingsInvoicesCustomerAccountComponent } from './settings-edit-items/settings-invoices-customer-account/settings-invoices-customer-account.component';
import { SettingsInvoicesRemindersComponent } from './settings-edit-items/settings-invoices-reminders/settings-invoices-reminders.component';
import { SettingsInvoicesBankAccountComponent } from './settings-edit-items/settings-invoices-bank-account/settings-invoices-bank-account.component';
import { SettingsInvoicesQrBillComponent } from './settings-edit-items/settings-invoices-qr-bill/settings-invoices-qr-bill.component';
import { SettingsSecurityPasswordComponent } from './settings-edit-items/settings-security-password/settings-security-password.component';
import { SettingsSecurityPinComponent } from './settings-edit-items/settings-security-pin/settings-security-pin.component';
import { SettingsWarehouseBuyingPriceComponent } from './settings-edit-items/settings-warehouse-buying-price/settings-warehouse-buying-price.component';
import { SettingsSettingsFormatsComponent } from './settings-edit-items/settings-settings-formats/settings-settings-formats.component';
import { SettingsSettingsPrefixesComponent } from './settings-edit-items/settings-settings-prefixes/settings-settings-prefixes.component';
import { SettingsSettingsArticlePricesComponent } from './settings-edit-items/settings-settings-article-prices/settings-settings-article-prices.component';
import { SettingsSettingsBookingTypesComponent } from './settings-edit-items/settings-settings-booking-types/settings-settings-booking-types.component';
import { SettingsWarehouseStoresComponent } from './settings-edit-items/settings-warehouse-stores/settings-warehouse-stores.component';
import { SettingsFinanceCurrenciesComponent } from './settings-edit-items/settings-finance-currencies/settings-finance-currencies.component';
import { SettingsFinanceTaxRatesComponent } from './settings-edit-items/settings-finance-taxes/settings-finance-tax-rates.component';
import { SettingsDefaultValuesArticlesComponent } from './settings-edit-items/settings-default-values-articles/settings-default-values-articles.component';
import { SettingsDefaultValuesCustomersComponent } from './settings-edit-items/settings-default-values-customers/settings-default-values-customers.component';
import { SettingsDefaultValuesSuppliersComponent } from './settings-edit-items/settings-default-values-suppliers/settings-default-values-suppliers.component';
import { SettingsDefaultValuesVoucherTypesComponent } from './settings-edit-items/settings-default-values-voucher-types/settings-default-values-voucher-types.component';
import { SettingsDefaultValuesSubscriptionTypesComponent } from './settings-edit-items/settings-default-values-subscription-types/settings-default-values-subscription-types.component';
import { SettingsAccountTypesComponent } from './settings-edit-items/settings-account-types/settings-account-types.component';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { SettingsPriceLevelsComponent } from './settings-edit-items/settings-price-levels/settings-price-levels.component';
import { AuthPermissionModel } from '../../../models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { SettingsFashionSeasonComponent } from './settings-edit-items/settings-fashion-season/settings-fashion-season.component';

@Component({
    selector: 'arc-settings',
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'
})
export class SettingsComponent extends BaseAuthComponent implements OnInit {
    isLoading = true;
    data: SettingsListModel[] = [];
    buttons: ActionButtonModel[] = [
        new ActionButtonModel({
            key: ActionButtonsService.editButtonKey,
            text: 'General.Actions.Edit',
            icon: 'edit',
            isEnabled: true,
            min: 1,
            max: 1,
            hasParameters: false,
            order: 20,
            clickFn: (btn: ActionButtonModel, selectedItem: SettingsListModel) => {
                this.openEditSidebar(selectedItem).subscribe(shouldReload => {
                    if (shouldReload) {
                        this.loadData();
                    }
                });
            }
        })
    ];

    private editModalsConfig!: { [key in SettingsCardTypesEnum]: EditSidebarDataModel<ConfigurationModel, ConfigurationModel> };

    private readonly translationService = inject(TranslationService);
    private readonly sidebarService = inject(SidebarService);
    private readonly settingsStore = inject(SettingsStore);
    private readonly permissionService = inject(PermissionService);

    ngOnInit(): void {
        this.loadData();
        this.setupEditConfigs();
    }

    hasPermission(permission: PermissionsEnum): true | AuthPermissionModel {
        return this.permissionService.hasPermission(permission);
    }

    private openEditSidebar(item: SettingsListModel): Observable<OptionalType<boolean>> {
        const config = this.editModalsConfig[item.type];
        config.existingId = item.id;
        config.headerSubtitle = () => of(item.title);

        return this.sidebarService.openEdit<ConfigurationModel, ConfigurationModel>(config);
    }

    private loadData(): void {
        this.isLoading = true;
        this.settingsStore.list().subscribe(r => {
            this.data = r.value || [];
            this.addPermissions();
            this.isLoading = false;
        });
    }

    private addPermissions(): void {
        this.data.forEach(item => {
            switch (item.type) {
                case SettingsCardTypesEnum.Warehouse:
                    item.requiredPermission = PermissionsEnum.Stores;
                    break;
                case SettingsCardTypesEnum.PriceLevels:
                    item.requiredPermission = PermissionsEnum.PriceLevels;
                    break;
                case SettingsCardTypesEnum.Fashion:
                    item.requiredPermission = PermissionsEnum.Colors;
                    break;
                default:
                    item.requiredPermission = PermissionsEnum.Configuration;
            }
        });
    }

    private setupEditConfigs(): void {
        const baseData: EditSidebarDataModel<ConfigurationModel, ConfigurationModel> = {
            entityName: 'Configuration',
            store: this.settingsStore,
            editComponents: [],
            headerTitle: () => this.translationService.getTextObservable('Settings.Settings')
        };
        this.editModalsConfig = {
            [SettingsCardTypesEnum.Customize]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Customize.BaseData.Name',
                        component: SettingsCustomizeBaseDataComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Customize.Colors.Name',
                        component: SettingsCustomizeColorsComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Customize.Logo.Name',
                        component: SettingsCustomizeLogoComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    }
                ]
            },
            [SettingsCardTypesEnum.Invoices]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Invoices.CustomerAccount.Name',
                        component: SettingsInvoicesCustomerAccountComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Invoices.Reminders.Name',
                        component: SettingsInvoicesRemindersComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Invoices.BankAccount.Name',
                        component: SettingsInvoicesBankAccountComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Invoices.QrBill.Name',
                        component: SettingsInvoicesQrBillComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    }
                ]
            },
            [SettingsCardTypesEnum.Settings]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Settings.Formats.Name',
                        component: SettingsSettingsFormatsComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Settings.Prefixes.Name',
                        component: SettingsSettingsPrefixesComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Settings.ArticlePrices.Name',
                        component: SettingsSettingsArticlePricesComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Settings.BookingTypes.Name',
                        component: SettingsSettingsBookingTypesComponent,
                        requiredPermission: PermissionsEnum.TransactionTypes
                    }
                ]
            },
            [SettingsCardTypesEnum.Security]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Security.Password.Name',
                        component: SettingsSecurityPasswordComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    },
                    {
                        titleKey: 'Settings.Edit.Security.Pin.Name',
                        component: SettingsSecurityPinComponent,
                        requiredPermission: PermissionsEnum.Configuration
                    }
                ]
            },
            [SettingsCardTypesEnum.Warehouse]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Warehouse.BuyingPrice.Name',
                        component: SettingsWarehouseBuyingPriceComponent,
                        requiredPermission: PermissionsEnum.EditBuyingPrice
                    },
                    {
                        titleKey: 'Settings.Edit.Warehouse.Stores.Name',
                        component: SettingsWarehouseStoresComponent,
                        requiredPermission: PermissionsEnum.Stores
                    }
                ]
            },
            [SettingsCardTypesEnum.DefaultValues]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.DefaultValues.ControllerNames.Articles',
                        component: SettingsDefaultValuesArticlesComponent,
                        requiredPermission: PermissionsEnum.DefaultValues
                    },
                    {
                        titleKey: 'Settings.Edit.DefaultValues.ControllerNames.Customers',
                        component: SettingsDefaultValuesCustomersComponent,
                        requiredPermission: PermissionsEnum.DefaultValues
                    },
                    {
                        titleKey: 'Settings.Edit.DefaultValues.ControllerNames.Suppliers',
                        component: SettingsDefaultValuesSuppliersComponent,
                        requiredPermission: PermissionsEnum.DefaultValues
                    },
                    {
                        titleKey: 'Settings.Edit.DefaultValues.ControllerNames.VoucherTypes',
                        component: SettingsDefaultValuesVoucherTypesComponent,
                        requiredPermission: PermissionsEnum.DefaultValues
                    },
                    {
                        titleKey: 'Settings.Edit.DefaultValues.ControllerNames.SubscriptionTypes',
                        component: SettingsDefaultValuesSubscriptionTypesComponent,
                        requiredPermission: PermissionsEnum.DefaultValues
                    }
                ]
            },
            [SettingsCardTypesEnum.Finance]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Finance.Currencies.Name',
                        component: SettingsFinanceCurrenciesComponent,
                        requiredPermission: PermissionsEnum.Currencies
                    },
                    {
                        titleKey: 'Settings.Edit.Finance.Taxes.Name',
                        component: SettingsFinanceTaxRatesComponent,
                        requiredPermission: PermissionsEnum.ArticleTaxes
                    }
                ]
            },
            [SettingsCardTypesEnum.PriceLevels]: {
                ...baseData,
                editComponents: [{ titleKey: 'Settings.Edit.PriceLevels.PriceLevels.Name', component: SettingsPriceLevelsComponent }]
            },
            [SettingsCardTypesEnum.AccountTypes]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.AccountTypes.AccountTypes.Name',
                        component: SettingsAccountTypesComponent,
                        requiredPermission: PermissionsEnum.AccountTypes
                    }
                ]
            },
            [SettingsCardTypesEnum.Fashion]: {
                ...baseData,
                editComponents: [
                    {
                        titleKey: 'Settings.Edit.Fashion.Seasons.Name',
                        component: SettingsFashionSeasonComponent,
                        requiredPermission: PermissionsEnum.Colors
                    }
                ]
            }
        };
    }
}
