<mat-sidenav-container autosize [hasBackdrop]="true" (backdropClick)="handleRightBackdropClick()">
    <mat-sidenav-content class="!h-screen !flex flex-col">
        <ng-content></ng-content>
    </mat-sidenav-content>

    <mat-sidenav position="end" mode="over" [opened]="sidebarService.rightOpenSubject | async" [disableClose]="true"
        class="smd:min-w-[100vw] !rounded-l-[10px]" [ngStyle]="{ width, maxWidth }">
        <div class="w-full h-full flex flex-col">
            @if (sidebarHeaderService.title()) {
                <arc-sidebar-header></arc-sidebar-header>
            }
            <div class="grow">
                <ng-template *ngIf="componentPortalSubject | async as portal" [cdkPortalOutlet]="portal"
                    (attached)="componentAttached($event)">
                </ng-template>
            </div>
        </div>
    </mat-sidenav>
</mat-sidenav-container>