<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.List.EditMode' | transloco }}</p>
        <p class="text-xl">{{ item.editmodeDescription }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.List.StandardQuantity' | transloco }}</p>
        <p class="text-xl">{{ item.standardQuantity | arcNumber:2 }}</p>
    </div>
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'Units.List.TareGrams' | transloco }}</p>
        <p class="text-xl">{{ item.tareGrams || '-' }}</p>
    </div>
</div>
