import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { ReportListModel } from '../../../models/responses/report-list.model';
import { ReportsStore } from '../../../services/stores/reports.store';
import { SecurityStorage } from '../../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../../core/services/environment.service';
import { ReportParametersService } from '../../../services/report-parameters.service';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ReportModel } from '../../../models/report.model';
import { ReportEditRequestModel } from '../../../models/requests/report-edit-request.model';
import { ReportCardComponent } from './report-card/report-card.component';
import { ActionButtonModel } from '../../../../core/models/action-button.model';
import { ReportEditBaseDataComponent } from './report-edit-items/report-edit-base-data/report-edit-base-data.component';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { ReportCreateSourceSelectionComponent } from './report-create-items/report-create-source-selection/report-create-source-selection.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { BaseReportPage } from '../../../../components/abstractions/base-report-page';
import { ActionButtonsService } from '../../../../core/services/action-buttons.service';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

@Component({
    selector: 'arc-reports',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends BaseReportPage<ReportListModel> {
    private readonly reportsStore = inject(ReportsStore);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);
    private readonly reportParametersService = inject(ReportParametersService);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super(
            inject(ReportsStore),
            false,
            new ActionButtonModel({
                clickFn: () => this.openAddReport(),
                isHidden: () => !this._showTemplates,
                icon: 'add',
                min: 0,
                max: 0,
                isEnabled: true
            })
        );

        this.reportsStore.showTemplates = this._showTemplates;
        this.config = new CardListViewConfigModel<ReportModel, ReportListModel, ReportEditRequestModel>({
            entityName: 'Reports',
            store: this.reportsStore,
            cardComponent: ReportCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardImageCustomClasses: 'bg-card-image object-contain p-4 h-[50%]',
            cardImage: currentItem => {
                const token = this.securityStorage.getToken();
                const baseUrl = this.environmentService.baseUrl;

                return `${baseUrl}/reports/${currentItem.id}/preview?token=${token}&templateKey=${currentItem.templateKey ?? ''}`;
            },
            cardBackgroundColor: currentItem => {
                if (currentItem.isTemplate && !currentItem.isInstalled) {
                    const style = getComputedStyle(document.body);
                    return style.getPropertyValue('--available-reports');
                }

                return '';
            },
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Reports.Edit.BaseData',
                        component: ReportEditBaseDataComponent,
                        requiredPermission: PermissionsEnum.Reporting,
                        requiredPermissionType: PermissionTypeEnum.Update
                    }
                ],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'General.Actions.Open',
                clickFn: data => {
                    data = Array.isArray(data) ? data : [data];
                    this.reportParametersService.openReportParametersDialog(data[0]['id']);
                },
                isHidden: data => !!data && !data.isInstalled
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false, hasCreate: false }),
            customActionButtons: this._customActionButtons
        });
    }

    override afterContextActionsLoad(): void {
        this._actionButtonsService
            .updateIsHidden(ActionButtonsService.editButtonKey, (btn, data) => !!data && (!this._showTemplates || data.isTemplate))
            .updateIsHidden(
                ActionButtonsService.deleteButtonKey,
                (btn, data) => !!data && (!this._showTemplates || (data.isTemplate && !data.isInstalled))
            );
    }

    openAddReport(): void {
        this.sidebarService
            .openEdit({
                entityName: this.config.entityName,
                store: this.config.store,
                editComponents: [
                    ...this.config.editSidebarConfig!.editComponents,
                    {
                        titleKey: 'Reports.Create.SourceSelection',
                        component: ReportCreateSourceSelectionComponent,
                        requiredPermission: PermissionsEnum.Reporting,
                        requiredPermissionType: PermissionTypeEnum.Create
                    }
                ],
                headerTitle: this.sidebarService.getDefaultRightSidebarHeaderTitleFn(this.config.entityName),
                headerSubtitle: currentItem => of((currentItem as any).title)
            })
            .subscribe(shouldReload => {
                if (shouldReload) {
                    this._searchRequestService.forceReload();
                }
            });
    }
}
