import { Component, ComponentRef, HostBinding, OnChanges, OnInit, SimpleChanges, ViewContainerRef, inject } from '@angular/core';

import { CustomColumnModel } from '../../models/column-types/custom-column.model';
import { BaseColumnComponent } from '../base-column.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-custom-column',
    templateUrl: './custom-column.component.html',
    styleUrls: ['./custom-column.component.scss']
})
export class CustomColumnComponent<T extends Identifyable<TId>, TId = number>
    extends BaseColumnComponent<T, TId>
    implements OnInit, OnChanges {
    @HostBinding('style') style = { display: 'none' };

    viewContainerRef = inject(ViewContainerRef);
    componentInstance?: ComponentRef<any>;

    override ngOnInit(): void {
        super.ngOnInit();
        const customColumnModel = this.columnModel as CustomColumnModel<T>;
        this.componentInstance = this.viewContainerRef.createComponent(customColumnModel.customComponent);
        this.componentInstance.setInput('item', this.item);
        this.componentInstance.setInput('formGroup', this.formGroup);
        this.componentInstance.setInput('columnModel', this.columnModel);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item']) {
            const previousValue = changes['item'].previousValue;
            const currentValue = changes['item'].currentValue;

            if (previousValue !== currentValue) {
                this.componentInstance?.setInput('item', this.item);
            }
        }

        if (changes['columnModel']) {
            const previousValue = changes['columnModel'].previousValue;
            const currentValue = changes['columnModel'].currentValue;
            if (previousValue !== currentValue) {
                this.componentInstance?.setInput('columnModel', this.columnModel);
            }
        }

        if (changes['formGroup']) {
            const previousValue = changes['formGroup'].previousValue;
            const currentValue = changes['formGroup'].currentValue;
            if (previousValue !== currentValue) {
                this.componentInstance?.setInput('formGroup', this.formGroup);
            }
        }
    }
}
