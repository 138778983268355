import { Injectable, inject } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';

import { Utils } from '../utils/tools/utils.tools';
import { CamtFilePreviewModel } from '../../app/models/camt-file-preview.model';
import { InvoicesStore } from '../../app/services/stores/invoices.store';

@Injectable({
    providedIn: 'root'
})
export class CamtUploadService {

    private readonly invoiceStore = inject(InvoicesStore);

    getCamtFilePreview(file: File): Observable<CamtFilePreviewModel[]> {
        return Utils.blobToBase64(file).pipe(
            switchMap(result =>
                this.invoiceStore.getCamtFilePreview(
                    {
                        blob: result,
                        originalFileExtension: file.name.split('.').pop() ?? ''
                    }
                )
            ),
            map(result => result.value ?? [])
        );
    }

    uploadCamtFile(file: File): Observable<boolean> {
        return Utils.blobToBase64(file).pipe(
            switchMap(result =>
                this.invoiceStore.uploadCamtFile(
                    {
                        blob: result,
                        originalFileExtension: file.name.split('.').pop() ?? ''
                    }
                )
            ),
            map(result => result.value ?? false)
        );
    }
}
