import { Component, ViewChild, inject, signal } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { MatChipListboxChange } from '@angular/material/chips';

import { EditableTableComponent } from '../../../../../../components/form/editable-table/editable-table.component';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GenericStatusEnum } from '../../../../../models/enums/generic-status.enum';
import { WarehouseRequestModel } from '../../../../../models/warehouse-request.model';
import { WarehouseRequestEditModel } from '../../../../../models/requests/warehouse-request-edit.model';
import { WarehouseRequestArticleModel } from '../../../../../models/warehouse-request-article.model';
import { WarehouseTransferArticlesTableConfigService } from './warehouse-transfer-articles-table-config.service';

@Component({
    selector: 'arc-warehouse-transfer-edit-articles',
    templateUrl: './warehouse-transfer-edit-articles.component.html',
    styleUrl: './warehouse-transfer-edit-articles.component.scss',
    providers: [WarehouseTransferArticlesTableConfigService]
})
export class WarehouseTransferEditArticlesComponent extends BaseEditSidebarItemComponent<WarehouseRequestModel, WarehouseRequestEditModel> {
    @ViewChild(EditableTableComponent) editableTable!: EditableTableComponent<WarehouseRequestArticleModel>;

    GenericStatusEnum = GenericStatusEnum;
    tableConfig?: EditableTableConfigModel<WarehouseRequestArticleModel>;
    override formGroup = new FormGroup({
        warehouseRequestArticles: new ArcFormControl<WarehouseRequestArticleModel[]>([])
    });

    filterPredicate: (data: FormGroup<{ [K in keyof WarehouseRequestArticleModel]: AbstractControl<any, any> }>, filter: string) => boolean;
    articlesTotalCount = signal(0);
    articlesDeviatingCount = signal(0);
    articlesNotDeliveredCount = signal(0);

    private readonly warehouseTransferArticlesTableConfigService = inject(WarehouseTransferArticlesTableConfigService);
    private readonly sharedDataService = inject(SharedDataService);

    constructor() {
        super();

        this.sharedDataService.getOrCreateSignal<number>('requestedArticlesCount');
        this.sharedDataService.getOrCreateSignal<number>('receivedArticlesCount');

        this.formGroup.controls.warehouseRequestArticles.valueChanges.subscribe(newValue => {
            const requestedArticlesCount = newValue
                .map(x => !!x.quantity ? x.quantity : 0)
                .filter(total => !Number.isNaN(Number(total)))
                .reduce((prev, curr) => prev += curr, 0);
            const receivedArticlesCount = newValue
                .map(x => !!x.deliveredQuantity ? x.deliveredQuantity : 0)
                .filter(total => !Number.isNaN(Number(total)))
                .reduce((prev, curr) => prev += curr, 0);

            this.sharedDataService.setOrUpdateSignal<number>('requestedArticlesCount', requestedArticlesCount);
            this.sharedDataService.setOrUpdateSignal<number>('receivedArticlesCount', receivedArticlesCount);

            const articlesDeviatingCount = newValue
                // eslint-disable-next-line eqeqeq
                .filter(x => !!x.deliveredQuantity && x.quantity != x.deliveredQuantity)
                .length;
            const articlesNotDeliveredCount = newValue.filter(x => !x.deliveredQuantity).length;

            this.articlesTotalCount.set(newValue.length);
            this.articlesDeviatingCount.set(articlesDeviatingCount);
            this.articlesNotDeliveredCount.set(articlesNotDeliveredCount);
        });

        this.filterPredicate = (formGroup, filter) => {
            const data = formGroup.value;
            switch (filter) {
                case 'deviating':
                    // eslint-disable-next-line eqeqeq
                    return !!data.deliveredQuantity && data.quantity != data.deliveredQuantity;
                case 'notDelivered':
                    return !data.deliveredQuantity;
                case 'all':
                default:
                    return true;
            }
        };
    }

    override onBrokenRulesLoad(): string[] {
        return this.tableConfig?.setBrokenRulesOnFormGroups(this.brokenRules) ?? [];
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
        this.tableConfig = this.warehouseTransferArticlesTableConfigService.getTableConfig(this.item, this.formGroup);
    }

    override prepareSaveModel(): Partial<WarehouseRequestEditModel> {
        return { warehouseRequestArticles: this.formGroup.getRawValue().warehouseRequestArticles };
    }

    onFilterChanged(event: MatChipListboxChange): void {
        this.editableTable.filter(event.value);
    }
}
