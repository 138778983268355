@for (statusMessage of statusMessages; track statusMessage.text) {
    <div [ngClass]="getStyleClasses(statusMessage)" class="flex items-center">
        <div class="flex w-full items-center justify-center py-4">
            <mat-icon class="mr-1">{{ statusMessage.icon }}</mat-icon>

            @if (!!statusMessage.url) {
                <a href="{{statusMessage.url}}" target="_blank" class="underline cursor-pointer">{{ statusMessage.text }}</a>
            } @else {
                <span>{{ statusMessage.text }}</span>
            }
        </div>
        @if (statusMessage.type !== StatusMessageTypeEnum.Error) {
            <button mat-icon-button class="ml-auto" (click)="dismiss(statusMessage)">
                <mat-icon>close</mat-icon>
            </button>
        }
    </div>
}
