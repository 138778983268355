<div class="flex justify-end mb-4">
    <arc-button color="primary" (clicked)="accordion().openAll()">{{'Roles.Edit.ExpandAll' | transloco}}</arc-button>
    <arc-button color="primary" (clicked)="accordion().closeAll()">{{'Roles.Edit.CollapseAll' | transloco}}</arc-button>
</div>
<mat-accordion #detailedPermissions multi>
    @for(key of data | async; track key) {
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>{{key}}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group fitInkBarToContent [selectedIndex]="getPermissionTabIndex(key)">
                <mat-tab [label]="'Roles.Edit.General' | transloco">
                    <div class="flex flex-col items-start mt-8">
                        <div class="w-full flex justify-between mb-2 gap-2 items-start">
                            <div class="text-center w-1/4">
                                <div>{{'Roles.Edit.None' | transloco}}</div>
                            </div>
                            <div class="text-center w-1/4">
                                <div>{{'Roles.Edit.View' | transloco}}</div>
                            </div>
                            <div class="text-center w-1/4">
                                <div>{{'Roles.Edit.Edit' | transloco}}</div>
                            </div>
                            <div class="text-center w-1/4">
                                <div>{{'Roles.Edit.All' | transloco}}</div>
                            </div>
                        </div>
                        <div class="flex w-[78.5%] ml-[5.6rem]">
                            <mat-slider min="1" max="4" step="1" tickInterval="1" [showTickMarks]="true" class="!w-full green">
                                <input matSliderThumb (change)="onGeneralPermissionChanged(key, $event)"
                                    [value]="getGeneralPermissionValue(key)">
                            </mat-slider>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="'Roles.Edit.Detail' | transloco" disabled></mat-tab>
                <mat-tab [label]="'Roles.Edit.Individual' | transloco">
                    <div class="mt-8">
                        @for (p of permissionGroups()[key]; track p) {
                            <span class="font-bold">{{p.title}}</span>
                            <div class="flex my-4">
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Read)"
                                    (change)="changeView(p, $event.checked)">
                                    {{'Roles.Edit.View' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Create)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Create)">
                                    {{'Roles.Edit.Create' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Update)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Update)">
                                    {{'Roles.Edit.Edit' | transloco}}
                                </mat-checkbox>
                                <mat-checkbox [checked]="getRolePermissionValue(p.id, PermissionTypeEnum.Delete)"
                                    (change)="changeOthers(p, $event.checked, PermissionTypeEnum.Delete)">
                                    {{'Roles.Edit.Delete' | transloco}}
                                </mat-checkbox>
                            </div>
                        }
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-expansion-panel>
    }
</mat-accordion>
<mat-paginator [length]="permissionGroupsKeys().length" [pageSize]="5" [showFirstLastButtons]="true" [hidePageSize]="true">
</mat-paginator>
