import { Component, inject } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ColoredStackedColumnModel } from '../../models/column-types/colored-stacked-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { ColorUtils } from '../../../../core/utils/tools/color-utils.tools';
import { SettingsStorage } from '../../../../core/services/storages/settings.storage';

@Component({
    selector: 'arc-colored-stacked-column',
    templateUrl: './colored-stacked-column.component.html',
    styleUrls: ['./colored-stacked-column.component.scss']
})
export class ColoredStackedColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> {
    get coloredStackedModel(): ColoredStackedColumnModel {
        return new ColoredStackedColumnModel(this.columnModel);
    }
    get colorValue(): OptionalType<string> {
        return this.getItemValue(this.coloredStackedModel.color);
    }
    get borderClass(): OptionalType<string> {
        const hasColorValue = this.coloredStackedModel.isColorInHex && !!this.colorValue;

        if (!hasColorValue) {
            return undefined;
        }

        const isDarkMode = this.settingsStorage.getDarkMode() || false;
        const hsl = ColorUtils.hexToHsl(this.colorValue);

        return isDarkMode && hsl.l < 0.3
            ? 'rim-white'
            : (!isDarkMode && hsl.l > 0.9 ? 'rim-black' : undefined);
    }

    private readonly settingsStorage = inject(SettingsStorage);

    getColor(): string {
        if (this.coloredStackedModel.color !== undefined && !!this.colorValue) {
            if (this.coloredStackedModel.isColorInHex) {
                return this.colorValue.includes('#') ? this.colorValue : `#${this.colorValue}`;
            }

            return this.colorValue.match(/(?<=\[).+?(?=])/)![0].toLowerCase() ?? '';
        }

        return '';
    }
}
