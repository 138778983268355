<div class="flex-col m-8">
    <div class="flex">
        <div class="text-3xl mr-auto">{{ 'LicensingOptions.Title' | transloco }}</div>
        <button mat-icon-button (click)="matDialogRef.close(false)" tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    @if(!isLoading){
        @if (hasBundles) {
            <div>{{ 'LicensingOptions.SelectOptionBelowUseFunction' | transloco }}</div>
        } @else {
            <div>{{ 'LicensingOptions.NoBundlesAvailable' | transloco }}</div>
        }
    }
</div>
@if (hasBundles || isLoading) {
    <mat-dialog-content>
        <div class="h-full flex flex-col m-2">
            @if (hasBundles) {
                <span class="text-3xl">{{ 'LicensingOptions.Modules' | transloco }}</span>
            }
            <div class="relative flex flex-wrap overflow-y-auto my-4 gap-4 min-h-[128px]">
                @if (hasBundles) {
                    @for (option of licensingOptions!.licensePurchaseOptions; track option.title) {
                        <mat-card class="rounded !border !border-solid !border-current !w-[330px] ssm:!w-full !h-[465px]">
                            <mat-card-header class="items-center mt-4">
                                <span class="text-3xl">{{ option.title }}</span>
                            </mat-card-header>
                            <mat-card-content class="!flex grow">
                                <div class="flex flex-col mt-8 overflow-auto w-full">
                                    <div class="text-sm grow mb-auto">{{ option.description }}</div>
                                    <div class="grid grid-cols-1 mb-8">
                                        <div>
                                            <div class="mb-2">{{ 'LicensingOptions.PayNow' | transloco }}</div>
                                            <div class="text-3xl">{{ option.duePayment | arcCurrency }}</div>
                                            <div class="text-xs">
                                                {{ 'LicensingOptions.ForXCashRegisters' | transloco:{ value: option.quantity } }}
                                            </div>
                                        </div>
                                        <div class="mt-2 border-t-[1px] border-current border-solid">
                                            <div class="mb-2 pt-2">
                                                {{ 'LicensingOptions.FromXMonthly' | transloco:{ value: option.recurrentPaymentFrom | arcDate } }}
                                                {{option.paymentPeriod}}
                                            </div>
                                            <div class="text-3xl">{{ option.recurrentPayment | arcCurrency }}</div>
                                            <div class="text-xs">
                                                {{ 'LicensingOptions.ForXCashRegisters' | transloco:{ value: option.quantity } }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                            <mat-card-actions class="justify-center !flex-col gap-2">
                                <div class="w-full" [matTooltipDisabled]="!option.lastDemoDate" matTooltipPosition="above"
                                    [matTooltip]="'LicensingOptions.AlreadyTestedUntilX' | transloco: { value: option.lastDemoDate | arcDate }">
                                    <button class="w-full" mat-stroked-button (click)="upgrade(option, true)"
                                        [disabled]="!!option.lastDemoDate">
                                        {{ 'LicensingOptions.30TestDays' | transloco }}
                                    </button>
                                </div>
                                <button mat-flat-button class="w-full" color='accent' (click)="upgrade(option)">
                                    {{ 'LicensingOptions.Buy' | transloco }}
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    }
                }

                @if (!!licensingOptions?.upgradeProductTitle) {
                    <div class="flex items-center justify-center !w-[100px] !h-[465px]">{{ 'LicensingOptions.Or' | transloco }}</div>
                    <mat-card
                        class="rounded !border !border-solid !border-current !bg-power-search text-on-power-search !w-[330px] !h-[465px]">
                        <mat-card-header class="items-center mt-4">
                            <span class="text-3xl">{{ licensingOptions!.upgradeProductTitle! }}</span>
                        </mat-card-header>
                        <mat-card-content class="!flex grow">
                            <div class="flex flex-col mt-8 overflow-auto w-full">
                                <div class="text-sm grow mb-auto">{{ licensingOptions!.upgradeProductDescription! }}</div>
                                <div class="grid grid-cols-1 mb-8">
                                    <div>
                                        <div class="mb-2">{{ 'LicensingOptions.PayNow' | transloco }}</div>
                                        <div class="text-3xl">{{ licensingOptions!.upgradeDuePayment! | arcCurrency }}</div>
                                    </div>
                                    <div class="mt-2 border-t-[1px] border-current border-solid">
                                        <div class="mb-2 pt-2">
                                            {{ 'LicensingOptions.FromXMonthlyAdditionalCosts' | transloco:{ value: licensingOptions!.upgradeRecurrentPaymentFrom | arcDate } }}
                                        </div>
                                        <div class="text-3xl">{{ licensingOptions!.upgradeAdditionalRecurrentPayment! | arcCurrency }}</div>
                                        <div class="text-xs">
                                            {{ 'LicensingOptions.ForXCashRegisters' | transloco:{ value: licensingOptions!.numberOfPos! } }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions class="justify-center">
                            <div class="grid grid-cols-2 w-full">
                                <div class="w-full"></div>
                                <button mat-flat-button color='accent' (click)="upgradeProduct(licensingOptions!)">
                                    {{ 'LicensingOptions.Upgrade' | transloco }}
                                </button>
                            </div>
                        </mat-card-actions>
                    </mat-card>
                }

                @if (isLoading) {
                    <div class="absolute inset-0 bg-overlay grid place-items-center">
                        <mat-spinner diameter="64"></mat-spinner>
                    </div>
                }
            </div>
        </div>
    </mat-dialog-content>
}
