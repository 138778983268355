import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../../../models/transaction.model';

@Component({
    selector: 'arc-transaction-payments',
    templateUrl: './transaction-payments.detail-widget.html',
    styleUrls: ['./transaction-payments.detail-widget.scss']
})
export class TransactionPaymentsDetailWidget extends BaseDetailWidget<TransactionListModel, TransactionModel> {
    get total(): number {
        return Object.values(this.entityModel.usedPaymentTypes).reduce((acc, item) => acc + item.amount, 0);
    }

    private readonly router = inject(Router);

    goToTransactionPaymentsPage(): void {
        this.router.navigate(['transaction-payments'], { queryParams: { PersonId: this.entityModel.customer?.id } }).then();
    }
}
