import { AfterViewInit, Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { FeatureModel } from '../../../app/models/feature.model';
import { FeatureDetailsDialogComponent } from './feature-details-dialog/feature-details-dialog.component';
import { FeatureStatusEnum } from '../../../app/models/enums/feature-status.enum';
import { FeatureDetailsDialogDataModel } from './feature-details-dialog/models/feature-details-dialog-data.model';
import { FeatureService } from '../../../core/services/feature.service';
import { BaseComponent } from '../../abstractions/base.component';

@Component({
    selector: 'arc-features-widget',
    templateUrl: './features-widget.component.html',
    styleUrls: ['./features-widget.component.scss']
})
export class FeaturesWidgetComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) matSort!: MatSort;

    isLoading = true;
    dataSource = new MatTableDataSource<FeatureModel>([]);

    readonly FeatureStatusEnum = FeatureStatusEnum;
    readonly displayedColumns = ['title', 'status', 'vote', 'featured'];
    readonly featureService = inject(FeatureService);
    readonly matDialog = inject(MatDialog);

    private features: FeatureModel[] = [];

    ngOnInit(): void {
        this.dataSource.filterPredicate = feature =>
            feature.title.toLowerCase().includes(this.dataSource.filter.toLowerCase()) ||
            feature.statusName.toLowerCase().includes(this.dataSource.filter.toLowerCase()) ||
            feature.areaName.toLowerCase().includes(this.dataSource.filter.toLowerCase());

        this.fetchFeatures();

        const sub = this.featureService.featureChanged().subscribe(() => {
            this.fetchFeatures();
        });
        this.addSubscriptions(sub);
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.matSort;
    }

    isNew(feature: FeatureModel): boolean {
        const sevenDaysAgo = new Date().getTime() - 6.048e+8;
        return feature.createdDate.getTime() > sevenDaysAgo;
    }

    openFeatureDetails(feature: FeatureModel): void {
        const dialogRef = this.matDialog.open(FeatureDetailsDialogComponent, {
            data: new FeatureDetailsDialogDataModel({
                featureId: feature.id
            }),
            width: '800px',
            maxWidth: '98vw',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(shouldRefetch => {
            if (shouldRefetch) {
                this.fetchFeatures();
            }
        });
    }

    private fetchFeatures(): void {
        this.isLoading = true;
        this.featureService.getFeatures().subscribe(features => {
            this.features = features;
            this.dataSource.data = this.features;
            this.isLoading = false;
        });
    }
}
