import { Injectable, signal, WritableSignal } from '@angular/core';

import { DictionaryType } from '../models/types/dictionary.type';
import { OptionalType } from '../models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    private data: DictionaryType<any> = {};

    reset(): void {
        for (const key of Object.keys(this.data)) {
            delete this.data[key];
        }
    }

    setOrUpdateSignal<T>(key: string, data: T): void {
        if (typeof this.data[key]?.set === 'function') {
            this.data[key].set(data);
        } else {
            this.data[key] = signal(data);
        }
    }

    getOrCreateSignal<T>(key: string): WritableSignal<OptionalType<T>> {
        if (!this.data[key]) {
            this.data[key] = signal(undefined);
        }

        return this.data[key];
    }

    getOrCreateSignalWithValue<T>(key: string, defaultValue: any): WritableSignal<T> {
        if (!this.data[key]) {
            this.data[key] = signal(defaultValue);
        }

        return this.data[key];
    }
}
