import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TransactionTypePaymentTypeModel } from '../../../../../models/transaction-type-payment-type.model';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';
import { TransactionTypeEditModel } from '../../../../../models/requests/transaction-type-edit.model';
import { TransactionTypeModel } from '../../../../../models/transaction-type.model';

@Component({
    selector: 'arc-transaction-type-payment-type-debit',
    templateUrl: './transaction-type-payment-type-debit.component.html',
    styleUrl: './transaction-type-payment-type-debit.component.scss'
})
export class TransactionTypePaymentTypeDebitComponent extends BaseEditSidebarItemComponent<TransactionTypeModel, TransactionTypeEditModel> {
    override formGroup = new FormGroup({
        transactionTypePaymentTypesDebit: new ArcFormControl<TransactionTypePaymentTypeModel[]>([])
    });
    transactionTypePaymentTypesTableConfig: EditableTableConfigModel<TransactionTypePaymentTypeModel>;

    constructor() {
        super();
        this.transactionTypePaymentTypesTableConfig = new EditableTableConfigModel<TransactionTypePaymentTypeModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    debit: new ArcFormControl(true),
                    paymentTypeId: new ArcFormControl(0, Validators.required),
                    isStandardPayment: new ArcFormControl(false),
                    askAmount: new ArcFormControl(false)
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new GeneralDataColumnModel({
                    propertyName: 'paymentTypeId',
                    isEditable: true,
                    widthPixels: 500,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeDebit.PaymentType',
                    generalDataType: GeneralDataTypeEnum.PaymentTypes
                }),
                new CheckboxColumnModel({
                    propertyName: 'isStandardPayment',
                    isEditable: true,
                    widthPixels: 32,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeDebit.StandardPayment'
                }),
                new CheckboxColumnModel({
                    propertyName: 'askAmount',
                    isEditable: true,
                    widthPixels: 32,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeDebit.AskAmount'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.transactionTypePaymentTypesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        const transactionTypePaymentTypesDebit = this.item.transactionTypePaymentTypes.filter(t => t.debit);
        this.formGroup.patchValue({ transactionTypePaymentTypesDebit });
    }

    override prepareSaveModel(): Partial<TransactionTypeEditModel> {
        if (
            this.item.transactionTypePaymentTypes.filter(t => t.debit).length !== 0 ||
            this.formGroup.value.transactionTypePaymentTypesDebit?.length !== 0
        ) {
            return { transactionTypePaymentTypesDebit: this.formGroup.value.transactionTypePaymentTypesDebit };
        }

        return {};
    }
}
