<div class="grid grid-cols-5 gap-8 w-full h-full">
    <mat-card class="rounded col-span-2 mb-8">
        <mat-card-header class="items-center mt-4">
            <span class="text-3xl">{{ 'MyProfile.Profile' | transloco }}</span>
        </mat-card-header>
        <mat-card-content class="mt-8 grow">
            <form [formGroup]="formGroup" class="!flex !flex-col gap-4">
                <span class="text-sm text-on-app-light">{{ 'MyProfile.ProfileImage' | transloco }}</span>
                <div class="grid grid-cols-4 mb-8">
                    <div>
                        @if (!!profileModel?.profileImageBlobFileName) {
                            <div class="flex items-center justify-center h-full user-image">
                                @if (!!imageSrc) {
                                    <img [src]="getBase64Src()" [attr.alt]="profileModel!.realName!" />
                                } @else {
                                    <div class="flex items-center justify-center">
                                        <mat-spinner [diameter]="64"></mat-spinner>
                                    </div>
                                }
                            </div>
                        } @else {
                            <div class="flex items-center justify-center h-full border border-solid border-on-app-light mx-3">
                                <span class="text-sm text-center">
                                    {{ 'Users.Details.NoImageAvailable' | transloco }}
                                </span>
                            </div>
                        }
                    </div>
                    <div class="flex-col col-span-3 ml-4">
                        <div>
                            <arc-button type="stroked" [isDisabled]="!isInEditMode">{{ 'MyProfile.ChangePassword' | transloco }}
                            </arc-button>
                        </div>
                        <div class="mt-4">
                            <arc-button type="stroked" [isDisabled]="!isInEditMode">{{ 'MyProfile.ChangeEmail' | transloco }}</arc-button>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-4">
                    <arc-input labelKey="MyProfile.Username" [control]="formGroup.controls.username"></arc-input>
                    <arc-input labelKey="MyProfile.Name" [control]="formGroup.controls.realName"></arc-input>
                    <arc-input labelKey="MyProfile.Email" [control]="formGroup.controls.email"></arc-input>
                    <div class="grid grid-cols-5 gap-4">
                        <div class="flex flex-col col-span-3 gap-4">
                            <arc-select labelKey="MyProfile.Language" [options]="allowedLanguages"
                                [control]="formGroup.controls.languageId"></arc-select>

                            <div class='relative'>
                                <arc-input [control]="formGroup.controls.pin" [type]="showPasswordClearText ? 'text' : 'password'"
                                    labelKey="MyProfile.LoginPinForCashRegister" placeholder="********" autocomplete="off">
                                </arc-input>
                                <arc-icon class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                                    [icon]="showPasswordClearText ? 'visibility_off' : 'visibility'"
                                    (click)='showPasswordClearText = !showPasswordClearText'></arc-icon>
                            </div>

                            <arc-checkbox [control]="formGroup.controls.isLeftHanded" labelKey="MyProfile.IsPosLayoutLeftHanded">
                            </arc-checkbox>
                            <arc-color-picker labelKey="MyProfile.UserColor" [control]="formGroup.controls.userColor"></arc-color-picker>
                        </div>
                        <div class="col-span-2 items-center flex-col justify-center">
                            <span class="text-sm">{{ 'MyProfile.LoginQRCodeForArcavisApp' | transloco }}</span>
                            <qrcode [qrdata]="qrCodeData" [width]="180" [margin]="1" class="!flex justify-center"></qrcode>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="rounded col-span-3 mb-8">
        <mat-card-header class="items-center mt-4">
            <span class="text-3xl">{{ 'MyProfile.WorkingHours' | transloco }}</span>
        </mat-card-header>
        <mat-card-content class="!flex grow">
            @if (workingTimesPermission !== true) {
                <div class="flex items-center justify-center h-full w-full">
                    <div [arcPermissionTooltip]="workingTimesPermission">
                        <arc-icon icon="lock" [size]="128" class="text-on-app" (click)="showUpgradeModal(workingTimesPermission)">
                        </arc-icon>
                    </div>
                </div>
            } @else{
                @if (isSaving) {
                    <div class="flex items-center justify-center h-full w-full">
                        <mat-spinner [diameter]="64"></mat-spinner>
                    </div>
                } @else if (isInEditMode) {
                    <arc-editable-table class="w-full" [config]="workingHoursTableConfig"
                        [formControl]="workingHoursFormGroup.controls.workingHours">
                    </arc-editable-table>
                } @else {
                    <arc-editable-table class="w-full" [config]="workingHoursTableConfigNotEditable"
                        [formControl]="workingHoursFormGroupNotEditable.controls.workingHours">
                    </arc-editable-table>
                }
            }
        </mat-card-content>
    </mat-card>
</div>
<div class="z-20 fixed bottom-8 right-8 smd:bottom-2 smd:right-2 flex flex-col-reverse items-center gap-2">
    <div class="flex flex-col-reverse gap-2">
        <button mat-fab color="primary" [disabled]="isSaving" (click)="toggleEditOrSave()">
            <i *ngIf="!isSaving" class="material-icons">{{ isInEditMode ? 'check' : 'edit' }}</i>
            <mat-spinner *ngIf="isSaving" diameter="22"></mat-spinner>
        </button>
    </div>
</div>
