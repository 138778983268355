<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="internalControl" [arcPortalTooltip]="label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="!!label">{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    <mat-select [formControl]="internalControl" (selectionChange)="handleSelect($event)"
        [multiple]="isMultiselect() ? true : null"
        [placeholder]="isPlaceholderTranslated ? placeholder : (placeholder | transloco)">
        <mat-option *ngFor="let option of data" [value]="option.value">
            {{ option.label }}
        </mat-option>
    </mat-select>
    <span *ngIf="!isAllowed" matSuffix>
        <arc-no-permission-lock [missingPermission]="permission">
        </arc-no-permission-lock>
    </span>
    <mat-spinner *ngIf="isAllowed && isLoading" matSuffix diameter="22">
    </mat-spinner>
</mat-form-field>