<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color"
    [arcErrorTooltip]="controlDe" [arcPortalTooltip]="labelKey" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="label || labelKey">{{ label || (labelKey | transloco)}}</mat-label>
    <input class="mr-[40px]" matInput [id]="tagId" (blur)="onBlur()" (input)="onChange($event)"
        [placeholder]="placeholder || (placeholderKey | transloco)" [formControl]="control" />
    <span *ngIf="!isAllowed" matSuffix>
        <arc-no-permission-lock [missingPermission]="permission">
        </arc-no-permission-lock>
    </span>
    <button type="button" mat-icon-button matSuffix *ngIf="isAllowed" (click)="openTranslationDialog()">
        <mat-icon>translate</mat-icon>
    </button>
</mat-form-field>