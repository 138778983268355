<div class="max-w-[360px] flex flex-col gap-12">
    @if (wasRequestSent) {
        <div>
            <h1> {{ "RequestPasswordReset.RequestSentTitle" | transloco }} </h1>
            <p> {{ "RequestPasswordReset.RequestSent" | transloco }} </p>
        </div>
        <mat-card-actions>
            <button mat-flat-button color="accent" (click)="returnToLogin()">
                {{ "RequestPasswordReset.ReturnToLogin" | transloco }}
            </button>
        </mat-card-actions>
    } @else {
        <h1 class="text-3xl mb-4 break-words"> {{ "RequestPasswordReset.Title" | transloco }} </h1>
        <mat-card-content class="!flex flex-col gap-4">
            <div [formGroup]="formGroup">
                <arc-input [control]="formGroup.controls.email" labelKey="General.Email" placeholder="you@arcavis.ch"></arc-input>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button color="accent" (click)="requestResetEmail()">
                {{ "RequestPasswordReset.SendRequest" | transloco }}
            </button>
        </mat-card-actions>
    }
</div>
