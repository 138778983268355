import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { MessageListModel } from '../../../../../models/responses/message-list.model';
import { MessageModel } from '../../../../../models/message.model';

@Component({
    selector: 'arc-message-body',
    templateUrl: './message-body.detail-widget.html',
    styleUrls: ['./message-body.detail-widget.scss']
})
export class MessageBodyDetailWidget extends BaseDetailWidget<MessageListModel, MessageModel> {}
