import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { QueryDesignerModel } from '../../models/query-designer.model';
import { QueryDesignerListModel } from '../../models/responses/query-designer-list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { QueryDesignerColumnModel } from '../../models/responses/query-designer-column.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { QueryDesignerEditModel } from '../../models/query-designer-edit.model';

@Injectable({
    providedIn: 'root'
})
export class QueryDesignerStore extends BaseCrudStore<QueryDesignerModel, QueryDesignerListModel, QueryDesignerEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'query-designer' }));
    }

    getColumnSuggestions(firstColumnId?: number): Observable<ApiResponseModel<QueryDesignerColumnModel[]>> {
        const params: KeyValueModel[] = [];

        if (!!firstColumnId) {
            params.push(new KeyValueModel<string, string>({
                key: 'firstColumnId',
                value: firstColumnId.toString()
            }));
        }

        return this._requestService.makeGet<ApiResponseModel<QueryDesignerColumnModel[]>>(
            this.getUrl('column-suggestions'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            ...params
        );
    }
}
