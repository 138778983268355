import { Component, OnInit, inject, signal, viewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { finalize } from 'rxjs';

import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { BonusRunItemModel } from '../../../../models/responses/bonus-run-item.model';
import { StackedColumnModel } from '../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { NumberColumnModel } from '../../../../../components/dynamic-table/models/column-types/number-column.model';
import { CurrencyColumnModel } from '../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { StaticTableConfigModel } from '../../../../../components/list-views/static-table/models/static-table-config.model';
import { BonusRunsStore } from '../../../../services/stores/bonus-runs.store';
import { OptionalType } from '../../../../../core/models/types/optional.type';
import { CurrencyPipe } from '../../../../../core/pipes/currency.pipe';
import { NumberPipe } from '../../../../../core/pipes/number.pipe';
import { StaticTableComponent } from '../../../../../components/list-views/static-table/static-table.component';

@Component({
    selector: 'arc-bonus-run-create-dialog',
    templateUrl: './bonus-run-create-dialog.component.html',
    styleUrl: './bonus-run-create-dialog.component.scss',
    providers: [
        CurrencyPipe,
        NumberPipe
    ]
})
export class BonusRunCreateDialogComponent implements OnInit {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    readonly formGroup = new FormGroup({
        id: new ArcFormControl<OptionalType<number>>(undefined),
        perDate: new ArcFormControl<Date>(new Date(), Validators.required),
        customerProfileId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        items: new ArcFormControl<BonusRunItemModel[]>([])
    });
    bonusRunsTableConfig!: StaticTableConfigModel<BonusRunItemModel>;
    isLoading = false;
    isCalculating = false;
    table = viewChild.required('table', { read: StaticTableComponent });
    get canExecute(): boolean {
        return this.formGroup.valid && this.selectedCustomersIds.length > 0;
    }
    get canCalculate(): boolean {
        return !this.isLoading && this.formGroup.valid;
    }

    private previousProfileId: OptionalType<number> = undefined;
    private selectedCustomersIds: number[] = [];
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly bonusRunsStore = inject(BonusRunsStore);
    private readonly translocoService = inject(TranslocoService);
    private readonly currencyPipe = inject(CurrencyPipe);
    private readonly numberPipe = inject(NumberPipe);
    private readonly customerTitleSignal = signal<string>('');
    private readonly redeemedPointsTitleSignal = signal<string>('');
    private readonly amountOfVouchersTitleSignal = signal<string>('');
    private readonly discountValueTitleSignal = signal<string>('');

    constructor() {
        this.setupCustomersTable([]);
    }

    ngOnInit(): void {
        this.updateColumnTitles();
    }

    cancel(): void {
        this.matDialogRef.close(false);
    }

    calculate(): void {
        this.isCalculating = true;

        this.table().clearSelection();
        this.bonusRunsStore
            .setup(
                !!this.previousProfileId && this.formGroup.value.customerProfileId === this.previousProfileId
                    ? this.formGroup.value.id
                    : undefined,
                this.formGroup.value.customerProfileId,
                this.formGroup.value.perDate
            )
            .pipe(finalize(() => this.isCalculating = false))
            .subscribe(resp => {
                this.previousProfileId = this.formGroup.value.customerProfileId;

                this.formGroup.patchValue(resp.value || {});
                this.setupCustomersTable(resp.value?.items || []);
            });
    }

    execute(): void {
        this.isLoading = true;
        this.bonusRunsStore
            .execute(this.formGroup.value.id!, this.selectedCustomersIds)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => this.matDialogRef.close(true));
    }

    onSelectionChanged(items: BonusRunItemModel[]): void {
        this.selectedCustomersIds = items.map(i => i.customerId);
        this.updateColumnTitles(items);
    }

    private updateColumnTitles(selectedItems: BonusRunItemModel[] = []): void {
        const { redeemedPoints, amountOfVouchers, discountValue } = selectedItems.reduce(
            (acc, item) => ({
                redeemedPoints: acc.redeemedPoints + item.redeemedPoints,
                amountOfVouchers: acc.amountOfVouchers + item.amountOfVouchers,
                discountValue: acc.discountValue + item.discountValue
            }),
            { redeemedPoints: 0, amountOfVouchers: 0, discountValue: 0 }
        );

        this.customerTitleSignal.set(this.translocoService.translate('BonusRuns.Create.Customer', { value: selectedItems.length }));
        this.redeemedPointsTitleSignal.set(
            this.translocoService.translate('BonusRuns.Create.RedeemedPoints', { value: this.numberPipe.transform(redeemedPoints, 2) })
        );
        this.amountOfVouchersTitleSignal.set(
            this.translocoService.translate('BonusRuns.Create.AmountOfVouchers', { value: amountOfVouchers })
        );
        this.discountValueTitleSignal.set(
            this.translocoService.translate('BonusRuns.Create.DiscountValue', { value: this.currencyPipe.transform(discountValue) })
        );
    }

    private setupCustomersTable(data: BonusRunItemModel[]): void {
        this.bonusRunsTableConfig = new StaticTableConfigModel<BonusRunItemModel>({
            data,
            showCheckboxes: true,
            shouldStartSelected: true,
            availableColumns: [
                new StackedColumnModel({
                    propertyName: 'personDisplayName',
                    propertyName2: 'personNumber',
                    widthPixels: 160,
                    titleSignal: this.customerTitleSignal
                }),
                new NumberColumnModel({
                    propertyName: 'redeemedPoints',
                    widthPixels: 160,
                    titleSignal: this.redeemedPointsTitleSignal
                }),
                new NumberColumnModel({
                    propertyName: 'amountOfVouchers',
                    widthPixels: 170,
                    titleSignal: this.amountOfVouchersTitleSignal
                }),
                new CurrencyColumnModel({
                    propertyName: 'discountValue',
                    widthPixels: 140,
                    titleSignal: this.discountValueTitleSignal
                })
            ]
        });
    }
}
