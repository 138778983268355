export enum PosSyncStatusEnum {
    Ok = 0,
    Offline = 1,
    InitFailed = 2,
    SyncFailed = 3,
    TransactionExecuteFailed = 4,
    NotRunning = 6,
    Paused = 7,
    Stopped = 8,
    Initializing = 9,
    Deactivated = 10,
    Unknown = 11
}
