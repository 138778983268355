import { Component, EventEmitter, input, Output } from '@angular/core';

import { BaseComponent } from '../../../abstractions/base.component';
import { TicketListModel } from '../../../../app/models/responses/ticket-list.model';

@Component({
    selector: 'arc-ticket-list-item',
    templateUrl: './ticket-list-item.component.html',
    styleUrl: './ticket-list-item.component.scss'
})
export class TicketListItemComponent extends BaseComponent {
    @Output() readonly ticketSelected = new EventEmitter<number>();
    ticket = input.required<TicketListModel>();

    handleSelectTicket(ticketId: number): void {
        this.ticketSelected.emit(ticketId);
    }
}
