<form [formGroup]="formGroup" class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4">
    <arc-input class="col-span-6" labelKey="Branches.Edit.Name" [control]="formGroup.controls.name"></arc-input>
    <arc-general-data-select class="col-span-6" label="Branches.Edit.Region"
        [generalDataType]="GeneralDataTypeEnum.Regions" [formControl]="formGroup.controls.regionId"
        [requiredPermission]="PermissionsEnum.Regions">
    </arc-general-data-select>
    <arc-address-picker class="col-span-12" [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber" [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.place" [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>
    <arc-general-data-select class="col-span-6" label="Branches.Edit.MainStore"
        [generalDataType]="GeneralDataTypeEnum.Stores"
        [formControl]="formGroup.controls.mainStoreId"
        [requiredPermission]="PermissionsEnum.Stores">
    </arc-general-data-select>
    <div class="col-span-6"></div>
    <arc-general-data-select class="col-span-6" label="Branches.Edit.OrderStore"
        [generalDataType]="GeneralDataTypeEnum.Stores"
        [formControl]="formGroup.controls.orderStoreId"
        [requiredPermission]="PermissionsEnum.Stores">
    </arc-general-data-select>
    <arc-checkbox class="col-span-6" labelKey="Branches.Edit.CanGenerateOrderNotifications"
        [control]="formGroup.controls.canGenerateOrderNotifications"
        [requiredPermission]="PermissionsEnum.Stores">
    </arc-checkbox>
    <arc-input class="col-span-6" labelKey="Branches.Edit.GLN" [control]="formGroup.controls.gln"></arc-input>
    <div class="col-span-6"></div>
    <mat-form-field class="col-span-6" subscriptSizing="dynamic">
        <mat-label>{{ 'Branches.Edit.DayStart' | transloco }}</mat-label>
        <mat-select [formControl]="formGroup.controls.dayStart" [compareWith]="dayStartCompare">
            <mat-option *ngFor="let time of allowedTimeValues" [value]="time">
                {{ time }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="col-span-6"></div>
    <arc-general-data-select class="col-span-6" label="Branches.Edit.PriceLevel"
        [generalDataType]="GeneralDataTypeEnum.PriceLevels"
        [formControl]="formGroup.controls.priceLevel">
    </arc-general-data-select>
</form>