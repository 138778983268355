import { Component, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArcavisLogListModel } from '../../../../../models/responses/arcavis-log-list.model';
import { ArcavisLogModel } from '../../../../../models/arcavis-log.model';

@Component({
    selector: 'arc-system-log-details-table',
    templateUrl: './system-log-details-table.detail-widget.html',
    styleUrl: './system-log-details-table.detail-widget.scss'
})
export class SystemLogDetailsTableDetailWidget extends BaseDetailWidget<ArcavisLogListModel, ArcavisLogModel, string> implements OnInit {
    keys?: any;

    ngOnInit(): void {
        this.keys = JSON.parse(this.entityModel.extJson ?? '');
    }
}
