
<div class="flex flex-col gap-4 grow overflow-y-auto">
    @if(createTicketService.isLoading()) {
        <div class="grow grid place-items-center">
            <mat-spinner [diameter]="48"></mat-spinner>
        </div>
    } @else {
        @if((createTicketService.currentStep() === CreateTicketStepEnum.CreateRequest || createTicketService.currentStep() === CreateTicketStepEnum.RequestCreated) && !selectedArticle()) {
            <arc-create-ticket-request></arc-create-ticket-request>
        }
        @if(createTicketService.currentStep() === CreateTicketStepEnum.RequestCreated) {
            <arc-create-ticket-response></arc-create-ticket-response>
        }

        @if(createTicketService.currentStep() === CreateTicketStepEnum.CreateTicket) {
            <arc-create-ticket [createTicketResponse]="createTicketService.createTicketResponse()"></arc-create-ticket>
        }
    }
</div>
@if(!selectedArticle()){
    <div
        class="flex items-center justify-between mt-4 sticky bottom-0 bg-element py-8 smd:py-4 shadow-[rgba(0,0,15,0.5)_1px_-6px_9px_-11px] z-[200]">
        <button mat-stroked-button (click)="cancel()" [disabled]="createTicketService.isLoading()">
            {{ 'General.Actions.Cancel' | transloco }}
        </button>
        <div class="flex gap-4">
            @for(actionButton of createTicketService.buttons(); track actionButton.id) 
            {
                @if(actionButton.step === createTicketService.currentStep()) {
                    <arc-button [type]="actionButton.type" [color]="actionButton.color" (clicked)="actionButton.clickFn()" [isDisabled]="createTicketService.isLoading()">
                        {{ actionButton.text }}
                    </arc-button>
                }
            }    
        </div>
    </div>
}