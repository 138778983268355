import { Directive, EventEmitter, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { BaseComponent } from '../../components/abstractions/base.component';

@Directive({})
export abstract class BaseSelectableTable<T> extends BaseComponent {
    @Output() readonly selectionChanged = new EventEmitter<T[]>();

    selectedItems = new SelectionModel<T>(true, []);
    data: T[] = [];

    itemSelectionChanged(event: any, item: T): void {
        if (!!event) {
            this.selectedItems.toggle(item);
            this.selectionChanged.emit(this.selectedItems.selected);
        }
    }

    isEverythingSelected(): boolean {
        const numSelected = this.selectedItems.selected.length;
        const numRows = this.data.length;

        return numSelected === numRows;
    }

    toggleAllRows(): void {
        if (this.isEverythingSelected()) {
            this.selectedItems.clear();
        } else {
            this.selectedItems.select(...this.data);
        }

        this.selectionChanged.emit(this.selectedItems.selected);
    }
}
