import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { VoucherTypeListModel } from '../../../../models/responses/voucher-type-list.model';

@Component({
    selector: 'arc-voucher-type-card',
    templateUrl: './voucher-type-card.component.html',
    styleUrls: ['./voucher-type-card.component.scss']
})
export class VoucherTypeCardComponent extends BaseCardComponent<VoucherTypeListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
