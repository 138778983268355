export enum ModuleIdentificationsEnum {
    Customers = '91A70312-03E2-4CC2-89E6-FC2D060DAC5C',
    ArcavisTouch = 'ACA2AEB0-9678-4AFF-9814-1B95FA28B61C',
    Warehouse = 'DA41E327-BB79-493E-BFF1-038C32A494DE',
    Voucher = '943B65B6-ABDD-4B66-B2F0-D3D2620D7D60',
    Invoice = '2ADC77F0-D63B-4E4D-9D4D-3598054D6CDA',
    Marketing = 'A702F796-24A4-4D8E-8F91-6B011818BFA7',
    Fashion = '73EF5ACF-5EEE-4380-B82A-26CA27A2DAA3',
    Subscription = '2EB1055D-D06E-4A82-8033-59B306A69F53',
    Tourism = '227B72EE-FF9C-4D7E-A3AB-7DC7E24C3D45',
    WebApi = '9BAF79B9-F838-4D5F-840F-EE24B9F770D2',
    Scale = '18CF0580-8F32-41DB-9956-760AC8FDF844',
    EftConnection = '3479C7C7-9D87-46F2-9098-1EEC9B82B3C3',
    WorkingTimer = '4F4C9D0D-8449-4B53-AB04-C9B49FAC373B',
    AccountingExport = '039C4DD3-0640-47AC-82EF-C990691AB21E',
    SpiderDevice = 'FDC518B0-2242-4973-9F8D-D669E93356FA',
    ArcavisApp = '79BAF069-D144-4086-86F8-9979133B3358',
    ArcavisCustomerApp = '6F042318-F08D-4313-95E0-BE5FA1B1DAF6',
    Efsta = '8AD53711-2AFD-4DAC-8532-7D6BAB3FF295',
    Legic = 'AA487A1D-4338-4B19-A2A8-4492388D9158',
    Protel = '16F62CFD-0C49-49A4-A053-DF590CA8AF13',
    Lobos = '436ABDE4-D44A-472D-8ACE-F71A66CAB24B',
    WebstarOrderImport = '5FE598B4-EE86-4B5C-A8CB-24D7F7DEF6C5',
    SevenDaysMediaImport = '840667C0-0826-48D7-92B5-327E01FC780B',
    BnnImport = '8E619762-9ED4-4971-8E61-83C0162C113D',
    Biopartner = '9B3CD6C6-2C3D-4BD0-87F1-4D498AFAB611',
    Edi = '67AD1B44-1D09-4BA7-A6FA-8BB7CD4599B1',
    Boncard = '8B066C81-EA67-4E58-A042-7E03162F472F',
    WeChat = 'CFAC19EA-27D0-4301-8C41-B0E606C29B51',
    Proffix = '90EFEFFC-0EF1-41AB-93D1-B11F6EAEA0C3',
    Semabit = '06EB347E-0C4D-40D2-A736-087CB66F0BFB',
    CloudSla = '289B0F1A-C3EF-4788-9C3C-E63674A42564',
    RegionAndBranches = '4E8471D1-F314-4E1B-8FB6-6AFB13B59EDE',
    CustomerOrderManagement = '385C410F-D3A0-4C6F-8279-823C0ED42E23',
    Gastro = 'D9D00149-4608-4947-8383-0EDDE904C7CC',
    AzureAi = '8D94FBCC-0A4C-4C6A-B203-DE2C81530D3D',
    Ticketpark = '8D4C8C21-4F9A-4532-B73F-65F4386168F1',
    InlaneCash = 'F715F69A-9AB5-42DB-848E-1563610EC81A',
    NTree = '1716A2EF-9CE3-47F5-812D-70A827CC2DB5',
    Mews = '87D9A4E0-D9BD-482A-ADE0-9015B9805CCB',
    Bexio = '4C14768A-0324-4553-A481-855021B25248',
    EGuma = 'D12D2681-E92F-490B-9FE8-3098ABAB1716'
}

export class ModuleIdentificationsEnumExtensions {
    static getKey(value: string): string {
        const index = Object.values(ModuleIdentificationsEnum).indexOf(
            value.toUpperCase() as ModuleIdentificationsEnum
        );
        return Object.keys(ModuleIdentificationsEnum)[index];
    }
}
