<div class="!m-4">
    <div class="mb-4">
        <p class="text-on-app-light">{{ 'VoucherTypes.VouchersQuantity' | transloco }}</p>
        <p class="text-xl">{{ item.vouchersQuantity }}</p>
    </div>
    <ng-container *ngIf="item.isDiscountVoucher; else valueVoucherTpl">
        <div class="mb-4">
            <p class="text-on-app-light">{{ 'Sales.Edit.Validity' | transloco }}</p>
            <p class="text-xl">{{ item.validFrom | arcDate }}
                <span *ngIf="!!item.validThru"> - {{ item.validThru | arcDate }}</span>
            </p>
        </div>
    </ng-container>
    <ng-template #valueVoucherTpl>
        <div>
            <p class="text-on-app-light">{{ 'VoucherTypes.TotalValue' | transloco }}</p>
            <p class="text-xl">{{ item.totalValue | arcCurrency }}</p>
        </div>
    </ng-template>
</div>
