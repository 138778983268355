<div class='flex items-center justify-center relative w-full h-full'>
    <ng-container>
        <div class="w-3/4 h-3/4 max-w-[360px] max-h-[360px] pointer-events-none transition-all duration-[500ms] absolute opacity-0 mix-blend-multiply" [class.opacity-100]='imageLoadingState === ImageLoadingStateEnum.Success && didViewLoad'>
            <img class='w-full h-full object-contain' [src]="baseUrl + '/auth/logo?subdomain=' + subdomain" (error)='handleImageLoadError()' (load)='handleImageLoad()' alt="">
        </div>
    </ng-container>
    <ng-container *ngIf='imageLoadingState === ImageLoadingStateEnum.Error'>
        <div class="relative w-full h-full transition-all duration-[500ms] opacity-0" [class.opacity-100]='didViewLoad'>
            <div class="bg-element-selected w-3/4 p-24 p-l-12 flex-col gap-12 absolute left-0 top-1/2 -translate-y-1/2 rounded-r-[2rem]">
                <h2 class='text-5xl leading-none text-on-app'>
                    {{ 'Login.Ad.Title' | transloco }}
                </h2>
                <ul class='flex-col gap-2 self-end min-w[60%]'>
                    <li>
                        {{ 'Login.Ad.Feature1' | transloco }}
                    </li>
                    <li>
                        {{ 'Login.Ad.Feature2' | transloco }}
                    </li>
                    <li>
                        {{ 'Login.Ad.Feature3' | transloco }}
                    </li>
                </ul>
                <div class="absolute leading-[0] w-[20%] left-[5%] bottom-0">
                    <img class='rounded-[2rem] translate-y-[40%]' src="assets/images/marketing_image_client_2.png" alt="">
                </div>
                <div class="absolute leading-[0] w-[40%] top-0 left-[40%]">
                    <img class='rounded-[2rem]  -translate-y-[90%]' src="assets/images/marketing_image_pos.png" alt="">
                </div>
                <div class="absolute leading-[0] w-1/3 right-0 top-[40%]">
                    <img class='rounded-[2rem] translate-x-[70%]' src="assets/images/marketing_image_client_1.png" alt="">
                </div>
                <div class="absolute leading-[0] left-8 w-12 top-0 -translate-y-1/2">
                    <svg class='w-full h-auto' width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M30.2559 49.9999H20.6204C19.4054 49.9999 18.4204 49.0149 18.4204 47.7999L18.4204 33.4554C18.4204 32.9031 17.9727 32.4554 17.4204 32.4554H3.07598C1.86095 32.4554 0.875977 31.4705 0.875977 30.2554V20.6199C0.875977 19.4049 1.86095 18.4199 3.07598 18.4199H17.4204C17.9727 18.4199 18.4204 17.9722 18.4204 17.4199L18.4204 3.07549C18.4204 1.86046 19.4054 0.875488 20.6204 0.875488H30.2559C31.4709 0.875488 32.4559 1.86046 32.4559 3.07549L32.4559 17.4199C32.4559 17.9722 32.9036 18.4199 33.4559 18.4199H47.8003C49.0154 18.4199 50.0003 19.4049 50.0003 20.6199V30.2554C50.0003 31.4705 49.0154 32.4554 47.8003 32.4554H33.4559C32.9036 32.4554 32.4559 32.9031 32.4559 33.4554L32.4559 47.7999C32.4559 49.0149 31.4709 49.9999 30.2559 49.9999Z"
                              fill="#B7C4CA" />
                    </svg>
                </div>
            </div>
            <div class="absolute leading-[0] top-12 right-12 w-6">
                <svg class='w-full h-auto' width="28" height="41" viewBox="0 0 28 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M27.2316 21.8576L21.8576 27.2316C21.8561 27.233 21.8547 27.2344 21.8533 27.2358L9.12965 39.9595C8.27049 40.8186 6.87753 40.8186 6.01838 39.9595L0.644367 34.5855C-0.214787 33.7263 -0.214788 32.3334 0.644366 31.4742L11.1095 21.009C11.5001 20.6185 11.5001 19.9853 11.1095 19.5948L0.644367 9.12965C-0.214787 8.27049 -0.214787 6.87753 0.644367 6.01838L6.01838 0.644367C6.87753 -0.214787 8.27049 -0.214788 9.12965 0.644366L21.8533 13.368C21.8547 13.3694 21.8561 13.3708 21.8576 13.3723L27.2316 18.7463C27.9833 19.4981 28.0773 20.6585 27.5135 21.5124C27.4329 21.6344 27.339 21.7502 27.2316 21.8576Z"
                          fill="#B7C4CA" />
                </svg>
            </div>
            <div class="absolute leading-[0] bottom-24 left-[40%] w-8">
                <svg class='w-full h-auto' width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M36 9.8L36 2.2C36 0.984974 35.015 -4.30546e-08 33.8 -9.61651e-08L2.2 -1.47745e-06C0.984974 -1.53056e-06 -4.30546e-08 0.984972 -9.61651e-08 2.2L-4.28372e-07 9.8C-4.81482e-07 11.015 0.984973 12 2.2 12L33.8 12C35.015 12 36 11.015 36 9.8Z"
                        fill="#D9E2E7" />
                    <path
                        d="M36 25.8L36 18.2C36 16.985 35.015 16 33.8 16L2.2 16C0.984974 16 -4.30546e-08 16.985 -9.61651e-08 18.2L-4.28372e-07 25.8C-4.81482e-07 27.015 0.984973 28 2.2 28L33.8 28C35.015 28 36 27.015 36 25.8Z"
                        fill="#D9E2E7" />
                </svg>
            </div>
            <a class="group absolute bottom-12 right-12 flex items-center cursor-pointer transition-all text-on-app gap-2" href="https://www.arcavis.ch/" target="_blank" rel="noopener">
                {{ 'Login.Ad.LearnMore' | transloco }} <mat-icon class='text-2xl relative transition-all group-hover:translate-x-[0.25rem] '>chevron_right</mat-icon>
            </a>
        </div>
    </ng-container>
</div>
