import { Observable } from 'rxjs';

export class EditableTableButtonModel<TData> {
    labelKey!: string;
    isDisabled = false;
    tooltipKey?: string;
    action!: (currentData: TData[]) => Observable<any>;

    constructor(init?: Partial<EditableTableButtonModel<TData>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
