<div class="flex flex-col gap-4">
    <arc-chip-selector label="Articles.EditFields.Sizes" [uniqueIdFn]="getSizeId" [(ngModel)]="selectedSizes" [displayFn]="sizeDisplayFn"
        [searchFn]="sizesSearch.bind(this)" (ngModelChange)="updateTable()" [addFn]="addSize.bind(this)" [formControl]="sizeControl">
    </arc-chip-selector>
    <arc-chip-selector label="Articles.EditFields.Colors" [uniqueIdFn]="getColorId" [(ngModel)]="selectedColors"
        [displayFn]="colorDisplayFn" [searchFn]="colorsSearch.bind(this)" [bgColorFn]="colorBgColorDisplay.bind(this)"
        [fgColorFn]="colorFgColorDisplay.bind(this)" [formControl]="colorControl" [addFn]="addColor.bind(this)"
        (ngModelChange)="updateTable()">
    </arc-chip-selector>
    <div class="relative">
        <arc-editable-table [config]="variantsTableConfig" [formControl]="formGroup.controls.variants"></arc-editable-table>
        @if(isLoading()){
            <div class="absolute bg-overlay inset-0 z-[999]">
                <mat-spinner [diameter]="48" class="top-8 left-1/2 -translate-x-1/2"></mat-spinner>
            </div>
        }
    </div>
</div>
