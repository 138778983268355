import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class CurrencyColumnModel extends BaseColumnModel {
    showCurrencySymbol = true;
    currencyId?: ((record: any) => string);

    constructor(init?: Partial<CurrencyColumnModel>) {
        super(ColumnTypeEnum.Currency);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
