import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { TransactionListModel } from '../../models/responses/transaction-list.model';
import { TransactionModel } from '../../models/transaction.model';
import { ResponseTypeEnum } from '../../../core/models/enums/response-type.enum';

@Injectable({
    providedIn: 'root'
})
export class TransactionsStore extends BaseCrudStore<TransactionModel, TransactionListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'transactions' }));
    }

    printTransaction(id: string): Observable<any> {
        return this._requestService.makeGet(this.getUrl(`${id}/file`), undefined, undefined, undefined, undefined, ResponseTypeEnum.Blob);
    }
}
