<form [formGroup]="formGroup" class="grid items-center grid-cols-2 gap-4">
    <arc-input labelKey="PointOfSales.Edit.Name" [control]="formGroup.controls.name"></arc-input>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Branches"
        [formControl]="formGroup.controls.branchId"
        label="PointOfSales.Edit.Branch"></arc-general-data-select>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.PosLayouts" 
        [formControl]="formGroup.controls.posLayoutId"
        label="PointOfSales.Edit.Layout"></arc-general-data-select>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.Taxes"
        [formControl]="formGroup.controls.overrideTaxId"
        label="PointOfSales.Edit.OverrideTax"></arc-general-data-select>
        <arc-datepicker labelKey="PointOfSales.Edit.DeactivationDate" [control]="formGroup.controls.deactivationDate"></arc-datepicker>
</form>
