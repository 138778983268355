import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { RegionModel } from '../../../../../models/responses/region.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-region-edit-base-data',
    templateUrl: './region-edit-base-data.component.html',
    styleUrl: './region-edit-base-data.component.scss'
})
export class RegionEditBaseDataComponent extends BaseEditSidebarItemComponent<RegionModel> {
    override formGroup = new FormGroup({
        title: new ArcFormControl('', Validators.required)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<RegionModel> {
        return { title: this.formGroup.value.title };
    }
}
