import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ImportDefinitionListModel } from '../../../models/responses/import-definition-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { ImportDefinitionModel } from '../../../models/responses/import-definition.model';
import { ImportDefinitionsStore } from '../../../services/stores/import-definitions.store';
import { ImportDefinitionCardComponent } from './components/card/import-definition-card.component';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-import-definitions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./import-definitions.component.scss']
})
export class ImportDefinitionsComponent extends PageListViewComponent<ImportDefinitionListModel, ImportDefinitionModel> {
    private readonly importDefinitionsStore = inject(ImportDefinitionsStore);

    constructor() {
        super('ImportDefinitions');
        this.config = new CardListViewConfigModel<ImportDefinitionModel, ImportDefinitionListModel>({
            entityName: 'ImportDefinition',
            store: this.importDefinitionsStore,
            cardComponent: ImportDefinitionCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasExport: false, hasEdit: false })
        });
    }
}
