import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { VoucherListModel } from '../../models/responses/voucher-list.model';
import { VoucherModel } from '../../models/voucher.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { VoucherCreateModel } from '../../models/voucher-create.model';

@Injectable({
    providedIn: 'root'
})
export class VouchersStore extends BaseCrudStore<VoucherModel, VoucherListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'vouchers' }));
    }

    addInBatch(records: VoucherCreateModel[]): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl('add-in-batch'), records);
    }

    generateIds(voucherTypeId: number, quantity = 1): Observable<ApiResponseModel<string[]>> {
        return this._requestService.makeGet<ApiResponseModel<string[]>>(
            this.getUrl(`generate-ids/${voucherTypeId}/${quantity}`)
        );
    }
}
