import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleVariantListModel } from '../../../../../models/responses/article-variant-list.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-variant-pictures',
    templateUrl: './article-variant-pictures.detail-widget.html',
    styleUrl: './article-variant-pictures.detail-widget.scss'
})
export class ArticleVariantPicturesDetailWidget extends BaseDetailWidget<ArticleVariantListModel, ArticleModel> {}
