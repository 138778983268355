<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToTransactionArticlesPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        <div *ngIf="isLoading; else chart" class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <ng-template #chart>
            <div *ngFor="let topTA of topTransactionArticles" class="relative mb-1 h-[43px]">
                <div class="flex h-full w-full bg-info-light text-on-app overflow-visible items-center"
                    [style.width.%]="widths[topTA.quantity]">
                    <div class="ml-3 grow">
                        <div class="text-xs mb-[-5px]">{{topTA.articleNumber}}</div>
                        <span class="text-sm whitespace-nowrap">{{topTA.article}}</span>
                    </div>
                    <div class="ml-4 float-right mr-3">
                        <span class="text-2xl">{{topTA.quantity}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
