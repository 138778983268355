@if (isAllowed) {
    <mat-checkbox [formControl]="castedControl" [color]="color" [id]="tagId" [required]="isRequired" [indeterminate]="isIndeterminate"
        [arcPortalTooltip]="labelKey" [labelPosition]="showLabelInFront ? 'before' : 'after'">
        {{ label || (labelKey | transloco) }}
    </mat-checkbox>
} @else {
    <div class="flex gap-2 items-center">
        <arc-no-permission-lock [iconSize]="24" [missingPermission]="permission" class="ml-[4px]"></arc-no-permission-lock>
        <span> {{ label || (labelKey | transloco) }} </span>
    </div>
}
