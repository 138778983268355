import { Component, WritableSignal, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TagsStore } from '../../../../../services/stores/tags.store';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { EntityTagModel } from '../../../../../models/entity-tag.model';
import { ArticleModel } from '../../../../../models/article.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ArticleStatusEnum } from '../../../../../models/enums/article-status.enum';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { ModuleIdentificationsEnum } from '../../../../../models/enums/module-identifications.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { GeneralDataService } from '../../../../../../core/services/general-data.service';
import { KeyValueModel } from '../../../../../../core/models/key-value.model';

@Component({
    selector: 'arc-article-edit-properties',
    templateUrl: './article-edit-properties.component.html',
    styleUrls: ['./article-edit-properties.component.scss']
})
export class ArticleEditPropertiesComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    static readonly tagsSignal = 'tags';
    static readonly wasTagsChangedByUserSignal = 'wasTagsChangedByUser';
    static readonly seasonIdSignal = 'seasonId';
    PermissionsEnum = PermissionsEnum;
    UnitsEnum = UnitsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    isFashionLicensed = false;
    contentUnits: KeyValueModel[] = [];
    quantityPerUnitLabel = 'Articles.EditFields.QuantityPerUnit';
    contentUnitPlaceholder = 'g / ml';

    override formGroup = new FormGroup({
        status: new ArcFormControl<ArticleStatusEnum>(ArticleStatusEnum.Active),
        isDiscountLocked: new ArcFormControl(false),
        ignoreStock: new ArcFormControl(false),
        showOnWebApi: new ArcFormControl(false),
        isBonusLocked: new ArcFormControl(false),
        isNegativeQuantity: new ArcFormControl(false),
        tags: new ArcFormControl<EntityTagModel[]>([]),
        seasonId: new ArcFormControl<OptionalType<number>>(undefined),
        requiredAge: new ArcFormControl<OptionalType<number>>(undefined),
        warrantyDays: new ArcFormControl<OptionalType<number>>(undefined),
        weightGrams: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ min: 0, isInteger: true })),
        quantityPerUnit: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ min: 0 })),
        contentUnitId: new ArcFormControl<OptionalType<number>>(undefined),
        brandSupplierId: new ArcFormControl<OptionalType<number>>(undefined),
        originCountryIsoCode: new ArcFormControl<OptionalType<string>>(undefined)
    });
    readonly tagsStore = inject(TagsStore);
    readonly translationService = inject(TranslationService);
    readonly statusItems = [
        {
            value: ArticleStatusEnum.Active,
            label: this.translationService.getText('Articles.EditFields.StatusActive')
        },
        {
            value: ArticleStatusEnum.Discontinued,
            label: this.translationService.getText('Articles.EditFields.StatusDiscontinued')
        },
        {
            value: ArticleStatusEnum.Inactive,
            label: this.translationService.getText('Articles.EditFields.StatusLocked')
        }
    ];

    private readonly sharedDataService = inject(SharedDataService);
    private readonly permissionService = inject(PermissionService);
    private readonly generalDataService = inject(GeneralDataService);
    private readonly tags: WritableSignal<EntityTagModel[]>;
    private readonly wasTagsChangedByUser: WritableSignal<boolean>;
    private readonly seasonId: WritableSignal<OptionalType<number>>;

    constructor() {
        super();

        this.wasTagsChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditPropertiesComponent.wasTagsChangedByUserSignal,
            false
        );
        this.tags = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditPropertiesComponent.tagsSignal, []);
        this.seasonId = this.sharedDataService.getOrCreateSignal(ArticleEditPropertiesComponent.seasonIdSignal);
        this.isFashionLicensed = this.permissionService.hasModuleLicensed(ModuleIdentificationsEnum.Fashion);
        this.generalDataService.getGeneralData(GeneralDataTypeEnum.ContentUnits).subscribe(res => {
            this.contentUnits = res;
            this.contentUnits.unshift({ value: this.contentUnitPlaceholder, key: undefined });
        });
    }

    override onItemSet(): void {
        const tagsSub = this.formGroup.controls.tags.valueChanges.subscribe(t => {
            if (!!t) {
                this.wasTagsChangedByUser.set(this.formGroup.controls.tags.dirty);
                this.tags.set(t);
            }
        });

        const seasonSub = this.formGroup.controls.seasonId.valueChanges.subscribe(s => {
            if (!!s) {
                this.seasonId.set(s);
            }
        });

        this.formGroup.patchValue({ ...this.item, status: this.item.articleStatus });
        this.addSubscriptions(tagsSub, seasonSub);
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        const value = this.formGroup.value;
        return {
            status: value.status,
            isDiscountLocked: value.isDiscountLocked,
            ignoreStock: value.ignoreStock,
            showOnWebApi: value.showOnWebApi,
            isBonusLocked: value.isBonusLocked,
            isNegativeQuantity: value.isNegativeQuantity,
            tags: value.tags,
            requiredAge: value.requiredAge,
            warrantyDays: value.warrantyDays,
            weightGrams: value.weightGrams,
            quantityPerUnit: value.quantityPerUnit,
            brandSupplierId: value.brandSupplierId,
            originCountryIsoCode: value.originCountryIsoCode,
            seasonId: value.seasonId,
            contentUnitId: value.contentUnitId
        };
    }

    tagDisplayFn(tag: EntityTagModel): string {
        return tag.title ?? '';
    }

    getTagKey(tag: EntityTagModel): number {
        return tag.id;
    }

    tagsSearch(searchText: string): Observable<EntityTagModel[]> {
        return this.tagsStore.search(new SearchRequestModel({ searchText })).pipe(map(response => response.value?.records ?? []));
    }
}
