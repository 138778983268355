<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div>
        {{ 'Articles.EditFields.WhichArticleTypeShouldCreate' | transloco }}
    </div>
    <div class="mt-2 pt-2 grow w-full flex gap-4">
        <arc-button type="flat" (clicked)="openSidebar(false)">
            {{ 'Articles.EditFields.RegularArticle' | transloco }}
        </arc-button>
        <arc-button color="accent" type="flat"(clicked)="openSidebar(true)">
            {{ 'Articles.EditFields.FashionVariants' | transloco }}
        </arc-button>
    </div>
</mat-dialog-content>
