import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { PointOfSaleListModel } from '../../../../../models/responses/pos-list.model';
import { PosSyncStatusEnum } from '../../../../../../core/models/enums/pos-sync-status.enum';

const hideStatusForPosIds = ['00000000-0000-0000-0000-000000000000', '99999999-9999-9999-9999-999999999999'];

@Component({
    selector: 'arc-pos-type-column',
    templateUrl: './pos-type-column.component.html',
    styleUrls: ['./pos-type-column.component.scss']
})
export class PosTypeColumnComponent extends BaseCustomColumnComponent<PointOfSaleListModel> {
    get colorClasses(): string[] {
        let status = this.item.status ?? PosSyncStatusEnum.Unknown;
        status = !this.item.deactivationDate ? status : PosSyncStatusEnum.Deactivated;

        const green = [PosSyncStatusEnum.Ok];
        const grey = [
            PosSyncStatusEnum.Offline,
            PosSyncStatusEnum.NotRunning,
            PosSyncStatusEnum.Paused,
            PosSyncStatusEnum.Stopped,
            PosSyncStatusEnum.Deactivated
        ];
        const red = [PosSyncStatusEnum.InitFailed, PosSyncStatusEnum.SyncFailed, PosSyncStatusEnum.TransactionExecuteFailed];

        if (green.includes(status)) {
            return ['bg-success', 'text-on-success'];
        } else if (grey.includes(status)) {
            return ['bg-element-disabled', 'text-on-element-disabled'];
        } else if (red.includes(status)) {
            return ['bg-error', 'text-on-error'];
        }

        // Unknown or Initializing
        return ['bg-warn', 'text-on-warn'];
    }

    get status(): string {
        return !this.item.deactivationDate
            ? PosSyncStatusEnum[this.item.status ?? PosSyncStatusEnum.Unknown]
            : PosSyncStatusEnum[PosSyncStatusEnum.Deactivated];
    }

    get showStatus(): boolean {
        return !hideStatusForPosIds.includes(this.item.posIdentification ?? '');
    }
}
