import { Component, inject, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';
import { SimpleTransactionModel } from '../../../../../models/simple-transaction.model';
import { InvoicesStore } from '../../../../../services/stores/invoices.store';

@Component({
    selector: 'arc-invoice-transactions',
    templateUrl: './invoice-transactions.detail-widget.html',
    styleUrls: ['./invoice-transactions.detail-widget.scss']
})
export class InvoiceTransactionsDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> implements OnInit {
    isLoading = true;
    items: SimpleTransactionModel[] = [];

    private readonly invoicesStore = inject(InvoicesStore);

    ngOnInit(): void {
        this.items = this.entityModel.transactions;

        if (this.items.length < 5) {
            this.isLoading = false;
            return;
        }

        this.invoicesStore.getTransactions(this.entityModel.id).subscribe(r => {
            this.items = r.value || [];
            this.isLoading = false;
        });
    }
}
