import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArticleImageModel } from '../../../../../models/responses/article-image.model';
import { ArticleModel } from '../../../../../models/article.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';

@Component({
    selector: 'arc-article-edit-images',
    templateUrl: './article-edit-images.component.html',
    styleUrl: './article-edit-images.component.scss'
})
export class ArticleEditImagesComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    override formGroup = new FormGroup({
        articleImages: new ArcFormControl<ArticleImageModel[]>([])
    });

    override onItemSet(): void {
        this.formGroup.patchValue({ articleImages: this.item.articleImages });
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return {
            articleImages: this.formGroup.value.articleImages
        };
    }
}
