import { Directive, OnChanges, OnDestroy, Input, SimpleChanges, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { PermissionService } from '../services/permission.service';

@Directive({
    selector: '[arcPermissionTooltip]'
})
export class PermissionTooltipDirective extends MatTooltip implements OnChanges, OnDestroy {
    @Input({ alias: 'arcPermissionTooltip', required: true }) permission!: true | AuthPermissionModel;

    private readonly permissionService = inject(PermissionService);

    ngOnChanges(changes: SimpleChanges): void {
        const permissionChanges = changes['permission'];
        if (!!permissionChanges) {
            if (this.permission === true) {
                this.message = '';
            } else {
                this.permissionService.getTooltipTextObs(this.permission).subscribe(t => this.message = t);
            }
        }
    }
}
