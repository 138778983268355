import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Observable, switchMap, tap } from 'rxjs';

import { RelatedEntityRequestModel } from '../../app/models/requests/tag-articles-request.model';
import { ComplexDataTypesEnum } from '../../core/models/complex-data-types.enum';
import { ApiResponseModel } from '../../app/models/responses/api-response.model';
import { SearchRequestService } from '../../core/services/search-request.service';
import { FilterService } from '../../core/services/filter.service';
import { SearchRequestModel } from '../../app/models/requests/search-request.model';
import { SearchResponseModel } from '../../app/models/responses/search-response.model';
import { ColumnSortModel } from '../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../core/models/enums/sort-direction.enum';
import { BaseColumnModel } from '../dynamic-table/models/column-types/base-column.model';
import { articleSelectionTableConfig } from '../dialogs/data-selection-dialog/data-selection-table-configs/article-selection-table.config';
import {
    customerSelectionTableConfig
} from '../dialogs/data-selection-dialog/data-selection-table-configs/customer-selection-table.config';
import {
    supplierSelectionTableConfig
} from '../dialogs/data-selection-dialog/data-selection-table-configs/supplier-selection-table.config';
import { ColumnTypeEnum } from '../dynamic-table/models/enums/column-type.enum';
import { BaseComponent } from '../abstractions/base.component';
import { DataSelectionDialogComponent } from '../dialogs/data-selection-dialog/data-selection-dialog.component';
import { DataSelectionDialogDataModel } from '../dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { RelatedEntityUpdateModel } from '../../app/models/requests/related-entity-update.model';
import { Identifyable } from '../../core/abstractions/identifyable';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { PermissionService } from '../../core/services/permission.service';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { PermissionTypeEnum } from '../../core/models/enums/permission-type.enum';

@Component({
    selector: 'arc-related-entity-assignment',
    templateUrl: './related-entity-assignment.component.html',
    styleUrls: ['./related-entity-assignment.component.scss'],
    providers: [SearchRequestService, FilterService]
})
export class RelatedEntityAssignmentComponent<TList extends Identifyable<TId>, TId = number> extends BaseComponent implements OnInit {
    // eslint-disable-next-line max-len
    @Input() getDataFn!: (id: TId, requestModel: RelatedEntityRequestModel<TId>) => Observable<ApiResponseModel<SearchResponseModel<TList, TId>>>;
    @Input() relatedEntityType!: ComplexDataTypesEnum;
    @Input() customColumnConfig?: BaseColumnModel[];
    @Input() customSelectionTableColumnConfig?: BaseColumnModel[];
    @Input() entityId!: TId;
    @Input() requiredPermission!: PermissionsEnum;
    @Input() isCreate = false;
    @Output() readonly relatedEntityUpdates = new EventEmitter<RelatedEntityUpdateModel<TId>>();

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    updatePermission: true | AuthPermissionModel = true;
    data: TList[] = [];
    isLoading = false;
    totalRecords = 0;
    relatedEntityChanges = new RelatedEntityUpdateModel<TId>();
    pageSize = 15;
    searchText = '';
    ColumnTypeEnum = ColumnTypeEnum;

    private readonly searchRequestService = inject(SearchRequestService);
    private readonly permissionService = inject(PermissionService);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();
        this.searchRequestService.init(new SearchRequestModel({ pageSize: this.pageSize, pageIndex: 0, searchText: '' }));
        const loadingStartSub = this.searchRequestService.loadingStart$.subscribe(() => (this.isLoading = true));
        const searchRequestChangedSub = this.searchRequestService.searchRequestChanged$
            .pipe(
                tap(() => {
                    this.isLoading = true;
                    this.data = [];
                }),
                switchMap(requestModel => this.getDataFn(
                    this.entityId, new RelatedEntityRequestModel<TId>({
                        ...requestModel,
                        addedIds: this.relatedEntityChanges.addedIds
                    }))
                )
            )
            .subscribe(result => {
                if (this.totalRecords !== result.value?.totalRecords) {
                    this.paginator.firstPage();
                }
                this.isLoading = false;
                const records = result.value?.records ?? [];
                this.data = records;
                this.totalRecords = result.value?.totalRecords ?? 0;
            });

        this.addSubscriptions(loadingStartSub, searchRequestChangedSub);
    }

    ngOnInit(): void {
        this.searchRequestService.forceReload();
        this.updatePermission = this.permissionService.hasPermission(this.requiredPermission, PermissionTypeEnum.Update);
    }

    get columnConfig(): BaseColumnModel[] {
        if (!!this.customColumnConfig) {
            return this.customColumnConfig;
        }

        switch (this.relatedEntityType) {
            case ComplexDataTypesEnum.Article:
                return articleSelectionTableConfig;
            case ComplexDataTypesEnum.Customer:
                return customerSelectionTableConfig;
            case ComplexDataTypesEnum.Supplier:
            default:
                return supplierSelectionTableConfig;
        }
    }

    get displayedColumns(): string[] {
        const columns = this.columnConfig?.map(c => c.propertyName!) ?? [];
        columns.push('delete');
        return columns;
    }

    handlePageEvent(event: PageEvent): void {
        this.searchRequestService.setPaginatorOptions(event.pageIndex, event.pageSize);
    }

    searchTextChanged(): void {
        this.searchRequestService.setSearchText(this.searchText);
    }

    handleSort(sort: Sort): void {
        this.searchRequestService.setSortings([
            new ColumnSortModel({
                column: sort.active,
                direction: sort.direction === 'desc' ? SortDirectionEnum.Desc : SortDirectionEnum.Asc
            })
        ]);
    }

    removeItem(id: TId): void {
        // eslint-disable-next-line eqeqeq
        const itemAddedIndex = this.relatedEntityChanges.addedIds.findIndex(value => id == value);
        if (itemAddedIndex >= 0) {
            this.relatedEntityChanges.addedIds.splice(itemAddedIndex, 1);
            this.searchRequestService.forceReload();
            this.relatedEntityUpdates.emit(this.relatedEntityChanges);
            return;
        }
        this.relatedEntityChanges.removedIds.push(id);
        this.searchRequestService.forceReload();
        this.relatedEntityUpdates.emit(this.relatedEntityChanges);
    }

    removeAllRelatedEntites(): void {
        if (this.data.length > 0) {
            this.relatedEntityChanges.removeAll = true;
        }
        this.relatedEntityChanges.removedIds = [];
        this.relatedEntityChanges.addedIds = [];
        this.searchRequestService.forceReload();
        this.relatedEntityUpdates.emit(this.relatedEntityChanges);
    }

    openSelectionDialog(event: MouseEvent): void {
        event.stopPropagation();
        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: new DataSelectionDialogDataModel({
                type: this.relatedEntityType,
                customColumnConfig: this.customSelectionTableColumnConfig,
                isMultiSelect: true
            }),
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe((result?: TList[]) => {
            if (!!result && result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                    this.relatedEntityChanges.addedIds.push(result[i].id);
                }
                this.searchRequestService.forceReload();
                this.relatedEntityUpdates.emit(this.relatedEntityChanges);
            }
        });
    }
}
