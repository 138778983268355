import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-stock-data',
    templateUrl: './article-article-stock-data.detail-widget.html',
    styleUrls: ['./article-article-stock-data.detail-widget.scss']
})
export class ArticleArticleStockDataDetailWidget extends BaseDetailWidget<ArticleListModel, ArticleModel> {}
