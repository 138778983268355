import { Component, Input } from '@angular/core';

import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { TransactionUsedPaymentModel } from '../../../../app/models/transaction-used-payment.model';

@Component({
    selector: 'arc-payments-breakdown',
    templateUrl: './payments-breakdown.component.html',
    styleUrls: ['./payments-breakdown.component.scss']
})
export class PaymentsBreakdownComponent {
    @Input() items!: TransactionUsedPaymentModel[];
    @Input() shouldDisplayTotal = false;

    get total(): number {
        return this.items.reduce((acc, item) => acc + item.amount, 0);
    }

    readonly colors: DictionaryType<string>;

    constructor() {
        this.colors = {};
        this.colors[0] = 'bg-accent text-on-app';
        this.colors[1] = 'bg-success-light text-on-app';
    }

    getWidthForItem(item: TransactionUsedPaymentModel): number {
        return item.amount / this.total * 100;
    }
}
