import { Injectable, OnDestroy, inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable, Subject, tap, map, BehaviorSubject, of } from 'rxjs';

import { UsersStore } from '../../app/services/stores/users.store';
import { LayoutSettingsModel } from '../../app/models/layout-settings.model';
import { DashboardConfigurationModel } from '../models/dashboard-configuration.model';
import { EditSidebarLayoutSettingsModel } from '../../components/sidebar-components/edit-sidebar/models/edit-sidebar-layout-settings.model';
import { TableSettingsModel } from '../models/table-settings.model';
import { CacheService } from './cache.service';
import { ListViewSettingsModel } from '../models/list-view-settings.model';

@Injectable({
    providedIn: 'root'
})
export class LayoutService implements OnDestroy {
    isMobile = false;
    isMobileSubject = new BehaviorSubject<boolean>(false);
    anyScrollSubject = new Subject<void>();

    private readonly cacheKey = 'layout';
    private readonly mobileQuery: MediaQueryList;
    private readonly mobileQueryListener: (query: MediaQueryListEvent) => void;
    private readonly cacheService = new CacheService();
    private readonly usersStore = inject(UsersStore);
    private readonly mediaMatcher = inject(MediaMatcher);

    private themeChanged = new Subject<boolean>();

    constructor() {
        this.mobileQuery = this.mediaMatcher.matchMedia('(max-width: 639px)');
        this.mobileQueryListener = queryEvent => {
            this.isMobile = queryEvent.matches;
            this.isMobileSubject.next(this.isMobile);
        };
        this.isMobile = this.mobileQuery.matches;

        this.mobileQuery.addEventListener('change', this.mobileQueryListener);
        this.isMobileSubject.next(this.isMobile);

        // `true` is needed so that any scroll event fires this event, not only scroll on main window
        window.addEventListener('scroll', this.onAnyScroll.bind(this), true);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
        window.removeEventListener('scroll', this.onAnyScroll.bind(this), true);
    }

    getThemeChangedObservable(): Observable<boolean> {
        return this.themeChanged.asObservable();
    }

    setDarkMode(isDarkMode: boolean): void {
        this.themeChanged.next(isDarkMode);
    }

    getLayoutSettings(): Observable<LayoutSettingsModel> {
        const value = this.cacheService.get<LayoutSettingsModel>(this.cacheKey);
        if (!!value) {
            return of(value);
        }
        return this.usersStore.getLayoutSettings().pipe(
            map(result => result.value ?? new LayoutSettingsModel()),
            tap(result => {
                this.cacheService.set(this.cacheKey, result);
            })
        );
    }

    getDashboardConfiguration(): Observable<DashboardConfigurationModel> {
        return this.usersStore.getDashboardSettings().pipe(
            map(result => result.value ?? new DashboardConfigurationModel())
        );
    }

    saveDashboardConfiguration(dashboardConfig: DashboardConfigurationModel): void {
        this.usersStore.getDashboardSettings().subscribe(r => {
            const settings = new DashboardConfigurationModel({ ...r.value, ...dashboardConfig });
            this.usersStore.saveDashboardSettings(settings).subscribe();
        });
    }

    saveEditSidebarLayout(key: string, editLayoutSetting: EditSidebarLayoutSettingsModel): void {
        this.getLayoutSettings().subscribe(result => {
            const settings = new LayoutSettingsModel(result);
            if (!settings.editSidebars) {
                settings.editSidebars = {};
            }
            settings.editSidebars[key] = editLayoutSetting;
            this.updateLayoutSettings(settings);
        });
    }

    saveIsLeftSidebarOpenDesktop(isOpen: boolean): void {
        this.getLayoutSettings().subscribe(result => {
            const settings = new LayoutSettingsModel(result);
            settings.isLeftSidebarOpenDesktop = isOpen;
            this.updateLayoutSettings(settings);
        });
    }

    getTableSettings(key: string): Observable<TableSettingsModel> {
        return this.usersStore.getTableSettings(key).pipe(
            map(result => result.value ?? new TableSettingsModel())
        );
    }

    saveTableSettings(key: string, tableSettings: TableSettingsModel): void {
        this.usersStore.getTableSettings(key).subscribe(r => {
            const settings = new TableSettingsModel({ ...r.value, ...tableSettings });
            this.usersStore.saveTableSettings(settings, key).subscribe();
        });
    }

    getListViewSettings(key: string): Observable<ListViewSettingsModel> {
        return this.usersStore.getListViewSettings(key).pipe(
            map(result => result.value ?? new ListViewSettingsModel())
        );
    }

    saveListViewSettings(key: string, listViewSettings: ListViewSettingsModel): void {
        this.usersStore.getListViewSettings(key).subscribe(r => {
            const settings = new ListViewSettingsModel({ ...r.value, ...listViewSettings });
            this.usersStore.saveListViewSettings(settings, key).subscribe();
        });
    }

    private updateLayoutSettings(settings: LayoutSettingsModel): void {
        this.usersStore.saveLayoutSettings(settings).subscribe();
        this.cacheService.clear(this.cacheKey);
    }

    private onAnyScroll(): void {
        this.anyScrollSubject.next();
    }
}
