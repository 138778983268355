import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '../user.service';
import { EmailVerificationDialogComponent } from '../../../components/dialogs/email-verification-dialog/email-verification-dialog.component';

export const checkEmailVerifiedGuard: CanActivateFn = () => {
    const userService = inject(UserService);
    const matDialog = inject(MatDialog);
    const loggedUser = userService.getUserInfo();

    if (!!loggedUser && !loggedUser.emailVerifiedDate) {
        matDialog.open(EmailVerificationDialogComponent, {
            width: '500px',
            maxWidth: '90vw',
            maxHeight: '90svh',
            disableClose: true
        });
    }

    return true;
};
