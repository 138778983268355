import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-description-detail-widget',
    templateUrl: './article-description.detail-widget.html',
    styleUrls: ['./article-description.detail-widget.scss']
})
export class ArticleDescriptionDetailWidget extends BaseDetailWidget<ArticleListModel, ArticleModel> {}
