import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../../components/abstractions/base-card.component';
import { ImportDefinitionListModel } from '../../../../../models/responses/import-definition-list.model';

@Component({
    selector: 'arc-import-definition-card',
    templateUrl: './import-definition-card.component.html',
    styleUrls: ['./import-definition-card.component.scss']
})
export class ImportDefinitionCardComponent extends BaseCardComponent<ImportDefinitionListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
