import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';

export class StaticTableConfigModel<T> {
    availableColumns!: BaseColumnModel[];
    defaultPageSize!: number;
    data: T[] = [];
    showCheckboxes = false;
    shouldStartSelected = false;

    constructor(init?: Partial<StaticTableConfigModel<T>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
