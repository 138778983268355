<div cdkDropList [cdkDropListData]="panels()" (cdkDropListDropped)="dropPanel($event)" class="flex flex-col w-[150px]">
    @for (panel of panels(); track panel.id; let i = $index) {
        <div cdkDrag class="flex items-center gap-2 py-2" [class.text-element-disabled]="activePanelId !== panel.id">
            <mat-icon class="shrink-0 grow-0" cdkDragHandle>drag_handle</mat-icon>
            <span class="overflow-hidden cursor-pointer text-nowrap overflow-ellipsis" (click)="activePanelIdChange.emit(panel.id)">
                {{ panel.title }}
            </span>
            <div class="grow"></div>
            <arc-icon matRipple [matRippleDisabled]="activePanelId !== panel.id" icon="edit"
                class="p-1 shrink-0 rounded-full cursor-pointer" (click)="editPanel(panel.id)">
            </arc-icon>
            @if (!!i) {
                <arc-icon matRipple [matRippleDisabled]="activePanelId !== panel.id"
                    icon="close" class="p-1 shrink-0 rounded-full cursor-pointer"
                    (click)="removePanel(panel.id)">
                </arc-icon>
            }
        </div>
    }

    @if (isLoading()) {
        <div class="flex items-center gap-2 py-2">
            <mat-spinner class="shrink-0 grow-0" [diameter]="24"></mat-spinner>
            <span class="overflow-hidden text-nowrap overflow-ellipsis">
                {{ 'PosLayouts.Edit.PosLayoutGroups.New' | transloco }}
            </span>
        </div>
    } @else {
        <div class="flex items-center gap-2 py-2 cursor-pointer text-element-disabled" (click)="addPanel()">
            <mat-icon class="shrink-0 grow-0">add</mat-icon>
            <span class="overflow-hidden text-nowrap overflow-ellipsis">
                {{ 'PosLayouts.Edit.PosLayoutGroups.New' | transloco }}
            </span>
        </div>
    }
</div>
