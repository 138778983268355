import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { InventoryModel } from '../../../../../models/inventory.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { InventoryUpdateModel } from '../../../../../models/requests/inventory-update.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { InventoryStatusEnum, InventoryStatusEnumExtensions } from '../../../../../models/enums/inventory-status.enum';

@Component({
    selector: 'arc-inventory-edit-base-data',
    templateUrl: './inventory-edit-base-data.component.html',
    styleUrl: './inventory-edit-base-data.component.scss'
})
export class InventoryEditBaseDataComponent extends BaseEditSidebarItemComponent<InventoryModel, InventoryUpdateModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    statusBgColorClass = '';

    override formGroup = new FormGroup({
        title: new ArcFormControl<string>(undefined, Validators.required)
    });

    override onItemSet(): void {
        this.statusBgColorClass = InventoryStatusEnumExtensions.getColor(this.item.status);
        if (this.item.status === InventoryStatusEnum.Finished) {
            this.formGroup.controls.title.disable();
        }
        this.formGroup.patchValue(this.item);
    }
    override prepareSaveModel(): Partial<InventoryUpdateModel> {
        return { title: this.formGroup.value.title };
    }
}
