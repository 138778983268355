import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ImportDefinitionModel } from '../../models/responses/import-definition.model';
import { ImportDefinitionListModel } from '../../models/responses/import-definition-list.model';

@Injectable({
    providedIn: 'root'
})
export class ImportDefinitionsStore extends BaseCrudStore<ImportDefinitionModel, ImportDefinitionListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'import-definitions' }));
    }
}
