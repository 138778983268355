import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseColumnComponent } from '../base-column.component';
import { TreeAutocompleteConfigsService } from '../../../../app/services/tree-autocomplete-configs.service';
import { TreeAutocompleteColumnModel } from '../../models/column-types/tree-autocomplete-column.model';
import { TreeDataSelectionConfig } from '../../../form/tree-autocomplete/models/tree-data-selection.config';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { ArticleGroupListModel } from '../../../../app/models/responses/article-group-list.model';

@Component({
    selector: 'arc-tree-autocomplete-column',
    templateUrl: './tree-autocomplete-column.component.html',
    styleUrl: './tree-autocomplete-column.component.scss'
})
export class TreeAutocompleteColumnComponent<T extends Identifyable<TId>, TId = number>
    extends BaseColumnComponent<T, TId>
    implements OnInit {
    articleGroupConfig = new TreeDataSelectionConfig<ArticleGroupListModel>({});
    readonlyControl = new FormControl();

    private readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.articleGroupConfig = new TreeDataSelectionConfig<ArticleGroupListModel>({
            ...this.treeAutocompleteConfigs.articleGroupConfig,
            allowsInlineCreation: false,
            allowSelectionAtLevels: this.castedCellModel.allowSelectionAtLevels
        });

        this.readonlyControl.disable();
        this.readonlyControl.setValue(this.getItemValue(this.columnModel.propertyName));
    }

    get castedCellModel(): TreeAutocompleteColumnModel {
        return this.columnModel as TreeAutocompleteColumnModel;
    }
}
