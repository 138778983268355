import { Component } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { SubscriptionTypeListModel } from '../../../../models/responses/subscription-type-list.model';

@Component({
    selector: 'arc-subscription-type-card',
    templateUrl: './subscription-type-card.component.html',
    styleUrls: ['./subscription-type-card.component.scss']
})
export class SubscriptionTypeCardComponent extends BaseCardComponent<SubscriptionTypeListModel> {}
