import { Component, Input, inject } from '@angular/core';

import { CustomerModel } from '../../../../app/models/customer.model';
import { DateService } from '../../../../core/services/date.service';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';

@Component({
    selector: 'arc-customer-data',
    templateUrl: './customer-data.component.html',
    styleUrls: ['./customer-data.component.scss']
})
export class CustomerDataComponent {
    @Input() model?: CustomerModel;

    customerProfilePermission: true | AuthPermissionModel = true;
    readonly dateService = inject(DateService);
    readonly permissionService = inject(PermissionService);

    constructor() {
        this.customerProfilePermission = this.permissionService.hasPermission(PermissionsEnum.CustomerProfiles);
    }

    /**
     * removes the protocol from the url like 'http://' or 'https://'
     */
    get websiteUrl(): string {
        const url = this.model?.contactUrl ?? '';
        return url.slice(url.indexOf('//') + 1);
    }
}
