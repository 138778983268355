import { Component, HostBinding, computed, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';

import { FeatureModel } from '../../../../app/models/feature.model';
import { FeatureStatusEnum } from '../../../../app/models/enums/feature-status.enum';
import { VoteTypeEnum } from '../../../../app/models/enums/vote-type.enum';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { FeatureDetailsDialogDataModel } from './models/feature-details-dialog-data.model';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { FeatureService } from '../../../../core/services/feature.service';
import { ToasterService } from '../../../../core/services/toaster.service';
import { BaseComponent } from '../../../abstractions/base.component';

@Component({
    selector: 'arc-feature-details-dialog',
    templateUrl: './feature-details-dialog.component.html',
    styleUrls: ['./feature-details-dialog.component.scss']
})
export class FeatureDetailsDialogComponent extends BaseComponent {
    @HostBinding('class') readonly classes = 'grow flex flex-col overflow-y-auto';

    readonly FeatureStatusEnum = FeatureStatusEnum;
    readonly VoteTypeEnum = VoteTypeEnum;
    readonly data: FeatureDetailsDialogDataModel = inject(MAT_DIALOG_DATA);

    feature = signal<OptionalType<FeatureModel>>(undefined);
    showCommentField = signal(false);
    isNew = computed(() => {
        const feature = this.feature();
        const sevenDaysAgo = new Date().getTime() - 6.048e8;
        return !!feature && feature.createdDate.getTime() > sevenDaysAgo;
    });

    formGroup = new FormGroup({
        vote: new ArcFormControl<OptionalType<VoteTypeEnum>>(undefined, Validators.required),
        comment: new ArcFormControl<OptionalType<string>>(undefined)
    });

    private readonly matDialogRef = inject(MatDialogRef);
    private readonly featureService = inject(FeatureService);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        super();

        const voteChangedSub = this.formGroup.controls.vote.valueChanges.subscribe(vote => {
            if (vote === VoteTypeEnum.Down) {
                this.formGroup.controls.comment.addValidators(Validators.required);
                this.showCommentField.set(true);
            } else {
                this.formGroup.controls.comment.removeValidators(Validators.required);
                this.formGroup.controls.comment.updateValueAndValidity();
                this.showCommentField.set(false);
            }
        });
        this.addSubscriptions(voteChangedSub);

        this.featureService.getFeatureById(this.data.featureId.toString()).subscribe(feature => {
            if (!feature) {
                this.matDialogRef.close();
                return;
            }

            this.feature.set(feature);

            const vote = this.getVoteTypeEnumValue(feature.vote) ?? this.data.vote;
            this.formGroup.patchValue({ vote });
        });
    }

    voteAndClose(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.invalid) {
            return;
        }

        const vote = this.formGroup.value.vote!;
        const comment = vote === VoteTypeEnum.Down ? (this.formGroup.value as any).comment : undefined;
        this.featureService.voteForFeature(this.data.featureId.toString(), vote, comment).subscribe(() => {
            this.toasterService.showSuccess('Widgets.Features.Voting.ThankYou');
            this.matDialogRef.close(true);
        });
    }

    private getVoteTypeEnumValue(vote?: number): OptionalType<VoteTypeEnum> {
        switch (vote) {
            case -1:
                return VoteTypeEnum.Down;
            case 0:
                return VoteTypeEnum.Neutral;
            case 1:
                return VoteTypeEnum.Up;
            default:
                return undefined;
        }
    }
}
