@if(message) {
    <div class="flex flex-col" [ngClass]="message.isMyResponse ? 'items-end ml-12' : 'items-start mr-12'">
        @if(message.isMyResponse) {
        <span class="mr-1 float-right text-xs text-gray-400">{{ message.createdDate | arcDate: 'short' }}</span>
        } @else {
        <span class="ml-1 float-right text-xs text-gray-400">{{message.createdByEmail}} {{'Tickets.View.On' | transloco}} {{ message.createdDate
            | arcDate: 'short' }}</span>
        }
        <div class="p-4 rounded-lg text-black" [style.background-color]="message.backgroundColorHex">
            <div markdown>{{ message.text }}</div>
        </div>
        @for(attachment of message.attachments; track attachment.id){
            <div class="p-4 rounded-lg my-4 text-on-app" [style.background-color]="message.backgroundColorHex">
                <div class="flex items-center gap-2">
                    <mat-icon class="mr-2">insert_drive_file</mat-icon>
                    <span>{{attachment.fileName}}</span>
                    <button class="ml-auto" mat-icon-button (click)="downloadFile(attachment)">
                        <mat-icon>download</mat-icon>
                    </button>
                </div>
            </div>
        }
    </div>
}