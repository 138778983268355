<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-quick-search
        [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig"
        [formControl]="formGroup.controls.invoiceLateFeeArticleId1"
        class="col-span-4"
        label="Settings.Edit.Invoices.Reminders.LateFeeArticleId1"></arc-quick-search>
    <arc-quick-search
        [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig"
        [formControl]="formGroup.controls.invoiceLateFeeArticleId2"
        class="col-span-4"
        label="Settings.Edit.Invoices.Reminders.LateFeeArticleId2"></arc-quick-search>
</form>
