import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';

@Component({
    selector: 'arc-customer-base-data-detail-widget',
    templateUrl: './customer-base-data.detail-widget.html',
    styleUrls: ['./customer-base-data.detail-widget.scss']
})
export class CustomerBaseDataDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> {}
