import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { VoucherListModel } from '../../../../../models/responses/voucher-list.model';
import { VoucherModel } from '../../../../../models/voucher.model';

@Component({
    selector: 'arc-voucher-customer',
    templateUrl: './voucher-customer.detail-widget.html',
    styleUrls: ['./voucher-customer.detail-widget.scss']
})
export class VoucherCustomerDetailWidget extends BaseDetailWidget<VoucherListModel, VoucherModel> {}
