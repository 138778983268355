import { Component } from '@angular/core';

@Component({
    selector: 'arc-poscustomer-screen-layouts',
    templateUrl: './poscustomer-screen-layouts.component.html',
    styleUrls: ['./poscustomer-screen-layouts.component.scss']
})
export class PosCustomerScreenLayoutsComponent {

}
