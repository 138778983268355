<div class="flex flex-col gap-4">
    <arc-rich-text-editor-form class="min-h-[100px]" [formControl]="formGroup.controls[descriptionKey] | formControl" label="General.Description"
        [placeholder]="'Components.RichTextEditor.LabelEnterTextHere' | transloco:  {label: ('General.Description' | transloco)}">
    </arc-rich-text-editor-form>
    <arc-rich-text-editor-form [formControl]="formGroup.controls[warrantyTextKey] | formControl" label="Articles.Edit.WarrantyText"
        [placeholder]="'Components.RichTextEditor.LabelEnterTextHere' | transloco:  {label: ('Articles.Edit.WarrantyText' | transloco)}">
    </arc-rich-text-editor-form>
    <arc-textarea [formControl]="formGroup.controls[displayWarningKey] | formControl" label="Articles.Edit.WarningMessage">
    </arc-textarea>
</div>
