import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TransactionTypeListModel } from '../../../models/responses/transaction-type-list.model';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { TransactionTypeModel } from '../../../models/transaction-type.model';
import { TransactionTypesStore } from '../../../services/stores/transaction-types.store';
import { TransactionTypeCardComponent } from './transaction-type-card/transaction-type-card.component';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import {
    TransactionTypeBasicDataComponent
} from './transaction-types-edit/transaction-type-basic-data/transaction-type-basic-data.component';
import {
    TransactionTypePaymentPurposesComponent
} from './transaction-types-edit/transaction-type-payment-purposes/transaction-type-payment-purposes.component';
import {
    TransactionTypePaymentTypeCreditComponent
} from './transaction-types-edit/transaction-type-payment-type-credit/transaction-type-payment-type-credit.component';
import {
    TransactionTypePaymentTypeDebitComponent
} from './transaction-types-edit/transaction-type-payment-type-debit/transaction-type-payment-type-debit.component';
import { TransactionTypeEditModel } from '../../../models/requests/transaction-type-edit.model';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-transaction-types',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./transaction-types.component.scss']
})
export class TransactionTypesComponent extends PageListViewComponent<TransactionTypeListModel> {
    cardConfig: CardListViewConfigModel<TransactionTypeModel, TransactionTypeListModel, TransactionTypeEditModel>;

    private readonly transactionTypesStore = inject(TransactionTypesStore);

    constructor() {
        super('TransactionTypes');

        this.config = new CardListViewConfigModel<TransactionTypeModel, TransactionTypeListModel, TransactionTypeEditModel>({
            entityName: 'TransactionTypes',
            store: this.transactionTypesStore,
            cardComponent: TransactionTypeCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            defaultSort: new ColumnSortModel({ column: 'sort' }),
            cardTitle: currentItem => currentItem.title,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'TransactionTypes.Edit.BaseData.BaseData', component: TransactionTypeBasicDataComponent },
                    {
                        titleKey: 'TransactionTypes.Edit.PaymentPurposes.PaymentPurposes',
                        component: TransactionTypePaymentPurposesComponent,
                        requiredPermission: PermissionsEnum.TransactionPurposes
                    },
                    {
                        titleKey: 'TransactionTypes.Edit.PaymentTypeCredit.PaymentTypeCredit',
                        component: TransactionTypePaymentTypeCreditComponent,
                        shouldHideSignalName: 'shouldHideTransactionTypePaymentTypeComponent'
                    },
                    {
                        titleKey: 'TransactionTypes.Edit.PaymentTypeDebit.PaymentTypeDebit',
                        component: TransactionTypePaymentTypeDebitComponent,
                        shouldHideSignalName: 'shouldHideTransactionTypePaymentTypeComponent'
                    }
                ]
            },
            mainButton: new MainCardButtonModel({
                text: 'TransactionTypes.List.ViewTransactions',
                clickFn: data => this._router.navigate(['transactions'], { queryParams: { TransactionTypeId: [data.id] } })
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
        this.cardConfig = this.config;
    }
}
