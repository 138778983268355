import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { StoreConfig } from '../../../core/models/store.config';
import { StatisticParametersRequestModel } from '../../models/requests/statistic-parameters-request.model';
import { StatisticalValueResponseModel } from '../../models/responses/statistical-value-response.model';

@Injectable()
export class StatisticsStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'statistics' }));
    }

    turnOverByGroup(statisticParameters: StatisticParametersRequestModel): Observable<ApiResponseModel<StatisticalValueResponseModel[]>> {
        const url = this.getUrl('turnoverbygroup');

        return this._requestService.makePost<ApiResponseModel<StatisticalValueResponseModel[]>>(url, statisticParameters);
    }

    turnOverByPos(statisticParameters: StatisticParametersRequestModel): Observable<ApiResponseModel<StatisticalValueResponseModel[]>> {
        const url = this.getUrl('turnoverbypos');

        return this._requestService.makePost<ApiResponseModel<StatisticalValueResponseModel[]>>(url, statisticParameters);
    }
}
