<div class="flex flex-col gap-4">
    <div class="flex items-baseline gap-4">
        <span>{{'ExtAccountingExport.EditFields.Type' | transloco}}</span>
        <mat-button-toggle-group [ngModel]="isPayment()" (change)="changeIsPayment($event)" class="grow" [value]="false"
            [disabled]="!isCreate">
            <mat-button-toggle [value]="false">
                <span>{{'ExtAccountingExport.EditFields.BookingArticles' | transloco}}</span>
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
                <span>{{'ExtAccountingExport.EditFields.Payments' | transloco}}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    @if(isPayment()){
        <arc-general-data-select [formControl]="formGroup.controls.paymentTypeId" label='ExtAccountingExport.EditFields.PaymentType'
            [generalDataType]="GeneralDataTypeEnum.PaymentTypes" [placeholder]="'General.All' | transloco"
            [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
        </arc-general-data-select>
        <div class="flex gap-4 items-baseline">
            <arc-general-data-select class="grow" [formControl]="formGroup.controls.cardType" label='ExtAccountingExport.EditFields.CardType'
                [generalDataType]="GeneralDataTypeEnum.CardTypes" [placeholder]="'General.All' | transloco"
                [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
            </arc-general-data-select>
            <span>{{'ExtAccountingExport.EditFields.Without' | transloco}}</span>
            <arc-general-data-select class="grow" [formControl]="formGroup.controls.exclCardType"
                label='ExtAccountingExport.EditFields.CardType' [generalDataType]="GeneralDataTypeEnum.CardTypes" placeholder="-"
                [hasFloatingLabel]="true">
            </arc-general-data-select>
        </div>
    }
    @else {
        <arc-general-data-select [formControl]="formGroup.controls.transactionTypeId" label='ExtAccountingExport.EditFields.TransactionType'
            [generalDataType]="GeneralDataTypeEnum.TransactionTypes" [placeholder]="'General.All' | transloco"
            [emptyOptionLabel]="'General.All' | transloco" [hasFloatingLabel]="true">
        </arc-general-data-select>
        <arc-select [control]="formGroup.controls.articleAmountField" labelKey="ExtAccountingExport.EditFields.Value"
            [options]="articleAmountOptions"></arc-select>
        }
</div>
