import { Injectable, inject } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';

import { CacheService } from './cache.service';
import { FavouriteListModel } from '../../app/models/responses/favourite-list.model';
import { FavouritesStore } from '../../app/services/stores/favourites.store';
import { Utils } from '../utils/tools/utils.tools';

@Injectable({
    providedIn: 'root'
})
export class FavouritesService {
    cacheKey = 'favourites';
    private readonly favouritesStore = inject(FavouritesStore);
    private readonly cacheService = new CacheService();

    getAll(): Observable<FavouriteListModel[]> {
        const value = this.cacheService.get<FavouriteListModel[]>(this.cacheKey);

        if (!!value) {
            return of(value);
        }

        return this.favouritesStore.getAll().pipe(
            map(data => data.value ?? []),
            tap(data => this.cacheService.set(this.cacheKey, data))
        );
    }

    add(favourite: FavouriteListModel): Observable<number> {
        const favourites = this.cacheService.get<FavouriteListModel[]>(this.cacheKey) ?? [];
        const existingFavourite = favourites.find(f => f.path === favourite.path);

        if (existingFavourite) {
            return of(existingFavourite.id);
        }

        return this.favouritesStore.add({ title: favourite.title, id: favourite.id, path: favourite.path }).pipe(
            map(result => result.value ?? 0),
            tap(id => {
                if (id ?? 0 > 0) {
                    const newFavourite = Utils.deepCopy(favourite);
                    newFavourite.id = +id;
                    favourites.push(newFavourite);
                    this.cacheService.set(this.cacheKey, favourites);
                }
            })
        );
    }

    remove(id: number): Observable<any> {
        return this.favouritesStore.remove(+id).pipe(
            tap(result => {
                if (result.value ?? 0 > 0) {
                    let favourites = this.cacheService.get<FavouriteListModel[]>(this.cacheKey) ?? [];
                    favourites = favourites.filter(f => f.id !== id);
                    this.cacheService.set(this.cacheKey, favourites);
                }
            })
        );
    }
}
