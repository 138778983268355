import { Component, OnInit, inject } from '@angular/core';

import { SidebarService } from '../../../../core/services/sidebar.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { SubMenuItemModel } from '../../../../core/models/sub-menu-item.model';
import { FavouritesService } from '../../../../core/services/favourites.service';
import { AuthenticatedUserModel } from '../../../../app/models/authenticated-user.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { UserService } from '../../../../core/services/user.service';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { ModuleIdentificationsEnum } from '../../../../app/models/enums/module-identifications.enum';

@Component({
    selector: 'arc-left-sidebar',
    templateUrl: './left-sidebar.component.html',
    styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent extends BaseComponent implements OnInit {
    currentSubMenu: SubMenuItemModel[] = [];
    isEmptyFavouritesList = false;
    homeDirectory = '/home';
    user?: AuthenticatedUserModel;
    hasSysAdminSection = false;
    quickFeedbacks: DictionaryType<string> = {
        reports: '35',
        tags: '53'
    };
    hasFavoritePermission: true | AuthPermissionModel = true;
    hasFashionModuleLicensed = true;

    readonly sidebarWidthPx = 230;
    readonly sidebarService = inject(SidebarService);
    readonly layoutService = inject(LayoutService);

    private readonly favouritesService = inject(FavouritesService);
    private readonly permissionService = inject(PermissionService);
    private readonly routeService = inject(RouteService);
    private readonly userService = inject(UserService);

    constructor() {
        super();
        this.hasFavoritePermission = this.permissionService.hasPermission(PermissionsEnum.Favourites);
        this.hasFashionModuleLicensed = this.permissionService.hasModuleLicensed(ModuleIdentificationsEnum.Fashion);
        const isMobileSubscription = this.layoutService.isMobileSubject.subscribe(isMobile => {
            if (isMobile) {
                this.sidebarService.closeLeft();
            } else {
                this.applyLeftSidebarDesktopSetting();
            }
        });

        if (!this.layoutService.isMobile) {
            this.applyLeftSidebarDesktopSetting();
        }

        // regenerate submenus on route change
        const subMenuItemsSub = this.routeService.subMenuItemsSub.subscribe(response => {
            this.currentSubMenu = response;
            if (this.hasFavoritePermission === true) {
                this.favouritesService.getAll().subscribe(result => {
                    this.currentSubMenu.forEach(menuItem => {
                        menuItem.favouriteId = result.find(f => f.path === menuItem.path)?.id;
                    });
                });
            }
            this.setupSubMenu();
        });

        this.addSubscriptions(isMobileSubscription, subMenuItemsSub);
    }

    async ngOnInit(): Promise<void> {
        this.user = await this.userService.getUserInfoAsync();
        this.hasSysAdminSection = !!this.user?.databaseName || !!this.user?.databaseVersion || !!this.user?.databaseName;
    }

    favouriteChanged(): void {
        if (this.routeService.isActiveRoute(this.homeDirectory)) {
            this.currentSubMenu = this.currentSubMenu.filter(el => el.favouriteId !== undefined);
        }
        this.setupSubMenu();
    }

    handleLeftBackdropClick(): void {
        this.sidebarService.closeLeft();
    }

    private setupSubMenu(): void {
        if (this.currentSubMenu.length === 0) {
            if (this.routeService.isActiveRoute(this.homeDirectory)) {
                this.isEmptyFavouritesList = true;
            } else {
                this.sidebarService.closeLeft();
                this.sidebarService.isLeftDisabled = true;
            }
        } else {

            const variant = this.currentSubMenu.findIndex(s => !!s.path && s.path === 'variations');
            if (variant > -1 && !this.hasFashionModuleLicensed) {
                this.currentSubMenu.splice(variant,1);
                this.currentSubMenu = this.currentSubMenu;
            }
            this.sidebarService.isLeftDisabled = false;
            if (!this.layoutService.isMobile) {
                this.applyLeftSidebarDesktopSetting();
            } else {
                this.sidebarService.closeLeft();
            }
        }
    }

    private applyLeftSidebarDesktopSetting(): void {
        this.layoutService.getLayoutSettings().subscribe(settings => {
            if (settings.isLeftSidebarOpenDesktop) {
                this.sidebarService.openLeft();
            } else {
                this.sidebarService.closeLeft();
            }
        });
    }
}
