import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { map, Observable, of } from 'rxjs';

import { ArticleVariantEditModel } from '../../../../../models/article-variant-edit.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { EntityTagModel } from '../../../../../models/entity-tag.model';
import { ArticleImageModel } from '../../../../../models/responses/article-image.model';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { TagsStore } from '../../../../../services/stores/tags.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { ArticleModel } from '../../../../../models/article.model';
import { MainArticlesStore } from '../../../../../services/stores/main-articles.store';

@Component({
    selector: 'arc-article-variants-edit-base-data',
    templateUrl: './article-variants-edit-base-data.component.html',
    styleUrl: './article-variants-edit-base-data.component.scss'
})
export class ArticleVariantsEditBaseDataComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleVariantEditModel> {
    override formGroup = new FormGroup({
        ean: new ArcFormControl('', Validators.required),
        orderNumber: new ArcFormControl<OptionalType<string>>(undefined),
        articleImages: new ArcFormControl<ArticleImageModel[]>([]),
        price: new ArcFormControl(0, Validators.required),
        buyingPrice: new ArcFormControl(0, Validators.required),
        tags: new ArcFormControl<EntityTagModel[]>([]),
        seasonId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    PermissionTypeEnum = PermissionTypeEnum;
    PermissionsEnum = PermissionsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    private readonly tagsStore = inject(TagsStore);
    private readonly mainArticlesStore = inject(MainArticlesStore);

    override onItemSet(): void {
        this.formGroup.patchValue({
            ...this.item,
            ean: this.item.identifications[0]?.identification,
            orderNumber: this.item.articleSuppliers.find(s => s.isMainSupplier)?.orderNumber
        });
    }

    override prepareSaveModel(): Partial<ArticleVariantEditModel> {
        const value = this.formGroup.value;
        const identifications = this.item.identifications;

        if (identifications.length > 0) {
            identifications[0].identification = value.ean || '';
        } else {
            identifications.push({
                id: 0,
                identification: value.ean || ''
            });
        }

        return {
            mainArticleId: this.item.mainArticleId,
            colorId: this.item.colorId,
            sizeId: this.item.sizeId,
            seasonId: value.seasonId,
            identifications: identifications,
            orderNumber: value.orderNumber,
            buyingPrice: value.buyingPrice,
            price: value.price,
            tags: value.tags,
            articleImages: value.articleImages
        };
    }

    tagDisplayFn(tag: EntityTagModel): string {
        return tag.title ?? '';
    }

    getTagKey(tag: EntityTagModel): number {
        return tag.id;
    }

    tagsSearch(searchText: string): Observable<EntityTagModel[]> {
        return this.tagsStore.search(new SearchRequestModel({ searchText })).pipe(map(response => response.value?.records ?? []));
    }

    generateNewIdentificationAction(): Observable<any> {
        return of(this.generateNewIdentification());
    }

    private generateNewIdentification(): void {
        this.mainArticlesStore.generateNewArticlesIdentifications().subscribe(r => {
            if (!!r.value && r.value.length > 0) {
                this.formGroup.patchValue({ ean: r.value[0] });
            }
        });
    }
}
