import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'arc-customer-description-dialog',
    templateUrl: './customer-description-dialog.component.html',
    styleUrls: ['./customer-description-dialog.component.scss']
})
export class CustomerDescriptionDialogComponent {
    readonly data: string = inject(MAT_DIALOG_DATA);
}
