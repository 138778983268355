import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { TransactionArticleModel } from '../../models/transaction-article.model';
import { TransactionArticleListModel } from '../../models/responses/transaction-article-list.model';
import { StoreConfig } from '../../../core/models/store.config';

@Injectable({
    providedIn: 'root'
})
export class TransactionArticlesStore extends BaseCrudStore<TransactionArticleModel, TransactionArticleListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'transaction-articles' }));
    }
}
