import { Component, OnInit } from '@angular/core';

import { InventoryArticleModel } from '../../../../../../models/inventory-article.model';
import { BaseCustomColumnComponent } from '../../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-inventory-article-status-column',
    templateUrl: './inventory-article-status-column.component.html',
    styleUrl: './inventory-article-status-column.component.scss'
})
export class InventoryArticleStatusColumnComponent extends BaseCustomColumnComponent<InventoryArticleModel> implements OnInit {
    readonly colorMap = {
        correct: 'bg-success',
        incorrect: 'bg-warn',
        notCounted: 'bg-info',
        soldDuringInventory: 'bg-error'
    };

    colorClass = '';

    ngOnInit(): void {
        this.formGroup.valueChanges.subscribe(value => this.setColorClass(value));
        this.setColorClass(this.formGroup.value);
    }

    private setColorClass(value: Partial<InventoryArticleModel>): void {
        this.colorClass = value.wasSoldDuringInventory
            ? this.colorMap.soldDuringInventory
            : !value.actualStock
                ? this.colorMap.notCounted
                : // eslint-disable-next-line eqeqeq
                value.actualStock === value.specifiedStock
                    ? this.colorMap.correct
                    : this.colorMap.incorrect;
    }
}
