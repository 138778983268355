import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const supplierSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'displayName',
        propertyName2: 'personNumber',
        columnTitleKey: 'Suppliers.List.SupplierName'
    }),
    new StringColumnModel({
        propertyName: 'zip',
        columnTitleKey: 'Suppliers.List.Zip'
    }),
    new StringColumnModel({
        propertyName: 'city',
        columnTitleKey: 'Suppliers.List.City'
    })
];
