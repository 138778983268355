import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ArticleSupplierModel } from '../../models/article-supplier.model';
import { ArticleSupplierListModel } from '../../models/responses/article-supplier-list.model';
import { ArticleSupplierEditModel } from '../../models/requests/article-supplier-edit.model';

@Injectable({
    providedIn: 'root'
})
export class ArticleSuppliersStore extends BaseCrudStore<ArticleSupplierModel, ArticleSupplierListModel, ArticleSupplierEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'article-suppliers' }));
    }

    getByOrderAndArticleId(orderId: number, articleId: number): Observable<ApiResponseModel<ArticleSupplierModel>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleSupplierModel>>(this.getUrl(`order/${orderId}/article/${articleId}`));
    }
}
