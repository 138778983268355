import { Component, HostBinding } from '@angular/core';

import { ArticleListModel } from '../../../../models/responses/article-list.model';
import { BaseCustomColumnComponent } from '../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-article-on-sale-column',
    templateUrl: './article-on-sale-column.component.html',
    styleUrl: './article-on-sale-column.component.scss'
})
export class ArticleOnSaleColumnComponent extends BaseCustomColumnComponent<ArticleListModel> {
    @HostBinding('class') classList = 'w-full flex justify-center';
}
