import { RouteModel } from '../../core/models/route.model';
import { ArticleStocksComponent } from '../views/stores/article-stocks/article-stocks.component';
import { OrdersComponent } from '../views/stores/orders/orders.component';
import { SuppliersComponent } from '../views/stores/suppliers/suppliers.component';
import { WarehouseTransfersComponent } from '../views/stores/warehouse-transfers/warehouse-transfers.component';
import { OrderArticlesComponent } from '../views/stores/order-articles/order-articles.component';
import { InventoriesComponent } from '../views/stores/inventories/inventories.component';
import { PermissionsEnum } from '../models/enums/permissions.enum';
import { InventoryStatusEnum } from '../models/enums/inventory-status.enum';
import { InventoryArticlesComponent } from '../views/stores/inventory-articles/inventory-articles.component';

export const storesRoutes: RouteModel[] = [
    {
        path: 'article-stocks',
        component: ArticleStocksComponent,
        data: { subMenu: 'storage', subMenuIndex: 0, title: 'Storage.ArticleStocks', requiredPermission: PermissionsEnum.ArticleStocks }
    },
    {
        path: 'stock-transfers',
        component: WarehouseTransfersComponent,
        data: {
            subMenu: 'storage',
            subMenuIndex: 2,
            title: 'Storage.StockTransfer',
            requiredPermission: PermissionsEnum.WarehouseRequests,
            startingQueryParams: { ShowCompleted: 'false' }
        }
    },
    {
        path: 'orders',
        component: OrdersComponent,
        data: {
            subMenu: 'storage',
            subMenuIndex: 3,
            title: 'Storage.Orders',
            requiredPermission: PermissionsEnum.Orders,
            startingQueryParams: { ShowCompleted: 'false' }
        }
    },
    {
        path: 'order-articles',
        component: OrderArticlesComponent,
        data: {
            subMenu: 'storage',
            subMenuIndex: 4,
            title: 'Storage.OrderArticles',
            requiredPermission: PermissionsEnum.Orders,
            startingQueryParams: { ShowCompleted: 'false' }
        }
    },
    {
        path: 'suppliers',
        component: SuppliersComponent,
        data: { subMenu: 'storage', subMenuIndex: 5, title: 'Storage.Suppliers', requiredPermission: PermissionsEnum.Suppliers }
    },
    {
        path: 'inventories',
        component: InventoriesComponent,
        data: {
            subMenu: 'storage',
            subMenuIndex: 6,
            title: 'Storage.Inventories',
            requiredPermission: PermissionsEnum.Inventories,
            startingQueryParams: { Status: InventoryStatusEnum.Open }
        }
    },
    {
        path: 'inventory-articles',
        component: InventoryArticlesComponent,
        data: {
            subMenu: 'storage',
            subMenuIndex: 7,
            title: 'Storage.InventoryArticles',
            requiredPermission: PermissionsEnum.Inventories,
            startingQueryParams: { InventoryStatus: InventoryStatusEnum.Open }
        }
    }
];
