<div class='max-w-[360px] flex flex-col gap-12'>
    <div>
        <h1 class='text-3xl mb-4 break-words'>
            {{'ResetPassword.Title' | transloco}}
        </h1>
    </div>
    <mat-card-content class="!flex flex-col gap-4">
        <arc-input [control]="formGroup.controls['newPassword']" type="password" labelKey="ResetPassword.NewPassword"
            placeholder="********">
        </arc-input>
        <arc-input [control]="formGroup.controls['confirmPassword']" type="password" labelKey="ResetPassword.ConfirmPassword"
            placeholder="********">
        </arc-input>
    </mat-card-content>
    <mat-card-actions>
        <button mat-flat-button color="accent" [disabled]="isResettingPassword || isCheckingToken" (click)="resetPassword()">
            {{ 'ResetPassword.ResetPassword' | transloco }}
        </button>
    </mat-card-actions>
</div>
