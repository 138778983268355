import { Component, HostBinding, Input, inject, ChangeDetectorRef, OnInit } from '@angular/core';

import { ColumnFilterModel } from '../../../../core/models/column-filter.model';
import { FilterItemTypeEnum } from '../../../../core/models/enums/filter-item-type.enum';
import { OperandTypeEnum } from '../../../../core/models/enums/operand-type.enum';
import { FilterItemModel } from '../../../../core/models/filter-item.model';
import { FilterOperator, getFilterOperator, getFilterOperators } from '../filter-operators';
import { ComparisonOperatorsEnum } from '../../../../core/models/enums/comparison-operators.enum';
import { FilterService } from '../../../../core/services/filter.service';
import { SelectOptionModel } from '../../../../core/models/select-option.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { TreeAutocompleteConfigsService } from '../../../../app/services/tree-autocomplete-configs.service';
import { KeyValueModel } from '../../../../core/models/key-value.model';
import { Tools } from '../../../../core/utils/tools';

@Component({
    selector: 'arc-filter-part',
    templateUrl: './filter-part.component.html',
    styleUrls: ['./filter-part.component.scss']
})
export class FilterPartComponent implements OnInit {
    @HostBinding('class') classes = 'flex gap-2 flex-wrap';

    @Input() index = 0;
    @Input() filterProperties: FilterItemModel[] = [];

    OperandTypeEnum = OperandTypeEnum;
    FilterItemTypeEnum = FilterItemTypeEnum;
    ComparisonOperatorsEnum = ComparisonOperatorsEnum;
    filter = new ColumnFilterModel();
    currentFilterProperty?: FilterItemModel;
    currentFilterComparisonOperators: FilterOperator[] = [];
    allowedValues?: KeyValueModel[] = [];
    currentComparisonOperator?: FilterOperator;
    dateFormatPlaceholder = '';

    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    readonly allowedTimeValues: SelectOptionModel<Date>[] = Tools.Time.generateSelectList();

    private readonly filterService = inject(FilterService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        this.filter = this.filterService.getFilter(this.index);
        this.handleColumnChanged(false);

        this.dateFormatPlaceholder = this.translationService.current.dateFormat;
    }

    handleColumnChanged(shouldPerformReset = true): void {
        this.currentFilterProperty = this.filterProperties.find(p => p.columnName === this.filter.column);

        if (!!this.currentFilterProperty) {
            this.filter.dataType = this.currentFilterProperty?.type;
            const filterComparisonOperators = getFilterOperators(this.currentFilterProperty);
            this.currentFilterComparisonOperators = filterComparisonOperators.filter(
                o => !o.shouldShowPredicate || o.shouldShowPredicate(this.currentFilterProperty!)
            );
        } else {
            this.currentFilterComparisonOperators = [];
        }

        if (shouldPerformReset) {
            // set default operator
            const defaultOperator = this.currentFilterComparisonOperators.find(o => o.isDefault);
            this.filter.comparisonOperator = defaultOperator?.operator ?? this.currentFilterComparisonOperators[0].operator;

            // reset value
            this.filter.values = [];
        }

        if (this.currentFilterProperty?.allowedValues) {
            this.allowedValues = this.currentFilterProperty.allowedValues;
            this.filter.comparisonOperator = ComparisonOperatorsEnum.Equal;
            // eslint-disable-next-line eqeqeq
            const hasValidValue = this.allowedValues.some(kv => kv.key == this.filter.values[0]);

            // set to first if not already a valid value
            if (!hasValidValue) {
                this.filter.values[0] = this.allowedValues[0].key;
            }
        } else {
            this.allowedValues = undefined;
        }

        this.changeDetectorRef.detectChanges();
        this.handleChange();
    }

    handleChange(): void {
        this.currentComparisonOperator = this.currentFilterComparisonOperators.find(o => o.operator === this.filter.comparisonOperator);
        this.filterService.updateFilter(this.index, this.filter);
    }

    handleValueChanged(value: any, index = 0): void {
        this.filter.values[index] = value;
        this.handleChange();
    }

    handleRemove(): void {
        this.filterService.removeFilter(this.index);
    }

    shouldShowValueField(): boolean {
        if (!this.currentFilterProperty) {
            return true;
        }

        return !!getFilterOperator(this.currentFilterProperty.type, this.filter.comparisonOperator)?.expectedParameterAmount;
    }
}
