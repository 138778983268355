<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToTransactionsPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        <div *ngIf="isLoading;" class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <arc-static-table *ngIf="!!tableConfig" [config]="tableConfig"
            [shouldUseCompactStyle]="true"></arc-static-table>
    </div>
</div>
