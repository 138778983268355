@if (!buttonColumnModel.hideButton || !buttonColumnModel.hideButton(item)) {
    @if (!!buttonColumnModel.actionButtonKey) {
        <arc-button [actionButtonKey]="buttonColumnModel.actionButtonKey" [actionButtonTitleKey]="buttonColumnModel.actionButtonTitleKey"
            [currentData]="item" [contextActionsStore]="buttonColumnModel.contextActionsStore"
            [type]="buttonColumnModel.isIcon ? 'icon' : ''" [isDisabled]="isDisabled" [isLoading]="isLoading()"></arc-button>
    } @else if (!!buttonColumnModel.icon && !getItemValue(buttonColumnModel.propertyName) && !buttonColumnModel.suffix) {
        <!-- button with icon only -->
        <button mat-icon-button (click)="onClick()" [style.background-color]="buttonColumnModel.bgColor"
            [style.color]="buttonColumnModel.fontColor" [disabled]="isDisabled">
            <mat-icon>{{ buttonColumnModel.icon(item) }}</mat-icon>
            {{ getItemValue(buttonColumnModel.propertyName) }} {{ buttonColumnModel.suffix | transloco }}
        </button>
    } @else {
        <!-- button with icon and text -->
        <button mat-button (click)="onClick()" [style.background-color]="buttonColumnModel.bgColor"
            [style.color]="buttonColumnModel.fontColor" [disabled]="isDisabled">
            <mat-icon *ngIf="!!buttonColumnModel.icon">{{ buttonColumnModel.icon(item) }}</mat-icon>
            @if(buttonColumnModel.suffix){
                {{ getItemValue(buttonColumnModel.propertyName) }} {{ buttonColumnModel.suffix(item) | transloco }}
            } @else {
                {{ getItemValue(buttonColumnModel.propertyName) }}
            }
        </button>
    }
}
