export enum InventoryArticleStatusEnum {
    NotCounted = 0,
    Deviating = 1,
    SoldDuringInventory = 2,
    Ok = 3
}
export class InventoryArticleStatusExtensions {
    static getColor(value: InventoryArticleStatusEnum): string {
        switch (value) {
            case InventoryArticleStatusEnum.NotCounted:
                return 'bg-info-light text-on-info-light';
            case InventoryArticleStatusEnum.Deviating:
                return 'bg-warn-light text-on-warn-light';
            case InventoryArticleStatusEnum.SoldDuringInventory:
                return 'bg-error-light text-on-error-light';
            case InventoryArticleStatusEnum.Ok:
                return 'bg-success-light text-on-success-light';
        }
    }
}
