import { Component, inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MessageModel } from '../../../app/models/message.model';
import { SecurityStorage } from '../../../core/services/storages/security.storage';
import { EnvironmentService } from '../../../core/services/environment.service';
import { MessagesStore } from '../../../app/services/stores/messages-store';

@Component({
    selector: 'arc-important-messages-dialog',
    templateUrl: './important-messages-dialog.component.html',
    styleUrls: ['./important-messages-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ImportantMessagesDialogComponent {
    readonly data: MessageModel[] = inject(MAT_DIALOG_DATA);
    currentMessageIndex = 0;

    private readonly dialogRef = inject(MatDialogRef<ImportantMessagesDialogComponent>);
    private readonly securityStorage = inject(SecurityStorage);
    private readonly environmentService = inject(EnvironmentService);
    private readonly messagesStore = inject(MessagesStore);

    getDownloadUrl(id: number): string {
        const token = this.securityStorage.getToken();
        const baseUrl = this.environmentService.baseUrl;

        return `${baseUrl}/messages/${id}/download-attachment/?token=${token}`;
    }

    markMessageAsRead(id: number): void {
        this.messagesStore.markAsRead(id).subscribe(r => {
            if (r.value) {
                if (this.currentMessageIndex + 1 < this.data.length) {
                    this.currentMessageIndex += 1;
                } else if (this.currentMessageIndex + 1 === this.data.length) {
                    this.dialogRef.close();
                }
            }
        });
    }
}
