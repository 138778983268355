import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Component, inject } from '@angular/core';

import { BaseComponent } from '../../../../../../../components/abstractions/base.component';
import { CustomerAccountBookingDialogDataModel } from './models/customer-account-booking-dialog-data.model';
import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../../core/utils/custom-validators';
import { OptionalType } from '../../../../../../../core/models/types/optional.type';
import { CustomersStore } from '../../../../../../services/stores/customers.store';
import { ToasterService } from '../../../../../../../core/services/toaster.service';

@Component({
    selector: 'arc-customer-account-booking-dialog',
    templateUrl: './customer-account-booking-dialog.component.html',
    styleUrls: ['./customer-account-booking-dialog.component.scss']
})
export class CustomerAccountBookingDialogComponent extends BaseComponent {
    readonly data: CustomerAccountBookingDialogDataModel = inject(MAT_DIALOG_DATA);

    formGroup = new FormGroup({
        amount: new ArcFormControl<number>(0.0, CustomValidators.number({ min: 0.0 })),
        remarks: new ArcFormControl<OptionalType<string>>('')
    });

    isCreating = false;

    private readonly dialogRef = inject(MatDialogRef<CustomerAccountBookingDialogComponent>);
    private readonly customerStore = inject(CustomersStore);
    private readonly toasterService = inject(ToasterService);

    onSave(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }
        this.isCreating = true;

        const requestModel = {
            personId: this.data.personId,
            accountId: this.data.accountId ?? 0,
            accountTypeId: this.data.accountTypeId,
            amount: this.formGroup.value.amount ?? 0.0,
            isDebit: this.data.isDebit,
            remarks: this.formGroup.value.remarks ?? ''
        };

        this.customerStore.addBalance(requestModel).subscribe(response => {
            if (response.value !== undefined) {
                this.dialogRef.close(response.value);
                this.toasterService.showSuccess('Customers.Account.AddBalanceSucceeded');

            } else {
                this.toasterService.showError('General.Alert.UnexpectedError');
                this.isCreating = false;
            }
        });
    }
}
