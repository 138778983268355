<div class="flex flex-col">
    <span class="truncate text-xs">{{ getItemValue(coloredStackedModel.propertyName) }}</span>

    <span class="truncate" [style.margin-top]="coloredStackedModel.isCompact ? '-5px' : ''">
        @if(!!colorValue){
            <span [ngClass]="borderClass" [class.dot]="!!getColor()" [style.background-color]="getColor()"></span>
        }
        <span [class.text-sm]="coloredStackedModel.isCompact">
            {{ getItemValue(coloredStackedModel.propertyName2) }}
        </span>
    </span>
</div>
