import { FormGroup } from '@angular/forms';

import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { EditableTableButtonModel } from './editable-table-button.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { BrokenRuleModel } from '../../../../app/models/broken-rule.model';

export class EditableTableConfigModel<TData extends Identifyable<TId>, TId = number> {
    formGroupGeneratorFn!: (data?: any) => FormGroup;
    onFormGroupValueChanged?: (newValue: TData, previousValue: TData, formGroups: FormGroup[]) => void;
    canDeleteRow?: (data: any) => boolean;
    onRowInit?: (formGroup: FormGroup) => void;
    columns?: BaseColumnModel[] = [];
    rowHeightPx = 52;
    rowGapPx = 4;
    allowDelete = true;
    showDeleteConfirmation = false;
    shouldHideAddButton = false;
    showPaging = false;
    pageSize = 10;
    additionalButtons: EditableTableButtonModel<TData>[] = [];
    willInvalidateOnDelete = false;
    formGroups: FormGroup[] = [];

    constructor(init?: Partial<EditableTableConfigModel<TData, TId>>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

    setBrokenRulesOnFormGroups(brokenRules: DictionaryType<BrokenRuleModel>): string[] {
        const mappedRuleIds: string[] = [];
        Object.keys(brokenRules).forEach(propName => {
            const br = brokenRules[propName];
            const fgs = this.formGroups.filter(fg => Object.keys(fg.controls).includes(propName) && fg.value[propName] === br.value);

            fgs.forEach(fg => fg.controls[propName].setErrors({ businessError: br.message }));

            if (fgs.length > 0) {
                mappedRuleIds.push(br.id!);
            }
        });

        return mappedRuleIds;
    }
}
