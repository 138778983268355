<form [formGroup]="formGroup" class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4">
    <arc-input class="col-span-3 ssm:col-span-6" labelKey="Suppliers.EditFields.SupplierNumber" [control]="formGroup.controls.personNumber">
    </arc-input>
    <div class="col-span-9 ssm:hidden"></div>
    <arc-checkbox class="col-span-2 ssm:col-span-6" labelKey="Suppliers.EditFields.IsCompany" [control]="formGroup.controls.isCompany">
    </arc-checkbox>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Suppliers.EditFields.CompanyName" [control]="formGroup.controls.companyName">
    </arc-input>
    <arc-general-data-select class="col-span-4 ssm:col-span-12" label="Suppliers.EditFields.CompanyLegalForm"
        [generalDataType]="GeneralDataTypeEnum.LegalForms" [formControl]="formGroup.controls.legalFormId"></arc-general-data-select>
    <arc-general-data-select class="col-span-6" label="Suppliers.EditFields.Salutation" [generalDataType]="GeneralDataTypeEnum.Salutations"
        [formControl]="formGroup.controls.salutationId"></arc-general-data-select>
    <arc-general-data-select class="col-span-6" label="Suppliers.EditFields.SalutationTitle"
        [generalDataType]="GeneralDataTypeEnum.SalutationTitles"
        [formControl]="formGroup.controls.salutationTitleId"></arc-general-data-select>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Suppliers.EditFields.FirstName" [control]="formGroup.controls.firstname">
    </arc-input>
    <arc-input class="col-span-6 ssm:col-span-12" labelKey="Suppliers.EditFields.Name" [control]="formGroup.controls.name"></arc-input>
    <arc-address-picker class="col-span-12 mt-4"
        [streetControl]="formGroup.controls.street"
        [streetNumberControl]="formGroup.controls.streetNumber"
        [streetSupplementControl]="formGroup.controls.streetSupplement"
        [isPoBoxControl]="formGroup.controls.isPoBox"
        [poBoxControl]="formGroup.controls.poBox"
        [zipControl]="formGroup.controls.zip"
        [cityControl]="formGroup.controls.city"
        [countryIsoCodeControl]="formGroup.controls.countryIsoCode">
    </arc-address-picker>
</form>
