import { Component, HostBinding, input, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { BaseControlComponent } from '../base-control/base-control.component';

@Component({
    selector: 'arc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseControlComponent {
    @HostBinding('class') classes = 'grow';
    @Input() type = 'text';
    @Input() minLength?: number;
    @Input() maxLength?: number;
    @Input() inputSize?: number;
    @Input() step?: number;
    @Input() prefixText?: string;
    @Input() showTextCount?: boolean;
    /**
     * Callback function to be added as an suffix icon.
     */
    @Input() action?: (control: FormControl) => Observable<any>;
    /**
     * The name of the icon to be used for the callback function.
     */
    @Input() actionIcon?: string;
    @Input() mask?: string;
    @Input() showMaskTyped = false;

    inputClasses = input<string>('');
    isActionLoading = false;

    executeAction(): void {
        if (!!this.action) {
            this.isActionLoading = true;
            this.action(this.castedControl).subscribe({
                complete: () => (this.isActionLoading = false)
            });
        }
    }
}
