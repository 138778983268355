import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseSidebarHeaderAdditionalInfoComponent } from '../../../../../components/sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';
import { WarehouseRequestModel } from '../../../../models/warehouse-request.model';
import { GenericStatusEnumExtensions } from '../../../../models/enums/generic-status.enum';

@Component({
    selector: 'arc-warehouse-transfer-edit-header-additional-info',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './warehouse-transfer-edit-header-additional-info.component.html',
    styleUrl: './warehouse-transfer-edit-header-additional-info.component.scss'
})
export class WarehouseTransferEditHeaderAdditionalInfoComponent extends BaseSidebarHeaderAdditionalInfoComponent<WarehouseRequestModel> {
    statusClass!: string;

    override onItemSet(): void {
        this.statusClass = GenericStatusEnumExtensions.getColor(this.item.status);
    }
}
