import { Component } from '@angular/core';

@Component({
    selector: 'arc-account-types',
    templateUrl: './account-types.component.html',
    styleUrls: ['./account-types.component.scss']
})
export class AccountTypesComponent {

}
