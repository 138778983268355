import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { TransactionTypeListModel } from '../../models/responses/transaction-type-list.model';
import { TransactionTypeModel } from '../../models/transaction-type.model';
import { TransactionTypeEditModel } from '../../models/requests/transaction-type-edit.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionTypesStore extends BaseCrudStore<TransactionTypeModel, TransactionTypeListModel, TransactionTypeEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'transaction-types' }));
    }
}
