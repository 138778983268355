import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { CustomerProfileModel } from '../../models/customer-profile.model';
import { CustomerProfileListModel } from '../../models/responses/customer-profile-list.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerProfilesStore extends BaseCrudStore<CustomerProfileModel, CustomerProfileListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'customer-profiles' }));
    }
}
