import { VoteTypeEnum } from '../../../../../app/models/enums/vote-type.enum';

export class FeatureDetailsDialogDataModel {
    featureId!: number;
    vote?: VoteTypeEnum;

    constructor (init?: Partial<FeatureDetailsDialogDataModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
