export enum TicketStatusEnum {
    Open = 0,
    WaitingOnRequester = 1,
    WaitingOnThirdParty = 2,
    ResolveSuggested = 3,
    Resolved = 4,
    Closed = 5
}
export class TicketStatusEnumExtensions {
    static getColor(value: TicketStatusEnum): string {
        switch (value) {
            case TicketStatusEnum.Open:
            case TicketStatusEnum.WaitingOnThirdParty:
                return 'bg-warn-light text-on-warn-light';
            case TicketStatusEnum.ResolveSuggested:
                return 'bg-success-light text-on-success-light';
            case TicketStatusEnum.Resolved:
                return 'bg-success text-on-success';
            case TicketStatusEnum.WaitingOnRequester:
                return 'bg-error text-on-error';
            case TicketStatusEnum.Closed:
                return 'bg-power-search text-on-power-search';
        }
    }
}
