import { Component, HostBinding, inject, signal } from '@angular/core';

import { BaseSidebarComponent } from '../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketListModel } from '../../models/responses/ticket-list.model';
import { TicketStore } from '../../services/stores/ticket.store';
import { TicketModel } from '../../models/ticket.model';
import { SidebarHeaderService } from '../../../core/services/sidebar-header.service';

@Component({
    selector: 'arc-tickets',
    templateUrl: './tickets.component.html',
    styleUrl: './tickets.component.scss'
})
export class TicketsComponent extends BaseSidebarComponent {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 smd:px-2 smd:pt-2';
    isLoading = signal(true);
    selectedTicket = signal<TicketModel | undefined>(undefined);
    openTickets = signal<TicketListModel[]>([]);

    private readonly ticketStore = inject(TicketStore);
    private readonly headerService = inject(SidebarHeaderService);

    constructor() {
        super();

        this.ticketStore.getOpenTickets().subscribe({
            next: r => {
                this.openTickets.set(r.value || []);
            },
            complete: () => {
                this.isLoading.set(false);
            }
        });
        this.headerService.hasPadding.set(false);
        this.addSubscriptions(this.headerService.backButtonClicked.subscribe(() => this.backToStart()));
    }

    selectTicket(ticketId: number): void {
        this.selectedTicket.set(undefined);

        this.isLoading.set(true);
        this.ticketStore.getTicket(ticketId).subscribe({
            next: r => {
                this.selectedTicket.set(r.value);
            },
            complete: () => {
                this.isLoading.set(false);
            }
        });
    }

    backToStart(): void {
        this.selectedTicket.set(undefined);
    }
}
