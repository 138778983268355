<div class="arc-pos-layout-card-container w-full h-full flex flex-col gap-1 overflow-y-auto overflow-x-hidden" #container>
    @if (item; as posLayout) {
        <!-- top row -->
        <div class="flex gap-1">
            <!-- top left -->
            <div class="grow"></div>

            <!-- top right (top panel) -->
            <div class="grid grid-flow-row" [ngStyle]="topGridStyle()">
                @for(command of posLayout.topGroup.commands; track command; let i = $index) {
                    <!-- skip this position if the previous command is double sized, since that one already takes 2 grid spaces -->
                    @if (!posLayout.topGroup.commands[i - 1]?.isDoubleSize) {
                        @if (command) {
                            <div [class.col-span-2]="command.isDoubleSize"
                                [style.background-color]="command.backgroundColor | transform : transformBackgroundColor">
                            </div>
                        } @else {
                            <div class="border border-dashed border-element-disabled"></div>
                        }
                    }
                }
            </div>
        </div>

        <!-- bottom panel -->
        @if (posLayout.bottomGroup) {
            <div class="grid grid-flow-row" [ngStyle]="bottomGridStyle()">
                @for(command of posLayout.bottomGroup.commands; track command; let i = $index) {
                    <!-- skip this position if the previous command is double sized, since that one already takes 2 grid spaces -->
                    @if (!posLayout.bottomGroup.commands[i - 1]?.isDoubleSize) {
                        @if (command) {
                            <div [class.col-span-2]="command.isDoubleSize"
                                [style.background-color]="command.backgroundColor | transform : transformBackgroundColor">
                            </div>
                        } @else {
                            <div class="border border-dashed border-element-disabled"></div>
                        }
                    }
                }
            </div>
        }
    }
</div>
