<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-translation-input translationDialogTitleKey="General.Actions.TranslateTitle" labelKey="Sales.Edit.Title"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it"></arc-translation-input>
    <div class="flex w-1/2 flex-row gap-4">
        @if(discountType.value === SaleDiscountTypesEnum.Amount) {
            <arc-currency-input label="Sales.Edit.Discount" [formControl]="formGroup.controls.valueAsNumber">
            </arc-currency-input>
        } @else if(discountType.value === SaleDiscountTypesEnum.Percent) {
            <arc-number-input label="Sales.Edit.Discount" [unit]="UnitsEnum.SpacedPercentSymbol" [isUnitTranslated]="true"
                [formControl]="formGroup.controls.valueAsNumber" [decimalPlaces]="2">
            </arc-number-input>
        }
        <div class="flex items-center">
            <arc-button-toggle-group class="ml-4 w-full" [control]="discountType" [items]="discountTypeItems"></arc-button-toggle-group>
        </div>
    </div>
    <div class="flex flex-row gap-4">
        <arc-number-input label="Sales.Edit.Quantity" [formControl]="formGroup.controls.quantity" unit="Sales.Edit.QuantityUnit"
            [decimalPlaces]="2">
        </arc-number-input>
        <arc-number-input label="Sales.Edit.RoundTo" [decimalPlaces]="2" [formControl]="formGroup.controls.roundTo">
        </arc-number-input>
        <arc-number-input label="Sales.Edit.BonusFactor" [formControl]="formGroup.controls.bonusFactor"
            [requiredPermission]="PermissionsEnum.CustomerBonusPrograms" [decimalPlaces]="2">
        </arc-number-input>
    </div>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches" [formControl]="formGroup.controls.branchId"
        label="Sales.Edit.Branch" [requiredPermission]="PermissionsEnum.Branches" class="w-2/3">
    </arc-general-data-select>
    <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PriceLevels" [formControl]="formGroup.controls.priceLevel"
        label="Sales.Edit.PriceLevel" class="w-2/3"></arc-general-data-select>
    <arc-input [control]="formGroup.controls.promotionCode" labelKey="Sales.Edit.PromotionCode"></arc-input>
    <div class="flex flex-row gap-4">
        <arc-checkbox labelKey="Sales.Edit.IsDiscountLocked" [control]="formGroup.controls.isDiscountLocked"></arc-checkbox>
        <arc-checkbox labelKey="Sales.Edit.AllowDeceedBuyingPrice" [control]="formGroup.controls.allowDeceedBuyingPrice"></arc-checkbox>
    </div>
</form>