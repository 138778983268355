import { Injectable } from '@angular/core';

import { BaseStorage } from '../../abstractions/base.storage';
import { OptionalType } from '../../models/types/optional.type';
import { AuthenticatedUserModel } from '../../../app/models/authenticated-user.model';

@Injectable({
    providedIn: 'root'
})
export class SecurityStorage extends BaseStorage {
    private readonly tokenKey = 'token';

    constructor() {
        super('security');
    }

    saveToken(userInfo: AuthenticatedUserModel): void {
        this.save(userInfo.token, this.tokenKey);
    }

    getToken(): OptionalType<string> {
        return this.get<string>(this.tokenKey);
    }

    eraseSecurityData(): void {
        this.remove(this.tokenKey);
    }
}
