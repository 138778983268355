<form [formGroup]="formGroup" class="grid items-center grid-cols-3 ssm:grid-cols-2 gap-4">
    <arc-input class="ssm:col-span-2" labelKey="Customers.EditFields.CardNumber"
        [control]="formGroup.controls.cardNumber"></arc-input>
    <arc-general-data-select label="Customers.EditFields.CustomerProfile"
        [generalDataType]="GeneralDataTypeEnum.CustomerProfiles" [formControl]="formGroup.controls.customerProfileId"
        [requiredPermission]="PermissionsEnum.CustomerProfiles" [requiredPermissionType]="PermissionTypeEnum.Update">
    </arc-general-data-select>
    <arc-input type="number" labelKey="Customers.EditFields.BonusPoints" [control]="formGroup.controls.bonusPoints"
        [requiredPermission]="PermissionsEnum.CustomerBonusPrograms" [requiredPermissionType]="PermissionTypeEnum.Read">
    </arc-input>
</form>