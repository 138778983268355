<h1 mat-dialog-title>
    <div class="inline-block w-full relative">
        <div class="flex items-center">
            <div class="grow flex flex-col">
                <span class="text-sm leading-none">{{ 'Widgets.Features.Feature' | transloco }} {{ data.featureId }}</span>
                <span class="leading-normal font-semibold">{{ feature()?.title }}</span>
            </div>
            <div class="flex gap-2 items-center justify-end">
                @if (feature()?.isFeatured) {
                    <mat-icon color="accent" [matTooltip]="'Widgets.Features.FeaturedToolTip' | transloco">
                        rocket_launch
                    </mat-icon>
                }
                @if (isNew()) {
                    <mat-icon color="accent">fiber_new</mat-icon>
                }
            </div>
        </div>
    </div>
</h1>

<mat-dialog-content class="grow !max-h-full !flex flex-col overflow-y-auto">

    @if (feature(); as feature) {
        <!-- chips list -->
        <div class="my-4 flex flex-wrap gap-2">
            <div class="px-4 py-1 rounded-full w-fit bg-info-light text-on-info-light">
                {{ 'Widgets.Features.Area' | transloco }}: {{ feature.areaName }}
            </div>
            <div class="px-4 py-1 rounded-full w-fit bg-info-light text-on-info-light" [ngClass]="{
            'bg-warn-light text-on-warn-light': feature.status === FeatureStatusEnum.Accepted,
            'bg-success-light text-on-success-light': feature.status === FeatureStatusEnum.InProgress,
            'bg-success text-on-success': feature.status === FeatureStatusEnum.Completed
            }">
                {{ 'Widgets.Features.Status' | transloco }}: {{ feature.statusName }}
            </div>
            <div class="px-4 py-1 rounded-full w-fit bg-info-light text-on-info-light">
                {{ 'Widgets.Features.Created' | transloco }}: {{ feature.createdDate | arcDate }}
            </div>
            @if (!!feature.expectedDeliveryTime) {
                <div class="px-4 py-1 rounded-full w-fit bg-info-light text-on-info-light">
                    {{ 'Widgets.Features.ExpectedDelivery' | transloco }}: {{ feature.expectedDeliveryTime | arcDate:'MMMM YYYY' }}
                </div>
            }
        </div>

        <!-- image and description -->
        <div markdown ngPreserveWhitespaces class="mb-16 prose prose-indigo">{{ feature.description }}</div>

        <form [formGroup]="formGroup">
            <mat-button-toggle-group [formControl]="formGroup.controls.vote" class="w-full pb-0.5 arc-button-toggle-group"
                [value]="feature.vote">
                <mat-button-toggle color="success" [value]="VoteTypeEnum.Up">
                    <mat-icon>sentiment_satisfied</mat-icon>
                    <span class="ml-2">
                        @if (feature.status === FeatureStatusEnum.Completed) {
                            {{ 'Widgets.Features.Voting.UpCompleted' | transloco }}
                        } @else {
                            {{ 'Widgets.Features.Voting.Up' | transloco }}
                        }
                    </span>
                </mat-button-toggle>
                <mat-button-toggle color="warn" [value]="VoteTypeEnum.Neutral">
                    <mat-icon>sentiment_neutral</mat-icon>
                    <span class="ml-2">
                        @if (feature.status === FeatureStatusEnum.Completed) {
                            {{ 'Widgets.Features.Voting.NeutralCompleted' | transloco }}
                        } @else {
                            {{ 'Widgets.Features.Voting.Neutral' | transloco }}
                        }
                    </span>
                </mat-button-toggle>
                <mat-button-toggle color="error" [value]="VoteTypeEnum.Down">
                    <mat-icon>sentiment_dissatisfied</mat-icon>
                    <span class="ml-2">
                        @if (feature.status === FeatureStatusEnum.Completed) {
                            {{ 'Widgets.Features.Voting.DownCompleted' | transloco }}
                        } @else {
                            {{ 'Widgets.Features.Voting.Down' | transloco }}
                        }
                    </span>
                </mat-button-toggle>
            </mat-button-toggle-group>

            @if (formGroup.controls.vote.touched) {
                <mat-error class="text-error">
                    {{ formGroup.controls.vote.errors | errorTranslator | async }}
                </mat-error>
            }

            @if (showCommentField()) {
                <mat-form-field class="w-full mt-6" subscriptSizing="dynamic" [arcErrorTooltip]="formGroup.controls.comment">
                    <textarea [formControl]="formGroup.controls.comment" matInput rows="5" class="resize-y min-h-[90px] max-h-[400px]"
                        [placeholder]="'Components.QuickFeedback.Dialog.TextPlaceholder' | transloco">
                    </textarea>
                </mat-form-field>
            }
        </form>
    }
    @else {
        <div class="w-full flex justify-center">
            <mat-spinner [diameter]="64"></mat-spinner>
        </div>
    }

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>{{ 'General.Actions.Cancel' | transloco }}</button>
    <button mat-flat-button color="accent" (click)="voteAndClose()">{{ 'General.Actions.Save' | transloco }}</button>
</mat-dialog-actions>
