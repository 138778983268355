import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { SecuritySessionStorage } from '../../../../core/services/storages/security.session-storage';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { SelectOptionModel } from '../../../../core/models/select-option.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { BaseAuthComponent } from '../../../../components/abstractions/base-auth.component';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BlobsStore } from '../../../services/stores/blobs.store';
import { EditableTableConfigModel } from '../../../../components/form/editable-table/models/editable-table-config.model';
import { WorkingTimeModel } from '../../../models/working-time.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { WorkingTimesStore } from '../../../services/stores/working-times.store';
import { SearchRequestModel } from '../../../models/requests/search-request.model';
import { Utils } from '../../../../core/utils/tools/utils.tools';
import { ProfileStore } from '../../../services/stores/profile.store';
import { ProfileModel } from '../../../models/profile.model';
import { ProfileEditModel } from '../../../models/profile-edit.model';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { ColumnFilterModel } from '../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../core/models/enums/filter-item-type.enum';
import { AuthenticatedUserModel } from '../../../models/authenticated-user.model';
import { AuthPermissionModel } from '../../../models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrl: './my-profile.component.scss'
})
export class MyProfileComponent extends BaseAuthComponent implements OnInit {
    formGroup = new FormGroup({
        username: new ArcFormControl<string>({ value: '', disabled: true }),
        realName: new ArcFormControl<string>('', [Validators.required]),
        email: new ArcFormControl<string>({ value: '', disabled: true }),
        languageId: new ArcFormControl<string>('', Validators.required),
        pin: new ArcFormControl<OptionalType<string>>(undefined),
        isLeftHanded: new ArcFormControl<boolean>(false, Validators.required),
        userColor: new ArcFormControl<OptionalType<string>>(undefined, Validators.required)
    });
    workingHoursFormGroupNotEditable = new FormGroup({
        workingHours: new ArcFormControl<WorkingTimeModel[]>([])
    });
    workingHoursFormGroup = new FormGroup({
        workingHours: new ArcFormControl<WorkingTimeModel[]>([])
    });
    isSaving = false;
    isLoading = true;
    isInEditMode = false;
    hasError = false;
    showPasswordClearText = false;
    profileModel?: ProfileModel;
    imageSrc = '';
    allowedLanguages: SelectOptionModel<string>[] = [];
    workingHoursTableConfigNotEditable = this.setupWorkingHoursTableConfig(false);
    workingHoursTableConfig = this.setupWorkingHoursTableConfig();
    workingTimesPermission: true | AuthPermissionModel;
    get qrCodeData(): string {
        // We use hardcoded my.arcavis.ch and username@licenseid to
        // Port the app to the old 1.50 api
        return `R,U,my.arcavis.ch,${this.profileModel?.username}@${this.userData?.licenseId}`;
    }

    private readonly blobsStore = inject(BlobsStore);
    private readonly profileStore = inject(ProfileStore);
    private readonly workingTimesStore = inject(WorkingTimesStore);
    private readonly translationService = inject(TranslationService);
    private readonly sidebarService = inject(SidebarService);
    private readonly securitySessionStorage = inject(SecuritySessionStorage);
    private readonly permissionService = inject(PermissionService);
    private readonly userData?: AuthenticatedUserModel;

    constructor() {
        super();

        this.sidebarService.closeLeft();
        this.changeFormGroupStatus(false);

        this.userData = this.securitySessionStorage.getUserInfo();
        this.workingTimesPermission = this.permissionService.hasPermission(PermissionsEnum.WorkingTimes);
    }

    ngOnInit(): void {
        if (!!this.userData) {
            this.profileStore.get(this.userData.id!).subscribe(resp => this.setupUserData(resp.value));
            this.loadWorkingHours();
        }
    }

    getBase64Src(): string {
        return Utils.getBase64ImageSrc(this.imageSrc);
    }

    toggleEditOrSave(): void {
        if (this.isInEditMode) {
            this.isSaving = true;

            this.profileStore.edit(
                {
                    ...this.profileModel,
                    ...this.formGroup.value,
                    workingTimes: this.workingHoursFormGroup.value.workingHours
                } as ProfileEditModel
            ).subscribe(() => {
                this.loadWorkingHours();
                this.changeFormGroupStatus(false);

                this.isSaving = false;
            });
        } else {
            this.changeFormGroupStatus(true);
        }

        this.isInEditMode = !this.isInEditMode;
    }

    showUpgradeModal(permission: AuthPermissionModel): void {
        this.permissionService.showUpgradeModal(permission);
    }

    private setupUserData(profile?: ProfileModel): void {
        if (!profile) {
            this.hasError = true;
            return;
        }

        this.profileModel = profile;
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));

        this.blobsStore.getFileByName(this.profileModel.profileImageBlobFileName!).subscribe(res => this.imageSrc = res.value!);
        this.formGroup.patchValue(this.profileModel);
        this.changeFormGroupStatus(false);

        this.isLoading = false;
    }

    private setupWorkingHoursTableConfig(isEditable = true): EditableTableConfigModel<WorkingTimeModel> {
        return new EditableTableConfigModel<WorkingTimeModel>({
            formGroupGeneratorFn: () =>
                !!this.profileModel
                    ? new FormGroup({
                        id: new ArcFormControl<number>(0),
                        userId: new ArcFormControl<number>(this.profileModel.id),
                        fromTime: new ArcFormControl<Date>(new Date(), Validators.required),
                        toTime: new ArcFormControl<Date>(new Date(), Validators.required),
                        remarks: new ArcFormControl<OptionalType<string>>(undefined)
                    })
                    : new FormGroup({}),
            rowHeightPx: 63,
            rowGapPx: 12,
            allowDelete: isEditable,
            shouldHideAddButton: !isEditable,
            showPaging: true,
            columns: [
                new DateColumnModel({
                    propertyName: 'fromTime',
                    format: 'short',
                    isEditable: isEditable,
                    hasTimePicker: true,
                    widthPixels: 200,
                    columnTitleKey: 'MyProfile.From'
                }),
                new DateColumnModel({
                    propertyName: 'toTime',
                    format: 'short',
                    isEditable: isEditable,
                    hasTimePicker: true,
                    widthPixels: 200,
                    columnTitleKey: 'MyProfile.To',
                    minDateField: 'fromTime'
                }),
                new StringColumnModel({
                    propertyName: 'total',
                    customFormatter: (record: WorkingTimeModel) => {
                        if (!!record.fromTime && !!record.toTime) {
                            let diff = record.toTime.getTime() - record.fromTime.getTime();
                            const hours = Math.floor(diff / (1000 * 60 * 60));
                            diff -= hours * (1000 * 60 * 60);
                            const minutes = Math.floor(diff / (1000 * 60));

                            return `${hours}h ${minutes}min`;
                        }

                        return '';
                    },
                    columnTitleKey: 'MyProfile.Total'
                }),
                new StringColumnModel({
                    propertyName: 'remarks',
                    isEditable: isEditable,
                    widthPixels: 300,
                    columnTitleKey: 'MyProfile.Remarks'
                })
            ]
        });
    }

    private changeFormGroupStatus(enable: boolean): void {
        this.formGroup.controls.realName.setDisabled(!enable);
        this.formGroup.controls.languageId.setDisabled(!enable);
        this.formGroup.controls.pin.setDisabled(!enable);
        this.formGroup.controls.isLeftHanded.setDisabled(!enable);
        this.formGroup.controls.userColor.setDisabled(!enable);
    }

    private loadWorkingHours(): void {
        if (this.workingTimesPermission !== true) {
            return;
        }

        this.workingTimesStore.search(new SearchRequestModel({
            pageSize: 999,
            filters: [
                new ColumnFilterModel({
                    column: 'UserId',
                    values: [this.userData!.id],
                    comparisonOperator: ComparisonOperatorsEnum.Equal,
                    dataType: FilterItemTypeEnum.Number
                })
            ]
        })).subscribe(resp => {
            const records = resp.value?.records || [];

            this.workingHoursFormGroup.patchValue({ workingHours: records });
            this.workingHoursFormGroupNotEditable.patchValue({ workingHours: records });
        });
    }
}
