import { Component, OnInit, signal } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ButtonToggleColumnModel } from '../../models/column-types/button-toggle-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { ButtonToggleModel } from '../../../../core/models/button-toggle.model';

@Component({
    selector: 'arc-button-toggle-column',
    templateUrl: './button-toggle-column.component.html',
    styleUrls: ['./button-toggle-column.component.scss']
})
export class ButtonToggleColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    columnItems = signal<ButtonToggleModel<T>[]>([]);

    override ngOnInit(): void {
        super.ngOnInit();
        const buttonToggleColumnModel = this.columnModel as ButtonToggleColumnModel;
        if (buttonToggleColumnModel) {
            this.columnItems.set(buttonToggleColumnModel.options.map(x => ({ value: x.key ?? '', label: x.value ?? '' })));
            if (!buttonToggleColumnModel.isEditable) {
                this.control?.disable();
            }
        }
    }
}
