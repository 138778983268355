export enum RecordChangeTypeEnum {
    Added = 4,
    Deleted = 8,
    Modified = 16
}

export class RecordChangeTypeEnumExtensions {
    static getColor(value: RecordChangeTypeEnum): string {
        switch (value) {
            case RecordChangeTypeEnum.Added:
                return 'bg-success-light text-on-success-light';
            case RecordChangeTypeEnum.Modified:
                return 'bg-warn-light text-on-warn-light';
            case RecordChangeTypeEnum.Deleted:
                return 'bg-error-light text-on-error-light';
        }
    }
}
