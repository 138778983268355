// if you change this, also change it in the backend (Arcavis.Api.Models/DynamicForms/GeneralDataTypes.cs)
// TODO: find a better solution
export enum GeneralDataTypeEnum {
    Groups = 'groups',
    Branches = 'branches',
    BranchesWithOrderStore = 'branches/order',
    PointOfSales = 'point-of-sales',
    Users = 'users',
    LegalForms = 'legal-forms',
    Regions = 'regions',
    ArticleTaxes = 'article-taxes',
    Tags = 'tags',
    Salutations = 'salutations',
    SalutationTitles = 'salutation-titles',
    Countries = 'countries',
    Units = 'units',
    PosReports = 'pos-reports',
    CustomerProfiles = 'customer-profiles',
    PriceLevels = 'price-levels',
    PaymentTypes = 'payment-types',
    TransactionTypes = 'transaction-types',
    TransactionPurposes = 'transaction-purposes',
    Stores = 'stores',
    Taxes = 'taxes',
    StatusCodes = 'status-codes',
    VoucherTypes = 'voucher-types',
    VoucherDiscounts = 'voucher-discounts',
    Seasons = 'seasons',
    BonusRuns = 'bons-runs',
    CommunicationRuns = 'communication-runs',
    WarehouseRequests = 'warehouse-requests',
    Sales = 'sales',
    SubscriptionTypes = 'subscription-types',
    Inventories = 'inventories',
    OrderExportScripts = 'order-export-scripts',
    PosLayouts = 'pos-layouts',
    Currencies = 'currencies',
    AccountTypesForCustomerProfile = 'account-types?contextTypeId=3',
    ReportTemplates = 'report-templates',
    Roles = 'roles',
    ReservationTypes = 'reservation-types',
    AccountContextTypes = 'account-context-types',
    Suppliers = 'suppliers',
    PosCommands = 'pos-commands',
    CardTypes = 'card-types',
    ContentUnits = 'content-units'
}
