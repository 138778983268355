import { Component, OnChanges } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { GenericStatusColumnModel } from '../../models/column-types/generic-status-column.model';
import { GenericStatusEnumExtensions } from '../../../../app/models/enums/generic-status.enum';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-generic-status-column',
    templateUrl: './generic-status-column.component.html',
    styleUrls: ['./generic-status-column.component.scss']
})
export class GenericStatusColumnComponent<T extends Identifyable<TId>, TId = number>
    extends BaseColumnComponent<T, TId> implements OnChanges {
    get castedColumnModel(): GenericStatusColumnModel {
        return this.columnModel as GenericStatusColumnModel;
    }

    descriptionColumn!: string;
    bgColor!: string;

    ngOnChanges(): void {
        this.descriptionColumn = this.getItemValue(this.castedColumnModel.descriptionColumn);
        this.bgColor = !!this.castedColumnModel.bgColor
            ? this.castedColumnModel.bgColor(this.getItemValue(this.castedColumnModel.statusColumn))
            : GenericStatusEnumExtensions.getColor(this.getItemValue(this.castedColumnModel.statusColumn));
    }
}
