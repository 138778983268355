import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionListModel } from '../../../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../../../models/transaction.model';
import { StaticTableConfigModel } from '../../../../../../components/list-views/static-table/models/static-table-config.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { TransactionArticlesStore } from '../../../../../services/stores/transaction-articles.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { ColumnFilterModel } from '../../../../../../core/models/column-filter.model';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { ComparisonOperatorsEnum } from '../../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../../core/models/enums/filter-item-type.enum';
import { NumberPipe } from '../../../../../../core/pipes/number.pipe';
import { UserService } from '../../../../../../core/services/user.service';

@Component({
    selector: 'arc-transaction-articles',
    templateUrl: './transaction-articles.detail-widget.html',
    styleUrls: ['./transaction-articles.detail-widget.scss'],
    providers: [NumberPipe]
})
export class TransactionArticlesDetailWidget extends BaseDetailWidget<TransactionListModel, TransactionModel> implements OnInit {
    tableConfig?: StaticTableConfigModel<TransactionArticleListModel>;
    isLoading = true;

    private readonly router = inject(Router);
    private readonly transactionArticlesStore = inject(TransactionArticlesStore);
    private readonly userService = inject(UserService);
    private readonly numberPipe = inject(NumberPipe);

    ngOnInit(): void {
        const currencyCode = this.userService.getUserInfo()!.currencyIsoCode;
        this.transactionArticlesStore.search(new SearchRequestModel({
            pageSize: 999,
            filters: [
                new ColumnFilterModel({
                    column: 'TransactionId',
                    values: [this.listModel.id],
                    comparisonOperator: ComparisonOperatorsEnum.Equal,
                    dataType: FilterItemTypeEnum.Number
                })
            ]
        })).subscribe(r => {
            this.tableConfig = new StaticTableConfigModel<TransactionArticleListModel>({
                data: r.value?.records || [],
                defaultPageSize: 5,
                availableColumns: [
                    new StringColumnModel({
                        columnTitleKey: 'TransactionArticles.List.Quantity',
                        propertyName: 'quantity',
                        widthPixels: 40,
                        isCompact: true
                    }),
                    new StringColumnModel({
                        columnTitleKey: 'TransactionArticles.List.Article',
                        propertyName: 'title',
                        widthPixels: 180,
                        isCompact: true
                    }),
                    new StringColumnModel({
                        columnTitleKey: 'TransactionArticles.List.Discount',
                        propertyName: 'discount',
                        widthPixels: 60,
                        columnTitleSuffix: currencyCode,
                        isCompact: true,
                        customFormatter: (record, value) => this.numberPipe.transform(value, 2)
                    }),
                    new StringColumnModel({
                        columnTitleKey: 'TransactionArticles.List.Total',
                        propertyName: 'price',
                        widthPixels: 60,
                        columnTitleSuffix: currencyCode,
                        isCompact: true,
                        customFormatter: record => this.numberPipe.transform((record.quantity * record.unitPrice) - record.discount, 2)
                    })
                ]
            });
            this.isLoading = false;
        });
    }

    goToArticlesPage(): void {
        this.router.navigate(['transaction-articles'], { queryParams: { TransactionId: this.listModel.id } }).then();
    }
}
