import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArcavisLogModel } from '../../../../../models/arcavis-log.model';
import { ArcavisLogListModel } from '../../../../../models/responses/arcavis-log-list.model';

@Component({
    selector: 'arc-system-log-stacktrace-detail-widget',
    templateUrl: './system-log-stacktrace.detail-widget.html',
    styleUrl: './system-log-stacktrace.detail-widget.scss'
})
export class SystemLogStackTraceDetailWidget extends BaseDetailWidget<ArcavisLogListModel, ArcavisLogModel, string> {}
