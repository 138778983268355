import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject, signal } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { PosLayoutModel } from '../../../../../models/pos-layout.model';
import { PosLayoutGroupListModel } from '../../../../../models/responses/pos-layout-group-list.model';
import { PosLayoutEditTabDialogComponent } from '../pos-layout-edit-tab-dialog/pos-layout-edit-tab-dialog.component';
import { PosLayoutsStore } from '../../../../../services/stores/pos-layouts.store';
import { PosLayoutGroupsStore } from '../../../../../services/stores/pos-layout-groups.store';
import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { PosLayoutEditTabDialogDataCreateModel } from '../pos-layout-edit-tab-dialog/models/pos-layout-edit-tab-dialog-data-create.model';
import { PosLayoutEditTabDialogDataUpdateModel } from '../pos-layout-edit-tab-dialog/models/pos-layout-edit-tab-dialog-data-update.model';

@Component({
    selector: 'arc-pos-layout-edit-tabs',
    templateUrl: './pos-layout-edit-tabs.component.html',
    styleUrl: './pos-layout-edit-tabs.component.scss'
})
export class PosLayoutEditTabsComponent extends BaseComponent implements OnChanges {
    @Input({ required: true }) posLayout!: PosLayoutModel;
    @Input() isBottomPanel = false;

    // TODO replace with acitvePanelId = model.required() once on v18
    @Input({ required: true }) activePanelId?: number;
    @Output() readonly activePanelIdChange = new EventEmitter<number>();

    @Output() readonly reload = new EventEmitter<void>();

    isLoading = signal(true);
    panels = signal<PosLayoutGroupListModel[]>([]);

    private readonly filterPredicate: (group: PosLayoutGroupListModel) => boolean;
    private readonly sortPredicate: (a: PosLayoutGroupListModel, b: PosLayoutGroupListModel) => number;
    private readonly posLayoutsStore = inject(PosLayoutsStore);
    private readonly posLayoutGroupsStore = inject(PosLayoutGroupsStore);
    private readonly matDialog = inject(MatDialog);

    constructor() {
        super();
        this.filterPredicate = g => g.isBottomPanel === this.isBottomPanel;
        this.sortPredicate = (a, b) => a.sort - b.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['posLayout']) {
            this.panels.set(this.posLayout.posLayoutGroups.filter(this.filterPredicate).sort(this.sortPredicate));
            this.isLoading.set(false);
        }
    }

    dropPanel(event: CdkDragDrop<PosLayoutGroupListModel[]>): void {
        const panels = this.panels();
        moveItemInArray(panels, event.previousIndex, event.currentIndex);

        this.isLoading.set(true);
        this.posLayoutsStore
            .savePosLayoutGroupsOrder(
                this.posLayout.id,
                panels.map(g => g.id)
            )
            .subscribe(() => this.reload.emit());
        this.panels.set(panels);
    }

    addPanel(): void {
        this.openEditPanelDialog({
            posLayoutId: this.posLayout.id,
            isBottomPanel: this.isBottomPanel
        });
    }

    editPanel(posLayoutGroupId: number): void {
        this.openEditPanelDialog({ posLayoutGroupId });
    }

    removePanel(panelId: number): void {
        this.posLayoutGroupsStore.remove(panelId).subscribe(() => {
            this.isLoading.set(true);
            this.reload.emit();
        });
    }

    private openEditPanelDialog(data: PosLayoutEditTabDialogDataCreateModel | PosLayoutEditTabDialogDataUpdateModel): void {
        const dialogRef = this.matDialog.open(PosLayoutEditTabDialogComponent, {
            data,
            width: '400px',
            maxHeight: '98svh',
            maxWidth: '98vw'
        });
        const dialogSub = dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.isLoading.set(true);
            this.reload.emit();
        });

        this.addSubscriptions(dialogSub);
    }
}
