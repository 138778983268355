@if (columnModel.isEditable && !!control) {
    <arc-tree-autocomplete
        [label]="castedCellModel.columnTitleKey"
        [formControl]="control | formControl"
        [treeDataSelectionConfig]="articleGroupConfig">
    </arc-tree-autocomplete>
}
@else {
    <arc-tree-autocomplete
        [label]="castedCellModel.columnTitleKey"
        [formControl]="readonlyControl | formControl"
        [treeDataSelectionConfig]="articleGroupConfig">
    </arc-tree-autocomplete>
}
