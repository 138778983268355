export const mainMenuRoutes = [
    {
        titleKey: 'Articles.Articles',
        subMenu: 'articles'
    },
    {
        titleKey: 'Storage.Storage',
        subMenu: 'storage'
    },
    {
        titleKey: 'Customers.Customers',
        subMenu: 'customers'
    },
    {
        titleKey: 'Sales.Sales',
        subMenu: 'transactions'
    }
];
