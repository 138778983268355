<div class="flex flex-col justify-center gap-4 mt-4">
    <header class="absolute top-0 right-0 flex justify-center">
        <a mat-icon-button [href]="qrCodeDownloadLink" download="qrcode">
            <mat-icon>download</mat-icon>
        </a>
    </header>
    <div class="h-full w-full">
        <div class="flex items-center flex-col">
            <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="qrCodeUrl.value!" [width]="150" [margin]="1"></qrcode>
        </div>
    </div>
    @if (entityModel.hasPin) {
        <div class="mt-4">
            <arc-button-toggle-group [control]="qrCodeUrl" [items]="qrCodeTypeItems()"
                toggleGroupClasses="w-full h-full arc-button-toggle-dynamic-height"></arc-button-toggle-group>
        </div>
    }
</div>