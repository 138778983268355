import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TransactionListModel } from '../../../models/responses/transaction-list.model';
import { TransactionModel } from '../../../models/transaction.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { TransactionsStore } from '../../../services/stores/transactions.store';
import { DateService } from '../../../../core/services/date.service';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { TransactionCustomerDetailWidget } from './transaction-detail-widgets/transaction-customer/transaction-customer.detail-widget';
import { TransactionArticlesDetailWidget } from './transaction-detail-widgets/transaction-articles/transaction-articles.detail-widget';
import { TransactionDetailsDetailWidget } from './transaction-detail-widgets/transaction-details/transaction-details.detail-widget';
import { TransactionPaymentsDetailWidget } from './transaction-detail-widgets/transaction-payments/transaction-payments.detail-widget';
import { ButtonColumnModel } from '../../../../components/dynamic-table/models/column-types/button-column.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-transactions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent extends PageListViewComponent<TransactionListModel, TransactionModel> {
    private readonly transactionsStore = inject(TransactionsStore);
    private readonly dateService = inject(DateService);
    private readonly router = inject(Router);

    constructor() {
        super('Transactions');
        this.config = new TableListViewConfigModel<TransactionListModel, TransactionModel>({
            entityName: 'Transactions',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Transactions.List.Transaction',
                    propertyName: 'title',
                    propertyName2: 'number',
                    widthPixels: 150
                }),
                dates: new StackedColumnModel({
                    columnTitleKey: 'Transactions.List.Time',
                    propertyName: 'saleTime',
                    propertyName2: 'saleDay',
                    widthPixels: 126,
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName === 'saleDay') {
                            return record.displaySaleDay ? this.dateService.format(value) : '';
                        } else {
                            return this.dateService.format(value, 'short').replace(',', '');
                        }
                    }
                }),
                information: new ColoredStackedColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Pos',
                    propertyName: 'regionBranchDescription',
                    propertyName2: 'posName',
                    color: 'posColor',
                    widthPixels: 156
                }),
                store: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.StoreName',
                    propertyName: 'storeName',
                    widthPixels: 126
                }),
                user: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.User',
                    propertyName: 'userName',
                    widthPixels: 126
                }),
                customer: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.CustomerName',
                    propertyName: 'customerDisplayName',
                    suffixPropertyName: 'customerCompanyContact',
                    widthPixels: 236
                }),
                numberOfArticles: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.NumberOfArticles',
                    propertyName: 'numberOfArticles',
                    widthPixels: 126
                }),
                numberOfPositions: new StringColumnModel({
                    columnTitleKey: 'Transactions.List.Positions',
                    propertyName: 'numberOfPositions',
                    widthPixels: 96
                }),
                total: new CurrencyColumnModel({
                    columnTitleKey: 'General.Total',
                    propertyName: 'amount',
                    widthPixels: 130
                }),
                cancellationReference: new ButtonColumnModel({
                    columnTitleKey: 'Transactions.List.Canceled',
                    suffix: () =>  'Transactions.List.Canceled',
                    icon: () => 'launch',
                    bgColor: 'var(--error)',
                    fontColor: 'var(--on-error)',
                    onClickFunction: (item: TransactionListModel) => this.navigateToCancellation(item),
                    hideButton: (item: TransactionListModel) => this.hideButton(item),
                    widthPixels: 236
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'saleTime',
                direction: SortDirectionEnum.Desc
            }),
            defaultColumnOrder: [
                'title',
                'dates',
                'information',
                'store',
                'user',
                'customer',
                'numberOfPositions',
                'numberOfArticles',
                'total',
                'cancellationReference'
            ],
            availableDetailWidgets: {
                customer: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.CustomerWidget',
                    component: TransactionCustomerDetailWidget,
                    requiredPermission: PermissionsEnum.Customers
                }),
                articles: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.ArticlesWidget',
                    component: TransactionArticlesDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.DetailsWidget',
                    component: TransactionDetailsDetailWidget
                }),
                payments: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.PaymentsWidget',
                    component: TransactionPaymentsDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['customer', 'articles', 'details', 'payments'],
            store: this.transactionsStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasEdit: false, hasDelete: false })
        });
    }

    navigateToCancellation(item: TransactionListModel): void {
        this.router.navigate(['transactions'], { queryParams: { Id: [item.id, item.annulationTransactionId] } }).then();
    }

    hideButton(item: TransactionListModel): boolean {
        return !item.annulationTransactionId;
    }
}
