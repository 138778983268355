<!-- TODO: if menu is not allowed (because of missing module or permission), show lock here and make disabled -->
@if (!menuItem.isDisabled) {
    <mat-list-item class="left-sidebar-item shrink-0 hover:bg-element-hover" routerLinkActive="!bg-element-selected"
        [routerLinkActiveOptions]="routerLinkActiveOptions" [routerLink]="menuItem.path" [queryParams]="menuItem.startingQueryParams">
        <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
        @if(hasFavoritesPermission === true){
            <!-- favourite button -->
            <button mat-icon-button class="!mr-[3px] !ml-2" *ngIf="isFavouriteButtonVisible || (isCurrentRoute && !canHover)"
                [matTooltip]="(menuItem.favouriteId !== undefined ? 'Layout.Menu.Favourites.RemoveFavourite' : 'Layout.Menu.Favourites.AddFavourite') | transloco"
                (click)="addOrRemoveMenuFavourites($event)" matListItemMeta>
                @if (!isFavouriteButtonLoading) {
                    <mat-icon class="text-icon-light">
                        {{ menuItem.favouriteId !== undefined ? 'bookmark' : 'bookmark_border' }}
                    </mat-icon>
                } @else {
                    <mat-spinner [diameter]="24"></mat-spinner>
                }
            </button>
        }
    </mat-list-item>
} @else if (menuItem.permission !== true) {
    <mat-list-item class="left-sidebar-item" (click)="showUpgradeModal(menuItem.permission)">
        <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
        <div matListItemMeta [arcPermissionTooltip]="menuItem.permission">
            <arc-icon icon="lock" [size]="20" class="text-on-app"></arc-icon>
        </div>
    </mat-list-item>
} @else {
    <mat-list-item class="left-sidebar-item" [matTooltip]="'General.ComingSoon' | transloco" disabled>
        <span matListItemTitle class="ml-2 !text-inherit">{{ menuItem.labelKey | transloco }}</span>
    </mat-list-item>
}
