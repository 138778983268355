<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="Settings.Edit.Invoices.QrBill.Iban" [control]="formGroup.controls.invoiceIban"></arc-input>
    <arc-input labelKey="Settings.Edit.Invoices.QrBill.CompanyName" [control]="formGroup.controls.invoiceCompanyName"></arc-input>
    <arc-address-picker
        class="col-span-12 mt-4"
        [streetControl]="formGroup.controls.invoiceStreet"
        [streetNumberControl]="formGroup.controls.invoiceStreetNumber"
        [streetSupplementControl]="formGroup.controls.invoiceStreetSupplement"
        [zipControl]="formGroup.controls.invoiceZip"
        [cityControl]="formGroup.controls.invoiceCity"
        [countryIsoCodeControl]="formGroup.controls.invoiceCountryIsoCode">
    </arc-address-picker>
</form>
