import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { OptionalType } from '../../../../core/models/types/optional.type';

export class EditSidebarLayoutSettingsModel {
    editItemsOrder!: string[];
    editItemsOpen!: DictionaryType<OptionalType<boolean>>;

    constructor(init?: Partial<EditSidebarLayoutSettingsModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
