<div class='max-w-[360px] flex flex-col gap-12'>
    <div>
        <h1 class='text-3xl mb-4 break-words'>
            {{ 'Login.Title' | transloco }}
        </h1>
        <p>
            {{ 'Login.Description' | transloco }}
        </p>
    </div>
    <form [formGroup]="formGroup" (submit)="login()">
        <mat-card-content class="!flex flex-col gap-4">
            @if (!isSupporterLogin || !isSuccessfulLogin) {
                @if (!isCheckingAuth) {
                    <arc-input [control]="formGroup.controls['user']" labelKey="Login.Username"></arc-input>
                    <div class='relative'>
                        <arc-input [control]="formGroup.controls['password']" [type]="showPasswordClearText ? 'text' : 'password'"
                            labelKey="Login.Password" placeholder="********">
                        </arc-input>
                        <arc-icon class="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
                            [icon]="showPasswordClearText ? 'visibility_off' : 'visibility'"
                            (click)='showPasswordClearText = !showPasswordClearText'></arc-icon>
                    </div>
                    <button type="button" class="text-right text-accent -ml-2 -mt-1 mr-5 mb-10 hover:underline"
                        (click)="redirectToPasswordRecover()">
                        {{ 'Login.RecoverPassword' | transloco }}
                    </button>
                } @else {
                    <div class="flex flex-col items-center gap-4">
                        <mat-spinner [diameter]="48"></mat-spinner>
                        <span>{{ 'Login.CheckingAuthentication' | transloco }}...</span>
                    </div>
                }
            } @else {
                <mat-form-field>
                    <mat-label>Login on behalf</mat-label>
                    <mat-select [(ngModel)]="selectedUserToImpersonate" [ngModelOptions]="{standalone: true}">
                        @for (user of usersToImpersonate; track user) {
                            <mat-option [value]="user">{{ user.realName }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }
        </mat-card-content>
        @if (!isCheckingAuth) {
            <mat-card-actions>
                <button type="submit" mat-flat-button color="accent">{{ 'Login.SignIn' | transloco }}</button>
            </mat-card-actions>
        }
    </form>
</div>


