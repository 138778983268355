import { Component } from '@angular/core';

import { VoucherListModel } from '../../../../../models/responses/voucher-list.model';
import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';

@Component({
    selector: 'arc-voucher-type-column',
    templateUrl: './voucher-type-column.component.html',
    styleUrls: ['./voucher-type-column.component.scss']
})
export class VoucherTypeColumnComponent extends BaseCustomColumnComponent<VoucherListModel> {
    get colorClasses(): string[] {
        return this.item.isDiscountVoucher ? ['bg-info', 'text-on-info'] : ['bg-primary', 'text-on-primary'];
    }
}
