import { Injectable } from '@angular/core';

import { BaseStorage } from '../../abstractions/base.storage';
import { StorageTypeEnum } from '../../models/enums/storage-type.enum';
import { OptionalType } from '../../models/types/optional.type';
import { AuthenticatedUserModel } from '../../../app/models/authenticated-user.model';
import { AuthPermissionModel } from '../../../app/models/auth-permission.model';
import { StatusMessageModel } from '../../../app/models/status-message.model';

@Injectable({
    providedIn: 'root'
})
export class SecuritySessionStorage extends BaseStorage {
    private readonly userInfoKey = 'userInfo';
    private readonly statusMessageKey = 'statusMessage';

    constructor() {
        super('security-session', StorageTypeEnum.Session);
    }

    saveUserInfo(userInfo: AuthenticatedUserModel): void {
        this.saveStatusMessages((userInfo.statusMessages || []).sort((a, b) => a.text.localeCompare(b.text)));
        this.save(userInfo, this.userInfoKey);
    }

    getUserInfo(): OptionalType<AuthenticatedUserModel> {
        return this.get<AuthenticatedUserModel>(this.userInfoKey);
    }

    getPermissions(): AuthPermissionModel[] {
        const userInfo = this.get<AuthenticatedUserModel>(this.userInfoKey);
        return userInfo?.permissions || [];
    }

    getStatusMessages(): StatusMessageModel[] {
        return this.get<StatusMessageModel[]>(this.statusMessageKey) || [];
    }

    dismissStatusMessage(statusMessage: StatusMessageModel): void {
        const statusMessages = this.getStatusMessages();
        const idx = this.findStatusMessageIdx(statusMessage);

        if (idx >= 0) {
            statusMessages[idx].hasBeenDismissed = true;
        }

        this.saveStatusMessages(statusMessages);
    }

    findStatusMessageIdx(statusMessage: StatusMessageModel): number {
        const statusMessages = this.getStatusMessages();
        return statusMessages.findIndex(
            sm => sm.text === statusMessage.text && sm.icon === statusMessage.icon && sm.type === statusMessage.type
        );
    }

    eraseSecurityData(): void {
        this.remove(this.userInfoKey);
        this.remove(this.statusMessageKey);
    }

    private saveStatusMessages(statusMessages: StatusMessageModel[]): void {
        this.save(statusMessages, this.statusMessageKey);
    }
}
