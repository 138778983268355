import { Component, inject, AfterViewInit } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { MessageListModel } from '../../../models/responses/message-list.model';
import { MessagesStore } from '../../../services/stores/messages-store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { MessageModel } from '../../../models/message.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { MessagePriorityColumnComponent } from './components/message-priority-column/message-priority-column.component';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { MessageBodyDetailWidget } from './detail-widgets/message-body/message-body.detail-widget';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { TableListViewComponent } from '../../../../components/list-views/table-list-view/table-list-view.component';

@Component({
    selector: 'arc-messages',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent extends PageListViewComponent<MessageListModel> implements AfterViewInit {
    private readonly messagesStore = inject(MessagesStore);

    constructor() {
        super('Messages');
        this.config = new TableListViewConfigModel<MessageListModel, MessageModel, MessageModel>({
            entityName: 'Messages',
            availableColumns: {
                priority: new CustomColumnModel({
                    columnTitleKey: 'Messages.List.Priority',
                    propertyName: 'priority',
                    customComponent: MessagePriorityColumnComponent,
                    widthPixels: 60
                }),
                data: new StackedColumnModel({
                    columnTitleKey: 'Messages.List.Message',
                    propertyName: 'subject',
                    propertyName2: 'senderUserName',
                    widthPixels: 550,
                    sortColumn: 'subject',
                    isBold: (record: MessageListModel) => !record.readTime
                }),
                createdTime: new DateColumnModel({
                    columnTitleKey: 'Messages.List.Received',
                    propertyName: 'createdTime',
                    widthPixels: 180,
                    format: 'short',
                    isBold: (record: MessageListModel) => !record.readTime
                })
            },
            defaultColumnOrder: ['priority', 'data', 'createdTime'],
            defaultSort: new ColumnSortModel({
                column: 'createdTime',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                body: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.DetailsWidget',
                    component: MessageBodyDetailWidget,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['body'],
            store: this.messagesStore
        });
    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();

        const panelSub = (this.listView as TableListViewComponent<MessageModel, MessageListModel>).panelOpenedSub.subscribe(id =>
            this.messagesStore.markAsRead(id).subscribe()
        );

        this.addSubscriptions(panelSub);
    }
}
