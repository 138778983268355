<div class="relative min-h-[128px]">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
        <input matInput [(ngModel)]="searchText" (input)="searchTextChanged()"
            placeholder="{{ 'General.Search' | transloco }}..." />
    </mat-form-field>
    <table mat-table [dataSource]="data" matSort (matSortChange)="handleSort($event)">

        <!-- columns -->
        <ng-container *ngFor="let column of columnConfig" [matColumnDef]="column.propertyName!">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.propertyName!">
                {{ column.columnTitleKey| transloco }}
            </th>
            <td mat-cell *matCellDef="let entry">
                <ng-container arcDynamicColumn [columnModel]="column" [item]="entry"></ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let entry">
                @if(updatePermission === true) {
                <button class="hover:text-on-accent" mat-icon-button (click)="removeItem(entry.id)"
                    *ngIf="relatedEntityChanges.addedIds.includes(entry.id) ||(!relatedEntityChanges.removedIds.includes(entry.id) && !relatedEntityChanges.removeAll)">
                    <mat-icon>close</mat-icon>
                </button>
                }
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [class.removed]="relatedEntityChanges.removedIds.includes(entry.id) || relatedEntityChanges.removeAll"
            [class.added]="relatedEntityChanges.addedIds.includes(entry.id) && !isCreate" mat-row
            *matRowDef="let entry; columns: displayedColumns;">
        </tr>

    </table>

    <div *ngIf="isLoading" class="absolute inset-0 bg-overlay grid place-items-center">
        <mat-spinner [diameter]="64"></mat-spinner>
    </div>
</div>

<mat-paginator [length]="totalRecords" [pageSize]="pageSize" (page)="handlePageEvent($event)"></mat-paginator>

@if(updatePermission === true) {
<div class="flex gap-4 justify-end">
    <button *ngIf="!isCreate" mat-stroked-button (click)="removeAllRelatedEntites()">
        {{'Tags.Edit.RemoveAllArticles' | transloco}}
    </button>
    <button mat-stroked-button (click)="openSelectionDialog($event)">
        {{'Tags.Edit.AddArticles' | transloco}}
    </button>
</div>
}