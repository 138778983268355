import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-settings-settings-article-prices',
    templateUrl: './settings-settings-article-prices.component.html',
    styleUrl: './settings-settings-article-prices.component.scss'
})
export class SettingsSettingsArticlePricesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        articlePriceDecimalDigits: new ArcFormControl(0, [Validators.required, CustomValidators.number()]),
        tryCalculateQuantityFromPriceEan: new ArcFormControl(false)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            articlePriceDecimalDigits: value.articlePriceDecimalDigits,
            tryCalculateQuantityFromPriceEan: value.tryCalculateQuantityFromPriceEan
        };
    }
}
