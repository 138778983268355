<div class="h-full">
    <div class="h-full w-full">
        @if (isLoading) {
        <mat-spinner [diameter]="32"></mat-spinner>
        }
        @if (!!tableConfig) {
        <arc-static-table [config]="tableConfig" [shouldUseCompactStyle]="true" [isSingleLineRow]="true">
        </arc-static-table>
        }
    </div>
</div>