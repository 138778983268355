import { Component, inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PointOfSaleEditModel } from '../../../../../models/requests/point-of-sale-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PosCashRegister } from '../../../../../models/pos-cash-registers.model';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { CurrencyColumnModel } from '../../../../../../components/dynamic-table/models/column-types/currency-column.model';
import { PointOfSaleModel } from '../../../../../models/point-of-sale.model';
import { KeyValueModel } from '../../../../../../core/models/key-value.model';
import { GeneralDataService } from '../../../../../../core/services/general-data.service';
import { NumberPipe } from '../../../../../../core/pipes/number.pipe';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';

@Component({
    selector: 'arc-pos-edit-balance',
    templateUrl: './pos-edit-balance.component.html',
    styleUrls: ['./pos-edit-balance.component.scss'],
    providers: [NumberPipe]
})
export class PosEditBalanceComponent extends BaseEditSidebarItemComponent<PointOfSaleModel, PointOfSaleEditModel> {
    override formGroup = new FormGroup({
        posCashRegisters: new ArcFormControl<PosCashRegister[]>([])
    });
    cashRegistersTableConfig: EditableTableConfigModel<PosCashRegister>;
    currencies: KeyValueModel[] = [];

    private readonly generalDataService = inject(GeneralDataService);
    private readonly numberPipe = inject(NumberPipe);

    constructor() {
        super();
        this.generalDataService.getGeneralData(GeneralDataTypeEnum.Currencies).subscribe(c => {
            this.currencies = c;
        });
        this.cashRegistersTableConfig = new EditableTableConfigModel<PosCashRegister>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    balance: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0.0 })]),
                    basis: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ min: 0.0 })),
                    currencyId: new ArcFormControl(1, [Validators.required, this.uniqueCurrencyValidator()]),
                    lastCheckDate: new ArcFormControl(new Date())
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            willInvalidateOnDelete: true,
            columns: [
                new StringColumnModel({
                    propertyName: 'balance',
                    isEditable: false,
                    columnTitleKey: 'PointOfSales.EditFields.Balance',
                    customFormatter: (record, value) =>
                        this.numberPipe.transform(value, 2, this.currencies.find(c => c.key === record.currencyId)?.value + ' ')
                }),
                new CurrencyColumnModel({
                    propertyName: 'basis',
                    isEditable: true,
                    currencyId: item => item.currencyId,
                    columnTitleKey: 'PointOfSales.EditFields.Basis'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'currencyId',
                    isEditable: true,
                    generalDataType: GeneralDataTypeEnum.Currencies,
                    columnTitleKey: 'PointOfSales.EditFields.Currency',
                    widthPixels: 150,
                    onValueChanged: (item, newValue, updateValidity) => {
                        const posCashRegisters = this.formGroup.value.posCashRegisters;
                        this.formGroup.patchValue({ posCashRegisters });
                        // This is needed so to update validity of form on next event loop cycle
                        setTimeout(() => updateValidity(), 50);
                    }
                }),
                new DateColumnModel({
                    propertyName: 'lastCheckDate',
                    columnTitleKey: 'PointOfSales.EditFields.LastCheckDate',
                    widthPixels: 150,
                    format: 'short'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.cashRegistersTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    // TODO: This is commented for now because cash balance is not editable - uncomment when cash balance is editable again
    // override shouldSave(itemCopy: PointOfSaleModel): Promise<boolean> {
    //     // Cash Registers are not changed => don't show confirmation dialog
    //     if (Utils.areEqual(itemCopy.posCashRegisters, this.formGroup.value.posCashRegisters)) {
    //         return Promise.resolve(true);
    //     }

    //     // Show confirmation dialog
    //     const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
    //         data: { promptKey: 'PointOfSales.Edit.BalanceChangePromt' }
    //     });

    //     return firstValueFrom(dialogRef.afterClosed());
    // }

    override prepareSaveModel(): Partial<PointOfSaleEditModel> {
        return {
            posCashRegisters: this.formGroup.value.posCashRegisters
        };
    }

    uniqueCurrencyValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            const newCurrencyId = control.value;

            const isCurrencyExists = this.formGroup.value.posCashRegisters?.find(
                d => d.currencyId === newCurrencyId && control.parent?.value?.id > 0 && d.id !== control.parent?.value.id
            );

            if (isCurrencyExists) {
                return { uniqueCurrencies: true };
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }
}
