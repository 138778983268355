import { Component, HostBinding, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../../../components/abstractions/base.component';
import { OptionalType } from '../../../../../core/models/types/optional.type';
import { ToasterService } from '../../../../../core/services/toaster.service';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { ComplexDataTypesEnum } from '../../../../../core/models/complex-data-types.enum';
import { GeneralDataTypeEnum } from '../../../../../core/models/enums/general-data-type.enum';
import { OrdersStore } from '../../../../services/stores/orders.store';
import { OrderCreateModel } from '../../../../models/requests/order-create.model';

@Component({
    selector: 'arc-order-create-dialog',
    templateUrl: './order-create-dialog.component.html',
    styleUrls: ['./order-create-dialog.component.scss']
})
export class OrderCreateDialogComponent extends BaseComponent {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    ComplexDataTypesEnum = ComplexDataTypesEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    isLoading = false;
    isCreating = false;
    formGroup = new FormGroup({
        supplierId: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
        branchId: new ArcFormControl<OptionalType<string>>(undefined, Validators.required)
    });

    private readonly ordersStore = inject(OrdersStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);

    create(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.invalid) {
            return;
        }

        this.isCreating = true;
        const createModel = this.formGroup.value as OrderCreateModel;

        this.ordersStore.add(createModel).subscribe(response => {
            if (response.value) {
                this.matDialogRef.close({ shouldReload: true, id: response.value });
            } else {
                this.toasterService.showError('General.Alert.UnexpectedError');
                this.isCreating = false;
            }
        });
    }
}
