import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class CheckboxColumnModel extends BaseColumnModel {

    constructor(init?: Partial<CheckboxColumnModel>) {
        super(ColumnTypeEnum.Checkbox);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
