import { Component, inject, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import {
    StaticTableConfigModel
} from '../../../list-views/static-table/models/static-table-config.model';
import { SimpleTransactionModel } from '../../../../app/models/simple-transaction.model';
import { DateService } from '../../../../core/services/date.service';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { ColoredStackedColumnModel } from '../../../dynamic-table/models/column-types/colored-stacked-column.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';
import { NumberPipe } from '../../../../core/pipes/number.pipe';
import { SecuritySessionStorage } from '../../../../core/services/storages/security.session-storage';
import { UserService } from '../../../../core/services/user.service';

@Component({
    selector: 'arc-transactions-list-widget',
    templateUrl: './transactions-list-widget.component.html',
    styleUrls: ['./transactions-list-widget.component.scss'],
    providers: [NumberPipe]
})
export class TransactionsListWidgetComponent implements OnChanges {
    @Input() items: SimpleTransactionModel[] = [];
    @Input() queryParams: DictionaryType<string> = {};
    @Input() isLoading!: boolean;

    tableConfig?: StaticTableConfigModel<SimpleTransactionModel>;

    protected readonly _dateService = inject(DateService);
    protected readonly _router = inject(Router);
    protected readonly _numberPipe = inject(NumberPipe);
    protected readonly _securitySession = inject(SecuritySessionStorage);
    protected readonly _userService = inject(UserService);

    ngOnChanges(): void {
        this.setupTable();
    }

    goToTransactionsPage(): void {
        this._router.navigate(['transactions'], { queryParams: this.queryParams }).then();
    }

    protected setupTable(): void {
        const currencyCode = this._userService.getUserInfo()!.currencyIsoCode;
        this.tableConfig = new StaticTableConfigModel<SimpleTransactionModel>({
            data: this.items,
            defaultPageSize: 4,
            availableColumns: [
                new StackedColumnModel({
                    columnTitleKey: 'Transactions.List.Transaction',
                    propertyName: 'title',
                    propertyName2: 'number',
                    widthPixels: 115,
                    isCompact: true
                }),
                new StackedColumnModel({
                    columnTitleKey: 'Transactions.List.Date',
                    propertyName: 'saleTime',
                    propertyName2: 'saleDay',
                    widthPixels: 90,
                    isCompact: true,
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName === 'saleDay') {
                            return record.displaySaleDay ? this._dateService.format(value) : '';
                        } else {
                            return this._dateService.format(value, 'short').replace(',', '');
                        }
                    }
                }),
                new ColoredStackedColumnModel({
                    columnTitleKey: 'Transactions.List.PointOfSale',
                    propertyName: 'posName',
                    propertyName2: 'regionBranchDescription',
                    color: 'posColor',
                    widthPixels: 110,
                    isCompact: true
                }),
                new StringColumnModel({
                    columnTitleKey: 'TransactionPayments.List.Amount',
                    columnTitleSuffix: currencyCode,
                    propertyName: 'amount',
                    widthPixels: 80,
                    isCompact: true,
                    customFormatter: (record, value) => this._numberPipe.transform(value, 2)
                })
            ]
        });
        this.isLoading = false;
    }
}
