import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LockedDialogComponent } from '../../../components/dialogs/locked-dialog/locked-dialog.component';
import { UserService } from '../user.service';

export const checkIsLocked: CanActivateFn = () => {
    const userService = inject(UserService);
    const matDialog = inject(MatDialog);
    const loggedUser = userService.getUserInfo();

    if (!!loggedUser && loggedUser.isLocked) {
        matDialog.open(LockedDialogComponent, {
            data: { message: loggedUser.lockedMessage },
            width: '400px',
            maxWidth: '90vw',
            maxHeight: '90svh',
            disableClose: true
        });
    }

    return true;
};
