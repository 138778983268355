import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    private readonly overlayElementId = 'global-overlay';
    private _overlayElement?: HTMLElement;

    private excludedElement?: HTMLElement;

    private get overlayElement(): HTMLElement {
        if (!this._overlayElement) {
            this._overlayElement = document.getElementById(this.overlayElementId) ?? undefined;

            if (!this._overlayElement) {
                throw new Error('OverlayService: Global overlay element not found');
            }
        }

        return this._overlayElement;
    }

    showOverlay(selector: HTMLElement | string): void {
        if (!!this.excludedElement) {
            return;
        }

        this.excludedElement = (typeof selector === 'string')
            ? document.querySelector(selector) ?? undefined
            : selector;

        if (!this.excludedElement) {
            return;
        }

        const rect = this.excludedElement.getBoundingClientRect();

        this.overlayElement.style.left = `${rect.left}px`;
        this.overlayElement.style.top = `${rect.top}px`;
        this.overlayElement.style.width = `${rect.width}px`;
        this.overlayElement.style.height = `${rect.height}px`;
        this.showGlobalOverlay();
    }

    removeOverlay(): void {
        this.hideGlobalOverlay();
        this.excludedElement = undefined;
    }

    private showGlobalOverlay(): void {
        this.overlayElement.style.display = 'block';
        this.overlayElement.animate([{ opacity: '0' }, { opacity: '1' }], {
            duration: 200
        });
    }

    private hideGlobalOverlay(): void {
        this.overlayElement.animate([{ opacity: '1' }, { opacity: '0' }], {
            duration: 100
        }).addEventListener('finish', () => {
            this.overlayElement.style.display = 'none';
        });
    }
}
