import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { SettingsListModel } from '../../models/responses/settings-list.model';
import { ConfigurationModel } from '../../models/configuration.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsStore extends BaseCrudStore<ConfigurationModel, ConfigurationModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'settings' }));
    }

    list(): Observable<ApiResponseModel<SettingsListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SettingsListModel[]>>(this.getUrl('list'));
    }
}
