import { Component, input } from '@angular/core';

import { BaseControlComponent } from '../form/base-control/base-control.component';

@Component({
    selector: 'arc-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrl: './color-picker.component.scss'
})
export class ColorPickerComponent extends BaseControlComponent {
    availableFormats = input<Array<'hex' | 'rgba' | 'hsla' | 'cmyk'>>(['hex']);
    colorPickerControls = input<'default' | 'only-alpha' | 'no-alpha'>('no-alpha');

    colorChanged(newColor: string): void {
        this.control.setValue(newColor);
        this.control.markAsDirty();
    }
}
