import { Component, OnInit, inject } from '@angular/core';
import { ChartData } from 'chart.js';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { CustomerListModel } from '../../../../../models/responses/customer-list.model';
import { CustomerModel } from '../../../../../models/customer.model';
import { CustomersStore } from '../../../../../services/stores/customers.store';
import { CustomerTurnoverModel } from '../../../../../models/customer-turnover.model';
import { DateService } from '../../../../../../core/services/date.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { DictionaryType } from '../../../../../../core/models/types/dictionary.type';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';

@Component({
    selector: 'arc-customer-turnover',
    templateUrl: './customer-turnover.detail-widget.html',
    styleUrls: ['./customer-turnover.detail-widget.scss']
})
export class CustomerTurnoverDetailWidget extends BaseDetailWidget<CustomerListModel, CustomerModel> implements OnInit {
    isLoading = true;
    chartData?: ChartData;
    currentData?: CustomerTurnoverModel;
    bonusPointsPermission: true | AuthPermissionModel = true;

    private readonly customersStore = inject(CustomersStore);
    private readonly dateService = inject(DateService);
    private readonly translationService = inject(TranslationService);

    ngOnInit(): void {
        const turnoverLabel = this.translationService.getText('Customers.Details.Turnover');

        this.customersStore.getTurnover(this.entityModel.id).subscribe(r => {
            this.currentData = r.value!;
            this.chartData = {
                labels: this.getLabels(),
                datasets: [
                    {
                        label: turnoverLabel,
                        data: this.getData(this.currentData.last12MonthsTurnover),
                        backgroundColor: 'rgb(12, 66, 69)'
                    }
                ]
            };
            this.isLoading = false;
        });
    }

    private getLabels(): string[] {
        return Object.keys(this.currentData!.last12MonthsTurnover)
            .map(k => `${this.dateService.format(new Date(k), 'MMM')}`.toUpperCase().substring(0, 3));
    }

    private getData(dict: DictionaryType<number>): number[] {
        return Object.values(dict);
    }
}
