import { Directive, HostBinding, Injector, Input, OnInit, inject } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NgControl,
    Validators
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { merge } from 'rxjs';

import { BaseComponent } from '../../components/abstractions/base.component';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../models/enums/permission-type.enum';
import { ArcFormControl } from '../utils/arc-form-control';
import { environment } from '../../environments/environment';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { PermissionService } from '../services/permission.service';

@Directive()
export abstract class BaseControlValueAccessor<T> extends BaseComponent implements ControlValueAccessor, OnInit {
    @Input() label?: string;
    @Input() isLabelTranslated = false;
    @Input() placeholder = '';
    @Input() isPlaceholderTranslated?: boolean;
    @Input() color: ThemePalette = 'primary';
    @Input() hasFloatingLabel = false;
    @Input() hasFixedSubscript = false;
    @Input() requiredPermission?: PermissionsEnum;
    @Input() requiredPermissionType = PermissionTypeEnum.Read;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @HostBinding('class') @Input('class') classList = '';

    value?: T;

    isDisabled = false;
    isTouched = false;
    permission: true | AuthPermissionModel = true;

    isRequired = false;

    internalControl = new ArcFormControl();

    protected _formControl?: AbstractControl;

    protected readonly _permissionService = inject(PermissionService);
    private readonly injector = inject(Injector);

    get isAllowed(): boolean {
        return this.permission === true;
    }

    onTouched: () => void = () => {
    };
    onChange: (value?: T) => void = () => {
    };

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
            this._formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
        } else {
            this._formControl = (ngControl as FormControlDirective).form;
        }

        let touchedObservable = this.internalControl.onTouched;
        if (this._formControl instanceof ArcFormControl) {
            touchedObservable = merge(this.internalControl.onTouched, this._formControl.onTouched);
        } else {
            if (!environment.production) {
                console.warn('Please use ArcFormControl with BaseControlValueAccessor');
            }
        }
        const touchedSub = touchedObservable.subscribe(newTouched => {
            if (!this.isTouched && newTouched) {
                this.markAsTouched();
            } else if (this.isTouched && !newTouched) {
                this.markAsUntouched();
            }
        });
        this.addSubscriptions(touchedSub);

        if (!!this.requiredPermission) {
            this.permission = this._permissionService.hasPermission(this.requiredPermission, this.requiredPermissionType);
            if (this.permission !== true) {
                this.setDisabledState(true);
            }
        }

        if (this.isPlaceholderTranslated === undefined) {
            this.isPlaceholderTranslated = this.isLabelTranslated;
        }

        if (!!this._formControl) {
            // eslint-disable-next-line no-null/no-null
            this.internalControl.setValidators(() => this._formControl?.errors ?? null);
            this.isRequired = this._formControl.hasValidator(Validators.required);
            if (this.isRequired) {
                // add required validator so material adds the *
                this.internalControl.addValidators(Validators.required);
            }

            const control = this._formControl;
            this._formControl.statusChanges.subscribe(() => {
                this.internalControl.setErrors(control.errors);
            });
        }
    }

    /**
     * Value set from outside the control. If that value is invalid, reset to undefined
     */
    writeValue(value?: T): void {
        // undefined must be a valid value, else it would cause infinite recursion
        if (this.isValueValid(value) || value === undefined) {
            this.value = value;
            this.internalControl.setValue(value);
        } else {
            this.value = undefined;
            this.internalControl.setValue(undefined);
        }
    }

    /**
     * Registers a function that should be called when the control value changes
     */
    registerOnChange(fn: (value?: T) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a function that should be called when the control is touched
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * sets the disabled stat of the control from outside
     */
    setDisabledState(isDisabled: boolean): void {
        // if permission is missing, the control cannot be enabled
        if (!isDisabled && !this.isAllowed) {
            return;
        }

        this.isDisabled = isDisabled;

        if (isDisabled) {
            this.internalControl.disable();
        } else {
            this.internalControl.enable();
        }
    }

    /**
     * This method can be overwritten in a component extending this class
     * to check a value before setting in {@link writeValue}
     * @param value the value to be set
     * @returns whether the value is valid
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected isValueValid(value?: T): boolean {
        return true;
    }

    /**
     * This should be executed in a component extending this class whenever
     * the value changed internally (e.g. the user clicks or writes something)
     * @param newValue The changed value of the control
     * @param shouldMarkAsTouched Whether the control should be marked as touched with this change
     */
    protected valueChanged(newValue?: T, shouldMarkAsTouched = true): void {
        if (shouldMarkAsTouched) {
            this.markAsTouched();
        }
        this.value = newValue;
        this.onChange(newValue);
    }

    /**
     * This marks the control and the dummy control as touched
     */
    protected markAsTouched(): void {
        if (!this.isTouched) {
            this.isTouched = true;
            this.onTouched();
            this.internalControl.markAsTouched();
        }
    }

    /**
     * This marks the control and the dummy control as untouched
     */
    protected markAsUntouched(): void {
        if (this.isTouched) {
            this.isTouched = false;
            this.internalControl.markAsUntouched();
        }
    }
}
