<ng-container *ngIf="!!model; else noSupplierTpl">
    <div class="h-full w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-7">
            <div class="min-h-[100px]">
                <p class="text-on-app-light text-sm">{{ 'General.Address.Address' | transloco }}</p>
                @if(model.isCompany){
                    <p>{{ model.displayName }}</p>
                }
                <p>
                    {{ model.salutation }} {{ model.salutationTitle }}
                    {{ model.isCompany ? model.companyContactName : model.firstname + ' ' + model.name }}
                </p>
                <p>{{ model.street }} {{ model.streetNumber }}</p>
                <p>{{ model.streetSupplement }}</p>
                <p *ngIf="model.isPoBox">{{ 'General.Address.IsPoBox' | transloco }} {{ model.poBox }}</p>
                <p>{{ model.zip }} {{ model.city }}</p>
            </div>
            <div class="min-h-[50px]">
                <p class="text-on-app-light text-sm">{{ 'General.Contact' | transloco }}</p>
                <p *ngIf="!!model.contactEmail" class="flex gap-2">
                    <arc-icon icon="mail_outline" [size]="14"></arc-icon>
                    <a href="mailto:{{ model.contactEmail }}" class="line-clamp-1">{{ model.contactEmail }}</a>
                </p>
                <p *ngIf="!!model.contactPhone" class="flex gap-2">
                    <arc-icon icon="phone" [size]="14"></arc-icon>
                    <a href="tel:{{ model.contactPhone }}">{{ model.contactPhone }}</a>
                </p>
                <p *ngIf="!!model.contactMobile" class="flex gap-2">
                    <arc-icon icon="smartphone" [size]="14"></arc-icon>
                    <a href="tel:{{ model.contactMobile }}">{{ model.contactMobile }}</a>
                </p>
                <p *ngIf="!!model.contactFax" class="flex gap-2">
                    <arc-icon icon="fax" [size]="14"></arc-icon>
                    <a href="fax:{{ model.contactFax }}">{{ model.contactFax }}</a>
                </p>
                <p *ngIf="!!model.contactUrl" class="flex gap-2">
                    <arc-icon icon="language" [size]="14"></arc-icon>
                    <a target="_blank" href="//{{ websiteUrl }}">{{ model.contactUrl }}</a>
                </p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-5">
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.EditFields.CustomerNumber' | transloco }}</p>
                <p>{{ model.supplierCustomerNumber }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Details.ContactData.MinimumOrderAmount' | transloco }}</p>
                <p>{{ model.personDeliveryCondition?.minOrderAmount | arcCurrency }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Details.ContactData.DeliveryDays' | transloco }}</p>
                <p>{{ deliveryDays }}</p>
            </div>
            <div class="min-h-[40px]">
                <p class="text-on-app-light text-sm">{{ 'Suppliers.Details.ContactData.DeliveryDeadline' | transloco }}</p>
                <p *ngIf="!!model.supplierDeliveryDays">
                    {{ model.supplierDeliveryDays }} {{ 'General.Units.Days.Short' | transloco }}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noSupplierTpl>
    <div class="flex items-center justify-center h-full">
        <span class="text-sm">
            {{ 'Suppliers.Details.ContactData.NoSupplierSelected' | transloco }}
        </span>
    </div>
</ng-template>
