import { Component, HostBinding } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { TranslationStringColumnModel } from '../../models/column-types/translation-string-column.model';
import { ArcFormControlInterface } from '../../../../core/utils/arc-form-control';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-translation-string-column',
    templateUrl: './translation-string-column.component.html',
    styleUrl: './translation-string-column.component.scss'
})
export class TranslationStringColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> {
    @HostBinding('class') classList = 'h-full';
    get castedColumnModel(): TranslationStringColumnModel {
        return this.columnModel as TranslationStringColumnModel;
    }
    get controlDe(): ArcFormControlInterface {
        return this.formGroup?.get(this.castedColumnModel.controlNameDe) as ArcFormControlInterface;
    }
    get controlEn(): ArcFormControlInterface {
        return this.formGroup?.get(this.castedColumnModel.controlNameEn) as ArcFormControlInterface;
    }
    get controlFr(): ArcFormControlInterface {
        return this.formGroup?.get(this.castedColumnModel.controlNameFr) as ArcFormControlInterface;
    }
    get controlIt(): ArcFormControlInterface {
        return this.formGroup?.get(this.castedColumnModel.controlNameIt) as ArcFormControlInterface;
    }
}
