import { Component, HostBinding, inject, ViewChild, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TreeItemFlatNode } from '../../../core/models/tree-item.flat-node';
import { SearchRequestService } from '../../../core/services/search-request.service';
import { FilterService } from '../../../core/services/filter.service';
import { TreeDataSelectionConfig } from '../../form/tree-autocomplete/models/tree-data-selection.config';
import { DynamicTreeDatabase } from './utils/dynamic-tree.database';
import { TreeConfigSetupService } from './utils/tree-config-setup.service';
import { BaseTreeListComponent } from '../../../core/abstractions/base.tree-list.component';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { PermissionService } from '../../../core/services/permission.service';
import { PermissionsEnum } from '../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../core/models/enums/permission-type.enum';

/**
 * IMPORTANT!
 * This component is expected to behave similarly to the ArticleGroupsComponent.
 * If one change is made here, that would alter its behavior in a way that should be replicated there, we should do so.
 */
@Component({
    selector: 'arc-tree-data-selection-dialog',
    templateUrl: './tree-data-selection-dialog.component.html',
    styleUrls: ['./tree-data-selection-dialog.component.scss'],
    // tree data selection dialog needs its own instances of these services
    providers: [
        SearchRequestService,
        FilterService,
        DynamicTreeDatabase,
        { provide: TreeDataSelectionConfig, useExisting: MAT_DIALOG_DATA },
        TreeConfigSetupService
    ]
})
export class TreeDataSelectionDialogComponent<T extends Identifyable<TId>, TId> extends BaseTreeListComponent<T, TId> {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';
    @ViewChild('newRecordInput') _newRecordInput?: ElementRef;

    selectionModel = new SelectionModel<TreeItemFlatNode<TId>>(false);

    protected readonly _dialogData: TreeDataSelectionConfig<T, TId> = inject(MAT_DIALOG_DATA);

    private readonly matDialogRef = inject(MatDialogRef);
    private readonly permissionsService = inject(PermissionService);

    constructor() {
        super();
        this._canCreate = this.permissionsService.hasPermission(PermissionsEnum.ArticleGroups, PermissionTypeEnum.Create) === true;
    }

    selectItem(node: TreeItemFlatNode<TId>): void {
        this.selectionModel.select(node);
        this.matDialogRef.close(node.id);
    }

    canNodeBeSelected(node: TreeItemFlatNode<TId>): boolean {
        return this._dialogData.allowSelectionAtLevels.includes(node.level);
    }
}
