import { Component, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { PointOfSaleListModel } from '../../../../../models/responses/pos-list.model';
import { SimpleTransactionModel } from '../../../../../models/simple-transaction.model';
import { PointOfSaleModel } from '../../../../../models/point-of-sale.model';

@Component({
    selector: 'arc-pos-transactions',
    templateUrl: './pos-transactions.component.html',
    styleUrls: ['./pos-transactions.component.scss']
})
export class PosTransactionsComponent extends BaseDetailWidget<PointOfSaleListModel, PointOfSaleModel> implements OnInit {
    items: SimpleTransactionModel[] = [];

    ngOnInit(): void {
        this.items = this.entityModel.transactions;
    }
}
