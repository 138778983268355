<div [ngClass]="{ 'border border-solid border-error rounded-md p-2': !!brokenRules['articleSuppliers'] }">
    <arc-editable-table [config]="accountTypesTableConfig" [template]="accountTypeTemplate" [formControl]="formGroup.controls.accountTypes">
        <ng-template #accountTypeTemplate let-formGroup>
            <div class="grid grid-cols-3 grid-rows-3 gap-4 items-center">
                <arc-input labelKey="Settings.Edit.AccountTypes.AccountTypes.Title" [control]="formGroup.controls.title">
                </arc-input>
                <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.AccountContextTypes"
                    [formControl]="formGroup.controls.contextTypeId" label="Settings.Edit.AccountTypes.AccountTypes.ContextType">
                </arc-general-data-select>
                <arc-number-input label="Settings.Edit.AccountTypes.AccountTypes.MinAmount" [formControl]="formGroup.controls.minAmount">
                </arc-number-input>
                <arc-checkbox labelKey="Settings.Edit.AccountTypes.AccountTypes.Debit" [control]="formGroup.controls.debit">
                </arc-checkbox>
                <arc-checkbox labelKey="Settings.Edit.AccountTypes.AccountTypes.CumulateBalance"
                    [control]="formGroup.controls.cumulateBalance">
                </arc-checkbox>
                <arc-checkbox labelKey="Settings.Edit.AccountTypes.AccountTypes.AllowInvoice" [control]="formGroup.controls.allowInvoice">
                </arc-checkbox>
                <arc-number-input label="Settings.Edit.AccountTypes.AccountTypes.PaymentDays"
                    [formControl]="formGroup.controls.paymentDays">
                </arc-number-input>
            </div>
        </ng-template>
    </arc-editable-table>
</div>