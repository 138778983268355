/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { SupplierModel } from '../../models/supplier.model';
import { SupplierListModel } from '../../models/responses/supplier-list.model';
import { SupplierEditRequestModel } from '../../models/requests/supplier-edit-request.model';

@Injectable({
    providedIn: 'root'
})
export class SuppliersStore extends BaseCrudStore<SupplierModel, SupplierListModel, SupplierEditRequestModel, SupplierEditRequestModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'suppliers' }));
    }
}
