import { Injectable } from '@angular/core';

import { BaseStorage } from '../../abstractions/base.storage';
import { OptionalType } from '../../models/types/optional.type';
import { LayoutSettingsModel } from '../../../app/models/layout-settings.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsStorage extends BaseStorage {
    private notLoggedRedirectUrl = 'notLoggedRedirectUrlKey';
    private notLoggedUserLanguageCode = 'notLoggedUserLanguageCodeKey';
    private darkMode = 'darkModeKey';
    private layoutSettings = 'layoutSettingsKey';
    private lastLoginTime = 'lastLoginTimeKey';
    private lastCheckedImportantMessagesTime = 'lastCheckedImportantMessagesTimeKey';

    constructor() {
        super('settings');
    }

    saveNotLoggedRedirectUrl(redirectUrl?: string): void {
        this.save(redirectUrl, this.notLoggedRedirectUrl);
    }

    getNotLoggedRedirectUrl(): OptionalType<string> {
        return this.get<string>(this.notLoggedRedirectUrl);
    }

    saveNotLoggedLanguageCode(languageCode: string): void {
        this.save(languageCode, this.notLoggedUserLanguageCode);
    }

    getNotLoggedLanguageCode(): OptionalType<string> {
        return this.get<string>(this.notLoggedUserLanguageCode);
    }

    saveDarkMode(isDarkMode: boolean): void {
        this.save(isDarkMode, this.darkMode);
    }

    getDarkMode(): OptionalType<boolean> {
        return this.get<boolean>(this.darkMode);
    }

    saveLayoutSettings(layoutSettings: LayoutSettingsModel): void {
        this.save(layoutSettings, this.layoutSettings);
    }

    getLayoutSettings(): OptionalType<LayoutSettingsModel> {
        return this.get<LayoutSettingsModel>(this.layoutSettings);
    }

    saveLastLoginTimeStamp(lastLogin: number): void {
        this.save(lastLogin, this.lastLoginTime);
    }

    getLastLoginTimeStamp(): OptionalType<number> {
        return this.get<number>(this.lastLoginTime);
    }

    saveCheckedImportantMessagesTimeStamp(lastLogin: number): void {
        this.save(lastLogin, this.lastCheckedImportantMessagesTime);
    }

    getLastCheckedImportantMessagesTimeStamp(): OptionalType<number> {
        return this.get<number>(this.lastCheckedImportantMessagesTime);
    }
}
