import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { SubscriptionTypeModel } from '../../models/subscription-type.model';
import { SubscriptionTypeListModel } from '../../models/responses/subscription-type-list.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionTypesStore extends BaseCrudStore<SubscriptionTypeModel, SubscriptionTypeListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'subscription-types' }));
    }
}
