<form [formGroup]="formGroup" class="grid items-center grid-cols-2 ssm:grid-cols-1 ssm:grid-flow-row gap-4">
    <arc-input labelKey="Articles.Edit.ArticleNumber" [control]="formGroup.controls.articleNumber"></arc-input>
    <div></div>
    <arc-translation-input labelKey="Articles.EditFields.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it">
    </arc-translation-input>
    <div></div>
    <arc-tree-autocomplete label="ArticleGroups.ArticleGroup" [formControl]="formGroup.controls.articleGroupId"
        [treeDataSelectionConfig]="treeAutocompleteConfigs.articleGroupConfig">
    </arc-tree-autocomplete>
    <div></div>
    <arc-currency-input label="Articles.List.Price" [formControl]="formGroup.controls.price"></arc-currency-input>
    <arc-general-data-select label="Articles.EditFields.Unit" [generalDataType]="GeneralDataTypeEnum.Units"
        [formControl]="formGroup.controls.unitId">
    </arc-general-data-select>
    <div class="flex">
        <arc-currency-input label="Articles.Edit.BuyingPrice" [requiredPermission]="PermissionsEnum.BuyingPrice"
            [requiredPermissionType]="PermissionTypeEnum.Update" [formControl]="formGroup.controls.buyingPrice">
        </arc-currency-input>
        <div class="w-[15%] flex items-center justify-center bg-gray-200 rounded-r-md">
            @if (item.isBuyingPriceCalculated) {
                <arc-icon [size]="20" icon="info_outline" [matTooltip]="'Articles.Edit.CalculateBuyingPriceTooltip' | transloco" />
            } @else if (!item.articleSuppliers || item.articleSuppliers.length === 0) {
                <!-- no suppliers, cannot bind to main supplier price -->
                <arc-icon [size]="20" icon="link_off" [matTooltip]="'Articles.Edit.BuyingPriceNotLinkedTooltip' | transloco" />
            } @else if (shouldBindMainSupplierBuyingPrice()) {
                <button type="button" mat-icon-button (click)="unlinkBuyingPriceFromMainSupplier()" class="grid place-items-center"
                    [matTooltip]="'Articles.Edit.BuyingPriceLinkedTooltip' | transloco">
                    <arc-icon [size]="20" icon="link" />
                </button>
            } @else {
                <button type="button" mat-icon-button (click)="linkBuyingPriceToMainSupplier()" class="grid place-items-center"
                    [matTooltip]="'Articles.Edit.BuyingPriceNotLinkedTooltip' | transloco">
                    <arc-icon [size]="20" icon="link_off" />
                </button>
            }
        </div>
    </div>
    <arc-number-input label="Articles.EditFields.Margin" [formControl]="marginFormControl" [unit]="UnitsEnum.Percent" [decimalPlaces]="2"
        [requiredPermission]="PermissionsEnum.Margins" [requiredPermissionType]="PermissionTypeEnum.Update">
    </arc-number-input>
    <div class="flex align-baseline gap-4" arcPortalTooltip="Articles.Edit.VAT">
        <span class="mt-3">{{'Articles.Edit.VAT' | transloco}}</span>
        <arc-button-toggle-group [control]="formGroup.controls.taxId" [items]="articleTaxItems"></arc-button-toggle-group>
    </div>
</form>
