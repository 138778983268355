import { Component, OnInit } from '@angular/core';

import { ImageLoadingStateEnum } from '../../login/login-visual/ImageLoadingStateEnum';

@Component({
    selector: 'arc-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {

    didViewLoad = false;

    ImageLoadingStateEnum = ImageLoadingStateEnum;
    imageLoadingState: ImageLoadingStateEnum = this.ImageLoadingStateEnum.Loading;


    ngOnInit(): void {
        this.didViewLoad = true;
    }

}
