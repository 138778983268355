import { RouteModel } from '../../core/models/route.model';
import { TransactionsComponent } from '../views/transactions/transactions/transactions.component';
import TransactionArticlesComponent from '../views/transactions/transaction-articles/transaction-articles.component';
import { TransactionPaymentsComponent } from '../views/transactions/transaction-payments/transaction-payments.component';
import { ReportsComponent } from '../views/transactions/reports/reports.component';
import { QueryDesignerComponent } from '../views/transactions/query-designer/query-designer.component';
import { PermissionsEnum } from '../models/enums/permissions.enum';

export const transactionRoutes: RouteModel[] = [
    {
        path: 'transactions',
        component: TransactionsComponent,
        data: { subMenu: 'transactions', subMenuIndex: 0, title: 'Sales.Transactions', requiredPermission: PermissionsEnum.Transactions }
    },
    {
        path: 'transaction-articles',
        component: TransactionArticlesComponent,
        data: { subMenu: 'transactions', subMenuIndex: 1, title: 'Sales.SoldArticles', requiredPermission: PermissionsEnum.Transactions }
    },
    {
        path: 'transaction-payments',
        component: TransactionPaymentsComponent,
        data: { subMenu: 'transactions', subMenuIndex: 2, title: 'Sales.Payments', requiredPermission: PermissionsEnum.Transactions }
    },
    {
        path: 'reports',
        children: [{ path: '', component: ReportsComponent }],
        data: { subMenu: 'transactions', subMenuIndex: 3, title: 'Sales.Reports', requiredPermission: PermissionsEnum.Reporting }
    },
    {
        path: 'query-designer',
        component: QueryDesignerComponent,
        data: {
            subMenu: 'transactions',
            subMenuIndex: 4,
            title: 'Sales.QueryDesigner',
            requiredPermission: PermissionsEnum.QueryDesignerReports
        }
    },
    {
        path: 'provisions',
        redirectTo: 'reports', // TODO create component
        data: {
            subMenu: 'transactions',
            subMenuIndex: 5,
            title: 'Sales.Provisions',
            requiredPermission: PermissionsEnum.GuideProvisions,
            isDisabled: true
        }
    }
];
