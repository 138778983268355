import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayGrid, GridsterConfig, GridsterItem } from 'angular-gridster2';

import { BaseAuthComponent } from '../../../components/abstractions/base-auth.component';
import { DashboardService } from '../../../core/services/dashboard.service';
import { WidgetModel } from '../../../core/models/widget.model';
import { StatisticsWidgetModel } from '../../../core/models/statistics-widget.model';
import { WidgetTypeEnum } from '../../../core/models/enums/widget-type.enum';
import { WelcomeBarModel } from '../../../core/models/welcome-bar-model';
import { WelcomeBarService } from '../../../core/services/welcome-bar.service';

@Component({
    selector: 'arc-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseAuthComponent implements OnInit {
    welcomeBar?: WelcomeBarModel;
    companyLogoUrl?: string;
    gridsterOptions!: GridsterConfig;
    widgetTypesList: WidgetTypeEnum[] = [];
    widgets!: Array<WidgetModel>;
    isDashboardEditable = false;
    get dashboardComponentTypeEnum(): typeof WidgetTypeEnum {
        return WidgetTypeEnum;
    }

    private currentGridPosition?: GridsterItem;

    private readonly router = inject(Router);
    private readonly dashboardService = inject(DashboardService);
    private readonly welcomeBarService = inject(WelcomeBarService);

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.dashboardService.init();

        this.widgetTypesList = Object.entries(this.dashboardService.widgetsList).map(dictionaryType => dictionaryType[1].type);
        this.gridsterOptions = {
            ...this.dashboardService.gridsterOptions
        };
        const widgetsSubscription = this.dashboardService.widgets.subscribe(i => (this.widgets = i));

        this.addSubscriptions(widgetsSubscription);

        const getWelcomeBarSubscription = this.welcomeBarService.getWelcomeBarModel().subscribe(async result => {
            this.welcomeBar = await result;
        });
        this.addSubscriptions(getWelcomeBarSubscription);

        this.companyLogoUrl = this.welcomeBarService.getCompanyLogoUrl();
    }

    addWidget(widgetType: WidgetTypeEnum): void {
        this.dashboardService.addWidgetByType(widgetType, this.currentGridPosition);

        if (!this.isDashboardEditable) {
            this.dashboardService.saveCurrent();
        }
    }

    removeWidget($event: MouseEvent | TouchEvent, widget: WidgetModel): void {
        // preventDefault and stopPropagation have to be called to prevent a widget "shadow" to be left behind
        // see: https://github.com/tiberiuzuld/angular-gridster2/issues/516
        $event.preventDefault();
        $event.stopPropagation();

        this.dashboardService.removeWidget(widget);
    }

    toggleDashboardEdit(): void {
        const editable = {
            displayGrid: DisplayGrid.Always,
            draggable: {
                enabled: true
            },
            resizable: {
                enabled: true
            }
        };
        const notEditable = {
            displayGrid: DisplayGrid.None,
            draggable: {
                enabled: false
            },
            resizable: {
                enabled: false
            }
        };

        this.isDashboardEditable = !this.isDashboardEditable;
        this.dashboardService.setDashboardEditable(this.isDashboardEditable);

        this.gridsterOptions = {
            ...this.gridsterOptions,
            ...(this.isDashboardEditable ? editable : notEditable)
        };

        this.updateGridOptions();

        if (!this.isDashboardEditable) {
            this.dashboardService.saveCurrent();
        }
    }

    asStatisticsWidgetModel(model: WidgetModel): StatisticsWidgetModel {
        return model as StatisticsWidgetModel;
    }

    showUnreadMessages(): void {
        this.router.navigate(['settings/messages'], { queryParams: { IsRead: false } }).then();
    }

    private updateGridOptions(): void {
        if (!!this.gridsterOptions.api?.optionsChanged) {
            this.gridsterOptions.api.optionsChanged();
        }
    }
}
