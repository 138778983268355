import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { BranchModel } from '../../models/branch.model';
import { BranchListModel } from '../../models/responses/branches-list.model';
import { BranchEditModel } from '../../models/branch-edit.model';

@Injectable({
    providedIn: 'root'
})
export class BranchesStore extends BaseCrudStore<BranchModel, BranchListModel, BranchEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'branches' }));
    }
}
