import { Component, ElementRef, EventEmitter, HostBinding, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, switchMap, tap, distinctUntilChanged } from 'rxjs';

import { GlobalSearchResultResponseModel } from '../../../../../app/models/responses/global-search-result-response.model';
import { GlobalSearchStore } from '../../../../../app/services/stores/global-search.store';
import { DictionaryType } from '../../../../../core/models/types/dictionary.type';
import { HelpService } from '../../../../../core/services/help.service';

@Component({
    selector: 'arc-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {
    static readonly searchDebounceTimeMs = 250;

    @HostBinding('class') classList = 'grow flex px-0 ml-4 justify-start';

    @ViewChild('searchbarContainer') searchbarContainer!: ElementRef;
    @ViewChild('searchbar') searchbar!: ElementRef;

    @Output() readonly searchbarCollapsed = new EventEmitter<boolean>();

    isLoading = false;
    searchString = '';
    searchSubject = new Subject<string>();
    shouldShowResults = false;
    results: GlobalSearchResultResponseModel[] = [];
    isSearchbarCollapsed = true;
    readonly resultTypeIconMap: DictionaryType<string> = {
        'Customer': 'person',
        'Supplier': 'local_shipping',
        'HelpArticle': 'help',
        'Article': 'inventory_2',
        'Variant': 'inventory_2',
        'Tag': 'sell',
        'Report': 'description',
        'Order': 'shopping_cart',
        'Invoice': 'payments',
        'Reservation': 'assignment',
        'ArticleGroup': 'account_tree'
    };

    private readonly router = inject(Router);
    private readonly globalSearchStore = inject(GlobalSearchStore);
    private readonly helpService = inject(HelpService);

    constructor() {
        this.searchSubject.pipe(
            distinctUntilChanged(),
            tap(searchString => {
                this.isLoading = true;
                this.shouldShowResults = !!searchString;
            }),
            debounceTime(GlobalSearchComponent.searchDebounceTimeMs),
            switchMap(searchString => this.globalSearchStore.search(searchString))
        ).subscribe(result => {
            this.isLoading = false;
            this.results = result.value ?? [];
        });
    }

    handleGlobalSearch(): void {
        this.searchSubject.next(this.searchString);
    }

    closeOverlay(): void {
        this.shouldShowResults = false;
    }

    toggleSearchbarCollapse(): void {
        this.isSearchbarCollapsed = !this.isSearchbarCollapsed;
        this.searchbarCollapsed.emit(this.isSearchbarCollapsed);

        if (!this.isSearchbarCollapsed) {
            this.searchbar.nativeElement.focus();
        }
    }

    goToResult(result: GlobalSearchResultResponseModel): void {
        let url = '';

        switch (result.type) {
            case 'Report':
                url = `reports/view/${result.id}`;
                break;
            case 'Article':
                url = `articles?Id=${result.id}`;
                break;
            case 'Variant':
                url = `variations?Id=${result.id}`;
                break;
            case 'ArticleGroup':
                url = `articles?ArticleGroupId=${result.id}`;
                break;
            case 'Supplier':
                url = `articles?SupplierId=${result.id}`;
                break;
            case 'Tag':
                url = `articles?TagId=${result.id}`;
                break;
            case 'Customer':
                url = `customers?Id=${result.id}`;
                break;
            case 'Invoice':
                url = `invoices?Id=${result.id}`;
                break;
            case 'Order':
                url = `orders?Id=${result.id}`;
                break;
            case 'HelpArticle':
                this.reset();
                return this.helpService.openHelp(result.id);
            default:
                url = result.id;
                break;
        }

        this.reset();
        this.router.navigateByUrl(url).then();
    }

    private reset(): void {
        this.shouldShowResults = false;
        this.searchString = '';
        this.isSearchbarCollapsed = true;

        this.searchbarCollapsed.emit(this.isSearchbarCollapsed);
    }
}
