<!-- <img [src]="logo" width="90" height="70" /> -->
<form [formGroup]="formGroup">
    <div class="!h-[70px] !w-[90px]">
        <img [src]="logo" width="90" height="70" class="object-contain h-full" />
    </div>
    <arc-file-upload
        class="block w-full !h-[60px]"
        fileTypes=".png, .jpg, .jpeg"
        [shouldForceMinHeight]="false"
        [formControl]="fileControl"
        [allowUrlUpload]="false"
        [maxFileSizeInKb]="2048">
    </arc-file-upload>
</form>
