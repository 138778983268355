import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { UnitListModel } from '../../../models/responses/unit-list.model';
import { UnitModel } from '../../../models/unit.model';
import { UnitsStore } from '../../../services/stores/units.store';
import { UnitCardComponent } from './unit-card/unit-card.component';
import { UnitEditBaseDataComponent } from './unit-edit-items/unit-edit-base-data/unit-edit-base-data.component';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-units',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './units.component.scss'
})
export class UnitsComponent extends PageListViewComponent<UnitListModel> {
    private readonly unitsStore = inject(UnitsStore);

    constructor() {
        super('Units');

        this.config = new CardListViewConfigModel<UnitModel, UnitListModel>({
            entityName: 'Units',
            store: this.unitsStore,
            cardComponent: UnitCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => `${currentItem.title} (${currentItem.titleShort})`,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary', 'text-center'],
            editSidebarConfig: {
                editComponents: [{ titleKey: 'Units.Edit.BaseData', component: UnitEditBaseDataComponent }],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            mainButton: new MainCardButtonModel({
                text: 'Units.List.ShowArticles',
                clickFn: data => this._router.navigate(['articles'], { queryParams: { UnitId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false })
        });
    }
}
