<form [formGroup]="formGroup">
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-6">
            <arc-translation-input labelKey="Units.Edit.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
                [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en"
                [controlFr]="formGroup.controls.title_fr" [controlIt]="formGroup.controls.title_it">
            </arc-translation-input>
        </div>
        <div class="col-span-6">
            <arc-translation-input labelKey="Units.Edit.Abbreviation" translationDialogTitleKey="General.Actions.TranslateTitle"
                [controlDe]="formGroup.controls.titleShort_de" [controlEn]="formGroup.controls.titleShort_en"
                [controlFr]="formGroup.controls.titleShort_fr" [controlIt]="formGroup.controls.titleShort_it">
            </arc-translation-input>
        </div>
        <div class="col-span-12 items-center">
            {{ 'Units.Edit.EditMode' | transloco }}:
            <arc-button-toggle-group [control]="formGroup.controls.editmode" [items]="editModeItems"></arc-button-toggle-group>
        </div>
        <div class="col-span-4">
            <div class="flex flex-col gap-4">
                <arc-number-input label="Units.Edit.StandardQuantity" [formControl]="formGroup.controls.standardQuantity"
                    [decimalPlaces]="2"></arc-number-input>
                <arc-number-input label="Units.Edit.TareGrams" [formControl]="formGroup.controls.tareGrams"></arc-number-input>
            </div>
        </div>
    </div>
</form>
