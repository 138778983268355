import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { RecycleBinListModel } from '../../models/responses/recycle-bin-list.model';

@Injectable({
    providedIn: 'root'
})
export class RecycleBinStore extends BaseCrudStore<RecycleBinListModel, RecycleBinListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'recycle-bin' }));
    }
}

