<h1 mat-dialog-title>
    {{ 'Vouchers.CreateTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <div class="!flex !mt-2 items-center">
        <arc-select class="flex-grow" label="Vouchers.Create.VoucherType" [options]="types"
            [formControl]="formGroup.controls.type"></arc-select>
        <arc-number-input class="ml-4 grow-0" label="General.Number" [formControl]="formGroup.controls.quantity"
            [decimalPlaces]="0"></arc-number-input>
        <arc-button type="flat" color="accent" class="ml-4 grow-0" [isDisabled]="formGroup.invalid"
            [isLoading]="isUpdatingRows" (clicked)="updateRows()">
            {{'General.Actions.Create' | transloco}}
        </arc-button>
    </div>
    <div class="mt-6">
        <arc-editable-table [config]="vouchersTableConfig" [formControl]="vouchersFormControl">
        </arc-editable-table>
    </div>
    <div class="flex flex-col mt-auto items-end">
        <div class="flex gap-4">
            <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
            <arc-button type="flat" color="accent" [isDisabled]="!isFormSetValid" [isLoading]="isSaving"
                (clicked)="saveRecords()">
                {{ 'General.Actions.Save' | transloco }}
            </arc-button>
        </div>
    </div>
</mat-dialog-content>