import { Component, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';
import { SizeListModel } from '../../../../../models/size-list.model';
import { ColorListModel } from '../../../../../models/color-list.model';

@Component({
    selector: 'arc-article-variants',
    templateUrl: './article-variants.detail-widget.html',
    styleUrl: './article-variants.detail-widget.scss'
})
export class ArticleVariantsDetailWidget extends BaseDetailWidget<ArticleListModel, ArticleModel> implements OnInit {
    selectedSizes: SizeListModel[] = [];
    selectedColors: ColorListModel[] = [];

    ngOnInit(): void {
        this.entityModel.variants.forEach(v => {
            if (!this.selectedSizes.find(s => s.id === v.sizeId)) {
                this.selectedSizes.push({ id: v.sizeId, description: v.size });
            }

            if (!this.selectedColors.find(c => c.id === v.colorId)) {
                this.selectedColors.push({
                    id: v.colorId,
                    description: v.color,
                    colorValue: v.colorValue,
                    foreColorValue: v.foreColorValue
                });
            }
        });
    }

    getColorValue(color: ColorListModel): string {
        return !!color.colorValue ? `#${color.colorValue}` : '';
    }

    getForeColorValue(color: ColorListModel): string {
        return !!color.foreColorValue ? `#${color.foreColorValue}` : '';
    }
}
