import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { OrderModel } from '../../../../../models/order.model';
import { OrderListModel } from '../../../../../models/responses/order-list.model';

@Component({
    selector: 'arc-order-supplier-base-data',
    templateUrl: './order-supplier-base-data.detail-widget.html',
    styleUrls: ['./order-supplier-base-data.detail-widget.scss']
})
export class OrderSupplierBaseDataDetailWidget extends BaseDetailWidget<OrderListModel, OrderModel> {}
