import { DictionaryType } from '../types/dictionary.type';

export enum ComparisonOperatorsEnum {
    Empty = 0,
    NotEmpty = 1,
    False = 2,
    True = 3,
    Equal = 4,
    NotEqual = 5,
    GreaterThan = 6,
    LessThan = 7,
    GreaterThanOrEqualTo = 8,
    LessThanOrEqualTo = 9,
    Contains = 10,
    DoesNotContain = 11,
    StartsWith = 12,
    EndsWith = 13,
    Today = 14,
    ThisMonth = 15,
    ThisYear = 16,
    InRange = 17
}

const mappings: DictionaryType<ComparisonOperatorsEnum> = {
    'empty': ComparisonOperatorsEnum.Empty,
    '!empty': ComparisonOperatorsEnum.NotEmpty,
    'false': ComparisonOperatorsEnum.False,
    'true': ComparisonOperatorsEnum.True,
    '=': ComparisonOperatorsEnum.Equal,
    '!=': ComparisonOperatorsEnum.NotEqual,
    '>': ComparisonOperatorsEnum.GreaterThan,
    '<': ComparisonOperatorsEnum.LessThan,
    '>=': ComparisonOperatorsEnum.GreaterThanOrEqualTo,
    '<=': ComparisonOperatorsEnum.LessThanOrEqualTo,
    'contains': ComparisonOperatorsEnum.Contains,
    '!contains': ComparisonOperatorsEnum.DoesNotContain,
    'starts-with': ComparisonOperatorsEnum.StartsWith,
    'ends-with': ComparisonOperatorsEnum.EndsWith,
    'today': ComparisonOperatorsEnum.Today,
    'this-month': ComparisonOperatorsEnum.ThisMonth,
    'this-year': ComparisonOperatorsEnum.ThisYear,
    'in-range': ComparisonOperatorsEnum.InRange
};

export class ComparisonOperatorsEnumExtensions {
    static operatorToEnum(operator: string): ComparisonOperatorsEnum {
        return mappings[operator] ?? ComparisonOperatorsEnum.Equal;
    }

    static toText(operatorEnum: ComparisonOperatorsEnum): string {
        return Object.entries(mappings)
            .map(([key, value]) => ({ key, value }))
            .find(({ value }) => value === operatorEnum)?.key ?? '';
    }
}
