<arc-translation-input
    [labelKey]="columnModel.columnTitleKey"
    translationDialogTitleKey="General.Actions.TranslateTitle"
    [controlDe]="controlDe"
    [controlEn]="controlEn"
    [controlFr]="controlFr"
    [controlIt]="controlIt">
</arc-translation-input>
<ng-template #readonlyTpl>
    <p>Not Implemented</p>
</ng-template>
