import { Component } from '@angular/core';

import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';
import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';

@Component({
    selector: 'arc-article-pictures',
    templateUrl: './article-pictures.detail-widget.html',
    styleUrls: ['./article-pictures.detail-widget.scss']
})
export class ArticlePicturesDetailWidget extends BaseDetailWidget<ArticleListModel, ArticleModel> {}
