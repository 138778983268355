import { Component, HostBinding, OnInit, computed, inject, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslationLanguageModel } from '../../../../core/models/translation-language.model';
import { AuthService } from '../../../../core/services/auth.service';
import { LayoutService } from '../../../../core/services/layout.service';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { SettingsStorage } from '../../../../core/services/storages/settings.storage';
import { TranslationService } from '../../../../core/services/translation.service';
import { BaseComponent } from '../../../abstractions/base.component';
import { RouteService } from '../../../../core/services/route.service';
import { MainMenuItemModel } from '../../../../core/models/main-menu-item.model';
import { HelpService } from '../../../../core/services/help.service';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { TicketSummaryModel } from '../../../../app/models/ticket-summary.model';
import { RightSidebarConfigModel } from '../../../../core/models/right-sidebar-config.model';
import { TicketsComponent } from '../../../../app/components/tickets/tickets.component';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';
import { TicketStore } from '../../../../app/services/stores/ticket.store';

@Component({
    selector: 'arc-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent extends BaseComponent implements OnInit {
    @HostBinding('class') classes = 'sticky top-0 z-10';

    readonly mainNavigation = signal<MainMenuItemModel[]>([]);
    activeSubMenu = signal('');
    messagesPermission = signal<true | AuthPermissionModel>(true);

    isSearchbarCollapsed = signal(true);
    isDarkMode = signal(false);
    ticketsSummary = signal<TicketSummaryModel | undefined>(undefined);

    hasOpenTickets = computed(() => this.ticketsSummary()?.open ?? 0 > 0);
    actionNeededCount = computed(() => this.ticketsSummary()?.actionNeeded ?? 0);
    currentRotation = signal(0);

    selectedLanguage = signal<TranslationLanguageModel | undefined>(undefined);
    allowedLanguages = signal<TranslationLanguageModel[]>([]);

    private readonly router = inject(Router);
    private readonly sidebarService = inject(SidebarService);
    private readonly layoutService = inject(LayoutService);
    private readonly helpService = inject(HelpService);
    private readonly settingsStorage = inject(SettingsStorage);
    private readonly authService = inject(AuthService);
    private readonly translationService = inject(TranslationService);
    private readonly routeService = inject(RouteService);
    private readonly permissionsService = inject(PermissionService);
    private readonly ticketStore = inject(TicketStore);
    private readonly headerService = inject(SidebarHeaderService);

    constructor() {
        super();
        this.selectedLanguage.set(this.translationService.current);
        this.mainNavigation.set(this.routeService.generateMainMenu());

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const data = this.routeService.getRouteDataFromUri(event.url);
                this.activeSubMenu.set(data?.subMenu || '');
            }
        });
    }

    ngOnInit(): void {
        this.messagesPermission.set(this.permissionsService.hasPermission(PermissionsEnum.Messages));
        this.allowedLanguages.set([...this.translationService.allowedLanguages].sort((a, b) => a.name.localeCompare(b.name)));

        this.isDarkMode.set(this.settingsStorage.getDarkMode() ?? false);
        const darkModeChangedSubscription = this.layoutService.getThemeChangedObservable().subscribe(isDarkMode => {
            this.isDarkMode.set(isDarkMode);
        });

        this.ticketStore.getTicketsSummary().subscribe(summary => {
            this.ticketsSummary.set(summary.value);
        });
        this.addSubscriptions(darkModeChangedSubscription);
    }

    toggleSidebar(): void {
        const isSidebarOpen = this.sidebarService.toggleLeft();

        let rotationDegree;
        if (isSidebarOpen === false) {
            rotationDegree = this.currentRotation() - 180;
        } else if (isSidebarOpen === true) {
            rotationDegree = this.currentRotation() + 180;
        }

        if (rotationDegree !== undefined) {
            this.currentRotation.set(rotationDegree);
        }
    }

    logout(): void {
        this.authService.logout();
    }

    switchLanguage(code: string): void {
        this.translationService.switch(code);
        this.selectedLanguage.set(this.translationService.current);
    }

    toggleTheme(): void {
        this.layoutService.setDarkMode(!this.isDarkMode());
    }

    openTickets(): void {
        const config = new RightSidebarConfigModel({
            component: TicketsComponent,
            width: 'full',
            maxWidth: '800px',
            shouldCloseOnBackdropClick: true
        });
        this.sidebarService.openRight<undefined>(config);

        this.headerService.hasCloseButton.set(true);
        this.headerService.title.set(this.translationService.getText('Tickets.ComponentTitle'));
    }

    openHelp(): void {
        this.helpService.openHelp();
    }
}
