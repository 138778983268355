import { ColumnTypeEnum } from '../enums/column-type.enum';
import { BaseColumnModel } from './base-column.model';

export class TranslationStringColumnModel extends BaseColumnModel {
    controlNameDe!: string;
    controlNameEn!: string;
    controlNameFr!: string;
    controlNameIt!: string;

    constructor(init?: Partial<TranslationStringColumnModel>) {
        super(ColumnTypeEnum.TranslationText);

        if (!!init) {
            Object.assign(this, init);
        }
    }
}
