import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { PosLayoutModel } from '../../models/pos-layout.model';
import { PosLayoutListModel } from '../../models/responses/pos-layout-list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { PosLayoutCreateModel } from '../../models/requests/pos-layout-create.model';
import { PosLayoutUpdateModel } from '../../models/requests/pos-layout-update.model';

@Injectable({
    providedIn: 'root'
})
export class PosLayoutsStore extends BaseCrudStore<PosLayoutModel, PosLayoutListModel, PosLayoutCreateModel, PosLayoutUpdateModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'pos-layouts' }));
    }

    savePosLayoutGroupsOrder(posLayoutId: number, groupIdsInOrder: number[]): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${posLayoutId}/update-groups-order`), groupIdsInOrder);
    }
}
