<button type="button" (click)="openFeedbackDialog()" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    class="quick-feedback-btn text-on-warn w-[30px] aspect-square grid place-items-center">
    <arc-icon icon="thumbs_up_down"></arc-icon>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isHovering" [cdkConnectedOverlayPositions]="positions">
    <mat-button-toggle-group (mouseenter)="onMouseEnterOverlay()" (mouseleave)="onMouseLeaveOverlay()">
        <mat-button-toggle (click)="openFeedbackDialog(VoteTypeEnum.Up)" class="hover:text-success"><mat-icon>sentiment_satisfied</mat-icon></mat-button-toggle>
        <mat-button-toggle (click)="openFeedbackDialog(VoteTypeEnum.Neutral)" class="hover:text-warn"><mat-icon>sentiment_neutral</mat-icon></mat-button-toggle>
        <mat-button-toggle (click)="openFeedbackDialog(VoteTypeEnum.Down)" class="hover:text-error"><mat-icon>sentiment_dissatisfied</mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>