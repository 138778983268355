import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { SupplierListModel } from '../../../models/responses/supplier-list.model';
import { SuppliersStore } from '../../../services/stores/suppliers.store';
import { SupplierModel } from '../../../models/supplier.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CurrencyPipe } from '../../../../core/pipes/currency.pipe';
import { SupplierEditBaseDataComponent } from './supplier-edit-items/supplier-edit-base-data/supplier-edit-base-data.component';
import { SupplierEditContactComponent } from './supplier-edit-items/supplier-edit-contact/supplier-edit-contact.component';
import { SupplierEditTextComponent } from './supplier-edit-items/supplier-edit-text/supplier-edit-text.component';
import { SupplierEditDeliveryConditionsComponent } from './supplier-edit-items/supplier-edit-delivery-conditions/supplier-edit-delivery-conditions.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { SupplierBaseDataDetailWidget } from './detail-widgets/supplier-base-data/supplier-base-data.detail-widget';
import { SupplierEditRequestModel } from '../../../models/requests/supplier-edit-request.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-suppliers',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./suppliers.component.scss'],
    providers: [CurrencyPipe]
})
export class SuppliersComponent extends PageListViewComponent<SupplierListModel, SupplierModel, SupplierEditRequestModel> {
    private readonly suppliersStore = inject(SuppliersStore);
    private readonly translationService = inject(TranslationService);
    private readonly currencyPipe = inject(CurrencyPipe);
    private ordersSuffix = '';

    constructor() {
        super('Suppliers');

        this.translationService.getTextAsync('Layout.Menu.Storage.Orders').then(r => (this.ordersSuffix = r));

        this.config = new TableListViewConfigModel<SupplierListModel, SupplierModel, SupplierEditRequestModel>({
            entityName: 'Suppliers',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Suppliers.List.SupplierName',
                    propertyName: 'displayName',
                    propertyName2: 'personNumber',
                    widthPixels: 310
                }),
                email: new StringColumnModel({
                    columnTitleKey: 'General.Email',
                    propertyName: 'contactEmail',
                    widthPixels: 280
                }),
                phone: new StringColumnModel({
                    columnTitleKey: 'Customers.List.ContactPhone',
                    widthPixels: 250,
                    allowSort: false,
                    customFormatter: record => record.contactPhone || record.contactMobile
                }),
                draftOpen: new StackedColumnModel({
                    columnTitleKey: 'Suppliers.List.DraftOpen',
                    propertyName: 'draftOpenOrdersValue',
                    propertyName2: 'draftOpenOrdersQuantity',
                    widthPixels: 200,
                    sortColumn: 'draftOpenOrdersValue',
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName?.endsWith('Value')) {
                            return this.currencyPipe.transform(value);
                        }

                        return `${value} ${this.ordersSuffix}`;
                    },
                    requiredPermission: PermissionsEnum.Orders
                }),
                totalYtd: new StackedColumnModel({
                    columnTitleKey: 'Suppliers.List.TotalYTD',
                    propertyName: 'ytdOrdersValue',
                    propertyName2: 'ytdOrdersQuantity',
                    widthPixels: 200,
                    sortColumn: 'ytdOrdersValue',
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName?.endsWith('Value')) {
                            return this.currencyPipe.transform(value);
                        }

                        return `${value} ${this.ordersSuffix}`;
                    },
                    requiredPermission: PermissionsEnum.Orders
                }),
                total: new StackedColumnModel({
                    columnTitleKey: 'Suppliers.List.TotalOrders',
                    propertyName: 'ordersTotalValue',
                    propertyName2: 'ordersTotalQuantity',
                    widthPixels: 200,
                    sortColumn: 'ordersTotalValue',
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName?.endsWith('Value')) {
                            return this.currencyPipe.transform(value);
                        }

                        return `${value} ${this.ordersSuffix}`;
                    },
                    requiredPermission: PermissionsEnum.Orders
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'displayName',
                direction: SortDirectionEnum.Asc
            }),
            defaultColumnOrder: ['title', 'email', 'phone', 'draftOpen', 'totalYtd', 'total'],
            availableDetailWidgets: {
                contactData: new TableListDetailWidgetModel({
                    name: 'Suppliers.Details.ContactData.Title',
                    component: SupplierBaseDataDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['contactData'],
            store: this.suppliersStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Suppliers.Edit.BaseData', component: SupplierEditBaseDataComponent },
                    { titleKey: 'Suppliers.Edit.Contact', component: SupplierEditContactComponent },
                    { titleKey: 'Suppliers.Edit.Texts', component: SupplierEditTextComponent },
                    { titleKey: 'Suppliers.Edit.DeliveryConditions', component: SupplierEditDeliveryConditionsComponent }
                ],
                headerSubtitle: currentItem => of(currentItem.displayName)
            }
        });
    }
}
