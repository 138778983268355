import { Injectable, OnDestroy, inject } from '@angular/core';
import {
    ActivatedRoute,
    IsActiveMatchOptions,
    NavigationEnd,
    PRIMARY_OUTLET,
    Params,
    Route,
    Router,
    Event as RouterEvent
} from '@angular/router';
import { Observable, Subject, Subscription, map, of } from 'rxjs';

import { mainMenuRoutes } from '../../app/routing/main-menu';
import { routes } from '../../app/routing/app-routing.module';
import { RouteDataModel } from '../models/route-data.model';
import { OptionalType } from '../models/types/optional.type';
import { MainMenuItemModel } from '../models/main-menu-item.model';
import { SubMenuItemModel } from '../models/sub-menu-item.model';
import { PermissionService } from './permission.service';
import { FavouritesStore } from '../../app/services/stores/favourites.store';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';

@Injectable({
    providedIn: 'root'
})
export class RouteService implements OnDestroy {
    routerEvent$: Observable<RouterEvent>;
    subMenuItemsSub: Subject<SubMenuItemModel[]> = new Subject();

    private readonly labelPrefix = 'Layout.Menu';
    private readonly allRoutes: Route[];
    private readonly routerEventSub = new Subject<RouterEvent>();
    private readonly routerEventSubscription: Subscription;
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly permissionService = inject(PermissionService);
    private readonly favouritesStore = inject(FavouritesStore);

    constructor() {
        this.routerEvent$ = this.routerEventSub.asObservable();
        this.allRoutes = this.getAllRoutesWithDataRecursive();

        this.routerEventSubscription = this.router.events.subscribe(event => {
            this.routerEventSub.next(event);

            if (event instanceof NavigationEnd) {
                this.generateSubMenuForUri(event.urlAfterRedirects ?? event.url);
            }
        });
    }

    ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
    }

    getQueryParams(): Params {
        return this.activatedRoute.snapshot.queryParams;
    }

    getRouteDataFromUri(uri: string): OptionalType<RouteDataModel> {
        const tree = this.router.parseUrl(uri);
        const currentPath = tree.root.children[PRIMARY_OUTLET]?.segments.map(s => s.path).join('/');
        const currentData = this.allRoutes.find(r => r.path === currentPath);

        if (!!currentData) {
            return currentData.data as OptionalType<RouteDataModel>;
        }

        return this.allRoutes.find(
            r => r.data?.['withoutParamPath'] === this.getPathWithoutParam(currentPath || '', +(r.data?.['paramIdx'] || -1))
        )?.data as OptionalType<RouteDataModel>;
    }

    generateMainMenu(): MainMenuItemModel[] {
        return mainMenuRoutes
            .map(mainMenu => ({
                mainMenu: mainMenu,
                firstAllowedRoute: this.getFirstAvailableRouteForSubmenu(mainMenu.subMenu)
            }))
            .map(
                ({ mainMenu, firstAllowedRoute }) =>
                    new MainMenuItemModel({
                        path: firstAllowedRoute.path ?? '',
                        labelKey: `${this.labelPrefix}.${mainMenu.titleKey}`,
                        subMenu: mainMenu.subMenu
                    })
            );
    }

    isActiveRoute(path: string, customConfig?: IsActiveMatchOptions): boolean {
        return this.router.isActive(
            path,
            customConfig ?? {
                paths: 'subset',
                fragment: 'ignored',
                matrixParams: 'ignored',
                queryParams: 'ignored'
            }
        );
    }

    getFilteredRoutes(submenuName: string): SubMenuItemModel[] {
        const routesWithPermission = this.allRoutes.map(route => {
            const data = route.data as RouteDataModel;
            const permission =
                !data.requiredPermission || this.permissionService.hasPermission(data.requiredPermission, data.requiredPermissionType);

            return { route, permission };
        });

        return routesWithPermission
            .filter(r => {
                const data = r.route.data as RouteDataModel;

                return (
                    (r.permission === true || (r.permission as AuthPermissionModel).isLicensed || !data.hideIfPermissionMissing) &&
                    data.subMenu === submenuName &&
                    !!data.title
                );
            })
            .sort((a, b) => {
                const dataA = a.route.data as RouteDataModel;
                const dataB = b.route.data as RouteDataModel;

                return (dataA.subMenuIndex ?? 0) - (dataB.subMenuIndex ?? 0);
            })
            .map(r => {
                const data = r.route.data as RouteDataModel;

                return {
                    path: r.route.path!,
                    labelKey: `${this.labelPrefix}.${data.title}`,
                    isDisabled: data.isDisabled || r.permission !== true,
                    startingQueryParams: data.startingQueryParams,
                    subMenu: data.subMenu,
                    permission: r.permission
                } satisfies SubMenuItemModel;
            });
    }

    private generateSubMenuForUri(uri: string): void {
        const currentRouteData = this.getRouteDataFromUri(uri);
        const submenu = currentRouteData?.subMenu;

        if (!submenu) {
            return;
        }

        this.generateSubMenu(submenu).subscribe(result => {
            this.subMenuItemsSub.next(result);
        });
    }

    private getPathWithoutParam(path: string, paramIdx: number): string {
        const paths = path.split('/');
        paths.splice(paramIdx, 1);

        return paths.join('/');
    }

    private generateSubMenu(submenuName: string): Observable<SubMenuItemModel[]> {
        if (submenuName === 'home') {
            if (this.permissionService.hasPermission(PermissionsEnum.Favourites) !== true) {
                return of([]);
            }

            return this.favouritesStore.getAll().pipe(
                map(l => l.value ?? []),
                map(f =>
                    f.map(item => ({
                        path: item.path,
                        labelKey: item.title,
                        favouriteId: item.id,
                        subMenu: 'home',
                        permission: true
                    }))
                )
            );
        }

        return of(this.getFilteredRoutes(submenuName));
    }

    private getAllRoutesWithDataRecursive(childRoutes?: Route[], parent?: Route): Route[] {
        const allRoutes: Route[] = [];
        const localRoutes = childRoutes || routes;

        localRoutes.forEach(r => {
            const parentLength = parent?.path?.split('/').length || 0;
            const paramIdx = r.path?.split('/').findIndex(a => a.match(/:.*/)) || -1;

            if (!!parent && !!parent.path) {
                r.path = parent.path + '/' + r.path;
            }

            if (!!r.data) {
                if (paramIdx >= 0) {
                    const newPath = r.path?.replace(/\/?:.*\/?/, '/') || '';
                    r.data['paramIdx'] = paramIdx + parentLength;
                    r.data['withoutParamPath'] = newPath.endsWith('/') ? newPath.slice(0, -1) : newPath;
                }

                allRoutes.push(r);
            }

            if (!!r.children && r.children.length > 0) {
                allRoutes.push(...this.getAllRoutesWithDataRecursive(r.children, r));
            }
        });

        return allRoutes;
    }

    private getFirstAvailableRouteForSubmenu(subMenu: string): Route {
        const subMenuRoutes = this.allRoutes
            .map(r => ({ route: r, data: r.data as RouteDataModel }))
            .filter(r => (r.data as RouteDataModel).subMenu === subMenu)
            .sort((a, b) => (a.data.subMenuIndex ?? 0) - (b.data.subMenuIndex ?? 0));
        const allowedRoutes = subMenuRoutes
            .filter(r => !r.data.isDisabled)
            .filter(
                r =>
                    !r.data.requiredPermission ||
                    this.permissionService.hasPermission(r.data.requiredPermission, r.data.requiredPermissionType) === true
            )
            .map(r => r.route);

        return allowedRoutes[0] ?? subMenuRoutes[0].route;
    }
}
