<div class="w-full h-full grid justify-stretch">
    <div class="mt-4">
        <span class="text-on-app-light text-sm">
            {{'Articles.EditFields.Sizes' | transloco}}
        </span>

        <mat-chip-set>
            @for (size of selectedSizes; track $index) {
                <mat-chip>{{size.description}}</mat-chip>
            }
        </mat-chip-set>
    </div>

    <div>
        <span class="text-on-app-light text-sm">
            {{'Articles.EditFields.Colors' | transloco}}
        </span>

        <mat-chip-set>
            @for (color of selectedColors; track $index) {
                <mat-chip [style.background-color]="getColorValue(color)">
                    <span [style.color]="getForeColorValue(color)">{{color.description}}</span>
                </mat-chip>
            }
        </mat-chip-set>
    </div>
</div>
