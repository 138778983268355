<ng-container [ngTemplateOutlet]="messageTmpl" [ngTemplateOutletContext]="{message:data[currentMessageIndex]}">
</ng-container>

<ng-template #messageTmpl let-message="message">
    <mat-dialog-content class="grow !max-h-full !flex flex-col">
        <h3 class="pb-2 text-lg">
            {{ message.senderUserName }} - {{ message.createdTime | arcDate }}
        </h3>
        <h2 class="text-2xl font-semibold pt-1 pb-1">{{ message.subject }}</h2>
        <div class="message-container mt-4 border border-solid border-gray-200 p-4 rounded w-full">
            <div class="arc-markdown" [innerHTML]="message.body">

            </div>
        </div>
        <div class="grow"></div>
    </mat-dialog-content>
    <mat-dialog-actions class="important-messages-dialog--dialog-actions">
        <a *ngIf="data[currentMessageIndex].attachmentType" mat-stroked-button
            href="{{getDownloadUrl(data[currentMessageIndex].id)}}" class="mr-auto">
            <mat-icon class="mr-2"> attachment</mat-icon>
            <span>{{"Messages.DownloadAttachment" | transloco}}</span></a>
        <button mat-stroked-button mat-dialog-close>{{"Messages.ReadLater" | transloco}}</button>

        <button (click)="markMessageAsRead(data[currentMessageIndex].id)" mat-flat-button color="accent">{{
            currentMessageIndex + 1 === data.length ?
            ("Messages.Finish"
            | transloco) : ("Messages.Next" | transloco) }}</button>
    </mat-dialog-actions>
</ng-template>