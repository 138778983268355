import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';

@Component({
    selector: 'arc-settings-customize-logo',
    templateUrl: './settings-customize-logo.component.html',
    styleUrl: './settings-customize-logo.component.scss'
})
export class SettingsCustomizeLogoComponent extends BaseEditSidebarItemComponent<ConfigurationModel> implements OnInit {
    override formGroup = new FormGroup({
        logo: new ArcFormControl<string>('')
    });
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    get logo(): string {
        return this.formGroup.value.logo ? Utils.getBase64ImageSrc(this.formGroup.value.logo, 'jpeg') : '';
    }

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(file => {
            if (!!file) {
                Utils.blobToBase64(file).subscribe(resp => {
                    this.formGroup.controls.logo.setValue(resp);
                    this.fileControl.setValue(undefined);
                });
            }
        });
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { logo: this.formGroup.value.logo };
    }
}
