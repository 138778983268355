@if(!!article){
    <div (click)="selectArticle(article.id)"
        class="border border-on-app-light border-solid rounded-xl p-5 flex flex-row group hover:bg-element-hover hover:cursor-pointer">
        <arc-icon [icon]="article.helpSubTopicIcon || article.helpTopicIcon || 'help'" [size]="40" class="flex-none"></arc-icon>
        <div class="ml-5 grow">
            <h3 class="font-bold">{{article.title}}</h3>
            <span>{{article.summary}}</span>
        </div>
        <arc-icon icon="chevron_right" [size]="30" class="opacity-0 group-hover:opacity-100 duration-300 text-icon-light"></arc-icon>
    </div>
}