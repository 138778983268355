import { Component, EventEmitter, Input, OnChanges, Output, Signal, SimpleChanges, computed, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, switchMap, tap } from 'rxjs';

import { PosLayoutGroupModel } from '../../../../../models/pos-layout-group.model';
import { PosLayoutGroupCommandGridModel } from '../../../../../models/responses/pos-layout-group-command-grid.model';
import { PosLayoutEditCommandDialogComponent } from '../pos-layout-edit-command-dialog/pos-layout-edit-command-dialog.component';
import { PosLayoutGroupsStore } from '../../../../../services/stores/pos-layout-groups.store';
import { PosLayoutGroupCommandsStore } from '../../../../../services/stores/pos-layout-group-commands.store';
import { PosLayoutCreateCommandDialogData } from '../pos-layout-edit-command-dialog/models/pos-layout-create-command-dialog-data.type';
import { PosLayoutUpdateCommandDialogData } from '../pos-layout-edit-command-dialog/models/pos-layout-update-command-dialog-data.type';
import { BaseComponent } from '../../../../../../components/abstractions/base.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-pos-layout-edit-command-grid',
    templateUrl: './pos-layout-edit-command-grid.component.html',
    styleUrl: './pos-layout-edit-command-grid.component.scss'
})
export class PosLayoutEditCommandGridComponent extends BaseComponent implements OnChanges {
    @Input({ required: true }) posLayoutGroupId!: number;

    @Output() readonly swapped = new EventEmitter<void>();

    isLoading = signal(true);
    posLayoutGroup = signal<PosLayoutGroupModel>({
        id: 0,
        posLayoutId: 0,
        title: '',
        title_de: '',
        isTab: false,
        isBottomPanel: false,
        sort: 0,
        rows: 0,
        columns: 0,
        buttonSize: 0,
        jumpToMainGroup: false,
        posLayoutGroupCommands: []
    });

    buttonSizePx = 120;
    gapSizePx = 8;
    buttonDoubleWidthPx: number;

    grid: Signal<{ index: number; row: number; column: number; command?: PosLayoutGroupCommandGridModel }[][]>;
    grid1d: Signal<{ index: number; row: number; column: number; command?: PosLayoutGroupCommandGridModel }[]>;

    gridStyle = computed(() => {
        const posLayoutGroup = this.posLayoutGroup();
        return {
            'min-height': `${this.buttonSizePx}px`,
            'min-width': `${this.buttonSizePx}px`,
            'grid-template-columns': `repeat(${posLayoutGroup.columns}, ${this.buttonSizePx}px)`,
            'grid-template-rows': `repeat(${posLayoutGroup.rows}, ${this.buttonSizePx}px)`
        };
    });

    private readonly matDialog = inject(MatDialog);
    private readonly posLayoutGroupsStore = inject(PosLayoutGroupsStore);
    private readonly posLayoutGroupCommandsStore = inject(PosLayoutGroupCommandsStore);

    constructor() {
        super();
        this.buttonDoubleWidthPx = 2 * this.buttonSizePx + this.gapSizePx;

        this.grid = computed(() => {
            const group = this.posLayoutGroup();
            const result: { index: number; row: number; column: number; command?: PosLayoutGroupCommandGridModel }[][] = [];

            for (let row = 0; row < group.rows; row++) {
                result.push([]);
                for (let column = 0; column < group.columns; column++) {
                    const index = row * group.columns + column;
                    result[row].push({
                        index,
                        row,
                        column,
                        command: group.posLayoutGroupCommands.find(c => c.position === index + 1)
                    });
                }
            }

            return result;
        });

        this.grid1d = computed(() => {
            const group = this.posLayoutGroup();
            const result: { index: number; row: number; column: number; command?: PosLayoutGroupCommandGridModel }[] = [];

            for (let row = 0; row < group.rows; row++) {
                for (let column = 0; column < group.columns; column++) {
                    const index = row * group.columns + column;
                    result.push({
                        index,
                        row,
                        column,
                        command: group.posLayoutGroupCommands.find(c => c.position === index + 1)
                    });
                }
            }

            return result;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['posLayoutGroupId']) {
            this.getPosLayoutGroup().subscribe();
        }
    }

    editCommand(posLayoutGroupCommandId: number): void {
        this.openCommandEditDialog({ posLayoutGroupCommandId });
    }

    addCommand(index: number): void {
        this.openCommandEditDialog({
            posLayoutGroupId: this.posLayoutGroupId,
            position: index + 1
        });
    }

    removeCommand(command: PosLayoutGroupCommandGridModel): void {
        this.posLayoutGroupCommandsStore
            .remove(command.id)
            .pipe(switchMap(() => this.getPosLayoutGroup()))
            .subscribe();
    }

    reload(): void {
        this.getPosLayoutGroup().subscribe();
    }

    dragStart(event: DragEvent, command: PosLayoutGroupCommandGridModel): void {
        event.dataTransfer?.setData('text/plain', JSON.stringify(command));
    }

    dragOver(event: DragEvent): void {
        event.preventDefault();
        if (!event.dataTransfer) {
            return;
        }

        event.dataTransfer.dropEffect = 'move';
    }

    drop(event: DragEvent, destinationCommand: OptionalType<PosLayoutGroupCommandGridModel>, destinationPosition: number): void {
        event.preventDefault();
        if (!event.dataTransfer) {
            return;
        }

        try {
            const data = JSON.parse(event.dataTransfer.getData('text/plain')) as OptionalType<PosLayoutGroupCommandGridModel>;
            if (!data?.id || data.id === destinationCommand?.id) {
                return;
            }

            this.posLayoutGroupCommandsStore
                .swap(data.id, this.posLayoutGroupId, destinationPosition)
                .subscribe(() => this.swapped.emit());
        } catch {
            return undefined;
        }
    }

    private getPosLayoutGroup(): Observable<void> {
        this.isLoading.set(true);
        return this.posLayoutGroupsStore.get(this.posLayoutGroupId).pipe(
            tap(result => {
                this.posLayoutGroup.set(result.value!);
                this.isLoading.set(false);
            }),
            map(() => undefined)
        );
    }

    private openCommandEditDialog(data: PosLayoutCreateCommandDialogData | PosLayoutUpdateCommandDialogData): void {
        const dialogRef = this.matDialog.open(PosLayoutEditCommandDialogComponent, {
            data,
            width: '400px',
            maxHeight: '98svh',
            maxWidth: '98vw'
        });
        const dialogSub = dialogRef.afterClosed().subscribe(commandToSave => {
            if (commandToSave) {
                this.isLoading.set(true);
                this.getPosLayoutGroup().subscribe(() => this.isLoading.set(false));
            }
        });

        this.addSubscriptions(dialogSub);
    }
}
