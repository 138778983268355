import { Injectable } from '@angular/core';

import { BaseSearchStore } from '../../../core/abstractions/base-search.store';
import { PermissionModel } from '../../models/permission.model';
import { StoreConfig } from '../../../core/models/store.config';

@Injectable({
    providedIn: 'root'
})
export class PermissionsStore extends BaseSearchStore<PermissionModel, string> {
    constructor() {
        super(new StoreConfig({ baseController: 'permissions' }));
    }
}
