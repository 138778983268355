<div class="w-full flex flex-col">
    @for (item of items; track item.description; let i = $index) {
        <div class="flex" [matTooltip]="item.description" matTooltipPosition="above">
            <div class="flex basis-0 grow justify-end text-end">
                @if (item.amount < 0) {
                    <div [ngClass]="colors[i % 2 == 0 ? 0 : 1]" class="py-2 px-2" [style.width.%]="getWidthForItem(item)">
                        <p class="whitespace-nowrap">{{ item.description }}</p>
                        @if (shouldDisplayTotal) {
                            <p class="whitespace-nowrap">{{ item.amount | arcCurrency }}</p>
                        }
                        @for (voucher of item.vouchers; track voucher.id) {
                            <div>
                                <p class="text-xs">{{ 'Widgets.PaymentsBreakdown.VoucherType' | transloco }}: {{ voucher.type }}</p>
                                <p class="text-xs mt-[-5px]">{{ 'Widgets.PaymentsBreakdown.VoucherId' | transloco }}: {{ voucher.id }}</p>
                                <p class="text-xs mt-[-5px]">
                                    {{ 'Widgets.PaymentsBreakdown.Amount' | transloco }}: {{ voucher.amount | arcCurrency }}
                                </p>
                            </div>
                        }
                    </div>
                }
            </div>
            <div class="w-[2px] bg-power-search"></div>
            <div class="flex basis-0 grow justify-start">
                @if (item.amount >= 0) {
                    <div [ngClass]="colors[i % 2 == 0 ? 0 : 1]" class="py-2 px-2" [style.width.%]="getWidthForItem(item)">
                        <p class="whitespace-nowrap">{{ item.description }}</p>
                        @if (shouldDisplayTotal) {
                            <p class="whitespace-nowrap">{{ item.amount | arcCurrency }}</p>
                        }
                        @for (voucher of item.vouchers; track voucher.id) {
                            <div>
                                <p class="text-xs">{{ 'Widgets.PaymentsBreakdown.VoucherType' | transloco }}: {{ voucher.type }}</p>
                                <p class="text-xs mt-[-5px]">{{ 'Widgets.PaymentsBreakdown.VoucherId' | transloco }}: {{ voucher.id }}</p>
                                <p class="text-xs mt-[-5px]">
                                    {{ 'Widgets.PaymentsBreakdown.Amount' | transloco }}: {{ voucher.amount | arcCurrency }}
                                </p>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }
</div>
