import { Component, inject, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { VoucherListModel } from '../../../../../models/responses/voucher-list.model';
import { VoucherModel } from '../../../../../models/voucher.model';
import { Tools } from '../../../../../../core/utils/tools';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';

@Component({
    selector: 'arc-voucher-type',
    templateUrl: './voucher-type.detail-widget.html',
    styleUrls: ['./voucher-type.detail-widget.scss']
})
export class VoucherTypeDetailWidget extends BaseDetailWidget<VoucherListModel, VoucherModel> implements OnInit {
    Tools = Tools;
    allText = '';
    bonusProgramPermission: true | AuthPermissionModel;

    readonly today = new Date();

    private readonly translationService = inject(TranslationService);
    private readonly permissionService = inject(PermissionService);

    constructor() {
        super();
        this.bonusProgramPermission = this.permissionService.hasPermission(PermissionsEnum.CustomerBonusPrograms);
    }

    ngOnInit(): void {
        this.allText = this.translationService.getText('General.All');
    }

    get voucherTypeTags(): string {
        const tags = this.entityModel.type?.tags?.map(t => t.title).join(', ');
        return tags || this.allText;
    }
}
