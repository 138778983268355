import { Component, HostBinding, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-rich-text-editor-form',
    templateUrl: './rich-text-editor-form.component.html',
    styleUrls: ['./rich-text-editor-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RichTextEditorFormComponent),
            multi: true
        }
    ]
})
export class RichTextEditorFormComponent extends BaseControlValueAccessor<string> {
    @HostBinding('class') classes = 'grow';

    @Input() maxLength?: number;
}
