import { Component } from '@angular/core';

import { DefaultValuesEnum } from '../../../../../../core/models/enums/default-values.enum';
import { SettingsDefaultValuesBaseComponentComponent } from '../settings-default-values-base-component/settings-default-values-base-component.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-default-values-customers',
    templateUrl: '../settings-default-values-base-component/settings-default-values-base-component.component.html',
    styleUrl: './settings-default-values-customers.component.scss'
})
export class SettingsDefaultValuesCustomersComponent extends SettingsDefaultValuesBaseComponentComponent {
    constructor() {
        super(DefaultValuesEnum.Customers);
    }

    override onItemSet(): void {
        const data = this.item.defaultCustomers;
        this.formGroup.controls.defaultValues.setValue(data);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { defaultCustomers: this.formGroup.value.defaultValues };
    }
}
