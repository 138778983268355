<form [formGroup]="formGroup">
    @if(!!formGroup.value.profileImageBlobFileName && !!imageSrc){
        <div class="!h-[70px] !w-[90px] mb-4">
            <img [src]="profileImage" width="90" height="70" class="object-contain h-full" />
        </div>
    }
    @else if(!!formGroup.value.profileImageBlobFileName){
        <div class="flex items-center justify-center mb-4">
            <mat-spinner [diameter]="64"></mat-spinner>
        </div>
    }
    <arc-file-upload class="block w-full !h-[60px]" fileTypes=".png, .jpg, .jpeg" [shouldForceMinHeight]="false" [formControl]="fileControl"
    [allowUrlUpload]="false" [maxFileSizeInKb]="2048">
</arc-file-upload>
</form>