import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { OrderArticleListModel } from '../../../models/responses/order-article-list.model';
import { OrderArticlesStore } from '../../../services/stores/order-articles.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { OrderArticleModel } from '../../../models/order-article.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { OrderStatusEnumExtensions } from '../../../models/enums/order-status.enum';
import { OrderArticlePicturesDetailWidget } from './order-article-pictures/order-article-pictures.detail-widget';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { OrderArticleStocksDetailWidget } from './order-article-stocks/order-article-stocks.detail-widget';
import { OrderArticleDescriptionDetailWidget } from './order-article-description/order-article-description.detail-widget';
import { OrderArticleDetailsDetailWidget } from './order-article-details/order-article-details.detail-widget';

@Component({
    selector: 'arc-order-articles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./order-articles.component.scss']
})
export class OrderArticlesComponent
    extends PageListViewComponent<OrderArticleListModel, OrderArticleModel, OrderArticleModel, OrderArticleModel, string> {
    private readonly orderArticlesStore = inject(OrderArticlesStore);

    constructor() {
        super('OrderArticles');

        this.config = new TableListViewConfigModel<OrderArticleListModel, OrderArticleModel, OrderArticleModel, OrderArticleModel, string>({
            entityName: 'OrderArticles',
            availableColumns: {
                article: new StackedColumnModel({
                    columnTitleKey: 'OrderArticles.List.Article',
                    propertyName: 'articleTitle',
                    propertyName2: 'articleId',
                    widthPixels: 286
                }),
                orderNumber: new StringColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderNumber',
                    propertyName: 'orderNumber',
                    widthPixels: 126
                }),
                order: new StackedColumnModel({
                    columnTitleKey: 'OrderArticles.List.Order',
                    propertyName: 'orderSupplierDisplayName',
                    propertyName2: 'orderId',
                    widthPixels: 236
                }),
                orderStatus: new GenericStatusColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderStatus',
                    propertyName: 'orderStatus',
                    widthPixels: 126,
                    statusColumn: 'orderStatus',
                    descriptionColumn: 'orderStatusDescription',
                    bgColor: value => OrderStatusEnumExtensions.getColor(value)
                }),
                orderBranchName: new StringColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderBranch',
                    propertyName: 'orderBranchName',
                    widthPixels: 156
                }),
                orderDateSent: new DateColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderDateSent',
                    propertyName: 'orderDateSent',
                    widthPixels: 156,
                    format: 'short'
                }),
                orderDateDelivered: new DateColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderDateDelivered',
                    propertyName: 'orderDateDelivered',
                    widthPixels: 156,
                    format: 'short'
                }),
                orderDeliveryDate: new DateColumnModel({
                    columnTitleKey: 'OrderArticles.List.OrderDeliveryDate',
                    propertyName: 'orderDeliveryDate',
                    widthPixels: 156,
                    format: 'short'
                }),
                orderDeliveredQuantityOrderQuantity: new StringColumnModel({
                    columnTitleKey: 'OrderArticles.List.QuantityDeliveredQuantityOrdered',
                    propertyName: 'orderDeliveredQuantityOrderQuantity',
                    widthPixels: 175
                })
            },
            defaultColumnOrder: [
                'article',
                'orderNumber',
                'order',
                'orderStatus',
                'orderBranchName',
                'orderDateSent',
                'orderDateDelivered',
                'orderDeliveryDate',
                'orderDeliveredQuantityOrderQuantity'
            ],
            availableDetailWidgets: {
                pictures: new TableListDetailWidgetModel({
                    name: 'OrderArticles.Detail.PicturesWidget',
                    component: OrderArticlePicturesDetailWidget
                }),
                stock: new TableListDetailWidgetModel({
                    name: 'OrderArticles.Detail.StockWidget',
                    component: OrderArticleStocksDetailWidget
                }),
                description: new TableListDetailWidgetModel({
                    name: 'OrderArticles.Detail.DescriptionWidget',
                    component: OrderArticleDescriptionDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'OrderArticles.Detail.DetailsWidget',
                    component: OrderArticleDetailsDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['pictures', 'stock', 'description', 'details'],
            store: this.orderArticlesStore
        });
    }
}
