import { Component, inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { CustomerProfileModel } from '../../../../../models/customer-profile.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { CustomerBonusProgramModel } from '../../../../../models/customer-bonus-program.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { UserService } from '../../../../../../core/services/user.service';
import { AuthPermissionModel } from '../../../../../models/auth-permission.model';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';

@Component({
    selector: 'arc-customer-bonus-programs',
    templateUrl: './customer-bonus-programs.component.html',
    styleUrl: './customer-bonus-programs.component.scss'
})
export class CustomerBonusProgramsComponent extends BaseEditSidebarItemComponent<CustomerProfileModel> {
    override formGroup = new FormGroup({
        isDiscount: new ArcFormControl(true),
        cashbackInPercent: new ArcFormControl(false),
        noRemainingBonusPoints: new ArcFormControl(false),
        bonusFactor: new ArcFormControl<OptionalType<number>>(undefined),
        customerDiscounts: new ArcFormControl<CustomerBonusProgramModel[]>([]),
        customerCashbacks: new ArcFormControl<CustomerBonusProgramModel[]>([]),
        customerCashbacksInPercent: new ArcFormControl<CustomerBonusProgramModel[]>([])
    });
    discountTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    cashbackTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    cashbackInPercentTableConfig: EditableTableConfigModel<CustomerBonusProgramModel>;
    voucherPermission: true | AuthPermissionModel = true;

    readonly voucherPermissionItems: ButtonToggleModel[] = [];
    readonly translationService = inject(TranslationService);

    private readonly userService = inject(UserService);
    private readonly permissionsService = inject(PermissionService);

    constructor() {
        super();

        this.voucherPermission = this.permissionsService.hasPermission(PermissionsEnum.Vouchers);
        this.discountTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    requiredBonusPoints: new ArcFormControl(0, [this.uniqueBonusPointsValidator()]),
                    paymentVoucherTypeId: new ArcFormControl<OptionalType<number>>(undefined)
                }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'requiredBonusPoints',
                    isEditable: true,
                    unit: UnitsEnum.Points,
                    columnTitleKey: 'CustomerProfiles.EditFields.RequiredBonusPoints'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentVoucherTypeId',
                    isEditable: true,
                    widthPixels: 250,
                    columnTitleKey: 'CustomerProfiles.EditFields.Voucher',
                    generalDataType: GeneralDataTypeEnum.VoucherDiscounts
                })
            ]
        });

        this.cashbackTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    requiredBonusPoints: new ArcFormControl(0, [this.uniqueBonusPointsValidator()]),
                    bonusValue: new ArcFormControl(''),
                    paymentAccountTypeId: new ArcFormControl<OptionalType<number>>(undefined)
                }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'requiredBonusPoints',
                    isEditable: true,
                    unit: UnitsEnum.Points,
                    columnTitleKey: 'CustomerProfiles.EditFields.RequiredBonusPoints'
                }),
                new NumberColumnModel({
                    propertyName: 'bonusValue',
                    isEditable: true,
                    decimalPlaces: 2,
                    unit: this.userService.getUserInfo()?.currencyIsoCode,
                    columnTitleKey: 'CustomerProfiles.EditFields.BonusValue'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentAccountTypeId',
                    isEditable: true,
                    columnTitleKey: 'CustomerProfiles.EditFields.PayoutOn',
                    generalDataType: GeneralDataTypeEnum.AccountTypesForCustomerProfile,
                    widthPixels: 350
                })
            ]
        });

        this.cashbackInPercentTableConfig = new EditableTableConfigModel<CustomerBonusProgramModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    bonusValue: new ArcFormControl(''),
                    paymentAccountTypeId: new ArcFormControl<OptionalType<number>>(undefined)
                }),
            rowHeightPx: 63,
            columns: [
                new NumberColumnModel({
                    propertyName: 'bonusValue',
                    isEditable: true,
                    unit: UnitsEnum.Percent,
                    decimalPlaces: 2,
                    columnTitleKey: 'CustomerProfiles.EditFields.PercentOfBalance'
                }),
                new GeneralDataColumnModel({
                    propertyName: 'paymentAccountTypeId',
                    isEditable: true,
                    columnTitleKey: 'CustomerProfiles.EditFields.PayoutOn',
                    generalDataType: GeneralDataTypeEnum.AccountTypesForCustomerProfile,
                    widthPixels: 350
                })
            ]
        });

        if (this.voucherPermission === true) {
            this.voucherPermissionItems = [{ label: this.translationService.getText('CustomerProfiles.Edit.Discount'), value: true }];
        }
        this.voucherPermissionItems.push({ label: this.translationService.getText('CustomerProfiles.Edit.Cashback'), value: false });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);

        const customerDiscounts = this.item.customerBonusPrograms.filter(c => !!c.paymentVoucherTypeId);
        const customerCashbacks = this.item.customerBonusPrograms.filter(c => !c.paymentVoucherTypeId && c.requiredBonusPoints);
        const customerCashbacksInPercent = this.item.customerBonusPrograms.filter(c => !c.paymentVoucherTypeId && !c.requiredBonusPoints);

        this.formGroup.patchValue({ customerDiscounts, customerCashbacks, customerCashbacksInPercent });

        if (customerCashbacks.length > 0) {
            this.formGroup.controls.isDiscount.setValue(false);
        }

        if (customerCashbacksInPercent.length > 0) {
            this.formGroup.controls.isDiscount.setValue(false);
            this.formGroup.controls.cashbackInPercent.setValue(true);
        }
    }

    override prepareSaveModel(): Partial<CustomerProfileModel> {
        const value = this.formGroup.value;

        let customerBonusPrograms: OptionalType<CustomerBonusProgramModel[]>;
        if (value.isDiscount) {
            customerBonusPrograms = value.customerDiscounts;
        } else {
            if (value.cashbackInPercent) {
                customerBonusPrograms = value.customerCashbacksInPercent?.map(cashback => ({
                    ...cashback,
                    bonusValue: cashback?.bonusValue?.toString()
                }));
            } else {
                customerBonusPrograms = value.customerCashbacks?.map(cashback => ({
                    ...cashback,
                    bonusValue: cashback?.bonusValue?.toString()
                }));
            }
        }

        return {
            noRemainingBonusPoints: value.noRemainingBonusPoints,
            bonusFactor: value.bonusFactor,
            customerBonusPrograms
        };
    }

    uniqueBonusPointsValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.pristine) {
                // eslint-disable-next-line no-null/no-null
                return null;
            }
            const newBonusPointValue = control.value;
            const isExistsInDiscount =
                this.formGroup.value.isDiscount &&
                this.formGroup.value.customerDiscounts?.find(
                    d => d.requiredBonusPoints === newBonusPointValue && d.id !== control.parent?.value.id
                );
            const isExistsInCashback =
                !this.formGroup.value.isDiscount &&
                !this.formGroup.value.cashbackInPercent &&
                this.formGroup.value.customerCashbacks?.find(
                    c => c.requiredBonusPoints === newBonusPointValue && c.id !== control.parent?.value.id
                );

            if (isExistsInCashback || isExistsInDiscount) {
                return { uniqueBonusPoints: true };
            }

            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    override onBrokenRulesLoad(): string[] {
        const mappedBrokenRuleIds: string[] = [];
        mappedBrokenRuleIds.push(...this.discountTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));
        mappedBrokenRuleIds.push(...this.cashbackTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));
        mappedBrokenRuleIds.push(...this.cashbackInPercentTableConfig.setBrokenRulesOnFormGroups(this.brokenRules));

        return mappedBrokenRuleIds;
    }
}
