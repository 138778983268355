import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { InventoryListModel } from '../../../../../models/responses/inventory-list.model';
import { InventoryStatusEnum } from '../../../../../models/enums/inventory-status.enum';

@Component({
    selector: 'arc-inventory-status-column',
    templateUrl: './inventory-status-column.component.html',
    styleUrl: './inventory-status-column.component.scss'
})
export class InventoryStatusColumnComponent extends BaseCustomColumnComponent<InventoryListModel> {
    get colorClasses(): string[] {
        const green = [InventoryStatusEnum.Open];
        const grey = [InventoryStatusEnum.Finished];

        if (green.includes(this.item.status)) {
            return ['bg-success', 'text-on-success'];
        } else if (grey.includes(this.item.status)) {
            return ['bg-element-disabled', 'text-on-element-disabled'];
        }

        return [];
    }

    get status(): string {
        return InventoryStatusEnum[this.item.status];
    }
}
