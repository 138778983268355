import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { PermissionService } from '../services/permission.service';

@Pipe({ name: 'arcPermission' })
export class PermissionPipe implements PipeTransform {

    private readonly permissionService = inject(PermissionService);

    transform(permission?: true | AuthPermissionModel): Observable<string> {
        if (!permission || permission === true) {
            return of('');
        }

        return this.permissionService.getTooltipTextObs(permission);
    }
}
