import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';
import { DataSelectionDialogDataModel } from '../../../dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { DataSelectionDialogCustomDataModel } from '../../../dialogs/data-selection-dialog/models/data-selection-dialog-custom-data.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { ColumnFilterModel } from '../../../../core/models/column-filter.model';

export class QuickSearchConfigModel<TList extends Identifyable<TId>, TId = number> {
    store!: BaseSearchStore<TList, TId>;
    displayFn!: (data?: TList) => string;
    shouldHideDataSelectDialogButton?: boolean;
    dataSelectionDialogConfig?: DataSelectionDialogDataModel | DataSelectionDialogCustomDataModel<TList, TId>;
    sortings?: ColumnSortModel[] = [];
    filters?: ColumnFilterModel[] = [];

    constructor(init: QuickSearchConfigModel<TList, TId>) {
        Object.assign(this, init);
    }
}
