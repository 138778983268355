export class RelatedEntityUpdateModel<TId = number> {
    addedIds: TId[] = [];
    removedIds: TId[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    removeAll = false;

    constructor(init?: Partial<RelatedEntityUpdateModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }

    hasChanges(): boolean {
        return this.removeAll || this.removedIds.length > 0 || this.addedIds.length > 0;
    }
}
