import { Component, ViewChild, effect, inject, signal, untracked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';

import { PosLayoutGroupCommandModel } from '../../../../../models/pos-layout-group-command.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PosCommandsStore } from '../../../../../services/stores/pos-commands.store';
import { DynamicFormField } from '../../../../../../components/dynamic-form/models/dynamic-form-field';
import { Tools } from '../../../../../../core/utils/tools';
import { DynamicFormComponent } from '../../../../../../components/dynamic-form/dynamic-form.component';
import { PosLayoutCreateCommandDialogData } from './models/pos-layout-create-command-dialog-data.type';
import { PosLayoutUpdateCommandDialogData } from './models/pos-layout-update-command-dialog-data.type';
import { PosLayoutGroupCommandsStore } from '../../../../../services/stores/pos-layout-group-commands.store';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';

@Component({
    selector: 'arc-pos-layout-edit-command-dialog',
    templateUrl: './pos-layout-edit-command-dialog.component.html',
    styleUrl: './pos-layout-edit-command-dialog.component.scss'
})
export class PosLayoutEditCommandDialogComponent {
    @ViewChild('dynamicForm') parametersForm?: DynamicFormComponent;

    GeneralDataTypeEnum = GeneralDataTypeEnum;

    isAdd: boolean;
    isLoading = signal(false);
    isLoadingCommandType = signal(false);
    hasParameters = signal(false);
    parameterFormFields = signal<DynamicFormField[]>([]);
    isSaving = signal(false);

    commandImageFile = signal<OptionalType<File>>(undefined);

    existingCommand?: PosLayoutGroupCommandModel;

    formGroup = new FormGroup({
        posCommandId: new ArcFormControl<number>(undefined, Validators.required),
        title_de: new ArcFormControl<string>(''),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        commandParameter: new ArcFormControl<OptionalType<{ [key: string]: any }>>(undefined),
        image: new ArcFormControl<OptionalType<string>>(undefined),
        backgroundColor: new ArcFormControl<OptionalType<string>>(undefined, CustomValidators.hexColor()),
        foreColor: new ArcFormControl<OptionalType<string>>(undefined, CustomValidators.hexColor()),
        requiredRoleId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    dialogData: PosLayoutCreateCommandDialogData | PosLayoutUpdateCommandDialogData = inject(MAT_DIALOG_DATA);

    posCommandsStore = inject(PosCommandsStore);
    posLayoutGroupCommandsStore = inject(PosLayoutGroupCommandsStore);
    matDialogRef = inject(MatDialogRef);

    constructor() {
        this.isAdd = !('posLayoutGroupCommandId' in this.dialogData);
        if ('posLayoutGroupCommandId' in this.dialogData) {
            this.isLoading.set(true);
            this.posLayoutGroupCommandsStore.get(this.dialogData.posLayoutGroupCommandId!).subscribe(result => {
                this.existingCommand = result.value;
                if (result.value) {
                    this.formGroup.patchValue(result.value);

                    if (result.value.image) {
                        const buffer = Tools.Utils.base64ToByteArray(result.value.image);
                        // no need to trigger effect, formgroup already has correct value
                        untracked(() => {
                            this.commandImageFile.set(new File([buffer], ''));
                        });
                    }
                }

                this.isLoading.set(false);
            });
        }

        this.formGroup.controls.posCommandId.valueChanges.subscribe(commandId => {
            this.commandTypeChanged(commandId);
        });

        effect(async () => {
            const file = this.commandImageFile();

            if (!file) {
                this.formGroup.patchValue({ image: undefined });
                return;
            }

            Tools.Utils.arrayBufferToBase64(file.arrayBuffer()).subscribe(base64 => {
                this.formGroup.patchValue({ image: base64 });
            });
        });
    }

    commandTypeChanged(posCommandId?: number): void {
        if (!posCommandId) {
            return;
        }

        this.isLoadingCommandType.set(true);

        this.posCommandsStore.getListModel(posCommandId).subscribe(result => {
            const posCommand = result.value;
            if (!posCommand) {
                this.formGroup.patchValue({ posCommandId: undefined });
                this.isLoadingCommandType.set(false);
                return;
            }

            this.hasParameters.set(posCommand.hasParameter);
            if (posCommand.hasParameter) {
                this.posCommandsStore.getCommandParameters(posCommandId).subscribe(paramsResult => {
                    const formFields = paramsResult.value ?? [];

                    // TODO refactor dynamic form to be controlvalueaccessor
                    formFields.forEach(field => {
                        const value = this.formGroup.value.commandParameter?.[Tools.Utils.lowerCaseFirstLetter(field.key)];
                        field.value = value;
                    });

                    this.parameterFormFields.set(formFields);
                    this.isLoadingCommandType.set(false);
                });
            } else {
                this.isLoadingCommandType.set(false);
            }
        });
    }

    async save(): Promise<void> {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        if (!!this.parametersForm) {
            await this.parametersForm.submitForm();
            return;
        }

        this.onParametersFormSubmitted(undefined);
    }

    onParametersFormSubmitted(values: OptionalType<object>): void {
        this.formGroup.patchValue({ commandParameter: values });

        if ('posLayoutGroupCommandId' in this.dialogData) {
            this.posLayoutGroupCommandsStore
                .edit({
                    id: this.dialogData.posLayoutGroupCommandId!,
                    posLayoutGroupId: this.existingCommand!.posLayoutGroupId,
                    position: this.existingCommand!.position,
                    ...this.formGroup.getRawValue()
                })
                .subscribe(result => this.matDialogRef.close(!!result.value));
        } else {
            this.posLayoutGroupCommandsStore
                .add({
                    id: 0,
                    posLayoutGroupId: this.dialogData.posLayoutGroupId!,
                    position: this.dialogData.position!,
                    ...this.formGroup.getRawValue()
                })
                .subscribe(result => this.matDialogRef.close(!!result.value));
        }
    }
}
