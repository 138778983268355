import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ComplexDataTypesEnum } from '../../../core/models/complex-data-types.enum';
import { DataSelectionDialogComponent } from '../../../components/dialogs/data-selection-dialog/data-selection-dialog.component';
import { DataSelectionDialogDataModel } from '../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-data.model';
import { CustomerListModel } from '../../models/responses/customer-list.model';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { ToasterService } from '../../../core/services/toaster.service';
import { OptionalType } from '../../../core/models/types/optional.type';
import { ArticleListModel } from '../../models/responses/article-list.model';

@Component({
    selector: 'arc-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss']
})
export class ControlsComponent {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    value?: any;
    errors?: any;

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    form = new FormGroup({
        input: new ArcFormControl<string>('', Validators.required),
        select: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        autocomplete: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        datepicker: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        dateRangeStart: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        dateRangeEnd: new ArcFormControl<OptionalType<Date>>(undefined, Validators.required),
        checkbox: new ArcFormControl<OptionalType<boolean>>(false),
        article: new ArcFormControl<OptionalType<ArticleListModel>>(undefined, Validators.required),
        country: new ArcFormControl<string>('', Validators.required),
        file: new ArcFormControl<OptionalType<File>>(undefined, Validators.required)
    });

    selectOptions = [
        { value: 1, label: 'Option 1' },
        { value: 2, label: 'Option 2' },
        { value: 3, label: 'Option 3' },
        { value: 4, label: 'Option 4' }
    ];

    autocompleteOptions = [
        { value: 1, label: 'Cat' },
        { value: 2, label: 'Dog' },
        { value: 3, label: 'Pig' },
        { value: 4, label: 'Cow' }
    ];

    selectedCustomers: CustomerListModel[] = [];

    private readonly matDialog = inject(MatDialog);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        this.form.valueChanges.subscribe(value => {
            this.value = JSON.stringify(value, undefined, ' ');
            this.errors = JSON.stringify(this.form.errors, undefined, ' ');
        });
    }

    submit(): void {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

        if (this.form.valid) {
            window.location.reload();
        } else {
            this.toasterService.showWarning('Form not valid!', undefined, true);
        }
    }

    toggleDisabled(): void {
        if (this.form.disabled) {
            this.form.enable();
        } else {
            this.form.disable();
        }
    }

    get formValue(): string {
        return JSON.stringify(this.form.value, undefined, ' ');
    }

    openCustomerMultiSelect(): void {
        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: new DataSelectionDialogDataModel({
                type: ComplexDataTypesEnum.Customer,
                isMultiSelect: true
            }),
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.selectedCustomers = result ?? [];
        });
    }
}
