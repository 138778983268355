import { EditSidebarLayoutSettingsModel } from '../../components/sidebar-components/edit-sidebar/models/edit-sidebar-layout-settings.model';
import { DictionaryType } from '../../core/models/types/dictionary.type';

export class LayoutSettingsModel {
    /**
     * Stores the order and collapsed state of all "edit items" (expandable containers with edit fields) for all crud pages
     */
    editSidebars: DictionaryType<EditSidebarLayoutSettingsModel> = {};
    isLeftSidebarOpenDesktop = true;

    constructor(init?: Partial<LayoutSettingsModel>) {
        Object.assign(this, init);
    }
}
