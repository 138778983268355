import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ArticleModel } from '../../models/article.model';
import { ArticleListModel } from '../../models/responses/article-list.model';
import { ArticleEditModel } from '../../models/requests/article-edit.model';
import { RelatedEntityRequestModel } from '../../models/requests/tag-articles-request.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { SearchResponseModel } from '../../models/responses/search-response.model';

@Injectable({
    providedIn: 'root'
})
export class ArticlesStore extends BaseCrudStore<ArticleModel, ArticleListModel, ArticleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'articles/without-mainarticles' }));
    }

    getArticlesByInventoryId(
        inventoryId: number,
        requestModel: RelatedEntityRequestModel
    ): Observable<ApiResponseModel<SearchResponseModel<ArticleListModel>>> {
        return this._requestService.makePost<ApiResponseModel<SearchResponseModel<ArticleListModel>>>(
            this.getUrl(`inventories/${inventoryId}`),
            requestModel
        );
    }

    getArticlesByTagId(
        tagId: number,
        requestModel: RelatedEntityRequestModel
    ): Observable<ApiResponseModel<SearchResponseModel<ArticleListModel>>> {
        return this._requestService.makePost<ApiResponseModel<SearchResponseModel<ArticleListModel>>>(
            this.getUrl(`tags/${tagId}`),
            requestModel
        );
    }

    getArticlesBySaleId(
        saleId: number,
        requestModel: RelatedEntityRequestModel
    ): Observable<ApiResponseModel<SearchResponseModel<ArticleListModel>>> {
        return this._requestService.makePost<ApiResponseModel<SearchResponseModel<ArticleListModel>>>(
            this.getUrl(`sales/${saleId}`),
            requestModel
        );
    }
}
