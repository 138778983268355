import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BonusRunModel } from '../../models/bonus-run.model';
import { BonusRunListModel } from '../../models/responses/bonus-run-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';

@Injectable({
    providedIn: 'root'
})
export class BonusRunsStore extends BaseCrudStore<BonusRunModel, BonusRunListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'bonus-runs' }));
    }

    setup(bonusRunId?: number, customerProfileId?: number, perDate?: Date): Observable<ApiResponseModel<BonusRunModel>> {
        return this._requestService.makePost<ApiResponseModel<BonusRunModel>>(
            this.getUrl('setup'),
            { bonusRunId, customerProfileId, perDate }
        );
    }

    execute(bonusRunId: number, selectedCustomers: number[]): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(
            this.getUrl(`execute/${bonusRunId}`),
            selectedCustomers
        );
    }
}
