<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-button-toggle-group [control]="formGroup.controls.reportType"
        [items]="reportTypeItems()"></arc-button-toggle-group>

        <arc-translation-input labelKey="PosReports.EditFields.Title" translationDialogTitleKey="General.Actions.TranslateTitle"
            [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en"
            [controlFr]="formGroup.controls.title_fr" [controlIt]="formGroup.controls.title_it">
        </arc-translation-input>

    <arc-number-input label="PosReports.EditFields.NumberOfCopies"
        [formControl]="formGroup.controls.copies"></arc-number-input>

    <ng-container *ngIf="formGroup.controls.reportType?.value === PosReportTypeEnum.Receipt">
        <h2>{{'PosReports.Edit.PrintConditions' | transloco }}</h2>
        <arc-checkbox labelKey="PosReports.EditFields.AutoPrint"
            [control]="formGroup.controls.autoPrint"></arc-checkbox>
        <arc-checkbox labelKey="PosReports.EditFields.PrintPerArticle"
            [control]="formGroup.controls.printPerArticle"></arc-checkbox>
            <h2>{{'PosReports.Edit.ForArticlesWithTags' | transloco }}</h2>
        <arc-chip-selector label="Articles.EditFields.ArticleTags" [formControl]="formGroup.controls.tags"
            [uniqueIdFn]="getTagId" [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)">
        </arc-chip-selector>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
            [formControl]="formGroup.controls.transactionTypeId"
            label="PosReports.EditFields.TransactionType"></arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.PaymentTypes"
            [formControl]="formGroup.controls.paymentTypeId"
            label="PosReports.EditFields.PaymentType"></arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.VoucherTypes"
            [formControl]="formGroup.controls.voucherTypeId"
            label="PosReports.EditFields.VoucherType"
            [requiredPermission]="PermissionsEnum.VoucherTypes"></arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches"
            [formControl]="formGroup.controls.branchId" label="PosReports.EditFields.Branch"
            [requiredPermission]="PermissionsEnum.Branches"></arc-general-data-select>

        <p>{{'PosReports.EditFields.PaymentDebitHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentDebit" [items]="paymentDebitItems"></arc-button-toggle-group>

        <p>{{'PosReports.EditFields.PaymentRequiresSignatureHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.paymentRequiresSignature" [items]="paymentRequiresSignatureItems"></arc-button-toggle-group>

        <p>{{'PosReports.EditFields.IsWarrantyHeader' | transloco}}</p>
        <arc-button-toggle-group [control]="formGroup.controls.isWarranty" [items]="isWarrantyItems"></arc-button-toggle-group>
    </ng-container>

    <ng-container *ngIf="formGroup.controls.reportType?.value === PosReportTypeEnum.Order">
        <h2>{{'PosReports.Edit.PrintConditions' | transloco }}</h2>
        <arc-chip-selector label="Articles.EditFields.ArticleTags" [formControl]="formGroup.controls.tags"
            [uniqueIdFn]="getTagId" [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)">
        </arc-chip-selector>
        <arc-select [formControl]="formGroup.controls.resourceGroupName" [options]="resourceGroupNameOptions"
            label="PosReports.EditFields.Resource"></arc-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.TransactionTypes"
            [formControl]="formGroup.controls.transactionTypeId"
            label="PosReports.EditFields.TransactionType"></arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Branches"
            [formControl]="formGroup.controls.branchId" label="PosReports.EditFields.Branch"></arc-general-data-select>
    </ng-container>

    <arc-file-upload *ngIf="!item.isTemplate" [arcErrorTooltip]="formGroup.controls.reportBlobData" [formControl]="formGroup.controls.fileControl" fileTypes=".rdlx">
    </arc-file-upload>
</form>
