import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseStore } from '../../../core/abstractions/base.store';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { TicketListModel } from '../../models/responses/ticket-list.model';
import { TicketModel } from '../../models/ticket.model';
import { TicketCreateRequestModel } from '../../models/requests/ticket-create-request.model';
import { TicketCreateResponseModel } from '../../models/responses/ticket-create-response.model';
import { TicketCreateModel } from '../../models/requests/ticket-create.model';
import { TicketCreateMessageModel } from '../../models/ticket-create-message.model';
import { TicketSummaryModel } from '../../models/ticket-summary.model';

@Injectable({
    providedIn: 'root'
})
export class TicketStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'portal/tickets' }));
    }

    getTickets(searchText = ''): Observable<ApiResponseModel<TicketListModel[]>> {
        const url = this.getUrl('');

        return this._requestService.makeGet<ApiResponseModel<TicketListModel[]>>(
            url,
            undefined,
            false,
            undefined,
            false,
            undefined,
            new KeyValueModel({ key: 'searchText', value: searchText })
        );
    }

    getTicket(ticketId: number): Observable<ApiResponseModel<TicketModel>> {
        const url = this.getUrl('');

        return this._requestService.makeGet<ApiResponseModel<TicketModel>>(url, ticketId.toString());
    }

    createTicket(requestId: string, requestModel: TicketCreateModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${requestId}/create`), requestModel);
    }

    resolveRequest(requestId: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${requestId}/resolve`));
    }

    createTicketRequest(requestModel: TicketCreateRequestModel): Observable<ApiResponseModel<TicketCreateResponseModel>> {
        return this._requestService.makePost<ApiResponseModel<TicketCreateResponseModel>>(this.getUrl('request'), requestModel);
    }

    addMessage(ticketId: number, requestModel: TicketCreateMessageModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`${ticketId}/messages`), requestModel);
    }

    resolveTicket(ticketId: number, message?: TicketCreateMessageModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePut<ApiResponseModel<boolean>>(this.getUrl(`${ticketId}/resolve`), message);
    }

    getTicketsSummary(): Observable<ApiResponseModel<TicketSummaryModel>> {
        return this._requestService.makeGet<ApiResponseModel<TicketSummaryModel>>(this.getUrl('summary'));
    }

    getOpenTickets(): Observable<ApiResponseModel<TicketListModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<TicketListModel[]>>(this.getUrl('open'));
    }
}
