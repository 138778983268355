import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleListModel } from '../../../../../models/responses/article-list.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-details',
    templateUrl: './article-details.detail-widget.html',
    styleUrls: ['./article-details.detail-widget.scss']
})
export class ArticleDetailsDetailWidget extends BaseDetailWidget<ArticleListModel, ArticleModel> {}
