import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { DefaultValuesEnum } from '../../../../../../core/models/enums/default-values.enum';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { DefaultValueModel } from '../../../../../models/default-value.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';

@Directive()
export abstract class SettingsDefaultValuesBaseComponentComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        defaultValues: new ArcFormControl<DefaultValueModel[]>([])
    });
    controllerName: DefaultValuesEnum;
    tableConfig: EditableTableConfigModel<DefaultValueModel>;

    protected constructor(controllerName: DefaultValuesEnum) {
        super();

        this.controllerName = controllerName;
        this.tableConfig = new EditableTableConfigModel<DefaultValueModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    fieldName: new ArcFormControl(''),
                    value: new ArcFormControl(''),
                    controllerName: new ArcFormControl(this.controllerName)
                }),
            rowHeightPx: 63,
            columns: [
                new StringColumnModel({
                    propertyName: 'fieldName',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.DefaultValues.DefaultValues.FieldName'
                }),
                new StringColumnModel({
                    propertyName: 'value',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.DefaultValues.DefaultValues.Value'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.tableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }
}
