<ng-container [ngSwitch]="item.status">
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.Ok">
        <div class="flex gap-1">
            <span class="text-success">
                {{ 'General.Alert.Ok' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.infoMessage" icon="info_outline" [matTooltip]="item.infoMessage">
            </arc-icon>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.PartialPayment">
        <div class="flex gap-1">
            <span class="text-warn">
                {{ 'Components.CamtFileUpload.PartialPayment' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.warningMessage" icon="info_outline" [matTooltip]="item.warningMessage">
            </arc-icon>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.Warning">
        <div class="flex gap-1">
            <span class="text-warn">
                {{ 'General.Alert.Warning' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.warningMessage" icon="info_outline" [matTooltip]="item.warningMessage">
            </arc-icon>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.Overpaid">
        <div class="flex gap-1">
            <span class="text-warn">
                {{ 'Components.CamtFileUpload.OverPaid' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.warningMessage" icon="info_outline" [matTooltip]="item.warningMessage">
            </arc-icon>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.AlreadyPaid">
        <div class="flex gap-1">
            <span class="text-error">
                {{ 'Components.CamtFileUpload.AlreadyPaid' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.errorMessage" icon="help_outline" [matTooltip]="item.errorMessage">
            </arc-icon>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CamtInvoiceStatusEnum.Error">
        <div class="flex gap-1">
            <span class="text-error">
                {{ 'General.Alert.Error' | transloco}}
            </span>
            <arc-icon *ngIf="!!item.errorMessage" icon="help_outline" [matTooltip]="item.errorMessage">
            </arc-icon>
        </div>
    </ng-container>
</ng-container>