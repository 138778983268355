import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { SaleListModel } from '../../models/responses/sale-list.model';
import { SaleModel } from '../../models/sale.model';

@Injectable({
    providedIn: 'root'
})
export class SalesStore extends BaseCrudStore<SaleModel, SaleListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'sales' }));
    }
}
