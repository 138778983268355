import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { TagModel } from '../../models/tag.model';
import { TagListModel } from '../../models/responses/tag-list.model';

@Injectable({
    providedIn: 'root'
})
export class TagsStore extends BaseCrudStore<TagModel, TagListModel, TagModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'tags' }));
    }
}
