<form [formGroup]="formGroup" class="grid items-center grid-cols-2 gap-4">
    <arc-input labelKey="CustomerProfiles.EditFields.Title" [control]="formGroup.controls.title"></arc-input>
    <arc-checkbox labelKey="CustomerProfiles.EditFields.IsDefault" [control]="formGroup.controls.isDefault"></arc-checkbox>
    <arc-general-data-select
        [generalDataType]="GeneralDataTypeEnum.PriceLevels"
        [formControl]="formGroup.controls.priceLevel"
        label="CustomerProfiles.EditFields.PriceLevel"></arc-general-data-select>
    <div></div>
    <arc-number-input label="CustomerProfiles.EditFields.AccountMinAmount" [formControl]="formGroup.controls.accountMinAmount"
        [unit]="currencyCode" [decimalPlaces]="2">
    </arc-number-input>
</form>
