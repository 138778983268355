import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { InvoiceModel } from '../../models/invoice.model';
import { InvoiceListModel } from '../../models/responses/invoice-list.model';
import { InvoiceCreateModel } from '../../models/requests/invoice-create.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { InvoiceSuggestionModel } from '../../models/responses/invoice-suggestion.model';
import { SimpleTransactionModel } from '../../models/simple-transaction.model';
import { CamtFilePreviewModel } from '../../models/camt-file-preview.model';
import { UploadFileRequestModel } from '../../models/requests/upload-file-request.model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesStore extends BaseCrudStore<InvoiceModel, InvoiceListModel, InvoiceCreateModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'invoices' }));
    }

    getInvoiceSuggestions(issuedDate: Date): Observable<ApiResponseModel<InvoiceSuggestionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<InvoiceSuggestionModel[]>>(
            this.getUrl('suggestions'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'issuedDate', value: issuedDate.toISOString() }
        );
    }

    getTransactions(id: number): Observable<ApiResponseModel<SimpleTransactionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SimpleTransactionModel[]>>(this.getUrl(`${id}/transactions`));
    }

    getCamtFilePreview(uploadFileRequest: UploadFileRequestModel): Observable<ApiResponseModel<CamtFilePreviewModel[]>> {
        return this._requestService.makePost<ApiResponseModel<CamtFilePreviewModel[]>>(
            this.getUrl('upload-camt-file-preview'),
            uploadFileRequest
        );
    }

    uploadCamtFile(uploadFileRequest: UploadFileRequestModel): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl('upload-camt-file'), uploadFileRequest);
    }
}
