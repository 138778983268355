<div class="flex flex-col gap-4">
    <arc-input class="grow" [control]="formGroup.controls.extText" labelKey="ExtAccountingExport.EditFields.ExtText"></arc-input>
    <div class="flex gap-8 mt-4">
        <div class="flex flex-col gap-4 w-[30%]">
            <span>{{'ExtAccountingExport.EditFields.Debit' | transloco}}</span>
            <arc-input [control]="formGroup.controls.extDebitAccount" labelKey="ExtAccountingExport.EditFields.Account"></arc-input>
            <arc-input [control]="formGroup.controls.extDebitCC" labelKey="ExtAccountingExport.EditFields.CostCenter"></arc-input>
            <arc-input class="mt-4" [control]="formGroup.controls.extTaxCode" labelKey="ExtAccountingExport.EditFields.ExtTaxCode"></arc-input>
        </div>
        <div class="flex flex-col gap-4 w-[30%]">
            <span>{{'ExtAccountingExport.EditFields.Credit' | transloco}}</span>
            <arc-input class="grow-0" [control]="formGroup.controls.extCreditAccount" labelKey="ExtAccountingExport.EditFields.Account"></arc-input>
            <arc-input class="grow-0" [control]="formGroup.controls.extCreditCC" labelKey="ExtAccountingExport.EditFields.CostCenter"></arc-input>
        </div>
        <div class="flex items-center">
            <arc-checkbox class="mt-4" labelKey="ExtAccountingExport.EditFields.InvertAmount" [control]="formGroup.controls.invertAmount"></arc-checkbox>
        </div>
    </div>
</div>
