import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TransactionArticleListModel } from '../../../models/responses/transaction-article-list.model';
import { TransactionArticleModel } from '../../../models/transaction-article.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { TransactionArticlesStore } from '../../../services/stores/transaction-articles.store';
import { DateService } from '../../../../core/services/date.service';
import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { TransactionArticlePicturesDetailWidget } from './transaction-articles-detail-widgets/transaction-article-pictures/transaction-article-pictures.detail-widget';
import { TransactionArticleDescriptionDetailWidget } from './transaction-articles-detail-widgets/transaction-article-description/transaction-article-description.detail-widget';
import { TransactionArticleCustomerDetailWidget } from './transaction-articles-detail-widgets/transaction-article-customer/transaction-article-customer.detail-widget';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { TransactionArticleDetailWidget } from './transaction-articles-detail-widgets/transaction-article/transaction-article.detail-widget';

@Component({
    selector: 'arc-transaction-articles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./transaction-articles.component.scss']
})
export default class TransactionArticlesComponent extends PageListViewComponent<TransactionArticleListModel, TransactionArticleModel> {
    private readonly transactionArticlesStore = inject(TransactionArticlesStore);
    private readonly dateService = inject(DateService);

    constructor() {
        super('TransactionArticles');
        this.config = new TableListViewConfigModel<TransactionArticleListModel, TransactionArticleModel>({
            entityName: 'TransactionArticles',
            availableColumns: {
                transactionArticle: new StackedColumnModel({
                    columnTitleKey: 'TransactionArticles.List.TransactionArticle',
                    propertyName: 'title',
                    propertyName2: 'transactionNumber',
                    widthPixels: 236
                }),
                transactionType: new StringColumnModel({
                    columnTitleKey: 'TransactionArticles.List.TransactionType',
                    propertyName: 'transactionTypeTitle',
                    widthPixels: 156
                }),
                time: new StackedColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Time',
                    propertyName: 'saleTime',
                    propertyName2: 'saleDate',
                    widthPixels: 126,
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName === 'saleDay') {
                            return record.displaySaleDay ? this.dateService.format(value) : '';
                        } else {
                            return this.dateService.format(value, 'short').replace(',', '');
                        }
                    }
                }),
                posName: new ColoredStackedColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Pos',
                    propertyName: 'regionAndBranch',
                    propertyName2: 'posName',
                    color: 'posColor',
                    widthPixels: 156
                }),
                quantity: new StringColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Quantity',
                    propertyName: 'quantity',
                    widthPixels: 96
                }),
                buyingPrice: new CurrencyColumnModel({
                    columnTitleKey: 'TransactionArticles.List.BuyingPrice',
                    propertyName: 'buyingPrice',
                    widthPixels: 126,
                    requiredPermission: PermissionsEnum.BuyingPrice
                }),
                unitPrice: new CurrencyColumnModel({
                    columnTitleKey: 'TransactionArticles.List.UnitPrice',
                    propertyName: 'unitPrice',
                    widthPixels: 126
                }),
                discount: new CurrencyColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Discount',
                    propertyName: 'discount',
                    widthPixels: 96
                }),
                price: new CurrencyColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Price',
                    propertyName: 'price',
                    widthPixels: 126
                }),
                user: new StringColumnModel({
                    columnTitleKey: 'TransactionArticles.List.User',
                    propertyName: 'user',
                    widthPixels: 126
                }),
                customer: new StringColumnModel({
                    columnTitleKey: 'TransactionArticles.List.Customer',
                    propertyName: 'customer',
                    widthPixels: 236
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'saleTime',
                direction: SortDirectionEnum.Desc
            }),
            defaultColumnOrder: [
                'transactionArticle',
                'transactionType',
                'time',
                'posName',
                'quantity',
                'unitPrice',
                'discount',
                'price',
                'user',
                'customer'
            ],
            availableDetailWidgets: {
                pictures: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.PicturesWidget',
                    component: TransactionArticlePicturesDetailWidget
                }),
                description: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.DescriptionWidget',
                    component: TransactionArticleDescriptionDetailWidget
                }),
                customer: new TableListDetailWidgetModel({
                    name: 'Transactions.Details.CustomerWidget',
                    component: TransactionArticleCustomerDetailWidget,
                    requiredPermission: PermissionsEnum.Customers
                }),
                transactionArticle: new TableListDetailWidgetModel({
                    name: 'TransactionArticles.Details.TransactionArticleWidget',
                    component: TransactionArticleDetailWidget,
                    shouldRemovePadding: true
                })
            },
            defaultDetailWidgetOrder: ['pictures', 'description', 'customer', 'transactionArticle'],
            store: this.transactionArticlesStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasEdit: false, hasDelete: false })
        });
    }
}
