import { RouteModel } from '../../core/models/route.model';
import { ArticlesComponent } from '../views/articles/articles/articles.component';
import { ArticleVariantsComponent } from '../views/articles/article-variants/article-variants.component';
import { VouchersTypesComponent } from '../views/articles/vouchers-types/vouchers-types.component';
import { TagsComponent } from '../views/articles/tags/tags.component';
import { SalesComponent } from '../views/articles/sales/sales.component';
import { VouchersComponent } from '../views/articles/vouchers/vouchers.component';
import { SubscriptionTypesComponent } from '../views/articles/subscription-types/subscription-types.component';
import { SubscriptionsComponent } from '../views/articles/subscriptions/subscriptions.component';
import { PermissionsEnum } from '../models/enums/permissions.enum';

export const articlesRoutes: RouteModel[] = [
    {
        path: 'articles',
        component: ArticlesComponent,
        data: { subMenu: 'articles', subMenuIndex: 0, title: 'Articles.Articles', requiredPermission: PermissionsEnum.Articles }
    },
    {
        path: 'variations',
        component: ArticleVariantsComponent,
        data: {
            subMenu: 'articles',
            subMenuIndex: 1,
            title: 'Articles.Variants'
        }
    },
    {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        data: {
            subMenu: 'articles',
            subMenuIndex: 2,
            title: 'Articles.Subscriptions',
            requiredPermission: PermissionsEnum.SubscriptionTypes,
            startingQueryParams: { ShowOnlyValidOnes: 'true' }
        }
    },
    {
        path: 'subscription-types',
        component: SubscriptionTypesComponent,
        data: {
            subMenu: 'articles',
            subMenuIndex: 2,
            title: 'Articles.SubscriptionTypes',
            requiredPermission: PermissionsEnum.SubscriptionTypes
        }
    },
    {
        path: 'sales',
        component: SalesComponent,
        data: {
            subMenu: 'articles',
            subMenuIndex: 3,
            title: 'Articles.Sales',
            requiredPermission: PermissionsEnum.Sales,
            startingQueryParams: { ShowPastSales: 'false' }
        }
    },
    {
        path: 'vouchers',
        component: VouchersComponent,
        data: { subMenu: 'articles', subMenuIndex: 4, title: 'Articles.Vouchers', requiredPermission: PermissionsEnum.Vouchers }
    },
    {
        path: 'vouchers-types',
        component: VouchersTypesComponent,
        data: { subMenu: 'articles', subMenuIndex: 5, title: 'Articles.VouchersTypes', requiredPermission: PermissionsEnum.VoucherTypes }
    },
    {
        path: 'tags',
        component: TagsComponent,
        data: { subMenu: 'articles', subMenuIndex: 6, title: 'Articles.Tags', requiredPermission: PermissionsEnum.Tags }
    }
];
