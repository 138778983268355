<form [formGroup]="formGroup" (ngSubmit)="submitForm()" [class]="classLayout">
    @for (item of items; track item.key) {
        @switch (item.field.type) {
            @case (DynamicFormControlTypeEnum.Text) {
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" [type]="'text'"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''">
                </arc-input>
            }
            @case (DynamicFormControlTypeEnum.Number) {
                <arc-number-input class="mb-4" [formControl]="item.control | formControl" [decimalPlaces]="0"
                    [placeholder]="item.field.label" [label]="item.field.label" [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-number-input>
            }
            @case (DynamicFormControlTypeEnum.Decimal) {
                <arc-number-input class="mb-4" [formControl]="item.control | formControl" [decimalPlaces]="2"
                    [placeholder]="item.field.label" [label]="item.field.label" [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-number-input>
            }
            @case (DynamicFormControlTypeEnum.Checkbox) {
                <arc-checkbox [control]="item.control" [placeholder]="item.field.label"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''">
                </arc-checkbox>
            }
            @case (DynamicFormControlTypeEnum.Label) {
                <div class="ml-4 mb-4">
                    <span>{{ item.field.isLabelTranslated ? item.field.label : (item.field.label | transloco) }}</span>
                </div>
            }            
            @case (DynamicFormControlTypeEnum.UnorderedList) {
                <div class="ml-4">
                    <span>{{ item.field.isLabelTranslated ? item.field.label : (item.field.label | transloco) }}</span>
                    <ul class="list-disc ml-8">
                        @for (option of item.field.value; track option) {
                            <li>{{ option }}</li>
                        }
                    </ul>
                </div>
            }
            @case (DynamicFormControlTypeEnum.FileUpload) {
                <arc-file-upload class="block w-full" [ngClass]="item.field.fileUploadConfig!.customStyleClasses"
                    [fileTypes]="item.field.fileUploadConfig!.acceptedFileTypes" [formControl]="item.control | formControl"
                    [shouldForceMinHeight]="item.field.fileUploadConfig!.shouldForceMinHeight"
                    [allowUrlUpload]="item.field.fileUploadConfig!.allowUrlUpload" [shouldUseCompactStyle]="true"
                    [maxFileSizeInKb]="item.field.fileUploadConfig!.maxFileSizeInKb">
                </arc-file-upload>
            }
            @case (DynamicFormControlTypeEnum.Date) {
                <div class="mb-4">
                    <arc-datepicker [control]="item.control" [label]="item.field.isLabelTranslated ? item.field.label : ''"
                        [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''">
                    </arc-datepicker>
                </div>
            }
            @case (DynamicFormControlTypeEnum.DateRange) {
                <div class="mb-4">
                    <arc-date-range-picker [control]="(item.control | transform : asFormGroup).controls['FromDate']"
                        [controlEnd]="(item.control | transform : asFormGroup).controls['ToDate']"
                        [label]="item.field.isLabelTranslated ? item.field.label : ''"
                        [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''">
                    </arc-date-range-picker>
                </div>
            }
            @case (DynamicFormControlTypeEnum.ButtonToggle) {
                <div class="mb-4 px-4">
                    <section>
                        @if (!!item.field.label) {
                            <span class="mr-2">{{ item.field.label }}</span>
                        }
                        
                        <arc-button-toggle-group [control]="item.control | formControl" [items]="item.field.selectOptions!"></arc-button-toggle-group>
                    </section>
                </div>
            }
            @case (DynamicFormControlTypeEnum.Select) {
                <arc-select class="mb-4" [formControl]="item.control | formControl" [options]="item.field.selectOptions!"
                    [isMultiselect]="item.field.isMultiselect" [isLabelTranslated]="item.field.isLabelTranslated"
                    [label]="item.field.label">
                </arc-select>
            }
            @case (DynamicFormControlTypeEnum.GeneralData) {
                <arc-general-data-select class="mb-4" [formControl]="item.control | formControl" [isMultiselect]="item.field.isMultiselect"
                    [generalDataType]="item.field.generalDataType!" [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-general-data-select>
            }
            @case (DynamicFormControlTypeEnum.Article) {
                <arc-quick-search class="mb-4" [config]="ComplexDataTypesEnum.Article | arcQuickSearchConfig"
                    [formControl]="item.control | formControl" [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-quick-search>
            }
            @case (DynamicFormControlTypeEnum.Customers) {
                <arc-quick-search class="mb-4" [config]="ComplexDataTypesEnum.Customer | arcQuickSearchConfig"
                    [formControl]="item.control | formControl" [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-quick-search>
            }
            @case (DynamicFormControlTypeEnum.Suppliers) {
                <arc-quick-search class="mb-4" [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig"
                    [formControl]="item.control | formControl" [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-quick-search>
            }
            @case (DynamicFormControlTypeEnum.BulkSender) {
                <arc-bulk-send-editor [formControl]="item.control | formControl" [options]="getSortedOptions(item.field.options ?? [])"
                    [additionalData]="item.field.additionalData">
                </arc-bulk-send-editor>
            }
            @case (DynamicFormControlTypeEnum.Link) {
                <div class="flex items-center">
                    <div>
                        <a href="{{item.field.value}}" target="_blank" class="cursor-pointer underline">
                            {{ item.field.isLabelTranslated ? item.field.label : (item.field.label | transloco) }}
                        </a>
                    </div>
                    <div class="ml-[2px]">
                        <a class="cursor-pointer" [cdkCopyToClipboard]="item.field.value">
                            <mat-icon>content_copy</mat-icon>
                        </a>
                    </div>
                </div>
            }
            @case (DynamicFormControlTypeEnum.ArticleGroup) {
                <arc-tree-autocomplete class="mb-4" [formControl]="item.control | formControl"
                    [treeDataSelectionConfig]="getArticleGroupConfig(item.field)" [label]="item.field.label"
                    [isLabelTranslated]="item.field.isLabelTranslated">
                </arc-tree-autocomplete>
            }
            @default {
                <arc-input class="mb-4" [control]="item.control" [placeholder]="item.field.label" [type]="'text'"
                    [label]="item.field.isLabelTranslated ? item.field.label : ''"
                    [labelKey]="!item.field.isLabelTranslated ? item.field.label : ''">
                </arc-input>
            }
        }
    }
</form>
