import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { FeatureModel } from '../../models/feature.model';
import { VoteTypeEnum } from '../../models/enums/vote-type.enum';
import { LicensePurchaseResponseModel } from '../../models/responses/license-purchase-response.model';
import { LicensePurchaseRequestModel } from '../../models/license-purchase-request.model';
import { ContractModel } from '../../models/contract.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { OptionalType } from '../../../core/models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class PortalStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'portal' }));
    }

    getFeatures(): Observable<ApiResponseModel<FeatureModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<FeatureModel[]>>(this.getUrl('features'));
    }

    getFeatureById(id: string): Observable<ApiResponseModel<FeatureModel>> {
        return this._requestService.makeGet<ApiResponseModel<FeatureModel>>(this.getUrl('features'), id);
    }

    voteForFeature(id: string, vote: VoteTypeEnum, comment?: string): Observable<ApiResponseModel<boolean>> {
        const requestPayload: { key: string; value: string }[] = [{ key: 'vote', value: vote.toString() }];

        // Check if the comment parameter is provided and not null
        if (comment && comment.trim() !== '') {
            requestPayload.push({ key: 'comment', value: comment });
        }
        return this._requestService.makePost<ApiResponseModel<boolean>>(
            this.getUrl(`features/${id}/vote`),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            ...requestPayload
        );
    }

    postFeedback(formData: FormData): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makeFilePost<ApiResponseModel<boolean>>(this.getUrl('feedback'), formData);
    }

    getTooltip(key: string): Observable<OptionalType<ApiResponseModel<string>>> {
        return this._requestService.makeGet<OptionalType<ApiResponseModel<string>>>(this.getUrl('tooltips'), key);
    }

    getLicensingOptions(moduleId: string): Observable<ApiResponseModel<LicensePurchaseResponseModel[]>> {
        return this._requestService.makePost<ApiResponseModel<LicensePurchaseResponseModel[]>>(this.getUrl(`module-bundles/${moduleId}`));
    }

    purchaseLicense(request: LicensePurchaseRequestModel): Observable<ApiResponseModel<any>> {
        return this._requestService.makePost<ApiResponseModel<any>>(this.getUrl('module-bundles/purchase'), request);
    }

    upgradeProduct(productId: number): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`product/${productId}/upgrade`));
    }

    getContracts(code: string, email: string): Observable<ApiResponseModel<ContractModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ContractModel[]>>(this.getUrl(`contracts/${code}/${email}`));
    }

    signContracts(code: string, email: string): Observable<ApiResponseModel<boolean>> {
        return this._requestService.makePost<ApiResponseModel<boolean>>(this.getUrl(`contracts/${code}/${email}/sign`));
    }

    getHelpTopics(): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl('help-topics'));
    }
}
