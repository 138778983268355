import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { InvoiceListModel } from '../../../../../models/responses/invoice-list.model';
import { InvoiceModel } from '../../../../../models/invoice.model';

@Component({
    selector: 'arc-invoice-remarks',
    templateUrl: './invoice-remarks.detail-widget.html',
    styleUrls: ['./invoice-remarks.detail-widget.scss']
})
export class InvoiceRemarksDetailWidget extends BaseDetailWidget<InvoiceListModel, InvoiceModel> {}
