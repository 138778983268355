<div class="h-full">
    <header *ngIf="subscriptions.length > 0" class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToSubscriptionsPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        <div *ngIf="isLoading; else contentTpl" class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <ng-template #contentTpl>
            <ng-container *ngIf="subscriptions.length > 0; else noRecordsTpl">
                <div class="grid grid-cols-3 gap-4">
                    <div *ngFor="let sub of subscriptions" class="p-2 bg-on-app text-app">
                        <div class="mb-[-10px] text-xs">{{sub.id}}</div>
                        <div class="text-xs">{{sub.title}}</div>
                        <div class="float-right">
                            <span *ngIf="!!sub.subscriptionQuantity else validTpl" class="text-xl">
                                {{sub.remainingQuantity}}/{{sub.subscriptionQuantity}}
                            </span>
                            <ng-template #validTpl>
                                <span class="text-xl">{{dateService.format(sub.validThru)}}</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noRecordsTpl>
                <div class="absolute inset-0 grid place-items-center">
                    {{ 'Customers.Details.NoSubscriptions' | transloco }}
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
