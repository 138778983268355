import { Component, HostBinding } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { UnitListModel } from '../../../../models/responses/unit-list.model';

@Component({
    selector: 'arc-unit-card',
    templateUrl: './unit-card.component.html',
    styleUrl: './unit-card.component.scss'
})
export class UnitCardComponent extends BaseCardComponent<UnitListModel> {
    @HostBinding('class') classList = 'h-full flex flex-col';
}
