import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionPaymentListModel } from '../../../../../models/responses/transaction-payment-list.model';
import { TransactionPaymentModel } from '../../../../../models/responses/transaction-payment.model';

@Component({
    selector: 'arc-transaction-payment-customer',
    templateUrl: './transaction-payment-customer.detail-widget.html',
    styleUrls: ['./transaction-payment-customer.detail-widget.scss']
})
export class TransactionPaymentCustomerDetailWidget extends BaseDetailWidget<TransactionPaymentListModel, TransactionPaymentModel> {}
