<h1 mat-dialog-title class="!px-7">{{'Widgets.Statistics.Filter.Filter' | transloco}}</h1>
<mat-dialog-content class="flex-1 overflow-auto !p-7">
    <arc-dynamic-form #dynamicForm *ngIf="formFields.length > 0" [formFields]="formFields" (formSubmitted)="onFormSubmitted($event)"></arc-dynamic-form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
        {{'General.Actions.Cancel' | transloco}}
    </button>
    <button mat-flat-button (click)="onUpdateSettings()" color="accent">
        {{'General.Actions.Save' | transloco}}
    </button>
</mat-dialog-actions>