import { Component, Injector, Signal, WritableSignal, computed, effect, inject, signal, untracked } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import Big from 'big.js';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { TreeAutocompleteConfigsService } from '../../../../../services/tree-autocomplete-configs.service';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { ArticleModel } from '../../../../../models/article.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';
import { Tools } from '../../../../../../core/utils/tools';
import { UserService } from '../../../../../../core/services/user.service';
import { ArticleEditSuppliersComponent } from '../article-edit-suppliers/article-edit-suppliers.component';
import { ArticleSupplierEditModel } from '../../../../../models/requests/article-supplier-edit.model';
import { ArticleTaxModel } from '../../../../../models/article-tax.model';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { PermissionService } from '../../../../../../core/services/permission.service';

@Component({
    selector: 'arc-article-edit-base-data',
    templateUrl: './article-edit-base-data.component.html',
    styleUrls: ['./article-edit-base-data.component.scss']
})
export class ArticleEditBaseDataComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    static readonly buyingPriceSignal = 'buyingPrice';
    static readonly wasBuyingPriceChangedByUserSignal = 'wasBuyingPriceChangedByUser';
    static readonly buyingPriceInputValueSignal = 'buyingPriceInputValue';
    static readonly isBuyingPriceLinkedSignal = 'isBuyingPriceLinked';
    static readonly taxRateSignal = 'taxRate';
    static readonly priceSignal = 'price';
    static readonly wasPriceChangedByUserSignal = 'wasPriceChangedByUser';
    static readonly debounceTime = 500;

    override formGroup = new FormGroup({
        articleNumber: new ArcFormControl('', Validators.required),
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        price: new ArcFormControl(0, Validators.required),
        buyingPrice: new ArcFormControl(0, Validators.required),
        articleGroupId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        taxId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        unitId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    PermissionsEnum = PermissionsEnum;
    PermissionTypeEnum = PermissionTypeEnum;
    UnitsEnum = UnitsEnum;
    articleTaxes: ArticleTaxModel[] = [];
    articleTaxItems: ButtonToggleModel<number>[] = [];

    readonly mainSupplier: Signal<OptionalType<ArticleSupplierEditModel>>;
    readonly isBuyingPriceLinked: WritableSignal<boolean>;
    readonly buyingPriceSuffix: Signal<string>;
    readonly hasBuyingPriceEditPermission: boolean;

    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    readonly marginFormControl = new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ max: 100 }));

    private previousMainSupplier: OptionalType<ArticleSupplierEditModel>;

    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly userService = inject(UserService);
    private readonly injector = inject(Injector);
    private readonly translationService = inject(TranslationService);
    private readonly permissionService = inject(PermissionService);

    private readonly buyingPriceInputValue: WritableSignal<OptionalType<number>>;
    private readonly buyingPrice: Signal<OptionalType<number>>;
    private readonly isBuyingPriceExclVat: Signal<boolean>;
    private readonly taxRate: WritableSignal<number>;
    private readonly price: WritableSignal<number>;
    private readonly wasPriceChangedByUser: WritableSignal<boolean>;
    private readonly wasBuyingPriceChangedByUser: WritableSignal<boolean>;

    private readonly currencyIsoCode = signal<string>('');
    private readonly buyingPriceExclusiveSuffix = signal<string>('');
    private readonly buyingPriceInclusiveSuffix = signal<string>('');

    constructor() {
        super();

        this.hasBuyingPriceEditPermission = this.permissionService.hasPermission(
            PermissionsEnum.BuyingPrice,
            PermissionTypeEnum.Update
        ) === true;

        this.buyingPriceInputValue = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.buyingPriceInputValueSignal);
        this.wasPriceChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditBaseDataComponent.wasPriceChangedByUserSignal,
            false
        );
        this.wasBuyingPriceChangedByUser = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditBaseDataComponent.wasBuyingPriceChangedByUserSignal,
            false
        );
        this.isBuyingPriceLinked = this.sharedDataService.getOrCreateSignalWithValue(
            ArticleEditBaseDataComponent.isBuyingPriceLinkedSignal,
            false
        );
        this.taxRate = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditBaseDataComponent.taxRateSignal, 0);
        this.price = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditBaseDataComponent.priceSignal, 0);
        this.mainSupplier = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.mainSupplierSignal);

        // calculate buying price exclusive vat
        this.buyingPrice = computed(() => {
            const isBuyingPriceLinked = this.isBuyingPriceLinked();
            const buyingPriceInputValue = this.buyingPriceInputValue();
            const mainSupplier = this.mainSupplier();
            const taxRate = this.taxRate();

            // buying price is calculated in backend, cannot be changed in frontend
            if (this.item.isBuyingPriceCalculated) {
                return this.item.buyingPrice;
            }

            // if value is 0 or undefined, return same value
            if (!buyingPriceInputValue) {
                return buyingPriceInputValue;
            }

            // if buying price is not linked to main supplier, it's always exclusive vat
            if (!isBuyingPriceLinked) {
                return buyingPriceInputValue;
            }

            // buying price is linked to main supplier
            return mainSupplier?.buyingPriceExclusive ? buyingPriceInputValue : this.getPriceExclVat(buyingPriceInputValue, taxRate);
        });

        // update shared data signal
        effect(() => {
            const buyingPrice = this.buyingPrice();
            untracked(() => this.sharedDataService.setOrUpdateSignal(ArticleEditBaseDataComponent.buyingPriceSignal, buyingPrice));
        });

        this.isBuyingPriceExclVat = computed(() => {
            const mainSupplier = this.mainSupplier();
            const isBuyingPriceLinked = this.isBuyingPriceLinked();

            // if not linked, buying price is always exclusive
            if (this.item.isBuyingPriceCalculated || !isBuyingPriceLinked || !mainSupplier) {
                return true;
            }

            return mainSupplier.buyingPriceExclusive;
        });

        const priceChangedSub = this.formGroup.controls.price.valueChanges.subscribe(p => {
            this.wasPriceChangedByUser.set(this.formGroup.controls.price.dirty);
            this.price.set(p ?? 0);
        });

        const buyingPriceChangedSub = this.formGroup.controls.buyingPrice.valueChanges.subscribe(bp => {
            this.wasBuyingPriceChangedByUser.set(this.formGroup.controls.buyingPrice.dirty);
            this.buyingPriceInputValue.set(bp);
        });

        const marginChangedSub = this.marginFormControl.valueChanges.subscribe(m => {
            this.setPriceFromMargin(m ?? 0);
        });

        const taxIdChangedSub = this.formGroup.controls.taxId.valueChanges.subscribe(taxId => {
            this.taxRate.set(this.getTaxRateById(taxId));
        });

        const textsSub1 = this.translationService
            .getTextObservable('Articles.EditFields.BuyingPriceExclusiveSuffix')
            .subscribe(this.buyingPriceExclusiveSuffix.set);
        const textsSub2 = this.translationService
            .getTextObservable('Articles.EditFields.BuyingPriceInclusiveSuffix')
            .subscribe(this.buyingPriceInclusiveSuffix.set);
        this.currencyIsoCode.set(this.userService.getUserInfo()?.currencyIsoCode ?? 'CHF');

        this.addSubscriptions(priceChangedSub, buyingPriceChangedSub, marginChangedSub, taxIdChangedSub, textsSub1, textsSub2);

        this.buyingPriceSuffix = computed(() => {
            const currency = this.currencyIsoCode();
            const buyingPriceExclusiveSuffix = this.buyingPriceExclusiveSuffix();
            const buyingPriceInclusiveSuffix = this.buyingPriceInclusiveSuffix();

            return this.isBuyingPriceExclVat() ? `${currency} ${buyingPriceExclusiveSuffix}` : `${currency} ${buyingPriceInclusiveSuffix}`;
        });

        // effect to update the margin when values change
        effect(() => {
            const buyingPriceIncl = this.getPriceInclVat(this.buyingPrice() ?? 0, this.taxRate());
            const price = this.price();

            if (price <= 0) {
                this.marginFormControl.setValue(0, { emitEvent: false });
                return;
            }

            if (buyingPriceIncl === 0) {
                this.marginFormControl.setValue(100, { emitEvent: false });
                return;
            }

            // margin = (1 - buyingPriceIncl / price) * 100
            const newMargin = Big(1).minus(Big(buyingPriceIncl).div(price)).times(100).toNumber();

            this.marginFormControl.setValue(newMargin, { emitEvent: false });
        });

        // effect that runs when main supplier changes
        effect(() => {
            const mainSupplier = this.mainSupplier();

            // update linked status when main supplier was changed (new id)
            untracked(() => {
                if (!this.hasBuyingPriceEditPermission || !mainSupplier || this.item.isBuyingPriceCalculated) {
                    this.unlinkBuyingPriceFromMainSupplier();
                    return;
                }

                if (mainSupplier.id === this.previousMainSupplier?.id) {
                    // if main supplier was not changed, link status remains the same
                    return;
                }

                const mainSupplierBuyingPriceExclusiveVat = mainSupplier.buyingPriceExclusive
                    ? mainSupplier.buyingPrice
                    : this.getPriceExclVat(mainSupplier.buyingPrice, this.taxRate());

                if (this.buyingPrice() === mainSupplierBuyingPriceExclusiveVat) {
                    this.linkBuyingPriceToMainSupplier();
                } else {
                    this.unlinkBuyingPriceFromMainSupplier();
                }
            });

            this.previousMainSupplier = mainSupplier;
        });
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);

        // set signals initial values
        this.buyingPriceInputValue.set(this.item.buyingPrice);
        this.price.set(this.item.price);

        if (this.item.isBuyingPriceCalculated) {
            // buying price cannot be changed manually
            this.formGroup.controls.buyingPrice.disable();
        } else {
            // keep buying price linked to main supplier, if set
            effect(
                () => {
                    const shouldBindMainSupplierBuyingPrice = this.isBuyingPriceLinked();
                    const mainSupplier = this.mainSupplier();

                    if (!shouldBindMainSupplierBuyingPrice || !mainSupplier) {
                        return;
                    }

                    if (mainSupplier.buyingPrice !== this.formGroup.getRawValue().buyingPrice) {
                        this.formGroup.controls.buyingPrice.setValue(mainSupplier.buyingPrice);
                    }
                },
                { injector: this.injector }
            );
        }

        // get tax rates
        this.generalDataStore.getGeneralData(GeneralDataTypeEnum.ArticleTaxes).subscribe(result => {
            const taxes = (result.value ?? []).map(tax => ({ key: +tax.key!, value: +tax.value! })).sort((t1, t2) => t1.value - t2.value);
            for (const tax of taxes) {
                const articleTax = {
                    id: tax.key,
                    taxRate: tax.value,
                    title: `${Tools.Utils.roundTo(tax.value * 100, 0.1)}%`
                };
                this.articleTaxes.push(articleTax);
                this.articleTaxItems.push({ value: articleTax.id, label: articleTax.title });
            }

            if (this.item.taxId !== undefined && !this.articleTaxes.find(t => t.id?.toString() === this.item.taxId.toString())) {
                // currently set tax id does not exist
                this.formGroup.patchValue({ taxId: undefined });
            } else {
                this.taxRate.set(this.getTaxRateById(this.item.taxId));
            }
        });
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        const value = this.formGroup.getRawValue();

        return {
            articleNumber: value.articleNumber,
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            price: value.price,
            buyingPrice: this.buyingPrice(),
            articleGroupId: value.articleGroupId,
            taxId: value.taxId,
            unitId: value.unitId
        };
    }

    setPriceFromMargin(newMargin: number): void {
        if (newMargin === 100) {
            // do not trigger margin calculation
            this.formGroup.controls.price.setValue(0, { emitEvent: false });
            this.price.set(0);
            return;
        }

        const buyingPriceIncl = this.getPriceInclVat(this.buyingPrice() ?? 0, this.taxRate());
        // price = buyingPriceIncl / (1 - newMargin / 100)
        const newPrice = Big(buyingPriceIncl)
            .div(Big(1).minus(Big(newMargin).div(100)))
            .toNumber();
        const newPriceRounded = Tools.Utils.roundTo(newPrice, this.userService.defaultRoundTo);

        this.formGroup.controls.price.setValue(newPriceRounded, { emitEvent: false });
        this.price.set(newPriceRounded);
    }

    unlinkBuyingPriceFromMainSupplier(): void {
        this.isBuyingPriceLinked.set(false);
    }

    linkBuyingPriceToMainSupplier(): void {
        this.isBuyingPriceLinked.set(true);
        this.formGroup.controls.buyingPrice.setValue(this.mainSupplier()?.buyingPrice ?? 0);
    }

    private getPriceInclVat(price: number, taxRate: number): number {
        // price including vat = price * (1 + taxRate)
        return Big(price).times(Big(1).plus(taxRate)).toNumber();
    }

    private getPriceExclVat(price: number, taxtRate: number): number {
        // price exculuding vat = price / (1 + taxRate)
        const buyingPriceExcl = Big(price).div(Big(1).plus(taxtRate)).toNumber();
        return Tools.Utils.roundTo(buyingPriceExcl, 0.01);
    }

    private getTaxRateById(taxId?: number): number {
        // eslint-disable-next-line eqeqeq
        const articleTax = this.articleTaxes.find(t => t.id == taxId);
        return !!articleTax ? articleTax.taxRate : this.item.taxRate;
    }
}
