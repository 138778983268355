@if ((columnModel.isEditable) && !!control) {
    <arc-currency-input [formControl]="control | formControl" [currencyId]="currencyId"></arc-currency-input>
} @else {
    <div class="w-full-h-full align-center">
        <span *ngIf="hasValue" class="truncate" [class.text-sm]="currColumnModel.isCompact"
        [class.font-bold]="isBold(currColumnModel.propertyName)">
        {{ value | arcCurrency }}
    </span>
    </div>
    @if (permission !== true) {
        <arc-no-permission-lock [missingPermission]="permission"></arc-no-permission-lock>
    }
}
