import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { RegionModel } from '../../models/responses/region.model';
import { RegionListModel } from '../../models/responses/region-list.model';

@Injectable({
    providedIn: 'root'
})
export class RegionsStore extends BaseCrudStore<RegionModel, RegionListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'regions' }));
    }
}
