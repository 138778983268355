import { Component, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { VoucherTypeModel } from '../../../../../models/voucher-type.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { TreeAutocompleteConfigsService } from '../../../../../services/tree-autocomplete-configs.service';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { EntityTagModel } from '../../../../../models/entity-tag.model';
import { TagsStore } from '../../../../../services/stores/tags.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-voucher-type-edit-base-data',
    templateUrl: './voucher-type-edit-base-data.component.html',
    styleUrls: ['./voucher-type-edit-base-data.component.scss']
})
export class VoucherTypeEditBaseDataComponent extends BaseEditSidebarItemComponent<VoucherTypeModel> {
    PermissionsEnum = PermissionsEnum;
    UnitsEnum = UnitsEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        validFrom: new ArcFormControl<Date>(new Date(), Validators.required),
        validThru: new ArcFormControl<Date>(new Date(), Validators.required),
        minimalVoucherValue: new ArcFormControl(0, CustomValidators.number({ min: 0 })),
        paymentTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        generateVoucherId: new ArcFormControl(false),
        discountAsNumber: new ArcFormControl<OptionalType<number>>(undefined, undefined, undefined, 'discount'),
        isDiscountPercent: new ArcFormControl<boolean>(false),
        validForArticleGroupId: new ArcFormControl<OptionalType<number>>(undefined),
        validForBranchId: new ArcFormControl<OptionalType<number>>(undefined),
        validFromAmount: new ArcFormControl<number>(0, CustomValidators.number({ mustBePositive: true })),
        validForArticleMinPrice: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ mustBePositive: true })),
        bonusFactor: new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ mustBePositive: true })),
        cumulateVouchers: new ArcFormControl(false),
        cumulateDiscounts: new ArcFormControl(false),
        isReusable: new ArcFormControl(false),
        validFromQuantity: new ArcFormControl<OptionalType<number>>(
            undefined,
            CustomValidators.number({ mustBePositive: true, isInteger: true })
        ),
        applyOnCheapestNArticles: new ArcFormControl<OptionalType<number>>(
            undefined,
            CustomValidators.number({ mustBePositive: true, isInteger: true })
        ),
        tags: new ArcFormControl<EntityTagModel[]>([]),
        isDiscountVoucher: new ArcFormControl(true)
    });

    readonly isDiscountVoucher = signal(true);

    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    readonly tagsStore = inject(TagsStore);
    readonly translationService = inject(TranslationService);

    readonly isDiscountVoucherItems = [
        { value: true, label: this.translationService.getText('VoucherTypes.DiscountVoucher') },
        { value: false, label: this.translationService.getText('VoucherTypes.ValueVoucher') }
    ];
    readonly isDiscountPercentItems = [
        { value: false, label: this.translationService.getText('VoucherTypes.Edit.Amount') },
        { value: true, label: this.translationService.getText('VoucherTypes.Edit.Percent') }
    ];

    constructor() {
        super();

        const isDiscountVoucherChangedSub = this.formGroup.controls.isDiscountVoucher.valueChanges.subscribe(isDiscountVoucher => {
            this.isDiscountVoucher.set(isDiscountVoucher);
            this.setupValidators(isDiscountVoucher);
        });
        this.addSubscriptions(isDiscountVoucherChangedSub);
    }

    onItemSet(): void {
        this.formGroup.patchValue({
            ...this.item,
            discountAsNumber: !!this.item.discount ? +this.item.discount : undefined,
            isDiscountPercent: !!this.item.discount?.includes('%')
        });

        if (!this.isCreate) {
            this.formGroup.controls.isDiscountVoucher.disable();
            this.formGroup.controls.discountAsNumber.disable();
            this.formGroup.controls.isDiscountPercent.disable();
        }
    }

    override prepareSaveModel(): Partial<VoucherTypeModel> {
        const value = this.formGroup.getRawValue();
        const isDiscountVoucher = this.isCreate ? value.isDiscountVoucher : this.item.isDiscountVoucher;
        const paymentTypeId = isDiscountVoucher ? undefined : value.paymentTypeId;
        const discountString = value.discountAsNumber + (value.isDiscountPercent ? '%' : '');
        const discount = !this.isCreate ? this.item.discount : isDiscountVoucher ? discountString : undefined;

        return {
            title_de: value.title_de,
            title_fr: value.title_fr,
            title_en: value.title_en,
            title_it: value.title_it,
            validFrom: value.validFrom,
            validThru: value.validThru,
            minimalVoucherValue: value.minimalVoucherValue,
            paymentTypeId,
            generateVoucherId: value.generateVoucherId,
            discount,
            validForArticleGroupId: value.validForArticleGroupId,
            validForBranchId: value.validForBranchId,
            validFromAmount: value.validFromAmount,
            validForArticleMinPrice: value.validForArticleMinPrice,
            bonusFactor: value.bonusFactor,
            cumulateVouchers: value.cumulateVouchers,
            cumulateDiscounts: value.cumulateDiscounts,
            isReusable: value.isReusable,
            validFromQuantity: value.validFromQuantity,
            applyOnCheapestNArticles: value.applyOnCheapestNArticles,
            tags: value.tags,
            isDiscountVoucher: value.isDiscountVoucher
        };
    }

    getTagId(tag: EntityTagModel): number {
        return tag.id;
    }

    tagDisplayFn(tag: EntityTagModel): string {
        return tag.title;
    }

    tagSearchFn(searchText: string): Observable<EntityTagModel[]> {
        return this.tagsStore.search(new SearchRequestModel({ searchText })).pipe(map(response => response.value?.records ?? []));
    }

    private setupValidators(isDiscountVoucher = false): void {
        if (isDiscountVoucher) {
            this.setupDiscountVouchersValidators();
        } else {
            this.setupValueVouchersValidators();
        }
    }

    private setupValueVouchersValidators(): void {
        this.formGroup.controls.discountAsNumber.clearValidators();
        this.formGroup.controls.discountAsNumber.updateValueAndValidity();
        this.formGroup.controls.paymentTypeId.setValidators(Validators.required);
    }

    private setupDiscountVouchersValidators(): void {
        this.formGroup.controls.paymentTypeId.clearValidators();
        this.formGroup.controls.paymentTypeId.updateValueAndValidity();
        this.formGroup.controls.discountAsNumber.setValidators([Validators.required, CustomValidators.number({ mustBePositive: true })]);
    }
}
