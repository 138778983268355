import { Component, OnInit, computed, inject, signal, viewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';

import { PosLayoutsStore } from '../../../../services/stores/pos-layouts.store';
import { PosLayoutModel } from '../../../../models/pos-layout.model';
import { PosLayoutUpdateModel } from '../../../../models/requests/pos-layout-update.model';
import { OptionalType } from '../../../../../core/models/types/optional.type';
import { PosLayoutEditCommandGridComponent } from './pos-layout-edit-command-grid/pos-layout-edit-command-grid.component';

@Component({
    selector: 'arc-pos-layout-edit',
    templateUrl: './pos-layout-edit.component.html',
    styleUrl: './pos-layout-edit.component.scss'
})
export class PosLayoutEditComponent implements OnInit {
    commandGrids = viewChildren(PosLayoutEditCommandGridComponent);

    posLayoutId = 0;

    isLoading = signal(true);
    posLayout = signal<PosLayoutModel>({
        id: 0,
        title: '',
        articlePanelHeight: 0,
        infoPanelHeight: 0,
        paymentPanelHeight: 0,
        functionsPanelWidth: 0,
        preserveSelectedTabs: false,
        isSelfcheckout: false,
        useCamForAgeVerification: false,
        logoutAfterTransaction: false,
        isGastroEnabled: false,
        showUnit: false,
        showTaxRate: false,
        posLayoutGroups: []
    });

    sortedGroups = computed(() => this.posLayout().posLayoutGroups.sort((a, b) => a.sort - b.sort) ?? []);
    topRightPanels = computed(() => this.sortedGroups().filter(g => !g.isBottomPanel));
    bottomPanels = computed(() => this.sortedGroups().filter(g => g.isBottomPanel));

    activeTopRightPanelId = signal(0);
    activeBottomPanelId = signal<OptionalType<number>>(undefined);

    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly posLayoutsStore = inject(PosLayoutsStore);

    async ngOnInit(): Promise<void> {
        this.posLayoutId = Number(this.activatedRoute.snapshot.params['id']);

        if (!this.posLayoutId || Number.isNaN(this.posLayoutId)) {
            await this.navigateToPosLayoutsList();
            return;
        }

        this.fetchPosLayout().subscribe(() => {
            this.activeTopRightPanelId.set(this.topRightPanels()[0].id);
            // possible that there is no bottom panel
            this.activeBottomPanelId.set(this.bottomPanels()[0]?.id);

            this.isLoading.set(false);
        });
    }

    async navigateToPosLayoutsList(): Promise<void> {
        await this.router.navigate(['/settings/pos-layouts']);
    }

    toggleShowUnit(): Observable<any> {
        return this.updatePosLayout({ showUnit: !this.posLayout().showUnit });
    }

    toggleShowTaxRate(): Observable<any> {
        return this.updatePosLayout({ showTaxRate: !this.posLayout().showTaxRate });
    }

    reload(): void {
        this.fetchPosLayout().subscribe();
    }

    reloadGrids(): void {
        this.commandGrids().forEach(g => g.reload());
    }

    private updatePosLayout(updates: Partial<PosLayoutUpdateModel>): Observable<void> {
        const posLayoutModel = this.posLayout();
        return this.posLayoutsStore
            .edit({
                id: posLayoutModel.id,
                title: posLayoutModel.title,
                showUnit: posLayoutModel.showUnit,
                showTaxRate: posLayoutModel.showTaxRate,
                ...updates
            })
            .pipe(switchMap(() => this.fetchPosLayout()));
    }

    private fetchPosLayout(): Observable<void> {
        return this.posLayoutsStore.get(this.posLayoutId).pipe(
            tap(async result => {
                if (!result.value) {
                    await this.navigateToPosLayoutsList();
                    return;
                }

                this.posLayout.set(result.value);
            }),
            map(() => undefined)
        );
    }
}
