import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HelpArticleListModel } from '../../../../app/models/responses/help-article-list.model';

@Component({
    selector: 'arc-article-list-item',
    templateUrl: './help-article-list-item.component.html',
    styleUrl: './help-article-list-item.component.scss'
})
export class HelpArticleListItemComponent {
    @Input() article?: HelpArticleListModel;
    @Output() readonly articleSelected = new EventEmitter<number>();
    selectArticle(articleId: number): void {
        this.articleSelected.emit(articleId);
    }
}
