import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of, switchMap } from 'rxjs';

import { ArcFormControl } from '../../../../../../../core/utils/arc-form-control';
import { OrderArticleModel } from '../../../../../../models/order-article.model';
import { ArticleSuppliersStore } from '../../../../../../services/stores/article-suppliers.store';
import { OrderStatusEnum } from '../../../../../../models/enums/order-status.enum';
import { TranslationService } from '../../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-order-article-edit-dialog',
    templateUrl: './order-article-edit-dialog.component.html',
    styleUrl: './order-article-edit-dialog.component.scss'
})
export class OrderArticleEditDialogComponent implements OnInit {
    isSaving = false;
    isDraft = true;
    unit = '';

    formGroup!: FormGroup;

    readonly translationService = inject(TranslationService);
    readonly orderBuyingPriceItems = [
        { value: false, label: this.translationService.getText('Orders.Edit.Articles.BuyingPriceInclusive') },
        { value: true, label: this.translationService.getText('Orders.Edit.Articles.BuyingPriceExclusive') }
    ];
    private readonly data: { orderStatus: OrderStatusEnum; item: OrderArticleModel } = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly articleSuppliersStore = inject(ArticleSuppliersStore);

    ngOnInit(): void {
        this.isDraft = this.data.orderStatus === OrderStatusEnum.Draft;

        this.formGroup = this.isDraft
            ? new FormGroup({
                orderNumber: new ArcFormControl('', Validators.required),
                orderBuyingPrice: new ArcFormControl(0, Validators.required),
                orderBuyingPriceExclusive: new ArcFormControl(false),
                orderUnitQuantity: new ArcFormControl(0, Validators.required),
                updateArticle: new ArcFormControl(false),
                notes: new ArcFormControl('')
            })
            : new FormGroup({
                deliveredUnitQuantity: new ArcFormControl(0, Validators.required),
                orderBuyingPrice: new ArcFormControl(0, Validators.required),
                orderBuyingPriceExclusive: new ArcFormControl(false),
                updateArticle: new ArcFormControl(false),
                notes: new ArcFormControl('')
            });

        this.formGroup.patchValue(this.data.item);
        this.unit = this.data.item.articleUnitTitle;
    }

    save(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }

        this.isSaving = true;
        if (this.formGroup.value.updateArticle) {
            // get article supplier
            this.articleSuppliersStore
                .getByOrderAndArticleId(this.data.item.orderId, this.data.item.articleId)
                .pipe(
                    switchMap(result => {
                        const articleSupplier = result.value;
                        if (!articleSupplier) {
                            return of();
                        }

                        articleSupplier.buyingPrice = this.formGroup.getRawValue().orderBuyingPrice;
                        articleSupplier.buyingPriceExclusive = this.formGroup.getRawValue().orderBuyingPriceExclusive;
                        if (this.isDraft) {
                            articleSupplier.orderNumber = this.formGroup.getRawValue().orderNumber;
                            articleSupplier.unitQuantity = this.formGroup.getRawValue().orderUnitQuantity;
                        } else {
                            articleSupplier.unitQuantity = this.formGroup.getRawValue().deliveredUnitQuantity;
                        }

                        // update article supplier
                        return this.articleSuppliersStore.edit(articleSupplier);
                    })
                )
                .subscribe(() => this.matDialogRef.close(this.formGroup.value))
                .add(() => {
                    this.isSaving = false;
                });
        } else {
            this.matDialogRef.close(this.formGroup.value);
        }
    }
}
