import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { SupplierListModel } from '../../../../../models/responses/supplier-list.model';
import { SupplierModel } from '../../../../../models/supplier.model';

@Component({
    selector: 'arc-supplier-base-data',
    templateUrl: './supplier-base-data.detail-widget.html',
    styleUrls: ['./supplier-base-data.detail-widget.scss']
})
export class SupplierBaseDataDetailWidget extends BaseDetailWidget<SupplierListModel, SupplierModel> {}
