import { Component, inject, OnInit, EventEmitter, Output } from '@angular/core';

import { ImageLoadingStateEnum } from './ImageLoadingStateEnum';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from '../../../core/services/environment.service';

@Component({
    selector: 'arc-login-visual',
    templateUrl: './login-visual.component.html',
    styleUrls: ['./login-visual.component.scss']
})
export class LoginVisualComponent implements OnInit {
    @Output() readonly imageLoadingStateUpdate = new EventEmitter<ImageLoadingStateEnum>();

    ImageLoadingStateEnum = ImageLoadingStateEnum;
    _imageLoadingState: ImageLoadingStateEnum = this.ImageLoadingStateEnum.Loading;

    get imageLoadingState(): ImageLoadingStateEnum {
        return this._imageLoadingState;
    }

    set imageLoadingState(value: ImageLoadingStateEnum) {
        this._imageLoadingState = value;
        this.imageLoadingStateUpdate.emit(this._imageLoadingState);
    }

    baseUrl = '';
    subdomain = '';
    didViewLoad = false;

    private readonly environmentService = inject(EnvironmentService);

    ngOnInit(): void {
        this.subdomain = this.environmentService.getSubdomain();
        this.baseUrl = environment.baseUrl;
    }

    handleImageLoad(): void {
        this.imageLoadingState = this.ImageLoadingStateEnum.Success;
        // to make the fade-in animation function correctly
        setTimeout(() => {
            this.didViewLoad = true;
        }, 50);
    }

    handleImageLoadError(): void {
        this.imageLoadingState = this.ImageLoadingStateEnum.Error;
        // to make the fade-in animation function correctly
        setTimeout(() => {
            this.didViewLoad = true;
        }, 50);
    }
}
