import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { CurrencyModel } from '../../../../../models/currency.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';

@Component({
    selector: 'arc-settings-finance-currencies',
    templateUrl: './settings-finance-currencies.component.html',
    styleUrl: './settings-finance-currencies.component.scss'
})
export class SettingsFinanceCurrenciesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        currencies: new ArcFormControl<CurrencyModel[]>([])
    });
    currenciesTableConfig: EditableTableConfigModel<CurrencyModel>;

    constructor() {
        super();
        this.currenciesTableConfig = new EditableTableConfigModel<CurrencyModel>({
            formGroupGeneratorFn: (data: CurrencyModel) => {
                const formGroup = new FormGroup({
                    id: new ArcFormControl(0),
                    currencyIsoCode: new ArcFormControl('', [Validators.required, Validators.maxLength(3)]),
                    exchangeRate: new ArcFormControl<OptionalType<number>>(1),
                    roundTo: new ArcFormControl(0.0, Validators.required),
                    isStandard: new ArcFormControl(false)
                });
                formGroup.controls.exchangeRate.setDisabled(data.isStandard);

                if (!data.isStandard) {
                    formGroup.controls.exchangeRate.addValidators(Validators.required);
                }

                return formGroup;
            },
            canDeleteRow: (data: CurrencyModel) => !data.isStandard,
            rowHeightPx: 63,
            columns: [
                new StringColumnModel({
                    propertyName: 'currencyIsoCode',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Finance.Currencies.CurrencyIsoCode'
                }),
                new NumberColumnModel({
                    propertyName: 'exchangeRate',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Finance.Currencies.ExchangeRate'
                }),
                new NumberColumnModel({
                    propertyName: 'roundTo',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Finance.Currencies.RoundTo'
                }),
                new CheckboxColumnModel({
                    propertyName: 'isStandard',
                    isEditable: false,
                    columnTitleKey: 'Settings.Edit.Finance.Currencies.Standard'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.currenciesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { currencies: this.formGroup.value.currencies };
    }
}
