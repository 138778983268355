import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseCustomColumnComponent } from '../../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { WarehouseRequestArticleModel } from '../../../../../../models/warehouse-request-article.model';

@Component({
    selector: 'arc-warehouse-transfer-article-status-column',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './warehouse-transfer-article-status-column.component.html',
    styleUrl: './warehouse-transfer-article-status-column.component.scss'
})
export class WarehouseTransferArticleStatusColumnComponent
    extends BaseCustomColumnComponent<WarehouseRequestArticleModel>
    implements OnInit {
    readonly colorMap = {
        correct: 'bg-success',
        incorrect: 'bg-warn',
        zero: 'bg-error'
    };

    colorClass = '';

    ngOnInit(): void {
        this.formGroup.valueChanges.subscribe(value => this.setColorClass(value));
        this.setColorClass(this.formGroup.value);
    }

    private setColorClass(value: Partial<WarehouseRequestArticleModel>): void {
        this.colorClass = !value.deliveredQuantity
            ? this.colorMap.zero
            // eslint-disable-next-line eqeqeq
            : value.deliveredQuantity == value.quantity
                ? this.colorMap.correct
                : this.colorMap.incorrect;
    }
}
