import { Component, computed, inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PosReportEditRequestModel } from '../../../../../models/requests/pos-report-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { PosReportTypeEnum } from '../../../../../../core/models/enums/pos-report-type.enum';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TagsStore } from '../../../../../services/stores/tags.store';
import { EntityTagModel } from '../../../../../models/entity-tag.model';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionService } from '../../../../../../core/services/permission.service';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { PosReportModel } from '../../../../../models/pos-report.model';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-pos-report-edit',
    templateUrl: './pos-report-edit-base-data.component.html',
    styleUrls: ['./pos-report-edit-base-data.component.scss']
})
export class PosReportEditBaseDataComponent
    extends BaseEditSidebarItemComponent<PosReportModel, PosReportEditRequestModel>
    implements OnInit {
    PermissionsEnum = PermissionsEnum;
    override formGroup = new FormGroup({
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        reportType: new ArcFormControl(PosReportTypeEnum.Report, Validators.required),
        copies: new ArcFormControl(1, Validators.required),
        autoPrint: new ArcFormControl(false, Validators.required),
        printPerArticle: new ArcFormControl(false, Validators.required),
        transactionTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        paymentTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        voucherTypeId: new ArcFormControl<OptionalType<number>>(undefined),
        branchId: new ArcFormControl<OptionalType<number>>(undefined),
        // eslint-disable-next-line no-null/no-null
        paymentDebit: new ArcFormControl<boolean | null>(null),
        // eslint-disable-next-line no-null/no-null
        paymentRequiresSignature: new ArcFormControl<boolean | null>(null),
        // eslint-disable-next-line no-null/no-null
        isWarranty: new ArcFormControl<boolean | null>(null),
        tags: new ArcFormControl<EntityTagModel[]>([]),
        resourceGroupName: new ArcFormControl<OptionalType<string>>(undefined),
        reportBlobData: new ArcFormControl<OptionalType<number[]>>(undefined),
        fileControl: new ArcFormControl(),
        reportName: new ArcFormControl('', Validators.required) // this is the filename
    });

    PosReportTypeEnum = PosReportTypeEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    resourceGroupNameOptions: SelectOptionModel<OptionalType<string>>[] = [{ value: undefined, label: '-' }];

    readonly translationService = inject(TranslationService);
    readonly isWarrantyItems = [
        {
            label: this.translationService.getText('PosReports.EditFields.IsWarrantyNull'),
            // eslint-disable-next-line no-null/no-null
            value: null
        },
        {
            label: this.translationService.getText('PosReports.EditFields.IsWarrantyTrue'),
            value: true
        },
        {
            label: this.translationService.getText('PosReports.EditFields.IsWarrantyFalse'),
            value: false
        }
    ];
    readonly paymentRequiresSignatureItems = [
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentRequiresSignatureNull'),
            // eslint-disable-next-line no-null/no-null
            value: null
        },
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentRequiresSignatureTrue'),
            value: true
        },
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentRequiresSignatureFalse'),
            value: false
        }
    ];
    readonly paymentDebitItems = [
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentDebitNull'),
            // eslint-disable-next-line no-null/no-null
            value: null
        },
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentDebitTrue'),
            value: true
        },
        {
            label: this.translationService.getText('PosReports.EditFields.PaymentDebitFalse'),
            value: false
        }
    ];
    reportTypeItems = computed(() => {
        const items = [
            {
                label: this.translationService.getText('PosReports.PosReportTypeEnum.Report'),
                value: PosReportTypeEnum.Report
            },
            {
                label: this.translationService.getText('PosReports.PosReportTypeEnum.Receipt'),
                value: PosReportTypeEnum.Receipt
            },
            {
                label: this.translationService.getText('PosReports.PosReportTypeEnum.PosEndOfDay'),
                value: PosReportTypeEnum.PosEndOfDay
            },
            {
                label: this.translationService.getText('PosReports.PosReportTypeEnum.UserEndOfDay'),
                value: PosReportTypeEnum.UserEndOfDay
            }
        ];
        if (this.isGastroLicensedAndHasPermission()) {
            items.push({
                label: this.translationService.getText('PosReports.PosReportTypeEnum.Order'),
                value: PosReportTypeEnum.Order
            });
        }
        return items;
    });
    private readonly tagsStore = inject(TagsStore);
    private readonly permssionStore = inject(PermissionService);

    ngOnInit(): void {
        this.formGroup.controls.fileControl.valueChanges.subscribe(async (file?: File) => {
            this.formGroup.controls.reportBlobData.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined);
            this.formGroup.controls.reportName.setValue(file?.name ?? '');
        }
        );
    }

    override onItemSet(): void {
        this.resourceGroupNameOptions = [
            ...this.resourceGroupNameOptions,
            ...(this.item.resourceGroupNames?.map(r => ({ value: r, label: r })) || [])
        ];

        this.formGroup.patchValue(this.item);

        if (this.item.isTemplate) {
            this.formGroup.controls.reportType.disable();
        }
        if (this.isCreate) {
            this.formGroup.controls.reportBlobData.setValidators(Validators.required);
            this.formGroup.controls.fileControl.setValidators(Validators.required);
        }
    }

    override prepareSaveModel(): Partial<PosReportEditRequestModel> {
        const value = this.formGroup.value;
        return {
            title_de: value.title_de,
            title_en: value.title_en,
            title_fr: value.title_fr,
            title_it: value.title_it,
            reportName: value.reportName,
            reportType: value.reportType,
            autoPrint: value.autoPrint,
            printPerArticle: value.printPerArticle,
            copies: value.copies,
            transactionTypeId: value.transactionTypeId,
            paymentTypeId: value.paymentTypeId,
            voucherTypeId: value.voucherTypeId,
            branchId: value.branchId,
            paymentDebit: value.paymentDebit ?? undefined,
            paymentRequiresSignature: value.paymentRequiresSignature ?? undefined,
            isWarranty: value.isWarranty ?? undefined,
            tags: value.tags,
            resourceGroupName: value.resourceGroupName,
            reportBlobData: value.reportBlobData
        };
    }

    getTagId(tag: EntityTagModel): number {
        return tag.id;
    }

    tagDisplayFn(tag: EntityTagModel): string {
        return tag.title;
    }

    tagsSearch(query: string): Observable<EntityTagModel[]> {
        return this.tagsStore.search(new SearchRequestModel({ searchText: query })).pipe(map(response => response.value?.records ?? []));
    }

    isGastroLicensedAndHasPermission(): boolean {
        // checking for resource permsission as this one is bound to the gastro module
        return this.permssionStore.hasPermission(PermissionsEnum.Resources) === true;
    }
}
