import { Component, OnInit } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionPaymentListModel } from '../../../../../models/responses/transaction-payment-list.model';
import { TransactionPaymentModel } from '../../../../../models/responses/transaction-payment.model';
import { TransactionPaymentTypeEnum } from '../../../../../models/enums/transaction-payment-type.enum';

@Component({
    selector: 'arc-transaction-payment-details',
    templateUrl: './transaction-payment-details.detail-widget.html',
    styleUrls: ['./transaction-payment-details.detail-widget.scss']
})
export class TransactionPaymentDetailsDetailWidget extends BaseDetailWidget<TransactionPaymentListModel, TransactionPaymentModel>
    implements OnInit {
    TransactionPaymentTypeEnum = TransactionPaymentTypeEnum;
    icon = '';

    ngOnInit(): void {
        switch (this.entityModel.type) {
            case TransactionPaymentTypeEnum.Card:
                this.icon = 'credit_card';
                break;
            case TransactionPaymentTypeEnum.Voucher:
                this.icon = 'card_giftcard';
                break;
            case TransactionPaymentTypeEnum.Cash:
                this.icon = 'payments';
                break;
            default:
                this.icon = 'list_alt';
        }
    }
}
