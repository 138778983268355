import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';

@Component({
    selector: 'arc-settings-warehouse-buying-price',
    templateUrl: './settings-warehouse-buying-price.component.html',
    styleUrl: './settings-warehouse-buying-price.component.scss'
})
export class SettingsWarehouseBuyingPriceComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        calculateBuyingPrice: new ArcFormControl(false)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { calculateBuyingPrice: this.formGroup.value.calculateBuyingPrice };
    }
}
