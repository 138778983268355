import { AsyncValidatorFn, FormControl, FormControlOptions, FormControlState, ValidatorFn } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { OptionalType } from '../models/types/optional.type';

export interface ArcFormControlInterface<TValue = any> extends FormControl<TValue> {
    validationName?: string;
    onTouched: Observable<boolean>;

    setDisabled(isDisabled: boolean): void;
}

// ɵFormControlCtor with enhanced typings
interface ArcFormControlCtor {
    prototype: ArcFormControlInterface<any>;

    new(): ArcFormControlInterface<any>;

    new<T = any>(value: FormControlState<T> | T, opts: FormControlOptions & { nonNullable: true }): ArcFormControlInterface<T>;

    new<T = any>(
        value: FormControlState<OptionalType<T>> | OptionalType<T>,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | undefined,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | undefined,
        validationName?: string | undefined
    ): ArcFormControlInterface<T>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ArcFormControl: ArcFormControlCtor = class ArcControl<T = any> extends FormControl implements ArcFormControlInterface<T> {
    validationName?: string;

    private _onTouchedSub = new Subject<boolean>();

    get onTouched(): Observable<boolean> {
        return this._onTouchedSub;
    }

    constructor(
        formState: FormControlState<T> | T = undefined as T,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | undefined,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | undefined,
        validationName?: string | undefined
    ) {
        super(formState, validatorOrOpts, asyncValidator);
        this.validationName = validationName;
    }

    setDisabled(isDisabled: boolean): void {
        if (isDisabled) {
            this.disable();
        } else {
            this.enable();
        }
    }

    override markAsTouched(opts?: { onlySelf?: boolean | undefined } | undefined): void {
        super.markAsTouched(opts);
        this._onTouchedSub.next(true);
    }

    override markAsUntouched(opts?: { onlySelf?: boolean | undefined } | undefined): void {
        super.markAsUntouched(opts);
        this._onTouchedSub.next(false);
    }
};
