import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { VoucherTypeModel } from '../../models/voucher-type.model';
import { StoreConfig } from '../../../core/models/store.config';
import { VoucherTypeListModel } from '../../models/responses/voucher-type-list.model';

@Injectable({
    providedIn: 'root'
})
export class VoucherTypesStore extends BaseCrudStore<VoucherTypeModel, VoucherTypeListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'voucher-types' }));
    }
}
