import { Component, HostBinding, OnInit, ViewContainerRef, inject } from '@angular/core';

import { CustomColumnModel } from '../../models/column-types/custom-column.model';
import { BaseColumnComponent } from '../base-column.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-custom-column',
    templateUrl: './custom-column.component.html',
    styleUrls: ['./custom-column.component.scss']
})
export class CustomColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    @HostBinding('style') style = { display: 'none' };

    viewContainerRef = inject(ViewContainerRef);

    override ngOnInit(): void {
        super.ngOnInit();

        const customColumnModel = this.columnModel as CustomColumnModel<T>;
        const comp = this.viewContainerRef.createComponent(customColumnModel.customComponent);

        comp.setInput('item', this.item);
        comp.setInput('formGroup', this.formGroup);
        comp.setInput('columnModel', customColumnModel);
    }
}
