import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { VoucherListModel } from '../../../../../models/responses/voucher-list.model';
import { VoucherModel } from '../../../../../models/voucher.model';

@Component({
    selector: 'arc-voucher-id-qr-code',
    templateUrl: './voucher-id-qr-code.detail-widget.html',
    styleUrls: ['./voucher-id-qr-code.detail-widget.scss']
})
export class VoucherIdQrCodeDetailWidget extends BaseDetailWidget<VoucherListModel, VoucherModel> {
    qrCodeDownloadLink: SafeUrl = '';

    onChangeURL(url: SafeUrl): void {
        this.qrCodeDownloadLink = url;
    }
}
