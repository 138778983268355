import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';

@Component({
    selector: 'arc-settings-invoices-bank-account',
    templateUrl: './settings-invoices-bank-account.component.html',
    styleUrl: './settings-invoices-bank-account.component.scss'
})
export class SettingsInvoicesBankAccountComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    override formGroup = new FormGroup({
        bankDetailsBank: new ArcFormControl<OptionalType<string>>(undefined),
        bankDetailsAccount: new ArcFormControl<OptionalType<string>>(undefined),
        bankDetailsIban: new ArcFormControl<OptionalType<string>>(undefined),
        bankDetailsBlz: new ArcFormControl<OptionalType<string>>(undefined),
        bankDetailsBic: new ArcFormControl<OptionalType<string>>(undefined)
    });

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        const value = this.formGroup.value;
        return {
            bankDetailsBank: value.bankDetailsBank,
            bankDetailsAccount: value.bankDetailsAccount,
            bankDetailsIban: value.bankDetailsIban,
            bankDetailsBlz: value.bankDetailsBlz,
            bankDetailsBic: value.bankDetailsBic
        };
    }
}
