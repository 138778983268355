import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';
import { Tools } from '../utils/tools';

@Pipe({ name: 'arcCurrency' })
export class CurrencyPipe implements PipeTransform {
    private static currencyCode = '';
    private static roundTo = 0;

    private readonly userService = inject(UserService);
    private readonly translationService = inject(TranslationService);

    transform(value: any, localize = true): string {
        if (!CurrencyPipe.currencyCode) {
            CurrencyPipe.currencyCode = this.userService.getUserInfo()?.currencyIsoCode ?? '';
        }
        if (!CurrencyPipe.roundTo) {
            CurrencyPipe.roundTo = this.userService.defaultRoundTo;
        }

        let numberVal = Number(value);
        if (Number.isNaN(numberVal)) {
            return value;
        }

        // round number
        numberVal = Tools.Utils.roundTo(numberVal, CurrencyPipe.roundTo);

        if (localize) {
            let culture = this.translationService.current.culture;
            if (culture === 'de-CH' && CurrencyPipe.currencyCode === 'EUR') {
                culture = 'de-DE';
            }

            value = numberVal.toLocaleString(this.translationService.current.culture, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        } else {
            value = numberVal.toFixed(2);
        }

        return `${CurrencyPipe.currencyCode} ` + value;
    }
}
