<form [formGroup]="formGroup">
    <div class="grid grid-cols-2 ssm:grid-cols-1 gap-4">
        <div class="flex items-center gap-2" arcPortalTooltip="Articles.EditFields.Status">
            <p>{{'Articles.EditFields.Status' | transloco}}</p>
            <arc-button-toggle-group [control]="formGroup.controls.status" [items]="statusItems" class="grow"
                toggleGroupClasses="w-full"></arc-button-toggle-group>
        </div>
        <div></div>
        <div class="grow flex flex-col gap-4">
            <arc-input labelKey="Articles.EditFields.RequiredAge" [control]="formGroup.controls.requiredAge" type="number">
            </arc-input>
            <arc-input labelKey="Articles.EditFields.WarrantyDays" [control]="formGroup.controls.warrantyDays" type="number">
            </arc-input>
            <arc-number-input label="Articles.EditFields.WeightGrams" [formControl]="formGroup.controls.weightGrams"
                [unit]="UnitsEnum.Grams" [decimalPlaces]="0">
            </arc-number-input>
        </div>
        <div class="flex flex-col">
            <arc-checkbox labelKey="Articles.EditFields.IsDiscountLocked" [control]="formGroup.controls.isDiscountLocked">
            </arc-checkbox>
            <arc-checkbox labelKey="Articles.EditFields.IgnoreStock" [control]="formGroup.controls.ignoreStock"
                [requiredPermission]="PermissionsEnum.ArticleStocks">
            </arc-checkbox>
            <arc-checkbox labelKey="Articles.EditFields.ShowOnWebApi" [control]="formGroup.controls.showOnWebApi"
                [requiredPermission]="PermissionsEnum.WebAPI">
            </arc-checkbox>
            <arc-checkbox labelKey="Articles.EditFields.IsBonusLocked" [control]="formGroup.controls.isBonusLocked"
                [requiredPermission]="PermissionsEnum.BonusRuns">
            </arc-checkbox>
            <arc-checkbox labelKey="Articles.EditFields.IsNegativeQuantity" [control]="formGroup.controls.isNegativeQuantity">
            </arc-checkbox>
        </div>
        <mat-form-field [arcPortalTooltip]="quantityPerUnitLabel" [arcErrorTooltip]="formGroup.controls.quantityPerUnit"
            subscriptSizing="dynamic">
            <mat-label>{{ quantityPerUnitLabel | transloco}}</mat-label>
            <input type="number" matInput [formControl]="formGroup.controls.quantityPerUnit" />
            <mat-select matSuffix [formControl]="formGroup.controls.contentUnitId" [placeholder]="contentUnitPlaceholder"
                class="arc-article-edit-content-unit-dropdown-suffix">
                <mat-option *ngFor="let option of contentUnits" [value]="option.key">
                    {{ option.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        @if(isFashionLicensed){
            <arc-general-data-select label="Articles.EditFields.Season" [generalDataType]="GeneralDataTypeEnum.Seasons"
                [formControl]="formGroup.controls.seasonId">
            </arc-general-data-select>
        } @else {
            <div></div>
        }
        <arc-quick-search label="Articles.EditFields.Brand" [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig"
            [formControl]="formGroup.controls.brandSupplierId">
        </arc-quick-search>
        <arc-general-data-select label="Articles.EditFields.CountryOfOrigin" [generalDataType]="GeneralDataTypeEnum.Countries"
            [formControl]="formGroup.controls.originCountryIsoCode">
        </arc-general-data-select>
        <arc-chip-selector class="col-span-2" label="Articles.EditFields.ArticleTags" [formControl]="formGroup.controls.tags"
            [displayFn]="tagDisplayFn" [searchFn]="tagsSearch.bind(this)" [uniqueIdFn]="getTagKey">
        </arc-chip-selector>
    </div>
</form>
