import { Component, OnInit, effect, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { TicketTypesEnum } from '../../../../app/models/enums/ticket-types.enum';
import { TicketStore } from '../../../../app/services/stores/ticket.store';
import { ArcFormControl } from '../../../../core/utils/arc-form-control';
import { SelectOptionModel } from '../../../../core/models/select-option.model';
import { PortalStore } from '../../../../app/services/stores/portal.store';
import { BaseComponent } from '../../../abstractions/base.component';
import { SidebarHeaderService } from '../../../../core/services/sidebar-header.service';
import { CreateTicketService } from '../../../../core/services/create-ticket.service';
import { TicketActionButtonModel } from '../../../../core/models/ticket-action-button.model';
import { TranslationService } from '../../../../core/services/translation.service';
import { CreateTicketStepEnum } from '../../../../app/models/enums/create-ticket-step.enum';

@Component({
    selector: 'arc-create-ticket-request',
    templateUrl: './create-ticket-request.component.html',
    styleUrl: './create-ticket-request.component.scss'
})
export class CreateTicketRequestComponent extends BaseComponent implements OnInit {
    formGroup = new FormGroup({
        title: new ArcFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]),
        type: new ArcFormControl(TicketTypesEnum.Problem),
        categoryHelpTopicId: new ArcFormControl('', Validators.required)
    });

    helpTopics: SelectOptionModel[] = [];

    TicketTypesEnum = TicketTypesEnum;

    private readonly ticketStore = inject(TicketStore);
    private readonly portalStore = inject(PortalStore);
    private readonly headerService = inject(SidebarHeaderService);
    private readonly createTicketService = inject(CreateTicketService);
    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        effect(() => {
            if (this.createTicketService.currentStep() === CreateTicketStepEnum.CreateRequest) {
                this.formGroup.enable();
            } else {
                this.formGroup.disable();
            }
        });
    }

    get showTextCount(): boolean {
        return this.createTicketService.currentStep() === CreateTicketStepEnum.CreateRequest;
    }

    ngOnInit(): void {
        this.portalStore.getHelpTopics().subscribe(data => {
            this.helpTopics = data.value!.map(x => ({ value: x.key, label: x.value ?? '' }));
        });

        this.headerService.hasCloseButton.set(false);
        const createRequestButton = new TicketActionButtonModel({
            id: 'create-ticket-request',
            text: this.translationService.getText('General.Actions.Send'),
            step: CreateTicketStepEnum.CreateRequest,
            clickFn: () => this.createRequest()
        });
        this.createTicketService.addButtons(createRequestButton);
        if(this.createTicketService.createTicketRequest()){
            this.formGroup.setValue(this.createTicketService.createTicketRequest()!);
        }
    }

    createRequest(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }

        this.createTicketService.isLoading.set(true);
        const ticket = {
            type: this.formGroup.value.type || TicketTypesEnum.Problem,
            title: this.formGroup.value.title || '',
            categoryHelpTopicId: this.formGroup.value.categoryHelpTopicId || ''
        };
        this.createTicketService.saveCreateRequest(ticket);
        this.ticketStore.createTicketRequest(ticket).subscribe(result => {
            this.createTicketService.ticketResponseCreated(result.value);
        });
    }
}
