import { Component, Input, ViewChild, OnChanges, AfterViewInit, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { StaticTableConfigModel } from './models/static-table-config.model';
import { SortDirectionEnum } from '../../../core/models/enums/sort-direction.enum';
import { BaseColumnModel } from '../../dynamic-table/models/column-types/base-column.model';
import { ColumnSortModel } from '../../../core/models/column-sort.model';
import { Tools } from '../../../core/utils/tools';
import { BaseSelectableTable } from '../../../core/abstractions/base-selectable-table';
import { TableSelectionModel } from '../../../core/models/table-selection.model';

@Component({
    selector: 'arc-static-table',
    templateUrl: './static-table.component.html',
    styleUrls: ['./static-table.component.scss']
})
export class StaticTableComponent<T> extends BaseSelectableTable<T> implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    @Input() config!: StaticTableConfigModel<T>;
    @Input() shouldUseCompactStyle = false;
    /**
     * Whether the row has no stack columns (only one line).
     */
    @Input() isSingleLineRow = false;

    SortDirectionEnum = SortDirectionEnum;

    readonly columnGap = 4;
    dataSource!: MatTableDataSource<T>;
    pageSize = 0;
    totalRecords = 0;
    currentSorting?: ColumnSortModel;
    displayedColumns: string[] = [];
    selectionModel = new TableSelectionModel<T>();

    ngOnInit(): void {
        this.setupDisplayedColumns();
    }

    ngOnChanges(): void {
        this.setupDisplayedColumns();

        this.totalRecords = this.config.data.length;
        this.pageSize = this.config.defaultPageSize;
        this.data.set(this.config.data);
        this.dataSource = new MatTableDataSource<T>(this.config.data);
        this.dataSource.paginator = this.paginator;

        if (this.config.shouldStartSelected) {
            // This is required because if the table config is changed with the data in the same component, the rows are unselected.
            // Instead of leaving them checked we unselect and re-select, otherwise the previous rows would be selected, not the current.
            if (this.isEverythingSelected()) {
                this.clearSelection();
            }

            this.selectAll();
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    sortColumn(column: BaseColumnModel): void {
        const previousColumn = this.currentSorting?.column;
        this.currentSorting ??= new ColumnSortModel();

        if (!!previousColumn && previousColumn === column.propertyName) {
            // cycle through sort states
            if (!this.currentSorting.direction) {
                this.currentSorting.direction = SortDirectionEnum.Asc;
            } else if (this.currentSorting.direction === SortDirectionEnum.Asc) {
                this.currentSorting.direction = SortDirectionEnum.Desc;
            } else {
                // reset sortings
                this.currentSorting = undefined;
            }
        } else if (!!column.propertyName) {
            this.currentSorting.column = column.propertyName;
            this.currentSorting.direction = SortDirectionEnum.Asc;
        } else {
            // reset sortings
            this.currentSorting = undefined;
        }

        if (!!this.currentSorting) {
            let filteredItems = [...this.config.data];
            filteredItems = filteredItems.sort((a, b) => {
                const sort = this.currentSorting!;
                const valueA = Tools.Utils.getValueForProperty(a, sort.column);
                const valueB = Tools.Utils.getValueForProperty(b, sort.column);
                let comparatorResult = 0;

                if (valueA && valueB) {
                    if (valueA > valueB) {
                        comparatorResult = 1;
                    } else if (valueA < valueB) {
                        comparatorResult = -1;
                    }
                } else if (valueA) {
                    comparatorResult = 1;
                } else if (valueB) {
                    comparatorResult = -1;
                }

                return comparatorResult * (sort.direction === SortDirectionEnum.Asc ? 1 : -1);
            });
            this.config.data = [...filteredItems];
        }
    }

    private setupDisplayedColumns(): void {
        const extraColumns = [];

        if (this.config.showCheckboxes) {
            extraColumns.push('checkboxes');
        }

        this.displayedColumns = [...extraColumns, ...this.config.availableColumns.map(c => c.identifier)];
    }
}
