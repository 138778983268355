import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArticleModel } from '../../../../../models/article.model';

@Component({
    selector: 'arc-article-edit-texts',
    templateUrl: './article-edit-texts.component.html',
    styleUrls: ['./article-edit-texts.component.scss']
})
export class ArticleEditTextsComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    descriptionKey = '';
    warrantyTextKey = '';
    displayWarningKey = '';

    override formGroup = new FormGroup<any>({});

    private readonly translationService = inject(TranslationService);

    override onItemSet(): void {
        const lngCode = this.translationService.current.code;
        this.descriptionKey = 'description_' + lngCode;
        this.warrantyTextKey = 'warrantyText_' + lngCode;
        this.displayWarningKey = 'displayWarning_' + lngCode;
        this.formGroup.addControl(this.descriptionKey, new ArcFormControl(''));
        this.formGroup.addControl(this.warrantyTextKey, new ArcFormControl(''));
        this.formGroup.addControl(this.displayWarningKey, new ArcFormControl(''));
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return { ...this.formGroup.value };
    }
}
