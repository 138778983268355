<div class="w-full h-[75%] p-4">
    <div class="text-2xl font-bold leading-tight">{{ entityModel.title }}</div>

    @if (isSale || listModel.discount > 0) {
        <div class="flex items-center mt-4">
            <div class="inline-flex justify-center py-1 px-4 rounded" [ngClass]="boxColorClasses">
                {{ 'TransactionArticles.Details.' + (isSale ? 'Sale' : 'Discount') | transloco }}
            </div>

            @if (!!entityModel.saleTitle) {
                <div class="text-xl ml-4">{{ entityModel.saleTitle }}</div>
            }

            @if (listModel.discount > 0 && !entityModel.discountPercent) {
                <div class="text-xl ml-4">{{ listModel.discount | arcCurrency }}</div>
            } @else if (!!entityModel.discountPercent) {
                <div class="text-xl ml-4">{{ entityModel.discountPercent | arcNumber: 2:undefined:'%' }}</div>
            }
        </div>
    }

    <div class="grid grid-flow-col justify-stretch mt-4">
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.Quantity' | transloco }}</span>
            <div class="text-xl">{{ listModel.quantity }}</div>
        </div>
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.PricePerUnit' | transloco }}</span>
            <div class="text-xl" [class.font-bold]="!isPricePerUnitDiff" [class.line-through]="isPricePerUnitDiff">
                {{ listModel.unitPrice | arcCurrency }}
            </div>

            @if (isPricePerUnitDiff) {
                <div class="text-xl font-bold">
                    {{ priceByQuantity | arcCurrency }}
                </div>
            }
        </div>
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.Total' | transloco }}</span>

            @if (isTotalDiff) {
                <div class="text-xl line-through">
                    {{ unitPriceTimesQuantity | arcCurrency }}
                </div>
            }

            <div class="text-xl font-bold">{{ listModel.price | arcCurrency }}</div>
        </div>
    </div>
</div>
<div class="flex w-full h-[25%] bg-app px-4 items-center">
    <div class="grid grid-flow-col justify-stretch w-full">
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.VAT' | transloco }}</span>
            <div class="text-xl">{{ entityModel.taxRate * 100 | arcNumber: 2:undefined:'%' }}</div>
        </div>
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.BuyingPrice' | transloco }}</span>
            <div class="text-xl">{{ entityModel.buyingPrice | arcCurrency }}</div>
        </div>
        <div class="flex flex-col">
            <span class="text-on-app-light text-sm">{{ 'TransactionArticles.Details.PriceLevel' | transloco }}</span>
            <div class="text-xl">{{ entityModel.priceLevelTitle || '-' }}</div>
        </div>
    </div>
</div>
