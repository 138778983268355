/* eslint-disable no-null/no-null */
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {
    static number(params: { min?: number; max?: number; isInteger?: boolean; mustBePositive?: boolean } = {}): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                !control.hasValidator(Validators.required) &&
                (control.value === undefined || control.value === null || control.value === '')
            ) {
                return null;
            }

            const value = Number(control.value);

            if (isNaN(value)) {
                return { number: true };
            }

            const isRange = params.min !== undefined && params.max !== undefined;
            if (params.min !== undefined || params.max !== undefined) {
                const isTooSmall = params.min !== undefined && value < params.min;
                const isTooBig = params.max !== undefined && value > params.max;
                if (isRange && (isTooSmall || isTooBig)) {
                    return { numberRange: { min: params.min, max: params.max } };
                } else if (isTooSmall) {
                    return { numberMin: { min: params.min } };
                } else if (isTooBig) {
                    return { numberMax: { max: params.max } };
                }
            }

            if (params.isInteger && !Number.isInteger(value)) {
                return { numberInteger: true };
            }

            if (params.mustBePositive && value < 0) {
                return { numberPositive: true };
            }

            return null;
        };
    }

    static url(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            try {
                return Boolean(new URL(control.value)) ? null : { url: true };
            } catch (e) {
                return { url: true };
            }
        };
    }

    static ibanValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const iban = control.value;

            // Regular expression for IBAN validation
            const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;

            if (!ibanRegex.test(iban)) {
                // If the input does not match the IBAN pattern, return an error
                return { invalidIBAN: { value: control.value } };
            }

            // If the input is a valid IBAN, return null
            return null;
        };
    }

    static hexColor(allowAlpha = false): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const color = control.value;

            // not validating empty values
            if (color === undefined || color === null || color === '') {
                return null;
            }

            // Regular expression for hex validation
            let hexColorRegex = /^#[0-9A-Fa-f]{6}$/;
            if (allowAlpha) {
                // Regular expression for hexa validation
                hexColorRegex = /^#[0-9A-Fa-f]{6}(?:[0-9A-Fa-f]{2})?$/;
            }

            if (!hexColorRegex.test(color)) {
                // If the input does not match the pattern, return an error
                return { invalidHexColor: { value: control.value } };
            }

            // valid
            return null;
        };
    }
}
