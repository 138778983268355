@if (isLoading()) {
    <div class="grid w-full h-full place-items-center">
        <mat-spinner [diameter]="48"></mat-spinner>
    </div>
} @else {
    <div class="w-full h-full p-8">
        <div class="flex flex-col h-max w-max bg-on-app">
            <div class="flex items-center w-full h-[40px] px-4 py-2 text-app">
                <p>{{ posLayout().title }}</p>
            </div>

            <div class="flex flex-col gap-4 p-4 mx-4 grow bg-element">
                <!-- top half -->
                <div class="flex gap-4 grow">
                    <!-- top left panel -->
                    <arc-pos-layout-edit-top-left class="flex flex-col basis-0 grow" [posLayout]="posLayout"
                        [toggleShowUnitFn]="toggleShowUnit.bind(this)" [toggleShowTaxRateFn]="toggleShowTaxRate.bind(this)">
                    </arc-pos-layout-edit-top-left>

                    <!-- top right panel -->
                    <div class="flex gap-4 shrink-0">
                        <arc-pos-layout-edit-command-grid [posLayoutGroupId]="activeTopRightPanelId()" (swapped)="reloadGrids()"/>
                        <arc-pos-layout-edit-tabs [posLayout]="posLayout()" [activePanelId]="activeTopRightPanelId()"
                            (activePanelIdChange)="activeTopRightPanelId.set($event)" (reload)="reload()">
                        </arc-pos-layout-edit-tabs>
                    </div>
                </div>

                <!-- bottom half (bottom panel) -->
                <div class="flex gap-4">
                    @if (activeBottomPanelId(); as activeBottomPanelId) {
                        <arc-pos-layout-edit-command-grid [posLayoutGroupId]="activeBottomPanelId" (swapped)="reloadGrids()"/>
                    }
                    <arc-pos-layout-edit-tabs [posLayout]="posLayout()" [isBottomPanel]="true" [activePanelId]="activeBottomPanelId()"
                        (activePanelIdChange)="activeBottomPanelId.set($event)" (reload)="reload()">
                    </arc-pos-layout-edit-tabs>
                </div>
            </div>

            <!-- TODO: footer content? -->
            <p class="px-4 py-2 text-app"></p>
        </div>
    </div>
}
