import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseSearchStore } from '../../../core/abstractions/base-search.store';
import { PosCommandModel } from '../../models/pos-command.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { DynamicFormField } from '../../../components/dynamic-form/models/dynamic-form-field';

@Injectable({
    providedIn: 'root'
})
export class PosCommandsStore extends BaseSearchStore<PosCommandModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'pos-commands' }));
    }

    getCommandParameters(posCommandId: number): Observable<ApiResponseModel<DynamicFormField[]>> {
        return this._requestService.makeGet(this.getUrl(`parameters/${posCommandId}`));
    }
}
