<div class="flex flex-col gap-2 grow">
    <!-- demo articles table -->
    <table class="demo-articles-table">
        <tr>
            <th class="pr-4 text-left">{{ 'PosLayouts.Edit.Columns.Count' | transloco }}</th>
            <th class="pr-4 text-left">
                <div class="flex items-center gap-2" [class.text-element-disabled]="!posLayout().showUnit">
                    <span>{{ 'PosLayouts.Edit.Columns.Unit' | transloco }}</span>
                    @if (!isUpdatingShowUnit()) {
                        <mat-icon (click)="toggleShowUnit()" class="cursor-pointer hover:text-accent">
                            {{ posLayout().showUnit ? 'visibility' : 'visibility_off' }}
                        </mat-icon>
                    } @else {
                        <mat-spinner [diameter]="24"></mat-spinner>
                    }
                </div>
            </th>
            <th class="pr-4 text-left">{{ 'PosLayouts.Edit.Columns.Article' | transloco }}</th>
            <th class="pr-4 text-left">{{ 'PosLayouts.Edit.Columns.PricePerUnit' | transloco }}</th>
            <th class="pr-4 text-left">
                <div class="flex items-center gap-2" [class.text-element-disabled]="!posLayout().showTaxRate">
                    <span>{{ 'PosLayouts.Edit.Columns.TaxRate' | transloco }}</span>
                    @if (!isUpdatingShowTaxRate()) {
                        <mat-icon (click)="toggleShowTaxRate()" class="cursor-pointer hover:text-accent">
                            {{ posLayout().showTaxRate ? 'visibility' : 'visibility_off' }}
                        </mat-icon>
                    } @else {
                        <mat-spinner [diameter]="24"></mat-spinner>
                    }
                </div>
            </th>
            <th class="pr-4 text-left">{{ 'PosLayouts.Edit.Columns.Discount' | transloco }}</th>
            <th class="pr-4 text-left">{{ 'PosLayouts.Edit.Columns.Price' | transloco }}</th>
        </tr>
    
        <!-- demo articles -->
        <tr>
            <td>1</td>
            <td>{{ 'General.Units.Pieces.Short' | transloco }}</td>
            <td>{{ 'PosLayouts.Edit.DemoArticle' | transloco : { suffix: 1 } }}</td>
            <td>12.70</td>
            <td>8.10%</td>
            <td>-</td>
            <td>12.70</td>
        </tr>
    
        <tr class="demo-row-highlighted">
            <td>2</td>
            <td>{{ 'General.Units.Pieces.Short' | transloco }}</td>
            <td>{{ 'PosLayouts.Edit.DemoArticle' | transloco : { suffix: 2 } }}</td>
            <td>2.50</td>
            <td>8.10%</td>
            <td>-</td>
            <td>5.00</td>
        </tr>
    
    </table>

    <div class="grow"></div>

    <!-- customer info and total price -->
    <div class="flex">

        <!-- customer info -->
        <div class="flex items-center gap-2 grow">
            <arc-icon icon="person_outline" [size]="32"></arc-icon>
            <div>
                <p class="text-2xl">Maria Muster</p>
                <p class="text-sm">{{ 'PosLayouts.Edit.CustomerTurnover' | transloco }}: 1'257.80</p>
                <p class="text-sm">{{ 'PosLayouts.Edit.CustomerBalance' | transloco }}: 0.00</p>
            </div>
        </div>

        <!-- total price -->
        <div class="flex items-center text-left">
            <p class="mr-24 font-bold">{{ 'PosLayouts.Edit.TotalPriceChf' | transloco }}</p>
            <p class="text-4xl font-bold">17.70</p>
        </div>

    </div>

</div>
