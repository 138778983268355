import { Component, OnInit, inject } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { StringColumnModel } from '../../models/column-types/string-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';

@Component({
    selector: 'arc-string-column',
    templateUrl: './string-column.component.html',
    styleUrls: ['./string-column.component.scss']
})
export class StringColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    permission: true | AuthPermissionModel = true;
    get castedColumnModel(): StringColumnModel {
        return this.columnModel as StringColumnModel;
    }

    private readonly permissionService = inject(PermissionService);

    override ngOnInit(): void {
        super.ngOnInit();

        if (!!this.columnModel.requiredPermission) {
            this.permission = this.permissionService.hasPermission(
                this.columnModel.requiredPermission,
                this.columnModel.requiredPermissionType
            );
        }
    }
}
