import { Component, OnInit, inject, input, output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { GeneralDataService } from '../../../core/services/general-data.service';
import { GeneralDataTypeEnum } from '../../../core/models/enums/general-data-type.enum';
import { SelectOptionModel } from '../../../core/models/select-option.model';
import { BaseControlValueAccessor } from '../../../core/abstractions/base-control-value-accessor';
import { OptionalType } from '../../../core/models/types/optional.type';

@Component({
    selector: 'arc-general-data-select',
    templateUrl: './general-data-select.component.html',
    styleUrls: ['./general-data-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: GeneralDataSelectComponent
        }
    ]
})
export class GeneralDataSelectComponent extends BaseControlValueAccessor<any> implements OnInit {
    generalDataType = input.required<GeneralDataTypeEnum>();
    isMultiselect = input<boolean>(false);
    shouldAutoSelectFirstOption = input<boolean>(false);
    emptyOptionLabel = input<OptionalType<string>>();

    readonly optionSelected = output<any>();

    isLoading = true;
    data: SelectOptionModel[] = [];

    private readonly generalDataService = inject(GeneralDataService);

    override ngOnInit(): void {
        super.ngOnInit();

        this.generalDataService.getGeneralData(this.generalDataType()).subscribe(data => {
            if (!this.isRequired && !this.isMultiselect()) {
                this.data.push({ value: undefined, label: this.emptyOptionLabel() || '-' });
            }
            this.data = [...this.data, ...data.map(x => ({ value: x.key, label: x.value ?? '' }))];

            // auto select first option if required and there is only one option
            // OR if shouldAutoSelectFirstOption is set to true
            if (
                this.isAllowed &&
                this.data.length > 0 &&
                !this.value && // only auto select first if value not already set
                ((this.isRequired && this.data.length === 1) || this.shouldAutoSelectFirstOption())
            ) {
                if (!!this._formControl) {
                    // update the formcontrol value without marking it as dirty
                    this._formControl?.setValue(this.data[0].value);
                } else {
                    this.valueChanged(this.data[0].value, false);
                }
            }

            // check if the current value is still valid
            if (!this.isValueValid(this.value)) {
                this.writeValue(undefined);
            }

            this.isLoading = false;
        });
    }

    handleSelect(event: MatSelectChange): void {
        this.valueChanged(event.value);
        this.optionSelected.emit(event.value);
    }

    protected override valueChanged(newValue?: any, shouldMarkAsTouched?: boolean): void {
        super.valueChanged(newValue, shouldMarkAsTouched);
        this.internalControl.setValue(newValue);
    }

    /**
     * Only allow values that are in {@link data}.
     * Or allow all values if data is still empty. Once data is filled, this will be checked again.
     */
    protected override isValueValid(value?: any): boolean {
        if (value === undefined || this.data.length === 0) {
            return true;
        }

        let isValid = true;
        if (Array.isArray(value)) {
            for (const val of value) {
                if (!this.data.some(d => d.value === val)) {
                    isValid = false;
                    break;
                }
            }
        } else {
            isValid = this.data.some(d => d.value === value);
        }
        return isValid;
    }
}
