import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';

import { ArticleGroupsStore } from './stores/article-groups.store';
import { TreeDataSelectionConfig } from '../../components/form/tree-autocomplete/models/tree-data-selection.config';
import { ArticleGroupModel } from '../models/article-group.model';
import { TranslationService } from '../../core/services/translation.service';
import { ArticleGroupListModel } from '../models/responses/article-group-list.model';
import { SearchRequestModel } from '../models/requests/search-request.model';
import { ColumnFilterModel } from '../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../core/models/enums/comparison-operators.enum';
import { OperandTypeEnum } from '../../core/models/enums/operand-type.enum';
import { PermissionsEnum } from '../models/enums/permissions.enum';

export const generateArticleGroupConfig = (
    articleGroupsStore: ArticleGroupsStore,
    translationService: TranslationService
): TreeDataSelectionConfig<ArticleGroupListModel> =>
    new TreeDataSelectionConfig({
        selectionDialogTitleKey: 'ArticleGroups.ArticleGroup',
        requiredPermission: PermissionsEnum.ArticleGroups,
        getNodeTitle: (group: ArticleGroupListModel) => group.title,
        search: (searchText: string) => {
            const request = new SearchRequestModel({
                searchText,
                filters: [
                    new ColumnFilterModel({
                        column: 'ReturnArticleGroups',
                        comparisonOperator: ComparisonOperatorsEnum.True,
                        operand: OperandTypeEnum.Or
                    })
                ]
            });

            return articleGroupsStore.search(request).pipe(map(response => response.value?.records ?? []));
        },

        loadRootItems: () => articleGroupsStore.getMainGroups().pipe(map(response => response.value ?? [])),
        loadChildren: parentId => articleGroupsStore.getSubGroups(parentId).pipe(map(response => response.value ?? [])),

        getNodeParent: node => node.parentGroup,
        hasNodeChildren: node => node.hasChildren,

        store: articleGroupsStore,
        addItemAtLevelZeroTextKey: 'ArticleGroups.AddMainGroup',
        addItemAtLevelOneTextKey: 'ArticleGroups.AddProductGroup',
        addItemAtLevelTwoTextKey: 'ArticleGroups.AddArticleGroup',
        saveNewRecordHandler: (parentNode, text) => {
            const newGroup: ArticleGroupModel = {
                id: 0,
                parentGroupId: parentNode?.id || undefined,
                title: text,
                title_de: text,
                hasChildren: false
            };

            switch (translationService.current.code) {
                case 'en':
                    newGroup.title_en = text;
                    break;
                case 'fr':
                    newGroup.title_fr = text;
                    break;
                case 'it':
                    newGroup.title_it = text;
                    break;
            }

            return articleGroupsStore.add(newGroup);
        }
    });

export const generateArticleGroupConfigForSelection = (
    articleGroupsStore: ArticleGroupsStore,
    levels = [0, 1, 2]
): TreeDataSelectionConfig<ArticleGroupListModel> =>
    new TreeDataSelectionConfig({
        selectionDialogTitleKey: 'ArticleGroups.ArticleGroup',
        requiredPermission: PermissionsEnum.ArticleGroups,
        getNodeTitle: (group: ArticleGroupListModel) => group.title,
        search: (searchText: string) => {
            const request = new SearchRequestModel({ searchText });

            if (levels.includes(0)) {
                request.filters.push(
                    new ColumnFilterModel({
                        column: 'ReturnMainGroups',
                        comparisonOperator: ComparisonOperatorsEnum.True,
                        operand: OperandTypeEnum.Or
                    })
                );
            }

            if (levels.includes(1)) {
                request.filters.push(
                    new ColumnFilterModel({
                        column: 'ReturnTradeGroups',
                        comparisonOperator: ComparisonOperatorsEnum.True,
                        operand: OperandTypeEnum.Or
                    })
                );
            }

            if (levels.includes(2)) {
                request.filters.push(
                    new ColumnFilterModel({
                        column: 'ReturnArticleGroups',
                        comparisonOperator: ComparisonOperatorsEnum.True,
                        operand: OperandTypeEnum.Or
                    })
                );
            }

            return articleGroupsStore.search(request).pipe(map(response => response.value?.records ?? []));
        },

        loadRootItems: () => articleGroupsStore.getMainGroups().pipe(map(response => response.value ?? [])),
        loadChildren: parentId => articleGroupsStore.getSubGroups(parentId).pipe(map(response => response.value ?? [])),

        getNodeParent: node => node.parentGroup,
        hasNodeChildren: node => node.hasChildren,

        store: articleGroupsStore,
        allowSelectionAtLevels: levels,
        allowsInlineCreation: false,
        allowGroupEditing: false
    });

@Injectable({
    providedIn: 'root'
})
export class TreeAutocompleteConfigsService {
    get articleGroupConfig(): TreeDataSelectionConfig<ArticleGroupListModel> {
        return generateArticleGroupConfig(this.articleGroupsStore, this.translationService);
    }

    get articleGroupConfigForSelection(): TreeDataSelectionConfig<ArticleGroupListModel> {
        return generateArticleGroupConfigForSelection(this.articleGroupsStore);
    }

    get articleGroupConfigForSelection3rdLevel(): TreeDataSelectionConfig<ArticleGroupListModel> {
        return generateArticleGroupConfigForSelection(this.articleGroupsStore, [2]);
    }

    private readonly articleGroupsStore = inject(ArticleGroupsStore);
    private readonly translationService = inject(TranslationService);
}
