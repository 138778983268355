import { Component } from '@angular/core';

import { BaseCustomColumnComponent } from '../../../dynamic-table/column-components/custom-column/base-custom-column.component';
import { CamtFilePreviewModel } from '../../../../app/models/camt-file-preview.model';
import { CamtInvoiceStatusEnum } from '../../../../app/models/enums/camt-invoice-status.enum';

@Component({
    selector: 'arc-camt-status-column',
    templateUrl: './camt-status-column.component.html',
    styleUrls: ['./camt-status-column.component.scss']
})
export class CamtStatusColumnComponent extends BaseCustomColumnComponent<CamtFilePreviewModel> {
    CamtInvoiceStatusEnum = CamtInvoiceStatusEnum;
}
