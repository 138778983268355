import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Directive, Input, OnChanges, ViewContainerRef, inject } from '@angular/core';

import { BaseCardComponent } from '../../components/abstractions/base-card.component';
import { Identifyable } from '../abstractions/identifyable';
import { OptionalType } from '../models/types/optional.type';

@Directive({
    selector: '[arcCardContent]'
})
export class CardContentDirective<TList extends Identifyable<TId>, TId = number> implements OnChanges {
    @Input() item!: TList;
    @Input() component!: ComponentType<BaseCardComponent<TList, TId>>;

    private readonly viewContainerRef = inject(ViewContainerRef);

    private componentRef: OptionalType<ComponentRef<BaseCardComponent<TList, TId>>>;

    ngOnChanges(): void {
        if (!!this.componentRef) {
            this.componentRef.destroy();
        }

        this.componentRef = this.viewContainerRef.createComponent(this.component);
        this.componentRef.instance.item = this.item;

        this.componentRef.instance.afterItemLoad();
    }
}
