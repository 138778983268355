import { Component, WritableSignal, effect, inject, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import Big from 'big.js';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArticleEditModel } from '../../../../../models/requests/article-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { TreeAutocompleteConfigsService } from '../../../../../services/tree-autocomplete-configs.service';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { ArticleModel } from '../../../../../models/article.model';
import { PermissionsEnum } from '../../../../../models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { SharedDataService } from '../../../../../../core/services/shared-data.service';
import { ButtonToggleModel } from '../../../../../../core/models/button-toggle.model';
import { Tools } from '../../../../../../core/utils/tools';
import { UserService } from '../../../../../../core/services/user.service';
import { ArticleEditSuppliersComponent } from '../article-edit-suppliers/article-edit-suppliers.component';
import { ArticleSupplierEditModel } from '../../../../../models/requests/article-supplier-edit.model';
import { ArticleTaxModel } from '../../../../../models/article-tax.model';

@Component({
    selector: 'arc-article-edit-base-data',
    templateUrl: './article-edit-base-data.component.html',
    styleUrls: ['./article-edit-base-data.component.scss']
})
export class ArticleEditBaseDataComponent extends BaseEditSidebarItemComponent<ArticleModel, ArticleEditModel> {
    static readonly buyingPriceSignal = 'buyingPrice';
    static readonly taxRateSignal = 'taxRate';
    static readonly priceSignal = 'price';
    static readonly debounceTime = 500;
    override formGroup = new FormGroup({
        articleNumber: new ArcFormControl('', Validators.required),
        title_de: new ArcFormControl('', Validators.required),
        title_fr: new ArcFormControl<OptionalType<string>>(undefined),
        title_en: new ArcFormControl<OptionalType<string>>(undefined),
        title_it: new ArcFormControl<OptionalType<string>>(undefined),
        price: new ArcFormControl(0, Validators.required),
        buyingPrice: new ArcFormControl(0, Validators.required),
        articleGroupId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        taxId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required),
        unitId: new ArcFormControl<OptionalType<number>>(undefined, Validators.required)
    });

    GeneralDataTypeEnum = GeneralDataTypeEnum;
    PermissionsEnum = PermissionsEnum;
    PermissionTypeEnum = PermissionTypeEnum;
    UnitsEnum = UnitsEnum;
    articleTaxes: ArticleTaxModel[] = [];
    articleTaxItems: ButtonToggleModel<number>[] = [];

    shouldBindMainSupplierBuyingPrice = signal(false);

    readonly treeAutocompleteConfigs = inject(TreeAutocompleteConfigsService);
    readonly marginFormControl = new ArcFormControl<OptionalType<number>>(undefined, CustomValidators.number({ max: 100 }));

    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly userService = inject(UserService);

    private buyingPrice: WritableSignal<OptionalType<number>>;
    private taxRate: WritableSignal<number>;
    private price: WritableSignal<number>;
    private mainSupplier: WritableSignal<OptionalType<ArticleSupplierEditModel>>;

    constructor() {
        super();

        this.buyingPrice = this.sharedDataService.getOrCreateSignal(ArticleEditBaseDataComponent.buyingPriceSignal);
        this.taxRate = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditBaseDataComponent.taxRateSignal, 0);
        this.price = this.sharedDataService.getOrCreateSignalWithValue(ArticleEditBaseDataComponent.priceSignal, 0);
        this.mainSupplier = this.sharedDataService.getOrCreateSignal(ArticleEditSuppliersComponent.mainSupplierSignal);

        const priceChangedSub = this.formGroup.controls.price.valueChanges.subscribe(p => {
            this.price.set(p ?? 0);
        });

        const buyingPriceChangedSub = this.formGroup.controls.buyingPrice.valueChanges.subscribe(bp => {
            this.buyingPrice.set(bp ?? 0);
        });

        const marginChangedSub = this.marginFormControl.valueChanges.subscribe(m => {
            this.setPriceFromMargin(m ?? 0);
        });

        const taxIdChangedSub = this.formGroup.controls.taxId.valueChanges.subscribe(taxId => {
            this.taxRate.set(this.getTaxRateById(taxId));
        });

        // keep buying price linked to main supplier, if set
        effect(() => {
            const shouldBindMainSupplierBuyingPrice = this.shouldBindMainSupplierBuyingPrice();
            const mainSupplier = this.mainSupplier();
            if (!shouldBindMainSupplierBuyingPrice || !mainSupplier) {
                return;
            }

            const mainSupplierBuyingPriceExclusive = this.getMainSupplierBuyingPriceExclusiveVat();
            if (mainSupplierBuyingPriceExclusive !== this.formGroup.getRawValue().buyingPrice) {
                this.formGroup.controls.buyingPrice.setValue(mainSupplierBuyingPriceExclusive);
            }
        });

        // effect to update the margin when values change
        effect(() => {
            const buyingPriceIncl = this.getArticleBuyingPriceInclusiveVat();
            const price = this.price();

            if (price <= 0) {
                this.marginFormControl.setValue(0, { emitEvent: false });
                return;
            }

            if (buyingPriceIncl === 0) {
                this.marginFormControl.setValue(100, { emitEvent: false });
                return;
            }

            // margin = (1 - buyingPriceIncl / price) * 100
            const newMargin = Big(1).minus(Big(buyingPriceIncl).div(price)).times(100).toNumber();

            this.marginFormControl.setValue(newMargin, { emitEvent: false });
        });

        this.addSubscriptions(priceChangedSub, buyingPriceChangedSub, marginChangedSub, taxIdChangedSub);
    }

    onItemSet(): void {
        this.formGroup.patchValue(this.item);

        // set signals initial values
        this.buyingPrice.set(this.item.buyingPrice);
        this.price.set(this.item.price);


        // get tax rates
        this.generalDataStore.getGeneralData(GeneralDataTypeEnum.ArticleTaxes).subscribe(result => {
            const taxes = (result.value ?? []).map(tax => ({ key: +tax.key!, value: +tax.value! })).sort((t1, t2) => t1.value - t2.value);
            for (const tax of taxes) {
                const articleTax = {
                    id: tax.key,
                    taxRate: tax.value,
                    title: `${Tools.Utils.roundTo(tax.value * 100, 0.1)}%`
                };
                this.articleTaxes.push(articleTax);
                this.articleTaxItems.push({ value: articleTax.id, label: articleTax.title });
            }

            if (this.item.taxId !== undefined && !this.articleTaxes.find(t => t.id?.toString() === this.item.taxId.toString())) {
                // currently set tax id does not exist
                this.formGroup.patchValue({ taxId: undefined });
            } else {
                this.taxRate.set(this.getTaxRateById(this.item.taxId));

                // check if buying price is linked to main supplier
                if (this.item.buyingPrice === this.getMainSupplierBuyingPriceExclusiveVat()) {
                    this.linkBuyingPriceToMainSupplier();
                }
            }
        });
    }

    override prepareSaveModel(): Partial<ArticleEditModel> {
        return { ...this.formGroup.getRawValue() };
    }

    setPriceFromMargin(newMargin: number): void {
        if (newMargin === 100) {
            // do not trigger margin calculation
            this.formGroup.controls.price.setValue(0, { emitEvent: false });
            this.price.set(0);
            return;
        }

        const buyingPriceIncl = this.getArticleBuyingPriceInclusiveVat();
        // price = buyingPriceIncl / (1 - newMargin / 100)
        const newPrice = Big(buyingPriceIncl)
            .div(Big(1).minus(Big(newMargin).div(100)))
            .toNumber();
        const newPriceRounded = Tools.Utils.roundTo(newPrice, this.userService.defaultRoundTo);

        this.formGroup.controls.price.setValue(newPriceRounded, { emitEvent: false });
        this.price.set(newPriceRounded);
    }

    unlinkBuyingPriceFromMainSupplier(): void {
        this.shouldBindMainSupplierBuyingPrice.set(false);
        this.formGroup.controls.buyingPrice.enable();
    }

    linkBuyingPriceToMainSupplier(): void {
        this.shouldBindMainSupplierBuyingPrice.set(true);
        this.formGroup.controls.buyingPrice.setValue(this.getMainSupplierBuyingPriceExclusiveVat());
        this.formGroup.controls.buyingPrice.disable();
    }

    private getArticleBuyingPriceInclusiveVat(): number {
        // buyingPriceInclusiveVat = buyingPrice * (1 + taxRate)
        return Big(this.buyingPrice() ?? 0).times(Big(1).plus(this.taxRate())).toNumber();
    }

    private getMainSupplierBuyingPriceExclusiveVat(): number {
        const mainSupplier = this.mainSupplier();
        if (!mainSupplier) {
            return 0;
        }

        if (mainSupplier.buyingPriceExclusive) {
            return mainSupplier.buyingPrice;
        }

        // buyingPriceExclusive = buyingPrice / (1 + taxRate)
        const mainSupplierBuyingPriceExcl = Big(mainSupplier.buyingPrice).div(Big(1).plus(this.taxRate())).toNumber();
        return Tools.Utils.roundTo(mainSupplierBuyingPriceExcl, 0.01);
    }

    private getTaxRateById(taxId?: number): number {
        // eslint-disable-next-line eqeqeq
        const articleTax = this.articleTaxes.find(t => t.id == taxId);
        return !!articleTax ? articleTax.taxRate : this.item.taxRate;
    }
}
