<form [formGroup]="quickAddFormGroup" (submit)="quickAdd()" class="flex gap-4 items-center flex-wrap">
    <arc-quick-search #quickAddArticleControl label="Orders.Edit.Articles.Article" [config]="quickSearchConfig!"
        (optionSelected)="quickAddArticleChanged($event)" [formControl]="quickAddFormGroup.controls.articleStockId"
        class="basis-[200px] grow shrink-0">
    </arc-quick-search>

    <arc-number-input #quickAddQuantityControl label="WarehouseTransfers.Edit.Articles.Quantity"
        [unit]="quickAddQUnit()" [isUnitTranslated]="true"
        [formControl]="quickAddFormGroup.controls.quantity" class="basis-[150px] grow shrink-0">
    </arc-number-input>

    <button mat-icon-button (click)="quickAdd()" [disabled]="isQuickAddLoading">
        @if (isQuickAddLoading) {
            <mat-spinner [diameter]="24"></mat-spinner>
        } @else {
            <mat-icon>add</mat-icon>
        }
    </button>
</form>
