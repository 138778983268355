<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color" [arcErrorTooltip]="_formControl"
    [arcPortalTooltip]="label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    @if (!!label) {
        <mat-label>{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    }
    <mat-select [formControl]="internalControl" (selectionChange)="handleSelect($event)" [multiple]="isMultiselect() ? true : null"
        [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)">
        <mat-option *ngFor="let option of options()" [value]="option.value">
            {{ areOptionsTranslated() ? option.label : (option.label | transloco) }}
        </mat-option>
    </mat-select>
    @if (!isAllowed) {
        <span matSuffix>
            <arc-no-permission-lock [missingPermission]="permission">
            </arc-no-permission-lock>
        </span>
    }
</mat-form-field>
