import { Component } from '@angular/core';

@Component({
    selector: 'arc-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent {

}
