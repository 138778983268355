import { Component, OnInit } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-checkbox-column',
    templateUrl: './checkbox-column.component.html',
    styleUrls: ['./checkbox-column.component.scss']
})
export class CheckboxColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    override ngOnInit(): void {
        super.ngOnInit();
        this.classes = 'w-full';
    }
}
