<div class="flex flex-col h-full">
    <div class="grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Orders.Details.OrderData.Sent' | transloco }}</p>
                <p *ngIf="!!entityModel.dateSent" class="text-lg">{{ entityModel.dateSent | arcDate }}</p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Orders.Details.OrderData.Delivered' | transloco }}</p>
                <p *ngIf="!!entityModel.dateDelivered" class="text-lg">{{ entityModel.dateDelivered | arcDate }}</p>
            </div>
        </div>
        <!-- third column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Orders.Details.OrderData.Branch' | transloco }}</p>
                <p *ngIf="!!entityModel.branch" class="text-lg">{{ entityModel.branch }}</p>
            </div>
        </div>
    </div>
    <div class="flex grow mt-6">
        <div class="flex flex-col grow justify-center h-full bg-power-search text-on-power-search p-6">
            <span class="mt-2">{{ 'Orders.Details.OrderData.QuantityOrderedArticles' | transloco }}</span>
            <span class="mt-1 text-2xl">{{ entityModel.orderedArticlesQuantity }}</span>
            <span class="mt-8">{{ 'Orders.Details.OrderData.TotalOrderedArticles' | transloco }}</span>
            <span class="mt-1 text-2xl">{{ entityModel.orderedArticlesTotal | arcCurrency }}</span>
        </div>
        <div class="flex flex-col grow justify-center h-full bg-info text-on-info p-6">
            <span class="mt-2">{{ 'Orders.Details.OrderData.QuantityDeliveredArticles' | transloco }}</span>
            <span class="mt-1 text-2xl">{{ entityModel.deliveredArticlesQuantity > 0 ? entityModel.deliveredArticlesQuantity : '-' }}</span>
            <span class="mt-8">{{ 'Orders.Details.OrderData.TotalDeliveredArticles' | transloco }}</span>
            <span class="mt-1 text-2xl">
                {{ entityModel.deliveredArticlesTotal > 0 ? (entityModel.deliveredArticlesTotal | arcCurrency) : '-' }}
            </span>
        </div>
    </div>
</div>
