
export class CustomerAccountBookingDialogDataModel {
    personId!: number;
    accountId!: number;
    accountTypeId!: number;
    isDebit!: boolean;

    constructor(init: Partial<CustomerAccountBookingDialogDataModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
