import { Component, OnInit, inject } from '@angular/core';

import { StackedColumnModel } from '../../models/column-types/stacked-column.model';
import { BaseColumnComponent } from '../base-column.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';
import { PermissionService } from '../../../../core/services/permission.service';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';

@Component({
    selector: 'arc-stacked-column',
    templateUrl: './stacked-column.component.html',
    styleUrls: ['./stacked-column.component.scss']
})
export class StackedColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> implements OnInit {
    permission: true | AuthPermissionModel = true;

    private readonly permissionService = inject(PermissionService);

    override ngOnInit(): void {
        if (!!this.columnModel.requiredPermission) {
            this.permission = this.permissionService.hasPermission(
                this.columnModel.requiredPermission,
                this.columnModel.requiredPermissionType
            );
        }
    }

    get castedColumnModel(): StackedColumnModel {
        return this.columnModel as StackedColumnModel;
    }
}
