import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ProfileModel } from '../../models/profile.model';
import { ProfileListModel } from '../../models/responses/profile-list.model';
import { ProfileEditModel } from '../../models/profile-edit.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileStore extends BaseCrudStore<ProfileModel, ProfileListModel, ProfileEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'profile' }));
    }
}
