import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ArticleListModel } from '../../models/responses/article-list.model';
import { ArticleModel } from '../../models/article.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ArticleTurnoverModel } from '../../models/article-turnover.model';
import { ArticleStockDataModel } from '../../models/article-stock-data.model';
import { ArticleEditModel } from '../../models/requests/article-edit.model';

@Injectable({
    providedIn: 'root'
})
export class MainArticlesStore extends BaseCrudStore<ArticleModel, ArticleListModel, ArticleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'articles' }));
    }

    getTurnover(id: number): Observable<ApiResponseModel<ArticleTurnoverModel>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleTurnoverModel>>(this.getUrl(`${id}/turnover`));
    }

    getStockData(id: number): Observable<ApiResponseModel<ArticleStockDataModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<ArticleStockDataModel[]>>(this.getUrl(`${id}/stock-data`));
    }

    generateNewArticleIdentification(): Observable<ApiResponseModel<string>> {
        return this._requestService.makePost<ApiResponseModel<string>>(this.getUrl('identification/generate'));
    }
}
