import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ArticleSupplierModel } from '../../models/article-supplier.model';
import { ArticleSupplierListModel } from '../../models/responses/article-supplier-list.model';
import { ArticleSupplierEditModel } from '../../models/requests/article-supplier-edit.model';
import { RequestService } from '../../../core/services/request.service';

export class SupplierArticlesStore extends BaseCrudStore<ArticleSupplierModel, ArticleSupplierListModel, ArticleSupplierEditModel> {
    constructor(requestService: RequestService, supplierId: number) {
        super(new StoreConfig({ baseController: `article-suppliers/supplier/${supplierId}` }), requestService);
    }
}
