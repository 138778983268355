import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserEditModel } from '../../../../../models/user-edit.model';
import { UserModel } from '../../../../../models/user.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { UserEmploymentDateModel } from '../../../../../models/user-employment-date.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';

@Component({
    selector: 'arc-user-employment-dates',
    templateUrl: './user-employment-dates.component.html',
    styleUrl: './user-employment-dates.component.scss'
})
export class UserEmploymentDatesComponent extends BaseEditSidebarItemComponent<UserModel, UserEditModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        userEmploymentDates: new ArcFormControl<UserEmploymentDateModel[]>([])
    });
    userEmploymentDatesTableConfig: EditableTableConfigModel<UserEmploymentDateModel>;

    constructor() {
        super();
        this.userEmploymentDatesTableConfig = new EditableTableConfigModel<UserEmploymentDateModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl<number>(0),
                    fromDate: new ArcFormControl<Date>(new Date(), Validators.required),
                    toDate: new ArcFormControl<Date>(new Date(), Validators.required)
                }),
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new DateColumnModel({
                    propertyName: 'fromDate',
                    isEditable: true,
                    hasTimePicker: true,
                    hasTime: false,
                    columnTitleKey: 'Users.Edit.EmploymentDates.From'
                }),
                new DateColumnModel({
                    propertyName: 'toDate',
                    isEditable: true,
                    hasTimePicker: true,
                    hasTime: false,
                    columnTitleKey: 'Users.Edit.EmploymentDates.To',
                    minDateField: 'fromTime'
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.userEmploymentDatesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<UserEditModel> {
        return { userEmploymentDates: this.formGroup.value.userEmploymentDates };
    }
}
