<div class="p-8 h-full flex flex-col">
    <h2 class="font-semibold mb-4">{{ 'Widgets.WidgetTypes.Features' | transloco }}</h2>

    <mat-form-field subscriptSizing="dynamic" class="w-full mb-2">
        <input matInput type="text" [(ngModel)]="dataSource.filter" [placeholder]="'General.Search' | transloco">
    </mat-form-field>

    <div class="relative grow overflow-y-auto">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="title">
                    {{ 'Widgets.Features.Title' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-col py-2">
                        <span class="text-xs leading-none">{{ element.areaName }}</span>
                        <span>{{ element.title }}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
                    {{ 'Widgets.Features.Status' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="px-4 py-1 rounded-full w-fit line-clamp-1" [ngClass]="{
                        'bg-info-light text-on-info-light': element.status === FeatureStatusEnum.New,
                        'bg-warn-light text-on-warn-light': element.status === FeatureStatusEnum.Accepted,
                        'bg-success-light text-on-success-light': element.status === FeatureStatusEnum.InProgress,
                        'bg-success text-on-success': element.status === FeatureStatusEnum.Completed
                    }">
                        {{ element.statusName }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="vote">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="vote">
                    {{ 'Widgets.Features.Vote' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon color="warn" *ngIf="element.vote === -1">sentiment_dissatisfied</mat-icon>
                    <mat-icon class="!text-warn" *ngIf="element.vote === 0">sentiment_neutral</mat-icon>
                    <mat-icon color="accent" *ngIf="element.vote === 1">sentiment_satisfied</mat-icon>
                    <mat-icon *ngIf="element.vote === undefined">minimize</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="featured">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex items-center gap-2">
                        <mat-icon color="accent" *ngIf="element.isFeatured" [matTooltip]="'Widgets.Features.FeaturedToolTip' | transloco">
                            rocket_launch
                        </mat-icon>
                        <mat-icon class="ml-auto" color="accent" *ngIf="isNew(element)">fiber_new</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="cursor-pointer hover:!bg-element-hover"
                (click)="openFeatureDetails(element)">
            </tr>
            <tr *matNoDataRow>
                <td [attr.colspan]="displayedColumns.length" class="p-4 text-center">
                    {{ 'General.NoRecords' | transloco }}
                </td>
            </tr>
        </table>

        <div class="absolute z-[100] inset-0 bg-overlay grid place-items-center" *ngIf="isLoading">
            <mat-spinner [diameter]="64"></mat-spinner>
        </div>
    </div>

</div>