import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { ReservationDetailsModel } from '../../models/reservation-details.model';
import { ReservationListModel } from '../../models/responses/reservation-list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { ReservationArticleModel } from '../../models/reservation-article.model';
import { ReservationEditModel } from '../../models/reservation-edit.model';
import { ReservationModel } from '../../models/reservation.model';

@Injectable({
    providedIn: 'root'
})
export class ReservationsStore extends BaseCrudStore<ReservationDetailsModel, ReservationListModel, ReservationEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'reservations' }));
    }

    /**
     * Send the current reservation including the new reservation articles.
     * The new reservation articles only need to have articleId and quantity filled in, the rest will be sent back from the api.
     */
    prepareNewReservationArticles(reservation: ReservationModel): Observable<ApiResponseModel<ReservationArticleModel[]>> {
        return this._requestService.makePost<ApiResponseModel<ReservationArticleModel[]>>(this.getUrl('prepare-articles'), reservation);
    }

    recalculateArticles(
        data: ReservationModel,
        setPrice = false,
        setDiscount = false
    ): Observable<ApiResponseModel<ReservationArticleModel[]>> {
        return this._requestService.makePost<ApiResponseModel<ReservationArticleModel[]>>(
            this.getUrl('recalculate-articles'),
            data,
            undefined,
            undefined,
            undefined,
            undefined,
            { key: 'setPrice', value: setPrice.toString() },
            { key: 'setDiscount', value: setDiscount.toString() }
        );
    }
}
