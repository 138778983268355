<mat-form-field [floatLabel]="hasFloatingLabel ? 'always' : 'auto'" class="w-full" [color]="color" [arcErrorTooltip]="_formControl"
    [arcPortalTooltip]="label" [subscriptSizing]="hasFixedSubscript ? 'fixed' : 'dynamic'">
    <mat-label *ngIf="!!label">{{ isLabelTranslated ? label : (label | transloco) }}</mat-label>
    <input matInput (blur)="onBlur()" (change)="onInputChanged()" [attr.step]="inputStep"
        [placeholder]="(!placeholder || isPlaceholderTranslated) ? placeholder : (placeholder | transloco)" [formControl]="internalControl"
        [attr.min]="min" [attr.max]="max" [readonly]="isReadonly" (focus)="focusChanged.emit($event)" />
    <div matTextSuffix *ngIf="!!unit && isAllowed" class="text-on-app-light truncate" [style.max-width.px]="maxSuffixWidth">
        {{ isUnitTranslated ? unitText : (unitText | transloco) }}
    </div>
    <span *ngIf="!isAllowed" matSuffix>
        <arc-no-permission-lock [missingPermission]="permission">
        </arc-no-permission-lock>
    </span>
</mat-form-field>
