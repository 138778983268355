import { Component, Input } from '@angular/core';

import { AuthPermissionModel } from '../../app/models/auth-permission.model';

@Component({
    selector: 'arc-no-permission-lock',
    templateUrl: './no-permission-lock.component.html',
    styleUrls: ['./no-permission-lock.component.scss']
})
export class NoPermissionLockComponent {
    @Input({ required: true }) missingPermission?: true | AuthPermissionModel;
    @Input() iconSize = 16;
}
