import { Component, ElementRef, OnInit, ViewChild, signal } from '@angular/core';

import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { PosLayoutListModel } from '../../../../models/responses/pos-layout-list.model';

@Component({
    selector: 'arc-pos-layout-card',
    templateUrl: './pos-layout-card.component.html',
    styleUrl: './pos-layout-card.component.scss'
})
export class PosLayoutCardComponent extends BaseCardComponent<PosLayoutListModel> implements OnInit {
    @ViewChild('container', { static: true }) container!: ElementRef<HTMLDivElement>;

    readonly buttonsGapPx = 2;

    containerWidthPx?: number;
    topGridStyle = signal({});
    bottomGridStyle = signal({});

    ngOnInit(): void {
        const containerRect = this.container.nativeElement.getBoundingClientRect();
        this.containerWidthPx = containerRect.width;
        this.setupGrids();
    }

    override afterItemLoad(): void {
        this.setupGrids();
    }

    transformBackgroundColor(bgColor?: string): string {
        return (!!bgColor && bgColor !== '#ffffff') ? bgColor : '#eee';
    }

    private setupGrids(): void {
        if (!this.item || !this.containerWidthPx) {
            return;
        }

        const maxColumnsAmount = this.item.bottomGroup?.columns || this.item.topGroup.columns;
        const buttonSizePx = this.containerWidthPx / maxColumnsAmount - this.buttonsGapPx + (this.buttonsGapPx / maxColumnsAmount);

        this.topGridStyle.set({
            'grid-template-columns': `repeat(${this.item.topGroup.columns}, ${buttonSizePx}px)`,
            'grid-template-rows': `repeat(${this.item.topGroup.rows}, ${buttonSizePx}px)`,
            'gap': `${this.buttonsGapPx}px`
        });

        this.bottomGridStyle.set({
            'grid-template-columns': `repeat(${this.item.bottomGroup?.columns}, ${buttonSizePx}px)`,
            'grid-template-rows': `repeat(${this.item.bottomGroup?.rows}, ${buttonSizePx}px)`,
            'gap': `${this.buttonsGapPx}px`
        });
    }
}
