import { Component, inject, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatMenuTrigger } from '@angular/material/menu';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { QueryDesignerEditModel } from '../../../../../models/query-designer-edit.model';
import { QueryDesignerReportColumnModel } from '../../../../../models/responses/query-designer-report-column.model';
import { QueryDesignerStore } from '../../../../../services/stores/query-designer.store';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { QueryDesignerColumnModel } from '../../../../../models/responses/query-designer-column.model';
import { QueryDesignerModel } from '../../../../../models/query-designer.model';

@Component({
    selector: 'arc-query-designer-edit-view',
    templateUrl: './query-designer-edit-view.component.html',
    styleUrls: ['./query-designer-edit-view.component.scss']
})
export class QueryDesignerEditViewComponent extends BaseEditSidebarItemComponent<QueryDesignerModel, QueryDesignerEditModel> {
    @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

    isLoadingColumns = false;
    hasChanges = false;
    availableColumns: QueryDesignerColumnModel[] = [];
    reportColumns: QueryDesignerReportColumnModel[] = [];

    private readonly queryDesignerStore = inject(QueryDesignerStore);

    override onItemSet(): void {
        this.reportColumns = this.item.reportColumns.sort((a, b) => (a.columnSequence > b.columnSequence) ? 1 : -1);
    }

    override hasUnsavedChanges(): boolean {
        return this.hasChanges;
    }

    override prepareSaveModel(): Partial<QueryDesignerEditModel> {
        let columnSequence = 0;
        const reportColumns = this.reportColumns.map(rc => {
            columnSequence++;
            return {
                id: rc.id,
                columnSequence,
                queryDesignerReportId: rc.queryDesignerReportId!,
                columnId: rc.columnId
            };
        });

        return { reportColumns };
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.reportColumns, event.previousIndex, event.currentIndex);
        this.hasChanges = true;
    }

    remove(col: QueryDesignerReportColumnModel): void {
        const existingIdx = this.reportColumns.findIndex(rc => rc.columnId === col.columnId);

        if (existingIdx >= 0) {
            this.reportColumns.splice(existingIdx, 1);
            this.hasChanges = true;
        }
    }

    loadColumns(): void {
        this.isLoadingColumns = true;
        this.availableColumns = [];
        let firstColumnId: OptionalType<number>;

        if (this.reportColumns.length > 0) {
            firstColumnId = this.reportColumns[0].columnId;
        }

        this.queryDesignerStore.getColumnSuggestions(firstColumnId).subscribe(resp => {
            const usedIds = this.reportColumns.map(rc => rc.columnId);
            this.availableColumns = resp.value?.filter(c => !usedIds.includes(c.id)) || [];

            this.isLoadingColumns = false;

            if (this.availableColumns.length > 0) {
                this.trigger.openMenu();
            }
        });
    }

    addColumn(col: QueryDesignerColumnModel): void {
        const existingCol = this.reportColumns.length > 0
            ? this.reportColumns[this.reportColumns.length - 1]
            : undefined;

        this.reportColumns.push({
            id: 0,
            columnId: col.id,
            queryDesignerReportId: existingCol?.queryDesignerReportId,
            columnSequence: (existingCol?.columnSequence || 0) + 1,
            column: col
        });

        this.hasChanges = true;
    }
}
