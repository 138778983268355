import { Component } from '@angular/core';

import { InventoryModel } from '../../../../models/inventory.model';
import { BaseSidebarHeaderAdditionalInfoComponent } from '../../../../../components/sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';
import { InventoryStatusEnum, InventoryStatusEnumExtensions } from '../../../../models/enums/inventory-status.enum';

@Component({
    selector: 'arc-inventory-edit-header-additional-info',
    templateUrl: './inventory-edit-header-additional-info.component.html',
    styleUrl: './inventory-edit-header-additional-info.component.scss'
})
export class InventoryEditHeaderAdditionalInfoComponent extends BaseSidebarHeaderAdditionalInfoComponent<InventoryModel> {
    statusClass = '';
    statusKey = '';

    override onItemSet(): void {
        this.statusClass = InventoryStatusEnumExtensions.getColor(this.item.status);
        switch (this.item.status) {
            case InventoryStatusEnum.Open:
                this.statusKey = 'Inventories.Status.Open';
                break;
            case InventoryStatusEnum.Finished:
                this.statusKey = 'Inventories.Status.Finished';
                break;
        }
    }
}
