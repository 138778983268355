import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ArcavisLogModel } from '../../models/arcavis-log.model';
import { ArcavisLogListModel } from '../../models/responses/arcavis-log-list.model';

@Injectable({
    providedIn: 'root'
})
export class ArcavisLogsStore extends BaseCrudStore<ArcavisLogModel, ArcavisLogListModel, ArcavisLogModel, ArcavisLogModel, string> {
    constructor() {
        super(new StoreConfig({ baseController: 'logs' }));
    }
}
