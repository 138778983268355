import { ComponentType } from '@angular/cdk/portal';

import { BaseSidebarComponent } from '../../components/sidebar-components/base-sidebar/base-sidebar.component';

export class RightSidebarConfigModel {
    component!: ComponentType<BaseSidebarComponent>;
    data?: any;
    width?: string | 'full' | 'auto' = 'auto';
    maxWidth?: string;
    shouldCloseOnBackdropClick? = true;

    constructor(init?: Partial<RightSidebarConfigModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
