import { Component } from '@angular/core';

import { TransactionTypeListModel } from '../../../../models/responses/transaction-type-list.model';
import { BaseCardComponent } from '../../../../../components/abstractions/base-card.component';
import { TransactionTypeConfigurationsEnum } from '../../../../models/enums/transaction-type-configurations.enum';

@Component({
    selector: 'arc-transaction-type-card',
    templateUrl: './transaction-type-card.component.html',
    styleUrl: './transaction-type-card.component.scss'
})
export class TransactionTypeCardComponent extends BaseCardComponent<TransactionTypeListModel> {
    TransactionTypeConfigurations = TransactionTypeConfigurationsEnum;
}
