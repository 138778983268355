import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseStore } from '../../../core/abstractions/base.store';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { OptionalType } from '../../../core/models/types/optional.type';

@Injectable({
    providedIn: 'root'
})
export class BlobsStore extends BaseStore {
    constructor() {
        super(new StoreConfig({ baseController: 'blobs' }));
    }

    /**
     *  Retrieves the contents of a file by its name.
     */
    getFileByName(name: string): Observable<ApiResponseModel<OptionalType<string>>> {
        return this._requestService.makeGet<ApiResponseModel<string>>(this.getUrl(`${name}/content`));
    }
}
