<h1 mat-dialog-title>
    {{ 'WarehouseTransfers.CreateTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] !flex flex-col">
    <div class="pt-2 grow w-full flex flex-col gap-4">
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Stores"
            [formControl]="formGroup.controls.deliveringStoreId" label="WarehouseTransfers.Create.DeliveringStore">
        </arc-general-data-select>
        <arc-general-data-select [generalDataType]="GeneralDataTypeEnum.Stores"
            [formControl]="formGroup.controls.receivingStoreId" label="WarehouseTransfers.Create.ReceivingStore">
        </arc-general-data-select>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="{ shouldReload: false }">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating" (clicked)="create()">
        {{ 'General.Actions.Create' | transloco }}
    </arc-button>
</mat-dialog-actions>