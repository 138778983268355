import { AfterViewInit, Component, HostBinding, ViewChild, inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';

import { InvoiceCreateModel } from '../../../../models/requests/invoice-create.model';
import { InvoiceCreateCustomerModel } from '../../../../models/requests/invoice-create-customer.model';
import { InvoicesStore } from '../../../../services/stores/invoices.store';
import { InvoiceSuggestionModel } from '../../../../models/responses/invoice-suggestion.model';
import { ArcFormControl } from '../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../core/models/types/optional.type';
import { BaseComponent } from '../../../../../components/abstractions/base.component';
import { ToasterService } from '../../../../../core/services/toaster.service';

@Component({
    selector: 'arc-invoice-create-dialog',
    templateUrl: './invoice-create-dialog.component.html',
    styleUrls: ['./invoice-create-dialog.component.scss']
})
export class InvoiceCreateDialogComponent extends BaseComponent implements AfterViewInit {
    @HostBinding('class') classes = 'grow flex flex-col overflow-y-auto';

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    displayedColumns = [
        'checkbox',
        'personName',
        'amount',
        'dueDate'
    ];

    isLoading = false;
    isCreating = false;

    issuedDate = new ArcFormControl<OptionalType<Date>>(new Date(), Validators.required);
    searchText = new ArcFormControl('');

    datasource: MatTableDataSource<InvoiceSuggestionModel>;
    selection: InvoiceSuggestionModel[] = [];

    protected _debounceTimeMs = 150;

    private readonly fetchSuggestionsSubject = new Subject<Date>();

    private readonly invoicesStore = inject(InvoicesStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);

    constructor() {
        super();

        this.datasource = new MatTableDataSource<InvoiceSuggestionModel>([]);
        this.datasource.filterPredicate = (element, filter) =>
            element.personName.toLowerCase().includes(filter)
            || element.amount.toString() === filter;

        this.fetchSuggestionsSubject.pipe(
            distinctUntilChanged((prev, curr) => prev.getDate() === curr.getDate()),
            tap(() => {
                this.isLoading = true;
                this.selection = [];
                this.datasource.data = [];
            }),
            debounceTime(this._debounceTimeMs),
            switchMap(issuedDate => this.invoicesStore.getInvoiceSuggestions(issuedDate))
        ).subscribe(result => {
            this.datasource.data = result.value ?? [];
            this.selection = [...this.datasource.data];
            this.isLoading = false;
        });

        this.fetchSuggestions(this.issuedDate.value);
        const issuedDateChangeSub = this.issuedDate.valueChanges.subscribe(value => {
            this.fetchSuggestions(value);
        });

        const searchTextChangeSub = this.searchText.valueChanges.subscribe(searchText => {
            this.datasource.filter = searchText;
        });

        this.addSubscriptions(issuedDateChangeSub, searchTextChangeSub);
    }

    ngAfterViewInit(): void {
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
    }

    create(): void {
        this.issuedDate.markAsTouched();
        this.issuedDate.updateValueAndValidity({ emitEvent: false });
        if (this.issuedDate.invalid) {
            return;
        }

        this.isCreating = true;

        // if all are selected, send empty array to backend (which means create all)
        const selection = this.selection.length === this.datasource.data.length ? [] : this.selection;
        const createModel = new InvoiceCreateModel({
            issuedDate: this.issuedDate.value,
            customers: selection.map(s => ({
                customerId: s.personId,
                dueDate: s.dueDate
            }) as InvoiceCreateCustomerModel)
        });

        this.invoicesStore.add(createModel).subscribe(response => {
            if (response.value) {
                this.matDialogRef.close(true);
            } else {
                this.toasterService.showError('General.Alert.UnexpectedError');
                this.isCreating = false;
            }
        });
    }

    isSelected(element: InvoiceSuggestionModel): boolean {
        return !!this.selection.find(i => i.personId === element.personId);
    }

    handleSelectionChanged(element: InvoiceSuggestionModel): void {
        const index = this.selection.findIndex(i => i.personId === element.personId);
        if (index >= 0) {
            this.selection.splice(index, 1);
        } else {
            this.selection.push(element);
        }
    }

    handleSelectAll(): void {
        if (this.selection.length === this.datasource.data.length) {
            // unselect all
            this.selection = [];
        } else {
            // select all
            this.selection = [...this.datasource.data];
        }
    }

    private fetchSuggestions(issuedDate?: Date): void {
        if (!issuedDate) {
            return;
        }

        this.fetchSuggestionsSubject.next(issuedDate);
    }
}
