import { Pipe, PipeTransform, inject } from '@angular/core';

import { ComplexDataTypesEnum } from '../models/complex-data-types.enum';
import { QuickSearchConfigModel } from '../../components/form/quick-search/models/quick-search-config.model';
import { ArticleListModel } from '../../app/models/responses/article-list.model';
import { CustomerListModel } from '../../app/models/responses/customer-list.model';
import { SupplierListModel } from '../../app/models/responses/supplier-list.model';
import { CustomersStore } from '../../app/services/stores/customers.store';
import { SuppliersStore } from '../../app/services/stores/suppliers.store';
import { SubscriptionTypeArticlesStore } from '../../app/services/stores/subscription-type-article.store';
import { ArticlesStore } from '../../app/services/stores/articles.store';

@Pipe({ name: 'arcQuickSearchConfig' })
export class QuickSearchConfigPipe implements PipeTransform {
    private readonly articlesStore = inject(ArticlesStore);
    private readonly customersStore = inject(CustomersStore);
    private readonly supplierStore = inject(SuppliersStore);
    private readonly subscriptionTypeArticlesStore = inject(SubscriptionTypeArticlesStore);

    transform(complexDataType: ComplexDataTypesEnum, shouldHideDataSelectDialogButton = false): QuickSearchConfigModel<any> {
        switch (complexDataType) {
            case ComplexDataTypesEnum.Article:
                return new QuickSearchConfigModel<ArticleListModel>({
                    store: this.articlesStore,
                    displayFn: article => article?.title ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
            case ComplexDataTypesEnum.Customer:
                return new QuickSearchConfigModel<CustomerListModel>({
                    store: this.customersStore,
                    displayFn: customer => customer?.displayName ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
            case ComplexDataTypesEnum.Supplier:
                return new QuickSearchConfigModel<SupplierListModel>({
                    store: this.supplierStore,
                    displayFn: supplier => supplier?.displayName ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
            case ComplexDataTypesEnum.SubscriptionTypeArticle:
                return new QuickSearchConfigModel<ArticleListModel>({
                    store: this.subscriptionTypeArticlesStore,
                    displayFn: article => article?.title ?? '',
                    shouldHideDataSelectDialogButton,
                    dataSelectionDialogConfig: { type: complexDataType }
                });
        }
    }
}
