import { BaseSearchStore } from '../../../../core/abstractions/base-search.store';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

export class DataSelectionDialogCustomDataModel<T extends Identifyable<TId>, TId> {
    dialogTitleKey!: string;
    store!: BaseSearchStore<T, TId>;
    columnConfig!: BaseColumnModel[];
    isMultiSelect? = false;

    constructor(init: DataSelectionDialogCustomDataModel<T, TId>) {
        Object.assign(this, init);
        this.isMultiSelect = init.isMultiSelect ?? false;
    }
}
