import { Injectable, inject } from '@angular/core';
import { Observable, Subject, filter, map, mergeMap, of, tap } from 'rxjs';

import { CacheService } from './cache.service';
import { PortalStore } from '../../app/services/stores/portal.store';
import { FeatureModel } from '../../app/models/feature.model';
import { OptionalType } from '../models/types/optional.type';
import { VoteTypeEnum } from '../../app/models/enums/vote-type.enum';

@Injectable({
    providedIn: 'root'
})
export class FeatureService {
    featureChangedSub = new Subject<string>();

    private readonly portalStore = inject(PortalStore);
    private readonly cacheService = new CacheService();

    featureChanged(id?: string): Observable<OptionalType<FeatureModel>> {
        return this.featureChangedSub.asObservable().pipe(
            filter(featureId => id === undefined || featureId.toString() === id.toString()),
            mergeMap(featureId => this.getFeatureById(featureId))
        );
    }

    getFeatureById(id: string): Observable<OptionalType<FeatureModel>> {
        const value = this.cacheService.get<FeatureModel>(id);

        if (!!value) {
            return of(value);
        }

        return this.portalStore.getFeatureById(id).pipe(
            map(data => data.value ?? undefined),
            tap(data => this.cacheService.set(id, data))
        );
    }

    getFeatures(): Observable<FeatureModel[]> {
        return this.portalStore.getFeatures().pipe(
            map(response => response.value ?? [])
        );
    }

    voteForFeature(id: string, vote: VoteTypeEnum, comment?: string): Observable<boolean> {
        this.cacheService.clear(id);
        return this.portalStore.voteForFeature(id, vote, comment).pipe(
            tap(() => this.featureChangedSub.next(id)),
            map(result => result.value ?? false)
        );
    }
}
