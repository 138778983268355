import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { PointOfSaleListModel } from '../../models/responses/pos-list.model';
import { StoreConfig } from '../../../core/models/store.config';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { SimpleTransactionModel } from '../../models/simple-transaction.model';
import { PointOfSaleModel } from '../../models/point-of-sale.model';
import { PointOfSaleEditModel } from '../../models/requests/point-of-sale-edit.model';

@Injectable({
    providedIn: 'root'
})
export class PosStore extends BaseCrudStore<PointOfSaleModel, PointOfSaleListModel, PointOfSaleEditModel, PointOfSaleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'points-of-sale' }));
    }

    getTransactions(id: string): Observable<ApiResponseModel<SimpleTransactionModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<SimpleTransactionModel[]>>(this.getUrl(`${id}/transactions`));
    }
}
