<div class="flex flex-row gap-8">
    <div class="flex flex-col gap-4">
        <span class="text-base">{{ 'CustomerProfiles.Edit.CollectPoints' | transloco }}</span>
        <arc-number-input label="CustomerProfiles.EditFields.BonusFactor" [decimalPlaces]="2"
            [formControl]="formGroup.controls.bonusFactor">
        </arc-number-input>
    </div>
    <div class="flex flex-col gap-4">
        <span class="text-base">{{ 'CustomerProfiles.Edit.RedeemPoints' | transloco }}</span>
        <div class="flex flex-row">
            <arc-button-toggle-group [control]="formGroup.controls.isDiscount" [items]="voucherPermissionItems"></arc-button-toggle-group>
            <arc-checkbox class="m-auto" *ngIf="!formGroup.value.isDiscount"
                labelKey="CustomerProfiles.Edit.CashbackInPercent"
                [control]="formGroup.controls.cashbackInPercent">
            </arc-checkbox>
        </div>
    </div>
</div>
<arc-editable-table *ngIf="formGroup.value.isDiscount" [config]="discountTableConfig"
    [formControl]="formGroup.controls.customerDiscounts">
</arc-editable-table>

<arc-editable-table *ngIf="!formGroup.value.isDiscount && !formGroup.value.cashbackInPercent"
    [config]="cashbackTableConfig" [formControl]="formGroup.controls.customerCashbacks">
</arc-editable-table>
<arc-editable-table *ngIf="!formGroup.value.isDiscount && formGroup.value.cashbackInPercent"
    [config]="cashbackInPercentTableConfig" [formControl]="formGroup.controls.customerCashbacksInPercent">
</arc-editable-table>

<arc-checkbox labelKey="CustomerProfiles.EditFields.NoRemainingBonusPoints"
    [control]="formGroup.controls.noRemainingBonusPoints">
</arc-checkbox>