<div class="grid items-center grid-cols-12 grid-flow-row auto-cols-auto gap-4" arcQuickFeedback="41"
    [quickFeedbackPosition]="{ top: 10, left: 341 }">
    <arc-autocomplete class="col-span-6 ssm:col-span-9" [formControl]="streetControl" label="General.Address.Street"
        [searchFn]="searchFn.bind(this)" [optionDisplayAccessor]="optionDisplayFn.bind(this)" valueAccessor="street"
        [allowArbitraryValues]="true" (optionSelected)="handleAddressSelected($event)" (blurred)="onStreetControlBlur()">
    </arc-autocomplete>

    <arc-input class="col-span-6 ssm:col-span-3" labelKey="General.Address.StreetNumber" [control]="streetNumberControl"></arc-input>

    @if (!!streetSupplementControl) {
        <arc-input class="col-span-6 ssm:col-span-12" labelKey="General.Address.StreetSupplement" [control]="streetSupplementControl">
        </arc-input>
    }

    @if (!!isPoBoxControl) {
        <arc-checkbox class="col-span-3 ssm:col-span-6" labelKey="General.Address.IsPoBox" [control]="isPoBoxControl"></arc-checkbox>
    }

    @if (!!poBoxControl) {
        <arc-input class="col-span-2 ssm:col-span-4" labelKey="General.Address.PoBox" [control]="poBoxControl"></arc-input>
    }

    <div class="col-span-1 ssm:col-span-2"></div>
    <arc-place-select class="col-span-12" [zipFormControl]="zipControl" [cityFormControl]="cityControl"
        [countryIsoCodeFormControl]="countryIsoCodeControl"></arc-place-select>
</div>
