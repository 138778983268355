@if(!!createTicketResponse) {
    <div class="flex flex-col gap-4 my-4">
        <arc-input [control]="titleControl"></arc-input>
        <span>{{ 'Tickets.Create.Message' | transloco }}</span>
        <arc-markdown-editor [formControl]="formGroup.controls.message" imageUploadLocation="portaldb" />
        <arc-file-upload class="block w-full !h-[60px] px-[2px]" [shouldForceMinHeight]="false" [formControl]="fileControl"
            [allowUrlUpload]="false" [maxFileSizeInKb]="2048">
        </arc-file-upload>
        @for(file of formGroup.value.attachments; track file.fileName) {
            <div class="flex items-center p-4 bg-gray-100 rounded-lg">
                <mat-icon class="text-gray-700">insert_drive_file</mat-icon>
                <span class="ml-2 text-gray-700">{{ file.fileName }}</span>
                <button mat-icon-button class="ml-auto" (click)="removeFile(file.blobDataId)">
                    <mat-icon class="text-gray-700">delete</mat-icon>
                </button>
            </div>
        }
    </div>
    @if(createTicketResponse.additionalData.length > 0) {
        <arc-dynamic-form #dynamicForm [formFields]="createTicketResponse.additionalData" (formSubmitted)="onFormSubmitted($event)"
            classLayout="grid grid-cols-2 gap-x-4"></arc-dynamic-form>
    }
    <div class="flex flex-col items-start">
        <div class="text-xl font-semibold mb-4">{{ 'Tickets.Create.Priority.Name' | transloco }}</div>

        <div class="w-full px-32 overflow-hidden smd:px-16">
            <arc-selection-slider [options]="prioritySliderOptions" [formControl]="formGroup.controls.priority"
                [sliderClasses]="sliderColor()">
            </arc-selection-slider>
        </div>
    </div>
}
