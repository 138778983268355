import { inject } from '@angular/core';
import {  CanActivateChildFn, Router } from '@angular/router';

import { PermissionService } from '../permission.service';
import { OptionalType } from '../../models/types/optional.type';
import { RouteDataModel } from '../../models/route-data.model';


export const permissionGuard: CanActivateChildFn = (route) => {
    const permissionService = inject(PermissionService);
    const router = inject(Router);

    const data = route.data as OptionalType<RouteDataModel>;
    if (!data || !data.requiredPermission) {
        return true;
    }

    const permission = permissionService.hasPermission(data.requiredPermission, data.requiredPermissionType);
    if (permission === true) {
        return true;
    }

    router.navigate(['/no-permission'], {
        state: {
            missingPermission: permission,
            routeData: data
        }
    });
    return false;
};
