@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} 
@else if(!!selectedTicket()) {
    <arc-view-ticket [ticket]="selectedTicket()!"></arc-view-ticket>
}
@else if(openTickets().length > 0) {
    <div class="flex flex-col gap-4 mt-8 mb-10">
        @for (ticket of openTickets(); track ticket.id) {
            <arc-ticket-list-item [ticket]="ticket" (ticketSelected)="selectTicket($event)"></arc-ticket-list-item>
        }
    </div>
}