<div class="flex h-full gap-2">
    <div class="w-[45%]">
        @if(!!entityModel.profileImageBlobFileName){
            <div class="flex items-center justify-center h-full user-image">
                @if(!!imageSrc) {
                    <img [src]="getBase64Src()"/>
                } 
                @else {
                    <div class="flex items-center justify-center">
                        <mat-spinner [diameter]="64"></mat-spinner>
                    </div>
                }
            </div>
        }
        @else {
            <div
                class="flex items-center justify-center h-full border border-solid border-on-app-light mx-3">
                <span class="text-sm text-center">
                    {{'Users.Details.NoImageAvailable' | transloco}}
                </span>
            </div>

        }
    </div>
    <div class="w-[50%] pl-2">
        <div>
            <p class="text-sm text-on-app-light">{{'Users.Details.Username' | transloco}}</p>
            <p class="text-m">{{ entityModel.username }}</p>
        </div>
        <div class="mt-4">
            <p class="text-sm text-on-app-light">{{'Users.Details.RealName' | transloco}}</p>
            <p class="text-m">{{ entityModel.realName }}</p>
        </div>
        <div class="mt-4">
            <p class="text-sm text-on-app-light">{{'Users.Details.Contact' | transloco}}</p>
            <p class="text-m">{{ entityModel.contactPhone }}</p>
            <p class="text-m">{{ entityModel.contactMobile }}</p>
            <p class="text-m truncate">{{ entityModel.email }}</p>
        </div>
        <div class="mt-4">
            <p class="text-sm text-on-app-light">{{'Users.Details.Role' | transloco}}</p>
            <p class="text-m">{{ entityModel.roleName }}</p>
        </div>
    </div>
</div>