import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { CustomerProfileModel } from '../../../../../models/customer-profile.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { UserService } from '../../../../../../core/services/user.service';

@Component({
    selector: 'arc-customer-profiles-base-data',
    templateUrl: './customer-profiles-base-data.component.html',
    styleUrl: './customer-profiles-base-data.component.scss'
})
export class CustomerProfilesBaseDataComponent extends BaseEditSidebarItemComponent<CustomerProfileModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        title: new ArcFormControl('', Validators.required),
        isDefault: new ArcFormControl(false, Validators.required),
        priceLevel: new ArcFormControl<OptionalType<number>>(undefined),
        accountMinAmount: new ArcFormControl<OptionalType<number>>(undefined)
    });

    private readonly userService = inject(UserService);

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<CustomerProfileModel> {
        const value = this.formGroup.value;
        return {
            title: value.title,
            isDefault: value.isDefault,
            priceLevel: value.priceLevel,
            accountMinAmount: value.accountMinAmount
        };
    }

    get currencyCode(): string {
        return this.userService.getUserInfo()!.currencyIsoCode;
    }
}
