import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { StoreModel } from '../../../../../models/store.model';
import { AccountTypeModel } from '../../../../../models/account-type.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';

@Component({
    selector: 'arc-settings-account-types',
    templateUrl: './settings-account-types.component.html',
    styleUrl: './settings-account-types.component.scss'
})
export class SettingsAccountTypesComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        accountTypes: new ArcFormControl<AccountTypeModel[]>([])
    });
    accountTypesTableConfig: EditableTableConfigModel<StoreModel>;

    constructor() {
        super();
        this.accountTypesTableConfig = new EditableTableConfigModel<StoreModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    title: new ArcFormControl(''),
                    contextTypeId: new ArcFormControl(0),
                    minAmount: new ArcFormControl<OptionalType<number>>(undefined),
                    debit: new ArcFormControl(false),
                    cumulateBalance: new ArcFormControl(false),
                    allowInvoice: new ArcFormControl(false),
                    paymentDays: new ArcFormControl<OptionalType<number>>(undefined)
                })
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.accountTypesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { accountTypes: this.formGroup.value.accountTypes };
    }
}
