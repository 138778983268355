import { Component, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { UnitsEnum } from '../../../../../../core/models/enums/units.enum';
import { ArticleStockModel } from '../../../../../models/article-stock.model';
import { ArticleStocksStore } from '../../../../../services/stores/article-stocks.store';
import { Tools } from '../../../../../../core/utils/tools/index';
import { ArticleStockEditModel } from '../../../../../models/article-stock-edit.model';

@Component({
    selector: 'arc-article-stock-edit-base-data',
    templateUrl: './article-stock-edit-base-data.component.html',
    styleUrls: ['./article-stock-edit-base-data.component.scss']
})
export class ArticleStockEditBaseDataComponent extends BaseEditSidebarItemComponent<ArticleStockModel, ArticleStockEditModel, string> {
    UnitsEnum = UnitsEnum;
    shouldReload = false;
    isUpdatingStock = signal(false);
    stockValue?: number;

    override formGroup = new FormGroup({
        minStock: new ArcFormControl<OptionalType<number>>(undefined),
        targetStock: new ArcFormControl<OptionalType<number>>(undefined)
    });

    private readonly articleStocksStore = inject(ArticleStocksStore);

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ArticleStockEditModel> {
        const value = this.formGroup.value;
        return {
            minStock: value.minStock,
            targetStock: value.targetStock
        };
    }

    override shouldReloadOnCancel(): boolean {
        return this.shouldReload;
    }

    updateStock(): void {
        this.isUpdatingStock.set(true);
        this.articleStocksStore.getStock(Tools.Utils.getId(this.item.id)).subscribe(r => {
            this.stockValue = r.value;
            this.item.stock = r.value ?? 0;

            this.isUpdatingStock.set(false);
        });
    }
}
