import { TicketHistoryModel } from '../../../app/models/ticket-history.model';
import { TicketMessageModel } from '../../../app/models/ticket-message.model';

export interface TicketMessageOrEvent {
    id: string;
    date: Date;
    type: TicketMessageTypeEnum;
    item: TicketMessageModel | TicketHistoryModel;
}
export enum TicketMessageTypeEnum {
    Message = 'Message',
    Event = 'Event'
}
