import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { TransactionPaymentModel } from '../../models/responses/transaction-payment.model';
import { TransactionPaymentListModel } from '../../models/responses/transaction-payment-list.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionPaymentsStore extends BaseCrudStore<TransactionPaymentModel, TransactionPaymentListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'transaction-payments' }));
    }
}
