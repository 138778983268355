import { Identifyable } from '../../core/abstractions/identifyable';

export class ReportModel implements Identifyable {
    id!: number;
    title!: string;
    title_de!: string;
    title_en?: string;
    title_fr?: string;
    title_it?: string;
    description?: string;
    description_de?: string;
    description_en?: string;
    description_fr?: string;
    description_it?: string;
    requiredRoleId?: number;
    version!: string;
    isUpdateAvailable = false;
    isFavourite = false;
    isCustomReport = false; // This is not being used
    reportBlobData?: number[];
    tableName?: string;

    constructor(init?: Partial<ReportModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
