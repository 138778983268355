import { Component } from '@angular/core';

import {
    BaseCustomColumnComponent
} from '../../../../../../components/dynamic-table/column-components/custom-column/base-custom-column.component';
import { MessageListModel } from '../../../../../models/responses/message-list.model';

@Component({
    selector: 'arc-message-priority-column',
    templateUrl: './message-priority-column.component.html',
    styleUrls: ['./message-priority-column.component.scss']
})
export class MessagePriorityColumnComponent extends BaseCustomColumnComponent<MessageListModel> {}
