<div class="h-full">
    <div class="h-full w-full overflow-auto">
        <div class="grid grid-cols-2 mt-4">
            <div>
                <span class="text-on-app-light text-sm">{{'Transactions.Details.Remarks' | transloco}}</span>
                <br/>
                <span>
                    {{entityModel.remarks}}
                </span>
            </div>
            <div class="ml-4">
                <span class="text-on-app-light text-sm">{{'Transactions.Details.BonusPointsReceived' | transloco}}</span>
                <br/>
                <span>
                    {{entityModel.bonusPoints}}
                </span>
                <br/>
                <br/>
                <span class="text-on-app-light text-sm">{{'Transactions.Details.GroupReference' | transloco}}</span>
                <br/>
                <span>
                    {{entityModel.groupReference}}
                </span>
                <br/>
                <br/>
                <span class="text-on-app-light text-sm">{{'General.Status' | transloco}}</span>
                <br/>
                <span>
                    {{entityModel.status}}
                </span>
                <br/>
                <br/>
                <span class="text-on-app-light text-sm">{{'Transactions.Details.OrderNumber' | transloco}}</span>
                <br/>
                <span>
                    {{orderNumber}}
                </span>
            </div>
        </div>
    </div>
</div>
