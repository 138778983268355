<arc-editable-table [config]="suppliersCardConfig" [template]="cardTemplate" [formControl]="formGroup.controls.articleSuppliers">
    <ng-template #cardTemplate let-formGroup>
        <div class="grid grid-cols-3 grid-rows-3 gap-4 items-center">
            <arc-quick-search [config]="ComplexDataTypesEnum.Supplier | arcQuickSearchConfig" [formControl]="formGroup.controls.personId"
                class="col-span-2" label="Articles.EditFields.Supplier">
            </arc-quick-search>
            <arc-input labelKey="Articles.EditFields.OrderNumber" [control]="formGroup.controls.orderNumber">
            </arc-input>
            <arc-number-input label="Articles.EditFields.UnitQuantity" [formControl]="formGroup.controls.unitQuantity"
                [unit]="item.unitShortTitle" [isUnitTranslated]="true">
            </arc-number-input>
            <arc-number-input label="Articles.EditFields.MinOrderQuantity" [formControl]="formGroup.controls.minOrderQuantity"
                [unit]="item.unitShortTitle" [isUnitTranslated]="true">
            </arc-number-input>

            <arc-checkbox labelKey="Articles.EditFields.IsMainSupplier" [control]="formGroup.controls.isMainSupplier">
            </arc-checkbox>
            <div class="relative col-span-2">
                <arc-currency-input label="Articles.EditFields.BuyingPrice" [formControl]="formGroup.controls.buyingPrice">
                </arc-currency-input>
                @if (formGroup.value.isMainSupplier && isArticleBuyingPriceLinked()) {
                    <arc-icon icon="link" [size]="24" class="absolute -top-[10px] -right-[6px] text-accent bg-element"
                        [matTooltip]="'Articles.Edit.BuyingPriceLinkedTooltip' | transloco" />
                }
            </div>
            <arc-checkbox labelKey="Articles.EditFields.BuyingPriceExclusive" [control]="formGroup.controls.buyingPriceExclusive">
            </arc-checkbox>
        </div>
    </ng-template>
</arc-editable-table>
