import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { WarehouseRequestModel } from '../../models/warehouse-request.model';
import { WarehouseRequestListModel } from '../../models/responses/warehouse-request-list.model';
import { WarehouseRequestCreateModel } from '../../models/requests/warehouse-request-create.model';
import { WarehouseRequestEditModel } from '../../models/requests/warehouse-request-edit.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { WarehouseRequestArticleModel } from '../../models/warehouse-request-article.model';

@Injectable({
    providedIn: 'root'
})
export class WarehouseRequestsStore
    extends BaseCrudStore<WarehouseRequestModel, WarehouseRequestListModel, WarehouseRequestCreateModel, WarehouseRequestEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'warehouse-requests' }));
    }

    calculateArticlesForWarehouseRequest(id: number): Observable<ApiResponseModel<WarehouseRequestArticleModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<WarehouseRequestArticleModel[]>>(this.getUrl(`${id}/calculate-articles`));
    }
}
