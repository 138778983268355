import { ColumnFilterModel } from './column-filter.model';

export class SavedFilterModel {
    id!: string;
    name!: string;
    filters: ColumnFilterModel[] = [];

    constructor(init?: Partial<SavedFilterModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}

