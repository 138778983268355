<h1 mat-dialog-title>
    {{ 'WarehouseTransfers.Edit.Articles.EditDialogTitle' | transloco }}
</h1>
<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto">
    <form [formGroup]="formGroup" class="grid grid-cols-2 gap-4 pt-2">
        <arc-textarea label="WarehouseTransfers.Edit.Articles.Remarks" [formControl]="formGroup.controls.remarks"
            class="col-span-2">
        </arc-textarea>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <button mat-flat-button color="accent" [mat-dialog-close]="formGroup.value">{{ 'General.Actions.Save' | transloco }}</button>
</mat-dialog-actions>