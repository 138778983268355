import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

import { SubMenuItemModel } from '../../../../../core/models/sub-menu-item.model';
import { BaseComponent } from '../../../../abstractions/base.component';
import { RouteService } from '../../../../../core/services/route.service';
import { FavouritesService } from '../../../../../core/services/favourites.service';
import { PermissionService } from '../../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../../core/models/enums/permission-type.enum';
import { AuthPermissionModel } from '../../../../../app/models/auth-permission.model';

@Component({
    selector: 'arc-left-sidebar-item',
    templateUrl: './left-sidebar-item.component.html',
    styleUrl: './left-sidebar-item.component.scss'
})
export class LeftSidebarItemComponent extends BaseComponent implements AfterViewInit, OnInit {
    @Input({ required: true }) menuItem!: SubMenuItemModel;

    @Output() readonly favouriteChanged = new EventEmitter();

    isCurrentRoute = false;
    canHover = true;
    isFavouriteButtonVisible = false;
    isFavouriteButtonLoading = false;
    hasFavoritesPermission: true | AuthPermissionModel = true;

    readonly routerLinkActiveOptions: IsActiveMatchOptions = {
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'exact',
        paths: 'exact'
    };

    private hoverTimeout?: NodeJS.Timeout;

    private readonly permissionService = inject(PermissionService);
    private readonly routeService = inject(RouteService);
    private readonly favouritesService = inject(FavouritesService);
    private readonly favouriteButtonShowDelayMs = 500;

    constructor() {
        super();

        const routerEventsSub = this.routeService.routerEvent$.subscribe(() => {
            this.isCurrentRoute = this.routeService.isActiveRoute(this.menuItem.path);
        });
        this.addSubscriptions(routerEventsSub);
    }

    @HostListener('mouseenter')
    onHoverStart(): void {
        if (!!this.hoverTimeout) {
            clearTimeout(this.hoverTimeout);
        }
        this.hoverTimeout = setTimeout(() => (this.isFavouriteButtonVisible = true), this.favouriteButtonShowDelayMs);
    }

    @HostListener('mouseleave')
    onHoverEnd(): void {
        this.isFavouriteButtonVisible = false;
        if (!!this.hoverTimeout) {
            clearTimeout(this.hoverTimeout);
        }
    }

    ngOnInit(): void {
        this.hasFavoritesPermission = this.permissionService.hasPermission(PermissionsEnum.Favourites, PermissionTypeEnum.Create);
    }

    ngAfterViewInit(): void {
        this.canHover = matchMedia('(hover: hover)').matches;
    }

    addOrRemoveMenuFavourites(event: Event): void {
        event.stopPropagation();
        if (this.menuItem.favouriteId !== undefined) {
            this.removeMenuFromFavourites();
        } else {
            this.addMenuToFavourites();
        }
    }

    showUpgradeModal(permission: AuthPermissionModel): void {
        this.permissionService.showUpgradeModal(permission);
    }

    private removeMenuFromFavourites(): void {
        this.isFavouriteButtonLoading = true;
        this.favouritesService
            .remove(this.menuItem.favouriteId ?? 0)
            .subscribe()
            .add(() => {
                this.isFavouriteButtonLoading = false;
                this.favouriteChanged.emit();
            });
        this.menuItem.favouriteId = undefined;
    }

    private addMenuToFavourites(): void {
        this.isFavouriteButtonLoading = true;
        this.favouritesService
            .add({ id: 0, title: this.menuItem.labelKey, path: this.menuItem.path })
            .subscribe(id => (this.menuItem.favouriteId = id))
            .add(() => {
                this.isFavouriteButtonLoading = false;
                this.favouriteChanged.emit();
            });
    }
}
