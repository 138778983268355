<h1 mat-dialog-title>
    {{ 'Invoices.CreateTitle' | transloco }}
</h1>

<mat-dialog-content class="grow !max-h-[unset] overflow-y-auto !flex flex-col">

    <div class="flex gap-4 !mt-2">
        <arc-datepicker [control]="issuedDate" labelKey="General.IssuedDate"></arc-datepicker>
        <arc-input class="grow" placeholderKey="Invoices.Create.PersonName" [control]="searchText"></arc-input>
    </div>

    <div class="grow w-full relative overflow-y-auto">
        <table mat-table [dataSource]="datasource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox [checked]="selection.length === datasource.data.length"
                        [indeterminate]="selection.length > 0 && selection.length < datasource.data.length"
                        (change)="handleSelectAll()"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element" class="!border-b-0">
                    <mat-checkbox [disabled]="isLoading" [checked]="isSelected(element)"
                        (change)="handleSelectionChanged(element)"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="personName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Invoices.Create.PersonName' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="!border-b-0">
                    <div class="flex flex-col">
                        <span class="text-xs leading-none">{{ element.personNumber }}</span>
                        <span>
                            <span>{{ element.personName }}</span>
                            <span *ngIf="!!element.personCompanyContactName" class="text-on-app-light text-sm">
                                {{ element.personCompanyContactName }}
                            </span>
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Invoices.Create.Amount' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="!border-b-0">
                    {{ element.amount | arcCurrency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-end">
                    {{ 'Invoices.Create.DueDate' | transloco }}
                </th>
                <td mat-cell *matCellDef="let element" class="!border-b-0 arc-date-cell">
                    <div class="flex items-center">
                        <div class="w-0 overflow-hidden">
                            <input class="collapse" [(ngModel)]="element.dueDate" [matDatepicker]="picker">
                        </div>
                        <mat-datepicker #picker color="primary"></mat-datepicker>
                        <p>{{ element.dueDate | arcDate }}</p>
                        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="!border-b-0"></tr>

            <tr *matNoDataRow>
                <td [attr.colspan]="displayedColumns.length" class="p-4 text-center">
                    {{ 'General.NoRecords' | transloco }}
                </td>
            </tr>
        </table>

        <div class="absolute inset-0 bg-overlay grid place-items-center" *ngIf="isLoading">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 15, 20]" [pageSize]="15" [showFirstLastButtons]="true"> </mat-paginator>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false">{{ 'General.Actions.Cancel' | transloco }}</button>
    <arc-button type="flat" color="accent" [isDisabled]="selection.length === 0" [isLoading]="isCreating"
        (clicked)="create()">
        {{ 'Invoices.AddX' | transloco:{ amount: selection.length } }}
    </arc-button>
</mat-dialog-actions>
