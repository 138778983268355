<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyName" [control]="formGroup.controls.companyName"></arc-input>
    <arc-address-picker
        class="col-span-12 mt-4"
        [streetControl]="formGroup.controls.companyStreet"
        [streetNumberControl]="formGroup.controls.companyStreetNumber"
        [zipControl]="formGroup.controls.companyZip"
        [cityControl]="formGroup.controls.companyPlace"
        [countryIsoCodeControl]="formGroup.controls.companyCountry">
    </arc-address-picker>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyVatNo" [control]="formGroup.controls.companyVatNo"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyUid" [control]="formGroup.controls.companyUid"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyPhoneNumber" [control]="formGroup.controls.companyPhoneNumber"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyFaxNumber" [control]="formGroup.controls.companyFaxNumber"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyEmail" [control]="formGroup.controls.companyEmail"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyWebsite" [control]="formGroup.controls.companyWebsite"></arc-input>
    <arc-input
        labelKey="Settings.Edit.Customize.BaseData.CompanyHeadquartersPlace"
        [control]="formGroup.controls.companyHeadquartersPlace"></arc-input>
    <arc-input
        labelKey="Settings.Edit.Customize.BaseData.CompanyJurisdictionPlace"
        [control]="formGroup.controls.companyJurisdictionPlace"></arc-input>
    <arc-input labelKey="Settings.Edit.Customize.BaseData.CompanyCeoName" [control]="formGroup.controls.companyCeoName"></arc-input>
</form>
