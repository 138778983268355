import { Component, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import {
    BaseEditSidebarItemComponent
} from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { SupplierEditRequestModel } from '../../../../../models/requests/supplier-edit-request.model';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { ComplexDataTypesEnum } from '../../../../../../core/models/complex-data-types.enum';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { SupplierModel } from '../../../../../models/supplier.model';

@Component({
    selector: 'arc-supplier-edit-contact',
    templateUrl: './supplier-edit-contact.component.html',
    styleUrls: ['./supplier-edit-contact.component.scss']
})
export class SupplierEditContactComponent extends BaseEditSidebarItemComponent<SupplierModel, SupplierEditRequestModel> {
    ComplexDataTypesEnum = ComplexDataTypesEnum;
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    allowedLanguages: SelectOptionModel<string>[] = [];

    override formGroup = new FormGroup({
        contactEmail: new ArcFormControl('', [Validators.email, Validators.maxLength(50)]),
        contactPhone: new ArcFormControl('', Validators.maxLength(20)),
        contactMobile: new ArcFormControl('', Validators.maxLength(20)),
        contactFax: new ArcFormControl('', Validators.maxLength(20)),
        contactUrl: new ArcFormControl('', Validators.maxLength(100)),
        languageId: new ArcFormControl('', Validators.required),
        responsibleUserId: new ArcFormControl<OptionalType<number>>(undefined)
    });

    private readonly translationService = inject(TranslationService);

    constructor() {
        super();
        this.allowedLanguages = this.translationService.allowedLanguages.map(l => ({ label: l.name, value: l.code }));
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<SupplierEditRequestModel> {
        const value = this.formGroup.getRawValue();
        return {
            contactEmail: value.contactEmail,
            contactPhone: value.contactPhone,
            contactMobile: value.contactMobile,
            contactFax: value.contactFax,
            contactUrl: value.contactUrl,
            languageId: value.languageId,
            responsibleUserId: value.responsibleUserId
        };
    }
}
