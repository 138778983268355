import { Component, input } from '@angular/core';

import { TicketHistoryModel } from '../../../../app/models/ticket-history.model';

@Component({
    selector: 'arc-event-message-item',
    templateUrl: './event-message-item.component.html',
    styleUrl: './event-message-item.component.scss'
})
export class EventMessageItemComponent {
    event = input.required<TicketHistoryModel>();
}
