<div class="h-full">
    <header *ngIf="vouchers.length > 0" class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goToVouchersPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        <div *ngIf="isLoading; else contentTpl" class="w-full h-full grid place-items-center">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>
        <ng-template #contentTpl>
            <ng-container *ngIf="vouchers.length > 0; else noRecordsTpl">
                <div class="grid grid-cols-3 gap-4">
                    <div *ngFor="let voucher of vouchers" class="p-2 bg-primary text-on-primary">
                        <div class="mb-[-10px] text-xs">{{voucher.id}}</div>
                        <div class="text-xs">{{voucher.title}}</div>
                        <div class="float-right">
                            <span class="text-xl" *ngIf="!!voucher.value else discountTpl">{{ voucher.value | arcCurrency }}</span>
                            <ng-template #discountTpl>
                                <div *ngIf="!!voucher.discount" class="text-xl">{{ voucher.discount | arcCurrency }}</div>
                                <span [class.text-xl]="!voucher.discount" [class.text-xs]="!!voucher.discount">
                                    {{dateService.format(voucher.validThru)}}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noRecordsTpl>
                <div class="absolute inset-0 grid place-items-center">
                    {{ 'Customers.Details.NoVouchers' | transloco }}
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
