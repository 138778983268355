import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ConfigurationModel } from '../../../../../models/configuration.model';
import { SeasonModel } from '../../../../../models/season.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { DateColumnModel } from '../../../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';

@Component({
    selector: 'arc-settings-fashion-season',
    templateUrl: './settings-fashion-season.component.html',
    styleUrl: './settings-fashion-season.component.scss'
})
export class SettingsFashionSeasonComponent extends BaseEditSidebarItemComponent<ConfigurationModel> {
    GeneralDataTypeEnum = GeneralDataTypeEnum;
    override formGroup = new FormGroup({
        seasons: new ArcFormControl<SeasonModel[]>([])
    });
    seasonTableConfig: EditableTableConfigModel<SeasonModel>;

    constructor() {
        super();
        this.seasonTableConfig = new EditableTableConfigModel<SeasonModel>({
            formGroupGeneratorFn: () =>
                new FormGroup({
                    id: new ArcFormControl(0),
                    title: new ArcFormControl('', Validators.required),
                    fromDate: new ArcFormControl<OptionalType<Date>>(undefined),
                    toDate: new ArcFormControl<OptionalType<Date>>(undefined)
                }),
            rowHeightPx: 63,
            allowDelete: true,
            columns: [
                new StringColumnModel({
                    propertyName: 'title',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Fashion.Seasons.Title',
                    widthPixels: 280
                }),
                new DateColumnModel({
                    propertyName: 'fromDate',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Fashion.Seasons.FromDate',
                    widthPixels: 180
                }),
                new DateColumnModel({
                    propertyName: 'toDate',
                    isEditable: true,
                    columnTitleKey: 'Settings.Edit.Fashion.Seasons.ToDate',
                    widthPixels: 180
                })
            ]
        });
    }

    override onBrokenRulesLoad(): string[] {
        return this.seasonTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }

    override prepareSaveModel(): Partial<ConfigurationModel> {
        return { seasons: this.formGroup.value.seasons };
    }
}
