import { Component, computed } from '@angular/core';

import { BaseColumnComponent } from '../base-column.component';
import { ButtonColumnModel } from '../../models/column-types/button-column.model';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-button-column',
    templateUrl: './button-column.component.html',
    styleUrls: ['./button-column.component.scss']
})
export class ButtonColumnComponent<T extends Identifyable<TId>, TId = number> extends BaseColumnComponent<T, TId> {
    isLoading = computed(() => !!this.buttonColumnModel.isLoading && this.buttonColumnModel.isLoading(this.item)());
    get buttonColumnModel(): ButtonColumnModel<T> {
        return this.columnModel as ButtonColumnModel<T>;
    }
    get isDisabled(): boolean {
        return !!this.buttonColumnModel.isButtonDisabled ? this.buttonColumnModel.isButtonDisabled(this.item) : false;
    }

    onClick(): void {
        this.buttonColumnModel.onClickFunction(this.item, this.formGroup);
    }
}
