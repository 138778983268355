import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { ArticleImageModel } from '../../../../../models/responses/article-image.model';
import { BaseControlValueAccessor } from '../../../../../../core/abstractions/base-control-value-accessor';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-article-images-editor',
    templateUrl: './article-images-editor.component.html',
    styleUrl: './article-images-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ArticleImagesEditorComponent
        }
    ]
})
export class ArticleImagesEditorComponent extends BaseControlValueAccessor<ArticleImageModel[]> {
    fileControl = new ArcFormControl<OptionalType<File>>(undefined);

    constructor() {
        super();
        this.fileControl.valueChanges.subscribe(file => {
            if (!file) {
                return;
            }

            if (file.size === 0) {
                // Is URL.
                this.addImage(file.name);
                this.fileControl.setValue(undefined);
            } else {
                // Is file.
                Utils.blobToBase64(file).subscribe(resp => {
                    this.addImage(undefined, resp, file.name.split('.').pop());
                    this.fileControl.setValue(undefined);
                });
            }
        });
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (!this.value) {
            return;
        }
        moveItemInArray(this.value, event.previousIndex, event.currentIndex);
        this.valueChanged(this.value);
    }

    updateImageDescription(image: ArticleImageModel, description: string): void {
        if (!this.value) {
            return;
        }

        const imageIndex = this.value.findIndex(i => i.id === image.id);
        if (imageIndex >= 0) {
            this.value[imageIndex].description = description;
        }
        this.valueChanged(this.value);
    }

    remove(image: ArticleImageModel): void {
        if (!this.value) {
            return;
        }

        const existingIdx = this.value.findIndex(i => i.id === image.id);
        if (existingIdx >= 0) {
            this.value.splice(existingIdx, 1);
            this.valueChanged(this.value);
        }
    }

    toDataUrl(image: ArticleImageModel): string {
        return Tools.Utils.getBase64ImageSrc(image.imageBlobData ?? '', image.fileExtension as any);
    }

    private addImage(path?: string, imageBlobData?: string, fileExtension?: string): void {
        const image: ArticleImageModel = {
            id: 0,
            number: (this.value?.length ?? 0) + 1,
            description: '',
            path,
            imageBlobData,
            fileExtension: !!imageBlobData ? fileExtension : undefined
        };

        this.valueChanged([...(this.value ?? []), image]);
    }
}
