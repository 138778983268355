import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ArticleStockDataModel } from '../../../../app/models/article-stock-data.model';
import { ArticleModel } from '../../../../app/models/article.model';
import { MainArticlesStore } from '../../../../app/services/stores/main-articles.store';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

@Component({
    selector: 'arc-article-stock-widget',
    templateUrl: './article-stock-widget.component.html',
    styleUrl: './article-stock-widget.component.scss'
})
export class ArticleStockWidgetComponent implements OnInit {
    @Input() article!: ArticleModel;

    isLoading = true;
    total = 0;
    totalStock = 0;
    totalOrdered = 0;
    data: ArticleStockDataModel[] = [];
    orderPermission: true | AuthPermissionModel = true;
    widgetPermission: true | AuthPermissionModel;

    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly router = inject(Router);
    private readonly permissionService = inject(PermissionService);

    constructor() {
        this.widgetPermission = this.permissionService.hasPermission(PermissionsEnum.ArticleStocks);
    }

    ngOnInit(): void {
        if (this.article.ignoreStock || this.widgetPermission !== true) {
            return;
        }

        this.orderPermission = this.permissionService.hasPermission(PermissionsEnum.Orders, PermissionTypeEnum.Read);
        this.totalStock = 0;
        this.totalOrdered = 0;
        this.total = 0;

        this.mainArticlesStore.getStockData(this.article.id).subscribe(r => {
            this.data = r.value || [];

            this.data.forEach(d => {
                this.totalStock += d.stock;
                this.totalOrdered += d.quantityOrdered;
            });

            this.total = this.totalStock + this.totalOrdered;
            this.isLoading = false;
        });
    }

    goToStockArticlesPage(): void {
        this.router.navigate(['article-stocks'], { queryParams: { 'Article.ArticleNumber': this.article.articleNumber } }).then();
    }

    goToOrderArticlesPage(): void {
        this.router.navigate(['order-articles'], { queryParams: { ArticleId: this.article.id } }).then();
    }

    getHeightForItem(articleStock: ArticleStockDataModel, isQuantity = false): number {
        if (articleStock.stock === 0) {
            return 1;
        }
        const percent = ((isQuantity ? articleStock.quantityOrdered : articleStock.stock) / this.total) * 100;
        return percent < 1 ? 1 : percent;
    }

    showUpgradeModal(permission: AuthPermissionModel): void {
        this.permissionService.showUpgradeModal(permission);
    }
}
