import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { ColoredStackedColumnModel } from '../../../../components/dynamic-table/models/column-types/colored-stacked-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { PointOfSaleListModel } from '../../../models/responses/pos-list.model';
import { PosStore } from '../../../services/stores/pos.store';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { PosTypeColumnComponent } from './components/pos-type-column/pos-type-column.component';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { PosItemOverviewComponent } from './pos-detail-widgets/pos-item-overview/pos-item-overview.detail-widget';
import { PosTransactionsComponent } from './pos-detail-widgets/pos-transactions/pos-transactions.component';
import { PointOfSaleModel } from '../../../models/point-of-sale.model';
import { PosEditBaseDataComponent } from './pos-edit-items/pos-edit-base-data/pos-edit-base-data.component';
import { PointOfSaleEditModel } from '../../../models/requests/point-of-sale-edit.model';
import { PosEditBalanceComponent } from './pos-edit-items/pos-edit-balance/pos-edit-balance.component';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-pos',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./pos.component.scss']
})
export class PosComponent extends PageListViewComponent<PointOfSaleListModel, PointOfSaleModel, PointOfSaleEditModel> {
    private readonly posStore = inject(PosStore);

    constructor() {
        super('PointOfSales');
        this.config = new TableListViewConfigModel<PointOfSaleListModel, PointOfSaleModel, PointOfSaleEditModel>({
            entityName: 'PointOfSales',
            pollAtEveryMs: 1000,
            availableColumns: {
                name: new ColoredStackedColumnModel({
                    columnTitleKey: 'PointOfSales.List.Pos',
                    propertyName: 'branchName',
                    propertyName2: 'name',
                    color: 'posColor',
                    isColorInHex: false,
                    widthPixels: 250,
                    sortColumn: 'name'
                }),
                branchName: new StringColumnModel({
                    columnTitleKey: 'PointOfSales.List.Branch',
                    propertyName: 'branchName',
                    widthPixels: 150
                }),
                layout: new StringColumnModel({
                    columnTitleKey: 'PointOfSales.List.Layout',
                    propertyName: 'layout',
                    widthPixels: 160
                }),
                status: new CustomColumnModel({
                    columnTitleKey: 'PointOfSales.List.Status',
                    propertyName: 'status',
                    customComponent: PosTypeColumnComponent,
                    widthPixels: 160
                }),
                lastDailyClosingDate: new DateColumnModel({
                    columnTitleKey: 'PointOfSales.List.PosEndOfDay',
                    propertyName: 'lastDailyClosingDate',
                    widthPixels: 150,
                    format: 'short'
                }),
                posLastSync: new DateColumnModel({
                    columnTitleKey: 'PointOfSales.List.LastSync',
                    propertyName: 'posLastSync',
                    widthPixels: 150,
                    format: 'short'
                }),
                posVersion: new StringColumnModel({
                    columnTitleKey: 'PointOfSales.List.Version',
                    propertyName: 'posVersion',
                    widthPixels: 150
                }),
                deactivationDate: new DateColumnModel({
                    columnTitleKey: 'PointOfSales.List.Deactivated',
                    propertyName: 'deactivationDate',
                    widthPixels: 150,
                    format: 'short'
                }),
                currentCashBalance: new CurrencyColumnModel({
                    columnTitleKey: 'PointOfSales.List.CurrentCashBalance',
                    propertyName: 'currentCashBalance',
                    widthPixels: 126,
                    allowSort: false
                })
            },
            defaultColumnOrder: [
                'name',
                'branchName',
                'layout',
                'status',
                'lastDailyClosingDate',
                'posLastSync',
                'posVersion',
                'deactivationDate',
                'currentCashBalance'
            ],
            defaultSort: new ColumnSortModel({
                column: 'name',
                direction: SortDirectionEnum.Asc
            }),
            availableDetailWidgets: {
                overview: new TableListDetailWidgetModel({
                    name: 'PointOfSales.Detail.OverviewWidget',
                    component: PosItemOverviewComponent
                }),
                posTransactions: new TableListDetailWidgetModel({
                    name: 'PointOfSales.Detail.TransactionsWidget',
                    component: PosTransactionsComponent
                })
            },
            defaultDetailWidgetOrder: ['overview', 'posTransactions'],
            store: this.posStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'PointOfSales.Edit.BaseData', component: PosEditBaseDataComponent },
                    { titleKey: 'PointOfSales.Edit.Balance', component: PosEditBalanceComponent }
                ],
                headerSubtitle: currentItem => of(currentItem.name)
            },
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasDelete: false })
        });
    }
}
