import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { RoleEditModel } from '../../../../../models/role-edit.model';
import { RoleModel } from '../../../../../models/role.model';
import { RolePermissionModel } from '../../../../../models/role-permission.model';
import { DictionaryType } from '../../../../../../core/models/types/dictionary.type';
import { PermissionsStore } from '../../../../../services/stores/permissions.store';
import { SearchRequestModel } from '../../../../../models/requests/search-request.model';
import { PermissionModel } from '../../../../../models/permission.model';
import { RolePermissionTypeEnum } from '../../../../../models/enums/role-permission-type.enum';
import { PermissionTypeEnum } from '../../../../../../core/models/enums/permission-type.enum';
import { ColumnFilterModel } from '../../../../../../core/models/column-filter.model';
import { ComparisonOperatorsEnum } from '../../../../../../core/models/enums/comparison-operators.enum';
import { FilterItemTypeEnum } from '../../../../../../core/models/enums/filter-item-type.enum';
import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-role-edit-item-permission',
    templateUrl: './role-edit-item-permission.component.html',
    styleUrl: './role-edit-item-permission.component.scss'
})
export class RoleEditItemPermissionComponent extends BaseEditSidebarItemComponent<RoleModel, RoleEditModel> {
    PermissionTypeEnum = PermissionTypeEnum;
    accordion = viewChild.required('detailedPermissions', { read: MatAccordion });
    paginator = viewChild.required(MatPaginator);
    dataSource!: MatTableDataSource<string>;
    permissionGroups = signal<DictionaryType<PermissionModel[]>>({});
    permissionGroupsKeys = computed(() => Object.keys(this.permissionGroups()));
    data = new BehaviorSubject<string[]>([]);

    readonly permissionSliderOptions = [
        { value: RolePermissionTypeEnum.None, label: 'Roles.Edit.None' },
        { value: RolePermissionTypeEnum.View, label: 'Roles.Edit.View' },
        { value: RolePermissionTypeEnum.CreateOrUpdate, label: 'Roles.Edit.Edit' },
        { value: RolePermissionTypeEnum.All, label: 'Roles.Edit.All' }
    ];

    private readonly permissionsStore = inject(PermissionsStore);
    private originalData: RolePermissionModel[] = [];

    override onItemSet(): void {
        this.originalData = Tools.Utils.deepCopy(this.item.permissions);
        this.permissionsStore
            .search(new SearchRequestModel({
                filters: [
                    new ColumnFilterModel({
                        column: 'PosScope',
                        values: [false],
                        comparisonOperator: ComparisonOperatorsEnum.False,
                        dataType: FilterItemTypeEnum.Boolean
                    })
                ],
                pageSize: 999
            }))
            .subscribe(resp => {
                if (!!resp.value) {
                    this.permissionGroups.set(
                        resp.value!.records.reduce(function (res, p) {
                            (res[p.groupName] = res[p.groupName] || []).push(p);
                            return res;
                        }, {} as DictionaryType<PermissionModel[]>)
                    );

                    this.dataSource = new MatTableDataSource<string>(this.permissionGroupsKeys());
                    this.dataSource.paginator = this.paginator();
                    this.data = this.dataSource.connect();
                }
            });
    }

    override prepareSaveModel(): Partial<RoleEditModel> {
        return { permissions: this.item.permissions };
    }

    override hasUnsavedChanges(): boolean {
        return !Utils.areEqual(this.originalData, this.item.permissions);
    }

    onGeneralPermissionChanged(key: string, value: RolePermissionTypeEnum): void {
        const permissions = this.permissionGroups()[key];

        for (const permission of permissions) {
            let rolePermission = this.item.permissions.find(rp => rp.permissionId === permission.id);
            let isNew = false;

            if (!rolePermission) {
                rolePermission = {
                    id: 0,
                    description: permission.title,
                    permissionId: permission.id,
                    permit: false,
                    permitCreate: false,
                    permitUpdate: false,
                    permitDelete: false
                };
                isNew = true;
            } else {
                rolePermission.permit = false;
                rolePermission.permitCreate = false;
                rolePermission.permitUpdate = false;
                rolePermission.permitDelete = false;
            }

            switch (value) {
                case RolePermissionTypeEnum.None:
                    break;
                case RolePermissionTypeEnum.View:
                    rolePermission.permit = true;
                    break;
                case RolePermissionTypeEnum.CreateOrUpdate:
                    rolePermission.permit = true;
                    rolePermission.permitCreate = true;
                    rolePermission.permitUpdate = true;
                    break;
                case RolePermissionTypeEnum.All:
                    rolePermission.permit = true;
                    rolePermission.permitCreate = true;
                    rolePermission.permitUpdate = true;
                    rolePermission.permitDelete = true;
                    break;
                default:
                    break;
            }

            if (isNew) {
                this.item.permissions.push(rolePermission);
            }
        }
    }

    getGeneralPermissionValue(key: string): number {
        const permissions = this.permissionGroups()[key];
        const rolePermissions = this.item.permissions.filter(rp => permissions.find(p => p.id === rp.permissionId));

        if (permissions.length !== rolePermissions.length) {
            return RolePermissionTypeEnum.None;
        }

        return rolePermissions.every(p => p.permitDelete)
            ? RolePermissionTypeEnum.All
            : (rolePermissions.every(p => p.permitCreate)
                ? RolePermissionTypeEnum.CreateOrUpdate
                : (rolePermissions.every(p => p.permit) ? RolePermissionTypeEnum.View : RolePermissionTypeEnum.None)
            );
    }

    onDetailPermissionChanged(permission: RolePermissionModel, event: any): void {
        permission.permit = false;
        permission.permitCreate = false;
        permission.permitUpdate = false;
        permission.permitDelete = false;

        switch (+event.target.value) {
            case RolePermissionTypeEnum.None:
                break;
            case RolePermissionTypeEnum.View:
                permission.permit = true;
                break;
            case RolePermissionTypeEnum.CreateOrUpdate:
                permission.permit = true;
                permission.permitCreate = true;
                permission.permitUpdate = true;
                break;
            case RolePermissionTypeEnum.All:
                permission.permit = true;
                permission.permitCreate = true;
                permission.permitUpdate = true;
                permission.permitDelete = true;
                break;
            default:
                break;
        }
    }

    getDetailPermissionValue(permission: RolePermissionModel): number {
        return permission.permitDelete
            ? RolePermissionTypeEnum.All
            : (permission.permitCreate
                ? RolePermissionTypeEnum.CreateOrUpdate
                : (permission.permit ? RolePermissionTypeEnum.View : RolePermissionTypeEnum.None)
            );
    }

    getRolePermissionValue(permissionId: string, type: PermissionTypeEnum): boolean {
        const rolePermission = this.item.permissions.find(p => p.permissionId === permissionId);

        if (!rolePermission) {
            return false;
        }

        switch (type) {
            case PermissionTypeEnum.Create:
                return rolePermission.permitCreate;
            case PermissionTypeEnum.Update:
                return rolePermission.permitUpdate;
            case PermissionTypeEnum.Delete:
                return rolePermission.permitDelete;;
            default:
                return rolePermission.permit;
        }
    }

    changeView(permission: PermissionModel, hasPermission: boolean): void {
        const rolePermission = this.item.permissions.find(rp => rp.permissionId === permission.id);

        if (!!rolePermission) {
            rolePermission.permit = hasPermission;

            if (!hasPermission) {
                rolePermission.permitCreate = rolePermission.permitDelete = rolePermission.permitUpdate = false;
            }
        } else {
            this.item.permissions.push({
                id: 0,
                permissionId: permission.id,
                permit: hasPermission,
                permitCreate: false,
                permitDelete: false,
                permitUpdate: false,
                description: permission.title
            });
        }
    }

    changeOthers(permission: PermissionModel, hasPermission: boolean, type: PermissionTypeEnum): void {
        const rolePermission = this.item.permissions.find(rp => rp.permissionId === permission.id);

        if (!!rolePermission) {
            this.updatePermissionByType(rolePermission, hasPermission, type);

            if (hasPermission && !rolePermission.permit) {
                rolePermission.permit = true;
            }
        } else {
            // Setting to true, because it's not possible to reach this method without setting a permission.
            // For example, since there's no permission, the user can not be unselecting it.
            const isAllowedNewPermission = true;
            const newRolePermission: RolePermissionModel = {
                id: 0,
                permissionId: permission.id,
                permit: isAllowedNewPermission,
                permitCreate: false,
                permitDelete: false,
                permitUpdate: false,
                description: permission.title
            };

            this.updatePermissionByType(newRolePermission, isAllowedNewPermission, type);
            this.item.permissions.push(newRolePermission);
        }
    }

    getPermissionTabIndex(key: string): number {
        const permissionsIds = this.permissionGroups()[key].map(p => p.id);
        const rolePermissions = this.item.permissions.filter(rp => permissionsIds.includes(rp.permissionId));
        const first = rolePermissions[0];
        const hasAnyDiff = !rolePermissions.every(rp =>
            rp.permit === first.permit
            && rp.permitCreate === first.permitCreate
            && rp.permitUpdate === first.permitUpdate
            && rp.permitDelete === first.permitDelete
        );

        return hasAnyDiff ? 2 : 0;
    }

    private updatePermissionByType(rolePermission: RolePermissionModel, value: boolean, type: PermissionTypeEnum): void {
        switch (type) {
            case PermissionTypeEnum.Create:
                rolePermission.permitCreate = value;
                break;
            case PermissionTypeEnum.Update:
                rolePermission.permitUpdate = value;
                break;
            case PermissionTypeEnum.Delete:
                rolePermission.permitDelete = value;
                break;
            default:
                break;
        }
    }
}
