import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { ReportEditRequestModel } from '../../../../../models/requests/report-edit-request.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { SelectOptionModel } from '../../../../../../core/models/select-option.model';
import { GeneralDataStore } from '../../../../../services/stores/general-data.store';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ReportSourceTypesEnum } from '../../../../../models/enums/report-source-types.enum';
import { TranslationService } from '../../../../../../core/services/translation.service';

@Component({
    selector: 'arc-report-create-source-selection',
    templateUrl: './report-create-source-selection.component.html',
    styleUrl: './report-create-source-selection.component.scss'
})
export class ReportCreateSourceSelectionComponent extends BaseEditSidebarItemComponent<ReportEditRequestModel> implements OnInit {
    sourceType = new FormControl(ReportSourceTypesEnum.File);
    tableNames: SelectOptionModel<string>[] = [];
    override formGroup = new FormGroup({
        reportBlobData: new ArcFormControl<OptionalType<number[]>>(undefined),
        tableName: new ArcFormControl(''),
        fileControl: new ArcFormControl()
    });

    ReportSourceTypesEnum = ReportSourceTypesEnum;

    readonly translationService = inject(TranslationService);
    readonly sourceTypeItems = [
        { value: ReportSourceTypesEnum.File, label: this.translationService.getText('Reports.Create.File') },
        { value: ReportSourceTypesEnum.Template, label: this.translationService.getText('Reports.Create.File') }
    ];
    private readonly generalDataStore = inject(GeneralDataStore);

    ngOnInit(): void {
        this.generalDataStore
            .getGeneralData(GeneralDataTypeEnum.ReportTemplates)
            .subscribe(resp => (this.tableNames = resp.value?.map(i => ({ value: i.key!, label: i.value! })) || []));
        this.formGroup.controls.fileControl.valueChanges.subscribe(async (file?: File) =>
            this.formGroup.controls.reportBlobData.setValue(!!file ? Array.from(new Uint8Array(await file.arrayBuffer())) : undefined)
        );
        this.formGroup.valueChanges.subscribe(() => this.tableNameOrFileValidator());
    }

    override onItemSet(): void {}

    override prepareSaveModel(): Partial<ReportEditRequestModel> {
        const value = this.formGroup.getRawValue();
        return {
            reportBlobData: value.reportBlobData,
            tableName: value.tableName
        };
    }

    private tableNameOrFileValidator(): void {
        const isReportBlobFilled = (this.formGroup.value.reportBlobData?.length || 0) > 0;
        const isTableNameFilled = !!this.formGroup.value.tableName;

        // eslint-disable-next-line no-null/no-null
        this.formGroup.controls.reportBlobData.setErrors(null);
        // eslint-disable-next-line no-null/no-null
        this.formGroup.controls.fileControl.setErrors(null);
        // eslint-disable-next-line no-null/no-null
        this.formGroup.controls.tableName.setErrors(null);

        if (!isReportBlobFilled && this.sourceType.value === ReportSourceTypesEnum.File) {
            this.formGroup.controls.reportBlobData.setErrors({ required: true });
            this.formGroup.controls.fileControl.setErrors({ required: true });
        }

        if (!isTableNameFilled && this.sourceType.value === ReportSourceTypesEnum.Template) {
            this.formGroup.controls.tableName.setErrors({ required: true });
        }
    }
}
