import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { ArticleStockListModel } from '../../../../../models/responses/article-stock-list.model';
import { ArticleStockModel } from '../../../../../models/article-stock.model';

@Component({
    selector: 'arc-article-stock-description',
    templateUrl: './article-stock-description.detail-widget.html',
    styleUrl: './article-stock-description.detail-widget.scss'
})
export class ArticleStockDescriptionDetailWidget extends BaseDetailWidget<ArticleStockListModel, ArticleStockModel, string> {}
