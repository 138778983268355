import { Component } from '@angular/core';

import { TicketModel } from '../../../../app/models/ticket.model';
import { BaseSidebarHeaderAdditionalInfoComponent } from '../../../sidebar-components/edit-sidebar/base-edit-sidebar-header-additional-info.component';

@Component({
    selector: 'arc-ticket-edit-header-additional-info',
    templateUrl: './ticket-edit-header-additional-info.component.html',
    styleUrl: './ticket-edit-header-additional-info.component.scss'
})
export class TicketEditHeaderAdditionalInfoComponent extends BaseSidebarHeaderAdditionalInfoComponent<TicketModel> {
    onItemSet(): void {}
}
