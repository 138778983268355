import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, inject } from '@angular/core';

import { ToasterService } from '../../../core/services/toaster.service';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { OptionalType } from '../../../core/models/types/optional.type';
import { CamtFilePreviewModel } from '../../../app/models/camt-file-preview.model';
import { CamtUploadService } from '../../../core/services/camt-upload.service';
import { CurrencyColumnModel } from '../../dynamic-table/models/column-types/currency-column.model';
import { StackedColumnModel } from '../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../dynamic-table/models/column-types/string-column.model';
import { DateService } from '../../../core/services/date.service';
import { CustomColumnModel } from '../../dynamic-table/models/column-types/custom-column.model';
import { CamtStatusColumnComponent } from './camt-status-column/camt-status-column.component';
import { StaticTableConfigModel } from '../../list-views/static-table/models/static-table-config.model';

@Component({
    selector: 'arc-camt-file-dialog',
    templateUrl: './camt-file-dialog.component.html',
    styleUrls: ['./camt-file-dialog.component.scss']
})
export class CamtFileDialogComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    fileControl = new ArcFormControl<OptionalType<File>>(undefined);
    isLoading = false;
    previewData: CamtFilePreviewModel[];
    tableConfig?: StaticTableConfigModel<CamtFilePreviewModel>;

    private readonly dialogRef = inject(MatDialogRef<CamtFileDialogComponent>);
    private readonly camtUploadService = inject(CamtUploadService);
    private readonly toasterService = inject(ToasterService);
    private readonly dateService = inject(DateService);

    constructor() {
        this.previewData = [];
    }

    ngOnInit(): void {
        this.fileControl.valueChanges.subscribe(value => this.getFilePreview(value));
    }

    getFilePreview(file?: File): void {
        this.isLoading = true;
        if (!!file) {
            this.camtUploadService
                .getCamtFilePreview(file)
                .subscribe(result => {
                    this.tableConfig = new StaticTableConfigModel<CamtFilePreviewModel>({
                        defaultPageSize: 10,
                        data: result,
                        availableColumns: [
                            new StackedColumnModel({
                                propertyName: 'personDisplayName',
                                propertyName2: 'reference',
                                columnTitleKey: 'Components.CamtFileUpload.Invoice',
                                widthPixels: 200
                            }),
                            new CurrencyColumnModel({
                                propertyName: 'openAmount',
                                columnTitleKey: 'Components.CamtFileUpload.InvoiceAmount',
                                widthPixels: 200
                            }),
                            new CurrencyColumnModel({
                                propertyName: 'amount',
                                columnTitleKey: 'Components.CamtFileUpload.PaidAmount',
                                widthPixels: 200
                            }),
                            new StringColumnModel({
                                propertyName: 'valuta',
                                columnTitleKey: 'Components.CamtFileUpload.PaidDate',
                                widthPixels: 200,
                                customFormatter: (record, value) => this.dateService.format(value)
                            }),
                            new CustomColumnModel({
                                propertyName: 'status',
                                columnTitleKey: 'Components.CamtFileUpload.Status',
                                customComponent: CamtStatusColumnComponent
                            })
                        ]
                    });
                    this.previewData = result;
                })
                .add(() => {
                    this.isLoading = false;
                });
        }
    }

    uploadCamtFile(): void {
        this.isLoading = true;
        this.camtUploadService.uploadCamtFile(this.fileControl.value!).subscribe(result => {
            let shouldReload = false;
            if (result) {
                this.toasterService.showSuccess('Components.CamtFileUpload.UploadSucceed');
                shouldReload = true;
            }
            this.isLoading = false;
            this.dialogRef.close(shouldReload);
        });
    }

    getPayableInvoicesCount(): number {
        return this.previewData.filter(d => !d.errorMessage).length;
    }
}
