import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../../components/abstractions/base.detail-widget';
import { TransactionArticleListModel } from '../../../../../models/responses/transaction-article-list.model';
import { TransactionArticleModel } from '../../../../../models/transaction-article.model';

@Component({
    selector: 'arc-transaction-article',
    templateUrl: './transaction-article.detail-widget.html',
    styleUrl: './transaction-article.detail-widget.scss'
})
export class TransactionArticleDetailWidget extends BaseDetailWidget<TransactionArticleListModel, TransactionArticleModel> {
    get isPricePerUnitDiff(): boolean {
        return this.priceByQuantity !== this.listModel.unitPrice;
    }
    get isTotalDiff(): boolean {
        return this.unitPriceTimesQuantity !== this.listModel.price;
    }
    get priceByQuantity(): number {
        return this.listModel.price / this.listModel.quantity;
    }
    get unitPriceTimesQuantity(): number {
        return this.listModel.unitPrice * this.listModel.quantity;
    }
    get boxColorClasses(): string[] {
        return this.isSale ? ['bg-warn', 'text-on-warn'] : ['bg-error', 'text-on-error'];
    }
    get isSale(): boolean {
        return !!this.entityModel.saleTitle;
    }
}
