import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { PointOfSaleEditModel } from '../../../../../models/requests/point-of-sale-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { PointOfSaleModel } from '../../../../../models/point-of-sale.model';

@Component({
    selector: 'arc-pos-edit-base-data',
    templateUrl: './pos-edit-base-data.component.html',
    styleUrls: ['./pos-edit-base-data.component.scss']
})
export class PosEditBaseDataComponent extends BaseEditSidebarItemComponent<PointOfSaleModel, PointOfSaleEditModel>{
    GeneralDataTypeEnum = GeneralDataTypeEnum;

    override formGroup = new FormGroup({
        name: new ArcFormControl('', Validators.required),
        branchId: new ArcFormControl(0, Validators.required),
        posLayoutId: new ArcFormControl(0, Validators.required),
        overrideTaxId: new ArcFormControl<OptionalType<number>>(undefined),
        deactivationDate: new ArcFormControl<OptionalType<Date>>(undefined)
    });
    override onItemSet(): void {
        this.formGroup.patchValue(this.item);
    }
    override prepareSaveModel(): Partial<PointOfSaleEditModel> {
        const value = this.formGroup.value;
        return {
            name: value.name,
            branchId: value.branchId,
            posLayoutId: value.posLayoutId,
            overrideTaxId: value.overrideTaxId,
            deactivationDate: value.deactivationDate
        };
    }
}
