<ng-container [ngSwitch]="type">
    <div [matTooltip]="tooltipText" [matTooltipDisabled]="!isDisabled || !hasPermission">
        <!-- [att.color] is needed, so that our custom scss in button.scss works -->
        <button *ngSwitchCase="'flat'" [ngClass]="customClasses" mat-flat-button [color]="color" [attr.color]="color"
            [disabled]="isDisabled || isLoading" (click)="handleClick()" type="button">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>
        <button *ngSwitchCase="'raised'" [ngClass]="customClasses" mat-raised-button [color]="color" [attr.color]="color"
            [disabled]="isDisabled || isLoading" (click)="handleClick()" type="button">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>
        <button *ngSwitchCase="'stroked'" [ngClass]="customClasses" mat-stroked-button [color]="color" [attr.color]="color"
            [disabled]="isDisabled || isLoading" (click)="handleClick()" type="button">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>
        <button *ngSwitchCase="'icon'" [ngClass]="customClasses" mat-icon-button [color]="color" [attr.color]="color"
            [disabled]="isDisabled || isLoading" (click)="handleClick()"
            [style]="isLoading ? '--mdc-icon-button-icon-size: 16px !important' : ''" type="button">
            @if (!hasPermission) {
                <arc-icon class="pointer-events-auto text-element-disabled" icon="lock"></arc-icon>
            } @else if (isLoading) {
                <div class="flex items-center">
                    <mat-spinner [diameter]="16" [color]="spinnerColor"></mat-spinner>
                </div>
            } @else {
                <arc-icon [icon]="icon!"></arc-icon>
            }
        </button>
        <button *ngSwitchCase="''" [ngClass]="customClasses" mat-button [color]="color" [attr.color]="color"
            [disabled]="isDisabled || isLoading" (click)="handleClick()" type="button">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </button>
    </div>
</ng-container>

<ng-template #contentTpl>
    <div class="flex items-center gap-2">
        @if (!hasPermission) {
            <arc-icon class="pointer-events-auto text-element-disabled" icon="lock"></arc-icon>
        }
        @else if (isLoading) {
            <mat-spinner [diameter]="16" [color]="spinnerColor"></mat-spinner>
        }
        @else if (!!prefixIcon) {
            <mat-icon>{{ prefixIcon }}</mat-icon>
        }

        <span>
            @if (!title) {
                <ng-content></ng-content>
            } @else {
                <div class="flex gap-2">
                    @if (!!icon) {
                        <arc-icon [icon]="icon"></arc-icon>
                    } {{ title }}
                </div>
            }
        </span>
    </div>
</ng-template>
