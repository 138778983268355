import { Component, inject } from '@angular/core';
import { of } from 'rxjs';

import { RolesStore } from '../../../services/stores/roles.store';
import { RoleListModel } from '../../../models/responses/role-list.model';
import { RoleModel } from '../../../models/role.model';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CardListViewConfigModel } from '../../../../components/list-views/card-list-view/models/card-list-view-config.model';
import { MainCardButtonModel } from '../../../../core/models/main-card-button.model';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { RoleCardComponent } from './role-card/role-card.component';
import { RoleEditBaseDataComponent } from './roles-edit-items/role-edit-base-data/role-edit-base-data.component';
import { RoleEditPosPermissionsComponent } from './roles-edit-items/role-edit-pos-permissions/role-edit-pos-permissions.component';
import { RoleEditItemPermissionComponent } from './roles-edit-items/role-edit-item-permission/role-edit-item-permission.component';
import { RoleEditModel } from '../../../models/role-edit.model';

@Component({
    selector: 'arc-roles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends PageListViewComponent<RoleListModel, RoleModel, RoleModel, RoleEditModel> {
    private readonly rolesStore = inject(RolesStore);

    constructor() {
        super('Roles');
        this.config = new CardListViewConfigModel<RoleModel, RoleListModel, RoleModel, RoleEditModel>({
            entityName: 'Roles',
            store: this.rolesStore,
            cardComponent: RoleCardComponent,
            cardWidth: '307px',
            cardHeight: '465px',
            actionButtonsCustomClasses: 'max-h-[52px]',
            cardTitle: currentItem => currentItem.roleName,
            cardTitleClasses: () => ['bg-primary', 'text-on-primary'],
            editSidebarConfig: {
                editComponents: [
                    {
                        titleKey: 'Roles.BaseData',
                        component: RoleEditBaseDataComponent
                    },
                    {
                        titleKey: 'Roles.POSRights',
                        component: RoleEditPosPermissionsComponent
                    },
                    {
                        titleKey: 'Roles.Permissions',
                        component: RoleEditItemPermissionComponent
                    }
                ],
                headerSubtitle: currentItem => of(currentItem.roleName)
            },
            mainButton: new MainCardButtonModel({
                text: 'Settings.Edit.Roles.ViewUsers',
                clickFn: data => this._router.navigate(['settings', 'users'], { queryParams: { RoleId: [data.id] } }).then()
            }),
            buttonsVisibility: new ButtonsVisibilityModel({ hasExport: false, hasDelete: false })
        });
    }
}
