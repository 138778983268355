import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { BaseColumnModel } from '../../../dynamic-table/models/column-types/base-column.model';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../dynamic-table/models/column-types/string-column.model';

export const articleSelectionTableConfig: BaseColumnModel[] = [
    new StackedColumnModel({
        propertyName: 'title',
        propertyName2: 'articleNumber',
        columnTitleKey: 'Articles.List.Article'
    }),
    new StringColumnModel({
        propertyName: 'price',
        columnTitleKey: 'Articles.List.Price'
    }),
    new StringColumnModel({
        propertyName: 'totalStock',
        columnTitleKey: 'Articles.List.TotalStock',
        requiredPermission: PermissionsEnum.ArticleStocks
    })
];
