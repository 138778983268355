<div class="h-full flex flex-col">
    <arc-search-filter [store]="config.store" arcQuickFeedback="36" [quickFeedbackPosition]="{ top: -10, right: -10 }"></arc-search-filter>
    <div class="relative overflow-y-auto my-4 gap-4 card-grid pb-8 min-h-[128px]"
        [ngStyle]="{ '--card-width': config.cardWidth, '--card-height': config.cardHeight }">
        <mat-card *ngFor="let item of data" [style.background-color]="config.cardBackgroundColor(item)">
            <ng-container *ngIf="!!config.cardTitle(item) || !!config.cardSubtitle(item)">
                <mat-card-header class="flex rounded-tr rounded-tl items-center h-[100px] !px-[32px] !py-[16px]"
                    [ngClass]="config.cardTitleClasses(item)">
                    <div class="w-full">
                        <div *ngIf="!!config.cardSubtitle(item)" class="mb-[-7px]">{{ config.cardSubtitle(item) }}</div>
                        <div *ngIf="!!config.cardTitle(item)" class="text-3xl" [title]="config.cardTitle(item)">{{ config.cardTitle(item) }}
                        </div>
                    </div>
                </mat-card-header>
            </ng-container>
            <ng-container *ngIf="!config.isCustomCard; else customCard">
                <img *ngIf="!!config.cardImage(item)" mat-card-image [src]="config.cardImage(item)!" (click)="onImageClick(item)"
                    [ngClass]="imageClasses">
                <mat-card-content class="!pt-2 mt-0.5 grow overflow-auto">
                    <ng-container arcCardContent [item]="item" [component]="config.cardComponent"></ng-container>
                </mat-card-content>
            </ng-container>
            <ng-template #customCard>
                <ng-container arcCardContent [item]="item" [component]="config.cardComponent"></ng-container>
            </ng-template>
            <mat-card-actions [ngClass]="config.actionButtonsCustomClasses">
                @if (!!mainButton && (!mainButton.isHidden || !mainButton.isHidden(item))) {
                    <button mat-button color="primary" class="dark:!text-accent" [disabled]="!!mainButton.isEnabled && !mainButton.isEnabled(item)"
                        (click)="mainButton.clickFn(item)">
                        {{ mainButton.text | transloco }}
                    </button>
                }
                <arc-buttons-bar class="ml-auto" [items]="singleRecordActionButtons()" [shouldStopPropagation]="true" [data]="item">
                </arc-buttons-bar>
            </mat-card-actions>
        </mat-card>
        @if (hasMore) {
            <mat-card>
                <mat-card-content class="w-full h-full bg-element-selected">
                    <div class="w-full h-full grid place-items-center cursor-pointer" (click)="loadMore()">
                        {{'Components.PageLists.LoadMore' | transloco}} >
                    </div>
                </mat-card-content>
            </mat-card>
        }
        <div *ngIf="data.length === 0 && !isLoading" class="absolute inset-0 grid place-items-center">
            {{ 'General.NoRecords' | transloco }}
        </div>
        <div *ngIf="isLoading" class="absolute inset-0 bg-overlay grid place-items-center">
            <mat-spinner diameter="64"></mat-spinner>
        </div>
    </div>
    <mat-paginator class="my-2 mb-44 !bg-transparent" [length]="totalRecords" [pageSize]="config.paginatorConfig.defaultPageSize"
        [pageSizeOptions]="config.paginatorConfig.pageSizeOptions" (page)="handlePageEvent($event)">
    </mat-paginator>
</div>
