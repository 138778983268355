import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, HostListener, inject, Signal, signal, effect, WritableSignal } from '@angular/core';
import { CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { filter, fromEvent, tap, finalize, firstValueFrom, map, defaultIfEmpty } from 'rxjs';

import { EditSidebarDataModel } from './models/edit-sidebar-data.model';
import { EditSidebarComponentModel } from './models/edit-sidebar-component.model';
import { SidebarService } from '../../../core/services/sidebar.service';
import { BaseEditSidebarItemComponent } from './base-edit-item/base-edit-sidebar-item.component';
import { EditSidebarLayoutSettingsModel } from './models/edit-sidebar-layout-settings.model';
import { BaseSidebarComponent } from '../base-sidebar/base-sidebar.component';
import { OptionalType } from '../../../core/models/types/optional.type';
import { LayoutService } from '../../../core/services/layout.service';
import { Utils } from '../../../core/utils/tools/utils.tools';
import { ToasterService } from '../../../core/services/toaster.service';
import { ErrorsService } from '../../../core/services/errors.service';
import { SidebarHeaderService } from '../../../core/services/sidebar-header.service';
import { SharedDataService } from '../../../core/services/shared-data.service';
import { ActionButtonModel } from '../../../core/models/action-button.model';
import { ActionButtonsService } from '../../../core/services/action-buttons.service';
import { ModalVisibilityEnum } from '../../../core/models/enums/modal-visibility.enum';
import { TranslationService } from '../../../core/services/translation.service';
import { AlertService } from '../../../core/services/alert.service';
import { Identifyable } from '../../../core/abstractions/identifyable';
import { EditSidebarItemAbstraction } from './models/edit-sidebar-item-abstraction.model';
import { FullModelValue } from './models/full-model-value';
import { DictionaryType } from '../../../core/models/types/dictionary.type';

@Component({
    selector: 'arc-edit-sidebar',
    templateUrl: './edit-sidebar.component.html',
    styleUrls: ['./edit-sidebar.component.scss']
})
export class EditSidebarComponent<
    TList extends Identifyable<TId>,
    T extends Identifyable<TId>,
    TUpdate extends Identifyable<TId>,
    TId = number
>
    extends BaseSidebarComponent
    implements EditSidebarItemAbstraction<T, TUpdate, TId>, OnInit, AfterViewInit, OnDestroy {
    override data?: EditSidebarDataModel<TList, T, T, TUpdate, TId>;
    readonly defaultSaveButtonKey = Utils.newGuid();
    readonly defaultSaveButton = new ActionButtonModel({
        key: this.defaultSaveButtonKey,
        text: '',
        modalVisibility: ModalVisibilityEnum.PrimaryCloseAfter
    });

    visibleComponents: EditSidebarComponentModel<T, TUpdate, TId>[] = [];
    isCreate = false;
    isLoading = true;
    hasError = false;
    isSaving = false;
    shouldDisableAnimation = true;
    item?: T;
    componentPortals: Map<EditSidebarComponentModel<T, TUpdate, TId>, ComponentPortal<BaseEditSidebarItemComponent<T, TUpdate, TId>>> =
        new Map();
    componentRefs: Map<EditSidebarComponentModel<T, TUpdate, TId>, ComponentRef<BaseEditSidebarItemComponent<T, TUpdate, TId>>> = new Map();
    editComponentsExpanded: Map<string, OptionalType<boolean>> = new Map();
    visiblePrimaryAction = Utils.deepCopy(this.defaultSaveButton);
    visibleSecondaryAction?: ActionButtonModel;
    collapsedActions: ActionButtonModel[] = [];

    get id(): string {
        return !!this.item ? Utils.getId(this.item.id) : '';
    }

    private readonly sidebarService = inject(SidebarService);
    private readonly layoutService = inject(LayoutService);
    private readonly toasterService = inject(ToasterService);
    private readonly errorsService = inject(ErrorsService);
    private readonly headerService = inject(SidebarHeaderService);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly actionButtonsService = inject(ActionButtonsService);
    private readonly translationService = inject(TranslationService);
    private readonly alertService = inject(AlertService);
    private readonly componentsWithHideSignals = signal<DictionaryType<OptionalType<WritableSignal<OptionalType<boolean>>>>>({});

    private actionButtons: Signal<ActionButtonModel[]> = signal<ActionButtonModel[]>([]);
    private localComponents: EditSidebarComponentModel<T, TUpdate, TId>[] = [];

    constructor() {
        super();

        this.sharedDataService.reset();
        effect(() => {
            this.setupButtonsBar();

            if (this.localComponents.length > 0) {
                this.visibleComponents = this.localComponents.filter(
                    lc => !lc.shouldHideSignalName || !this.componentsWithHideSignals()[lc.titleKey]!()
                );
            }
        });
    }

    @HostListener('window:beforeunload')
    doBeforeUnload(): boolean {
        if (this.hasUnsavedChanges()) {
            this.displayDiscardChangesModal();
            return false;
        }

        return true;
    }

    ngOnInit(): void {
        this.setupEscListener();

        this.actionButtons = this.actionButtonsService.buttons;
        this.errorsService.shouldDisplayAlertOnError = false;
        const businessErrorSub = this.errorsService.onBusinessException.subscribe(response => {
            const componentRefs = Array.from(this.componentRefs.values());

            // give unique id to each broken rule to be able to identify it later
            const brokenRules = response.brokenRules.map(br => ({ id: Utils.newGuid(), ...br }));

            const mappedRuleIds: string[] = [];

            componentRefs.forEach(ref => {
                mappedRuleIds.push(...ref.instance.setBusinessErrors(brokenRules));
            });

            // show dialog alert for unmapped broken rules
            const unmappedBrokenRules = brokenRules.filter(br => !mappedRuleIds.includes(br.id!));
            if (unmappedBrokenRules.length > 0) {
                this.errorsService.showAlertForBrokenRules(unmappedBrokenRules, response.message);
            }

            if (!!response.message) {
                this.toasterService.showError(response.message, undefined, true);
            } else {
                this.toasterService.showError('General.Alert.SaveFailedInvalid');
            }
        });

        if (!this.data) {
            return;
        }

        this.localComponents = this.data.editComponents ?? [];
        const componentsWithSignals: DictionaryType<OptionalType<WritableSignal<OptionalType<boolean>>>> = {};

        for (const editComponent of this.data.editComponents) {
            componentsWithSignals[editComponent.titleKey] =
                !!editComponent.shouldHideSignalName
                    ? this.sharedDataService.getOrCreateSignal<boolean>(editComponent.shouldHideSignalName)
                    : undefined;
        }

        this.componentsWithHideSignals.set(componentsWithSignals);

        for (const editComponent of this.localComponents) {
            this.componentPortals.set(editComponent, new ComponentPortal(editComponent.component));
        }

        if (typeof this.data.existingId !== 'undefined') {
            this.data.store.get(this.data.existingId!).subscribe({
                next: result => {
                    this.item = result.value;
                    this.doAfterRecordLoad();
                },
                error: () => (this.hasError = true),
                complete: () => (this.isLoading = false)
            });
        } else {
            // No existing item, create new
            this.isCreate = true;
            this.data.store.getDefaultValues().subscribe(response => {
                this.item = response.value ?? ({} as T);

                this.doAfterRecordLoad();

                this.isLoading = false;
            });
        }

        this.applyLayoutSettings();
        this.addSubscriptions(businessErrorSub);
    }

    ngAfterViewInit(): void {
        setTimeout(() => (this.shouldDisableAnimation = false));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.errorsService.shouldDisplayAlertOnError = true;
    }

    override onClose(): void {
        this.saveLayoutSettings();
    }

    drop(event: CdkDragSortEvent): void {
        moveItemInArray(this.visibleComponents, event.previousIndex, event.currentIndex);
    }

    handleComponentAttached(ref: CdkPortalOutletAttachedRef, editComponent: EditSidebarComponentModel<T, TUpdate, TId>): void {
        const componentRef = ref as ComponentRef<BaseEditSidebarItemComponent<T, TUpdate, TId>>;

        if (!!componentRef) {
            this.componentRefs.set(editComponent, componentRef);

            componentRef.instance.isCreate = this.isCreate;
            componentRef.instance.parent = this;

            if (!!this.data && !!this.item) {
                this.updateEditBoxData(componentRef);
            }
        }
    }

    async save(shouldCloseAfterSave = true): Promise<boolean> {
        if (!this.item) {
            return false;
        }

        let hasSaved = false;
        this.isSaving = true;
        const dataValidationResponse = await this.getUpToDateFullModelValue();

        if (dataValidationResponse.isValid) {
            const saveItem: T | TUpdate = dataValidationResponse.data!;

            if (this.isCreate) {
                hasSaved = await this.create(<T>saveItem!, shouldCloseAfterSave);
            } else {
                hasSaved = await this.update(<TUpdate>saveItem!, shouldCloseAfterSave);
            }
        } else {
            this.isSaving = false;
            this.toasterService.showWarning('General.Alert.SaveFailedInvalid');
        }

        return hasSaved;
    }

    cancel(): void {
        let shouldReload = false;
        const componentRefs = Array.from(this.componentRefs.values());

        componentRefs.forEach(ref => (shouldReload = ref.instance.shouldReloadOnCancel() || shouldReload));

        if (!this.hasUnsavedChanges()) {
            this.sidebarService.closeRight(shouldReload);
        } else {
            this.displayDiscardChangesModal();
        }
    }

    async onContextActionClick(evt: MouseEvent, btn: ActionButtonModel): Promise<void> {
        evt.stopPropagation();

        const shouldCloseAfterSave =
            btn.modalVisibility === ModalVisibilityEnum.PrimaryCloseAfter ||
            btn.modalVisibility === ModalVisibilityEnum.SecondaryCloseAfter;

        const hasSaved = await this.save(shouldCloseAfterSave);

        if (!hasSaved) {
            return;
        }

        if (!shouldCloseAfterSave) {
            const btnStatusSub = this.actionButtonsService.checkButtonLoadingStatusSub.subscribe(button => {
                if (button.key === btn.key && !button.isLoading) {
                    for (const componentRef of this.componentRefs.values()) {
                        this.updateEditBoxData(componentRef);
                    }
                }
            });
            this.addSubscriptions(btnStatusSub);
        }

        if (!!btn.clickFn) {
            btn.clickFn(btn, this.item);
        } else if (!!btn.key) {
            this.actionButtonsService.handleClick(btn.key, this.item);
        }
    }

    getButtonText(btn: ActionButtonModel): string {
        return btn.key === this.defaultSaveButtonKey
            ? this.translationService.getText(`General.Actions.${this.isCreate ? 'Create' : 'SaveChanges'}`)
            : /([a-zA-Z]+\.[a-zA-Z]+)/g.test(btn.text)
                ? this.translationService.getText(btn.text)
                : btn.text;
    }

    isButtonDisabled(btn: ActionButtonModel): boolean {
        return btn.isLoading || this.isSaving || this.isLoading || this.hasError;
    }

    /**
     * Retrieves the complete model value based on all edit item components of the current edit sidebar.
     */
    async getUpToDateFullModelValue(shouldValidate = true): Promise<FullModelValue<T, TUpdate, TId>> {
        const componentRefs = Array.from(this.componentRefs.values());

        if (shouldValidate) {
            // validate all components (cannot use array.every, since that
            // would return false before running isValid() on all instances)
            let isValid = true;

            componentRefs.forEach(ref => {
                isValid = ref.instance.updateAndCheckValidity() && isValid;
            });
            if (!isValid) {
                return { isValid: false };
            }
        }

        const saveItem: Partial<TUpdate> = {};
        saveItem.id = this.item?.id;
        let isValid = true;

        // let all edit components modify the item copy
        for (const componentRef of componentRefs) {
            if (!componentRef.instance) {
                continue;
            }

            isValid = await componentRef.instance.shouldSave();

            if (isValid) {
                const partialModel = componentRef.instance.prepareSaveModel();
                for (const [key, value] of Object.entries(partialModel)) {
                    saveItem[key as keyof TUpdate] = value;
                }
            }
        }

        return { isValid, data: saveItem as TUpdate };
    }

    private hasUnsavedChanges(): boolean {
        for (const componentRef of this.componentRefs.values()) {
            if (!componentRef.instance) {
                continue;
            }

            if (componentRef.instance.hasUnsavedChanges()) {
                return true;
            }
        }

        return false;
    }

    private doAfterRecordLoad(): void {
        this.setupButtonsBar(this.actionButtonsService.currentButtonsWithoutBase);
        this.setHeader();
    }

    private displayDiscardChangesModal(): void {
        this.alertService.showConfirm('General.Alert.UnsavedChanges', undefined, isConfirmed => {
            if (isConfirmed) {
                this.sidebarService.closeRight(true);
            }
        });
    }

    private updateEditBoxData(componentRef: ComponentRef<BaseEditSidebarItemComponent<T, TUpdate, TId>>): void {
        // edit components only get a copy of the item
        componentRef.instance.item = Utils.deepCopy(this.item!);
        componentRef.instance.onItemSet();
    }

    private setupButtonsBar(actionButtons?: ActionButtonModel[]): void {
        const buttons = (actionButtons || this.actionButtons()).filter(
            ab =>
                ab.min === 1 &&
                !this.actionButtonsService.isHidden(ab.key, this.item as any) &&
                ab.modalVisibility !== ModalVisibilityEnum.None
        );

        if (buttons.length < 1) {
            return;
        }

        const newPrimary = buttons.find(
            b => b.modalVisibility === ModalVisibilityEnum.PrimaryCloseAfter || b.modalVisibility === ModalVisibilityEnum.Primary
        );
        let secondary = buttons.find(
            b => b.modalVisibility === ModalVisibilityEnum.SecondaryCloseAfter || b.modalVisibility === ModalVisibilityEnum.Secondary
        );

        if (!!newPrimary) {
            secondary = undefined; // We reset it here because all the secondary actions will be in the dropdown in this case.
            this.visiblePrimaryAction = newPrimary;
            this.visibleSecondaryAction = Utils.deepCopy(this.defaultSaveButton);
        } else {
            this.visiblePrimaryAction = Utils.deepCopy(this.defaultSaveButton);

            if (!!secondary) {
                this.visibleSecondaryAction = secondary;
            }
        }

        this.collapsedActions = buttons.filter(b => (!newPrimary || b.key !== newPrimary.key) && (!secondary || b.key !== secondary.key));
    }

    private update(item: TUpdate, shouldCloseAfterSave: boolean): Promise<boolean> {
        return firstValueFrom(
            this.data!.store.edit(item).pipe(
                tap(() => {
                    if (shouldCloseAfterSave) {
                        this.sidebarService.closeRight(true);
                    } else {
                        this.item = item as unknown as T;
                    }
                }),
                map(resp => !!resp.value),
                finalize(() => (this.isSaving = false)),
                defaultIfEmpty(false)
            )
        );
    }

    private create(item: T, shouldCloseAfterSave: boolean): Promise<boolean> {
        return firstValueFrom(
            this.data!.store.add(item).pipe(
                tap(() => {
                    if (shouldCloseAfterSave) {
                        this.sidebarService.closeRight(true);
                    } else {
                        this.item = item;
                    }
                }),
                map(resp => !!resp.value),
                finalize(() => (this.isSaving = false)),
                defaultIfEmpty(false)
            )
        );
    }

    private saveLayoutSettings(): void {
        if (!this.data?.entityName) {
            return;
        }

        this.layoutService.saveEditSidebarLayout(
            this.data.entityName,
            new EditSidebarLayoutSettingsModel({
                editItemsOrder: this.visibleComponents.map(c => c.titleKey),
                editItemsOpen: Object.fromEntries(this.editComponentsExpanded.entries())
            })
        );
    }

    private applyLayoutSettings(): void {
        this.layoutService.getLayoutSettings().subscribe(result => {
            if (!result.editSidebars || !this.data?.entityName) {
                return;
            }

            const settings = result.editSidebars[this.data!.entityName] as EditSidebarLayoutSettingsModel;

            if (!settings) {
                return;
            }

            this.visibleComponents.sort(
                (a, b) => settings.editItemsOrder.indexOf(a.titleKey) - settings.editItemsOrder.indexOf(b.titleKey)
            );

            // check whether settings are set for expanded items if not set all items as expanded
            if (Object.keys(settings.editItemsOpen).length <= 0 && this.editComponentsExpanded.size <= 0) {
                settings.editItemsOrder.forEach(editItem => this.editComponentsExpanded.set(editItem, true));
            } else {
                this.editComponentsExpanded = new Map(
                    Object.entries(settings.editItemsOpen).filter(([key]) => this.visibleComponents.some(vc => vc.titleKey === key))
                );

                if (Array.from(this.editComponentsExpanded.values()).every(isExpanded => !isExpanded)) {
                    // by default, the first edit modal should be expanded. We don't want to have a view with all modals minimized
                    this.editComponentsExpanded.set(settings.editItemsOrder[0], true);
                }
            }
        });
    }

    private setHeader(): void {
        if (!this.item || !this.data) {
            return;
        }

        this.headerService.item.set(this.item);
        this.data.headerTitle(this.item).subscribe(t => this.headerService.title.set(t ?? ''));

        if (!!this.data.headerSubtitle) {
            this.data.headerSubtitle(this.item).subscribe(t => this.headerService.subtitle.set(t ?? ''));
        }

        if (!!this.data.headerAdditionalInfoComponent) {
            this.headerService.additionalInfoComponent.set(this.data.headerAdditionalInfoComponent);
            this.headerService.item.set(this.item);
        }

        this.headerService.hasCloseButton.set(this.data.showHeaderCloseButton ?? false);
    }

    private setupEscListener(): void {
        const sub = fromEvent(document, 'keydown')
            .pipe(filter(event => (event as KeyboardEvent).code === 'Escape'))
            .subscribe(() => this.cancel());
        this.addSubscriptions(sub);
    }
}
