import { Component, inject, input, output } from '@angular/core';

import { ReservationArticleModel } from '../../../../../../models/reservation-article.model';
import { Tools } from '../../../../../../../core/utils/tools';
import { UserService } from '../../../../../../../core/services/user.service';
import { UnitsEnum } from '../../../../../../../core/models/enums/units.enum';

@Component({
    selector: 'arc-reservation-edit-article-entry',
    templateUrl: './reservation-edit-article-entry.component.html',
    styleUrl: './reservation-edit-article-entry.component.scss'
})
export class ReservationEditArticleEntryComponent {
    UnitsEnum = UnitsEnum;

    article = input.required<ReservationArticleModel>();

    readonly update = output<ReservationArticleModel>();
    readonly delete = output<void>();

    readonly currencyIsoCode: string;

    private readonly userService = inject(UserService);

    constructor() {
        this.currencyIsoCode = this.userService.getUserInfo()?.currencyIsoCode ?? '';
    }

    updateQuantity(quantity?: number): void {
        const updated = { ...this.article(), quantity: quantity || 0 };
        this.recalculateDiscounts(updated);
        updated.total = this.calculateTotal(updated);
        this.update.emit(updated);
    }

    updateUnitPrice(unitPrice?: number): void {
        const updated = { ...this.article(), unitPrice: unitPrice || 0 };
        this.recalculateDiscounts(updated);
        updated.total = this.calculateTotal(updated);
        this.update.emit(updated);
    }

    updateDiscount(discount?: number): void {
        const updated = { ...this.article(), discount: discount || 0, discountPercent: undefined };
        updated.total = this.calculateTotal(updated);
        this.update.emit(updated);
    }

    updateDiscountPercent(discountPercent?: number): void {
        const updated = { ...this.article(), discountPercent };
        if (discountPercent === undefined) {
            updated.discount = 0;
        }
        this.recalculateDiscounts(updated);
        updated.total = this.calculateTotal(updated);
        this.update.emit(updated);
    }

    updateRemarks(remarks?: string): void {
        this.update.emit({ ...this.article(), remarks });
    }

    private calculateTotal(article: ReservationArticleModel): number {
        return Tools.Utils.roundTo(article.quantity * article.unitPrice - article.discount, this.userService.defaultRoundTo);
    }

    private recalculateDiscounts(article: ReservationArticleModel): void {
        if (article.discountPercent !== undefined) {
            article.discount = Tools.Utils.roundTo(
                (article.quantity * article.unitPrice * (article.discountPercent || 0)) / 100,
                this.userService.defaultRoundTo
            );
        } else {
            const totalWithoutDiscount = article.quantity * article.unitPrice;
            if (article.discount > totalWithoutDiscount) {
                article.discount = 0;
            }
        }
    }
}
