import { Component, Input, OnInit, inject } from '@angular/core';

import { ArticleModel } from '../../../../app/models/article.model';
import { AuthPermissionModel } from '../../../../app/models/auth-permission.model';
import { PermissionService } from '../../../../core/services/permission.service';
import { PermissionsEnum } from '../../../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../../../core/models/enums/permission-type.enum';

@Component({
    selector: 'arc-article-details-widget',
    templateUrl: './article-details-widget.component.html',
    styleUrl: './article-details-widget.component.scss'
})
export class ArticleDetailsWidgetComponent implements OnInit {
    @Input({ required: true }) article!: ArticleModel;

    buyingPricePermission: true | AuthPermissionModel = true;

    private readonly permissionService = inject(PermissionService);

    ngOnInit(): void {
        this.buyingPricePermission = this.permissionService.hasPermission(PermissionsEnum.BuyingPrice, PermissionTypeEnum.Read);
    }

    getUrl(text: string): string {
        return `https://bwipjs-api.metafloor.com/?bcid=code128&text=${text}`;
    }
}
