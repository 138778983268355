import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { ContractModel } from '../../../app/models/contract.model';
import { SecuritySessionStorage } from '../../../core/services/storages/security.session-storage';
import { PortalStore } from '../../../app/services/stores/portal.store';

@Component({
    selector: 'arc-contracts-accept-dialog',
    templateUrl: './contracts-accept-dialog.component.html',
    styleUrl: './contracts-accept-dialog.component.scss'
})
export class ContractsAcceptDialogComponent implements OnInit {
    contract?: ContractModel;
    isLoading = true;
    isAcceptingContract = false;
    readonly codes: string[] = inject(MAT_DIALOG_DATA);

    private code = '';
    private email = '';
    private readonly portalStore = inject(PortalStore);
    private readonly securitySessionStorage = inject(SecuritySessionStorage);
    private readonly matDialog = inject(MatDialog);
    private readonly matDialogRef = inject(MatDialogRef);

    ngOnInit(): void {
        this.code = this.codes.shift()!;
        this.email = this.securitySessionStorage.getUserInfo()?.email || '';

        this.portalStore.getContracts(this.code, this.email).subscribe(resp => {
            this.contract = resp.value?.[0];
            this.isLoading = false;

            if (!this.contract) {
                this.matDialogRef.close();
            }
        });
    }

    accept(): void {
        this.isAcceptingContract = true;
        this.portalStore.signContracts(this.code, this.email)
            .pipe(finalize(() => this.isAcceptingContract = false))
            .subscribe(() => {
                if (this.codes.length > 0) {
                    this.matDialog.open(ContractsAcceptDialogComponent, {
                        width: '1000px',
                        maxHeight: '98svh',
                        maxWidth: '98vw',
                        data: this.codes
                    });
                }

                this.matDialogRef.close();
            });
    }
}
