import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoldPipe } from './bold.pipe';
import { NumberPipe } from './number.pipe';
import { DatePipe } from './date.pipe';
import { ErrorTranslatorPipe } from './error-translator.pipe';
import { FormControlPipe } from './form-control.pipe';
import { CurrencyPipe } from './currency.pipe';
import { QuickSearchConfigPipe } from './quick-search-config.pipe';
import { ReplacePlaceholderPipe } from './replace-placeholder.pipe';
import { PermissionPipe } from './permission.pipe';
import { TransformPipe } from './transform.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BoldPipe,
        NumberPipe,
        DatePipe,
        ErrorTranslatorPipe,
        FormControlPipe,
        CurrencyPipe,
        QuickSearchConfigPipe,
        ReplacePlaceholderPipe,
        PermissionPipe,
        TransformPipe
    ],
    exports: [
        BoldPipe,
        NumberPipe,
        DatePipe,
        ErrorTranslatorPipe,
        FormControlPipe,
        CurrencyPipe,
        QuickSearchConfigPipe,
        ReplacePlaceholderPipe,
        PermissionPipe,
        TransformPipe
    ]
})
export class PipesModule { }
