import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'arc-article-create-dialog',
    templateUrl: './article-create-dialog.component.html',
    styleUrl: './article-create-dialog.component.scss'
})
export class ArticleCreateDialogComponent {
    private readonly matDialogRef = inject(MatDialogRef);

    openSidebar(isFashionArticle: boolean): void {
        this.matDialogRef.close(isFashionArticle);
    }
}
