import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { RecycleBinListModel } from '../../../models/responses/recycle-bin-list.model';
import { RecycleBinStore } from '../../../services/stores/recycle-bin.store';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-recycle-bin',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './recycle-bin.component.scss'
})
export class RecycleBinComponent extends PageListViewComponent<RecycleBinListModel, RecycleBinListModel> {
    private readonly recycleBinStore = inject(RecycleBinStore);

    constructor() {
        super('RecycleBin');
        this.config = new TableListViewConfigModel<RecycleBinListModel, RecycleBinListModel>({
            entityName: 'RecycleBin',
            availableColumns: {
                element: new StackedColumnModel({
                    columnTitleKey: 'RecycleBin.List.Element',
                    propertyName: 'element',
                    propertyName2: 'recordId',
                    widthPixels: 300
                }),
                deletedAt: new DateColumnModel({
                    columnTitleKey: 'RecycleBin.List.DeletedAt',
                    propertyName: 'deletedAt',
                    format: 'short',
                    widthPixels: 200
                }),
                username: new StringColumnModel({
                    columnTitleKey: 'RecycleBin.List.User',
                    propertyName: 'username',
                    widthPixels: 200
                }),
                tableName: new StringColumnModel({
                    columnTitleKey: 'RecycleBin.List.TableName',
                    propertyName: 'tableName',
                    widthPixels: 300
                })
            },
            defaultSort: new ColumnSortModel({
                column: 'deletedAt',
                direction: SortDirectionEnum.Desc
            }),
            defaultColumnOrder: ['element', 'deletedAt', 'username', 'tableName'],
            availableDetailWidgets: {},
            defaultDetailWidgetOrder: [],
            store: this.recycleBinStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasDelete: false, hasEdit: false, hasExport: false })
        });
    }
}

