<div class="px-8 py-12 h-full" arcQuickFeedback="38"
    [quickFeedbackShouldShow]="!isDashboardEditable && widget.parameters.chartType === StatisticChartTypesEnum.Number"
    [quickFeedbackPosition]="{ top: 5, right: 5 }">
    <header class="absolute top-0 left-8 right-12 h-12 flex items-center z-10">
        @if (!isDashboardEditable) {
            <h2 class="font-semibold">{{ widget.title }}</h2>
        } @else{
            <input class="title font-semibold w-full" matInput [(ngModel)]="widget.title" (touchstart)="handleClick($event)"
                [placeholder]="'Widgets.WidgetTitle' | transloco" (mousedown)="handleClick($event)" />
        }
    </header>
    @if (homeStatisticsPermission === true) {
        <div class="h-full w-full">
            @if (widget.parameters.chartType !== StatisticChartTypesEnum.Number) {
                <arc-chart [data]="chartData" [type]="getChartTypeString(widget.parameters.chartType)">
                </arc-chart>
            } @else {
                <div class="flex flex-col justify-center items-center h-full">
                    <div>
                        <div class="text-5xl" [matTooltip]="(fromDate | arcDate) + ' - ' + (toDate  | arcDate)">
                            {{ chartTypeNumberSum | arcNumber : 2 : undefined : undefined : true }}
                        </div>
                        @if (hasComparisonValues) {
                            <div class="text-2xl" [matTooltip]="(comparisonFromDate | arcDate) + ' - ' + (comparisonToDate  | arcDate)"
                                [ngClass]="isChartTypeNumberComparisonPositive ? 'text-accent' : 'text-error'">
                                @if (isChartTypeNumberComparisonPositive) {
                                    <span>+</span>
                                }
                                {{ chartTypeNumberChange | arcNumber : 2 : '' : '%' }}
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
        <footer class="absolute bottom-0 left-8 right-8 h-12 items-center flex justify-between">
            <div class="w-40">
                <mat-select (selectionChange)="onPeriodSelected($event.value)" [(value)]="widget.parameters.period">
                    @for (period of periods; track period.value) {
                        <mat-option [value]="period.value">
                            {{ period.label }}
                        </mat-option>
                    }
                </mat-select>
            </div>
            <div class="flex gap-4">
                <button mat-icon-button [matMenuTriggerFor]="chartTypeMenu"
                    matTooltip="{{ 'Widgets.Statistics.StatisticChartType' | transloco }}">
                    @if (widget.parameters.chartType) {
                        <mat-icon> {{ chartTypeIconMapper[widget.parameters.chartType] }}</mat-icon>
                    }
                </button>
                <mat-menu #chartTypeMenu="matMenu" [class]="'chart-types'">
                    @for (chartType of chartTypes; track chartType.value) {
                        <button mat-menu-item [ngClass]="{ '!bg-element-selected': widget.parameters.chartType === chartType.value }"
                            (click)="onChartTypeSelected(chartType.value)" [matTooltip]="chartType.label" matTooltipPosition="after">
                            <mat-icon class="!mr-0"> {{ chartType.icon }}</mat-icon>
                        </button>
                    }
                </mat-menu>
                <div class="inline-block">
                    <button mat-icon-button (click)="openOptionsDialog()" matTooltip="{{ 'Widgets.Statistics.Filter.Filter' | transloco }}">
                        <mat-icon [matBadge]="filtersSetCount !== 0 ? filtersSetCount : null">filter_alt</mat-icon>
                    </button>
                </div>
            </div>
        </footer>
    } @else {
        <div class="w-full h-full grid place-items-center">
            <arc-no-permission-lock [missingPermission]="homeStatisticsPermission" [iconSize]="48"></arc-no-permission-lock>
        </div>
    }
</div>
