<div class="grid items-center grid-cols-12 gap-4">

    <form [formGroup]="formGroup" clasS="col-span-5 smd:col-span-full h-full flex flex-col gap-4 align-top">
        <arc-input [control]="formGroup.controls.title" labelKey="Inventories.Edit.BaseData.Title">
        </arc-input>
    </form>

    <div class="col-span-7 smd:col-span-full h-full rounded-lg min-h-[10rem]" [ngClass]="statusBgColorClass">
        <div class="px-8 py-4 flex gap-16">

            <div class="flex flex-col gap-x-16 gap-y-4 min-w-fit">
                <div>
                    <p class="text-sm">{{ 'Inventories.Edit.BaseData.StartDate' | transloco }}</p>
                    <p class="text-xl">{{ (item.startDate | arcDate) }}</p>
                </div>
                <div>
                    <p class="text-sm">{{ 'Inventories.Edit.BaseData.FinishedDate' | transloco }}</p>
                    <p class="text-xl">{{ !!item.finishedDate ? (item.finishedDate | arcDate) : '-' }}</p>
                </div>
            </div>

            <div class="flex flex-col gap-x-16 gap-y-4">
                <div>
                    <p class="text-sm">{{ 'Inventories.Edit.BaseData.Store' | transloco }}</p>
                    <p class="text-xl">{{ item.storeName }}</p>
                </div>
            </div>

        </div>

    </div>

</div>
