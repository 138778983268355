<div class="h-full">
    <header class="absolute top-0 right-0 flex justify-center">
        <button mat-icon-button (click)="goTransactionsPage()">
            <mat-icon>open_in_new</mat-icon>
        </button>
    </header>
    <div class="h-full w-full">
        <mat-spinner *ngIf="isLoading" [diameter]="32"></mat-spinner>
        <arc-static-table
            *ngIf="!!tableConfig"
            [config]="tableConfig"
            [shouldUseCompactStyle]="true"
            [isSingleLineRow]="true"></arc-static-table>
    </div>
</div>
