<form [formGroup]="formGroup">
    @for (acc of item.accounts; track acc.id) {
        <div class="grid grid-cols-2 smd:grid-cols-1 gap-4">
            <div class="flex-flex-col">
                <p class="text-s text-on-app-light">{{ 'Customers.Account.Balance' | transloco }} {{ acc.accountTypeTitle }}</p>
                <p class="text-xl">{{ acc.amount | arcCurrency : true }}</p>
            </div>
            <div class="flex gap-4 mt-4">
                <button mat-stroked-button [disabled]="transactionPermission !== true"
                    (click)="openCustomerBookingDialog(item.id, acc.id, acc.accountTypeId, true)">
                    <div class="flex items-center gap-2">
                        <mat-icon>add</mat-icon>
                        <span>{{ 'General.Actions.Increase'| transloco }}</span>
                        @if (transactionPermission !== true) {
                            <arc-no-permission-lock [missingPermission]="transactionPermission"></arc-no-permission-lock>
                        }
                    </div>
                </button>
                <button mat-stroked-button [disabled]="transactionPermission !== true"
                    (click)="openCustomerBookingDialog(item.id, acc.id, acc.accountTypeId, false)">
                    <div class="flex items-center gap-2">
                        <mat-icon>remove</mat-icon>
                        <span>{{ 'General.Actions.Decrease' | transloco }}</span>
                        @if (transactionPermission !== true) {
                            <arc-no-permission-lock [missingPermission]="transactionPermission"></arc-no-permission-lock>
                        }
                    </div>
                </button>
            </div>
        </div>
    }
    <div class="mt-10 grid grid-cols-2 smd:grid-cols-1 gap-4">
        <arc-number-input label="Customers.EditFields.PaymentDays" [formControl]="formGroup.controls.paymentDays" [unit]="UnitsEnum.Days"
            [decimalPlaces]="0" [requiredPermission]="PermissionsEnum.Invoices"></arc-number-input>
        <arc-currency-input [label]="accountLimitLabel" [isLabelTranslated]="true" [formControl]="formGroup.controls.accountMinAmount">
        </arc-currency-input>
    </div>
</form>
