import { Component } from '@angular/core';

import { BaseDetailWidget } from '../../../../../components/abstractions/base.detail-widget';
import { OrderArticleListModel } from '../../../../models/responses/order-article-list.model';
import { OrderArticleModel } from '../../../../models/order-article.model';

@Component({
    selector: 'arc-order-article-pictures',
    templateUrl: './order-article-pictures.detail-widget.html',
    styleUrl: './order-article-pictures.detail-widget.scss'
})
export class OrderArticlePicturesDetailWidget extends BaseDetailWidget<OrderArticleListModel, OrderArticleModel, string> {}
