import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BaseEditSidebarItemComponent } from '../../../../../../components/sidebar-components/edit-sidebar/base-edit-item/base-edit-sidebar-item.component';
import { TransactionTypeEditModel } from '../../../../../models/requests/transaction-type-edit.model';
import { ArcFormControl } from '../../../../../../core/utils/arc-form-control';
import { TransactionTypePaymentTypeModel } from '../../../../../models/transaction-type-payment-type.model';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { GeneralDataColumnModel } from '../../../../../../components/dynamic-table/models/column-types/general-data-column.model';
import { GeneralDataTypeEnum } from '../../../../../../core/models/enums/general-data-type.enum';
import { CheckboxColumnModel } from '../../../../../../components/dynamic-table/models/column-types/checkbox-column.model';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { TransactionTypeModel } from '../../../../../models/transaction-type.model';
import { returnPaymentTypeValidator } from './return-payment-type-validator';

@Component({
    selector: 'arc-transaction-type-payment-type-credit',
    templateUrl: './transaction-type-payment-type-credit.component.html',
    styleUrl: './transaction-type-payment-type-credit.component.scss'
})
export class TransactionTypePaymentTypeCreditComponent
    extends BaseEditSidebarItemComponent<TransactionTypeModel, TransactionTypeEditModel> {
    override formGroup = new FormGroup({
        transactionTypePaymentTypesCredit: new ArcFormControl<TransactionTypePaymentTypeModel[]>([])
    });
    transactionTypePaymentTypesTableConfig: EditableTableConfigModel<TransactionTypePaymentTypeModel>;

    constructor() {
        super();

        this.transactionTypePaymentTypesTableConfig = new EditableTableConfigModel<TransactionTypePaymentTypeModel>({
            formGroupGeneratorFn: () =>
                new FormGroup(
                    {
                        debit: new ArcFormControl(false),
                        paymentTypeId: new ArcFormControl(0, Validators.required),
                        returnPaymentTypeId: new ArcFormControl<OptionalType<number>>(undefined),
                        isStandardPayment: new ArcFormControl(false),
                        askAmount: new ArcFormControl(false)
                    },
                    { validators: returnPaymentTypeValidator() }
                ),
            onRowInit: fg => {
                const askAmountControl = fg.controls['askAmount'];

                if (!askAmountControl.value) {
                    const returnPaymentTypeIdControl = fg.controls['returnPaymentTypeId'];
                    returnPaymentTypeIdControl.disable();
                }

                const askAmountValueChangesSubscription = askAmountControl.valueChanges.subscribe(value => {
                    const returnPaymentTypeIdControl = fg.controls['returnPaymentTypeId'];
                    if (value) {
                        returnPaymentTypeIdControl.enable();
                    } else {
                        returnPaymentTypeIdControl.disable();
                    }
                });

                this.addSubscriptions(askAmountValueChangesSubscription);
            },
            rowHeightPx: 63,
            rowGapPx: 12,
            columns: [
                new GeneralDataColumnModel({
                    propertyName: 'paymentTypeId',
                    isEditable: true,
                    widthPixels: 250,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeCredit.PaymentType',
                    generalDataType: GeneralDataTypeEnum.PaymentTypes
                }),
                new GeneralDataColumnModel({
                    propertyName: 'returnPaymentTypeId',
                    isEditable: true,
                    widthPixels: 250,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeCredit.ReturnPaymentType',
                    generalDataType: GeneralDataTypeEnum.PaymentTypes
                }),
                new CheckboxColumnModel({
                    propertyName: 'isStandardPayment',
                    isEditable: true,
                    widthPixels: 32,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeCredit.StandardPayment'
                }),
                new CheckboxColumnModel({
                    propertyName: 'askAmount',
                    isEditable: true,
                    widthPixels: 32,
                    columnTitleKey: 'TransactionTypes.Edit.PaymentTypeCredit.AskAmount'
                })
            ]
        });
    }
    override onBrokenRulesLoad(): string[] {
        return this.transactionTypePaymentTypesTableConfig.setBrokenRulesOnFormGroups(this.brokenRules);
    }

    override onItemSet(): void {
        const transactionTypePaymentTypesCredit = this.item.transactionTypePaymentTypes.filter(t => !t.debit);
        this.formGroup.patchValue({ transactionTypePaymentTypesCredit });
    }

    override prepareSaveModel(): Partial<TransactionTypeEditModel> {
        if (
            this.item.transactionTypePaymentTypes.filter(t => !t.debit).length !== 0 ||
            this.formGroup.value.transactionTypePaymentTypesCredit?.length !== 0
        ) {
            return { transactionTypePaymentTypesCredit: this.formGroup.value.transactionTypePaymentTypesCredit };
        }

        return {};
    }
}
