<h1 mat-dialog-title>{{ data.isDebit ? ('Customers.Account.IncreaseBalance' | transloco) : ('Customers.Account.DecreaseBalance' | transloco)}}</h1>
<mat-dialog-content>
    <div class="flex flex-col gap-4 !mt-2">
        <arc-currency-input labelKey="customer.Account.Amount" [formControl]="formGroup.controls.amount"></arc-currency-input>
        <arc-textarea label="Customers.Account.Remarks" [formControl]="formGroup.controls.remarks"></arc-textarea>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>{{'General.Actions.Cancel' | transloco}}</button>
    <arc-button type="flat" color="accent" [isLoading]="isCreating" (clicked)="onSave()">
        {{'General.Actions.Book' | transloco}}
    </arc-button>
</mat-dialog-actions>
