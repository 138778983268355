import { Component, ViewChild, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DynamicFormField } from '../../../dynamic-form/models/dynamic-form-field';
import { DynamicFormControlTypeEnum } from '../../../dynamic-form/models/dynamic-form-control-type-enum';
import { StatisticsWidgetOptionsDialogData } from './models/statistics-widget-options-dialog-data.model';
import { GeneralDataTypeEnum } from '../../../../core/models/enums/general-data-type.enum';
import { DynamicFormComponent } from '../../../dynamic-form/dynamic-form.component';

@Component({
    selector: 'arc-statistics-widget-options-dialog',
    templateUrl: './statistics-widget-options-dialog.component.html',
    styleUrls: ['./statistics-widget-options-dialog.component.scss']
})
export class StatisticsWidgetOptionsDialogComponent {
    @ViewChild('dynamicForm') dynamicForm!: DynamicFormComponent;

    formFields: DynamicFormField[] = [];

    private readonly data: StatisticsWidgetOptionsDialogData = inject(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef);

    constructor() {
        const formFields: DynamicFormField[] = [];

        const mainGroupIdField = new DynamicFormField();
        mainGroupIdField.type = DynamicFormControlTypeEnum.ArticleGroup;
        mainGroupIdField.key = 'mainGroupId';
        mainGroupIdField.label = 'ArticleGroups.MainGroup';
        mainGroupIdField.value = this.data.parameters.mainGroupId;
        mainGroupIdField.treeAutocompleteConfig = { allowsInlineCreation: false, allowEmptySelection: true, selectableLevels: [0] };

        formFields.push(mainGroupIdField);

        const pointOfSaleField = new DynamicFormField();
        pointOfSaleField.type = DynamicFormControlTypeEnum.GeneralData;
        pointOfSaleField.generalDataType = GeneralDataTypeEnum.PointOfSales;
        pointOfSaleField.key = 'pointOfSaleId';
        pointOfSaleField.label = 'Widgets.Statistics.Filter.Pos';
        pointOfSaleField.value = this.data.parameters.pointOfSaleId;
        formFields.push(pointOfSaleField);

        const branchField = new DynamicFormField();
        branchField.type = DynamicFormControlTypeEnum.GeneralData;
        branchField.generalDataType = GeneralDataTypeEnum.Branches;
        branchField.key = 'branchId';
        branchField.label = 'Widgets.Statistics.Filter.Branch';
        branchField.value = this.data.parameters.branchId;

        formFields.push(branchField);

        const comparePeriodField = new DynamicFormField();
        comparePeriodField.type = DynamicFormControlTypeEnum.Checkbox;
        comparePeriodField.key = 'comparePeriod';
        comparePeriodField.label = 'Widgets.Statistics.Filter.ComparisonPeriodChartLabel';
        comparePeriodField.value = this.data.parameters.comparePeriod;

        formFields.push(comparePeriodField);

        const salesBudgetField = new DynamicFormField();
        salesBudgetField.type = DynamicFormControlTypeEnum.Checkbox;
        salesBudgetField.key = 'salesBudget';
        salesBudgetField.label = 'Widgets.Statistics.Filter.BudgetValuesChartLabel';
        salesBudgetField.value = this.data.parameters.salesBudget;

        formFields.push(salesBudgetField);

        this.formFields = formFields;
    }

    onUpdateSettings(): void {
        this.dynamicForm.submitForm().then();
    }

    onFormSubmitted(data: any): void {
        this.data.parameters = { ...this.data.parameters, ...data };
        this.dialogRef.close(this.data.parameters);
    }
}
