import { BaseColumnModel } from './base-column.model';
import { GeneralDataTypeEnum } from '../../../../core/models/enums/general-data-type.enum';
import { ColumnTypeEnum } from '../enums/column-type.enum';

export class GeneralDataColumnModel extends BaseColumnModel {
    generalDataType!: GeneralDataTypeEnum;
    onValueChanged?: (item: any, newValue: any, updateValidity: () => void) => void;

    constructor(init?: Partial<GeneralDataColumnModel>) {
        super(ColumnTypeEnum.GeneralData);
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
