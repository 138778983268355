import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import EasyMDE from 'easymde';

@Component({
    selector: 'arc-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrl: './markdown-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        }
    ]
})
export class MarkdownEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
    @ViewChild('arcMarkdownEditor') markdownEditor?: ElementRef;
    @Input() @HostBinding('class') styleClass = 'mde-default';

    easyMDE?: EasyMDE;
    text = '';
    isTouched = false;

    onTouched: () => void = () => { };
    onChange: (value?: string) => void = () => { };

    ngAfterViewInit(): void {
        this.easyMDE = new EasyMDE({
            element: this.markdownEditor?.nativeElement,
            status: false
        });

        if (!!this.text) {
            this.easyMDE!.value(this.text);
        }

        this.easyMDE!.codemirror.on('change', () => {
            if (!this.isTouched) {
                this.isTouched = true;
                this.onTouched();
            }

            this.onChange(this.easyMDE?.value());
        });
    }

    ngOnDestroy(): void {
        this.easyMDE?.cleanup();
    }

    writeValue(value: string): void {
        this.text = value;
        this.easyMDE?.value(value);
    }
    registerOnChange(fn: (value?: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    insertTextAtCursor(text: string): void {
        if (this.easyMDE) {
            const cursor = this.easyMDE.codemirror.getCursor();
            this.easyMDE.codemirror.replaceSelection(text);

            // Adjust cursor position after insertion
            this.easyMDE.codemirror.setCursor(cursor.line, cursor.ch + text.length);
        }
    }
    focus(): void {
        this.easyMDE?.codemirror.focus();
    }
}
