import { Injectable } from '@angular/core';

import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { StoreConfig } from '../../../core/models/store.config';
import { OrderArticleListModel } from '../../models/responses/order-article-list.model';
import { OrderArticleModel } from '../../models/order-article.model';

@Injectable({
    providedIn: 'root'
})
export class OrderArticlesStore
    extends BaseCrudStore<OrderArticleModel, OrderArticleListModel, OrderArticleModel, OrderArticleModel, string> {
    constructor() {
        super(new StoreConfig({ baseController: 'order-articles' }));
    }
}
