import { Injectable } from '@angular/core';

import { StoreConfig } from '../../../core/models/store.config';
import { SizeListModel } from '../../models/size-list.model';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';

@Injectable({
    providedIn: 'root'
})
export class SizesStore extends BaseCrudStore<SizeListModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'sizes' }));
    }
}
